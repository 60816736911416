const Types = {
  INVENTORY_ITEMS_CREATE_REQUEST: '@inventory/CREATE_INVENTORY_ITEM_REQUEST',
  INVENTORY_ITEMS_CREATE_SUCCESS: '@inventory/CREATE_INVENTORY_ITEM_SUCCESS',
  INVENTORY_ITEMS_CREATE_FAILURE: '@inventory/CREATE_INVENTORY_ITEM_FAILURE',

  INVENTORY_ITEMS_UPDATE_REQUEST: '@inventory/UPDATE_INVENTORY_ITEM_REQUEST',
  INVENTORY_ITEMS_UPDATE_SUCCESS: '@inventory/UPDATE_INVENTORY_ITEM_SUCCESS',
  INVENTORY_ITEMS_UPDATE_FAILURE: '@inventory/UPDATE_INVENTORY_ITEM_FAILURE',

  INVENTORY_ITEMS_DELETE_REQUEST: '@inventory/DELETE_INVENTORY_ITEM_REQUEST',
  INVENTORY_ITEMS_DELETE_SUCCESS: '@inventory/DELETE_INVENTORY_ITEM_SUCCESS',
  INVENTORY_ITEMS_DELETE_FAILURE: '@inventory/DELETE_INVENTORY_ITEM_FAILURE',

  INVENTORY_ITEMS_RESET_LIST: '@inventory/RESET_LIST_INVENTORY_ITEM',

  INVENTORY_ITEMS_SEARCH_OPEN_MODAL:
    '@inventory/OPEN_MODAL_SEARCH_INVENTORY_ITEM',
  INVENTORY_ITEMS_SEARCH_CLOSE_MODAL:
    '@inventory/CLOSE_MODAL_SEARCH_INVENTORY_ITEM',
  INVENTORY_ITEMS_SET_TABLE_FILTERS:
    '@inventory/SET_TABLE_FILTERS_INVENTORY_ITEM',
};

export default Types;
