import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  globalList: [],
  detail: {},
  settingDetail: {},
  loading: false,
  requestError: false,
  settingModalOpen: false,
  configureDefaultPriceListModalOpen: false,
  configureDefaultPaymentMethodModalOpen: false,
  configuteDefaultProductDiscountTypeModalOpen: false,
};

function formatSettingValues(setting, field_type) {
  let formattedValue = setting;

  if (field_type === 'BOOLEAN') {
    if (formattedValue === 'false') {
      formattedValue = false;
    } else if (formattedValue === 'true') {
      formattedValue = true;
    }
  }

  if (field_type === 'NUMBER') {
    formattedValue = Number(formattedValue);
  }

  return formattedValue;
}

function updateGlobalList(globalList, newSetting) {
  return globalList.map(globalItem => {
    if (globalItem.id === newSetting.id) {
      const formattedValue = formatSettingValues(
        newSetting.value,
        globalItem.field_type
      );

      const data = {
        ...globalItem,
        value: formattedValue,
      };

      return data;
    }

    return globalItem;
  });
}

export default function settings(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SETTINGS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.SETTINGS_GET_SUCCESS: {
        const { settings: settingData } = action.payload;

        const settingList = [];

        settingData.forEach(setting => {
          const { items, ...rest } = setting;

          const eachItems = items.map(item => ({
            ...item,
            setting_name: setting.name,
            parent_id: setting.id,
            level: 2,
          }));

          const newSetting = {
            ...rest,
            level: 1,
          };

          settingList.push(newSetting);
          settingList.push(...eachItems);
        });

        const sortedList = settingList.sort((a, b) => {
          if (a.level === 1 && b.level === 1) {
            return a.type < b.type && a.type !== b.type ? -1 : 1;
          }

          if (a.level === 2 && b.level === 2) {
            return a.type < b.type && a.type !== b.type ? -1 : 1;
          }

          return 0;
        });

        draft.list = sortedList;
        draft.loading = false;
        draft.requestError = false;
        break;
      }
      case Types.SETTINGS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SETTINGS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SETTINGS_UPDATE_SUCCESS: {
        const { newSettingItem } = action.payload;

        const settingList = draft.list.map(setting => {
          return setting.id === newSettingItem.id
            ? {
                ...setting,
                ...newSettingItem,
                parent_id: newSettingItem.setting_id,
                level: 2,
              }
            : setting;
        });

        const globalList = updateGlobalList(draft.globalList, newSettingItem);

        draft.list = settingList;
        draft.globalList = globalList;
        draft.detail = {};
        draft.settingDetail = {};
        draft.loading = false;
        draft.settingModalOpen = false;
        draft.configureDefaultPriceListModalOpen = false;
        draft.configureDefaultPaymentMethodModalOpen = false;
        draft.configuteDefaultProductDiscountTypeModalOpen = false;
        break;
      }
      case Types.SETTINGS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SETTINGS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        break;
      }

      case Types.SETTINGS_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          const selectedSetting = state.list.find(setting => {
            return setting.id === id;
          });

          draft.detail = selectedSetting;

          draft.settingDetail = state.list.find(setting => {
            return setting.id === selectedSetting.parent_id;
          });
        }

        draft.settingModalOpen = true;
        break;
      }
      case Types.SETTINGS_CLOSE_MODAL: {
        draft.detail = {};
        draft.settingModalOpen = false;
        draft.settingDetail = {};
        break;
      }

      case Types.SETTINGS_CONFIGURE_DEFAULT_PRICE_LIST_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          const selectedSetting = state.list.find(setting => {
            return setting.id === id;
          });

          draft.detail = selectedSetting;

          draft.settingDetail = state.list.find(setting => {
            return setting.id === selectedSetting.parent_id;
          });
        }

        draft.configureDefaultPriceListModalOpen = true;
        break;
      }
      case Types.SETTINGS_CONFIGURE_DEFAULT_PRICE_LIST_CLOSE_MODAL: {
        draft.detail = {};
        draft.configureDefaultPriceListModalOpen = false;
        draft.settingDetail = {};
        break;
      }

      case Types.SETTINGS_SET_GLOBAL_LIST_SUCCESS: {
        const { settings: settingsData } = action.payload;

        const settingList = [];

        settingsData.forEach(setting => {
          const { items, field_type } = setting;

          const eachItems = items.map(item => ({
            ...item,
            setting_name: setting.name,
          }));

          const filteredItems = eachItems.filter(
            item => item.type !== 'MOBILE'
          );

          const formattedSettings = filteredItems.map(item => {
            const formattedValue = formatSettingValues(item.value, field_type);

            const data = {
              id: item.id,
              setting_name: item.setting_name,
              setting_id: setting.id,
              display_label: setting.display_label,
              value: formattedValue,
              type: item.type,
              field_type,
            };

            return data;
          });

          settingList.push(...formattedSettings);
        });

        draft.globalList = settingList;
        break;
      }
      case Types.SETTINGS_SET_GLOBAL_LIST_FAILURE: {
        draft.globalList = [];
        break;
      }
      case Types.SETTINGS_RESET_GLOBAL_LIST: {
        draft.globalList = [];
        break;
      }
      case Types.SETTINGS_UPDATE_GLOBAL_SETTING: {
        const { setting: newSetting } = action.payload;

        const globalList = updateGlobalList(draft.globalList, newSetting);

        draft.globalList = globalList;

        break;
      }

      case Types.SETTINGS_CONFIGURE_DEFAULT_PAYMENT_METHOD_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          const selectedSetting = state.list.find(setting => {
            return setting.id === id;
          });

          draft.detail = selectedSetting;

          draft.settingDetail = state.list.find(setting => {
            return setting.id === selectedSetting.parent_id;
          });
        }

        draft.configureDefaultPaymentMethodModalOpen = true;
        break;
      }
      case Types.SETTINGS_CONFIGURE_DEFAULT_PAYMENT_METHOD_CLOSE_MODAL: {
        draft.detail = {};
        draft.configureDefaultPaymentMethodModalOpen = false;
        draft.settingDetail = {};
        break;
      }

      case Types.SETTINGS_CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          const selectedSetting = state.list.find(setting => {
            return setting.id === id;
          });

          draft.detail = selectedSetting;

          draft.settingDetail = state.list.find(setting => {
            return setting.id === selectedSetting.parent_id;
          });
        }

        draft.configuteDefaultProductDiscountTypeModalOpen = true;
        break;
      }
      case Types.SETTINGS_CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_CLOSE_MODAL: {
        draft.detail = {};
        draft.configuteDefaultProductDiscountTypeModalOpen = false;
        draft.settingDetail = {};
        break;
      }

      default:
    }
  });
}
