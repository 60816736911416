import styled from 'styled-components';

import { Delete as MuiDelete } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'info':
      styles = ``;
      break;
    case 'table':
      styles = `color: ${colors.darkRegular}`;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Delete = styled(MuiDelete)`
  ${({ type }) => defineStyles(type)}
`;
