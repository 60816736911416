import Types from './types';

export function getPromoterFiles(files) {
  return {
    type: Types.PROMOTER_FILES_GET,
    payload: { files },
  };
}

export function createPromoterFileRequest(promoter_id, file) {
  return {
    type: Types.PROMOTER_FILES_CREATE_REQUEST,
    payload: { promoter_id, file },
  };
}

export function createPromoterFileSuccess(file) {
  return {
    type: Types.PROMOTER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createPromoterFileFailure() {
  return {
    type: Types.PROMOTER_FILES_CREATE_FAILURE,
  };
}

export function updatePromoterFileRequest(promoter_id, file_id, newFile) {
  return {
    type: Types.PROMOTER_FILES_UPDATE_REQUEST,
    payload: { promoter_id, file_id, newFile },
  };
}

export function updatePromoterFileSuccess(newFile) {
  return {
    type: Types.PROMOTER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updatePromoterFileFailure() {
  return {
    type: Types.PROMOTER_FILES_UPDATE_FAILURE,
  };
}

export function deletePromoterFileRequest(promoter_id, file_id) {
  return {
    type: Types.PROMOTER_FILES_DELETE_REQUEST,
    payload: { promoter_id, file_id },
  };
}

export function deletePromoterFileSuccess(file_id) {
  return {
    type: Types.PROMOTER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deletePromoterFileFailure() {
  return {
    type: Types.PROMOTER_FILES_DELETE_FAILURE,
  };
}

export function openModalPromoterFile() {
  return {
    type: Types.PROMOTER_FILES_OPEN_MODAL,
  };
}

export function closeModalPromoterFile() {
  return {
    type: Types.PROMOTER_FILES_CLOSE_MODAL,
  };
}

export function resetListPromoterFile() {
  return {
    type: Types.PROMOTER_FILES_RESET_LIST,
  };
}
