import React from 'react';
import { Bell, /* Search as SearchIcon, */ Users } from 'react-feather';
import { useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';

import { withTheme } from 'styled-components';

import PropTypes from 'prop-types';

import { Grid, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import { openModalContact } from '~/store/modules/contacts/actions';

import LanguageMenu from './LanguageMenu';
import {
  AppBar,
  GridContainer,
  IconButton,
  Indicator,
  // Search,
  // SearchIconWrapper,
  // Input,
  TitleContainer,
  Title,
} from './styles';
import UserMenu from './UserMenu';

/* TODO: Futuramente fazer a parte de notificacoes, adicionando no badgeContent
   o numero de nao lidos */
/* TODO: Futuramente adicionar o recurso de pesquisar */
function Header({ onDrawerToggle }) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { company } = useSelector(state => state.auth);

  return (
    <AppBar>
      <Toolbar>
        <GridContainer container>
          <Grid item>
            <IconButton
              aria-label={formatMessage({ id: 'header.open-drawer' })}
              onClick={onDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <TitleContainer>
            <Title>{company.nickname || company.name}</Title>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={formatMessage({ id: 'header.search' })} />
            </Search> */}
          </TitleContainer>

          <Grid item>
            <IconButton onClick={() => dispatch(openModalContact())}>
              <Users />
            </IconButton>

            <IconButton>
              <Indicator badgeContent={0}>
                <Bell />
              </Indicator>
            </IconButton>
            <LanguageMenu />
            <UserMenu />
          </Grid>
        </GridContainer>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default connect()(withTheme(Header));
