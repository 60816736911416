import Types from './types';

export function getRuralPropertyOwners(owners) {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_GET,
    payload: { owners },
  };
}

export function createRuralPropertyOwnerRequest(property_id, owner) {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_CREATE_REQUEST,
    payload: { property_id, owner },
  };
}

export function createRuralPropertyOwnerSuccess(owner) {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_CREATE_SUCCESS,
    payload: { owner },
  };
}

export function createRuralPropertyOwnerFailure() {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_CREATE_FAILURE,
  };
}

export function deleteRuralPropertyOwnerRequest(
  property_id,
  property_owner_id
) {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_DELETE_REQUEST,
    payload: { property_id, property_owner_id },
  };
}

export function deleteRuralPropertyOwnerSuccess(property_owner_id) {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_DELETE_SUCCESS,
    payload: { property_owner_id },
  };
}

export function deleteRuralPropertyOwnerFailure() {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_DELETE_FAILURE,
  };
}

export function resetListRuralPropertyOwner() {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_RESET_LIST,
  };
}

export function openModalRuralPropertyOwner() {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_OPEN_MODAL,
  };
}

export function closeModalRuralPropertyOwner() {
  return {
    type: Types.RURAL_PROPERTY_OWNERS_CLOSE_MODAL,
  };
}
