import Types from './types';

export function getOrderItems(items) {
  return {
    type: Types.ORDER_ITEMS_GET,
    payload: { items },
  };
}

export function createOrderItemRequest(
  order_id,
  item,
  saveAndNew = false,
  resetForm = null,
  handleFocusProductAfterSaveAndNew = null
) {
  return {
    type: Types.ORDER_ITEMS_CREATE_REQUEST,
    payload: {
      order_id,
      item,
      saveAndNew,
      resetForm,
      handleFocusProductAfterSaveAndNew,
    },
  };
}

export function createOrderItemSuccess(item, saveAndNew) {
  return {
    type: Types.ORDER_ITEMS_CREATE_SUCCESS,
    payload: { item, saveAndNew },
  };
}

export function createOrderItemFailure() {
  return {
    type: Types.ORDER_ITEMS_CREATE_FAILURE,
  };
}

export function updateOrderItemRequest(
  order_id,
  item_id,
  newItem,
  recalculateValues = false
) {
  return {
    type: Types.ORDER_ITEMS_UPDATE_REQUEST,
    payload: { order_id, item_id, newItem, recalculateValues },
  };
}

export function updateOrderItemSuccess(newItem) {
  return {
    type: Types.ORDER_ITEMS_UPDATE_SUCCESS,
    payload: { newItem },
  };
}

export function updateOrderItemFailure() {
  return {
    type: Types.ORDER_ITEMS_UPDATE_FAILURE,
  };
}

export function deleteOrderItemRequest(order_id, item_id) {
  return {
    type: Types.ORDER_ITEMS_DELETE_REQUEST,
    payload: { order_id, item_id },
  };
}

export function deleteOrderItemSuccess(item_id) {
  return {
    type: Types.ORDER_ITEMS_DELETE_SUCCESS,
    payload: { item_id },
  };
}

export function deleteOrderItemFailure() {
  return {
    type: Types.ORDER_ITEMS_DELETE_FAILURE,
  };
}

export function resetListOrderItem() {
  return {
    type: Types.ORDER_ITEMS_RESET_LIST,
  };
}

export function openModalOrderItem(item_id) {
  return {
    type: Types.ORDER_ITEMS_OPEN_MODAL,
    payload: { item_id },
  };
}

export function closeModalOrderItem() {
  return {
    type: Types.ORDER_ITEMS_CLOSE_MODAL,
  };
}

export function openModalPriceList() {
  return {
    type: Types.ORDER_ITEMS_PRICE_LIST_OPEN_MODAL,
  };
}

export function closeModalPriceList() {
  return {
    type: Types.ORDER_ITEMS_PRICE_LIST_CLOSE_MODAL,
  };
}
