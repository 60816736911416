import React from 'react';

import PropTypes from 'prop-types';

import { TrendingUp } from './styles';

export default function IncreaseIcon({ type }) {
  return <TrendingUp type={type} />;
}

IncreaseIcon.propTypes = {
  type: PropTypes.string,
};

IncreaseIcon.defaultProps = {
  type: '',
};
