import React from 'react';

import PropTypes from 'prop-types';

import {
  Category,
  CategoryText,
  CategoryIconLess,
  CategoryIconMore,
  CategoryBadge,
} from './styles';

export default function SidebarCategory({
  name,
  icon,
  isOpen,
  isCollapsable,
  badge,
  isNested,
  ...rest
}) {
  let iconMargin = 0;

  const checkIsNested = isNested ? 'true' : 'false';

  if (checkIsNested === 'true') {
    if (icon) {
      iconMargin = '15px';
    } else {
      iconMargin = '35px';
    }
  }
  return (
    <Category {...rest}>
      {icon}
      <CategoryText nested={checkIsNested} icon_margin={iconMargin}>
        {name}
      </CategoryText>
      {isCollapsable && (isOpen ? <CategoryIconMore /> : <CategoryIconLess />)}
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
}

SidebarCategory.propTypes = {
  name: PropTypes.element.isRequired,
  icon: PropTypes.element,
  isOpen: PropTypes.bool,
  isCollapsable: PropTypes.bool.isRequired,
  badge: PropTypes.string,
  isNested: PropTypes.bool,
};

SidebarCategory.defaultProps = {
  isOpen: false,
  badge: null,
  icon: null,
  isNested: null,
};
