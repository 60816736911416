import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filterColumnsOrder: null,
  filterSearch: '',

  loading: false,
};

export default function countries(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COUNTRIES_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COUNTRIES_GET_SUCCESS: {
        const { data, filterColumnsOrder, filterSearch } = action.payload;

        draft.list = data;
        draft.loading = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }
        break;
      }
      case Types.COUNTRIES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COUNTRIES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        break;
      }

      default:
    }
  });
}
