import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createInventoryItemSuccess,
  createInventoryItemFailure,
  updateInventoryItemSuccess,
  updateInventoryItemFailure,
  deleteInventoryItemSuccess,
  deleteInventoryItemFailure,
} from './actions';
import Types from './types';

export function* createInventoryItem({ payload }) {
  try {
    const { inventory_id, item, updateItemList } = payload;

    const response = yield call(
      api.post,
      `inventories/${inventory_id}/items`,
      item
    );

    yield put(createInventoryItemSuccess());

    if (updateItemList) {
      updateItemList(response.data);
    }

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createInventoryItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateInventoryItem({ payload }) {
  try {
    const { inventory_id, item_id, newItem, updateItemList } = payload;

    const response = yield call(
      api.put,
      `inventories/${inventory_id}/items/${item_id}`,
      newItem
    );

    yield put(updateInventoryItemSuccess());

    if (updateItemList) {
      updateItemList(response.data);
    }

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateInventoryItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteInventoryItem({ payload }) {
  try {
    const { inventory_id, item_id, updateItemList } = payload;

    yield call(api.delete, `inventories/${inventory_id}/items/${item_id}`);

    yield put(deleteInventoryItemSuccess());

    if (updateItemList) {
      updateItemList(item_id);
    }

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteInventoryItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.INVENTORY_ITEMS_CREATE_REQUEST, createInventoryItem),
  takeLatest(Types.INVENTORY_ITEMS_UPDATE_REQUEST, updateInventoryItem),
  takeLatest(Types.INVENTORY_ITEMS_DELETE_REQUEST, deleteInventoryItem),
]);
