const Types = {
  OWNERS_GET_REQUEST: '@owners/GET_OWNERS_REQUEST',
  OWNERS_GET_SUCCESS: '@owners/GET_OWNERS_SUCCESS',
  OWNERS_GET_FAILURE: '@owners/GET_OWNERS_FAILURE',

  OWNERS_CREATE_REQUEST: '@owners/CREATE_OWNER_REQUEST',
  OWNERS_CREATE_SUCCESS: '@owners/CREATE_OWNER_SUCCESS',
  OWNERS_CREATE_FAILURE: '@owners/CREATE_OWNER_FAILURE',

  OWNERS_UPDATE_REQUEST: '@owners/UPDATE_OWNER_REQUEST',
  OWNERS_UPDATE_SUCCESS: '@owners/UPDATE_OWNER_SUCCESS',
  OWNERS_UPDATE_FAILURE: '@owners/UPDATE_OWNER_FAILURE',

  OWNERS_DELETE_REQUEST: '@owners/DELETE_OWNER_REQUEST',
  OWNERS_DELETE_SUCCESS: '@owners/DELETE_OWNER_SUCCESS',
  OWNERS_DELETE_FAILURE: '@owners/DELETE_OWNER_FAILURE',

  OWNERS_BECOME_REQUEST: '@owners/BECOME_OWNER_REQUEST',
  OWNERS_BECOME_SUCCESS: '@owners/BECOME_OWNER_SUCCESS',
  OWNERS_BECOME_FAILURE: '@owners/BECOME_OWNER_FAILURE',

  OWNERS_GET_DETAILS_REQUEST: '@owners/GET_DETAILS_OWNER_REQUEST',
  OWNERS_GET_DETAILS_SUCCESS: '@owners/GET_DETAILS_OWNER_SUCCESS',
  OWNERS_GET_DETAILS_FAILURE: '@owners/GET_DETAILS_OWNER_FAILURE',

  OWNERS_RESET_LIST: '@owners/RESET_LIST_OWNERS',
  OWNERS_RESET_DETAILS: '@owners/RESET_DETAILS_OWNER',

  OWNERS_SET_SAVING_ON_SUB_RESOURCE: '@owners/SET_SAVING_ON_SUB_RESOURCE_OWNER',
  OWNERS_RESET_SUB_RESOURCE_DATA: '@owners/RESET_SUB_RESOURCE_DATA_OWNER',

  OWNERS_ADD_OPEN_MODAL: '@owners/OPEN_MODAL_ADD_OWNER',
  OWNERS_ADD_CLOSE_MODAL: '@owners/CLOSE_MODAL_ADD_OWNER',

  OWNERS_FILTER_OPEN_MODAL: '@owners/OPEN_MODAL_FILTER_OWNER',
  OWNERS_FILTER_CLOSE_MODAL: '@owners/CLOSE_MODAL_FILTER_OWNER',

  OWNERS_OBSERVATION_OPEN_MODAL: '@owners/OPEN_MODAL_OWNER_OBSERVATION',
  OWNERS_OBSERVATION_CLOSE_MODAL: '@owners/CLOSE_MODAL_OWNER_OBSERVATION',

  OWNERS_OPEN_FILE_DIALOG: '@owners/OPEN_OWNER_FILE_DIALOG',
};

export default Types;
