const Types = {
  BRANCHES_ACTIVITIES_GET_REQUEST:
    '@branchesActivities/GET_BRANCHES_ACTIVITIES_REQUEST',
  BRANCHES_ACTIVITIES_GET_SUCCESS:
    '@branchesActivities/GET_BRANCHES_ACTIVITIES_SUCCESS',
  BRANCHES_ACTIVITIES_GET_FAILURE:
    '@branchesActivities/GET_BRANCHES_ACTIVITIES_FAILURE',

  BRANCHES_ACTIVITIES_CREATE_REQUEST:
    '@branchesActivities/CREATE_BRANCH_ACTIVITY_REQUEST',
  BRANCHES_ACTIVITIES_CREATE_SUCCESS:
    '@branchesActivities/CREATE_BRANCH_ACTIVITY_SUCCESS',
  BRANCHES_ACTIVITIES_CREATE_FAILURE:
    '@branchesActivities/CREATE_BRANCH_ACTIVITY_FAILURE',

  BRANCHES_ACTIVITIES_UPDATE_REQUEST:
    '@branchesActivities/UPDATE_BRANCH_ACTIVITY_REQUEST',
  BRANCHES_ACTIVITIES_UPDATE_SUCCESS:
    '@branchesActivities/UPDATE_BRANCH_ACTIVITY_SUCCESS',
  BRANCHES_ACTIVITIES_UPDATE_FAILURE:
    '@branchesActivities/UPDATE_BRANCH_ACTIVITY_FAILURE',

  BRANCHES_ACTIVITIES_DELETE_REQUEST:
    '@branchesActivities/DELETE_BRANCH_ACTIVITY_REQUEST',
  BRANCHES_ACTIVITIES_DELETE_SUCCESS:
    '@branchesActivities/DELETE_BRANCH_ACTIVITY_SUCCESS',
  BRANCHES_ACTIVITIES_DELETE_FAILURE:
    '@branchesActivities/DELETE_BRANCH_ACTIVITY_FAILURE',

  BRANCHES_ACTIVITIES_RESET_LIST:
    '@branchesActivities/RESET_LIST_BRANCHES_ACTIVITIES',

  BRANCHES_ACTIVITIES_OPEN_MODAL:
    '@branchesActivities/OPEN_MODAL_BRANCH_ACTIVITY',
  BRANCHES_ACTIVITIES_CLOSE_MODAL:
    '@branchesActivities/CLOSE_MODAL_BRANCH_ACTIVITY',
};

export default Types;
