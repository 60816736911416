const Types = {
  PAYMENT_METHODS_TYPES_GET_REQUEST:
    '@paymentMethodsTypes/GET_PAYMENT_METHODS_TYPES_REQUEST',
  PAYMENT_METHODS_TYPES_GET_SUCCESS:
    '@paymentMethodsTypes/GET_PAYMENT_METHODS_TYPES_SUCCESS',
  PAYMENT_METHODS_TYPES_GET_FAILURE:
    '@paymentMethodsTypes/GET_PAYMENT_METHODS_TYPES_FAILURE',

  PAYMENT_METHODS_TYPES_RESET_LIST:
    '@paymentMethodsTypes/RESET_LIST_PAYMENT_METHODS_TYPES',
};

export default Types;
