import React from 'react';
import { toast } from 'react-toastify';

import { format } from 'date-fns';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import { getDatesBetweenMonth } from '~/utils/getDatesBetweenMonth';
import IntlMessages from '~/utils/IntlMessages';

import {
  getProductsChartFailure,
  getProductsChartSuccess,
  getOrdersSalesChartFailure,
  getOrdersSalesChartSuccess,
} from './actions';
import Types from './types';

export function* getProductsChart({ payload }) {
  try {
    const { filters } = payload;

    const formattedStartDate = format(filters.between_date_start, 'yyyy-MM-dd');
    const formattedEndDate = format(filters.between_date_end, 'yyyy-MM-dd');

    const { data } = yield call(
      api.get,
      `orders-products?between_registered_start=${formattedStartDate}&between_registered_end=${formattedEndDate}&type=["ORDER"]&subtype=["ORDER"]&situation=["OPENED","FINISHED"]`
    );

    yield put(
      getProductsChartSuccess({
        data,
        filters,
      })
    );
  } catch (err) {
    yield put(getProductsChartFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getOrdersSalesCharts({ payload }) {
  try {
    const { filters } = payload;

    const { startDate: firstStartDate, endDate: firstEndDate } =
      getDatesBetweenMonth(filters.first_month_date);

    const firstFormattedStartDate = format(firstStartDate, 'yyyy-MM-dd');
    const firstFormattedEndDate = format(firstEndDate, 'yyyy-MM-dd');

    const { data: firstData } = yield call(
      api.get,
      `order-sale-evolutions?between_registered_start=${firstFormattedStartDate}&between_registered_end=${firstFormattedEndDate}&type=["ORDER"]&subtype=["ORDER"]&situation=["OPENED","FINISHED"]`
    );

    const { startDate: secondStartDate, endDate: secondEndDate } =
      getDatesBetweenMonth(filters.second_month_date);

    const secondFormattedStartDate = format(secondStartDate, 'yyyy-MM-dd');
    const secondFormattedEndDate = format(secondEndDate, 'yyyy-MM-dd');

    const { data: secondData } = yield call(
      api.get,
      `order-sale-evolutions?between_registered_start=${secondFormattedStartDate}&between_registered_end=${secondFormattedEndDate}&type=["ORDER"]&subtype=["ORDER"]&situation=["OPENED","FINISHED"]`
    );

    const data = { firstData, secondData };

    yield put(getOrdersSalesChartSuccess({ data, filters }));
  } catch (err) {
    yield put(getOrdersSalesChartFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([
  takeLatest(Types.DASHBOARD_GET_PRODUCTS_CHART_REQUEST, getProductsChart),
  takeLatest(
    Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_REQUEST,
    getOrdersSalesCharts
  ),
]);
