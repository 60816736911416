import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Menu, MenuItem } from '@material-ui/core';

import userAvatar from '~/assets/img/avatar/avatar-user.png';
import { BusinessIcon } from '~/components/Icons/';
import history from '~/services/history';
import { signOut, clearSelectedCompany } from '~/store/modules/auth/actions';
import IntlMessages from '~/utils/IntlMessages';

import { IconButton } from '../styles';
import { LogOut, User, Switch, Setting, Help, Globe } from './styles';

export default function UserMenu() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const company = useSelector(state => state.auth.company);

  const userProfileAvatar =
    user.files?.length > 0 ? user.files[0].url : userAvatar;

  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = Boolean(anchorMenu);

  function handleSignOut() {
    setAnchorMenu(null);
    dispatch(signOut());
  }

  function handleProfile() {
    setAnchorMenu(null);
    history.push('/profile');
  }

  function handleCompany() {
    setAnchorMenu(null);
    history.push('/company');
  }

  function handleCompanyPublicProfile() {
    setAnchorMenu(null);

    window.open(`${process.env.REACT_APP_FRONT_URL}/profile/${company.slug}`);
  }

  function handleSetting() {
    setAnchorMenu(null);
    history.push('/settings');
  }

  function handleHelp() {
    setAnchorMenu(null);
    history.push('/help');
  }

  function handleSwitchCompany() {
    setAnchorMenu(null);
    dispatch(clearSelectedCompany());
  }

  return (
    <>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={event => setAnchorMenu(event.currentTarget)}
      >
        <Avatar
          alt={formatMessage({ id: 'header.user-avatar' })}
          src={userProfileAvatar}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={open}
        onClose={() => setAnchorMenu(null)}
      >
        <MenuItem onClick={handleProfile}>
          <User />
          <IntlMessages id="header.profile" />
        </MenuItem>
        <MenuItem onClick={handleCompany}>
          <BusinessIcon type="menu" />
          <IntlMessages id="header.company" />
        </MenuItem>

        {company.slug && (
          <MenuItem onClick={handleCompanyPublicProfile}>
            <Globe />
            <IntlMessages id="header.company-public-profile" />
          </MenuItem>
        )}

        <MenuItem onClick={handleSwitchCompany}>
          <Switch />
          <IntlMessages id="header.switch-company" />
        </MenuItem>
        <MenuItem onClick={handleSetting}>
          <Setting />
          <IntlMessages id="header.settings" />
        </MenuItem>
        <MenuItem onClick={handleHelp}>
          <Help />
          <IntlMessages id="header.help" />
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          <LogOut />
          <IntlMessages id="header.sign-out" />
        </MenuItem>
      </Menu>
    </>
  );
}
