import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createOwnerFileSuccess,
  createOwnerFileFailure,
  updateOwnerFileSuccess,
  updateOwnerFileFailure,
  deleteOwnerFileSuccess,
  deleteOwnerFileFailure,
} from './actions';
import Types from './types';

export function* createOwnerFile({ payload }) {
  try {
    const { owner_id, file } = payload;

    const response = yield call(api.post, `owners/${owner_id}/files`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(createOwnerFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createOwnerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOwnerFile({ payload }) {
  try {
    const { owner_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `owners/${owner_id}/files/${file_id}`,
      newFile
    );

    yield put(updateOwnerFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateOwnerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOwnerFile({ payload }) {
  try {
    const { owner_id, file_id } = payload;

    yield call(api.delete, `owners/${owner_id}/files/${file_id}`);

    yield put(deleteOwnerFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteOwnerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.OWNER_FILES_CREATE_REQUEST, createOwnerFile),
  takeLatest(Types.OWNER_FILES_UPDATE_REQUEST, updateOwnerFile),
  takeLatest(Types.OWNER_FILES_DELETE_REQUEST, deleteOwnerFile),
]);
