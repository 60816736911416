import produce from 'immer';

import OrderItemTypes from '../orderItems/types';
import Types from './types';

const INITIAL_STATE = {
  productId: null,
  list: [],
};

export default function orderPriceListItems(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ORDER_PRICE_LIST_ITEMS_GET: {
        const { items, productId } = action.payload;
        draft.list = items;
        draft.productId = productId;
        break;
      }

      case Types.ORDER_PRICE_LIST_ITEMS_GET_FAILURE: {
        draft.list = [];
        draft.productId = null;
        break;
      }

      case Types.ORDER_PRICE_LIST_ITEMS_RESET_LIST: {
        draft.list = [];
        draft.productId = null;
        break;
      }

      case OrderItemTypes.ORDER_ITEMS_CLOSE_MODAL: {
        draft.list = [];
        draft.productId = null;
        break;
      }

      case OrderItemTypes.ORDER_ITEMS_CREATE_SUCCESS: {
        draft.list = [];
        draft.productId = null;
        break;
      }

      case OrderItemTypes.ORDER_ITEMS_UPDATE_SUCCESS: {
        draft.list = [];
        draft.productId = null;
        break;
      }

      default:
    }
  });
}
