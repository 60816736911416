const colors = {
  lightestSuccess: '#a9ffcc',
  lightSuccess: '#00C851',
  success: '#28A745',
  darkSuccess: '#007E33',

  lightInfo: '#33b5e5',
  info: '#17A2B8',
  darkInfo: '#0099CC',
  darkerInfo: '#0078d4',

  lightestWarning: '#ffd796',
  lightWarning: '#FCE21B',
  warning: '#FFC107',
  darkWarning: '#FF8800',

  lightestError: '#fc9081',
  lightError: '#ff4444',
  error: '#DC3545',
  darkError: '#CC0000',

  lightDefault: '#B2DFDB',
  default: '#2BBBAD',
  darkDefault: '#00695c',

  lightPrimary: '#64B5F6',
  primary: '#4285F4',
  darkPrimary: '#0d47a1',

  lightSecondary: '#CE93D8',
  secondary: '#aa66cc',
  darkSecondary: '#9933CC',

  lightTertiary: '#F48FB1',
  tertiary: '#EC407A',
  darkTertiary: '#D81B60',

  white: '#FFF',
  lightest: '#fafafa',
  lighter: '#f7f9fc',
  light: '#f5f5f5',

  lightestRegular: '#f0efef',
  lighterRegular: '#DDD',
  lightRegular: '#bfbfbf',
  regular: '#999',
  darkRegular: '#757575',
  darkerRegular: '#666',
  darkestRegular: '#777',

  lightDark: '#444',
  dark: '#333',
  darker: '#222',
  darkest: '#111',
  black: '#000',

  transparent: 'transparent',

  lightestTransparent: 'rgba(255, 255, 255, 0.8)',
  lighterTransparent: 'rgba(255, 255, 255, 0.6)',
  lightTransparent: 'rgba(255, 255, 255, 0.4)',

  lighterRegularTransparent: 'rgba(255, 255, 255, 0.2)',
  lightRegularTransparent: 'rgba(122, 122, 122, 0.6)',
  regularTransparent: 'rgba(122, 122, 122, 0.4)',
  darkRegularTransparent: 'rgba(122, 122, 122, 0.2)',
  darkerRegularTransparent: 'rgba(0, 0, 0, 0.2)',

  darkTransparent: 'rgba(0, 0, 0, 0.4)',
  darkerTransparent: 'rgba(0, 0, 0, 0.6)',
  darkestTransparent: 'rgba(0, 0, 0, 0.8)',
};

export default colors;
