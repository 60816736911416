import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  currentTheme: 0,
};

/* TODO: Excluir este reducer e colocar junto com as informacoes do usuario ou grupo de usuario */
export default function theme(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_THEME: {
        draft.currentTheme = action.payload.value;
        break;
      }

      default:
    }
  });
}
