import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getBankBillingsSuccess,
  getBankBillingsFailure,
  deleteBankBillingSuccess,
  deleteBankBillingFailure,
} from './actions';
import Types from './types';

export function* getBankBillings({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `pixs${queryParams}`);

    yield put(
      getBankBillingsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getBankBillingsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* deleteBankBilling({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `pixs/${id}`);

    yield put(deleteBankBillingSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBankBillingFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.BANK_BILLINGS_GET_REQUEST, getBankBillings),
  takeLatest(Types.BANK_BILLINGS_DELETE_REQUEST, deleteBankBilling),
]);
