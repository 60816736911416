import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { resetFoundPerson } from '../persons/actions';
import {
  getTransporterAddresses,
  openModalTransporterAddress,
  resetListTransporterAddress,
} from '../transporterAddresses/actions';
import {
  getTransporterEmails,
  resetListTransporterEmail,
  openModalTransporterEmail,
} from '../transporterEmails/actions';
import {
  getTransporterFiles,
  resetListTransporterFile,
} from '../transporterFiles/actions';
import {
  getTransporterPhones,
  openModalTransporterPhone,
  resetListTransporterPhone,
} from '../transporterPhones/actions';
import {
  getTransportersSuccess,
  getTransportersFailure,
  createTransporterSuccess,
  createTransporterFailure,
  deleteTransporterSuccess,
  deleteTransporterFailure,
  becomeTransporterSuccess,
  becomeTransporterFailure,
  getDetailsTransporterSuccess,
  updateTransporterSuccess,
  updateTransporterFailure,
  getDetailsTransporterFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getTransporters({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `transporters${queryParams}`);

    yield put(
      getTransportersSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getTransportersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createTransporter({ payload }) {
  try {
    const { transporter, subResourceType } = payload;

    const response = yield call(api.post, 'transporters', transporter);

    yield put(createTransporterSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/transporter/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createTransporterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateTransporter({ payload }) {
  try {
    const { id, newTransporter } = payload;

    const response = yield call(api.put, `transporters/${id}`, newTransporter);

    yield put(updateTransporterSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateTransporterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteTransporter({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `transporters/${id}`);

    yield put(deleteTransporterSuccess(id));

    history.push('/transporters');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteTransporterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeTransporter({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'TRANSPORTER';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeTransporterSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/transporter/${id}`);
  } catch (err) {
    yield put(becomeTransporterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsTransporter({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `transporters/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getTransporterPhones(phones));

    yield put(getTransporterAddresses(addresses));

    yield put(getTransporterEmails(emails));

    yield put(getTransporterFiles(files));

    yield put(getDetailsTransporterSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.transporters
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalTransporterPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalTransporterEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalTransporterAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsTransporterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsTransporter() {
  yield put(resetListTransporterPhone());
  yield put(resetListTransporterAddress());
  yield put(resetListTransporterEmail());
  yield put(resetListTransporterFile());
}

export default all([
  takeLatest(Types.TRANSPORTERS_GET_REQUEST, getTransporters),
  takeLatest(Types.TRANSPORTERS_CREATE_REQUEST, createTransporter),
  takeLatest(Types.TRANSPORTERS_UPDATE_REQUEST, updateTransporter),
  takeLatest(Types.TRANSPORTERS_DELETE_REQUEST, deleteTransporter),
  takeLatest(Types.TRANSPORTERS_BECOME_REQUEST, becomeTransporter),
  takeLatest(Types.TRANSPORTERS_GET_DETAILS_REQUEST, getDetailsTransporter),
  takeLatest(Types.TRANSPORTERS_RESET_DETAILS, resetDetailsTransporter),
]);
