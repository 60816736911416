import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSupplierEmailSuccess,
  createSupplierEmailFailure,
  updateSupplierEmailSuccess,
  updateSupplierEmailFailure,
  deleteSupplierEmailSuccess,
  deleteSupplierEmailFailure,
} from './actions';
import Types from './types';

export function* createSupplierEmail({ payload }) {
  try {
    const { supplier_id, email } = payload;

    const response = yield call(
      api.post,
      `suppliers/${supplier_id}/emails`,
      email
    );

    yield put(createSupplierEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSupplierEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupplierEmail({ payload }) {
  try {
    const { supplier_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `suppliers/${supplier_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateSupplierEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupplierEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupplierEmail({ payload }) {
  const { supplier_id, email_id } = payload;

  const emails = yield select(state => state.supplierEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteSupplierEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `suppliers/${supplier_id}/emails/${email_id}`);

    yield put(deleteSupplierEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupplierEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.SUPPLIER_EMAILS_CREATE_REQUEST, createSupplierEmail),
  takeLatest(Types.SUPPLIER_EMAILS_UPDATE_REQUEST, updateSupplierEmail),
  takeLatest(Types.SUPPLIER_EMAILS_DELETE_REQUEST, deleteSupplierEmail),
]);
