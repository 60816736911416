import styled from 'styled-components';

import colors from '~/theme/colors';

export const ChipContainer = styled.div`
  display: inline-block;
  margin: 10px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${colors.lighter};
  box-shadow: 0px 1px 3px ${colors.lighterRegularTransparent};
`;
