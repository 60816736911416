import Types from './types';

export function getPromotersRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.PROMOTERS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getPromotersSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.PROMOTERS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getPromotersFailure() {
  return {
    type: Types.PROMOTERS_GET_FAILURE,
  };
}

export function createPromoterRequest(promoter, subResourceType = null) {
  return {
    type: Types.PROMOTERS_CREATE_REQUEST,
    payload: { promoter, subResourceType },
  };
}

export function createPromoterSuccess(promoter) {
  return {
    type: Types.PROMOTERS_CREATE_SUCCESS,
    payload: { promoter },
  };
}

export function createPromoterFailure() {
  return {
    type: Types.PROMOTERS_CREATE_FAILURE,
  };
}

export function updatePromoterRequest(id, newPromoter) {
  return {
    type: Types.PROMOTERS_UPDATE_REQUEST,
    payload: { id, newPromoter },
  };
}

export function updatePromoterSuccess(newPromoter) {
  return {
    type: Types.PROMOTERS_UPDATE_SUCCESS,
    payload: { newPromoter },
  };
}

export function updatePromoterFailure() {
  return {
    type: Types.PROMOTERS_UPDATE_FAILURE,
  };
}

export function deletePromoterRequest(id) {
  return {
    type: Types.PROMOTERS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deletePromoterSuccess(id) {
  return {
    type: Types.PROMOTERS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deletePromoterFailure() {
  return {
    type: Types.PROMOTERS_DELETE_FAILURE,
  };
}

export function becomePromoterRequest(id) {
  return {
    type: Types.PROMOTERS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomePromoterSuccess(promoter) {
  return {
    type: Types.PROMOTERS_BECOME_SUCCESS,
    payload: { promoter },
  };
}

export function becomePromoterFailure() {
  return {
    type: Types.PROMOTERS_BECOME_FAILURE,
  };
}

export function getDetailsPromoterRequest(id) {
  return {
    type: Types.PROMOTERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsPromoterSuccess(promoter) {
  return {
    type: Types.PROMOTERS_GET_DETAILS_SUCCESS,
    payload: { promoter },
  };
}

export function getDetailsPromoterFailure() {
  return {
    type: Types.PROMOTERS_GET_DETAILS_FAILURE,
  };
}

export function resetListPromoters() {
  return {
    type: Types.PROMOTERS_RESET_LIST,
  };
}

export function resetDetailsPromoter() {
  return {
    type: Types.PROMOTERS_RESET_DETAILS,
  };
}

export function openModalAddPromoter() {
  return {
    type: Types.PROMOTERS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddPromoter() {
  return {
    type: Types.PROMOTERS_ADD_CLOSE_MODAL,
  };
}

export function openModalPromoterObservation() {
  return {
    type: Types.PROMOTERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalPromoterObservation() {
  return {
    type: Types.PROMOTERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalPromoterFilter() {
  return {
    type: Types.PROMOTERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalPromoterFilter() {
  return {
    type: Types.PROMOTERS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.PROMOTERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.PROMOTERS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.PROMOTERS_RESET_SUB_RESOURCE_DATA,
  };
}
