import produce from 'immer';

import AccountTypes from '../accounts/types';
import BankAccountPixTypes from '../bankAccountPixs/types';
import CompanyTypes from '../companies/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  bankAccountPixFileModalOpen: false,
};

export default function files(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BANK_ACCOUNT_PIX_FILES_GET: {
        const { files: fileData } = action.payload;

        draft.list = fileData;
        break;
      }

      case Types.BANK_ACCOUNT_PIX_FILES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.file);

        draft.detail = action.payload.file;
        draft.loading = false;
        draft.bankAccountPixFileModalOpen = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIX_FILES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_UPDATE_SUCCESS: {
        const { newFile } = action.payload;

        draft.list = state.list.map(file =>
          file.id === newFile.id ? newFile : file
        );

        draft.detail = newFile;
        draft.loading = false;
        draft.bankAccountPixFileModalOpen = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIX_FILES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_DELETE_SUCCESS: {
        const { file_id } = action.payload;

        draft.list = state.list.filter(bankAccountPixFile => {
          return bankAccountPixFile.id !== file_id;
        });

        draft.detail = {};
        draft.loading = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIX_FILES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.BANK_ACCOUNT_PIX_FILES_OPEN_MODAL: {
        draft.bankAccountPixFileModalOpen = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIX_FILES_CLOSE_MODAL: {
        draft.bankAccountPixFileModalOpen = false;
        break;
      }

      case BankAccountPixTypes.BANK_ACCOUNT_PIXS_OPEN_MODAL: {
        const bankAccountPixId = action.payload?.bank_account_pix_id ?? null;

        draft.detail = {};

        const filterPixType =
          bankAccountPixId &&
          state.list.find(file => file.type === 'image/qrcode' && file.is_main);

        draft.detail = filterPixType ?? {};

        break;
      }

      case AccountTypes.ACCOUNTS_RESET_DETAILS: {
        draft.bankAccountPixFileModalOpen = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      case BankAccountPixTypes.BANK_ACCOUNT_PIXS_CLOSE_MODAL: {
        draft.detail = {};

        break;
      }

      case BankAccountPixTypes.BANK_ACCOUNT_PIXS_DELETE_SUCCESS: {
        draft.detail = {};
        draft.list = [];
        break;
      }

      case CompanyTypes.COMPANIES_RESET_DETAILS: {
        draft.bankAccountPixFileModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
