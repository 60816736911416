const Types = {
  TRANSPORTER_PHONES_GET: '@transporters/GET_TRANSPORTER_PHONES',

  TRANSPORTER_PHONES_CREATE_REQUEST:
    '@transporters/CREATE_TRANSPORTER_PHONE_REQUEST',
  TRANSPORTER_PHONES_CREATE_SUCCESS:
    '@transporters/CREATE_TRANSPORTER_PHONE_SUCCESS',
  TRANSPORTER_PHONES_CREATE_FAILURE:
    '@transporters/CREATE_TRANSPORTER_PHONE_FAILURE',

  TRANSPORTER_PHONES_UPDATE_REQUEST:
    '@transporters/UPDATE_TRANSPORTER_PHONE_REQUEST',
  TRANSPORTER_PHONES_UPDATE_SUCCESS:
    '@transporters/UPDATE_TRANSPORTER_PHONE_SUCCESS',
  TRANSPORTER_PHONES_UPDATE_FAILURE:
    '@transporters/UPDATE_TRANSPORTER_PHONE_FAILURE',

  TRANSPORTER_PHONES_DELETE_REQUEST:
    '@transporters/DELETE_TRANSPORTER_PHONE_REQUEST',
  TRANSPORTER_PHONES_DELETE_SUCCESS:
    '@transporters/DELETE_TRANSPORTER_PHONE_SUCCESS',
  TRANSPORTER_PHONES_DELETE_FAILURE:
    '@transporters/DELETE_TRANSPORTER_PHONE_FAILURE',

  TRANSPORTER_PHONES_RESET_LIST: '@transporters/RESET_LIST_TRANSPORTER_PHONE',

  TRANSPORTER_PHONES_OPEN_MODAL: '@transporters/OPEN_MODAL_TRANSPORTER_PHONE',
  TRANSPORTER_PHONES_CLOSE_MODAL: '@transporters/CLOSE_MODAL_TRANSPORTER_PHONE',
};

export default Types;
