import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createPromoterFileSuccess,
  createPromoterFileFailure,
  updatePromoterFileSuccess,
  updatePromoterFileFailure,
  deletePromoterFileSuccess,
  deletePromoterFileFailure,
} from './actions';
import Types from './types';

export function* createPromoterFile({ payload }) {
  try {
    const { promoter_id, file } = payload;

    const response = yield call(
      api.post,
      `promoters/${promoter_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createPromoterFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createPromoterFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePromoterFile({ payload }) {
  try {
    const { promoter_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `promoters/${promoter_id}/files/${file_id}`,
      newFile
    );

    yield put(updatePromoterFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updatePromoterFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePromoterFile({ payload }) {
  try {
    const { promoter_id, file_id } = payload;

    yield call(api.delete, `promoters/${promoter_id}/files/${file_id}`);

    yield put(deletePromoterFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePromoterFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.PROMOTER_FILES_CREATE_REQUEST, createPromoterFile),
  takeLatest(Types.PROMOTER_FILES_UPDATE_REQUEST, updatePromoterFile),
  takeLatest(Types.PROMOTER_FILES_DELETE_REQUEST, deletePromoterFile),
]);
