import Types from './types';

export function getCategoriesRequest() {
  return {
    type: Types.CATEGORIES_GET_REQUEST,
  };
}

export function getCategoriesSuccess(categories) {
  return {
    type: Types.CATEGORIES_GET_SUCCESS,
    payload: { categories },
  };
}

export function getCategoriesFailure() {
  return {
    type: Types.CATEGORIES_GET_FAILURE,
  };
}

export function createCategoryRequest(category) {
  return {
    type: Types.CATEGORIES_CREATE_REQUEST,
    payload: { category },
  };
}

export function createCategorySuccess(category) {
  return {
    type: Types.CATEGORIES_CREATE_SUCCESS,
    payload: { category },
  };
}

export function createCategoryFailure() {
  return {
    type: Types.CATEGORIES_CREATE_FAILURE,
  };
}

export function updateCategoryRequest(id, newCategory) {
  return {
    type: Types.CATEGORIES_UPDATE_REQUEST,
    payload: { id, newCategory },
  };
}

export function updateCategorySuccess(newCategory) {
  return {
    type: Types.CATEGORIES_UPDATE_SUCCESS,
    payload: { newCategory },
  };
}

export function updateCategoryFailure() {
  return {
    type: Types.CATEGORIES_UPDATE_FAILURE,
  };
}

export function deleteCategoryRequest(id) {
  return {
    type: Types.CATEGORIES_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteCategorySuccess(id) {
  return {
    type: Types.CATEGORIES_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteCategoryFailure() {
  return {
    type: Types.CATEGORIES_DELETE_FAILURE,
  };
}

export function resetListCategories() {
  return {
    type: Types.CATEGORIES_RESET_LIST,
  };
}

export function openModalCategory({ id, newRegister }) {
  return {
    type: Types.CATEGORIES_OPEN_MODAL,
    payload: { id, newRegister },
  };
}

export function closeModalCategory() {
  return {
    type: Types.CATEGORIES_CLOSE_MODAL,
  };
}
