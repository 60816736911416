import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import { isNetworkError } from '~/utils/checkNetwork';
import IntlMessages from '~/utils/IntlMessages';

import {
  setGlobalListSettingSuccess,
  setGlobalListSettingFailure,
  resetGlobalList,
} from '../settings/actions';
import GlobalTypes from '../types';
import {
  signInSuccess,
  signInFailure,
  signUpSuccess,
  signUpFailure,
  signOutTokenInvalid,
} from './actions';
import Types from './types';

export function* signIn({ payload }) {
  try {
    const { login, password: unencrypted_password } = payload;

    const response = yield call(api.post, 'sessions?type=WEB', {
      login,
      unencrypted_password,
    });

    const { token, user, companies, refresh_token } = response.data;

    if (
      companies.length === 0 &&
      user.username !== 'admin' &&
      user.username !== 'suporte' &&
      user.username !== 'replicador'
    ) {
      yield put(signInFailure());

      toast.error(<IntlMessages id="sign-in.company-failed" />);

      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user, companies, refresh_token));

    if (
      companies.length === 1 &&
      user.username !== 'admin' &&
      user.username !== 'suporte' &&
      user.username !== 'replicador'
    ) {
      try {
        const { data } = yield call(api.get, 'settings?is_enabled=true');

        yield put(setGlobalListSettingSuccess(data));
      } catch (err) {
        toast.error(<IntlMessages id="settings.get-settings-failure" />);
      }
    }
  } catch (err) {
    yield put(signInFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sign-in.authentication-failed" />);
  }
}

export function* signUp({ payload }) {
  try {
    const { name, username, email, password: unencrypted_password } = payload;

    yield call(api.post, 'users', {
      name,
      username,
      email,
      unencrypted_password,
    });

    yield put(signUpSuccess());

    toast.success(<IntlMessages id="sign-up.register-success" />);
  } catch (err) {
    yield put(signUpFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sign-up.register-failed" />);
  }
}

export function* checkToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    try {
      yield call(api.get, 'sessions/validation');

      try {
        const { data } = yield call(api.get, 'settings?is_enabled=true');

        yield put(setGlobalListSettingSuccess(data));
      } catch (err) {
        toast.error(<IntlMessages id="settings.get-settings-failure" />);
      }
    } catch (err) {
      if (!isNetworkError(err)) {
        yield put(signOutTokenInvalid());
      }
    }
  }
}

export function* getSelectedCompanySettings({ payload }) {
  if (!payload.company.id) return;

  try {
    const { data } = yield call(api.get, 'settings?is_enabled=true');

    yield put(setGlobalListSettingSuccess(data));
  } catch (err) {
    yield put(setGlobalListSettingFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="settings.get-settings-failure" />);
  }
}

export function* signOut() {
  yield put(resetGlobalList());
  yield localStorage.removeItem('persist:smartbusiness');
}

export default all([
  takeLatest(GlobalTypes.PERSIST_REHYDRATE, checkToken),
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_UP_REQUEST, signUp),
  takeLatest(Types.COMPANY_SET_SELECTED, getSelectedCompanySettings),
  takeLatest(Types.SIGN_OUT, signOut),
]);
