import styled from 'styled-components';

import { AddBox as MuiAddBox } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type, disabled) {
  let styles;

  switch (type) {
    case 'table':
      styles = `
        color: ${colors.darkRegular};
        width: 27px;
        height: 27px;
        padding: 3px;
      `;
      break;
    case 'inventory-panel':
      styles = `
        color: ${disabled ? colors.regular : colors.darkRegular};
      `;
      break;
    case 'info':
      styles = ``;
      break;
    case 'panel':
      styles = ``;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const AddBox = styled(MuiAddBox)`
  ${({ type, disabled }) => defineStyles(type, disabled)}
`;
