const Types = {
  TEAM_MEMBERS_GET: '@team/GET_TEAM_MEMBERS',

  TEAM_MEMBERS_UPDATE_REQUEST: '@team/UPDATE_TEAM_MEMBER_REQUEST',
  TEAM_MEMBERS_UPDATE_SUCCESS: '@team/UPDATE_TEAM_MEMBER_SUCCESS',
  TEAM_MEMBERS_UPDATE_FAILURE: '@team/UPDATE_TEAM_MEMBER_FAILURE',

  TEAM_MEMBERS_DELETE_REQUEST: '@team/DELETE_TEAM_MEMBER_REQUEST',
  TEAM_MEMBERS_DELETE_SUCCESS: '@team/DELETE_TEAM_MEMBER_SUCCESS',
  TEAM_MEMBERS_DELETE_FAILURE: '@team/DELETE_TEAM_MEMBER_FAILURE',

  TEAM_MEMBERS_RESET_LIST: '@team/RESET_LIST_TEAM_MEMBER',

  TEAM_MEMBERS_OPEN_MODAL: '@team/OPEN_MODAL_TEAM_MEMBER',
  TEAM_MEMBERS_CLOSE_MODAL: '@team/CLOSE_MODAL_TEAM_MEMBER',
};

export default Types;
