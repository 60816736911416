import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createRuralPropertyPhoneSuccess,
  createRuralPropertyPhoneFailure,
  updateRuralPropertyPhoneSuccess,
  updateRuralPropertyPhoneFailure,
  deleteRuralPropertyPhoneSuccess,
  deleteRuralPropertyPhoneFailure,
} from './actions';
import Types from './types';

export function* createRuralPropertyPhone({ payload }) {
  try {
    const { property_id, phone } = payload;

    const response = yield call(
      api.post,
      `rural-properties/${property_id}/phones`,
      phone
    );

    yield put(createRuralPropertyPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRuralPropertyPhone({ payload }) {
  try {
    const { property_id, phone_id, newPhone } = payload;

    const response = yield call(
      api.put,
      `rural-properties/${property_id}/phones/${phone_id}`,
      newPhone
    );

    yield put(updateRuralPropertyPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRuralPropertyPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteRuralPropertyPhone({ payload }) {
  const { property_id, phone_id } = payload;

  const phones = yield select(state => state.ruralPropertyPhones.list);

  const phone = phones.find(data => data.id === phone_id);

  if (phone.is_main && phones.length > 1) {
    yield put(deleteRuralPropertyPhoneFailure());

    toast.error(<IntlMessages id="phones.error-delete-is-main" />);

    return;
  }

  try {
    yield call(
      api.delete,
      `rural-properties/${property_id}/phones/${phone_id}`
    );

    yield put(deleteRuralPropertyPhoneSuccess(phone_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRuralPropertyPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.RURAL_PROPERTY_PHONES_CREATE_REQUEST,
    createRuralPropertyPhone
  ),
  takeLatest(
    Types.RURAL_PROPERTY_PHONES_UPDATE_REQUEST,
    updateRuralPropertyPhone
  ),
  takeLatest(
    Types.RURAL_PROPERTY_PHONES_DELETE_REQUEST,
    deleteRuralPropertyPhone
  ),
]);
