const Types = {
  BILL_TO_PAY_COST_CENTERS_GET: '@billToPay/GET_BILL_TO_PAY_COST_CENTERS',

  BILL_TO_PAY_COST_CENTERS_CREATE_REQUEST:
    '@billToPay/CREATE_BILL_TO_PAY_COST_CENTER_REQUEST',
  BILL_TO_PAY_COST_CENTERS_CREATE_SUCCESS:
    '@billToPay/CREATE_BILL_TO_PAY_COST_CENTER_SUCCESS',
  BILL_TO_PAY_COST_CENTERS_CREATE_FAILURE:
    '@billToPay/CREATE_BILL_TO_PAY_COST_CENTER_FAILURE',

  BILL_TO_PAY_COST_CENTERS_UPDATE_REQUEST:
    '@billToPay/UPDATE_BILL_TO_PAY_COST_CENTER_REQUEST',
  BILL_TO_PAY_COST_CENTERS_UPDATE_SUCCESS:
    '@billToPay/UPDATE_BILL_TO_PAY_COST_CENTER_SUCCESS',
  BILL_TO_PAY_COST_CENTERS_UPDATE_FAILURE:
    '@billToPay/UPDATE_BILL_TO_PAY_COST_CENTER_FAILURE',

  BILL_TO_PAY_COST_CENTERS_DELETE_REQUEST:
    '@billToPay/DELETE_BILL_TO_PAY_COST_CENTER_REQUEST',
  BILL_TO_PAY_COST_CENTERS_DELETE_SUCCESS:
    '@billToPay/DELETE_BILL_TO_PAY_COST_CENTER_SUCCESS',
  BILL_TO_PAY_COST_CENTERS_DELETE_FAILURE:
    '@billToPay/DELETE_BILL_TO_PAY_COST_CENTER_FAILURE',

  BILL_TO_PAY_COST_CENTERS_RESET_LIST:
    '@billToPay/RESET_LIST_BILL_TO_PAY_COST_CENTER',

  BILL_TO_PAY_COST_CENTERS_OPEN_MODAL:
    '@billToPay/OPEN_MODAL_BILL_TO_PAY_COST_CENTER',
  BILL_TO_PAY_COST_CENTERS_CLOSE_MODAL:
    '@billToPay/CLOSE_MODAL_BILL_TO_PAY_COST_CENTER',
};

export default Types;
