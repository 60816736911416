import produce from 'immer';

import AccountTypes from '../accounts/types';
import BankAccountPixFilesTypes from '../bankAccountPixFiles/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,
  bankAccountPixModalOpen: false,
  openFileDialog: false,
  bankAccountPixCompanyPixModalOpen: false,
};

export default function bankAccountPixs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BANK_ACCOUNT_PIXS_GET: {
        draft.list = action.payload.bankAccountPixs;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_CREATE_SUCCESS: {
        const { bankAccountPix } = action.payload;

        draft.list.unshift(bankAccountPix);
        draft.detail = bankAccountPix;
        draft.loading = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_UPDATE_SUCCESS: {
        const { newBankAccountPix } = action.payload;

        draft.list = state.list.map(bankAccountPix => {
          return bankAccountPix.id === newBankAccountPix.id
            ? newBankAccountPix
            : bankAccountPix;
        });

        draft.detail = newBankAccountPix;

        draft.loading = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_DELETE_SUCCESS: {
        draft.list = state.list.filter(bankAccountPix => {
          return bankAccountPix.id !== action.payload.bank_account_pix_id;
        });

        draft.loading = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.bankAccountPix;
        draft.requestError = false;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_OPEN_MODAL: {
        const { bank_account_pix_id } = action.payload;

        draft.detail = {};

        if (bank_account_pix_id) {
          [draft.detail] = draft.list.filter(
            bankAccountPix => bankAccountPix.id === bank_account_pix_id
          );
        }
        draft.bankAccountPixModalOpen = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_CLOSE_MODAL: {
        draft.bankAccountPixModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_COMPANY_PIX_OPEN_MODAL: {
        draft.bankAccountPixCompanyPixModalOpen = true;
        break;
      }
      case Types.BANK_ACCOUNT_PIXS_COMPANY_PIX_CLOSE_MODAL: {
        draft.bankAccountPixCompanyPixModalOpen = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_RESET_DETAILS: {
        draft.detail = {};
        draft.openFileDialog = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_RESET_LIST: {
        draft.list = [];
        draft.loading = false;
        draft.requestError = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_RESET_SUB_RESOURCE_DATA: {
        draft.openFileDialog = false;
        break;
      }

      case Types.BANK_ACCOUNT_PIXS_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case AccountTypes.ACCOUNTS_RESET_DETAILS: {
        draft.bankAccountPixModalOpen = false;
        draft.detail = {};
        draft.openFileDialog = false;
        draft.bankAccountPixCompanyPixModalOpen = false;
        break;
      }

      case BankAccountPixFilesTypes.BANK_ACCOUNT_PIX_FILES_CREATE_SUCCESS: {
        draft.detail.static_pix_status = 'ACTIVE';

        const changeListStatus = state.list.map(item => {
          if (item.id === action.payload.bank_account_pix_id) {
            const newItem = { ...item, static_pix_status: 'ACTIVE' };

            return newItem;
          }

          return item;
        });

        draft.list = changeListStatus;
        break;
      }

      case BankAccountPixFilesTypes.BANK_ACCOUNT_PIX_FILES_DELETE_SUCCESS: {
        draft.detail.static_pix_status = 'INACTIVE';

        const changeListStatus = state.list.map(item => {
          if (item.id === action.payload.bank_account_pix_id) {
            const newItem = { ...item, static_pix_status: 'INACTIVE' };

            return newItem;
          }

          return item;
        });

        draft.list = changeListStatus;
        break;
      }

      default:
    }
  });
}
