import styled from 'styled-components';

import { Business as MuiBusiness } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'menu':
      styles = `
        color: ${colors.darkRegular};
        margin-right: 10px;
      `;
      break;
    case 'info':
      styles = ``;
      break;
    case 'avatar-large':
      styles = `
        width: 80px;
        height: 80px;
      `;
      break;
    case 'avatar-small':
      styles = `
        width: 35px;
        height: 35px;
      `;
      break;
    case 'avatar-smaller':
      styles = `
        width: 30px;
        height: 30px;
      `;
      break;
    case 'avatar-medium':
      styles = `
        width: 60px;
        height: 60px
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Business = styled(MuiBusiness)`
  ${({ type }) => defineStyles(type)}
`;
