const Types = {
  BANK_BILLING_PIXS_CREATE_REQUEST:
    '@bankBilling/CREATE_BANK_BILLING_PIX_REQUEST',
  BANK_BILLING_PIXS_CREATE_SUCCESS:
    '@bankBilling/CREATE_BANK_BILLING_PIX_SUCCESS',
  BANK_BILLING_PIXS_CREATE_FAILURE:
    '@bankBilling/CREATE_BANK_BILLING_PIX_FAILURE',

  BANK_BILLING_PIXS_UPDATE_REQUEST:
    '@bankBilling/UPDATE_BANK_BILLING_PIX_REQUEST',
  BANK_BILLING_PIXS_UPDATE_SUCCESS:
    '@bankBilling/UPDATE_BANK_BILLING_PIX_SUCCESS',
  BANK_BILLING_PIXS_UPDATE_FAILURE:
    '@bankBilling/UPDATE_BANK_BILLING_PIX_FAILURE',

  BANK_BILLING_PIXS_SETTLEMENT_REQUEST:
    '@bankBilling/SETTLEMENT_BANK_BILLING_PIX_REQUEST',
  BANK_BILLING_PIXS_SETTLEMENT_SUCCESS:
    '@bankBilling/SETTLEMENT_BANK_BILLING_PIX_SUCCESS',
  BANK_BILLING_PIXS_SETTLEMENT_FAILURE:
    '@bankBilling/SETTLEMENT_BANK_BILLING_PIX_FAILURE',

  BANK_BILLING_PIXS_RESET_LIST: '@bankBilling/RESET_LIST_BANK_BILLING_PIX',

  BANK_BILLING_PIXS_OPEN_MODAL: '@bankBilling/OPEN_MODAL_BANK_BILLING_PIX',
  BANK_BILLING_PIXS_CLOSE_MODAL: '@bankBilling/CLOSE_MODAL_BANK_BILLING_PIX',

  BANK_BILLING_PIXS_EMIT_OPEN_MODAL:
    '@bankBilling/OPEN_MODAL_BANK_BILLING_PIX_EMIT',
  BANK_BILLING_PIXS_EMIT_CLOSE_MODAL:
    '@bankBilling/CLOSE_MODAL_BANK_BILLING_PIX_EMIT',

  BANK_BILLING_PIXS_ADD_ACCOUNT_OPEN_MODAL:
    '@bankBilling/OPEN_MODAL_BANK_BILLING_PIX_ADD_ACCOUNT',
  BANK_BILLING_PIXS_ADD_ACCOUNT_CLOSE_MODAL:
    '@bankBilling/CLOSE_MODAL_BANK_BILLING_PIX_ADD_ACCOUNT',

  BANK_BILLING_PIXS_SET_SELECTED_ACCOUNT:
    '@bankBilling/SET_SELECTED_ACCOUNT_BANK_BILLING_PIX',

  BANK_BILLING_PIXS_DETAIL_OPEN_MODAL:
    '@bankBilling/OPEN_MODAL_BANK_BILLING_PIX_DETAIL',
  BANK_BILLING_PIXS_DETAIL_CLOSE_MODAL:
    '@bankBilling/CLOSE_MODAL_BANK_BILLING_PIX_DETAIL',

  BANK_BILLING_PIXS_SHARE_OPEN_MODAL:
    '@bankBilling/OPEN_MODAL_BANK_BILLING_PIX_SHARE',
  BANK_BILLING_PIXS_SHARE_CLOSE_MODAL:
    '@bankBilling/CLOSE_MODAL_BANK_BILLING_PIX_SHARE',

  BANK_BILLING_PIXS_SETTLEMENT_OPEN_MODAL:
    '@bankBilling/OPEN_MODAL_BANK_BILLING_PIX_SETTLEMENT',
  BANK_BILLING_PIXS_SETTLEMENT_CLOSE_MODAL:
    '@bankBilling/CLOSE_MODAL_BANK_BILLING_PIX_SETTLEMENT',
};

export default Types;
