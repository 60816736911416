const Types = {
  SUPERVISORS_GET_REQUEST: '@supervisors/GET_SUPERVISORS_REQUEST',
  SUPERVISORS_GET_SUCCESS: '@supervisors/GET_SUPERVISORS_SUCCESS',
  SUPERVISORS_GET_FAILURE: '@supervisors/GET_SUPERVISORS_FAILURE',

  SUPERVISORS_CREATE_REQUEST: '@supervisors/CREATE_SUPERVISOR_REQUEST',
  SUPERVISORS_CREATE_SUCCESS: '@supervisors/CREATE_SUPERVISOR_SUCCESS',
  SUPERVISORS_CREATE_FAILURE: '@supervisors/CREATE_SUPERVISOR_FAILURE',

  SUPERVISORS_UPDATE_REQUEST: '@supervisors/UPDATE_SUPERVISOR_REQUEST',
  SUPERVISORS_UPDATE_SUCCESS: '@supervisors/UPDATE_SUPERVISOR_SUCCESS',
  SUPERVISORS_UPDATE_FAILURE: '@supervisors/UPDATE_SUPERVISOR_FAILURE',

  SUPERVISORS_DELETE_REQUEST: '@supervisors/DELETE_SUPERVISOR_REQUEST',
  SUPERVISORS_DELETE_SUCCESS: '@supervisors/DELETE_SUPERVISOR_SUCCESS',
  SUPERVISORS_DELETE_FAILURE: '@supervisors/DELETE_SUPERVISOR_FAILURE',

  SUPERVISORS_BECOME_REQUEST: '@supervisors/BECOME_SUPERVISOR_REQUEST',
  SUPERVISORS_BECOME_SUCCESS: '@supervisors/BECOME_SUPERVISOR_SUCCESS',
  SUPERVISORS_BECOME_FAILURE: '@supervisors/BECOME_SUPERVISOR_FAILURE',

  SUPERVISORS_GET_DETAILS_REQUEST:
    '@supervisors/GET_DETAILS_SUPERVISOR_REQUEST',
  SUPERVISORS_GET_DETAILS_SUCCESS:
    '@supervisors/GET_DETAILS_SUPERVISOR_SUCCESS',
  SUPERVISORS_GET_DETAILS_FAILURE:
    '@supervisors/GET_DETAILS_SUPERVISOR_FAILURE',

  SUPERVISORS_RESET_LIST: '@supervisors/RESET_LIST_SUPERVISORS',
  SUPERVISORS_RESET_DETAILS: '@supervisors/RESET_DETAILS_SUPERVISOR',

  SUPERVISORS_SET_SAVING_ON_SUB_RESOURCE:
    '@supervisors/SET_SAVING_ON_SUB_RESOURCE_SUPERVISOR',
  SUPERVISORS_RESET_SUB_RESOURCE_DATA:
    '@supervisors/RESET_SUB_RESOURCE_DATA_SUPERVISOR',

  SUPERVISORS_ADD_OPEN_MODAL: '@supervisors/OPEN_MODAL_ADD_SUPERVISOR',
  SUPERVISORS_ADD_CLOSE_MODAL: '@supervisors/CLOSE_MODAL_ADD_SUPERVISOR',

  SUPERVISORS_FILTER_OPEN_MODAL: '@supervisors/OPEN_MODAL_FILTER_SUPERVISOR',
  SUPERVISORS_FILTER_CLOSE_MODAL: '@supervisors/CLOSE_MODAL_FILTER_SUPERVISOR',

  SUPERVISORS_OBSERVATION_OPEN_MODAL:
    '@supervisors/OPEN_MODAL_SUPERVISOR_OBSERVATION',
  SUPERVISORS_OBSERVATION_CLOSE_MODAL:
    '@supervisors/CLOSE_MODAL_SUPERVISOR_OBSERVATION',

  SUPERVISORS_OPEN_FILE_DIALOG: '@supervisors/OPEN_SUPERVISOR_FILE_DIALOG',
};

export default Types;
