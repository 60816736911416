const Types = {
  SUPERVISOR_EMAILS_GET: '@supervisors/GET_SUPERVISOR_EMAILS',

  SUPERVISOR_EMAILS_CREATE_REQUEST:
    '@supervisors/CREATE_SUPERVISOR_EMAIL_REQUEST',
  SUPERVISOR_EMAILS_CREATE_SUCCESS:
    '@supervisors/CREATE_SUPERVISOR_EMAIL_SUCCESS',
  SUPERVISOR_EMAILS_CREATE_FAILURE:
    '@supervisors/CREATE_SUPERVISOR_EMAIL_FAILURE',

  SUPERVISOR_EMAILS_UPDATE_REQUEST:
    '@supervisors/UPDATE_SUPERVISOR_EMAIL_REQUEST',
  SUPERVISOR_EMAILS_UPDATE_SUCCESS:
    '@supervisors/UPDATE_SUPERVISOR_EMAIL_SUCCESS',
  SUPERVISOR_EMAILS_UPDATE_FAILURE:
    '@supervisors/UPDATE_SUPERVISOR_EMAIL_FAILURE',

  SUPERVISOR_EMAILS_DELETE_REQUEST:
    '@supervisors/DELETE_SUPERVISOR_EMAIL_REQUEST',
  SUPERVISOR_EMAILS_DELETE_SUCCESS:
    '@supervisors/DELETE_SUPERVISOR_EMAIL_SUCCESS',
  SUPERVISOR_EMAILS_DELETE_FAILURE:
    '@supervisors/DELETE_SUPERVISOR_EMAIL_FAILURE',

  SUPERVISOR_EMAILS_RESET_LIST: '@supervisors/RESET_LIST_SUPERVISOR_EMAIL',

  SUPERVISOR_EMAILS_OPEN_MODAL: '@supervisors/OPEN_MODAL_SUPERVISOR_EMAIL',
  SUPERVISOR_EMAILS_CLOSE_MODAL: '@supervisors/CLOSE_MODAL_SUPERVISOR_EMAIL',
};

export default Types;
