import produce from 'immer';

import PersonTypes from '../persons/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  supervisorAddModalOpen: false,
  supervisorObservationModalOpen: false,
  supervisorFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  openFileDialog: false,
};

export default function supervisors(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SUPERVISORS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.SUPERVISORS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.supervisorFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.SUPERVISORS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPERVISORS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPERVISORS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.supervisor);
        draft.loading = false;
        break;
      }
      case Types.SUPERVISORS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPERVISORS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPERVISORS_UPDATE_SUCCESS: {
        const { newSupervisor } = action.payload;
        draft.detail = newSupervisor;

        draft.list = state.list.map(supervisor => {
          return supervisor.id === newSupervisor.id
            ? newSupervisor
            : supervisor;
        });

        draft.supervisorObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.SUPERVISORS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPERVISORS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPERVISORS_DELETE_SUCCESS: {
        draft.list = state.list.filter(supervisor => {
          return supervisor.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.SUPERVISORS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPERVISORS_BECOME_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPERVISORS_BECOME_SUCCESS: {
        draft.loading = false;
        draft.supervisorAddModalOpen = false;
        break;
      }
      case Types.SUPERVISORS_BECOME_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPERVISORS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.SUPERVISORS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.supervisor;
        draft.requestError = false;
        break;
      }
      case Types.SUPERVISORS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.SUPERVISORS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.supervisorFilterModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }

      case Types.SUPERVISORS_RESET_DETAILS: {
        draft.detail = {};
        draft.supervisorAddModalOpen = false;
        draft.supervisorObservationModalOpen = false;
        break;
      }

      case Types.SUPERVISORS_ADD_OPEN_MODAL: {
        draft.supervisorAddModalOpen = true;
        break;
      }
      case Types.SUPERVISORS_ADD_CLOSE_MODAL: {
        draft.supervisorAddModalOpen = false;
        break;
      }

      case Types.SUPERVISORS_OBSERVATION_OPEN_MODAL: {
        draft.supervisorObservationModalOpen = true;
        break;
      }
      case Types.SUPERVISORS_OBSERVATION_CLOSE_MODAL: {
        draft.supervisorObservationModalOpen = false;
        break;
      }

      case Types.SUPERVISORS_FILTER_OPEN_MODAL: {
        draft.supervisorFilterModalOpen = true;
        break;
      }
      case Types.SUPERVISORS_FILTER_CLOSE_MODAL: {
        draft.supervisorFilterModalOpen = false;
        break;
      }

      case Types.SUPERVISORS_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.SUPERVISORS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        draft.openFileDialog = false;
        break;
      }

      case Types.SUPERVISORS_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case PersonTypes.PERSONS_RESET_DETAILS: {
        draft.supervisorAddModalOpen = false;
        break;
      }

      default:
    }
  });
}
