const Types = {
  COMPANY_EMAILS_GET: '@company/GET_COMPANY_EMAILS',

  COMPANY_EMAILS_CREATE_REQUEST: '@company/CREATE_COMPANY_EMAIL_REQUEST',
  COMPANY_EMAILS_CREATE_SUCCESS: '@company/CREATE_COMPANY_EMAIL_SUCCESS',
  COMPANY_EMAILS_CREATE_FAILURE: '@company/CREATE_COMPANY_EMAIL_FAILURE',

  COMPANY_EMAILS_UPDATE_REQUEST: '@company/UPDATE_COMPANY_EMAIL_REQUEST',
  COMPANY_EMAILS_UPDATE_SUCCESS: '@company/UPDATE_COMPANY_EMAIL_SUCCESS',
  COMPANY_EMAILS_UPDATE_FAILURE: '@company/UPDATE_COMPANY_EMAIL_FAILURE',

  COMPANY_EMAILS_DELETE_REQUEST: '@company/DELETE_COMPANY_EMAIL_REQUEST',
  COMPANY_EMAILS_DELETE_SUCCESS: '@company/DELETE_COMPANY_EMAIL_SUCCESS',
  COMPANY_EMAILS_DELETE_FAILURE: '@company/DELETE_COMPANY_EMAIL_FAILURE',

  COMPANY_EMAILS_RESET_LIST: '@company/RESET_LIST_COMPANY_EMAIL',

  COMPANY_EMAILS_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_EMAIL',
  COMPANY_EMAILS_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_EMAIL',
};

export default Types;
