import Types from './types';

export function getCustomerEmails(emails) {
  return {
    type: Types.CUSTOMER_EMAILS_GET,
    payload: { emails },
  };
}

export function createCustomerEmailRequest(customer_id, email) {
  return {
    type: Types.CUSTOMER_EMAILS_CREATE_REQUEST,
    payload: { customer_id, email },
  };
}

export function createCustomerEmailSuccess(email) {
  return {
    type: Types.CUSTOMER_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createCustomerEmailFailure() {
  return {
    type: Types.CUSTOMER_EMAILS_CREATE_FAILURE,
  };
}

export function updateCustomerEmailRequest(customer_id, email_id, newEmail) {
  return {
    type: Types.CUSTOMER_EMAILS_UPDATE_REQUEST,
    payload: { customer_id, email_id, newEmail },
  };
}

export function updateCustomerEmailSuccess(newEmail) {
  return {
    type: Types.CUSTOMER_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateCustomerEmailFailure() {
  return {
    type: Types.CUSTOMER_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteCustomerEmailRequest(customer_id, email_id) {
  return {
    type: Types.CUSTOMER_EMAILS_DELETE_REQUEST,
    payload: { customer_id, email_id },
  };
}

export function deleteCustomerEmailSuccess(email_id) {
  return {
    type: Types.CUSTOMER_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteCustomerEmailFailure() {
  return {
    type: Types.CUSTOMER_EMAILS_DELETE_FAILURE,
  };
}

export function resetListCustomerEmail() {
  return {
    type: Types.CUSTOMER_EMAILS_RESET_LIST,
  };
}

export function openModalCustomerEmail(email_id) {
  return {
    type: Types.CUSTOMER_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalCustomerEmail() {
  return {
    type: Types.CUSTOMER_EMAILS_CLOSE_MODAL,
  };
}
