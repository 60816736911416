const Types = {
  BILL_TO_PAY_CHART_ACCOUNTS_GET: '@billToPay/GET_BILL_TO_PAY_CHART_ACCOUNTS',

  BILL_TO_PAY_CHART_ACCOUNTS_CREATE_REQUEST:
    '@billToPay/CREATE_BILL_TO_PAY_CHART_ACCOUNT_REQUEST',
  BILL_TO_PAY_CHART_ACCOUNTS_CREATE_SUCCESS:
    '@billToPay/CREATE_BILL_TO_PAY_CHART_ACCOUNT_SUCCESS',
  BILL_TO_PAY_CHART_ACCOUNTS_CREATE_FAILURE:
    '@billToPay/CREATE_BILL_TO_PAY_CHART_ACCOUNT_FAILURE',

  BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_REQUEST:
    '@billToPay/UPDATE_BILL_TO_PAY_CHART_ACCOUNT_REQUEST',
  BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_SUCCESS:
    '@billToPay/UPDATE_BILL_TO_PAY_CHART_ACCOUNT_SUCCESS',
  BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_FAILURE:
    '@billToPay/UPDATE_BILL_TO_PAY_CHART_ACCOUNT_FAILURE',

  BILL_TO_PAY_CHART_ACCOUNTS_DELETE_REQUEST:
    '@billToPay/DELETE_BILL_TO_PAY_CHART_ACCOUNT_REQUEST',
  BILL_TO_PAY_CHART_ACCOUNTS_DELETE_SUCCESS:
    '@billToPay/DELETE_BILL_TO_PAY_CHART_ACCOUNT_SUCCESS',
  BILL_TO_PAY_CHART_ACCOUNTS_DELETE_FAILURE:
    '@billToPay/DELETE_BILL_TO_PAY_CHART_ACCOUNT_FAILURE',

  BILL_TO_PAY_CHART_ACCOUNTS_RESET_LIST:
    '@billToPay/RESET_LIST_BILL_TO_PAY_CHART_ACCOUNT',

  BILL_TO_PAY_CHART_ACCOUNTS_OPEN_MODAL:
    '@billToPay/OPEN_MODAL_BILL_TO_PAY_CHART_ACCOUNT',
  BILL_TO_PAY_CHART_ACCOUNTS_CLOSE_MODAL:
    '@billToPay/CLOSE_MODAL_BILL_TO_PAY_CHART_ACCOUNT',
};

export default Types;
