const Types = {
  RURAL_PROPERTY_REGISTRATIONS_GET:
    '@ruralProperty/GET_RURAL_PROPERTY_REGISTRATIONS',

  RURAL_PROPERTY_REGISTRATIONS_CREATE_REQUEST:
    '@ruralProperty/CREATE_RURAL_PROPERTY_REGISTRATION_REQUEST',
  RURAL_PROPERTY_REGISTRATIONS_CREATE_SUCCESS:
    '@ruralProperty/CREATE_RURAL_PROPERTY_REGISTRATION_SUCCESS',
  RURAL_PROPERTY_REGISTRATIONS_CREATE_FAILURE:
    '@ruralProperty/CREATE_RURAL_PROPERTY_REGISTRATION_FAILURE',

  RURAL_PROPERTY_REGISTRATIONS_UPDATE_REQUEST:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_REGISTRATION_REQUEST',
  RURAL_PROPERTY_REGISTRATIONS_UPDATE_SUCCESS:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_REGISTRATION_SUCCESS',
  RURAL_PROPERTY_REGISTRATIONS_UPDATE_FAILURE:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_REGISTRATION_FAILURE',

  RURAL_PROPERTY_REGISTRATIONS_DELETE_REQUEST:
    '@ruralProperty/DELETE_RURAL_PROPERTY_REGISTRATION_REQUEST',
  RURAL_PROPERTY_REGISTRATIONS_DELETE_SUCCESS:
    '@ruralProperty/DELETE_RURAL_PROPERTY_REGISTRATION_SUCCESS',
  RURAL_PROPERTY_REGISTRATIONS_DELETE_FAILURE:
    '@ruralProperty/DELETE_RURAL_PROPERTY_REGISTRATION_FAILURE',

  RURAL_PROPERTY_REGISTRATIONS_RESET_LIST:
    '@ruralProperty/RESET_LIST_RURAL_PROPERTY_REGISTRATION',

  RURAL_PROPERTY_REGISTRATIONS_OPEN_MODAL:
    '@ruralProperty/OPEN_MODAL_RURAL_PROPERTY_REGISTRATION',
  RURAL_PROPERTY_REGISTRATIONS_CLOSE_MODAL:
    '@ruralProperty/CLOSE_MODAL_RURAL_PROPERTY_REGISTRATION',
};

export default Types;
