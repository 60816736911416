import produce from 'immer';

import OrderBillPixTypes from '../orderBillPixs/types';
import Types from './types';

const INITIAL_STATE = {
  listLength: 0,
  detail: {},

  searchThroughTable: true,
  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,

  shouldFetchStatisticData: false,

  detailUpdatedOnce: false,
  requestError: false,
  loading: false,
  orderObservationModalOpen: false,
  orderNoteModalOpen: false,
  orderShippingModalOpen: false,
  orderMapModalOpen: false,
  orderFilterModalOpen: false,
  subResourceType: null,
  shouldOpenSubResourceModal: false,
  isCreatingPixFromList: false,
};

export default function orders(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ORDERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDERS_CREATE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.ORDERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDERS_UPDATE_SUCCESS: {
        const { newOrder } = action.payload;
        draft.detail = newOrder;

        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        draft.orderObservationModalOpen = false;
        draft.orderNoteModalOpen = false;
        draft.orderShippingModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.ORDERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDERS_DELETE_SUCCESS: {
        const { isFromDetail } = action.payload;

        if (isFromDetail) {
          if (state.page > 0 && state.listLength === 1) {
            draft.page = state.page - 1;
          }
        }

        draft.loading = false;
        draft.shouldFetchStatisticData = true;
        break;
      }
      case Types.ORDERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDERS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.ORDERS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.order;
        draft.requestError = false;
        break;
      }
      case Types.ORDERS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.ORDERS_RESET_LIST: {
        draft.requestError = false;
        draft.loading = false;
        draft.isCreatingPixFromList = false;
        draft.detail = {};
        draft.orderFilterModalOpen = false;

        draft.shouldFetchStatisticData = false;
        draft.filter = {};
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.searchThroughTable = true;
        draft.listLength = 0;

        break;
      }

      case Types.ORDERS_RESET_DETAILS: {
        draft.detail = {};
        draft.orderObservationModalOpen = false;
        draft.orderNoteModalOpen = false;
        draft.orderShippingModalOpen = false;
        draft.detailUpdatedOnce = false;
        break;
      }

      case Types.ORDERS_OBSERVATION_OPEN_MODAL: {
        draft.orderObservationModalOpen = true;
        break;
      }
      case Types.ORDERS_OBSERVATION_CLOSE_MODAL: {
        draft.orderObservationModalOpen = false;
        break;
      }

      case Types.ORDERS_NOTE_OPEN_MODAL: {
        draft.orderNoteModalOpen = true;
        break;
      }
      case Types.ORDERS_NOTE_CLOSE_MODAL: {
        draft.orderNoteModalOpen = false;
        break;
      }

      case Types.ORDERS_SHIPPING_OPEN_MODAL: {
        draft.orderShippingModalOpen = true;
        break;
      }
      case Types.ORDERS_SHIPPING_CLOSE_MODAL: {
        draft.orderShippingModalOpen = false;
        break;
      }
      case Types.ORDERS_MAP_OPEN_MODAL: {
        draft.orderMapModalOpen = true;
        break;
      }
      case Types.ORDERS_MAP_CLOSE_MODAL: {
        draft.orderMapModalOpen = false;
        break;
      }

      case Types.ORDERS_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.ORDERS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      case Types.ORDERS_FILTER_OPEN_MODAL: {
        draft.orderFilterModalOpen = true;
        break;
      }
      case Types.ORDERS_FILTER_CLOSE_MODAL: {
        draft.orderFilterModalOpen = false;
        break;
      }
      case Types.ORDERS_SET_TABLE_FILTERS: {
        const {
          modalFilters,
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
          searchThroughTable,
          shouldFetchStatisticData,
          listLength,
        } = action.payload;

        if (typeof listLength !== 'undefined') {
          draft.listLength = listLength;
        }

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        if (typeof rowsPerPage !== 'undefined') {
          draft.rowsPerPage = rowsPerPage;
        }

        if (typeof currentPage !== 'undefined') {
          draft.page = currentPage;
        }

        if (typeof searchThroughTable !== 'undefined') {
          draft.searchThroughTable = searchThroughTable;
        }

        if (typeof shouldFetchStatisticData !== 'undefined') {
          draft.shouldFetchStatisticData = shouldFetchStatisticData;
        }

        if (closeModal) {
          draft.orderFilterModalOpen = false;
        }

        break;
      }

      case Types.ORDERS_SET_REQUEST_ERROR: {
        draft.requestError = action.payload.requestError;
        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_OPEN_MODAL: {
        if (action.payload.order) {
          const { order } = action.payload;

          draft.detail = {};
          draft.isCreatingPixFromList = true;

          if (order && order.id) {
            draft.detail = order;
          }
        }

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_SHARE_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_DETAIL_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_ADD_ACCOUNT_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_EMIT_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      default:
    }
  });
}
