const Types = {
  COMPANY_USERS_GET: '@company/GET_COMPANY_USERS',

  COMPANY_USERS_CREATE_REQUEST: '@company/CREATE_COMPANY_USER_REQUEST',
  COMPANY_USERS_CREATE_SUCCESS: '@company/CREATE_COMPANY_USER_SUCCESS',
  COMPANY_USERS_CREATE_FAILURE: '@company/CREATE_COMPANY_USER_FAILURE',

  COMPANY_USERS_UPDATE_REQUEST: '@company/UPDATE_COMPANY_USER_REQUEST',
  COMPANY_USERS_UPDATE_SUCCESS: '@company/UPDATE_COMPANY_USER_SUCCESS',
  COMPANY_USERS_UPDATE_FAILURE: '@company/UPDATE_COMPANY_USER_FAILURE',

  COMPANY_USERS_DELETE_REQUEST: '@company/DELETE_COMPANY_USER_REQUEST',
  COMPANY_USERS_DELETE_SUCCESS: '@company/DELETE_COMPANY_USER_SUCCESS',
  COMPANY_USERS_DELETE_FAILURE: '@company/DELETE_COMPANY_USER_FAILURE',

  COMPANY_USERS_RESET_LIST: '@company/RESET_LIST_COMPANY_USER',

  COMPANY_USERS_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_USER',
  COMPANY_USERS_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_USER',

  COMPANY_USERS_INVITE_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_USERS_INVITE',
  COMPANY_USERS_INVITE_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_USERS_INVITE',
};

export default Types;
