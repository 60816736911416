import produce from 'immer';

import ContactTypes from '../contacts/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
};

export default function contactFiles(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CONTACT_FILES_GET: {
        const { files: fileData } = action.payload;

        const hasProfile = fileData.filter(
          file => file.type === 'image/profile' && file.is_main === true
        );

        if (hasProfile.length > 0) {
          const [profile] = hasProfile;

          draft.detail = profile;
        }

        draft.list = fileData;
        break;
      }

      case Types.CONTACT_FILES_RESET_LIST: {
        draft.list = [];
        draft.detail = {};
        break;
      }

      case ContactTypes.CONTACTS_DETAIL_CLOSE_MODAL: {
        draft.list = [];
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
