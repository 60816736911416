import styled from 'styled-components';

import { ExpandMore } from '@material-ui/icons';

function defineStyles(type, open) {
  let styles;

  const transformIcon = open ? 'rotate(180deg)' : 'rotate(0deg)';

  switch (type) {
    case 'panel':
      styles = `
        transition: transform 0.4s;
        transform: ${transformIcon};
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Expand = styled(ExpandMore)`
  ${({ type, open }) => defineStyles(type, open)}
`;
