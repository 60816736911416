module.exports = {
  // Accounts
  'accounts.title': 'Accounts',
  'accounts.new-title': 'New account',

  'accounts.deleted-account': 'Deleted account',
  'accounts.disabled-account': 'Disabled account',

  'accounts.name': 'Name',
  'accounts.balance': 'Balance',
  'accounts.species': 'Species',
  'accounts.bank': 'Bank',

  'accounts.pix-enabled': 'Pix enabled',
  'accounts.pix-enabled.enabled': 'Yes',
  'accounts.pix-enabled.disabled': 'No',

  'accounts.is-enabled': 'Enabled',
  'accounts.is-enabled.enabled': 'Enabled',
  'accounts.is-enabled.disabled': 'Disabled',
  'accounts.is-enabled.undefined': 'Undefined',

  'accounts.type': 'Type',
  'accounts.type.bank': 'Bank',
  'accounts.type.cashier': 'Cashier',
  'accounts.type.safe': 'Safe',
  'accounts.type.credit-card': 'Credit card',
  'accounts.type.undefined': 'Undefined',

  'accounts.form-title.informations': 'Informations',

  'accounts.get-accounts-failure': 'Failed to load the accounts! 😢',

  // Addresses
  'addresses.title': 'Addresses',
  'addresses.new-title': 'New address',
  'addresses.edit-title': 'Edit address',

  'addresses.postal-code': 'Postal code',
  'addresses.address': 'Address',
  'addresses.number': 'Number',
  'addresses.complement': 'Complement',
  'addresses.neighborhood': 'Neighborhood',
  'addresses.city': 'City',
  'addresses.state': 'State',

  'addresses.error-postal-code':
    'Something went wrong while we were fetching your postal code info! 🤔',

  'addresses.type': 'Type',
  'addresses.types.general': 'General',
  'addresses.types.billing': 'Billing',
  'addresses.types.delivery': 'Delivery',
  'addresses.types.branch-office': 'Branch office',
  'addresses.types.headquarters': 'Headquarters',
  'addresses.types.residential': 'Residential',
  'addresses.types.work': 'Work',

  'addresses.is-main': 'Main',
  'addresses.is-main-address': 'Main address',
  'addresses.error-delete-is-main':
    'Main address cannot be deleted when you have more than one! 🤔',
  'addresses.google-maps': 'View in Google Maps',

  // All Backups

  // All Backups Databases
  'all-backups-databases.title': 'All database backups',

  'all-backups-databases.customer-code': 'Code',
  'all-backups-databases.customer-name': 'Customer',

  'all-backups-databases.messages.no-more-backups': 'There is no backups',

  // All Devices
  'all-devices.title': 'All Devices',

  'all-devices.company-code': 'Code',
  'all-devices.company-name': 'Company',

  // Avatar
  'avatar.crop-modal.title': 'Crop image',

  // Backups

  // Backups Databases
  'backups-databases.total': 'Total',
  'backups-databases.older-backup': 'Older',
  'backups-databases.last-backup': 'Last',
  'backups-databases.backup-name': 'Archive',

  'backups-databases.status': 'Without Backup',
  'backups-databases.status.recent': 'Recent',
  'backups-databases.status.old': 'Old',
  'backups-databases.status.older': 'Older',
  'backups-databases.status.undefined': 'Undefined',

  // Bank Accounts
  'bank-accounts.bank': 'Bank',
  'bank-accounts.city': 'City',
  'bank-accounts.agency': 'Agency',
  'bank-accounts.agency-ck-digit': 'Digit',
  'bank-accounts.account': 'Account',
  'bank-accounts.account-ck-digit': 'Digit',
  'bank-accounts.assignor-name': 'Assignor name',
  'bank-accounts.credit-limit': 'Credit limit',

  'bank-accounts.assignor-person-kind': 'Person kind',
  'bank-accounts.assignor-person-kind.natural': 'Natural',
  'bank-accounts.assignor-person-kind.legal': 'Legal',

  'bank-accounts.form-title.bank-account': 'Bank account',

  // Bank Account Pixs
  'bank-account-pixs.title': 'Pixs',
  'bank-account-pixs.new-title': 'New Pix activation',
  'bank-account-pixs.edit-title': 'Edit Pix activation',

  'bank-account-pixs.pix-key': 'Pix key',
  'bank-account-pixs.client-id': 'Bank access identifier',
  'bank-account-pixs.client-key': 'Bank access key',
  'bank-account-pixs.client-secret': 'Bank secret key',
  'bank-account-pixs.pix-with-register': 'Pix with register',
  'bank-account-pixs.pix-without-register': 'Pix without register',

  'bank-account-pixs.pix-status.label': 'Active',
  'bank-account-pixs.pix-status.active': 'Active',
  'bank-account-pixs.pix-status.inactive': 'Inactive',

  'bank-account-pixs.status': 'Status',
  'bank-account-pixs.status.undefined': 'Undefined',
  'bank-account-pixs.status.active': 'Active',
  'bank-account-pixs.status.inactive': 'Inactive',

  'bank-account-pixs.type': 'Type',
  'bank-account-pixs.type.document': 'Document (CPF/CNPJ)',
  'bank-account-pixs.type.email': 'Email',
  'bank-account-pixs.type.phone-number': 'Phone',
  'bank-account-pixs.type.random-key': 'Random key',

  'bank-account-pixs.button-label.activate-pix': 'Activate Pix',

  'bank-account-pixs.error-bank-account-pix-create':
    'You can only register one Pix key.',

  'bank-account-pixs.message.configure-pix-on-itau':
    'To configure Pix on the Itaú bank account, contact support, as it is necessary to generate a certificate.',

  'bank-account-pixs.message.information-provided-by-bank':
    'Information provided by the bank.',
  'bank-account-pixs.message.required-region-code':
    'The phone number must start with +55',

  // Bank Billings
  'bank-billings.title': 'Bank Billings',
  'bank-billings.new-title': 'New billing',

  'bank-billings.account-name': 'Account',
  'bank-billings.type': 'Type',
  'bank-billings.amount': 'Amount',
  'bank-billings.favoured-name': 'Favoured',
  'bank-billings.status': 'Status',
  'bank-billings.due-at': 'Due date',

  // Banks
  'banks.title': 'Banks',

  'banks.code': 'Code',
  'banks.name': 'Name',

  'banks.get-banks-failure': 'Failed to load the banks! 😢',

  // Bills
  'bills.new-title': 'New bill',
  'bills.form-title.payment': 'Payment',
  'bills.settlement-modal-title': 'Settlement bill',

  'bills.settlement-modal-button-ok': 'Settlement',
  'bills.document-number': 'Document number',
  'bills.document': 'Document',
  'bills.payment-method-type': 'Payment method',
  'bills.competence': 'Competence',
  'bills.description': 'Description',
  'bills.observation': 'Observation',
  'bills.issued-at': 'Issue date',
  'bills.issue': 'Issue',
  'bills.due-at': 'Due date',
  'bills.due': 'Due',
  'bills.min-date-due': 'Date should not be before issue date',
  'bills.settlemented-at': 'Settlement date',
  'bills.settlement': 'Settlement',
  'bills.property': 'Property',
  'bills.percentage-paid': '% paid: {percentage}%',
  'bills.order': 'Order',
  'bills.account': 'Account',
  'bills.remaining-amount': 'Remaining amount',
  'bills.remove-settlement': 'Remove settlement',

  'bills.amount': 'Amount',
  'bills.amount-paid': 'Amount paid',
  'bills.fine-amount': 'Fine',
  'bills.interest-amount': 'Interest amount',
  'bills.discount-amount': 'Discount amount',

  'bills.reports.total-amount': 'Amount',
  'bills.reports.total-fine-amount': 'Total fine',
  'bills.reports.total-interest-amount': 'Total interest',

  'bills.debit-balance': 'Debit balance',
  'bills.balance': 'Balance',
  'bills.amount-balance': 'Amount / Balance',
  'bills.favoured': 'Favoured',
  'bills.favoured-description': 'Favoured / Description',
  'bills.status': 'Status',
  'bills.status.open': 'Open',
  'bills.status.overdue': 'Overdue',
  'bills.status.partially-paid': 'Partially paid',
  'bills.status.partially-paid-due': 'Partially paid due',
  'bills.status.paid': 'Paid',
  'bills.status.canceled': 'Canceled',
  'bills.status.discounted': 'Discounted',
  'bills.status.undefined': 'Undefined',

  'bills.situation': 'Situation',
  'bills.situation.normal': 'Normal',
  'bills.situation.canceled': 'Canceled',

  'bills.statistic-bar.relation-total': 'In relation to the total',
  'bills.statistic-bar.total-dued': 'Total dued',
  'bills.statistic-bar.total-due': 'Total to due',

  'bills.message.recalculate-fine-and-interest-amount.title': 'Warning',
  'bills.message.recalculate-fine-and-interest-amount.text':
    'When changing the due date, do you want to calculate interest and fine amount?',

  'bills.message.remove-settlemented-bill.title':
    'Do you want to remove the values paid of this installment?',
  'bills.message.remove-settlemented-bill.text':
    'When removing, the amounts paid will be zeroed and the debit balance will be readjusted to the amount of the installment.',

  'bills.get-favoured-failure': 'Failed to load the favoured! 😢',
  'bills.get-totalization-failure': 'Failed to load the totalization data! 😢',

  'bills.cost-centers.error-cost-center-create':
    'You can only register one cost center',

  'bills.chart-accounts.error-chart-account-create':
    'You can only register one chart of account',

  // Bills To Pay
  'bills-to-pay.title': 'Bills To Pay',
  'bills-to-pay.reports.total-pay': 'Total to pay',

  'bills-to-pay.statistic-bar.total-paid': 'Total paid',

  'bills-to-pay.get-bills-to-pay-failure':
    'Failed to load the bills to pay! 😢',

  // Bills To Receive
  'bills-to-receive.title': 'Bills to Receive',
  'bills-to-receive.reports.total-receive': 'Total to receive',

  'bills-to-receive.statistic-bar.total-received': 'Total received',

  'bills-to-receive.get-bills-to-receive-failure':
    'Failed to load the bills to receive! 😢',

  // Branches Activities
  'branches-activities.title': 'Branches Activities',
  'branches-activities.new-title': 'New branch activity',
  'branches-activities.create-new-title': 'Create new branch activity',
  'branches-activities.edit-title': 'Edit branch activity',

  'branches-activities.name': 'Name',

  'branches-activities.get-branches-activities-failure':
    'Failed to load the branches activities! 😢',
  'branches-activities.create-branch-activity-success':
    'Branch activity successfully registered! 🎉',

  // Brands
  'brands.title': 'Brands',
  'brands.new-title': 'New brand',
  'brands.create-new-title': 'Create new brand',
  'brands.edit-title': 'Edit brand',

  'brands.name': 'Name',

  'brands.get-brands-failure': 'Failed to load the brands! 😢',
  'brands.create-brand-success': 'Brand successfully registered! 🎉',

  // Buttons
  'buttons.add': 'New',
  'buttons.save': 'Save',
  'buttons.cancel': 'Cancel',
  'buttons.ok': 'OK',
  'buttons.close': 'Close',
  'buttons.clear': 'Clear',
  'buttons.delete': 'Delete',
  'buttons.settlement': 'Settlement',
  'buttons.hide': 'Hide',
  'buttons.go-back': 'Go back',
  'buttons.change': 'Change',
  'buttons.continue': 'Continue',
  'buttons.print': 'Print',
  'buttons.copy': 'Copy',
  'buttons.update': 'Update',
  'buttons.select': 'Select',
  'buttons.download-app': 'Download App',
  'buttons.share': 'Share',
  'buttons.catalog': 'Catalog',
  'buttons.filter': 'Filter',
  'buttons.recalculate': 'Recalculate',
  'buttons.send': 'Send',
  'buttons.reset': 'Reset',
  'buttons.remove': 'Remove',
  'buttons.view': 'View',
  'buttons.search': 'Search',
  'buttons.save-and-new': 'Save and new',
  'buttons.configure': 'Configure',
  'buttons.generate-report': 'Generate report',

  // Buyers
  'buyers.deleted-buyer': 'Deleted buyer',
  'buyers.disabled-buyer': 'Disabled buyer',

  'buyers.get-buyer-failure': 'Failed to load the buyer! 😢',

  // Categories
  'categories.title': 'Categories',
  'categories.new-title': 'New category',
  'categories.edit-title': 'Edit category',

  'categories.new-subcategory-title': 'New subcategory',
  'categories.edit-subcategory-title': 'Edit subcategory',

  'categories.name': 'Name',
  'categories.description': 'Description',

  'categories.get-categories-failure': 'Failed to load the categories! 😢',

  // Chart Accounts
  'chart-accounts.title': 'Chart of Accounts',
  'chart-accounts.new-title': 'New chart of account',
  'chart-accounts.edit-title': 'Edit chart of account',

  'chart-accounts.number': 'Number',
  'chart-accounts.reduced-number': 'Reduced number',
  'chart-accounts.description': 'Description',
  'chart-accounts.level': 'Level',

  'chart-accounts.bill-type': 'Type',
  'chart-accounts.bill-type.pay': 'Debit',
  'chart-accounts.bill-type.receive': 'Credit',

  'chart-accounts.add-level': 'Add on level',
  'chart-accounts.add-at-level': 'At level {value}',
  'chart-accounts.add-at-same-level': 'At same level {value}',
  'chart-accounts.add-at-lower-level': 'At lower level {value}',

  'chart-accounts.max-creation':
    'It is not possible to add more charts of accounts at this level.',

  'chart-accounts.get-chart-accounts-failure':
    'Failed to load the chart of accounts! 😢',

  // Cities
  'cities.title': 'Cities',

  'cities.name': 'Name',
  'cities.ibge-code': 'IBGE Code',
  'cities.state': 'State',
  'cities.default-postal-code': 'Default Postal Code',

  'cities.get-cities-failure': 'Failed to load the cities! 😢',

  // Company
  'company.title': 'Company',
  'company.form-title.users': 'Members',
  'company.new-title': 'New member',
  'company.edit-title': 'Edit member',

  'company.type': 'Type',
  'company.type.main': 'Main',
  'company.type.parent': 'Parent',
  'company.type.subsidiary': 'Subsidiary',

  'company.user.name': 'Name',
  'company.user.email': 'Email',

  'company.disabled-company': 'Disabled company',

  'company.situation.normal': 'Normal',
  'company.situation.canceled': 'Canceled',

  'company.dialogs.delete-company-title': 'Do you want to delete this company?',
  'company.dialogs.delete-company-text':
    'This action can NOT be undone, if you are sure, enter your password to confirm.',

  'company.table-localization.body.delete-user-text':
    'Are you sure you want to delete this user from the company?',

  // Company NCMs
  'company-ncms.title': 'NCMs',

  'company-ncms.id': 'Code',
  'company-ncms.description': 'Description',
  'company-ncms.category': 'Category',
  'company-ncms.level': 'Level',

  'company-ncms.is-enabled': 'Enabled',
  'company-ncms.is-enabled.enabled': 'Yes',
  'company-ncms.is-enabled.disabled': 'No',

  'company-ncms.is-single-phase': 'Single phase',
  'company-ncms.is-single-phase.enabled': 'Yes',
  'company-ncms.is-single-phase.disabled': 'No',

  // Company Profile
  'company-profile.no-product-found': 'No products were found.',
  'company-profile.product-found': '{value} product found.',
  'company-profile.products-found': '{value} products found.',
  'company-profile.filtered-by': 'Filtered by:',

  'company-profile.search-products': 'Search products...',

  'company-profile.filter-by.condition': 'Condition',
  'company-profile.filter-by.brand': 'Brand',

  'company-profile.filter-by.category': 'Category',
  'company-profile.filter-by.category.drawer-title': 'Select a category',
  'company-profile.filter-by.category.return': 'Return',
  'company-profile.filter-by.category.return-to': 'Return to {value}',

  'company-profile.is-hot': 'Hot',
  'company-profile.is-release': 'New',
  'company-profile.is-on-promotion': 'Promotion',

  'company-profile.navigation.previous': 'Previous',
  'company-profile.navigation.next': 'Next',

  'company-profile.get-products-failure': 'Failed to load the products! 😢',
  'company-profile.get-company-failure':
    'Failed to load the company informations! 😢',

  'company-profile.error.title': 'Error listing products!',
  'company-profile.error.message':
    'Please try again later or contact the responsible company.',

  // Company Users
  'company-users.invite-title': 'Invite member',

  'company-users.email': 'Email',

  'company-users.messages.invite-success': 'Successfully invited! 🎉',
  'company-users.messages.invite-failure':
    'Failed to invite the member, please try again! 😢',

  // Contacts
  'contacts.title': 'Contacts',
  'contacts.contact-detail.title': 'Contact',

  'contacts.manage-contact': 'Manage contact',

  // Cost Centers
  'cost-centers.title': 'Cost Centers',
  'cost-centers.new-title': 'New cost center',
  'cost-centers.edit-title': 'Edit cost center',

  'cost-centers.number': 'Number',
  'cost-centers.reduced-number': 'Reduced number',
  'cost-centers.description': 'Description',

  'cost-centers.add-level': 'Add on level',
  'cost-centers.add-at-level': 'At level {value}',
  'cost-centers.add-at-same-level': 'At same level {value}',
  'cost-centers.add-at-lower-level': 'At lower level {value}',

  'cost-centers.max-creation':
    'It is not possible to add more cost centers at this level.',

  'cost-centers.get-cost-centers-failure':
    'Failed to load the cost centers! 😢',

  // Countries
  'countries.title': 'Countries',

  'countries.name': 'Name',
  'countries.code': 'Code',

  // Covenants
  'covenants.title': 'Covenants',
  'covenants.new-title': 'New covenant',
  'covenants.create-new-title': 'Create new covenant',
  'covenants.edit-title': 'Edit covenant',

  'covenants.name': 'Name',

  'covenants.get-covenants-failure': 'Failed to load the covenants! 😢',
  'covenants.create-covenant-success': 'Covenant successfully registered! 🎉',

  // Cultures
  'cultures.title': 'Cultures',
  'cultures.new-title': 'New culture',
  'cultures.create-new-title': 'Create new culture',
  'cultures.edit-title': 'Edit culture',

  'cultures.name': 'Name',

  'cultures.create-culture-success': 'Culture successfully registered! 🎉',

  // Customers
  'customers.title': 'Customers',
  'customers.new-title': 'New customer',

  'customers.deleted-customer': 'Deleted customer',
  'customers.disabled-customer': 'Disabled customer',

  'customers.check-exists.become-customer': 'Become customer',
  'customers.check-exists.exists-view-title':
    'A customer with this document was found:',
  'customers.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a customer:',
  'customers.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a customer at this company:',

  'customers.check-exists.exists-not-shared-title':
    'This person is already registered as customer at company:',

  'customers.get-customer-failure': 'Failed to load the customer! 😢',
  'customers.get-customers-failure': 'Failed to load the customers! 😢',

  // Dashboard
  'dashboard.title': 'Dashboard',

  'dashboard.welcome': 'Welcome, ',
  'dashboard.logged-company': 'You are logged in the company ',

  // Dashboard Graphs
  'dashboard-graphs.sales-evolution.title': 'Sales evolution',
  'dashboard-graphs.sales-evolution.graph.label': 'Total of orders: {value}',
  'dashboard-graphs.sales-evolution.graph.first-month-label':
    '1st month - {value}',
  'dashboard-graphs.sales-evolution.graph.second-month-label':
    '2nd month - {value}',
  'dashboard-graphs.sales-evolution.message.failed-to-load-the-sales-evolution':
    'Failed to load the sales evolution! 😢',
  'dashboard-graphs.sales-evolution.filter.month': 'Month',
  'dashboard-graphs.sales-evolution.filter.first-title': 'First line',
  'dashboard-graphs.sales-evolution.filter.second-title': 'Second line',

  'dashboard-graphs.sellers-chart.total-by-sellers.title': 'Total by selers',
  'dashboard-graphs.sellers-chart.graph.first-month-label': '1st month',
  'dashboard-graphs.sellers-chart.graph.second-month-label': '2nd month',
  'dashboard-graphs.sellers-chart.message.failed-to-load-the-sellers-chart':
    'Failed to load the sellers chart! 😢',
  'dashboard-graphs.sellers-chart.filter.month': 'Month',
  'dashboard-graphs.sellers-chart.filter.first-title': 'First bar',
  'dashboard-graphs.sellers-chart.filter.second-title': 'Second bar',

  'dashboard-graphs.products-chart.others-label': 'Others',
  'dashboard-graphs.products-chart.best-selling-products.title':
    'Best-selling products',
  'dashboard-graphs.products-chart.percentage': 'Percentage: {value}',
  'dashboard-graphs.products-chart.message.failed-to-load-the-products-chart':
    'Failed to load the products chart! 😢',
  'dashboard-graphs.products.filter.date': 'Date',
  'dashboard-graphs.message.no-data-in-period':
    'No data available for this period.',

  // Datasmart
  'datasmart.name': 'Datasmart',
  'datasmart.copyright': '© {value} - Datasmart',
  'datasmart.website': 'https://www.datasmart.com.br',
  'datasmart.smartbusiness': 'SmartBusiness',

  'datasmart.email-company.email': 'datasmart@datasmart.com.br',
  'datasmart.email-company.contact': 'General subjects',
  'datasmart.email-company.subject-general-message': 'General matters',

  'datasmart.email-commercial.email': 'comercial@datasmart.com.br',
  'datasmart.email-commercial.contact': 'Commercial',
  'datasmart.email-commercial.subject-commercial-message': 'Business matters',

  'datasmart.email-support.email': 'suporte@datasmart.com.br',
  'datasmart.email-support.contact': 'Support',
  'datasmart.email-support.subject-support-message': 'Support',

  'datasmart.phone-fixed.contact': 'General subjects',
  'datasmart.phone-fixed.number.avare': '1437119090',
  'datasmart.phone-fixed.ddi': '55',
  'datasmart.phone-fixed.operator': 'Fixed',

  'datasmart.phone-mobile.contact': 'Support',
  'datasmart.phone-mobile.number': '14996641191',
  'datasmart.phone-mobile.number.lencois-paulista': '14997616504',
  'datasmart.phone-mobile.ddi': '55',
  'datasmart.phone-mobile.operator': 'Vivo',
  'datasmart.phone-mobile.whatsapp-message':
    "Hello, I'm in need of help from Datasmart support. Can you help me?",

  'datasmart.address.title': 'Address',
  'datasmart.address.state': 'SP',

  // Datasmart - Avare Address
  'datasmart.address.city.avare': 'Avaré',
  'datasmart.address.address-location.avare': 'Rua Alagoas',
  'datasmart.address.number.avare': '1633',
  'datasmart.address.neighborhood.avare': 'Centro',
  'datasmart.address.postal-code.avare': '18705-070',

  // Datasmart - Lencois Paulista Address
  'datasmart.address.city.lencois-paulista': 'Lençóis Paulista',
  'datasmart.address.address-location.lencois-paulista':
    'Avenida Geraldo Pereira de Barros',
  'datasmart.address.number.lencois-paulista': '336',
  'datasmart.address.neighborhood.lencois-paulista': 'Centro',
  'datasmart.address.postal-code.lencois-paulista': '18682-041',

  // Dates
  'dates.created-by': 'Created by',
  'dates.updated-by': 'Last updated by',
  'dates.on': 'on',
  'dates.at': 'at',
  'dates.date-interval': 'From {start} to {end}',

  // Departments
  'departments.title': 'Departments',
  'departments.new-title': 'New department',
  'departments.create-new-title': 'Create new department',
  'departments.edit-title': 'Edit department',

  'departments.name': 'Name',
  'departments.printer': 'Printer',
  'departments.number-copies': 'Number of copies',

  'departments.is-print-order': 'Print order',
  'departments.is-print-order.enabled': 'Yes',
  'departments.is-print-order.disabled': 'No',

  'departments.get-departments-failure': 'Failed to load the departments! 😢',
  'departments.create-department-success':
    'Department successfully registered! 🎉',

  // Devices
  'devices.title': 'Devices',

  'devices.local-remote-ip': 'Local / Remote IP',
  'devices.port': 'Port',
  'devices.name': 'Name',
  'devices.company-name-device-name': 'Company / Name',
  'devices.last-sync-at': 'Last synchronization',
  'devices.created-at': 'Created at',
  'devices.updated-at': 'Modified at',

  'devices.type': 'Type',
  'devices.type.server': 'Server',
  'devices.type.terminal': 'Terminal',
  'devices.type.mobile': 'Mobile',

  // Dialogs
  'dialogs.delete-title': 'Do you want to delete this record?',
  'dialogs.delete-text': "You won't be able to revert this!",

  'dialogs.change-person-kind-title': 'Do you want to change the person kind?',
  'dialogs.change-person-kind-text':
    'This action clears the completed documents.',

  'dialogs.data-loss-title': 'Do you wish to continue?',
  'dialogs.data-loss-text':
    'There are changes that have not yet been saved and will be lost if you continue.',

  // Dispatching Agencies
  'dispatching-agencies.title': 'Dispatching Agencies',

  'dispatching-agencies.name': 'Name',
  'dispatching-agencies.initials': 'Initials',
  'dispatching-agencies.state': 'State',

  'dispatching-agencies.get-dispatching-agencies-failure':
    'Failed to load the dispatching agencies! 😢',

  // Documents
  'documents.cpf': 'CPF',
  'documents.cpf-data': 'CPF: {value}',
  'documents.valid-cpf': 'Valid CPF',
  'documents.invalid-cpf': 'Invalid CPF',

  'documents.cnpj': 'CNPJ',
  'documents.cnpj-data': 'CNPJ: {value}',
  'documents.valid-cnpj': 'Valid CNPJ',
  'documents.invalid-cnpj': 'Invalid CNPJ',

  'documents.cpf-cnpj': 'CPF/CNPJ',

  // Emails
  'emails.title': 'Emails',
  'emails.new-title': 'New email',
  'emails.edit-title': 'Edit email',

  'emails.is-main': 'Main',
  'emails.is-main-email': 'Main email',
  'emails.error-delete-is-main':
    'Main email cannot be deleted when you have more than one! 🤔',

  'emails.email': 'Email',
  'emails.contact': 'Contact',
  'emails.send': 'Send email to ',

  // Employees
  'employees.title': 'Employees',
  'employees.new-title': 'New employee',

  'employees.check-exists.become-employee': 'Become employee',
  'employees.check-exists.exists-view-title':
    'A employee with this document was found:',
  'employees.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a employee:',
  'employees.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a employee at this company:',

  'employees.check-exists.exists-not-shared-title':
    'This person is already registered as employee at company:',

  // Files
  'files.add-image': 'Add image',
  'files.change-image': 'Change image',
  'files.delete-image': 'Delete image',

  'files.error-file-size-too-big':
    'The file has exceeded the limit of {value}!',
  'files.error-wrong-file-type': 'Wrong file type. Only {value} are valids!',

  // Filters
  'filters.filter-modal-title': 'Filter',
  'filters.between-start': 'Start',
  'filters.between-end': 'End',

  // Footer
  'footer.app-version': 'Version {value}',
  'footer.support': 'Support',
  'footer.help-center': 'Help Center',
  'footer.privacy': 'Privacy',
  'footer.terms-of-service': 'Terms of Service',

  // Forgot Password
  'forgot-password.title': 'Forgot password',

  'forgot-password.form-title': 'Forgot password?',
  'forgot-password.form-description':
    'We will send you an e-mail with instructions for you to reset your password.',

  'forgot-password.email': 'E-mail',

  'forgot-password.message.request-success':
    'In a moment, we will send you an e-mail with the necessary steps for you to reset your password!',
  'forgot-password.message.request-failure':
    'Failed to send the e-mail to reset password!',

  // Forms
  'forms.informations': 'Informations',
  'forms.select.dropdown-select': 'Select',

  'forms.field-excluded': 'Excluded',
  'forms.field-disabled': 'Disabled',

  'forms.autocomplete.close': 'Close',
  'forms.autocomplete.open': 'Open',
  'forms.autocomplete.clear': 'Clear',
  'forms.autocomplete.no-options': 'No options',
  'forms.autocomplete.loading': 'Loading...',

  'forms.date-picker.invalid-date': 'Invalid date',
  'forms.date-picker.min-date': 'Date should not be before minimal date',
  'forms.date-picker.max-date': 'Date should not be after maximal date',
  'forms.date-picker.clear': 'Clear',

  'forms.date-filter.today': 'Today',
  'forms.date-filter.last-7-days': 'Last 7 days',
  'forms.date-filter.last-week': 'Last week',
  'forms.date-filter.last-30-days': 'Last 30 days',
  'forms.date-filter.last-60-days': 'Last 60 days',
  'forms.date-filter.last-month': 'Last month',
  'forms.date-filter.this-month': 'This month',
  'forms.date-filter.next-month': 'Next month',
  'forms.date-filter.this-year': 'This year',
  'forms.date-filter.last-year': 'Last year',
  'forms.date-filter.customized': 'Customized',

  'forms.label.from': 'From',
  'forms.label.to': 'To',
  'forms.label.creation': 'Creation: ',
  'forms.label.last-update': 'Last update: ',

  'forms.input.search': 'Search',
  'forms.input.invalid-email': 'Invalid email',
  'forms.input.invalid-document': 'Invalid document',
  'forms.input.required-field': 'Required field',
  'forms.input.field-greater-than': 'Field must be greater than {value}',
  'forms.input.field-greater-than-or-equal-to':
    'Field must be greater than or equal to {value}',
  'forms.input.field-less-than': 'Field must be less than {value}',
  'forms.input.field-less-than-or-equal-to':
    'Field must be less than or equal to {value}',
  'forms.input.field-greater-than-zero': 'Field must be greater than 0',
  'forms.input.field-exactly-characters':
    'Value must be exactly {value} characters',
  'forms.input.total-is-below-to-cost-price': 'Total is below to cost price',

  'forms.input.custom-validation.is-correct-minimum-value':
    'The minimum value must be less than the maximum value',
  'forms.input.custom-validation.is-correct-maximum-value':
    'The maximum value must be greater than the minimum value',

  // Harvests
  'harvests.title': 'Harvests',
  'harvests.new-title': 'New harvest',
  'harvests.edit-title': 'Edit harvest',

  'harvests.name': 'Name',
  'harvests.culture': 'Culture',
  'harvests.started-at': 'Harvest start date',
  'harvests.ended-at': 'Harvest end date',
  'harvests.standard-unit-measure': 'Standard unit measure',

  'harvests.get-culture-failure': 'Failed to load the cultures! 😢',
  'harvests.get-standard-unit-measure-failure':
    'Failed to load the units measures! 😢',

  'harvests.min-date': 'The date should not be before the start date',

  // Header
  'header.search': 'Search',

  'header.language': 'Language',
  'header.language.en-us': 'English',
  'header.language.es-es': 'Español',
  'header.language.pt-br': 'Português',

  'header.profile': 'My profile',
  'header.company': 'My company',
  'header.switch-company': 'Switch company',
  'header.company-public-profile': 'Company public profile',
  'header.settings': 'Settings',
  'header.help': 'Help',
  'header.sign-out': 'Sign out',
  'header.user-avatar': 'User avatar',
  'header.open-drawer': 'Open drawer',

  // Help
  'help.title': 'Help',

  'help.contact-title': 'Contact us',
  'help.contact-description':
    'If you need help, please contact us through one of our channels.',

  // Images
  'images.whatsapp-logo': 'WhatsApp logo',

  // Installments
  'installments.title': 'Installments',
  'installments.new-title': 'New installment',
  'installments.edit-title': 'Edit installment',

  'installments.days': 'Days',
  'installments.installment': 'Installment',

  'installments.installment-limit': 'Installments limit reached',

  // Inventories
  'inventories.title': 'Inventories',
  'inventories.new-title': 'New inventory',

  'inventories.effective-at': 'Effective date',
  'inventories.description': 'Description',
  'inventories.registered-at': 'Register date',

  'inventories.situation': 'Situation',
  'inventories.situation.undefined': 'Undefined',
  'inventories.situation.opened': 'Opened',
  'inventories.situation.finished': 'Finished',
  'inventories.situation.canceled': 'Canceled',

  // Inventory Items
  'inventory-items.title': 'Items',
  'inventory-items.edit-title': 'Edit item',
  'inventory-items.search-title': 'Search',

  'inventory-items.product-title': 'Product',
  'inventory-items.stock': 'Stock',
  'inventory-items.stock-amount': 'Stock: {value}',
  'inventory-items.quantity': 'Quantity',
  'inventory-items.quantity-amount': 'Quantity: {value}',
  'inventory-items.brand': 'Brand',
  'inventory-items.category': 'Category',
  'inventory-items.prediction': 'Prediction',
  'inventory-items.prediction-amount': 'Prediction: {value}',

  'inventory-items.type': 'Type',
  'inventory-items.type.undefined': 'Undefined',
  'inventory-items.type.entry': 'Entry',
  'inventory-items.type.exit': 'Exit',

  'inventory-items.cannot-update-with-deleted-product':
    'Unable to update with deleted product!',

  'inventory-items.get-inventory-items-failure':
    'Failed to load the inventory items! 😢',

  // Inventory Items Report
  'inventory-items.report.title': 'Inventory',

  // Material Table
  'material-table.add': 'Add',
  'material-table.view-and-edit': 'View and edit',
  'material-table.print': 'Print',
  'material-table.refresh': 'Refresh',
  'material-table.edit': 'Edit',
  'material-table.download': 'Download',
  'material-table.settlement': 'Settlement',
  'material-table.delete': 'Delete',
  'material-table.share': 'Share',
  'material-table.view': 'View',
  'material-table.filter': 'Filter',
  'material-table.map': 'Show map',

  // Messages
  'messages.get-failure': 'Failed to load records, please refresh the page! 😢',

  'messages.save-success': 'Successfully registered! 🎉',
  'messages.save-failure': 'Failed to save the record, please try again! 😢',

  'messages.edit-success': 'Successfully applied changes! 🎉',
  'messages.edit-failure': 'Failed to apply changes, please try again! 😢',

  'messages.change-success': 'Successfully applied changes! 🎉',
  'messages.change-failure': 'Failed to apply changes, please try again! 😢',

  'messages.update-success': 'Successfully updated! 🎉',
  'messages.update-failure':
    'Failed to update the record, please try again! 😢',

  'messages.delete-success': 'Successfully deleted! 🎉',
  'messages.delete-failure':
    'Failed to delete the record, please try again! 😢',

  'messages.settlement-success': 'Successfully settlemented! 🎉',
  'messages.settlement-failure':
    'Failed to settlement the record, please try again! 😢',

  'messages.get-details-failure':
    'Failed to load record details, please refresh the page! 😢',

  'messages.error-500.without-connection-server':
    'Without connection to the server! 🤔',
  'messages.error-500.internal-server-error': 'Internal server error! 🤔',
  'messages.error-500.database-connection-error':
    'Internal server error! Code: 001 🤔',

  'messages.error-504.gateway-timeout': 'Internal server error! Code: 002 🤔',

  'messages.resource-available-consultation':
    'Resource available for consultation only',
  'messages.need-changes-contact-support-1': 'If you need any changes, click ',
  'messages.need-changes-contact-support-2': 'here',
  'messages.need-changes-contact-support-3': ' to contact support.',

  'messages.notice-title': 'Notice',
  'messages.order-items-generation':
    'The values of percentage applied, commission percentage and if discount will be denied are merely for the basis of generating the items as they can later be changed individually.',

  'messages.preposition.female': 'of',
  'messages.preposition.male': 'of',
  'messages.conjuction.and': 'and',

  'messages.copied': 'Copied!',

  // Modals - Share
  'modals.share': 'Share',
  'modals.share-by': 'Share by',
  'modals.share-link': 'Or by the link',

  // NCMS
  'ncms.title': 'NCMs',

  'ncms.id': 'Code',
  'ncms.description': 'Description',
  'ncms.category': 'Category',
  'ncms.level': 'Level',

  'ncms.is-single-phase': 'Single phase',
  'ncms.is-single-phase.enabled': 'Yes',
  'ncms.is-single-phase.disabled': 'No',

  'ncms.get-ncms-failure': 'Failed to load the NCMs! 😢',

  // New Company
  'new-company.title': 'New Company',

  'new-company.share-data-with': 'Share data with',
  'new-company.share-data-with.all-group-companies': 'All group companies',
  'new-company.share-data-with.only-superior-companies':
    'Only with superior companies',

  'new-company.create-button': 'Create new company',

  // Order Bills
  'order-bills.title': 'Installments control',
  'order-bills.edit-title': 'Edit installment',
  'order-bills.installments-title': 'Installments',

  'order-bills.base-date': 'Base date',
  'order-bills.document-number': 'Document number',
  'order-bills.document': 'Document',
  'order-bills.payment-method-type': 'Payment method',
  'order-bills.due': 'Due',
  'order-bills.due-at': 'Due date',
  'order-bills.amount': 'Amount',
  'order-bills.status': 'Status',
  'order-bills.installments-totalization': 'Total of installments: ',
  'order-bills.generate-installments': 'Generate installments',
  'order-bills.delete-all-installments': 'Delete all installments',

  // Order Items
  'order-items.title': 'Items',
  'order-items.new-title': 'New item',
  'order-items.edit-title': 'Edit item',

  'order-items.product': 'Product',
  'order-items.unit-measure': 'Unit measure',
  'order-items.type': 'Type',
  'order-items.description': 'Description',
  'order-items.quantity': 'Quantity',
  'order-items.unit-value': 'Unit value',
  'order-items.cost-value': 'Cost value',
  'order-items.total': 'Total',
  'order-items.available': 'Available',
  'order-items.discount-value': 'Discount value',
  'order-items.discount-percentage': 'Discount %',
  'order-items.commission-percentage': 'Commission %',
  'order-items.observation': 'Observation',
  'order-items.is-showcase': 'Showcase',
  'order-items.not-controlled': 'Not controlled',
  'order-items.net-value': 'Net value',
  'order-items.unit-value-discount-value': 'Unit value / Discount',
  'order-items.price-lists': 'Price lists',
  'order-items.price-list': 'Price list',

  'order-items.value.quantity-available': '{value} available',
  'order-items.value.quantity-availables': '{value} available',

  'order-items.situation': 'Situation',
  'order-items.situation.normal': 'Normal',
  'order-items.situation.blocked': 'Blocked',
  'order-items.situation.canceled': 'Canceled',
  'order-items.situation.undefined': 'Undefined',

  'order-items.discount-percentage-readjusted':
    'The discount percentage will be readjusted as the discount amount exceeds 2 decimal places! 🤔',

  'order-items.invalid-net-value-with-discount-check':
    'Error calculating net value with discount! 🤔',
  'order-items.invalid-net-value-check': 'Error calculating net price! 🤔',

  'order-items.message.quantity-change':
    'To change the quantity, it is necessary to delete this item and post it again.',
  'order-items.message.product-without-stock':
    'This product is without stock! 🤔',
  'order-items.message.item-without-quantity':
    'The quantity needs to be greater than 0! 🤔',
  'orders-items.message.not-allowed-to-decrease-product-price':
    'It is not allowed to decrease the product price!',
  'order-items.message.cannot-apply-discount-to-product':
    'It is not allowed to apply a discount on this product!',
  'order-items.message.item-total-exceeds-payment-method-maximum-amount':
    'The sum of the item and the existing record total exceeds the maximum value of the payment method!',

  // Orders
  'orders.title': 'Orders',
  'orders.new-title': 'New order',

  'orders.form-title.totalization': 'Totalization',
  'orders.form-title.discount': 'Discount',

  'orders.code': 'Code',
  'orders.total': 'Total',
  'orders.payment-method': 'Payment method',
  'orders.customer-supplier': 'Customer / Supplier',
  'orders.seller-buyer': 'Seller / Buyer',
  'orders.customer': 'Customer',
  'orders.supplier': 'Supplier',
  'orders.seller': 'Seller',
  'orders.buyer': 'Buyer',
  'orders.is-controls-stock': 'Control stock',
  'orders.customer-note': 'Customer note',
  'orders.store-note': 'Store note',
  'orders.estimated-delivery-at': 'Estimated delivery date',
  'orders.delivered-at': 'Delivered date',
  'orders.discount-percentage': 'Discount %',
  'orders.is-delivered': 'Delivered',
  'orders.discount-coupon': 'Discount coupon',
  'orders.shipping-method': 'Shipping method',
  'orders.taxes': 'Taxes',
  'orders.total-products': 'Total products',
  'orders.total-services': 'Total services',
  'orders.shipping-fee': 'Shipping fee',
  'orders.installments': 'Installments',
  'orders.tracking-code': 'Tracking code',
  'orders.sending-at': 'Sending date',
  'orders.discount-value': 'Discount value',
  'orders.observation': 'Observation',
  'orders.notes': 'Notes',
  'orders.shipping-delivery': 'Shipping / Delivery',
  'orders.shipping-at': 'Shipping date',
  'orders.registered-at': 'Register date',
  'orders.geographic-coordinate': 'Localization',
  'orders.commission-percentage': 'Commission %',
  'orders.transporter': 'Transporter',
  'orders.map.title': 'Orders map',
  'orders.map.customer-name': '<strong>Customer</strong>: {value}',
  'orders.map.seller-name': '<strong>Seller</strong>: {value}',
  'orders.map.total': '<strong>Total</strong>: {value}',
  'orders.map.footer': 'Orders: {coordinatesTotal}/{total}',
  'orders.map.no-data': 'There are no coordinates in current orders.',

  'orders.type': 'Type',
  'orders.type.budget': 'Budget',
  'orders.type.order': 'Order',
  'orders.type.sale': 'Sale',
  'orders.type.service': 'Service',
  'orders.type.purchase': 'Purchase',
  'orders.type.undefined': 'Undefined',

  'orders.subtype': 'Subtype',
  'orders.subtype.budget': 'Budget',
  'orders.subtype.order': 'Order',
  'orders.subtype.pre-sale': 'Pre-sale',
  'orders.subtype.bonification': 'Bonification',
  'orders.subtype.shipping': 'Shipping',
  'orders.subtype.exchange': 'Exchange',
  'orders.subtype.purchase-order': 'Purchase order',
  'orders.subtype.sale': 'Sale',
  'orders.subtype.ready-delivery': 'Ready delivery',
  'orders.subtype.service-order': 'Service order',
  'orders.subtype.visit': 'Visit',
  'orders.subtype.purchase': 'Purchase',
  'orders.subtype.undefined': 'Undefined',

  'orders.status': 'Status',
  'orders.status.releasing': 'Waiting for release',
  'orders.status.sorting': 'Waiting for separation',
  'orders.status.checking': 'Waiting for verification',
  'orders.status.released': 'Released',
  'orders.status.undefined': 'Undefined',

  'orders.situation': 'Situation',
  'orders.situation.opened': 'Opened',
  'orders.situation.finished': 'Finished',
  'orders.situation.canceled': 'Canceled',
  'orders.situation.undefined': 'Undefined',

  'orders.operation-type': 'Operation type',
  'orders.operation-type.entry': 'Entry',
  'orders.operation-type.exit': 'Exit',
  'orders.operation-type.undefined': 'Undefined',

  'orders.shipping-by': 'Shipping by',
  'orders.shipping-by.sender': 'Sender',
  'orders.shipping-by.recipient': 'Recipient',
  'orders.shipping-by.sender-charge-shipping': 'Sender (Charge shipping)',
  'orders.shipping-by.third-parties': 'Third parties',
  'orders.shipping-by.free-shipping': 'Free shipping',

  'orders.statistic-bar.relation-total': 'In relation to the total',
  'orders.statistic-bar.total-opened': 'Total opened',
  'orders.statistic-bar.total-finished': 'Total finished',

  'orders.discount-percentage-readjusted':
    'The discount percentage will be readjusted as the discount amount exceeds 2 decimal places! 🤔',

  'orders.message.total-difference-one':
    'There is a difference of {value} between the total ',
  'orders.message.total-difference-two': ' {type} with the installments total!',
  'orders.message.total-difference-three':
    'There is a difference of {value} between the register total with the installments total!',
  'orders.message.not-allowed-to-apply-discount':
    'It is not possible to apply a discount directly on order!',
  'orders.message.total-exceeds-payment-method-maximum-amount':
    'The total of the record exceeds the maximum amount of the payment method!',

  'orders.message.product-already-created':
    'This product has been created previously!',

  'orders.message.cannot-edit-values':
    'It is not possible to edit values because exists generated installments, delete all and try again!',

  'orders.get-orders-failure': 'Failed to load the orders! 😢',
  'orders.get-customer-failure': 'Failed to load the customers! 😢',
  'orders.get-supplier-failure': 'Failed to load the suppliers! 😢',
  'orders.get-seller-failure': 'Failed to load the sellers! 😢',
  'orders.get-totalization-failure': 'Failed to load the totalization data! 😢',

  // Orders report
  'orders.report.document.rg': 'RG: {value}',
  'orders.report.document.ie': 'IE: {value}',
  'orders.report.code': 'Code: {value}',
  'orders.report.date': 'Date: {value}',
  'orders.report.seller-man': 'Seller: {value}',
  'orders.report.seller-woman': 'Seller: {value}',
  'orders.report.buyer-man': 'Buyer: {value}',
  'orders.report.buyer-woman': 'Buyer: {value}',

  'orders.report.item.description': 'Description',
  'orders.report.item.quantity': 'Quantity',
  'orders.report.item.value': 'Value',
  'orders.report.item.total': 'Total',
  'orders.report.item.unit-measure': 'Unit.',
  'orders.report.item.total-product': 'Product total',
  'orders.report.item.total-service': 'Service total',
  'orders.report.item.discount': 'Discount',

  'orders.report.bills.document': 'Document',
  'orders.report.bills.payment-method-type': 'Payment method type',
  'orders.report.bills.due-at': 'Due date',
  'orders.report.bills.amount': 'Amount',

  'orders.report.observation.title': 'Observation',

  'orders.report.signature.title': 'Signature',

  'orders.report.failure.person-disabled':
    'Failed to create the report, disabled person! 😢',

  'orders.report.failure.person-canceled':
    'Failed to create the report, canceled person! 😢',

  'orders.report.failure.seller-deleted':
    'Failed to create the report, deleted seller! 😢',
  'orders.report.failure.buyer-deleted':
    'Failed to create the report, deleted buyer! 😢',

  'orders.report.failure.seller-disabled':
    'Failed to create the report, disabled seller! 😢',
  'orders.report.failure.buyer-disabled':
    'Failed to create the report, disabled buyer! 😢',

  'orders.report.failure.customer-deleted':
    'Failed to create the report, deleted customer! 😢',
  'orders.report.failure.supplier-deleted':
    'Failed to create the report, deleted supplier! 😢',

  'orders.report.failure.customer-disabled':
    'Failed to create the report, disabled customer! 😢',
  'orders.report.failure.supplier-disabled':
    'Failed to create the report, disabled supplier! 😢',

  'orders.report.failure.no-items-created':
    'Failed to create the report, there is no items created! 😢',

  'orders.report.failure.no-bills-created':
    'Failed to create the report, there is no bills created! 😢',

  'orders.report.failure.payment-method-deleted':
    'Failed to create the report, deleted payment method! 😢',

  // Owners
  'owners.title': 'Owners',
  'owners.new-title': 'New owner',

  'owners.deleted-owner': 'Deleted owner',
  'owners.disabled-owner': 'Disabled owner',

  'owners.check-exists.become-owner': 'Become owner',
  'owners.check-exists.exists-view-title':
    'A owner with this document was found:',
  'owners.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a owner:',
  'owners.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a owner at this company:',

  'owners.check-exists.exists-not-shared-title':
    'This person is already registered as owner at company:',

  'owners.get-owners-failure': 'Failed to load the owners! 😢',

  // Page404
  'page404.title': 'Page not found',

  'page404.404': 'Error 404',
  'page404.description': 'The page you are looking for was not found.',
  'page404.return-to-website': 'Return to website',

  // Payment
  'payment.invalid-total-check': 'Error calculating total value! 🤔',

  // Payment Methods
  'payment-methods.title': 'Payment methods',
  'payment-methods.title.bills-to-pay': 'Payment methods - Bills to pay',
  'payment-methods.title.bills-to-receive':
    'Payment methods - Bills to receive',
  'payment-methods.new-title': 'New payment method',

  'payment-methods.description': 'Description',
  'payment-methods.installments': 'Installments',
  'payment-methods.grace-days': 'Grace days',
  'payment-methods.discount-percentage': 'Discount %',
  'payment-methods.increase-percentage': 'Increase %',
  'payment-methods.payment-method-type': 'Payment method',
  'payment-methods.maximum-amount': 'Maximum value',

  'payment-methods.frequency': 'Frequency',
  'payment-methods.frequency.daily': 'Daily',
  'payment-methods.frequency.monthly': 'Monthly',

  'payment-methods.interest-types': 'Interest type',
  'payment-methods.interest-types.simple': 'Simple',
  'payment-methods.interest-types.compound': 'Compound',

  'payment-methods.is-enabled': 'Enabled',
  'payment-methods.is-enabled.enabled': 'Enabled',
  'payment-methods.is-enabled.disabled': 'Disabled',
  'payment-methods.is-enabled.undefined': 'Undefined',

  'payment-methods.cashier': 'Cashier',
  'payment-methods.bank-deposit': 'Bank deposit',
  'payment-methods.bank-transfer': 'Bank transfer',
  'payment-methods.bank-slip': 'Bank slip',
  'payment-methods.credit-card': 'Credit card',
  'payment-methods.debit-card': 'Debit card',
  'payment-methods.check': 'Check',
  'payment-methods.others': 'Others',

  'payment-methods.is-default': 'Default payment method',

  'payment-methods.get-payment-methods-failure':
    'Failed to load the payment methods! 😢',

  // Payment Methods Types
  'payment-methods-types.title': 'Payments methods',

  'payment-methods-types.display-label': 'Payment method',
  'payment-methods-types.code': 'Code',
  'payment-methods-types.description': 'Description',

  'payment-methods-types.undefined': 'Undefined',

  'payment-methods-types.get-payment-methods-types-failure':
    'Failed to load the payments methods! 😢',

  // Persons
  'persons.title': 'Persons',
  'persons.form-title.personal-information': 'Personal information',
  'persons.form-title.additional-information': 'Additional information',
  'persons.form-title.credit': 'Credit',
  'persons.form-title.personal-documents': 'Personal documents',
  'persons.form-title.additional-documents': 'Additional documents',
  'persons.form-title.occupation': 'Occupation',
  'persons.form-title.contact': 'Contact',
  'persons.form-title.values': 'Values',

  'persons.add-modal.title': 'Document verification',

  'persons.name': 'Full name',
  'persons.nickname': 'Nickname',
  'persons.trading-name': 'Trading name',
  'persons.born-at': 'Birth date',
  'persons.is-rural-producer': 'Rural producer',
  'persons.foundation-date': 'Foundation date',
  'persons.profession': 'Profession',
  'persons.nationality': 'Nationality',
  'persons.contact': 'Contact',
  'persons.website': 'Website',
  'persons.occupation': 'Occupation',
  'persons.workplace': 'Workplace',
  'persons.credit-limit': 'Credit limit',
  'persons.without-credit-limit': 'Without limit',
  'persons.price-list': 'Price list',
  'persons.payment-method': 'Payment method',
  'persons.fixed-discount-percentage': 'Discount %',
  'persons.observation': 'Observation',
  'persons.registered-at': 'Registered at',
  'persons.branch-activity': 'Branch activity',
  'persons.covenant': 'Covenant',
  'persons.address': 'Address',
  'persons.email': 'Email',
  'persons.phone': 'Phone',
  'persons.is-main-phone-email': 'Phone / Email',
  'persons.maximum-discount-percentage': 'Maximum discount %',
  'persons.commission-percentage': 'Commission %',
  'persons.slug': 'Slug',
  'persons.is-blocked-installment-sale': 'Block installment sales',

  'persons.document.valid': 'Valid',
  'persons.document.invalid': 'Invalid',
  'persons.document.undefined': 'Undefined',
  'persons.documents.dispatching-agency': 'Dispatching agency',
  'persons.documents.state': 'State',

  'persons.ie': 'IE',
  'persons.set-exempt': 'Set exempt',
  'persons.rg': 'RG',
  'persons.required-rg': 'The RG field is required',
  'persons.im': 'IM',

  'persons.person-kind': 'Person kind',
  'persons.natural-person': 'Natural',
  'persons.legal-person': 'Legal',

  'persons.parents-name': 'Parents name',
  'persons.father-filiation': "Father's name",
  'persons.mother-filiation': "Mother's name",

  'persons.gender': 'Gender',
  'persons.genders.female': 'Female',
  'persons.genders.male': 'Male',

  'persons.situation': 'Situation',
  'persons.situation.normal': 'Normal',
  'persons.situation.blocked': 'Blocked',
  'persons.situation.canceled': 'Canceled',
  'persons.situation.debtor': 'Debtor',
  'persons.situation.undefined': 'Undefined',

  'persons.type': 'Type',
  'persons.type.supplier': 'Supplier',
  'persons.type.customer': 'Customer',
  'persons.type.company': 'Company',
  'persons.type.employee': 'Employee',
  'persons.type.seller': 'Seller',
  'persons.type.driver': 'Driver',
  'persons.type.owner': 'Owner',
  'persons.type.property': 'Property',
  'persons.type.technical': 'Technical',
  'persons.type.transporter': 'Transporter',
  'persons.type.supervisor': 'Supervisor',
  'persons.type.promoter': 'Promoter',

  'persons.is-enabled': 'Enabled',
  'persons.is-enabled.enabled': 'Enabled',
  'persons.is-enabled.disabled': 'Disabled',
  'persons.is-enabled.undefined': 'Undefined',

  'persons.marital-status': 'Marital status',
  'persons.marital-status.married': 'Married',
  'persons.marital-status.single': 'Single',
  'persons.marital-status.divorced': 'Divorced',
  'persons.marital-status.stable-union': 'Stable Union',
  'persons.marital-status.widowed': 'Widowed',

  'persons.without-nickname': '(Not registered)',

  'persons.deleted-person': 'Deleted person',
  'persons.disabled-person': 'Disabled person',

  'persons.check-exists.not-verified-title': 'Verification by CPF/CNPJ',
  'persons.check-exists.not-verified-description':
    'Inform to check for an existing person or ignore leaving empty.',

  'persons.check-exists.invalid-document-title': 'Validation Error!',
  'persons.check-exists.invalid-document-description':
    'The CPF/CNPJ did not pass the document validation.',

  'persons.check-exists.connection-error-title': 'Connection Error!',
  'persons.check-exists.connection-error-description':
    'Without connection to the server.',

  'persons.check-exists.not-exists-title': 'Success!',
  'persons.check-exists.not-exists-description':
    'No person was found with this document.',

  'persons.check-exists.complete-registration': 'Complete registration',
  'persons.check-exists.view-registration': 'View registration',

  'persons.check-exists.edit-document-title': 'Warning!',
  'persons.check-exists.edit-document-description':
    'No person was found with this document. When changing the document and changing the type of person, the remaining documents will be cleaned.',
  'persons.check-exists.empty-edit-document-description':
    'Inform to check for an existing person or ignore leaving empty. When changing the document and changing the type of person, the remaining documents will be cleaned.',
  'persons.check-exists.edit-document': 'Change document',

  'persons.check-exists.exists-valid-document-title': 'Valid document!',
  'persons.check-exists.exists-valid-document-description':
    'This document is valid and registered for this person.',

  'persons.check-exists.exists-not-shared-title':
    'This person is already registered at company:',

  // Phones
  'phones.title': 'Phones',
  'phones.new-title': 'New phone',
  'phones.edit-title': 'Edit phone',

  'phones.number': 'Number',
  'phones.operator': 'Operator',
  'phones.contact': 'Contact',
  'phones.call': 'Call to ',
  'phones.without-ddi': 'Without DDI',
  'phones.select-ddi': 'Select',

  'phones.type': 'Type',
  'phones.types.general': 'General',
  'phones.types.cell-phone': 'Cell phone',
  'phones.types.contact': 'Contact',
  'phones.types.fax': 'Fax',
  'phones.types.branch-office': 'Branch office',
  'phones.types.landline': 'Landline',
  'phones.types.headquarters': 'Headquarters',
  'phones.types.residential': 'Residential',
  'phones.types.work': 'Work',

  'phones.is-whatsapp': 'WhatsApp',
  'phones.open-whatsapp': 'Open in WhatsApp',

  'phones.is-main': 'Main',
  'phones.is-main-phone': 'Main phone',
  'phones.error-delete-is-main':
    'Main phone cannot be deleted when you have more than one! 🤔',
  'phones.error.is-without-ddi-with-is-whatsapp':
    'It is not possible to save a phone without ddi with WhatsApp!',

  // Pixs
  'pixs.title': 'Pix',
  'pixs.panel-title': 'Pixs',
  'pixs.show-title': 'Show Pix',
  'pixs.with-register': 'With register',
  'pixs.without-register': 'Without register',
  'pixs.with-register-title': 'Pix with register',
  'pixs.without-register-title': 'Pix without register',
  'pixs.settlement-pix-title': 'Settlement Pix',
  'pixs.select-pix-account-title': 'Select a Pix account',
  'pixs.emit-pix-title': 'Emit Pix',
  'pixs.company-pix-title': 'Configure company to emit Pix by Datasmart',
  'pixs.sending-data-to-company-email':
    "This information will be sent to the company's main email.",

  'pixs.brand': 'Pix brand',

  'pixs.copy-qr-code': 'Copy QR Code',
  'pixs.qr-code': 'Pix QR Code',
  'pixs.amount': 'Amount',
  'pixs.amount-paid': 'Amount paid',
  'pixs.description': 'Description',
  'pixs.duration': 'Due date',
  'pixs.assignor-name': 'Name',
  'pixs.bank-name': 'Bank',
  'pixs.payer-name': 'Payer',
  'pixs.issued-at': 'Issue date',
  'pixs.due-at': 'Due date',
  'pixs.settlemented-at': 'Settlement date',
  'pixs.errors': 'Errors',
  'pixs.is-pix-qrcode-opened': 'Pix visualized',
  'pixs.payments': 'Payments',
  'pixs.subject': 'Pix payment',
  'pixs.company-name': 'Company',
  'pixs.pix-key': 'Pix key',
  'pixs.account': 'Account',
  'pixs.client-id': 'Access identifier',
  'pixs.client-secret': 'Customer secret key',
  'pixs.dynamic-pix': 'Pix with register',
  'pixs.static-pix': 'Pix without register',

  'pixs.button-label.emit-pix': 'Emit Pix',
  'pixs.message.wait-pix-request':
    'Please, wait {value} seconds to complete the Pix emition.',
  'pixs.message.current-pix-status': 'The Pix is in the final status: {value}',

  'pixs.type': 'Type',
  'pixs.type.static': 'Without register',
  'pixs.type.dynamic': 'With register',

  'pixs.pix-status': 'Pix status',
  'pixs.status': 'Status',
  'pixs.status.undefined': 'Undefined',
  'pixs.status.saved': 'Saved',
  'pixs.status.active': 'Active',
  'pixs.status.liquidated': 'Paid',
  'pixs.status.psp-removed': 'Removed by bank',
  'pixs.status.rejected': 'Rejected',
  'pixs.status.failed': 'Failed',

  'pixs.last-automatic-update': 'Last automatic update',
  'pixs.last-automatic-update.pix-created': 'Created',
  'pixs.last-automatic-update.pix-failed': 'Failed',
  'pixs.last-automatic-update.pix-successful': 'Emitted',
  'pixs.last-automatic-update.pix-payment-refunded': 'Payment refunded',
  'pixs.last-automatic-update.pix-refunded': 'Refunded',
  'pixs.last-automatic-update.pix-paid': 'Paid',
  'pixs.last-automatic-update.pix-expired': 'Expired',
  'pixs.last-automatic-update.pix-expired-without-payment':
    'Expired without payment',
  'pixs.last-automatic-update.pix-bank-rejected': 'Rejected by bank',

  'pixs.share-pix.title': 'Share Pix',
  'pixs.share-pix.share-by': 'Share this Pix by',
  'pixs.share-pix.share-link': 'Or by the link',
  'pixs.share-pix.title-message': 'Information regarding the Pix',
  'pixs.share-pix.body-message': 'To pay {value} by Pix, go to',

  'pixs.internal-error': 'Internal error!',

  // Price List Items
  'price-list-items.title': 'Items',
  'price-list-items.new-title': 'New items',
  'price-list-items.edit-title': 'Edit item',

  'price-list-items.price-list-title': 'Title',
  'price-list-items.cost-value': 'Cost price',
  'price-list-items.percentage-applied': '% applied',
  'price-list-items.price': 'Price',
  'price-list-items.commission-percentage': 'Commission %',
  'price-list-items.discount-percentage': 'Discount %',
  'price-list-items.is-denied-discount': 'Deny discount',

  'price-list-items.get-price-list-items-failure':
    'Failed to load the price list items! 😢',

  // Price Lists
  'price-lists.title': 'Price lists',
  'price-lists.new-title': 'New price lists',

  'price-lists.name': 'Name',
  'price-lists.percentage-applied': '% applied',
  'price-lists.commission-percentage': 'Commission %',
  'price-lists.price-list-reference': 'Price list reference',
  'price-lists.covenant': 'Covenant',
  'price-lists.discount-percentage': 'Discount %',
  'price-lists.started-at': 'Started date',
  'price-lists.ended-at': 'Ended date',
  'price-lists.is-default': 'Default list',
  'price-lists.is-added-manually': 'Add items manually',

  'price-lists.is-enabled': 'Enabled',
  'price-lists.is-enabled.enabled': 'Enabled',
  'price-lists.is-enabled.disabled': 'Disabled',
  'price-lists.is-enabled.undefined': 'Undefined',

  'price-lists.is-promotional': 'Promotional',
  'price-lists.is-promotional.enabled': 'Yes',
  'price-lists.is-promotional.disabled': 'No',

  'price-lists.is-enabled-to-order': 'Enabled to order / sale',
  'price-lists.is-enabled-to-order.enabled': 'Yes',
  'price-lists.is-enabled-to-order.disabled': 'No',

  'price-lists.is-denied-discount': 'Deny discount',
  'price-lists.is-denied-discount.enabled': 'Yes',
  'price-lists.is-denied-discount.disabled': 'No',

  'price-lists.get-price-lists-failure': 'Failed to load the price lists! 😢',

  // Products
  'products.title': 'Products',
  'products.new-title': 'New product',
  'products.edit-price-list': 'Edit item',
  'products.search-product': 'Search product',

  'products.discounts.new-title': 'New discount',
  'products.discounts.edit-title': 'Edit discount',

  'products.discounts.title': 'Product escalation type',
  'products.discounts.types.discount': 'Discount',
  'products.discounts.types.price-list': 'Price list',

  'products.form-title.exhibition': 'Exhibition',
  'products.form-title.identification': 'Identification',
  'products.form-title.costs-and-discounts': 'Costs and Discounts',
  'products.form-title.price-lists': 'Price lists',
  'products.form-title.discounts': 'Discounts',
  'products.form-title.promotion': 'Promotion',
  'products.form-title.classification': 'Classification',
  'products.form-title.general': 'General',
  'products.form-title.stock': 'Stock',
  'products.form-title.dimensions': 'Dimensions',
  'products.form-title.tributations': 'Tributations',

  'products.ean': 'EAN',
  'products.sku': 'SKU',
  'products.internal-code': 'Internal code',
  'products.name': 'Name',
  'products.product-title': 'Title',
  'products.stock': 'Stock',
  'products.minimum-stock': 'Minimum stock',
  'products.manufacturer-code': 'Manufacturer code',
  'products.reference': 'Reference',
  'products.registered-at': 'Register date',
  'products.is-hot': 'Hot',

  'products.description': 'Description',
  'products.description-small': 'Description small',

  'products.cost-value': 'Cost price',
  'products.dollar-cost-price': 'Dollar cost price',

  'products.maximum-discount-percentage': 'Maximum discount %',
  'products.commission-percentage': 'Commission %',
  'products.percentage-applied': '% applied',
  'products.price': 'Price',
  'products.commission': 'Commission',
  'products.list-name': 'Price list',
  'products.is-denied-discount': 'Deny discount',
  'products.is-default': 'Default list',

  'products.discount-percentage': 'Discount %',
  'products.quantity-minimum-maximum': 'Minimum / maximum qty.',
  'products.quantity-minimum-value': 'Minimum: {value}',
  'products.quantity-maximum-value': 'Maximum: {value}',
  'products.quantity-minimum': 'Minimum quantity',
  'products.quantity-maximum': 'Maximum quantity',

  'products.unit-measure': 'Unit measure (output)',
  'products.input-unit-measure': 'Unit measure (input)',
  'products.is-allowed-negative-stock': 'Allows negative stock',
  'products.is-controlled-stock': 'Control stock',
  'products.brand': 'Brand',
  'products.promotional-price': 'Promotional price',
  'products.promotion-started-at': 'First day of promotion',
  'products.promotion-ended-at': 'Last day of promotion',
  'products.model': 'Model',
  'products.category': 'Category',
  'products.department': 'Department',
  'products.is-release': 'Release',
  'products.warranty': 'Warranty',
  'products.is-free-shipping': 'Free shipping',
  'products.http': 'HTTP',
  'products.https': 'HTTPS',
  'products.is-available-profile': 'Available on company profile (catalog)',
  'products.is-available-mobile': 'Available on mobile',
  'products.weight': 'Weight',
  'products.length': 'Length',
  'products.width': 'Width',
  'products.height': 'Height',
  'products.cubic-weight': 'Cubic weight',
  'products.ncm': 'NCM',
  'products.set-without-gtin': 'Set without gtin',
  'products.last-sale-at': 'Last sale',
  'products.last-purchase-at': 'Last purchase',

  'products.situation': 'Situation',
  'products.situation.normal': 'Normal',
  'products.situation.canceled': 'Canceled',
  'products.situation.undefined': 'Undefined',

  'products.type': 'Type',
  'products.type.product': 'Product',
  'products.type.service': 'Service',

  'products.is-enabled': 'Enabled',
  'products.is-enabled.enabled': 'Enabled',
  'products.is-enabled.disabled': 'Disabled',
  'products.is-enabled.undefined': 'Undefined',

  'products.deleted-product': 'Deleted product',
  'products.canceled-product': 'Canceled product',

  'products.no-products-found': 'No products found!',
  'products.discount-exceeds-maximum-discount-allowed':
    'The discount percentage exceeds the maximum discount allowed!',

  'products.min-date-promotion': 'The date should not be before the start date',

  'products.message.promotion-date': 'On promotion from {start} to {end}',

  'products.get-products-failure': 'Failed to load the products! 😢',
  'products.failed-to-search-products': 'Failed to search products! 😢',

  // Products - Share
  // TODO: Reutilizar futuramente
  // 'products-share.message.greetings': 'Hello! 😀',
  // 'products-share.message.company-name': 'Here is the {value}.',
  // 'products-share.message.our-catalog':
  //   '🛒 Take advantage of our store promotions 🥳, accessing our product catalog.',
  // 'products-share.message.check-link': 'Check out! Access 👇',
  // 'products-share.message.support': 'Any questions, we are available! 😊',

  'products-share.message.greetings': 'Hello!',
  'products-share.message.company-name': 'Here is the {value}.',
  'products-share.message.our-catalog':
    'Here is our product catalog and take advantage of our offers!',

  'products-share.email-subject': 'Products catalog',

  // Product requests
  'product-requests.priority.low': 'Low',
  'product-requests.priority.medium': 'Medium',
  'product-requests.priority.high': 'High',

  // Profile
  'profile.title': 'Profile',

  'profile.change-image': 'Change image',
  'profile.full-name': 'Full name',
  'profile.username': 'Username',
  'profile.email': 'Email',

  'profile.current-password': 'Current password',
  'profile.new-password': 'New password',
  'profile.confirm-password': 'Confirm your new password',
  'profile.equal-passwords':
    'New password and confirmation fields should be equal',
  'profile.diferent-passwords':
    'New password and the current password must be different! 🤔',

  // Promoter
  'promoters.title': 'Promoters',
  'promoters.new-title': 'New promoter',
  'promoters.check-exists.become-promoter': 'Become promoter',
  'promoters.check-exists.exists-view-title':
    'A promoter with this document was found:',
  'promoters.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a promoter:',
  'promoters.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a promoter at this company:',
  'promoters.check-exists.exists-not-shared-title':
    'This person is already registered as promoter at company:',

  'promoters.get-promoter-failure': 'Failed to load the promoter! 😢',
  'promoters.get-promoters-failure': 'Failed to load the promoters! 😢',

  // Region cities
  'region-cities.cities': 'Cities',

  'region-cities.remove': 'Remove',

  'region-cities.name': 'Name',
  'region-cities.city': 'City',
  'region-cities.ibge-code': 'IBGE Code',
  'region-cities.state': 'State',
  'region-cities.default-postal-code': 'Default postal code',

  // Region customers
  'region-customers.manage-customers': 'Manage customers',

  'region-customers.add-customer': 'Add customer',
  'region-customers.added-customers': 'Added customers',

  'region-customers.city-name': 'City: {value}',

  'region-customers.name': 'Name',
  'region-customers.state': 'State',
  'region-customers.city': 'City',

  'region-customers.get-customers-failure': 'Failed to load the customers!',

  // Regions
  'regions.title': 'Regions',
  'regions.region': 'Region',
  'regions.new-title': 'New region',

  'regions.name': 'Name',
  'regions.cities': 'Cities',
  'regions.sellers': 'Sellers',
  'regions.customers': 'Customers',

  'regions.is-enabled': 'Enabled',
  'regions.is-enabled.undefined': 'Undefined',
  'regions.is-enabled.enabled': 'Enabled',
  'regions.is-enabled.disabled': 'Disabled',

  // Regions sellers
  'region-sellers.manage-sellers': 'Manage sellers',

  'region-sellers.name': 'Name',

  // Registrations
  'registrations.title': 'Registrations',
  'registrations.new-title': 'New registration',
  'registrations.edit-title': 'Edit registration',

  'registrations.registration': 'Registration',

  // Reports
  'reports.title': 'Reports',
  'reports.filter-title': 'Filters',

  'reports.data-uninformed': 'Uninformed',

  'reports.sections.customer-title': 'Customers',

  'reports.sections.customers-by-cities.report-title': 'Customers by cities',
  'reports.sections.customers-by-cities.without-city': 'Without city',
  'reports.sections.customers-by-cities.name': 'Name',
  'reports.sections.customers-by-cities.phone-email': 'Phone / Email',
  'reports.sections.customers-by-cities.contact': 'Contact',
  'reports.sections.customers-by-cities.address': 'Address',

  'reports.sections.product-title': 'Products',

  'reports.sections.products-by-prices.report-title': 'Products by prices',
  'reports.sections.products-by-prices.title': 'Title',
  'reports.sections.products-by-prices.table.title': 'Name',
  'reports.sections.products-by-prices.ean': 'EAN: {value}',
  'reports.sections.products-by-prices.table.ean': 'EAN',
  'reports.sections.products-by-prices.internal-code': 'Internal code: {value}',
  'reports.sections.products-by-prices.table.internal-code': 'Internal code',
  'reports.sections.products-by-prices.name': 'Name',
  'reports.sections.products-by-prices.cost-value': 'Cost value: {value}',
  'reports.sections.products-by-prices.table.cost-value': 'Cost value',
  'reports.sections.products-by-prices.percentage-applied':
    'Percentage applied',
  'reports.sections.products-by-prices.price': 'Price',

  'reports.sections.products-by-prices.filter.minimum-cost-value':
    'Minimum cost value',
  'reports.sections.products-by-prices.filter.maximum-cost-value':
    'Maximum cost value',
  'reports.sections.products-by-prices.filter.minimum-price': 'Minimum price',
  'reports.sections.products-by-prices.filter.maximum-price': 'Maximum price',

  'reports.sections.products-top-selling.report-title': 'Top selling products',
  'reports.sections.products-top-selling.title': 'Title',
  'reports.sections.products-top-selling.average-unit-value':
    'Average unit value',
  'reports.sections.products-top-selling.total': 'Total',
  'reports.sections.products-top-selling.quantity': 'Quantity',
  'reports.sections.products-top-selling.total-average':
    'Total average unit value',
  'reports.sections.products-top-selling.total-quantity': 'Total quantity',

  'reports.sections.products-requests.report-title': 'Requested products',
  'reports.sections.products-requests.product': 'Product',
  'reports.sections.products-requests.quantity': 'Quantity',
  'reports.sections.products-requests.priority': 'Priority',
  'reports.sections.products-requests.created-at': 'Created at',

  'reports.sections.products-requests.filter.date': 'Date',

  'reports.sections.products-top-selling.filter.type': 'Type',
  'reports.sections.products-top-selling.filter.date': 'Date',
  'reports.sections.products-top-selling.filter.seller': 'Seller',
  'reports.sections.products-top-selling.filter.customer': 'Customer',
  'reports.sections.products-top-selling.filter.list-by': 'List by',
  'reports.sections.products-top-selling.filter.listed-by': 'Listed by',

  'reports.generating-report-message':
    'Report being prepared, please wait a moment!',

  'reports.of': 'of',
  'reports.empty-data': 'There is no information to be shown.',
  'reports.unavailable-report': 'Report unavailable at moment.',
  'reports.customized-date': 'From {start} to {end}',
  'reports.searched': 'Searched: {value}',
  'reports.ordered-by': 'Ordered by: {value} {direction}',
  'reports.direction.ascending': 'ascending',
  'reports.direction.descending': 'descending',

  'reports.failed-to-load-image': 'Failed to load the image! 😢',
  'reports.failed-to-generate-report': 'Failed to generate the report! 😢',

  // Reset Password
  'reset-password.title': 'Reset password',

  'reset-password.form-title': 'Create your new password',
  'reset-password.form-description':
    'Enter your new password and then confirm it.',

  'reset-password.password': 'Password',
  'reset-password.confirm-password': 'Confirm password',
  'reset-password.password-min-length':
    'The password must have at least 8 characters.',

  'reset-password.message.equal-passwords':
    'The password and the confirmation must be equals!',

  'reset-password.message.validation-failure.title':
    'Error when changing the password',
  'reset-password.message.validation-failure.description':
    'An error was encountered while trying to change the password. Please try again later.',

  'reset-password.message.update-success':
    'Your password has been changed successfully!',
  'reset-password.message.update-failure': 'Failed to change the password!',

  // Road Map
  'road-map.title': 'Road maps',
  'road-map.new-road-map': 'New roadmap',

  'road-map.form-title.promoter': 'Promoter',

  'road-map.manage-schedule': 'Manage schedule',

  // Rural Properties
  'rural-properties.title': 'Properties',
  'rural-properties.new-title': 'New property',

  'rural-properties.name': 'Name',
  'rural-properties.website': 'Website',
  'rural-properties.size-in-bushels': 'Size in bushels',
  'rural-properties.size-in-hectares': 'Size in hectares',
  'rural-properties.incra': 'INCRA',
  'rural-properties.nirf': 'NIRF',
  'rural-properties.car': 'CAR',
  'rural-properties.address': 'Address',
  'rural-properties.registered-at': 'Register date',

  'rural-properties.deleted-property': 'Deleted property',
  'rural-properties.disabled-property': 'Disabled property',

  'rural-properties.cadesp': 'CADESP',
  'rural-properties.cadesp-expiration-at': 'CADESP Expiration',
  'rural-properties.cadesp.undefined': 'Undefined',
  'rural-properties.cadesp.expire-today': 'Expire today',
  'rural-properties.cadesp.expire-in': 'Expire in',
  'rural-properties.cadesp.expired-in': 'Expired in',

  'rural-properties.situation': 'Situation',
  'rural-properties.situation.canceled': 'Canceled',
  'rural-properties.situation.normal': 'Normal',
  'rural-properties.situation.undefined': 'Undefined',

  'rural-properties.is-enabled': 'Enabled',
  'rural-properties.is-enabled.enabled': 'Enabled',
  'rural-properties.is-enabled.disabled': 'Disabled',
  'rural-properties.is-enabled.undefined': 'Undefined',

  'rural-properties.table-localization.delete-owner-text':
    'Are you sure you want to delete this owner from this property?',

  'rural-properties.get-properties-failure':
    'Failed to load the properties! 😢',

  // Seller Companies
  'seller-companies.bound-title': 'Bound companies',

  'seller-companies.name': 'Name',
  'seller-companies.permission-for-sale': 'Permission for sale',

  'seller-companies.get-seller-companies-failure':
    'Failed to load the companies! 😢',

  // Sellers
  'sellers.title': 'Sellers',
  'sellers.new-title': 'New seller',

  'sellers.deleted-seller': 'Deleted seller',
  'sellers.disabled-seller': 'Disabled seller',

  'sellers.check-exists.become-seller': 'Become seller',
  'sellers.check-exists.exists-view-title':
    'A seller with this document was found:',
  'sellers.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a seller:',
  'sellers.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a seller at this company:',

  'sellers.check-exists.exists-not-shared-title':
    'This person is already registered as seller at company:',

  'sellers.messages.create-bound-success': 'Successfully bound! 🎉',
  'sellers.messages.create-bound-failure':
    'Failed to create the bound, please try again! 😢',

  'sellers.messages.delete-bound-success': 'Successfully deleted the bound! 🎉',
  'sellers.messages.delete-bound-failure':
    'Failed to delete the bound, please try again! 😢',

  'sellers.get-seller-failure': 'Failed to load the seller! 😢',
  'sellers.get-sellers-failure': 'Failed to load the sellers! 😢',

  // Seller Users
  'seller-users.bound-title': 'Bound users',

  'seller-users.name': 'Name',
  'seller-users.email': 'Email',
  'seller-users.bound': 'Bound user',

  'seller-users.button-label.bound': 'Bound',
  'seller-users.button-label.remove-bound': 'Remove bound',

  'seller-users.messages.get-seller-users-failure':
    'Failed to load the users! 😢',

  // Settings
  'settings.title': 'Settings',
  'settings.edit-title': 'Edit setting',

  'settings.name': 'Name',
  'settings.value': 'Value',
  'settings.set-default-value': 'Set default value',

  'settings.type': 'Type',
  'settings.type.all': 'All',
  'settings.type.web': 'Web',
  'settings.type.mobile': 'Mobile',

  'settings.enabled': 'Enabled',
  'settings.disabled': 'Disabled',

  'settings.get-settings-failure': 'Failed to load the settings! 😢',

  // Sidebar
  'sidebar.customer-logo': 'Customer Logo',
  'sidebar.menu': 'Menu',
  'sidebar.others': 'Others',
  'sidebar.reports': 'Reports',

  'sidebar.contacts': 'Contacts',
  'sidebar.contacts.customers': 'Customers',
  'sidebar.contacts.suppliers': 'Suppliers',
  'sidebar.contacts.sellers': 'Sellers',
  'sidebar.contacts.employees': 'Employees',
  'sidebar.contacts.promoters': 'Promoters',
  'sidebar.contacts.transporters': 'Transporters',
  'sidebar.contacts.covenants': 'Covenants',
  'sidebar.contacts.owners': 'Owners',
  'sidebar.contacts.supervisor': 'Supervisors',
  'sidebar.contacts.branches-activities': 'Branches Activities',
  'sidebar.contacts.dispatching-agencies': 'Dispatching Agencies',

  'sidebar.financial': 'Financial',
  'sidebar.financial.accounts': 'Accounts',
  'sidebar.financial.bills-to-receive': 'Bills to Receive',
  'sidebar.financial.bills-to-pay': 'Bills to Pay',
  'sidebar.financial.bank-billings': 'Bank Billings',
  'sidebar.financial.chart-accounts': 'Chart Accounts',
  'sidebar.financial.cost-centers': 'Cost Centers',
  'sidebar.financial.payment-methods': 'Payment Methods',
  'sidebar.financial.payment-methods.bills-to-receive': 'Bills to Receive',
  'sidebar.financial.payment-methods.bills-to-pay': 'Bills to Pay',
  'sidebar.financial.banks': 'Banks',
  'sidebar.financial.payment-methods-types': 'Payments Methods',

  'sidebar.fiscal': 'Fiscal',
  'sidebar.fiscal.tax-situations': 'Tax Situations',
  'sidebar.fiscal.tributations': 'Tributations',
  'sidebar.fiscal.fiscal-operations': 'Fiscal Operations',
  'sidebar.fiscal.csts': 'CSTs',
  'sidebar.fiscal.sats': 'SAT',

  'sidebar.managerial': 'Managerial',
  'sidebar.managerial.rural-properties': 'Rural Properties',
  'sidebar.managerial.cultures': 'Cultures',
  'sidebar.managerial.harvests': 'Harvests',

  'sidebar.movements': 'Movements',
  'sidebar.movements.orders': 'Orders',
  'sidebar.movements.budget': 'Budgets',
  'sidebar.movements.shipping': 'Shippings',
  'sidebar.movements.bonification': 'Bonifications',
  'sidebar.movements.exchange': 'Exchanges',
  'sidebar.movements.sale': 'Sales',
  'sidebar.movements.purchase': 'Purchases',
  'sidebar.movements.visit': 'Visits',
  'sidebar.movements.notes': 'Notes',

  'sidebar.products': 'Products',
  'sidebar.products.list': 'List',
  'sidebar.products.inventories': 'Inventories',
  'sidebar.products.price-lists': 'Price Lists',
  'sidebar.products.brands': 'Brands',
  'sidebar.products.categories': 'Categories',
  'sidebar.products.departments': 'Departments',
  'sidebar.products.ncms': 'NCMs',
  'sidebar.products.units-measure': 'Units Measure',
  'sidebar.products.company-ncms': 'NCMs (Creation)',

  'sidebar.regions': 'Regions',
  'sidebar.regions.list': 'List',
  'sidebar.regions.cities': 'Cities',
  'sidebar.regions.states': 'States',
  'sidebar.regions.countries': 'Countries',

  'sidebar.roadmap': 'Roadmaps',
  'sidebar.roadmap.list': 'List',
  'sidebar.roadmap.teams': 'Teams',

  'sidebar.tools': 'Tools',
  'sidebar.tools.all-backups': 'All Backups',
  'sidebar.tools.all-devices': 'All Devices',
  'sidebar.tools.devices': 'Devices',
  'sidebar.tools.companies-pix': 'Companies Pix',
  'sidebar.tools.companies-billet': 'Companies Billet',

  // Sign In
  'sign-in.title': 'Welcome!',

  'sign-in.description': 'Sign in to your account to continue',
  'sign-in.login': 'Email or username',
  'sign-in.password': 'Password',
  'sign-in.remind-me': 'Remind me',
  'sign-in.forgot-password': 'Forgot password?',
  'sign-in.sign-in': 'Sign in',

  'sign-in.authentication-failed': 'Authentication failed, check your data! 🤔',
  'sign-in.company-failed': 'User does not belong to any company! 🤔',

  // Sign Up
  'sign-up.title': 'Register',

  'sign-up.description': 'Enter your information to register in the system',
  'sign-up.full-name': 'Full name',
  'sign-up.username': 'Username',
  'sign-up.email': 'Email',

  'sign-up.password': 'Password',
  'sign-up.confirm-password': 'Confirm your password',
  'sign-up.equal-passwords':
    'The password and confirmation fields should be equal!',

  'sign-up.sign-up': 'Sign up',
  'sign-up.return': 'Return',

  'sign-up.register-success': 'Registered successfully! 🎉',
  'sign-up.register-failed': 'Register failed, check your data! 🤔',

  // States
  'states.title': 'States',

  'states.name': 'Name',
  'states.initials': 'Initials',
  'states.ibge-code': 'IBGE Code',
  'states.default-aliquot': 'Default Aliquot',

  // Statistic Bar
  'statistic-bar.title-chip-label.from-start': 'From the start',

  // Subsidiary Companies
  'subsidiary-companies.title': 'Subsidiaries',
  'subsidiary-companies.new-title': 'New subsidiary',

  'subsidiary-companies.document': 'Document',

  // Supervisor
  'supervisors.title': 'Supervisors',
  'supervisors.new-title': 'New supervisor',
  'supervisors.check-exists.become-supervisor': 'Become supervisor',
  'supervisors.check-exists.exists-view-title':
    'A supervisor with this document was found:',
  'supervisors.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a supervisor:',
  'supervisors.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a supervisor at this company:',

  'supervisors.check-exists.exists-not-shared-title':
    'This person is already registered as supervisor at company:',
  'supervisors.get-supervisor-failure': 'Failed to load the supervisor! 😢',
  'supervisors.get-supervisors-failure': 'Failed to load the supervisors! 😢',

  // Suppliers
  'suppliers.title': 'Suppliers',
  'suppliers.new-title': 'New supplier',

  'suppliers.deleted-supplier': 'Deleted supplier',
  'suppliers.disabled-supplier': 'Disabled supplier',

  'suppliers.check-exists.become-supplier': 'Become supplier',
  'suppliers.check-exists.exists-view-title':
    'A supplier with this document was found:',
  'suppliers.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a supplier:',
  'suppliers.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a supplier at this company:',

  'suppliers.check-exists.exists-not-shared-title':
    'This person is already registered as supplier at company:',

  'suppliers.get-supplier-failure': 'Failed to load the supplier! 😢',

  // Switch company
  'switch-company.title': 'Switch companies',

  'switch-company.title-member':
    'You are member in {value, plural, =0 {0 company!} one {# company!} other {# companies!}}',

  'switch-company.description': 'Select the company that you want to access.',
  'switch-company.new-company': 'New company',
  'switch-company.type': 'Type',
  'switch-company.sign-out': 'Sign out',

  'switch-company.list.not-found-company': 'Company not found.',

  // System Categories
  'system-categories.get-system-categories-failure':
    'Failed to load the system categories! 😢',

  // Table Localization
  'table-localization.body.add-tooltip': 'Add',
  'table-localization.body.edit-tooltip': 'Edit',
  'table-localization.body.delete-tooltip': 'Delete',
  'table-localization.body.empty-data-source-message': 'No records found',
  'table-localization.body.delete-text':
    'Are you sure you want to delete this record?',
  'table-localization.body.save-tooltip': 'Save',
  'table-localization.body.cancel-tooltip': 'Cancel',
  'table-localization.body.filter-tooltip': 'Filter',

  'table-localization.grouping.grouped-by': 'Grouped by',
  'table-localization.grouping.placeholder': 'Drag headers to group',

  'table-localization.header.actions': 'Actions',

  'table-localization.pagination.first-aria-label': 'First page',
  'table-localization.pagination.first-tooltip': 'First page',
  'table-localization.pagination.label-displayed-rows': 'of',
  'table-localization.pagination.label-rows-per-page': 'Records per page: ',
  'table-localization.pagination.label-rows-select': 'Records',
  'table-localization.pagination.last-aria-label': 'Last page',
  'table-localization.pagination.last-tooltip': 'Last page',
  'table-localization.pagination.next-aria-label': 'Next page',
  'table-localization.pagination.next-tooltip': 'Next page',
  'table-localization.pagination.previous-tooltip': 'Previous page',
  'table-localization.pagination.previous-aria-label': 'Previous page',

  'table-localization.toolbar.add-remove-columns': 'Add or remove columns',
  'table-localization.toolbar.clear-search-aria-label': 'Clear search',
  'table-localization.toolbar.export-aria-label': 'Export',
  'table-localization.toolbar.export-title': 'Export',
  'table-localization.toolbar.export-csv-name': 'Export as CSV',
  'table-localization.toolbar.export-pdf-name': 'Export as PDF',
  'table-localization.toolbar.n-rows-selected': 'Selected row(s)',
  'table-localization.toolbar.search-tooltip': 'Search',
  'table-localization.toolbar.search-placeholder': 'Search',
  'table-localization.toolbar.show-columns-title': 'Show columns',
  'table-localization.toolbar.show-columns-aria-label': 'Show columns',

  // Tables
  'tables.yes': 'Yes',
  'tables.no': 'No',

  'tables.field-excluded': 'Excluded',
  'tables.field-disabled': 'Disabled',

  'tables.not-registered': '(Not registered)',

  // Tax Situations
  'tax-situations.title': 'Tax Situations',

  'tax-situations.code': 'Code',
  'tax-situations.description': 'Description',

  'tax-situations.type': 'Type',
  'tax-situations.type.ipi': 'IPI',
  'tax-situations.type.cst': 'CST',
  'tax-situations.type.csosn': 'CSOSN',
  'tax-situations.type.pis': 'PIS',
  'tax-situations.type.cofins': 'COFINS',

  'tax-situations.get-tax-situations-failure':
    'Failed to load the tax situations! 😢',

  // Teams
  'teams.title': 'Teams',
  'teams.team': 'Team',
  'teams.new-title': 'New team',
  'teams.name': 'Name',
  'teams.members': 'Members',
  'teams.supervisor': 'Supervisor',
  'teams.new-team': 'New team',
  'teams.is-enabled': 'Enabled',
  'teams.is-enabled.enabled': 'enabled',
  'teams.is-enabled.disabled': 'disabled',

  // Teams Members

  'team-members.name': 'Name',
  'team-members.manage-members': 'Manage members',
  'teams-members.get-members-failure': '',

  // Transporters
  'transporters.title': 'Transporters',
  'transporters.new-title': 'New transporter',

  'transporters.deleted-transporter': 'Deleted transporter',
  'transporters.disabled-transporter': 'Disabled transporter',

  'transporters.check-exists.become-transporter': 'Become transporter',
  'transporters.check-exists.exists-view-title':
    'A transporter with this document was found:',
  'transporters.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a transporter:',
  'transporters.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a transporter at this company:',

  'transporters.check-exists.exists-not-shared-title':
    'This person is already registered as transporter at company:',

  'transporters.get-transporter-failure': 'Failed to load the transporter! 😢',

  // Units Measure
  'units-measure.title': 'Units Measure',
  'units-measure.new-title': 'New unit measure',
  'units-measure.create-new-title': 'Create new unit measure',
  'units-measure.edit-title': 'Edit unit measure',

  'units-measure.name': 'Name',
  'units-measure.abbreviation': 'Abbreviation',
  'units-measure.description': 'Description',
  'units-measure.quantity-unit': 'Quantity unit',

  'units-measure.service-unit': 'Service unit',
  'units-measure.service-unit.daily': 'Daily',
  'units-measure.service-unit.hour': 'Hour',
  'units-measure.service-unit.unique': 'Unique',

  'units-measure.get-units-measure-failure':
    'Failed to load the units measure! 😢',
  'units-measure.create-unit-measure-success':
    'Unit measure successfully registered! 🎉',

  // Users
  'users.new-title': 'New user',

  'users.new-user': 'New user',
};
