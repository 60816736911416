import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  requestError: false,
  costCenterModalOpen: false,
  newRegister: false,
};

export default function costCenters(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COST_CENTERS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.COST_CENTERS_GET_SUCCESS: {
        draft.list = action.payload.costCenters;
        draft.loading = false;
        draft.requestError = false;
        break;
      }
      case Types.COST_CENTERS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COST_CENTERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COST_CENTERS_CREATE_SUCCESS: {
        const { costCenter } = action.payload;
        const costCenterList = draft.list;
        costCenterList.push(costCenter);

        const sortedCostCenterList = costCenterList.sort((a, b) => {
          if (a.number.length === 4 && b.number.length === 4) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number.length === 8 && b.number.length === 8) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number && b.number) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          return 0;
        });

        draft.list = sortedCostCenterList;
        draft.detail = {};
        draft.loading = false;
        draft.costCenterModalOpen = false;
        break;
      }
      case Types.COST_CENTERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COST_CENTERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COST_CENTERS_UPDATE_SUCCESS: {
        const { newCostCenter } = action.payload;

        const costCenterList = draft.list.map(costCenter => {
          return costCenter.id === newCostCenter.id
            ? newCostCenter
            : costCenter;
        });

        const sortedCostCenterList = costCenterList.sort((a, b) => {
          if (a.number.length === 4 && b.number.length === 4) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number.length === 8 && b.number.length === 8) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number && b.number) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          return 0;
        });

        draft.list = sortedCostCenterList;
        draft.detail = {};
        draft.loading = false;
        draft.costCenterModalOpen = false;
        break;
      }
      case Types.COST_CENTERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COST_CENTERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COST_CENTERS_DELETE_SUCCESS: {
        draft.list = state.list.filter(costCenter => {
          return costCenter.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.COST_CENTERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COST_CENTERS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        break;
      }

      case Types.COST_CENTERS_OPEN_MODAL: {
        const { id, newRegister } = action.payload;

        draft.newRegister = newRegister;
        draft.detail = {};

        if (id) {
          draft.detail = state.list.find(costCenter => {
            return costCenter.id === id;
          });
        }

        draft.costCenterModalOpen = true;
        break;
      }
      case Types.COST_CENTERS_CLOSE_MODAL: {
        draft.newRegister = false;
        draft.detail = {};
        draft.costCenterModalOpen = false;

        break;
      }

      default:
    }
  });
}
