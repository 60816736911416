import styled from 'styled-components';

import { Event as MuiEvent } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'info':
      styles = ``;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Event = styled(MuiEvent)`
  ${({ type }) => defineStyles(type)}
`;
