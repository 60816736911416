import Types from './types';

export function getBillToReceivePixs(pixs) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_GET,
    payload: { pixs },
  };
}

export function createBillToReceivePixRequest(pix, pixType) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_CREATE_REQUEST,
    payload: { pix, pixType },
  };
}

export function createBillToReceivePixSuccess(pix, pixType) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_CREATE_SUCCESS,
    payload: { pix, pixType },
  };
}

export function createBillToReceivePixFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_CREATE_FAILURE,
  };
}

export function updateBillToReceivePixRequest(pix_id) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_UPDATE_REQUEST,
    payload: { pix_id },
  };
}

export function updateBillToReceivePixSuccess(newPix) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_UPDATE_SUCCESS,
    payload: { newPix },
  };
}

export function updateBillToReceivePixFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_UPDATE_FAILURE,
  };
}

export function settlementBillToReceivePixRequest(pix_id, newPix) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_REQUEST,
    payload: { pix_id, newPix },
  };
}

export function settlementBillToReceivePixSuccess(newPix) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_SUCCESS,
    payload: { newPix },
  };
}

export function settlementBillToReceivePixFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_FAILURE,
  };
}

export function deleteBillToReceivePixRequest(pix_id) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_DELETE_REQUEST,
    payload: { pix_id },
  };
}

export function deleteBillToReceivePixSuccess(pix_id) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_DELETE_SUCCESS,
    payload: { pix_id },
  };
}

export function deleteBillToReceivePixFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_DELETE_FAILURE,
  };
}

export function resetListBillToReceivePix() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_RESET_LIST,
  };
}

export function openModalBillToReceivePix({ id, type }) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_OPEN_MODAL,
    payload: { id, type },
  };
}

export function closeModalBillToReceivePix() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_CLOSE_MODAL,
  };
}

export function openModalBillToReceiveAddAccountPix(
  billData,
  isCreatingPixFromList
) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_OPEN_MODAL,
    payload: { billData, isCreatingPixFromList },
  };
}

export function closeModalBillToReceiveAddAccountPix() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_CLOSE_MODAL,
  };
}

export function setSelectedAccountBillToReceivePix(account) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SET_SELECTED_ACCOUNT,
    payload: { account },
  };
}

export function openModalBillToReceivePixDetail(id, pix = null) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_DETAIL_OPEN_MODAL,
    payload: { id, pix },
  };
}

export function closeModalBillToReceivePixDetail() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_DETAIL_CLOSE_MODAL,
  };
}

export function openModalBillToReceivePixShare(id, pix = null) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SHARE_OPEN_MODAL,
    payload: { id, pix },
  };
}

export function closeModalBillToReceivePixShare() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SHARE_CLOSE_MODAL,
  };
}

export function openModalBillToReceivePixSettlement(id) {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalBillToReceivePixSettlement() {
  return {
    type: Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_CLOSE_MODAL,
  };
}
