import Types from './types';

export function getCustomerPhones(phones) {
  return {
    type: Types.CUSTOMER_PHONES_GET,
    payload: { phones },
  };
}

export function createCustomerPhoneRequest(customer_id, phone) {
  return {
    type: Types.CUSTOMER_PHONES_CREATE_REQUEST,
    payload: { customer_id, phone },
  };
}

export function createCustomerPhoneSuccess(phone) {
  return {
    type: Types.CUSTOMER_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createCustomerPhoneFailure() {
  return {
    type: Types.CUSTOMER_PHONES_CREATE_FAILURE,
  };
}

export function updateCustomerPhoneRequest(customer_id, phone_id, newPhone) {
  return {
    type: Types.CUSTOMER_PHONES_UPDATE_REQUEST,
    payload: { customer_id, phone_id, newPhone },
  };
}

export function updateCustomerPhoneSuccess(newPhone) {
  return {
    type: Types.CUSTOMER_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateCustomerPhoneFailure() {
  return {
    type: Types.CUSTOMER_PHONES_UPDATE_FAILURE,
  };
}

export function deleteCustomerPhoneRequest(customer_id, phone_id) {
  return {
    type: Types.CUSTOMER_PHONES_DELETE_REQUEST,
    payload: { customer_id, phone_id },
  };
}

export function deleteCustomerPhoneSuccess(phone_id) {
  return {
    type: Types.CUSTOMER_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteCustomerPhoneFailure() {
  return {
    type: Types.CUSTOMER_PHONES_DELETE_FAILURE,
  };
}

export function resetListCustomerPhone() {
  return {
    type: Types.CUSTOMER_PHONES_RESET_LIST,
  };
}

export function openModalCustomerPhone(phone_id) {
  return {
    type: Types.CUSTOMER_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalCustomerPhone() {
  return {
    type: Types.CUSTOMER_PHONES_CLOSE_MODAL,
  };
}
