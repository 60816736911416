import React from 'react';

import PropTypes from 'prop-types';

import { FilterList } from './styles';

export default function FilterIcon({ type }) {
  return <FilterList type={type} />;
}

FilterIcon.propTypes = {
  type: PropTypes.string,
};

FilterIcon.defaultProps = {
  type: '',
};
