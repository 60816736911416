const Types = {
  OWNER_FILES_GET: '@owners/GET_OWNER_FILES',

  OWNER_FILES_CREATE_REQUEST: '@owners/CREATE_OWNER_FILE_REQUEST',
  OWNER_FILES_CREATE_SUCCESS: '@owners/CREATE_OWNER_FILE_SUCCESS',
  OWNER_FILES_CREATE_FAILURE: '@owners/CREATE_OWNER_FILE_FAILURE',

  OWNER_FILES_UPDATE_REQUEST: '@owners/UPDATE_OWNER_FILE_REQUEST',
  OWNER_FILES_UPDATE_SUCCESS: '@owners/UPDATE_OWNER_FILE_SUCCESS',
  OWNER_FILES_UPDATE_FAILURE: '@owners/UPDATE_OWNER_FILE_FAILURE',

  OWNER_FILES_DELETE_REQUEST: '@owners/DELETE_OWNER_FILE_REQUEST',
  OWNER_FILES_DELETE_SUCCESS: '@owners/DELETE_OWNER_FILE_SUCCESS',
  OWNER_FILES_DELETE_FAILURE: '@owners/DELETE_OWNER_FILE_FAILURE',

  OWNER_FILES_OPEN_MODAL: '@owners/OPEN_MODAL_OWNER_FILE',
  OWNER_FILES_CLOSE_MODAL: '@owners/CLOSE_MODAL_OWNER_FILE',

  OWNER_FILES_RESET_LIST: '@owners/RESET_LIST_OWNER_FILE',
};

export default Types;
