import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getAllBackupDatabasesSuccess,
  getAllBackupDatabasesFailure,
} from './actions';
import Types from './types';

export function* getAllBackupDatabases() {
  try {
    const response = yield call(api.get, 'all-backups/databases');

    yield put(getAllBackupDatabasesSuccess(response.data));
  } catch (err) {
    yield put(getAllBackupDatabasesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([
  takeLatest(Types.ALL_BACKUP_DATABASES_GET_REQUEST, getAllBackupDatabases),
]);
