import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import { ChipContainer } from './styles';

export default function IconChip({ properties }) {
  const { formatMessage } = useIntl();

  return (
    <ChipContainer color={properties.color}>
      {properties.icon}
      {formatMessage({ id: properties.label })}
    </ChipContainer>
  );
}

IconChip.propTypes = {
  properties: PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
  }).isRequired,
};
