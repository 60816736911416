import React from 'react';

import PropTypes from 'prop-types';

import { Share } from './styles';

export default function ShareIcon({ type }) {
  return <Share type={type} />;
}

ShareIcon.propTypes = {
  type: PropTypes.string,
};

ShareIcon.defaultProps = {
  type: '',
};
