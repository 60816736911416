import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from 'styled-components';

import { ptBR } from 'date-fns/locale';
import { PersistGate } from 'redux-persist/integration/react';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';

import AppLocale from '~/lang';
import Routes from '~/routes/Routes';
import history from '~/services/history';
import { persistor } from '~/store';
import { setTheme } from '~/store/modules/theme/actions';
import maTheme from '~/theme';

import GlobalStyle from './styles/global';

const currentAppLocale = AppLocale.pt;

/* TODO: fazer a mudanca do idioma */
function App() {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme);

  useEffect(() => {
    const themeCode = process.env.REACT_APP_THEME || 0;

    dispatch(setTheme(themeCode));
  }, [dispatch]);

  return (
    <PersistGate persistor={persistor}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Router history={history}>
          <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
                <ThemeProvider theme={maTheme[theme.currentTheme]}>
                  <Routes />
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </Router>
        <ToastContainer />
        <GlobalStyle />
      </IntlProvider>
    </PersistGate>
  );
}

export default App;
