import React from 'react';

import PropTypes from 'prop-types';

import { Stamp } from './styles';

export default function StampIcon({ type }) {
  return <Stamp type={type} />;
}

StampIcon.propTypes = {
  type: PropTypes.string,
};

StampIcon.defaultProps = {
  type: '',
};
