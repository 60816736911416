import Types from './types';

export function createBankBillingPixRequest(pix, pixType) {
  return {
    type: Types.BANK_BILLING_PIXS_CREATE_REQUEST,
    payload: { pix, pixType },
  };
}

export function createBankBillingPixSuccess(pix, pixType) {
  return {
    type: Types.BANK_BILLING_PIXS_CREATE_SUCCESS,
    payload: { pix, pixType },
  };
}

export function createBankBillingPixFailure() {
  return {
    type: Types.BANK_BILLING_PIXS_CREATE_FAILURE,
  };
}

export function updateBankBillingPixRequest(pix_id) {
  return {
    type: Types.BANK_BILLING_PIXS_UPDATE_REQUEST,
    payload: { pix_id },
  };
}

export function updateBankBillingPixSuccess(newPix) {
  return {
    type: Types.BANK_BILLING_PIXS_UPDATE_SUCCESS,
    payload: { newPix },
  };
}

export function updateBankBillingPixFailure() {
  return {
    type: Types.BANK_BILLING_PIXS_UPDATE_FAILURE,
  };
}

export function settlementBankBillingPixRequest(pix_id, newPix) {
  return {
    type: Types.BANK_BILLING_PIXS_SETTLEMENT_REQUEST,
    payload: {
      pix_id,
      newPix,
    },
  };
}

export function settlementBankBillingPixSuccess(newPix) {
  return {
    type: Types.BANK_BILLING_PIXS_SETTLEMENT_SUCCESS,
    payload: {
      newPix,
    },
  };
}

export function settlementBankBillingPixFailure() {
  return {
    type: Types.BANK_BILLING_PIXS_SETTLEMENT_FAILURE,
  };
}

export function resetListBankBillingPix() {
  return {
    type: Types.BANK_BILLING_PIXS_RESET_LIST,
  };
}

export function openModalBankBillingPix() {
  return {
    type: Types.BANK_BILLING_PIXS_OPEN_MODAL,
  };
}

export function closeModalBankBillingPix() {
  return {
    type: Types.BANK_BILLING_PIXS_CLOSE_MODAL,
  };
}

export function openModalBankBillingEmitPix({ id, type }) {
  return {
    type: Types.BANK_BILLING_PIXS_EMIT_OPEN_MODAL,
    payload: { id, type },
  };
}

export function closeModalBankBillingEmitPix() {
  return {
    type: Types.BANK_BILLING_PIXS_EMIT_CLOSE_MODAL,
  };
}

export function openModalBankBillingAddAccountPix() {
  return {
    type: Types.BANK_BILLING_PIXS_ADD_ACCOUNT_OPEN_MODAL,
  };
}

export function closeModalBankBillingAddAccountPix() {
  return {
    type: Types.BANK_BILLING_PIXS_ADD_ACCOUNT_CLOSE_MODAL,
  };
}

export function setSelectedAccountBankBillingPix(account) {
  return {
    type: Types.BANK_BILLING_PIXS_SET_SELECTED_ACCOUNT,
    payload: { account },
  };
}

export function openModalBankBillingPixDetail(id, pix = null) {
  return {
    type: Types.BANK_BILLING_PIXS_DETAIL_OPEN_MODAL,
    payload: { id, pix },
  };
}

export function closeModalBankBillingPixDetail() {
  return {
    type: Types.BANK_BILLING_PIXS_DETAIL_CLOSE_MODAL,
  };
}

export function openModalBankBillingPixShare(id, pix = null) {
  return {
    type: Types.BANK_BILLING_PIXS_SHARE_OPEN_MODAL,
    payload: { id, pix },
  };
}

export function closeModalBankBillingPixShare() {
  return {
    type: Types.BANK_BILLING_PIXS_SHARE_CLOSE_MODAL,
  };
}

export function openModalBankBillingPixSettlement(id) {
  return {
    type: Types.BANK_BILLING_PIXS_SETTLEMENT_OPEN_MODAL,
    payload: {
      id,
    },
  };
}

export function closeModalBankBillingPixSettlement() {
  return {
    type: Types.BANK_BILLING_PIXS_SETTLEMENT_CLOSE_MODAL,
  };
}
