import Types from './types';

export function getTransporterEmails(emails) {
  return {
    type: Types.TRANSPORTER_EMAILS_GET,
    payload: { emails },
  };
}

export function createTransporterEmailRequest(transporter_id, email) {
  return {
    type: Types.TRANSPORTER_EMAILS_CREATE_REQUEST,
    payload: { transporter_id, email },
  };
}

export function createTransporterEmailSuccess(email) {
  return {
    type: Types.TRANSPORTER_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createTransporterEmailFailure() {
  return {
    type: Types.TRANSPORTER_EMAILS_CREATE_FAILURE,
  };
}

export function updateTransporterEmailRequest(
  transporter_id,
  email_id,
  newEmail
) {
  return {
    type: Types.TRANSPORTER_EMAILS_UPDATE_REQUEST,
    payload: { transporter_id, email_id, newEmail },
  };
}

export function updateTransporterEmailSuccess(newEmail) {
  return {
    type: Types.TRANSPORTER_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateTransporterEmailFailure() {
  return {
    type: Types.TRANSPORTER_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteTransporterEmailRequest(transporter_id, email_id) {
  return {
    type: Types.TRANSPORTER_EMAILS_DELETE_REQUEST,
    payload: { transporter_id, email_id },
  };
}

export function deleteTransporterEmailSuccess(email_id) {
  return {
    type: Types.TRANSPORTER_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteTransporterEmailFailure() {
  return {
    type: Types.TRANSPORTER_EMAILS_DELETE_FAILURE,
  };
}

export function resetListTransporterEmail() {
  return {
    type: Types.TRANSPORTER_EMAILS_RESET_LIST,
  };
}

export function openModalTransporterEmail(email_id) {
  return {
    type: Types.TRANSPORTER_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalTransporterEmail() {
  return {
    type: Types.TRANSPORTER_EMAILS_CLOSE_MODAL,
  };
}
