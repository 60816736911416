import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { resetFoundPerson } from '../persons/actions';
import {
  getSellerAddresses,
  openModalSellerAddress,
  resetListSellerAddress,
} from '../sellerAddresses/actions';
import {
  getSellerCompanies,
  resetListSellerCompany,
} from '../sellerCompanies/actions';
import {
  getSellerEmails,
  openModalSellerEmail,
  resetListSellerEmail,
} from '../sellerEmails/actions';
import { getSellerFiles, resetListSellerFile } from '../sellerFiles/actions';
import {
  getSellerPhones,
  openModalSellerPhone,
  resetListSellerPhone,
} from '../sellerPhones/actions';
import { getSellerUsers, resetListSellerUser } from '../sellerUsers/actions';
import {
  getSellersSuccess,
  getSellersFailure,
  createSellerSuccess,
  createSellerFailure,
  deleteSellerSuccess,
  deleteSellerFailure,
  becomeSellerSuccess,
  becomeSellerFailure,
  getDetailsSellerSuccess,
  updateSellerSuccess,
  updateSellerFailure,
  getDetailsSellerFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getSellers({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `sellers${queryParams}`);

    yield put(
      getSellersSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getSellersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createSeller({ payload }) {
  try {
    const { seller, subResourceType } = payload;

    const response = yield call(api.post, 'sellers', seller);

    yield put(createSellerSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/seller/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSeller({ payload }) {
  try {
    const { id, newSeller } = payload;

    const response = yield call(api.put, `sellers/${id}`, newSeller);

    yield put(updateSellerSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSeller({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `sellers/${id}`);

    yield put(deleteSellerSuccess(id));

    history.push('/sellers');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeSeller({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'SELLER';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeSellerSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/seller/${id}`);
  } catch (err) {
    yield put(becomeSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsSeller({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `sellers/${id}`);

    const {
      phones,
      addresses,
      emails,
      companies,
      seller_company_user,
      files,
      ...rest
    } = response.data;

    yield put(getSellerPhones(phones));

    yield put(getSellerAddresses(addresses));

    yield put(getSellerCompanies(companies));

    yield put(getSellerEmails(emails));

    yield put(getSellerUsers(seller_company_user));

    yield put(getSellerFiles(files));

    yield put(getDetailsSellerSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.sellers
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalSellerPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalSellerEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalSellerAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsSeller() {
  yield put(resetListSellerPhone());

  yield put(resetListSellerAddress());

  yield put(resetListSellerEmail());

  yield put(resetListSellerCompany());

  yield put(resetListSellerUser());

  yield put(resetListSellerFile());
}

export default all([
  takeLatest(Types.SELLERS_GET_REQUEST, getSellers),
  takeLatest(Types.SELLERS_CREATE_REQUEST, createSeller),
  takeLatest(Types.SELLERS_UPDATE_REQUEST, updateSeller),
  takeLatest(Types.SELLERS_DELETE_REQUEST, deleteSeller),
  takeLatest(Types.SELLERS_BECOME_REQUEST, becomeSeller),
  takeLatest(Types.SELLERS_GET_DETAILS_REQUEST, getDetailsSeller),
  takeLatest(Types.SELLERS_RESET_DETAILS, resetDetailsSeller),
]);
