import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  updateRegionCitySuccess,
  updateRegionCityFailure,
  deleteRegionCitySuccess,
  deleteRegionCityFailure,
} from './actions';
import Types from './types';

export function* updateRegionCity({ payload }) {
  try {
    const { region_id, cities } = payload;

    const response = yield call(api.put, `regions/${region_id}/cities`, cities);

    yield put(updateRegionCitySuccess(response.data));

    toast.success(<IntlMessages id="messages.change-success" />);
  } catch (err) {
    yield put(updateRegionCityFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.change-failure" />);
  }
}

export function* deleteRegionCity({ payload }) {
  try {
    const { region_id, region_city_id } = payload;
    yield call(api.delete, `regions/${region_id}/cities/${region_city_id}`);

    yield put(deleteRegionCitySuccess(region_city_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRegionCityFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.REGION_CITIES_UPDATE_REQUEST, updateRegionCity),
  takeLatest(Types.REGION_CITIES_DELETE_REQUEST, deleteRegionCity),
]);
