import Types from './types';

export function getSupplierFiles(files) {
  return {
    type: Types.SUPPLIER_FILES_GET,
    payload: { files },
  };
}

export function createSupplierFileRequest(supplier_id, file) {
  return {
    type: Types.SUPPLIER_FILES_CREATE_REQUEST,
    payload: { supplier_id, file },
  };
}

export function createSupplierFileSuccess(file) {
  return {
    type: Types.SUPPLIER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createSupplierFileFailure() {
  return {
    type: Types.SUPPLIER_FILES_CREATE_FAILURE,
  };
}

export function updateSupplierFileRequest(supplier_id, file_id, newFile) {
  return {
    type: Types.SUPPLIER_FILES_UPDATE_REQUEST,
    payload: { supplier_id, file_id, newFile },
  };
}

export function updateSupplierFileSuccess(newFile) {
  return {
    type: Types.SUPPLIER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateSupplierFileFailure() {
  return {
    type: Types.SUPPLIER_FILES_UPDATE_FAILURE,
  };
}

export function deleteSupplierFileRequest(supplier_id, file_id) {
  return {
    type: Types.SUPPLIER_FILES_DELETE_REQUEST,
    payload: { supplier_id, file_id },
  };
}

export function deleteSupplierFileSuccess(file_id) {
  return {
    type: Types.SUPPLIER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteSupplierFileFailure() {
  return {
    type: Types.SUPPLIER_FILES_DELETE_FAILURE,
  };
}

export function openModalSupplierFile() {
  return {
    type: Types.SUPPLIER_FILES_OPEN_MODAL,
  };
}

export function closeModalSupplierFile() {
  return {
    type: Types.SUPPLIER_FILES_CLOSE_MODAL,
  };
}

export function resetListSupplierFile() {
  return {
    type: Types.SUPPLIER_FILES_RESET_LIST,
  };
}
