const Types = {
  CUSTOMER_ADDRESSES_GET: '@customers/GET_CUSTOMER_ADDRESSES',

  CUSTOMER_ADDRESSES_CREATE_REQUEST:
    '@customers/CREATE_CUSTOMER_ADDRESS_REQUEST',
  CUSTOMER_ADDRESSES_CREATE_SUCCESS:
    '@customers/CREATE_CUSTOMER_ADDRESS_SUCCESS',
  CUSTOMER_ADDRESSES_CREATE_FAILURE:
    '@customers/CREATE_CUSTOMER_ADDRESS_FAILURE',

  CUSTOMER_ADDRESSES_UPDATE_REQUEST:
    '@customers/UPDATE_CUSTOMER_ADDRESS_REQUEST',
  CUSTOMER_ADDRESSES_UPDATE_SUCCESS:
    '@customers/UPDATE_CUSTOMER_ADDRESS_SUCCESS',
  CUSTOMER_ADDRESSES_UPDATE_FAILURE:
    '@customers/UPDATE_CUSTOMER_ADDRESS_FAILURE',

  CUSTOMER_ADDRESSES_DELETE_REQUEST:
    '@customers/DELETE_CUSTOMER_ADDRESS_REQUEST',
  CUSTOMER_ADDRESSES_DELETE_SUCCESS:
    '@customers/DELETE_CUSTOMER_ADDRESS_SUCCESS',
  CUSTOMER_ADDRESSES_DELETE_FAILURE:
    '@customers/DELETE_CUSTOMER_ADDRESS_FAILURE',

  CUSTOMER_ADDRESSES_RESET_LIST: '@customers/RESET_LIST_CUSTOMER_ADDRESS',

  CUSTOMER_ADDRESSES_OPEN_MODAL: '@customers/OPEN_MODAL_CUSTOMER_ADDRESS',
  CUSTOMER_ADDRESSES_CLOSE_MODAL: '@customers/CLOSE_MODAL_CUSTOMER_ADDRESS',
};

export default Types;
