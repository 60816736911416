import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createTransporterEmailSuccess,
  createTransporterEmailFailure,
  updateTransporterEmailSuccess,
  updateTransporterEmailFailure,
  deleteTransporterEmailSuccess,
  deleteTransporterEmailFailure,
} from './actions';
import Types from './types';

export function* createTransporterEmail({ payload }) {
  try {
    const { transporter_id, email } = payload;

    const response = yield call(
      api.post,
      `transporters/${transporter_id}/emails`,
      email
    );

    yield put(createTransporterEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createTransporterEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateTransporterEmail({ payload }) {
  try {
    const { transporter_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `transporters/${transporter_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateTransporterEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateTransporterEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteTransporterEmail({ payload }) {
  const { transporter_id, email_id } = payload;

  const emails = yield select(state => state.transporterEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteTransporterEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `transporters/${transporter_id}/emails/${email_id}`);

    yield put(deleteTransporterEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteTransporterEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.TRANSPORTER_EMAILS_CREATE_REQUEST, createTransporterEmail),
  takeLatest(Types.TRANSPORTER_EMAILS_UPDATE_REQUEST, updateTransporterEmail),
  takeLatest(Types.TRANSPORTER_EMAILS_DELETE_REQUEST, deleteTransporterEmail),
]);
