import async from '~/components/Async';

const Page404 = async(() => import('~/pages/Page404'));
const ForgotPassword = async(() => import('~/pages/ForgotPassword'));
const SignIn = async(() => import('~/pages/SignIn'));
const ResetPassword = async(() => import('~/pages/ResetPassword'));
// const SignUp = async(() => import('~/pages/SignUp'));

export const authRoutes = {
  children: [
    {
      path: '/',
      component: SignIn,
    },
    // {
    //   path: '/register',
    //   component: SignUp,
    // },
    {
      path: '/forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/reset-password/:id',
      component: ResetPassword,
    },
    {
      path: '/error-404',
      component: Page404,
    },
  ],
};
