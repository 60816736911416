import produce from 'immer';

import AuthTypes from '../auth/types';
import BranchActivityTypes from '../branchesActivities/types';
import CovenantTypes from '../covenants/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,
  companyObservationModalOpen: false,
  newBranchActivity: {},
  newCovenant: {},
  openFileDialog: false,
};

export default function company(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COMPANIES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }

      case Types.COMPANIES_GET_SUCCESS: {
        const { companies } = action.payload;

        draft.list = companies;
        draft.loading = false;
        draft.requestError = false;
        break;
      }

      case Types.COMPANIES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANIES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.company);
        draft.loading = false;
        break;
      }
      case Types.COMPANIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANIES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANIES_UPDATE_SUCCESS: {
        const { newCompany } = action.payload;

        draft.detail = newCompany;
        draft.companyObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.COMPANIES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANIES_DELETE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.COMPANIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      // case Types.COMPANIES_BECOME_REQUEST: {
      //   draft.loading = true;
      //   break;
      // }
      // case Types.COMPANIES_BECOME_SUCCESS: {
      //   draft.loading = false;
      //   break;
      // }
      // case Types.COMPANIES_BECOME_FAILURE: {
      //   draft.loading = false;
      //   break;
      // }

      case Types.COMPANIES_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.COMPANIES_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.company;
        draft.requestError = false;
        break;
      }
      case Types.COMPANIES_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.COMPANIES_RESET_DETAILS: {
        draft.detail = {};
        draft.companyObservationModalOpen = false;
        break;
      }

      case Types.COMPANIES_OBSERVATION_OPEN_MODAL: {
        draft.companyObservationModalOpen = true;
        break;
      }
      case Types.COMPANIES_OBSERVATION_CLOSE_MODAL: {
        draft.companyObservationModalOpen = false;
        break;
      }

      case Types.COMPANIES_CLEAR_NEW_BRANCH_ACTIVITY: {
        draft.newBranchActivity = {};
        break;
      }

      case Types.COMPANIES_CLEAR_NEW_COVENANT: {
        draft.newCovenant = {};
        break;
      }

      case Types.COMPANIES_RESET_SUB_RESOURCE_DATA: {
        draft.openFileDialog = false;
        break;
      }

      case Types.COMPANIES_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case AuthTypes.SIGN_OUT: {
        draft.list = [];

        break;
      }

      case BranchActivityTypes.BRANCHES_ACTIVITIES_CREATE_SUCCESS: {
        const { source, branchActivity } = action.payload;

        if (source === 'COMPANY') {
          draft.newBranchActivity = branchActivity;
        }

        break;
      }

      case CovenantTypes.COVENANTS_CREATE_SUCCESS: {
        const { source, covenant } = action.payload;

        if (source === 'COMPANY') {
          draft.newCovenant = covenant;
        }

        break;
      }

      default:
    }
  });
}
