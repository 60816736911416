import Types from './types';

export function getContactAddresses(addresses) {
  return {
    type: Types.CONTACT_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function resetListContactAddress() {
  return {
    type: Types.CONTACT_ADDRESSES_RESET_LIST,
  };
}
