import Types from './types';

export function updatePersonRequest(id, newPerson) {
  return {
    type: Types.PERSONS_UPDATE_REQUEST,
    payload: { id, newPerson },
  };
}

export function updatePersonSuccess(newPerson) {
  return {
    type: Types.PERSONS_UPDATE_SUCCESS,
    payload: { newPerson },
  };
}

export function updatePersonFailure() {
  return {
    type: Types.PERSONS_UPDATE_FAILURE,
  };
}

export function deletePersonRequest(id) {
  return {
    type: Types.PERSONS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deletePersonSuccess(id) {
  return {
    type: Types.PERSONS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deletePersonFailure() {
  return {
    type: Types.PERSONS_DELETE_FAILURE,
  };
}

export function getCheckExistsPersonRequest(document, type) {
  return {
    type: Types.PERSONS_CHECK_EXISTS_REQUEST,
    payload: { document, type },
  };
}

export function getCheckExistsPersonSuccess(person) {
  return {
    type: Types.PERSONS_CHECK_EXISTS_SUCCESS,
    payload: { person },
  };
}

export function getCheckExistsPersonFailure() {
  return {
    type: Types.PERSONS_CHECK_EXISTS_FAILURE,
  };
}

export function getDetailsPersonRequest(id) {
  return {
    type: Types.PERSONS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsPersonSuccess(person) {
  return {
    type: Types.PERSONS_GET_DETAILS_SUCCESS,
    payload: { person },
  };
}

export function getDetailsPersonFailure() {
  return {
    type: Types.PERSONS_GET_DETAILS_FAILURE,
  };
}

export function resetFoundPerson() {
  return {
    type: Types.PERSONS_RESET_FOUND,
  };
}

export function resetDetailsPerson() {
  return {
    type: Types.PERSONS_RESET_DETAILS,
  };
}
