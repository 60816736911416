import Types from './types';

export function getOwnerFiles(files) {
  return {
    type: Types.OWNER_FILES_GET,
    payload: { files },
  };
}

export function createOwnerFileRequest(owner_id, file) {
  return {
    type: Types.OWNER_FILES_CREATE_REQUEST,
    payload: { owner_id, file },
  };
}

export function createOwnerFileSuccess(file) {
  return {
    type: Types.OWNER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createOwnerFileFailure() {
  return {
    type: Types.OWNER_FILES_CREATE_FAILURE,
  };
}

export function updateOwnerFileRequest(owner_id, file_id, newFile) {
  return {
    type: Types.OWNER_FILES_UPDATE_REQUEST,
    payload: { owner_id, file_id, newFile },
  };
}

export function updateOwnerFileSuccess(newFile) {
  return {
    type: Types.OWNER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateOwnerFileFailure() {
  return {
    type: Types.OWNER_FILES_UPDATE_FAILURE,
  };
}

export function deleteOwnerFileRequest(owner_id, file_id) {
  return {
    type: Types.OWNER_FILES_DELETE_REQUEST,
    payload: { owner_id, file_id },
  };
}

export function deleteOwnerFileSuccess(file_id) {
  return {
    type: Types.OWNER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteOwnerFileFailure() {
  return {
    type: Types.OWNER_FILES_DELETE_FAILURE,
  };
}

export function openModalOwnerFile() {
  return {
    type: Types.OWNER_FILES_OPEN_MODAL,
  };
}

export function closeModalOwnerFile() {
  return {
    type: Types.OWNER_FILES_CLOSE_MODAL,
  };
}

export function resetListOwnerFile() {
  return {
    type: Types.OWNER_FILES_RESET_LIST,
  };
}
