const Types = {
  OWNER_ADDRESSES_GET: '@owners/GET_OWNER_ADDRESSES',

  OWNER_ADDRESSES_CREATE_REQUEST: '@owners/CREATE_OWNER_ADDRESS_REQUEST',
  OWNER_ADDRESSES_CREATE_SUCCESS: '@owners/CREATE_OWNER_ADDRESS_SUCCESS',
  OWNER_ADDRESSES_CREATE_FAILURE: '@owners/CREATE_OWNER_ADDRESS_FAILURE',

  OWNER_ADDRESSES_UPDATE_REQUEST: '@owners/UPDATE_OWNER_ADDRESS_REQUEST',
  OWNER_ADDRESSES_UPDATE_SUCCESS: '@owners/UPDATE_OWNER_ADDRESS_SUCCESS',
  OWNER_ADDRESSES_UPDATE_FAILURE: '@owners/UPDATE_OWNER_ADDRESS_FAILURE',

  OWNER_ADDRESSES_DELETE_REQUEST: '@owners/DELETE_OWNER_ADDRESS_REQUEST',
  OWNER_ADDRESSES_DELETE_SUCCESS: '@owners/DELETE_OWNER_ADDRESS_SUCCESS',
  OWNER_ADDRESSES_DELETE_FAILURE: '@owners/DELETE_OWNER_ADDRESS_FAILURE',

  OWNER_ADDRESSES_RESET_LIST: '@owners/RESET_LIST_OWNER_ADDRESS',

  OWNER_ADDRESSES_OPEN_MODAL: '@owners/OPEN_MODAL_OWNER_ADDRESS',
  OWNER_ADDRESSES_CLOSE_MODAL: '@owners/CLOSE_MODAL_OWNER_ADDRESS',
};

export default Types;
