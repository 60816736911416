const Types = {
  COMPANY_NCMS_GET_REQUEST: '@companyNcms/GET_COMPANY_NCMS_REQUEST',
  COMPANY_NCMS_GET_SUCCESS: '@companyNcms/GET_COMPANY_NCMS_SUCCESS',
  COMPANY_NCMS_GET_FAILURE: '@companyNcms/GET_COMPANY_NCMS_FAILURE',

  COMPANY_NCMS_CREATE_REQUEST: '@companyNcms/CREATE_COMPANY_NCM_REQUEST',
  COMPANY_NCMS_CREATE_SUCCESS: '@companyNcms/CREATE_COMPANY_NCM_SUCCESS',
  COMPANY_NCMS_CREATE_FAILURE: '@companyNcms/CREATE_COMPANY_NCM_FAILURE',

  COMPANY_NCMS_DELETE_REQUEST: '@companyNcms/DELETE_COMPANY_NCM_REQUEST',
  COMPANY_NCMS_DELETE_SUCCESS: '@companyNcms/DELETE_COMPANY_NCM_SUCCESS',
  COMPANY_NCMS_DELETE_FAILURE: '@companyNcms/DELETE_COMPANY_NCM_FAILURE',

  COMPANY_NCMS_RESET_LIST: '@companyNcms/RESET_LIST_COMPANY_NCMS',

  COMPANY_NCMS_FILTER_OPEN_MODAL: '@companyNcms/OPEN_MODAL_FILTER_COMPANY_NCM',
  COMPANY_NCMS_FILTER_CLOSE_MODAL:
    '@companyNcms/CLOSE_MODAL_FILTER_COMPANY_NCM',
};

export default Types;
