const Types = {
  PAYMENT_METHODS_GET_REQUEST: '@paymentMethods/GET_PAYMENT_METHODS_REQUEST',
  PAYMENT_METHODS_GET_SUCCESS: '@paymentMethods/GET_PAYMENT_METHODS_SUCCESS',
  PAYMENT_METHODS_GET_FAILURE: '@paymentMethods/GET_PAYMENT_METHODS_FAILURE',

  PAYMENT_METHODS_CREATE_REQUEST:
    '@paymentMethods/CREATE_PAYMENT_METHOD_REQUEST',
  PAYMENT_METHODS_CREATE_SUCCESS:
    '@paymentMethods/CREATE_PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHODS_CREATE_FAILURE:
    '@paymentMethods/CREATE_PAYMENT_METHOD_FAILURE',

  PAYMENT_METHODS_UPDATE_REQUEST:
    '@paymentMethods/UPDATE_PAYMENT_METHOD_REQUEST',
  PAYMENT_METHODS_UPDATE_SUCCESS:
    '@paymentMethods/UPDATE_PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHODS_UPDATE_FAILURE:
    '@paymentMethods/UPDATE_PAYMENT_METHOD_FAILURE',

  PAYMENT_METHODS_DELETE_REQUEST:
    '@paymentMethods/DELETE_PAYMENT_METHOD_REQUEST',
  PAYMENT_METHODS_DELETE_SUCCESS:
    '@paymentMethods/DELETE_PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHODS_DELETE_FAILURE:
    '@paymentMethods/DELETE_PAYMENT_METHOD_FAILURE',

  PAYMENT_METHODS_GET_DETAILS_REQUEST:
    '@paymentMethods/GET_DETAILS_PAYMENT_METHOD_REQUEST',
  PAYMENT_METHODS_GET_DETAILS_SUCCESS:
    '@paymentMethods/GET_DETAILS_PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHODS_GET_DETAILS_FAILURE:
    '@paymentMethods/GET_DETAILS_PAYMENT_METHOD_FAILURE',

  PAYMENT_METHODS_RESET_LIST: '@paymentMethods/RESET_LIST_PAYMENT_METHODS',
  PAYMENT_METHODS_RESET_DETAILS: '@paymentMethods/RESET_DETAILS_PAYMENT_METHOD',

  PAYMENT_METHODS_FILTER_OPEN_MODAL:
    '@paymentMethods/OPEN_MODAL_FILTER_PAYMENT_METHOD',
  PAYMENT_METHODS_FILTER_CLOSE_MODAL:
    '@paymentMethods/CLOSE_MODAL_FILTER_PAYMENT_METHOD',

  PAYMENT_METHODS_SET_SAVING_ON_SUB_RESOURCE:
    '@paymentMethods/SET_SAVING_ON_SUB_RESOURCE_PAYMENT_METHOD',
  PAYMENT_METHODS_RESET_SUB_RESOURCE_DATA:
    '@paymentMethods/RESET_SUB_RESOURCE_DATA_PAYMENT_METHOD',

  PAYMENT_METHODS_SET_BILL_TYPE: '@paymentMethods/BILL_TYPE_PAYMENT_METHOD',
};

export default Types;
