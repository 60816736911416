import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { getBankAccountPixFiles } from '../bankAccountPixFiles/actions';
import {
  getBankAccountPixs,
  openModalBankAccountPix,
  resetListBankAccountPixs,
} from '../bankAccountPixs/actions';
import {
  getAccountsSuccess,
  getAccountsFailure,
  createAccountSuccess,
  createAccountFailure,
  updateAccountSuccess,
  updateAccountFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  getDetailsAccountSuccess,
  getDetailsAccountFailure,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getAccounts({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `accounts${queryParams}`);

    yield put(
      getAccountsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getAccountsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createAccount({ payload }) {
  try {
    const { account, subResourceType } = payload;

    const response = yield call(api.post, 'accounts', account);

    yield put(createAccountSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/account/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateAccount({ payload }) {
  try {
    const { id, newAccount, subResourceType } = payload;

    const response = yield call(api.put, `accounts/${id}`, newAccount);

    yield put(updateAccountSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);

    if (subResourceType === 'BANK-ACCOUNT-PIX') {
      yield put(openModalBankAccountPix());
    }

    yield put(resetSubResourceData());
  } catch (err) {
    yield put(updateAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteAccount({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `accounts/${id}`);

    yield put(deleteAccountSuccess(id));

    history.push('/accounts');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsAccount({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `accounts/${id}`);

    const { bank_account, ...rest } = response.data;

    let data = {
      ...rest,
    };

    if (bank_account) {
      const { bank_accounts_pixs, ...bankAccountRest } = bank_account;

      if (bank_accounts_pixs.length > 0) {
        const [bank_account_pix] = bank_accounts_pixs;

        const { files, ...bankAccountPixRest } = bank_account_pix;

        yield put(getBankAccountPixs([bankAccountPixRest]));

        yield put(getBankAccountPixFiles(files));
      }

      data = {
        ...rest,
        bank_account: {
          ...bankAccountRest,
        },
      };
    }

    yield put(getDetailsAccountSuccess(data));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.accounts
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'BANK-ACCOUNT-PIX') {
        yield put(openModalBankAccountPix());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsAccount() {
  yield put(resetListBankAccountPixs());
}

export default all([
  takeLatest(Types.ACCOUNTS_GET_REQUEST, getAccounts),
  takeLatest(Types.ACCOUNTS_CREATE_REQUEST, createAccount),
  takeLatest(Types.ACCOUNTS_UPDATE_REQUEST, updateAccount),
  takeLatest(Types.ACCOUNTS_DELETE_REQUEST, deleteAccount),
  takeLatest(Types.ACCOUNTS_GET_DETAILS_REQUEST, getDetailsAccount),
  takeLatest(Types.ACCOUNTS_RESET_DETAILS, resetDetailsAccount),
]);
