import Types from './types';

export function createCustomerRequest(customer, subResourceType = null) {
  return {
    type: Types.CUSTOMERS_CREATE_REQUEST,
    payload: { customer, subResourceType },
  };
}

export function createCustomerSuccess(customer) {
  return {
    type: Types.CUSTOMERS_CREATE_SUCCESS,
    payload: { customer },
  };
}

export function createCustomerFailure() {
  return {
    type: Types.CUSTOMERS_CREATE_FAILURE,
  };
}

export function updateCustomerRequest(id, newCustomer) {
  return {
    type: Types.CUSTOMERS_UPDATE_REQUEST,
    payload: { id, newCustomer },
  };
}

export function updateCustomerSuccess(newCustomer) {
  return {
    type: Types.CUSTOMERS_UPDATE_SUCCESS,
    payload: { newCustomer },
  };
}

export function updateCustomerFailure() {
  return {
    type: Types.CUSTOMERS_UPDATE_FAILURE,
  };
}

export function deleteCustomerRequest({
  id,
  fetchList = null,
  isFromDetail = false,
}) {
  return {
    type: Types.CUSTOMERS_DELETE_REQUEST,
    payload: { id, fetchList, isFromDetail },
  };
}

export function deleteCustomerSuccess(isFromDetail = false) {
  return {
    type: Types.CUSTOMERS_DELETE_SUCCESS,
    payload: { isFromDetail },
  };
}

export function deleteCustomerFailure() {
  return {
    type: Types.CUSTOMERS_DELETE_FAILURE,
  };
}

export function becomeCustomerRequest(id) {
  return {
    type: Types.CUSTOMERS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeCustomerSuccess(customer) {
  return {
    type: Types.CUSTOMERS_BECOME_SUCCESS,
    payload: { customer },
  };
}

export function becomeCustomerFailure() {
  return {
    type: Types.CUSTOMERS_BECOME_FAILURE,
  };
}

export function getDetailsCustomerRequest(id) {
  return {
    type: Types.CUSTOMERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsCustomerSuccess(customer) {
  return {
    type: Types.CUSTOMERS_GET_DETAILS_SUCCESS,
    payload: { customer },
  };
}

export function getDetailsCustomerFailure() {
  return {
    type: Types.CUSTOMERS_GET_DETAILS_FAILURE,
  };
}

export function resetListCustomers() {
  return {
    type: Types.CUSTOMERS_RESET_LIST,
  };
}

export function resetDetailsCustomer() {
  return {
    type: Types.CUSTOMERS_RESET_DETAILS,
  };
}

export function openModalAddCustomer() {
  return {
    type: Types.CUSTOMERS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddCustomer() {
  return {
    type: Types.CUSTOMERS_ADD_CLOSE_MODAL,
  };
}

export function openModalCustomerObservation() {
  return {
    type: Types.CUSTOMERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalCustomerObservation() {
  return {
    type: Types.CUSTOMERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function clearNewCustomerBranchActivity() {
  return {
    type: Types.CUSTOMERS_CLEAR_NEW_BRANCH_ACTIVITY,
  };
}

export function clearNewCustomerCovenant() {
  return {
    type: Types.CUSTOMERS_CLEAR_NEW_COVENANT,
  };
}

export function openModalCustomerFilter() {
  return {
    type: Types.CUSTOMERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalCustomerFilter() {
  return {
    type: Types.CUSTOMERS_FILTER_CLOSE_MODAL,
  };
}

export function setCustomerTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
  searchThroughTable,
  listLength,
}) {
  return {
    type: Types.CUSTOMERS_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      searchThroughTable,
      listLength,
    },
  };
}

export function setCustomerRequestError() {
  return {
    type: Types.CUSTOMERS_SET_REQUEST_ERROR,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.CUSTOMERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.CUSTOMERS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.CUSTOMERS_RESET_SUB_RESOURCE_DATA,
  };
}
