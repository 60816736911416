const Types = {
  BILLS_TO_PAY_CREATE_REQUEST: '@billsToPay/CREATE_BILL_TO_PAY_REQUEST',
  BILLS_TO_PAY_CREATE_SUCCESS: '@billsToPay/CREATE_BILL_TO_PAY_SUCCESS',
  BILLS_TO_PAY_CREATE_FAILURE: '@billsToPay/CREATE_BILL_TO_PAY_FAILURE',

  BILLS_TO_PAY_UPDATE_REQUEST: '@billsToPay/UPDATE_BILL_TO_PAY_REQUEST',
  BILLS_TO_PAY_UPDATE_SUCCESS: '@billsToPay/UPDATE_BILL_TO_PAY_SUCCESS',
  BILLS_TO_PAY_UPDATE_FAILURE: '@billsToPay/UPDATE_BILL_TO_PAY_FAILURE',

  BILLS_TO_PAY_SETTLEMENT_REQUEST: '@billsToPay/SETTLEMENT_BILL_TO_PAY_REQUEST',
  BILLS_TO_PAY_SETTLEMENT_SUCCESS: '@billsToPay/SETTLEMENT_BILL_TO_PAY_SUCCESS',
  BILLS_TO_PAY_SETTLEMENT_FAILURE: '@billsToPay/SETTLEMENT_BILL_TO_PAY_FAILURE',

  BILLS_TO_PAY_DELETE_REQUEST: '@billsToPay/DELETE_BILL_TO_PAY_REQUEST',
  BILLS_TO_PAY_DELETE_SUCCESS: '@billsToPay/DELETE_BILL_TO_PAY_SUCCESS',
  BILLS_TO_PAY_DELETE_FAILURE: '@billsToPay/DELETE_BILL_TO_PAY_FAILURE',

  BILLS_TO_PAY_GET_DETAILS_REQUEST:
    '@billsToPay/GET_DETAILS_BILL_TO_PAY_REQUEST',
  BILLS_TO_PAY_GET_DETAILS_SUCCESS:
    '@billsToPay/GET_DETAILS_BILL_TO_PAY_SUCCESS',
  BILLS_TO_PAY_GET_DETAILS_FAILURE:
    '@billsToPay/GET_DETAILS_BILL_TO_PAY_FAILURE',

  BILLS_TO_PAY_RESET_LIST: '@billsToPay/RESET_LIST_BILLS_TO_PAY',
  BILLS_TO_PAY_RESET_DETAILS: '@billsToPay/RESET_DETAILS_BILL_TO_PAY',

  BILLS_TO_PAY_SET_SAVING_ON_SUB_RESOURCE:
    '@billsToPay/SET_SAVING_ON_SUB_RESOURCE_BILL_TO_PAY',
  BILLS_TO_PAY_RESET_SUB_RESOURCE_DATA:
    '@billsToPay/RESET_SUB_RESOURCE_DATA_BILL_TO_PAY',

  BILLS_TO_PAY_SETTLEMENT_OPEN_MODAL:
    '@billsToPay/OPEN_MODAL_SETTLEMENT_BILL_TO_PAY',
  BILLS_TO_PAY_SETTLEMENT_CLOSE_MODAL:
    '@billsToPay/CLOSE_MODAL_SETTLEMENT_BILL_TO_PAY',

  BILLS_TO_PAY_FILTER_OPEN_MODAL: '@billsToPay/OPEN_MODAL_FILTER_BILL_TO_PAY',
  BILLS_TO_PAY_FILTER_CLOSE_MODAL: '@billsToPay/CLOSE_MODAL_FILTER_BILL_TO_PAY',

  BILLS_TO_PAY_OBSERVATION_OPEN_MODAL:
    '@billsToPay/OPEN_MODAL_BILL_TO_PAY_OBSERVATION',
  BILLS_TO_PAY_OBSERVATION_CLOSE_MODAL:
    '@billsToPay/CLOSE_MODAL_BILL_TO_PAY_OBSERVATION',

  BILLS_TO_PAY_SET_TABLE_FILTERS: '@billsToPay/SET_TABLE_FILTERS_BILL_TO_PAY',
  BILLS_TO_PAY_SET_INITIAL_TABLE_FILTERS:
    '@billsToPay/SET_INITIAL_TABLE_FILTERS_BILL_TO_PAY',
  BILLS_TO_PAY_SET_REQUEST_ERROR: '@billsToPay/SET_REQUEST_ERROR_BILL_TO_PAY',
};

export default Types;
