import Types from './types';

export function getPromoterPhones(phones) {
  return {
    type: Types.PROMOTER_PHONES_GET,
    payload: { phones },
  };
}

export function createPromoterPhoneRequest(promoter_id, phone) {
  return {
    type: Types.PROMOTER_PHONES_CREATE_REQUEST,
    payload: { promoter_id, phone },
  };
}

export function createPromoterPhoneSuccess(phone) {
  return {
    type: Types.PROMOTER_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createPromoterPhoneFailure() {
  return {
    type: Types.PROMOTER_PHONES_CREATE_FAILURE,
  };
}

export function updatePromoterPhoneRequest(promoter_id, phone_id, newPhone) {
  return {
    type: Types.PROMOTER_PHONES_UPDATE_REQUEST,
    payload: { promoter_id, phone_id, newPhone },
  };
}

export function updatePromoterPhoneSuccess(newPhone) {
  return {
    type: Types.PROMOTER_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updatePromoterPhoneFailure() {
  return {
    type: Types.PROMOTER_PHONES_UPDATE_FAILURE,
  };
}

export function deletePromoterPhoneRequest(promoter_id, phone_id) {
  return {
    type: Types.PROMOTER_PHONES_DELETE_REQUEST,
    payload: { promoter_id, phone_id },
  };
}

export function deletePromoterPhoneSuccess(phone_id) {
  return {
    type: Types.PROMOTER_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deletePromoterPhoneFailure() {
  return {
    type: Types.PROMOTER_PHONES_DELETE_FAILURE,
  };
}

export function resetListPromoterPhone() {
  return {
    type: Types.PROMOTER_PHONES_RESET_LIST,
  };
}

export function openModalPromoterPhone(phone_id) {
  return {
    type: Types.PROMOTER_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalPromoterPhone() {
  return {
    type: Types.PROMOTER_PHONES_CLOSE_MODAL,
  };
}
