import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createTransporterFileSuccess,
  createTransporterFileFailure,
  updateTransporterFileSuccess,
  updateTransporterFileFailure,
  deleteTransporterFileSuccess,
  deleteTransporterFileFailure,
} from './actions';
import Types from './types';

export function* createTransporterFile({ payload }) {
  try {
    const { transporter_id, file } = payload;

    const response = yield call(
      api.post,
      `transporters/${transporter_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createTransporterFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createTransporterFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateTransporterFile({ payload }) {
  try {
    const { transporter_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `transporters/${transporter_id}/files/${file_id}`,
      newFile
    );

    yield put(updateTransporterFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateTransporterFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteTransporterFile({ payload }) {
  try {
    const { transporter_id, file_id } = payload;

    yield call(api.delete, `transporters/${transporter_id}/files/${file_id}`);

    yield put(deleteTransporterFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteTransporterFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.TRANSPORTER_FILES_CREATE_REQUEST, createTransporterFile),
  takeLatest(Types.TRANSPORTER_FILES_UPDATE_REQUEST, updateTransporterFile),
  takeLatest(Types.TRANSPORTER_FILES_DELETE_REQUEST, deleteTransporterFile),
]);
