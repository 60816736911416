import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createRuralPropertyRegistrationSuccess,
  createRuralPropertyRegistrationFailure,
  updateRuralPropertyRegistrationSuccess,
  updateRuralPropertyRegistrationFailure,
  deleteRuralPropertyRegistrationSuccess,
  deleteRuralPropertyRegistrationFailure,
} from './actions';
import Types from './types';

export function* createRuralPropertyRegistration({ payload }) {
  try {
    const { property_id, registration } = payload;

    const response = yield call(
      api.post,
      `rural-properties/${property_id}/registrations`,
      registration
    );

    yield put(createRuralPropertyRegistrationSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyRegistrationFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRuralPropertyRegistration({ payload }) {
  try {
    const { property_id, registration_id, newRegistration } = payload;

    const response = yield call(
      api.put,
      `rural-properties/${property_id}/registrations/${registration_id}`,
      newRegistration
    );

    yield put(updateRuralPropertyRegistrationSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRuralPropertyRegistrationFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteRuralPropertyRegistration({ payload }) {
  try {
    const { property_id, registration_id } = payload;

    yield call(
      api.delete,
      `rural-properties/${property_id}/registrations/${registration_id}`
    );

    yield put(deleteRuralPropertyRegistrationSuccess(registration_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRuralPropertyRegistrationFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_REQUEST,
    createRuralPropertyRegistration
  ),
  takeLatest(
    Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_REQUEST,
    updateRuralPropertyRegistration
  ),
  takeLatest(
    Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_REQUEST,
    deleteRuralPropertyRegistration
  ),
]);
