import produce from 'immer';

import BrandTypes from '../brands/types';
import DepartmentTypes from '../departments/types';
import UnitMeasureTypes from '../unitsMeasure/types';
import Types from './types';

const INITIAL_STATE = {
  listLength: 0,
  detail: {},

  searchThroughTable: true,
  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,

  detailUpdatedOnce: false,
  requestError: false,
  loading: false,
  productDescriptionModalOpen: false,
  productFilterModalOpen: false,
  newBrand: {},
  newDepartment: {},
  newUnitMeasure: {},
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  openFileDialog: false,
  productShareModalOpen: false,
};

export default function products(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PRODUCTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRODUCTS_CREATE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.PRODUCTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRODUCTS_UPDATE_SUCCESS: {
        const { newProduct } = action.payload;
        draft.detail = newProduct;

        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        draft.loading = false;
        draft.productDescriptionModalOpen = false;
        break;
      }
      case Types.PRODUCTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCTS_DELETE_REQUEST: {
        draft.loading = true;

        break;
      }
      case Types.PRODUCTS_DELETE_SUCCESS: {
        const { isFromDetail } = action.payload;

        if (isFromDetail) {
          if (state.page > 0 && state.listLength === 1) {
            draft.page = state.page - 1;
          }
        }

        draft.loading = false;

        break;
      }
      case Types.PRODUCTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCTS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.PRODUCTS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.product;
        draft.requestError = false;
        break;
      }
      case Types.PRODUCTS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.PRODUCTS_DESCRIPTION_OPEN_MODAL: {
        draft.productDescriptionModalOpen = true;
        break;
      }
      case Types.PRODUCTS_DESCRIPTION_CLOSE_MODAL: {
        draft.productDescriptionModalOpen = false;
        break;
      }

      case Types.PRODUCTS_RESET_LIST: {
        draft.loading = false;
        draft.requestError = false;
        draft.productShareModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.productFilterModalOpen = false;
        draft.searchThroughTable = true;
        draft.listLength = 0;
        break;
      }

      case Types.PRODUCTS_RESET_DETAILS: {
        draft.detail = {};
        draft.productDescriptionModalOpen = false;
        draft.detailUpdatedOnce = false;
        break;
      }

      case Types.PRODUCTS_CLEAR_NEW_BRAND: {
        draft.newBrand = {};
        break;
      }
      case Types.PRODUCTS_CLEAR_NEW_DEPARTMENT: {
        draft.newDepartment = {};
        break;
      }
      case Types.PRODUCTS_CLEAR_NEW_UNIT_MEASURE: {
        draft.newUnitMeasure = {};
        break;
      }

      case Types.PRODUCTS_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.PRODUCTS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        draft.openFileDialog = false;
        break;
      }

      case Types.PRODUCTS_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case Types.PRODUCTS_SHARE_OPEN_MODAL: {
        draft.productShareModalOpen = true;
        break;
      }
      case Types.PRODUCTS_SHARE_CLOSE_MODAL: {
        draft.productShareModalOpen = false;
        break;
      }

      case Types.PRODUCTS_FILTER_OPEN_MODAL: {
        draft.productFilterModalOpen = true;
        break;
      }
      case Types.PRODUCTS_FILTER_CLOSE_MODAL: {
        draft.productFilterModalOpen = false;
        break;
      }
      case Types.PRODUCTS_SET_TABLE_FILTERS: {
        const {
          modalFilters,
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
          searchThroughTable,
          listLength,
        } = action.payload;

        if (typeof listLength !== 'undefined') {
          draft.listLength = listLength;
        }

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        if (typeof rowsPerPage !== 'undefined') {
          draft.rowsPerPage = rowsPerPage;
        }

        if (typeof currentPage !== 'undefined') {
          draft.page = currentPage;
        }

        if (typeof searchThroughTable !== 'undefined') {
          draft.searchThroughTable = searchThroughTable;
        }

        if (closeModal) {
          draft.productFilterModalOpen = false;
        }

        break;
      }

      case Types.PRODUCTS_SET_REQUEST_ERROR: {
        draft.requestError = true;

        break;
      }

      case BrandTypes.BRANDS_CREATE_SUCCESS: {
        const { source, brand } = action.payload;

        if (source === 'PRODUCT') {
          draft.newBrand = brand;
        }
        break;
      }

      case DepartmentTypes.DEPARTMENTS_CREATE_SUCCESS: {
        const { source, department } = action.payload;

        if (source === 'PRODUCT') {
          draft.newDepartment = department;
        }
        break;
      }

      case UnitMeasureTypes.UNITS_MEASURE_CREATE_SUCCESS: {
        const { source, unitMeasure } = action.payload;

        if (source === 'PRODUCT') {
          draft.newUnitMeasure = unitMeasure;
        }
        break;
      }

      default:
    }
  });
}
