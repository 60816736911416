const Types = {
  SELLER_PHONES_GET: '@sellers/GET_SELLER_PHONES',

  SELLER_PHONES_CREATE_REQUEST: '@sellers/CREATE_SELLER_PHONE_REQUEST',
  SELLER_PHONES_CREATE_SUCCESS: '@sellers/CREATE_SELLER_PHONE_SUCCESS',
  SELLER_PHONES_CREATE_FAILURE: '@sellers/CREATE_SELLER_PHONE_FAILURE',

  SELLER_PHONES_UPDATE_REQUEST: '@sellers/UPDATE_SELLER_PHONE_REQUEST',
  SELLER_PHONES_UPDATE_SUCCESS: '@sellers/UPDATE_SELLER_PHONE_SUCCESS',
  SELLER_PHONES_UPDATE_FAILURE: '@sellers/UPDATE_SELLER_PHONE_FAILURE',

  SELLER_PHONES_DELETE_REQUEST: '@sellers/DELETE_SELLER_PHONE_REQUEST',
  SELLER_PHONES_DELETE_SUCCESS: '@sellers/DELETE_SELLER_PHONE_SUCCESS',
  SELLER_PHONES_DELETE_FAILURE: '@sellers/DELETE_SELLER_PHONE_FAILURE',

  SELLER_PHONES_RESET_LIST: '@sellers/RESET_LIST_SELLER_PHONE',

  SELLER_PHONES_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_PHONE',
  SELLER_PHONES_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_PHONE',
};

export default Types;
