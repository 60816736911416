const Types = {
  DISPATCHING_AGENCIES_GET_REQUEST:
    '@dispatchingAgencies/GET_DISPATCHING_AGENCIES_REQUEST',
  DISPATCHING_AGENCIES_GET_SUCCESS:
    '@dispatchingAgencies/GET_DISPATCHING_AGENCIES_SUCCESS',
  DISPATCHING_AGENCIES_GET_FAILURE:
    '@dispatchingAgencies/GET_DISPATCHING_AGENCIES_FAILURE',

  DISPATCHING_AGENCIES_RESET_LIST:
    '@dispatchingAgencies/RESET_LIST_DISPATCHING_AGENCIES',
};

export default Types;
