import styled from 'styled-components';

import { Button as MuiButton, Typography } from '@material-ui/core';

export const Error = styled(Typography).attrs({
  component: 'h1',
  variant: 'h1',
  align: 'center',
  gutterBottom: true,
})``;

export const Title = styled(Typography).attrs({
  component: 'h2',
  variant: 'h5',
  align: 'center',
  gutterBottom: true,
})``;

export const Description = styled(Typography).attrs({
  component: 'h2',
  variant: 'body1',
  align: 'center',
  gutterBottom: true,
})``;

export const Button = styled(MuiButton).attrs({
  variant: 'contained',
  color: 'secondary',
  mt: 2,
})``;

export const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;
