import React from 'react';
import { Shuffle } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const OrdersList = async(() => import('~/pages/Orders/List'));

export const movementsRoutes = {
  id: <IntlMessages id="sidebar.movements" />,
  path: '/movements',
  header: <IntlMessages id="sidebar.menu" />,
  icon: <Shuffle />,
  children: [
    {
      path: '/orders',
      name: <IntlMessages id="sidebar.movements.orders" />,
      component: OrdersList,
    },
    // {
    //   path: '/orders/budgets',
    //   name: <IntlMessages id="sidebar.movements.budget" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/shippings',
    //   name: <IntlMessages id="sidebar.movements.shipping" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/bonifications',
    //   name: <IntlMessages id="sidebar.movements.bonification" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/exchanges',
    //   name: <IntlMessages id="sidebar.movements.exchange" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/sales',
    //   name: <IntlMessages id="sidebar.movements.sale" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/purchases',
    //   name: <IntlMessages id="sidebar.movements.purchase" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/visits',
    //   name: <IntlMessages id="sidebar.movements.visit" />,
    //   component: OrdersList,
    // },
    // {
    //   path: '/orders/notes',
    //   name: <IntlMessages id="sidebar.movements.notes" />,
    //   component: OrdersList,
    // },
  ],
};
