export function todayDate() {
  const now = new Date();
  const day = String(now.getDate());
  const formattedDay = day.padStart(2, '0');
  const month = String(now.getMonth() + 1);
  const formattedMonth = month.padStart(2, '0');
  const year = now.getFullYear();

  const today = new Date(year, now.getMonth(), day);

  return {
    now,
    day,
    formattedDay,
    month,
    formattedMonth,
    year,
    today,
  };
}
