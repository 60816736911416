import React from 'react';

import PropTypes from 'prop-types';

import { BookOpen } from './styles';

export default function CatalogIcon({ type }) {
  return <BookOpen type={type} />;
}

CatalogIcon.propTypes = {
  type: PropTypes.string,
};

CatalogIcon.defaultProps = {
  type: '',
};
