import Types from './types';

export function createBillToReceiveRequest(
  billToReceive,
  subResourceType = null
) {
  return {
    type: Types.BILLS_TO_RECEIVE_CREATE_REQUEST,
    payload: { billToReceive, subResourceType },
  };
}

export function createBillToReceiveSuccess(billToReceive) {
  return {
    type: Types.BILLS_TO_RECEIVE_CREATE_SUCCESS,
    payload: { billToReceive },
  };
}

export function createBillToReceiveFailure() {
  return {
    type: Types.BILLS_TO_RECEIVE_CREATE_FAILURE,
  };
}

export function updateBillToReceiveRequest(id, newBillToReceive) {
  return {
    type: Types.BILLS_TO_RECEIVE_UPDATE_REQUEST,
    payload: { id, newBillToReceive },
  };
}

export function updateBillToReceiveSuccess(newBillToReceive) {
  return {
    type: Types.BILLS_TO_RECEIVE_UPDATE_SUCCESS,
    payload: { newBillToReceive },
  };
}

export function updateBillToReceiveFailure() {
  return {
    type: Types.BILLS_TO_RECEIVE_UPDATE_FAILURE,
  };
}

export function settlementBillToReceiveRequest(
  id,
  newBillToReceive,
  fetchList = null
) {
  return {
    type: Types.BILLS_TO_RECEIVE_SETTLEMENT_REQUEST,
    payload: { id, newBillToReceive, fetchList },
  };
}

export function settlementBillToReceiveSuccess(newBillToReceive) {
  return {
    type: Types.BILLS_TO_RECEIVE_SETTLEMENT_SUCCESS,
    payload: { newBillToReceive },
  };
}

export function settlementBillToReceiveFailure() {
  return {
    type: Types.BILLS_TO_RECEIVE_SETTLEMENT_FAILURE,
  };
}

export function deleteBillToReceiveRequest({
  id,
  fetchList = null,
  isFromDetail = false,
}) {
  return {
    type: Types.BILLS_TO_RECEIVE_DELETE_REQUEST,
    payload: { id, fetchList, isFromDetail },
  };
}

export function deleteBillToReceiveSuccess(isFromDetail = false) {
  return {
    type: Types.BILLS_TO_RECEIVE_DELETE_SUCCESS,
    payload: { isFromDetail },
  };
}

export function deleteBillToReceiveFailure() {
  return {
    type: Types.BILLS_TO_RECEIVE_DELETE_FAILURE,
  };
}

export function getDetailsBillToReceiveRequest(id) {
  return {
    type: Types.BILLS_TO_RECEIVE_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsBillToReceiveSuccess(billToReceive) {
  return {
    type: Types.BILLS_TO_RECEIVE_GET_DETAILS_SUCCESS,
    payload: { billToReceive },
  };
}

export function getDetailsBillToReceiveFailure() {
  return {
    type: Types.BILLS_TO_RECEIVE_GET_DETAILS_FAILURE,
  };
}

export function resetListBillsToReceive() {
  return {
    type: Types.BILLS_TO_RECEIVE_RESET_LIST,
  };
}

export function resetDetailsBillToReceive() {
  return {
    type: Types.BILLS_TO_RECEIVE_RESET_DETAILS,
  };
}

export function openModalSettlementBillToReceive(id, sharedDetail) {
  return {
    type: Types.BILLS_TO_RECEIVE_SETTLEMENT_OPEN_MODAL,
    payload: { id, sharedDetail },
  };
}

export function closeModalSettlementBillToReceive() {
  return {
    type: Types.BILLS_TO_RECEIVE_SETTLEMENT_CLOSE_MODAL,
  };
}

export function openModalBillToReceiveObservation() {
  return {
    type: Types.BILLS_TO_RECEIVE_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalBillToReceiveObservation() {
  return {
    type: Types.BILLS_TO_RECEIVE_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalBillToReceiveFilter() {
  return {
    type: Types.BILLS_TO_RECEIVE_FILTER_OPEN_MODAL,
  };
}

export function closeModalBillToReceiveFilter() {
  return {
    type: Types.BILLS_TO_RECEIVE_FILTER_CLOSE_MODAL,
  };
}

export function setBillToReceiveTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
  searchThroughTable,
  shouldFetchStatisticData = false,
  listLength,
}) {
  return {
    type: Types.BILLS_TO_RECEIVE_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      searchThroughTable,
      shouldFetchStatisticData,
      listLength,
    },
  };
}

export function setBillToReceiveInitialTableFilters() {
  return {
    type: Types.BILLS_TO_RECEIVE_SET_INITIAL_TABLE_FILTERS,
  };
}

export function setBillToReceiveRequestError() {
  return {
    type: Types.BILLS_TO_RECEIVE_SET_REQUEST_ERROR,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.BILLS_TO_RECEIVE_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.BILLS_TO_RECEIVE_RESET_SUB_RESOURCE_DATA,
  };
}

export function setCreatingFromBillToReceive(creatingFrom) {
  return {
    type: Types.BILLS_TO_RECEIVE_SET_CREATING_FROM,
    payload: { creatingFrom },
  };
}
