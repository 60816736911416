const Types = {
  ORDER_PRICE_LIST_ITEMS_GET: '@order/GET_ORDER_PRICE_LIST_ITEMS',

  ORDER_PRICE_LIST_ITEMS_GET_FAILURE:
    '@order/GET_ORDER_PRICE_LIST_ITEMS_FAILURE',

  ORDER_PRICE_LIST_ITEMS_RESET_LIST: '@order/RESET_LIST_ORDER_PRICE_LIST_ITEMS',
};

export default Types;
