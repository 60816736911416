const Types = {
  CUSTOMER_PHONES_GET: '@customers/GET_CUSTOMER_PHONES',

  CUSTOMER_PHONES_CREATE_REQUEST: '@customers/CREATE_CUSTOMER_PHONE_REQUEST',
  CUSTOMER_PHONES_CREATE_SUCCESS: '@customers/CREATE_CUSTOMER_PHONE_SUCCESS',
  CUSTOMER_PHONES_CREATE_FAILURE: '@customers/CREATE_CUSTOMER_PHONE_FAILURE',

  CUSTOMER_PHONES_UPDATE_REQUEST: '@customers/UPDATE_CUSTOMER_PHONE_REQUEST',
  CUSTOMER_PHONES_UPDATE_SUCCESS: '@customers/UPDATE_CUSTOMER_PHONE_SUCCESS',
  CUSTOMER_PHONES_UPDATE_FAILURE: '@customers/UPDATE_CUSTOMER_PHONE_FAILURE',

  CUSTOMER_PHONES_DELETE_REQUEST: '@customers/DELETE_CUSTOMER_PHONE_REQUEST',
  CUSTOMER_PHONES_DELETE_SUCCESS: '@customers/DELETE_CUSTOMER_PHONE_SUCCESS',
  CUSTOMER_PHONES_DELETE_FAILURE: '@customers/DELETE_CUSTOMER_PHONE_FAILURE',

  CUSTOMER_PHONES_RESET_LIST: '@customers/RESET_LIST_CUSTOMER_PHONE',

  CUSTOMER_PHONES_OPEN_MODAL: '@customers/OPEN_MODAL_CUSTOMER_PHONE',
  CUSTOMER_PHONES_CLOSE_MODAL: '@customers/CLOSE_MODAL_CUSTOMER_PHONE',
};

export default Types;
