import Types from './types';

export function getSuppliersRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.SUPPLIERS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getSuppliersSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.SUPPLIERS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getSuppliersFailure() {
  return {
    type: Types.SUPPLIERS_GET_FAILURE,
  };
}

export function createSupplierRequest(supplier, subResourceType = null) {
  return {
    type: Types.SUPPLIERS_CREATE_REQUEST,
    payload: { supplier, subResourceType },
  };
}

export function createSupplierSuccess(supplier) {
  return {
    type: Types.SUPPLIERS_CREATE_SUCCESS,
    payload: { supplier },
  };
}

export function createSupplierFailure() {
  return {
    type: Types.SUPPLIERS_CREATE_FAILURE,
  };
}

export function updateSupplierRequest(id, newSupplier) {
  return {
    type: Types.SUPPLIERS_UPDATE_REQUEST,
    payload: { id, newSupplier },
  };
}

export function updateSupplierSuccess(newSupplier) {
  return {
    type: Types.SUPPLIERS_UPDATE_SUCCESS,
    payload: { newSupplier },
  };
}

export function updateSupplierFailure() {
  return {
    type: Types.SUPPLIERS_UPDATE_FAILURE,
  };
}

export function deleteSupplierRequest(id) {
  return {
    type: Types.SUPPLIERS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteSupplierSuccess(id) {
  return {
    type: Types.SUPPLIERS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteSupplierFailure() {
  return {
    type: Types.SUPPLIERS_DELETE_FAILURE,
  };
}

export function becomeSupplierRequest(id) {
  return {
    type: Types.SUPPLIERS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeSupplierSuccess(supplier) {
  return {
    type: Types.SUPPLIERS_BECOME_SUCCESS,
    payload: { supplier },
  };
}

export function becomeSupplierFailure() {
  return {
    type: Types.SUPPLIERS_BECOME_FAILURE,
  };
}

export function getDetailsSupplierRequest(id) {
  return {
    type: Types.SUPPLIERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsSupplierSuccess(supplier) {
  return {
    type: Types.SUPPLIERS_GET_DETAILS_SUCCESS,
    payload: { supplier },
  };
}

export function getDetailsSupplierFailure() {
  return {
    type: Types.SUPPLIERS_GET_DETAILS_FAILURE,
  };
}

export function resetListSuppliers() {
  return {
    type: Types.SUPPLIERS_RESET_LIST,
  };
}

export function resetDetailsSupplier() {
  return {
    type: Types.SUPPLIERS_RESET_DETAILS,
  };
}

export function openModalAddSupplier() {
  return {
    type: Types.SUPPLIERS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddSupplier() {
  return {
    type: Types.SUPPLIERS_ADD_CLOSE_MODAL,
  };
}

export function openModalSupplierObservation() {
  return {
    type: Types.SUPPLIERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalSupplierObservation() {
  return {
    type: Types.SUPPLIERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalSupplierFilter() {
  return {
    type: Types.SUPPLIERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalSupplierFilter() {
  return {
    type: Types.SUPPLIERS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.SUPPLIERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.SUPPLIERS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.SUPPLIERS_RESET_SUB_RESOURCE_DATA,
  };
}
