import React from 'react';

import PropTypes from 'prop-types';

import { Event } from './styles';

export default function CalendarIcon({ type }) {
  return <Event type={type} />;
}

CalendarIcon.propTypes = {
  type: PropTypes.string,
};

CalendarIcon.defaultProps = {
  type: '',
};
