import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  updateProductPriceListItemSuccess,
  updateProductPriceListItemFailure,
} from './actions';
import Types from './types';

export function* updateProductPriceListItem({ payload }) {
  try {
    const { price_list_id, item_id, newItem } = payload;

    const response = yield call(
      api.put,
      `price-lists/${price_list_id}/items/${item_id}`,
      newItem
    );

    yield put(updateProductPriceListItemSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateProductPriceListItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export default all([
  takeLatest(
    Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_REQUEST,
    updateProductPriceListItem
  ),
]);
