import produce from 'immer';

import RegionTypes from '../regions/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  regionSellerModalOpen: false,
};

export default function regionSellers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.REGION_SELLERS_GET: {
        const { sellers } = action.payload;

        draft.list = sellers;
        break;
      }

      case Types.REGION_SELLERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGION_SELLERS_UPDATE_SUCCESS: {
        const { sellers } = action.payload;

        draft.list = sellers;

        draft.loading = false;
        draft.regionSellerModalOpen = false;

        break;
      }
      case Types.REGION_SELLERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGION_SELLERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGION_SELLERS_DELETE_SUCCESS: {
        const { region_seller_id } = action.payload;

        draft.list = state.list.filter(item => {
          return item.id !== region_seller_id;
        });

        draft.loading = false;
        break;
      }
      case Types.REGION_SELLERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGION_SELLERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.REGION_SELLERS_OPEN_MODAL: {
        draft.regionSellerModalOpen = true;
        break;
      }
      case Types.REGION_SELLERS_CLOSE_MODAL: {
        draft.regionSellerModalOpen = false;
        break;
      }

      case RegionTypes.REGIONS_RESET_DETAIL: {
        draft.regionSellerModalOpen = false;
        break;
      }

      default:
    }
  });
}
