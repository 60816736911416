import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createBankBillingPixSuccess,
  createBankBillingPixFailure,
  updateBankBillingPixSuccess,
  updateBankBillingPixFailure,
  settlementBankBillingPixSuccess,
  settlementBankBillingPixFailure,
} from './actions';
import Types from './types';

export function* createBankBillingPix({ payload }) {
  try {
    const { pix, pixType } = payload;

    const response = yield call(api.post, `/pixs`, pix);

    yield put(createBankBillingPixSuccess(response.data, pixType));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createBankBillingPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBankBillingPix({ payload }) {
  try {
    const { pix_id } = payload;

    const response = yield call(api.put, `pixs/${pix_id}`);

    yield put(updateBankBillingPixSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBankBillingPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* settlementBankBillingPix({ payload }) {
  try {
    const { pix_id, newPix } = payload;

    const response = yield call(api.put, `pixs/${pix_id}`, newPix);

    yield put(settlementBankBillingPixSuccess(response.data));

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(settlementBankBillingPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export default all([
  takeLatest(Types.BANK_BILLING_PIXS_CREATE_REQUEST, createBankBillingPix),
  takeLatest(Types.BANK_BILLING_PIXS_UPDATE_REQUEST, updateBankBillingPix),
  takeLatest(
    Types.BANK_BILLING_PIXS_SETTLEMENT_REQUEST,
    settlementBankBillingPix
  ),
]);
