import produce from 'immer';

import BankBillingTypes from '../bankBillings/types';
import Types from './types';

const INITIAL_STATE = {
  detail: {},
  loading: false,
  bankBillingPixModalOpen: false,
  bankBillingPixEmitModalOpen: false,
  bankBillingPixAddAccountModalOpen: false,
  bankBillingPixDetailModalOpen: false,
  bankBillingPixShareModalOpen: false,
  bankBillingPixSettlementModalOpen: false,
  showNextPixRequestMessage: null,
  selectedAccount: null,
};

export default function bankBillingPixs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BANK_BILLING_PIXS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_CREATE_SUCCESS: {
        const { pix, pixType } = action.payload;

        draft.detail = pix;

        if (pixType === 'DYNAMIC') {
          draft.showNextPixRequestMessage = true;
          draft.bankBillingPixEmitModalOpen = true;
        } else if (pixType === 'STATIC') {
          draft.selectedAccount = null;

          if (pix.status === 'ACTIVE') {
            draft.bankBillingPixShareModalOpen = true;
            draft.bankBillingPixEmitModalOpen = false;
          } else {
            draft.bankBillingPixDetailModalOpen = true;
            draft.bankBillingPixEmitModalOpen = false;
          }
        }

        draft.loading = false;
        break;
      }
      case Types.BANK_BILLING_PIXS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_BILLING_PIXS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_UPDATE_SUCCESS: {
        const { newPix } = action.payload;

        draft.detail = newPix;
        draft.loading = false;

        break;
      }
      case Types.BANK_BILLING_PIXS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_BILLING_PIXS_SETTLEMENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_SETTLEMENT_SUCCESS: {
        draft.detail = {};

        draft.loading = false;

        draft.bankBillingPixSettlementModalOpen = false;
        break;
      }
      case Types.BANK_BILLING_PIXS_SETTLEMENT_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_BILLING_PIXS_OPEN_MODAL: {
        draft.bankBillingPixModalOpen = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_CLOSE_MODAL: {
        draft.bankBillingPixModalOpen = false;
        draft.showNextPixRequestMessage = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.BANK_BILLING_PIXS_EMIT_OPEN_MODAL: {
        draft.bankBillingPixEmitModalOpen = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_EMIT_CLOSE_MODAL: {
        draft.bankBillingPixEmitModalOpen = false;
        draft.showNextPixRequestMessage = false;
        draft.selectedAccount = null;
        draft.detail = {};
        break;
      }

      case Types.BANK_BILLING_PIXS_ADD_ACCOUNT_OPEN_MODAL: {
        draft.bankBillingPixAddAccountModalOpen = true;
        draft.bankBillingPixModalOpen = false;
        break;
      }
      case Types.BANK_BILLING_PIXS_ADD_ACCOUNT_CLOSE_MODAL: {
        draft.bankBillingPixAddAccountModalOpen = false;
        break;
      }
      case Types.BANK_BILLING_PIXS_SET_SELECTED_ACCOUNT: {
        draft.selectedAccount = action.payload.account;
        draft.bankBillingPixAddAccountModalOpen = false;
        draft.bankBillingPixEmitModalOpen = true;
        break;
      }

      case Types.BANK_BILLING_PIXS_DETAIL_OPEN_MODAL: {
        const { pix: pixData } = action.payload;

        draft.detail = {};

        if (pixData) {
          draft.detail = pixData;
        }

        draft.showNextPixRequestMessage = false;
        draft.bankBillingPixEmitModalOpen = false;
        draft.bankBillingPixDetailModalOpen = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_DETAIL_CLOSE_MODAL: {
        draft.bankBillingPixDetailModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.BANK_BILLING_PIXS_SHARE_OPEN_MODAL: {
        const { pix: pixData } = action.payload;

        draft.detail = {};

        if (pixData) {
          draft.detail = pixData;
        }

        draft.showNextPixRequestMessage = false;
        draft.bankBillingPixEmitModalOpen = false;
        draft.bankBillingPixShareModalOpen = true;
        draft.selectedAccount = null;
        break;
      }
      case Types.BANK_BILLING_PIXS_SHARE_CLOSE_MODAL: {
        draft.bankBillingPixShareModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.BANK_BILLING_PIXS_SETTLEMENT_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          draft.detail = id;
        }

        draft.bankBillingPixSettlementModalOpen = true;
        break;
      }
      case Types.BANK_BILLING_PIXS_SETTLEMENT_CLOSE_MODAL: {
        draft.detail = {};
        draft.selectedAccount = null;
        draft.bankBillingPixSettlementModalOpen = false;
        break;
      }

      case BankBillingTypes.BANK_BILLINGS_RESET_LIST: {
        draft.detail = {};
        draft.showNextPixRequestMessage = null;
        draft.bankBillingPixEmitModalOpen = false;
        draft.bankBillingPixShareModalOpen = false;
        draft.bankBillingPixDetailModalOpen = false;
        draft.bankBillingPixAddAccountModalOpen = false;
        draft.bankBillingPixModalOpen = false;
        draft.bankBillingPixSettlementModalOpen = false;
        draft.selectedAccount = null;
        break;
      }

      default:
    }
  });
}
