import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  loading: false,
  companyNcmFilterModalOpen: false,
};

export default function companyNcms(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COMPANY_NCMS_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_NCMS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.companyNcmFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        break;
      }
      case Types.COMPANY_NCMS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_NCMS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_NCMS_CREATE_SUCCESS: {
        const newCompanyNcm = action.payload.companyNcm;

        draft.list = state.list.map(companyNcm => {
          return companyNcm.id === newCompanyNcm.id
            ? newCompanyNcm
            : companyNcm;
        });

        draft.loading = false;
        break;
      }
      case Types.COMPANY_NCMS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_NCMS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_NCMS_DELETE_SUCCESS: {
        draft.list = state.list.map(companyNcm => {
          if (companyNcm.id === action.payload.id) {
            const modifyIsEnabled = {
              ...companyNcm,
              is_enabled: false,
            };

            return modifyIsEnabled;
          }
          return companyNcm;
        });

        draft.loading = false;
        break;
      }
      case Types.COMPANY_NCMS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_NCMS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.companyNcmFilterModalOpen = false;
        break;
      }

      case Types.COMPANY_NCMS_FILTER_OPEN_MODAL: {
        draft.companyNcmFilterModalOpen = true;
        break;
      }
      case Types.COMPANY_NCMS_FILTER_CLOSE_MODAL: {
        draft.companyNcmFilterModalOpen = false;
        break;
      }

      default:
    }
  });
}
