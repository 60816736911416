import { TrendingDown as RFTrendingDown } from 'react-feather';

import styled from 'styled-components';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'tooltip':
      styles = `
        width: 13px;
        height: 13px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const TrendingDown = styled(RFTrendingDown)`
  ${({ type }) => defineStyles(type)}
`;
