import styled from 'styled-components';

import { Grid, ListItem as MuiListItem, Link } from '@material-ui/core';

import colors from '~/theme/colors';

export const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`;

export const GridContainer = styled(Grid).attrs({
  spacing: 0,
})``;

export const GridCompany = styled(Grid).attrs({
  justify: 'flex-end',
})``;

export const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: ${colors.black};
  }
`;

export const CustomNavigationLink = styled(Link).attrs({
  underline: 'none',
})`
  color: ${colors.black};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.palette.primary.main};
  }
`;
