import Types from './types';

export function getRegionCustomers(customers) {
  return {
    type: Types.REGION_CUSTOMERS_GET,
    payload: { customers },
  };
}

export function updateRegionCustomerRequest(region_id, customers, cities = []) {
  return {
    type: Types.REGION_CUSTOMERS_UPDATE_REQUEST,
    payload: { region_id, customers, cities },
  };
}

export function updateRegionCustomerSuccess(customers) {
  return {
    type: Types.REGION_CUSTOMERS_UPDATE_SUCCESS,
    payload: { customers },
  };
}

export function updateRegionCustomerFailure() {
  return {
    type: Types.REGION_CUSTOMERS_UPDATE_FAILURE,
  };
}

export function deleteRegionCustomerRequest(region_id, person) {
  return {
    type: Types.REGION_CUSTOMERS_DELETE_REQUEST,
    payload: { region_id, person },
  };
}

export function deleteRegionCustomerSuccess(person_id) {
  return {
    type: Types.REGION_CUSTOMERS_DELETE_SUCCESS,
    payload: { person_id },
  };
}

export function deleteRegionCustomerFailure() {
  return {
    type: Types.REGION_CUSTOMERS_DELETE_FAILURE,
  };
}

export function resetListRegionCustomer() {
  return {
    type: Types.REGION_CUSTOMERS_RESET_LIST,
  };
}

export function openModalRegionCustomer() {
  return {
    type: Types.REGION_CUSTOMERS_OPEN_MODAL,
  };
}

export function closeModalRegionCustomer() {
  return {
    type: Types.REGION_CUSTOMERS_CLOSE_MODAL,
  };
}
