const Types = {
  DASHBOARD_FILTER_OPEN_MODAL: '@dashboard/OPEN_MODAL_FILTER_DASHBOARD',
  DASHBOARD_FILTER_CLOSE_MODAL: '@dashboard/CLOSE_MODAL_FILTER_DASHBOARD',
  DASHBOARD_GET_PRODUCTS_CHART_REQUEST: '@dashboard/GET_PRODUCTS_CHART_REQUEST',
  DASHBOARD_GET_PRODUCTS_CHART_SUCCESS: '@dashboard/GET_PRODUCTS_CHART_SUCCESS',
  DASHBOARD_GET_PRODUCTS_CHART_FAILURE: '@dashboard/GET_PRODUCTS_CHART_FAILURE',
  DASHBOARD_GET_ORDERS_SALES_CHARTS_REQUEST:
    '@dashboard/GET_ORDERS_SALES_CHARTS_REQUEST',
  DASHBOARD_GET_ORDERS_SALES_CHARTS_SUCCESS:
    '@dashboard/GET_ORDERS_SALES_CHARTS_SUCCESS',
  DASHBOARD_GET_ORDERS_SALES_CHARTS_FAILURE:
    '@dashboard/GET_ORDERS_SALES_CHARTS_FAILURE',
  DASHBOARD_RESET_LIST: '@dashboard/RESET_LIST_DASHBOARD',
};

export default Types;
