const Types = {
  PROMOTER_ADDRESSES_GET: '@promoters/GET_PROMOTER_ADDRESSES',

  PROMOTER_ADDRESSES_CREATE_REQUEST:
    '@promoters/CREATE_PROMOTER_ADDRESS_REQUEST',
  PROMOTER_ADDRESSES_CREATE_SUCCESS:
    '@promoters/CREATE_PROMOTER_ADDRESS_SUCCESS',
  PROMOTER_ADDRESSES_CREATE_FAILURE:
    '@promoters/CREATE_PROMOTER_ADDRESS_FAILURE',

  PROMOTER_ADDRESSES_UPDATE_REQUEST:
    '@promoters/UPDATE_PROMOTER_ADDRESS_REQUEST',
  PROMOTER_ADDRESSES_UPDATE_SUCCESS:
    '@promoters/UPDATE_PROMOTER_ADDRESS_SUCCESS',
  PROMOTER_ADDRESSES_UPDATE_FAILURE:
    '@promoters/UPDATE_PROMOTER_ADDRESS_FAILURE',

  PROMOTER_ADDRESSES_DELETE_REQUEST:
    '@promoters/DELETE_PROMOTER_ADDRESS_REQUEST',
  PROMOTER_ADDRESSES_DELETE_SUCCESS:
    '@promoters/DELETE_PROMOTER_ADDRESS_SUCCESS',
  PROMOTER_ADDRESSES_DELETE_FAILURE:
    '@promoters/DELETE_PROMOTER_ADDRESS_FAILURE',

  PROMOTER_ADDRESSES_RESET_LIST: '@promoters/RESET_LIST_PROMOTER_ADDRESS',

  PROMOTER_ADDRESSES_OPEN_MODAL: '@promoters/OPEN_MODAL_PROMOTER_ADDRESS',
  PROMOTER_ADDRESSES_CLOSE_MODAL: '@promoters/CLOSE_MODAL_PROMOTER_ADDRESS',
};

export default Types;
