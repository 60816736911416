import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getSellerUsersSuccess,
  getSellerUsersFailure,
  createSellerUserSuccess,
  createSellerUserFailure,
  deleteSellerUserSuccess,
  deleteSellerUserFailure,
} from './actions';
import Types from './types';

export function* getSellerUsers({ payload }) {
  try {
    const { seller_id } = payload;

    const response = yield call(api.get, `sellers/${seller_id}/users`);

    yield put(getSellerUsersSuccess(response.data));
  } catch (err) {
    yield put(getSellerUsersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(
      <IntlMessages id="seller-users.messages.get-seller-users-failure" />
    );
  }
}

export function* createSellerUser({ payload }) {
  try {
    const { seller_id, user } = payload;

    const response = yield call(api.post, `sellers/${seller_id}/users`, user);

    yield put(createSellerUserSuccess(response.data));

    toast.success(<IntlMessages id="sellers.messages.create-bound-success" />);
  } catch (err) {
    yield put(createSellerUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sellers.messages.create-bound-failure" />);
  }
}

export function* deleteSellerUser({ payload }) {
  try {
    const { seller_id } = payload;

    yield call(api.delete, `sellers/${seller_id}/users`);

    yield put(deleteSellerUserSuccess());

    const response = yield call(api.get, `sellers/${seller_id}/users`);

    yield put(getSellerUsersSuccess(response.data));

    toast.success(<IntlMessages id="sellers.messages.delete-bound-success" />);
  } catch (err) {
    yield put(deleteSellerUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sellers.messages.delete-bound-failure" />);
  }
}

export default all([
  takeLatest(Types.SELLER_USERS_GET_REQUEST, getSellerUsers),
  takeLatest(Types.SELLER_USERS_CREATE_REQUEST, createSellerUser),
  takeLatest(Types.SELLER_USERS_DELETE_REQUEST, deleteSellerUser),
]);
