import React from 'react';

import PropTypes from 'prop-types';

import { Done } from './styles';

export default function OkIcon({ type }) {
  return <Done type={type} />;
}

OkIcon.propTypes = {
  type: PropTypes.string,
};

OkIcon.defaultProps = {
  type: '',
};
