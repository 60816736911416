import Types from './types';

export function getPaymentMethodInstallments(installments) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_GET,
    payload: { installments },
  };
}

export function createPaymentMethodInstallmentRequest(
  payment_method_id,
  installment
) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_REQUEST,
    payload: { payment_method_id, installment },
  };
}

export function createPaymentMethodInstallmentSuccess(installment) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_SUCCESS,
    payload: { installment },
  };
}

export function createPaymentMethodInstallmentFailure() {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_FAILURE,
  };
}

export function updatePaymentMethodInstallmentRequest(
  payment_method_id,
  installment_id,
  newInstallment
) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_REQUEST,
    payload: { payment_method_id, installment_id, newInstallment },
  };
}

export function updatePaymentMethodInstallmentSuccess(newInstallment) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_SUCCESS,
    payload: { newInstallment },
  };
}

export function updatePaymentMethodInstallmentFailure() {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_FAILURE,
  };
}

export function deletePaymentMethodInstallmentRequest(
  payment_method_id,
  installment_id
) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_REQUEST,
    payload: { payment_method_id, installment_id },
  };
}

export function deletePaymentMethodInstallmentSuccess(installment_id) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_SUCCESS,
    payload: { installment_id },
  };
}

export function deletePaymentMethodInstallmentFailure() {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_FAILURE,
  };
}

export function resetListPaymentMethodInstallment() {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_RESET_LIST,
  };
}

export function openModalPaymentMethodInstallment({
  installment_id,
  newRegister,
  editInstallment,
}) {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_OPEN_MODAL,
    payload: { installment_id, newRegister, editInstallment },
  };
}

export function closeModalPaymentMethodInstallment() {
  return {
    type: Types.PAYMENT_METHOD_INSTALLMENTS_CLOSE_MODAL,
  };
}
