import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,

  subResourceType: null,
  shouldOpenSubResourceModal: false,
};

export default function teams(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.TEAMS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.TEAMS_GET_SUCCESS: {
        const { data } = action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        break;
      }
      case Types.TEAMS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.TEAMS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.TEAMS_CREATE_SUCCESS: {
        const { team } = action.payload;

        draft.list.unshift(team);
        draft.loading = false;
        break;
      }
      case Types.TEAMS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.TEAMS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.TEAMS_UPDATE_SUCCESS: {
        const { newTeam } = action.payload;
        draft.detail = newTeam;

        draft.list = state.list.map(team =>
          team.id === newTeam.id ? newTeam : team
        );
        draft.loading = false;
        break;
      }
      case Types.TEAMS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.TEAMS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.TEAMS_DELETE_SUCCESS: {
        const { id } = action.payload;

        draft.list = state.list.filter(team => team.id !== id);
        draft.loading = false;
        break;
      }
      case Types.TEAMS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.TEAMS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.TEAMS_GET_DETAILS_SUCCESS: {
        const { team } = action.payload;

        draft.detail = team;
        draft.requestError = false;
        break;
      }
      case Types.TEAMS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.TEAMS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        break;
      }
      case Types.TEAMS_RESET_DETAIL: {
        draft.detail = {};
        draft.requestError = false;
        draft.loading = false;
        break;
      }

      case Types.TEAMS_SET_SAVING_ON_SUB_RESOURCE: {
        const { subResourceType } = action.payload;

        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = subResourceType;
        break;
      }
      case Types.TEAMS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      default:
    }
  });
}
