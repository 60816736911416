import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  updatePersonSuccess,
  updatePersonFailure,
  deletePersonSuccess,
  deletePersonFailure,
  getCheckExistsPersonSuccess,
  getCheckExistsPersonFailure,
  getDetailsPersonSuccess,
  getDetailsPersonFailure,
} from './actions';
import Types from './types';

export function* updatePerson({ payload }) {
  try {
    const { id, newPerson } = payload;

    const response = yield call(api.put, `persons/${id}`, newPerson);

    yield put(updatePersonSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updatePersonFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePerson({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `persons/${id}`);

    yield put(deletePersonSuccess(id));

    history.push('/dashboard');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePersonFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* checkExistsPerson({ payload }) {
  try {
    const { document, type } = payload;

    const response = yield call(api.get, `persons/check-exists/${document}`, {
      params: {
        type,
      },
    });

    yield put(getCheckExistsPersonSuccess(response.data));
  } catch (err) {
    yield put(getCheckExistsPersonFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsPerson({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `persons/${id}`);

    yield put(getDetailsPersonSuccess(response.data));
  } catch (err) {
    yield put(getDetailsPersonFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(Types.PERSONS_UPDATE_REQUEST, updatePerson),
  takeLatest(Types.PERSONS_DELETE_REQUEST, deletePerson),
  takeLatest(Types.PERSONS_CHECK_EXISTS_REQUEST, checkExistsPerson),
  takeLatest(Types.PERSONS_GET_DETAILS_REQUEST, getDetailsPerson),
]);
