const Types = {
  SUPPLIER_EMAILS_GET: '@suppliers/GET_SUPPLIER_EMAILS',

  SUPPLIER_EMAILS_CREATE_REQUEST: '@suppliers/CREATE_SUPPLIER_EMAIL_REQUEST',
  SUPPLIER_EMAILS_CREATE_SUCCESS: '@suppliers/CREATE_SUPPLIER_EMAIL_SUCCESS',
  SUPPLIER_EMAILS_CREATE_FAILURE: '@suppliers/CREATE_SUPPLIER_EMAIL_FAILURE',

  SUPPLIER_EMAILS_UPDATE_REQUEST: '@suppliers/UPDATE_SUPPLIER_EMAIL_REQUEST',
  SUPPLIER_EMAILS_UPDATE_SUCCESS: '@suppliers/UPDATE_SUPPLIER_EMAIL_SUCCESS',
  SUPPLIER_EMAILS_UPDATE_FAILURE: '@suppliers/UPDATE_SUPPLIER_EMAIL_FAILURE',

  SUPPLIER_EMAILS_DELETE_REQUEST: '@suppliers/DELETE_SUPPLIER_EMAIL_REQUEST',
  SUPPLIER_EMAILS_DELETE_SUCCESS: '@suppliers/DELETE_SUPPLIER_EMAIL_SUCCESS',
  SUPPLIER_EMAILS_DELETE_FAILURE: '@suppliers/DELETE_SUPPLIER_EMAIL_FAILURE',

  SUPPLIER_EMAILS_RESET_LIST: '@suppliers/RESET_LIST_SUPPLIER_EMAIL',

  SUPPLIER_EMAILS_OPEN_MODAL: '@suppliers/OPEN_MODAL_SUPPLIER_EMAIL',
  SUPPLIER_EMAILS_CLOSE_MODAL: '@suppliers/CLOSE_MODAL_SUPPLIER_EMAIL',
};

export default Types;
