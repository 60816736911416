import Types from './types';

export function getSupplierEmails(emails) {
  return {
    type: Types.SUPPLIER_EMAILS_GET,
    payload: { emails },
  };
}

export function createSupplierEmailRequest(supplier_id, email) {
  return {
    type: Types.SUPPLIER_EMAILS_CREATE_REQUEST,
    payload: { supplier_id, email },
  };
}

export function createSupplierEmailSuccess(email) {
  return {
    type: Types.SUPPLIER_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createSupplierEmailFailure() {
  return {
    type: Types.SUPPLIER_EMAILS_CREATE_FAILURE,
  };
}

export function updateSupplierEmailRequest(supplier_id, email_id, newEmail) {
  return {
    type: Types.SUPPLIER_EMAILS_UPDATE_REQUEST,
    payload: { supplier_id, email_id, newEmail },
  };
}

export function updateSupplierEmailSuccess(newEmail) {
  return {
    type: Types.SUPPLIER_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateSupplierEmailFailure() {
  return {
    type: Types.SUPPLIER_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteSupplierEmailRequest(supplier_id, email_id) {
  return {
    type: Types.SUPPLIER_EMAILS_DELETE_REQUEST,
    payload: { supplier_id, email_id },
  };
}

export function deleteSupplierEmailSuccess(email_id) {
  return {
    type: Types.SUPPLIER_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteSupplierEmailFailure() {
  return {
    type: Types.SUPPLIER_EMAILS_DELETE_FAILURE,
  };
}

export function resetListSupplierEmail() {
  return {
    type: Types.SUPPLIER_EMAILS_RESET_LIST,
  };
}

export function openModalSupplierEmail(email_id) {
  return {
    type: Types.SUPPLIER_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalSupplierEmail() {
  return {
    type: Types.SUPPLIER_EMAILS_CLOSE_MODAL,
  };
}
