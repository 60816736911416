import Types from './types';

export function getProductDiscounts(discounts) {
  return {
    type: Types.PRODUCT_DISCOUNTS_GET,
    payload: { discounts },
  };
}

export function createProductDiscountRequest(discount) {
  return {
    type: Types.PRODUCT_DISCOUNTS_CREATE_REQUEST,
    payload: { discount },
  };
}

export function createProductDiscountSuccess(discount) {
  return {
    type: Types.PRODUCT_DISCOUNTS_CREATE_SUCCESS,
    payload: { discount },
  };
}

export function createProductDiscountFailure() {
  return {
    type: Types.PRODUCT_DISCOUNTS_CREATE_FAILURE,
  };
}

export function updateProductDiscountRequest(discount_id, newDiscount) {
  return {
    type: Types.PRODUCT_DISCOUNTS_UPDATE_REQUEST,
    payload: { discount_id, newDiscount },
  };
}

export function updateProductDiscountSuccess(newDiscount) {
  return {
    type: Types.PRODUCT_DISCOUNTS_UPDATE_SUCCESS,
    payload: { newDiscount },
  };
}

export function updateProductDiscountFailure() {
  return {
    type: Types.PRODUCT_DISCOUNTS_UPDATE_FAILURE,
  };
}

export function deleteProductDiscountRequest(discount_id) {
  return {
    type: Types.PRODUCT_DISCOUNTS_DELETE_REQUEST,
    payload: { discount_id },
  };
}

export function deleteProductDiscountSuccess(discount_id) {
  return {
    type: Types.PRODUCT_DISCOUNTS_DELETE_SUCCESS,
    payload: { discount_id },
  };
}

export function deleteProductDiscountFailure() {
  return {
    type: Types.PRODUCT_DISCOUNTS_DELETE_FAILURE,
  };
}

export function openModalProductDiscount(discount_id) {
  return {
    type: Types.PRODUCT_DISCOUNTS_OPEN_MODAL,
    payload: { discount_id },
  };
}

export function closeModalProductDiscount() {
  return {
    type: Types.PRODUCT_DISCOUNTS_CLOSE_MODAL,
  };
}

export function resetListProductDiscount() {
  return {
    type: Types.PRODUCT_DISCOUNTS_RESET_LIST,
  };
}
