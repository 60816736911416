import produce from 'immer';

import RuralPropertyTypes from '../ruralProperties/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  ruralPropertyRegistrationModalOpen: false,
};

export default function ruralPropertyRegistrations(
  state = INITIAL_STATE,
  action
) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.RURAL_PROPERTY_REGISTRATIONS_GET: {
        draft.list = action.payload.registrations;
        break;
      }

      case Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_SUCCESS: {
        const { is_main } = action.payload.registration;

        if (is_main) {
          draft.list = draft.list.map(registration => ({
            ...registration,
            is_main: false,
          }));
        }

        draft.list.unshift(action.payload.registration);
        draft.loading = false;
        draft.ruralPropertyRegistrationModalOpen = false;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_SUCCESS: {
        const { newRegistration } = action.payload;

        draft.list = state.list.map(registration => {
          return registration.id === newRegistration.id
            ? newRegistration
            : registration;
        });

        draft.detail = {};
        draft.loading = false;
        draft.ruralPropertyRegistrationModalOpen = false;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_SUCCESS: {
        const { registration_id } = action.payload;

        draft.list = state.list.filter(registration => {
          return registration.id !== registration_id;
        });

        if (draft.detail.id && registration_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTY_REGISTRATIONS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.RURAL_PROPERTY_REGISTRATIONS_OPEN_MODAL: {
        const { registration_id } = action.payload;

        draft.detail = {};

        if (registration_id) {
          [draft.detail] = draft.list.filter(
            registration => registration.id === registration_id
          );
        }

        draft.ruralPropertyRegistrationModalOpen = true;
        break;
      }
      case Types.RURAL_PROPERTY_REGISTRATIONS_CLOSE_MODAL: {
        draft.ruralPropertyRegistrationModalOpen = false;
        draft.detail = {};
        break;
      }

      case RuralPropertyTypes.RURAL_PROPERTIES_RESET_DETAILS: {
        draft.ruralPropertyRegistrationModalOpen = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      default:
    }
  });
}
