import Types from './types';

export function getProductFiles(files) {
  return {
    type: Types.PRODUCT_FILES_GET,
    payload: { files },
  };
}

export function createProductFileRequest(product_id, file) {
  return {
    type: Types.PRODUCT_FILES_CREATE_REQUEST,
    payload: { product_id, file },
  };
}

export function createProductFileSuccess(file) {
  return {
    type: Types.PRODUCT_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createProductFileFailure() {
  return {
    type: Types.PRODUCT_FILES_CREATE_FAILURE,
  };
}

export function updateProductFileRequest(product_id, file_id, newFile) {
  return {
    type: Types.PRODUCT_FILES_UPDATE_REQUEST,
    payload: { product_id, file_id, newFile },
  };
}

export function updateProductFileSuccess(newFile) {
  return {
    type: Types.PRODUCT_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateProductFileFailure() {
  return {
    type: Types.PRODUCT_FILES_UPDATE_FAILURE,
  };
}

export function deleteProductFileRequest(product_id, file_id) {
  return {
    type: Types.PRODUCT_FILES_DELETE_REQUEST,
    payload: { product_id, file_id },
  };
}

export function deleteProductFileSuccess(file_id) {
  return {
    type: Types.PRODUCT_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteProductFileFailure() {
  return {
    type: Types.PRODUCT_FILES_DELETE_FAILURE,
  };
}

export function openModalProductFile() {
  return {
    type: Types.PRODUCT_FILES_OPEN_MODAL,
  };
}

export function closeModalProductFile() {
  return {
    type: Types.PRODUCT_FILES_CLOSE_MODAL,
  };
}

export function resetListProductFile() {
  return {
    type: Types.PRODUCT_FILES_RESET_LIST,
  };
}
