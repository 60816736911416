const Types = {
  CHART_ACCOUNTS_GET_REQUEST: '@chartAccounts/GET_CHART_ACCOUNTS_REQUEST',
  CHART_ACCOUNTS_GET_SUCCESS: '@chartAccounts/GET_CHART_ACCOUNTS_SUCCESS',
  CHART_ACCOUNTS_GET_FAILURE: '@chartAccounts/GET_CHART_ACCOUNTS_FAILURE',

  CHART_ACCOUNTS_CREATE_REQUEST: '@chartAccounts/CREATE_CHART_ACCOUNT_REQUEST',
  CHART_ACCOUNTS_CREATE_SUCCESS: '@chartAccounts/CREATE_CHART_ACCOUNT_SUCCESS',
  CHART_ACCOUNTS_CREATE_FAILURE: '@chartAccounts/CREATE_CHART_ACCOUNT_FAILURE',

  CHART_ACCOUNTS_UPDATE_REQUEST: '@chartAccounts/UPDATE_CHART_ACCOUNT_REQUEST',
  CHART_ACCOUNTS_UPDATE_SUCCESS: '@chartAccounts/UPDATE_CHART_ACCOUNT_SUCCESS',
  CHART_ACCOUNTS_UPDATE_FAILURE: '@chartAccounts/UPDATE_CHART_ACCOUNT_FAILURE',

  CHART_ACCOUNTS_DELETE_REQUEST: '@chartAccounts/DELETE_CHART_ACCOUNT_REQUEST',
  CHART_ACCOUNTS_DELETE_SUCCESS: '@chartAccounts/DELETE_CHART_ACCOUNT_SUCCESS',
  CHART_ACCOUNTS_DELETE_FAILURE: '@chartAccounts/DELETE_CHART_ACCOUNT_FAILURE',

  CHART_ACCOUNTS_RESET_LIST: '@chartAccounts/RESET_LIST_CHART_ACCOUNTS',

  CHART_ACCOUNTS_OPEN_MODAL: '@chartAccounts/OPEN_MODAL_CHART_ACCOUNT',
  CHART_ACCOUNTS_CLOSE_MODAL: '@chartAccounts/CLOSE_MODAL_CHART_ACCOUNT',
};

export default Types;
