import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createRuralPropertyFileSuccess,
  createRuralPropertyFileFailure,
  updateRuralPropertyFileSuccess,
  updateRuralPropertyFileFailure,
  deleteRuralPropertyFileSuccess,
  deleteRuralPropertyFileFailure,
} from './actions';
import Types from './types';

export function* createRuralPropertyFile({ payload }) {
  try {
    const { property_id, file } = payload;

    const response = yield call(
      api.post,
      `rural-properties/${property_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createRuralPropertyFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRuralPropertyFile({ payload }) {
  try {
    const { property_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `rural-properties/${property_id}/files/${file_id}`,
      newFile
    );

    yield put(updateRuralPropertyFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRuralPropertyFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteRuralPropertyFile({ payload }) {
  try {
    const { property_id, file_id } = payload;

    yield call(api.delete, `rural-properties/${property_id}/files/${file_id}`);

    yield put(deleteRuralPropertyFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRuralPropertyFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.RURAL_PROPERTY_FILES_CREATE_REQUEST,
    createRuralPropertyFile
  ),
  takeLatest(
    Types.RURAL_PROPERTY_FILES_UPDATE_REQUEST,
    updateRuralPropertyFile
  ),
  takeLatest(
    Types.RURAL_PROPERTY_FILES_DELETE_REQUEST,
    deleteRuralPropertyFile
  ),
]);
