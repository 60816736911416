import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createOrderBillSuccess,
  createOrderBillFailure,
  updateBillOrderBillSuccess,
  updateBillOrderBillFailure,
  deleteOrderBillSuccess,
  deleteOrderBillFailure,
  deleteAllOrderBillSuccess,
  deleteAllOrderBillFailure,
} from './actions';
import Types from './types';

export function* createOrderBill({ payload }) {
  try {
    const { order_id, bill, showMessage } = payload;

    const response = yield call(api.post, `orders/${order_id}/bills`, bill);

    yield put(createOrderBillSuccess(response.data));

    if (showMessage) {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createOrderBillFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOrderBill({ payload }) {
  try {
    const { bill_id, newBill } = payload;

    const response = yield call(api.put, `bills/${bill_id}`, newBill);

    yield put(updateBillOrderBillSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBillOrderBillFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOrderBill({ payload }) {
  try {
    const { bill_id } = payload;

    yield call(api.delete, `bills/${bill_id}`);

    yield put(deleteOrderBillSuccess(bill_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteOrderBillFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* deleteAllOrderBill({ payload }) {
  try {
    const { order_id } = payload;

    yield call(api.delete, `orders/${order_id}/bills/`);

    yield put(deleteAllOrderBillSuccess());

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteAllOrderBillFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.ORDER_BILLS_CREATE_REQUEST, createOrderBill),
  takeLatest(Types.ORDER_BILLS_UPDATE_BILL_REQUEST, updateOrderBill),
  takeLatest(Types.ORDER_BILLS_DELETE_REQUEST, deleteOrderBill),
  takeLatest(Types.ORDER_BILLS_DELETE_ALL_REQUEST, deleteAllOrderBill),
]);
