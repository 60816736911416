import React from 'react';

import PropTypes from 'prop-types';

import { View } from './styles';

export default function ViewIcon({ type }) {
  return <View type={type} />;
}

ViewIcon.propTypes = {
  type: PropTypes.string,
};

ViewIcon.defaultProps = {
  type: '',
};
