import Types from './types';

export function getRegionsRequest() {
  return {
    type: Types.REGIONS_GET_REQUEST,
  };
}

export function getRegionsSuccess({ data }) {
  return {
    type: Types.REGIONS_GET_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getRegionsFailure() {
  return {
    type: Types.REGIONS_GET_FAILURE,
  };
}

export function createRegionRequest(region, subResourceType = null) {
  return {
    type: Types.REGIONS_CREATE_REQUEST,
    payload: { region, subResourceType },
  };
}

export function createRegionSuccess(region) {
  return {
    type: Types.REGIONS_CREATE_SUCCESS,
    payload: { region },
  };
}

export function createRegionFailure() {
  return {
    type: Types.REGIONS_CREATE_FAILURE,
  };
}

export function updateRegionRequest(id, newRegion) {
  return {
    type: Types.REGIONS_UPDATE_REQUEST,
    payload: {
      id,
      newRegion,
    },
  };
}

export function updateRegionSuccess(newRegion) {
  return {
    type: Types.REGIONS_UPDATE_SUCCESS,
    payload: {
      newRegion,
    },
  };
}

export function updateRegionFailure() {
  return {
    type: Types.REGIONS_UPDATE_FAILURE,
  };
}

export function deleteRegionRequest(id) {
  return {
    type: Types.REGIONS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteRegionSuccess(id) {
  return {
    type: Types.REGIONS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteRegionFailure() {
  return {
    type: Types.REGIONS_DELETE_FAILURE,
  };
}

export function getDetailsRegionRequest(id) {
  return {
    type: Types.REGIONS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsRegionSuccess(region) {
  return {
    type: Types.REGIONS_GET_DETAILS_SUCCESS,
    payload: { region },
  };
}

export function getDetailsRegionFailure() {
  return {
    type: Types.REGIONS_GET_DETAILS_FAILURE,
  };
}

export function openModalRegionRowData(rowData) {
  return {
    type: Types.REGIONS_ROW_DATA_OPEN_MODAL,
    payload: { rowData },
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.REGIONS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.REGIONS_RESET_SUB_RESOURCE_DATA,
  };
}

export function closeModalRegionRowData() {
  return {
    type: Types.REGIONS_ROW_DATA_CLOSE_MODAL,
  };
}

export function resetListRegions() {
  return {
    type: Types.REGIONS_RESET_LIST,
  };
}

export function resetDetailsRegion() {
  return {
    type: Types.REGIONS_RESET_DETAIL,
  };
}
