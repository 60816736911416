const Types = {
  RURAL_PROPERTY_OWNERS_GET: '@ruralProperty/GET_RURAL_PROPERTY_OWNERS',

  RURAL_PROPERTY_OWNERS_CREATE_REQUEST:
    '@ruralProperty/CREATE_RURAL_PROPERTY_OWNER_REQUEST',
  RURAL_PROPERTY_OWNERS_CREATE_SUCCESS:
    '@ruralProperty/CREATE_RURAL_PROPERTY_OWNER_SUCCESS',
  RURAL_PROPERTY_OWNERS_CREATE_FAILURE:
    '@ruralProperty/CREATE_RURAL_PROPERTY_OWNER_FAILURE',

  RURAL_PROPERTY_OWNERS_DELETE_REQUEST:
    '@ruralProperty/DELETE_RURAL_PROPERTY_OWNER_REQUEST',
  RURAL_PROPERTY_OWNERS_DELETE_SUCCESS:
    '@ruralProperty/DELETE_RURAL_PROPERTY_OWNER_SUCCESS',
  RURAL_PROPERTY_OWNERS_DELETE_FAILURE:
    '@ruralProperty/DELETE_RURAL_PROPERTY_OWNER_FAILURE',

  RURAL_PROPERTY_OWNERS_RESET_LIST:
    '@ruralProperty/RESET_LIST_RURAL_PROPERTY_OWNER',

  RURAL_PROPERTY_OWNERS_OPEN_MODAL:
    '@ruralProperty/OPEN_MODAL_RURAL_PROPERTY_OWNER',
  RURAL_PROPERTY_OWNERS_CLOSE_MODAL:
    '@ruralProperty/CLOSE_MODAL_RURAL_PROPERTY_OWNER',
};

export default Types;
