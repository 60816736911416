const Types = {
  UNITS_MEASURE_GET_REQUEST: '@unitsMeasure/GET_UNITS_MEASURE_GET_REQUEST',
  UNITS_MEASURE_GET_SUCCESS: '@unitsMeasure/GET_UNITS_MEASURE_GET_SUCCESS',
  UNITS_MEASURE_GET_FAILURE: '@unitsMeasure/GET_UNITS_MEASURE_GET_FAILURE',

  UNITS_MEASURE_CREATE_REQUEST: '@unitsMeasure/CREATE_UNIT_MEASURE_REQUEST',
  UNITS_MEASURE_CREATE_SUCCESS: '@unitsMeasure/CREATE_UNIT_MEASURE_SUCCESS',
  UNITS_MEASURE_CREATE_FAILURE: '@unitsMeasure/CREATE_UNIT_MEASURE_FAILURE',

  UNITS_MEASURE_UPDATE_REQUEST: '@unitsMeasure/UPDATE_UNIT_MEASURE_REQUEST',
  UNITS_MEASURE_UPDATE_SUCCESS: '@unitsMeasure/UPDATE_UNIT_MEASURE_SUCCESS',
  UNITS_MEASURE_UPDATE_FAILURE: '@unitsMeasure/UPDATE_UNIT_MEASURE_FAILURE',

  UNITS_MEASURE_DELETE_REQUEST: '@unitsMeasure/DELETE_UNIT_MEASURE_REQUEST',
  UNITS_MEASURE_DELETE_SUCCESS: '@unitsMeasure/DELETE_UNIT_MEASURE_SUCCESS',
  UNITS_MEASURE_DELETE_FAILURE: '@unitsMeasure/DELETE_UNIT_MEASURE_FAILURE',

  UNITS_MEASURE_RESET_LIST: '@unitsMeasure/RESET_LIST_UNITS_MEASURE',

  UNITS_MEASURE_FILTER_OPEN_MODAL:
    '@unitsMeasure/OPEN_MODAL_FILTER_UNIT_MEASURE',
  UNITS_MEASURE_FILTER_CLOSE_MODAL:
    '@unitsMeasure/CLOSE_MODAL_FILTER_UNIT_MEASURE',

  UNITS_MEASURE_OPEN_MODAL: '@unitsMeasure/OPEN_MODAL_UNIT_MEASURE',
  UNITS_MEASURE_CLOSE_MODAL: '@unitsMeasure/CLOSE_MODAL_UNIT_MEASURE',
};

export default Types;
