import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { CssBaseline, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';

import { Footer } from '~/components/Footers';
import Header from '~/components/Header';
import ContactModal from '~/components/Modals/ContactModal';
import Sidebar from '~/components/Sidebar';
import { closeModalContact } from '~/store/modules/contacts/actions';
// import Settings from '~/components/Settings';

import {
  GlobalStyle,
  Root,
  AppContent,
  // Paper,
  MainContent,
} from './styles';

const drawerWidth = 260;

/* TODO: Transformar em funcao, usar Hooks no state, futuramente remover botao
   tipo FloatingButton ou utilizar como ajuda */

function Dashboard({ children, routes, width }) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [mobileOpen, setMobileOpen] = useState(false);

  const contact = useSelector(state => state.contacts);

  function handleDrawerToggle() {
    return setMobileOpen(!mobileOpen);
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Sidebar
        routes={routes}
        PaperProps={{ style: { width: drawerWidth } }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      />
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp('lg', width) ? 10 : 5}>
          {children}
        </MainContent>
        <Footer />
      </AppContent>

      {contact.contactModalOpen && (
        <ContactModal
          open={contact.contactModalOpen}
          title={formatMessage({ id: 'contacts.title' })}
          onClose={() => dispatch(closeModalContact())}
          size="small"
        />
      )}

      {/* <Settings /> */}
    </Root>
  );
}

Dashboard.propTypes = {
  children: PropTypes.element.isRequired,
  routes: PropTypes.string,
  width: PropTypes.string.isRequired,
};

Dashboard.defaultProps = {
  routes: undefined,
};

export default withWidth()(Dashboard);
