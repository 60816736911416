import Types from './types';

export function getUserFiles(files) {
  return {
    type: Types.USER_FILES_GET,
    payload: { files },
  };
}

export function createUserFileRequest(user_id, file) {
  return {
    type: Types.USER_FILES_CREATE_REQUEST,
    payload: { user_id, file },
  };
}

export function createUserFileSuccess(file) {
  return {
    type: Types.USER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createUserFileFailure() {
  return {
    type: Types.USER_FILES_CREATE_FAILURE,
  };
}

export function updateUserFileRequest(user_id, file_id, newFile) {
  return {
    type: Types.USER_FILES_UPDATE_REQUEST,
    payload: { user_id, file_id, newFile },
  };
}

export function updateUserFileSuccess(newFile) {
  return {
    type: Types.USER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateUserFileFailure() {
  return {
    type: Types.USER_FILES_UPDATE_FAILURE,
  };
}

export function deleteUserFileRequest(user_id, file_id) {
  return {
    type: Types.USER_FILES_DELETE_REQUEST,
    payload: { user_id, file_id },
  };
}

export function deleteUserFileSuccess(file_id) {
  return {
    type: Types.USER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteUserFileFailure() {
  return {
    type: Types.USER_FILES_DELETE_FAILURE,
  };
}

export function openModalUserFile() {
  return {
    type: Types.USER_FILES_OPEN_MODAL,
  };
}

export function closeModalUserFile() {
  return {
    type: Types.USER_FILES_CLOSE_MODAL,
  };
}

export function resetListUserFile() {
  return {
    type: Types.USER_FILES_RESET_LIST,
  };
}
