import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: null,
};

export default function roadmaps(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch(action.type) {
      case Types.ROADMAPS_SET_LIST: {
        const { list } = action.payload;

        draft.list = list;
        break;
      }

      case Types.ROADMAPS_SET_DETAIL: {
        const { detail } = action.payload;

        draft.detail = detail;

        break;
      }

      case Types.ROADMAPS_RESET_DETAIL: {
        draft.detail = null;
        break;
      }

      default:
        break;
    }
  })
}
