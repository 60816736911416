import produce from 'immer';

import ProductTypes from '../products/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  productPriceListItemModalOpen: false,
};

export default function productPriceListItem(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PRODUCT_PRICE_LIST_ITEMS_GET: {
        draft.list = action.payload.items;
        break;
      }

      case Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_SUCCESS: {
        const { newItem } = action.payload;

        const changeData = {
          commission_percentage: newItem.commission_percentage,
          id: state.detail.id,
          is_default: state.detail.is_default,
          list_name: state.detail.list_name,
          percentage_applied: newItem.percentage_applied,
          price: newItem.price,
          price_list_id: state.detail.price_list_id,
          is_denied_discount: newItem.is_denied_discount,
        };

        draft.list = state.list.map(item => {
          return item.id === changeData.id ? changeData : item;
        });

        draft.detail = {};
        draft.loading = false;
        draft.productPriceListItemModalOpen = false;

        break;
      }
      case Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCT_PRICE_LIST_ITEMS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.PRODUCT_PRICE_LIST_ITEMS_OPEN_MODAL: {
        const { item_id } = action.payload;

        draft.detail = {};

        if (item_id) {
          [draft.detail] = draft.list.filter(element => element.id === item_id);
        }

        draft.productPriceListItemModalOpen = true;
        break;
      }
      case Types.PRODUCT_PRICE_LIST_ITEMS_CLOSE_MODAL: {
        draft.productPriceListItemModalOpen = false;
        draft.detail = {};
        break;
      }

      case ProductTypes.PRODUCTS_RESET_DETAILS: {
        draft.productPriceListItemModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
