const Types = {
  ORDER_BILL_PIXS_CREATE_REQUEST: '@order/CREATE_ORDER_BILL_PIX_REQUEST',
  ORDER_BILL_PIXS_CREATE_SUCCESS: '@order/CREATE_ORDER_BILL_PIX_SUCCESS',
  ORDER_BILL_PIXS_CREATE_FAILURE: '@order/CREATE_ORDER_BILL_PIX_FAILURE',

  ORDER_BILL_PIXS_UPDATE_REQUEST: '@order/UPDATE_ORDER_BILL_PIX_REQUEST',
  ORDER_BILL_PIXS_UPDATE_SUCCESS: '@order/UPDATE_ORDER_BILL_PIX_SUCCESS',
  ORDER_BILL_PIXS_UPDATE_FAILURE: '@order/UPDATE_ORDER_BILL_PIX_FAILURE',

  ORDER_BILL_PIXS_RESET_LIST: '@order/RESET_LIST_ORDER_BILL_PIX',

  ORDER_BILL_PIXS_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_BILL_PIX',
  ORDER_BILL_PIXS_CLOSE_MODAL: '@order/CLOSE_MODAL_ORDER_BILL_PIX',

  ORDER_BILL_PIXS_EMIT_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_BILL_PIX_EMIT',
  ORDER_BILL_PIXS_EMIT_CLOSE_MODAL: '@order/CLOSE_MODAL_ORDER_BILL_PIX_EMIT',

  ORDER_BILL_PIXS_ADD_ACCOUNT_OPEN_MODAL:
    '@order/OPEN_MODAL_ORDER_BILL_PIX_ADD_ACCOUNT',
  ORDER_BILL_PIXS_ADD_ACCOUNT_CLOSE_MODAL:
    '@order/CLOSE_MODAL_ORDER_BILL_PIX_ADD_ACCOUNT',
  ORDER_BILL_PIXS_SET_SELECTED_ACCOUNT:
    '@order/SET_SELECTED_ACCOUNT_ORDER_BILL_PIX',

  ORDER_BILL_PIXS_DETAIL_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_BILL_PIX_DETAIL',
  ORDER_BILL_PIXS_DETAIL_CLOSE_MODAL:
    '@order/CLOSE_MODAL_ORDER_BILL_PIX_DETAIL',

  ORDER_BILL_PIXS_SHARE_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_BILL_PIX_SHARE',
  ORDER_BILL_PIXS_SHARE_CLOSE_MODAL: '@order/CLOSE_MODAL_ORDER_BILL_PIX_SHARE',
};

export default Types;
