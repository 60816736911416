const Types = {
  SELLER_FILES_GET: '@sellers/GET_SELLER_FILES',

  SELLER_FILES_CREATE_REQUEST: '@sellers/CREATE_SELLER_FILE_REQUEST',
  SELLER_FILES_CREATE_SUCCESS: '@sellers/CREATE_SELLER_FILE_SUCCESS',
  SELLER_FILES_CREATE_FAILURE: '@sellers/CREATE_SELLER_FILE_FAILURE',

  SELLER_FILES_UPDATE_REQUEST: '@sellers/UPDATE_SELLER_FILE_REQUEST',
  SELLER_FILES_UPDATE_SUCCESS: '@sellers/UPDATE_SELLER_FILE_SUCCESS',
  SELLER_FILES_UPDATE_FAILURE: '@sellers/UPDATE_SELLER_FILE_FAILURE',

  SELLER_FILES_DELETE_REQUEST: '@sellers/DELETE_SELLER_FILE_REQUEST',
  SELLER_FILES_DELETE_SUCCESS: '@sellers/DELETE_SELLER_FILE_SUCCESS',
  SELLER_FILES_DELETE_FAILURE: '@sellers/DELETE_SELLER_FILE_FAILURE',

  SELLER_FILES_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_FILE',
  SELLER_FILES_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_FILE',

  SELLER_FILES_RESET_LIST: '@sellers/RESET_LIST_SELLER_FILE',
};

export default Types;
