const Types = {
  RURAL_PROPERTIES_GET_REQUEST: '@ruralProperties/GET_RURAL_PROPERTIES_REQUEST',
  RURAL_PROPERTIES_GET_SUCCESS: '@ruralProperties/GET_RURAL_PROPERTIES_SUCCESS',
  RURAL_PROPERTIES_GET_FAILURE: '@ruralProperties/GET_RURAL_PROPERTIES_FAILURE',

  RURAL_PROPERTIES_CREATE_REQUEST:
    '@ruralProperties/CREATE_RURAL_PROPERTY_REQUEST',
  RURAL_PROPERTIES_CREATE_SUCCESS:
    '@ruralProperties/CREATE_RURAL_PROPERTY_SUCCESS',
  RURAL_PROPERTIES_CREATE_FAILURE:
    '@ruralProperties/CREATE_RURAL_PROPERTY_FAILURE',

  RURAL_PROPERTIES_UPDATE_REQUEST:
    '@ruralProperties/UPDATE_RURAL_PROPERTY_REQUEST',
  RURAL_PROPERTIES_UPDATE_SUCCESS:
    '@ruralProperties/UPDATE_RURAL_PROPERTY_SUCCESS',
  RURAL_PROPERTIES_UPDATE_FAILURE:
    '@ruralProperties/UPDATE_RURAL_PROPERTY_FAILURE',

  RURAL_PROPERTIES_DELETE_REQUEST:
    '@ruralProperties/DELETE_RURAL_PROPERTY_REQUEST',
  RURAL_PROPERTIES_DELETE_SUCCESS:
    '@ruralProperties/DELETE_RURAL_PROPERTY_SUCCESS',
  RURAL_PROPERTIES_DELETE_FAILURE:
    '@ruralProperties/DELETE_RURAL_PROPERTY_FAILURE',

  RURAL_PROPERTIES_GET_DETAILS_REQUEST:
    '@ruralProperties/GET_DETAILS_RURAL_PROPERTY_REQUEST',
  RURAL_PROPERTIES_GET_DETAILS_SUCCESS:
    '@ruralProperties/GET_DETAILS_RURAL_PROPERTY_SUCCESS',
  RURAL_PROPERTIES_GET_DETAILS_FAILURE:
    '@ruralProperties/GET_DETAILS_RURAL_PROPERTY_FAILURE',

  RURAL_PROPERTIES_RESET_LIST: '@ruralProperties/RESET_LIST_RURAL_PROPERTIES',
  RURAL_PROPERTIES_RESET_DETAILS:
    '@ruralProperties/RESET_DETAILS_RURAL_PROPERTY',

  RURAL_PROPERTIES_ADD_OPEN_MODAL:
    '@ruralProperties/OPEN_MODAL_ADD_RURAL_PROPERTY',
  RURAL_PROPERTIES_ADD_CLOSE_MODAL:
    '@ruralProperties/CLOSE_MODAL_ADD_RURAL_PROPERTY',

  RURAL_PROPERTIES_FILTER_OPEN_MODAL:
    '@ruralProperties/OPEN_MODAL_FILTER_RURAL_PROPERTY',
  RURAL_PROPERTIES_FILTER_CLOSE_MODAL:
    '@ruralProperties/CLOSE_MODAL_FILTER_RURAL_PROPERTY',
};

export default Types;
