import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getSettingsSuccess,
  getSettingsFailure,
  updateSettingSuccess,
  updateSettingFailure,
} from './actions';
import Types from './types';

export function* getSettings({ payload }) {
  try {
    const { system_category_id } = payload;

    const response = yield call(
      api.get,
      `settings?is_enabled=true&system_category_id=${system_category_id}`
    );

    yield put(getSettingsSuccess(response.data));
  } catch (err) {
    yield put(getSettingsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* updateSetting({ payload }) {
  try {
    const { setting_id, setting_item_id, newSettingItem } = payload;

    const { display_value, ...rest } = newSettingItem;

    const response = yield call(
      api.put,
      `settings/${setting_id}/items/${setting_item_id}`,
      rest
    );

    const newData = { ...response.data, display_value };

    yield put(updateSettingSuccess(newData));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSettingFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export default all([
  takeLatest(Types.SETTINGS_GET_REQUEST, getSettings),
  takeLatest(Types.SETTINGS_UPDATE_REQUEST, updateSetting),
]);
