const Types = {
  ORDER_BILLS_GET: '@order/GET_ORDER_BILLS',

  ORDER_BILLS_SET_LIST: '@order/SET_LIST_ORDER_BILLS',

  ORDER_BILLS_CREATE_REQUEST: '@order/CREATE_ORDER_BILL_REQUEST',
  ORDER_BILLS_CREATE_SUCCESS: '@order/CREATE_ORDER_BILL_SUCCESS',
  ORDER_BILLS_CREATE_FAILURE: '@order/CREATE_ORDER_BILL_FAILURE',

  ORDER_BILLS_UPDATE_BILL_REQUEST: '@order/UPDATE_BILL_ORDER_BILL_REQUEST',
  ORDER_BILLS_UPDATE_BILL_SUCCESS: '@order/UPDATE_BILL_ORDER_BILL_SUCCESS',
  ORDER_BILLS_UPDATE_BILL_FAILURE: '@order/UPDATE_BILL_ORDER_BILL_FAILURE',

  ORDER_BILLS_DELETE_REQUEST: '@order/DELETE_ORDER_BILL_REQUEST',
  ORDER_BILLS_DELETE_SUCCESS: '@order/DELETE_ORDER_BILL_SUCCESS',
  ORDER_BILLS_DELETE_FAILURE: '@order/DELETE_ORDER_BILL_FAILURE',

  ORDER_BILLS_DELETE_ALL_REQUEST: '@order/DELETE_ALL_ORDER_BILL_REQUEST',
  ORDER_BILLS_DELETE_ALL_SUCCESS: '@order/DELETE_ALL_ORDER_BILL_SUCCESS',
  ORDER_BILLS_DELETE_ALL_FAILURE: '@order/DELETE_ALL_ORDER_BILL_FAILURE',

  ORDER_BILLS_SET_SELECTED_BILL: '@order/SET_SELECTED_BILL_ORDER_BILL',

  ORDER_BILLS_RESET_LIST: '@order/RESET_LIST_ORDER_BILL',

  ORDER_BILLS_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_BILL',
  ORDER_BILLS_CLOSE_MODAL: '@order/CLOSE_MODAL_ORDER_BILL',

  ORDER_BILLS_EDIT_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_BILL_EDIT',
  ORDER_BILLS_EDIT_CLOSE_MODAL: '@order/CLOSE_MODAL_ORDER_BILL_EDIT',
};

export default Types;
