import { AiOutlineQrcode } from 'react-icons/ai';

import styled from 'styled-components';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'avatar':
      styles = `
        width: 200px;
        height: 200px;
      `;
      break;

    default:
      styles = ``;
      break;
  }

  return styles;
}

export const QRCode = styled(AiOutlineQrcode)`
  ${({ type }) => defineStyles(type)}
`;
