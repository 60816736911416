import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createProductDiscountSuccess,
  createProductDiscountFailure,
  updateProductDiscountSuccess,
  updateProductDiscountFailure,
  deleteProductDiscountSuccess,
  deleteProductDiscountFailure,
} from './actions';
import Types from './types';

export function* createProductDiscount({ payload }) {
  try {
    const { discount } = payload;

    const response = yield call(api.post, `products-discounts`, discount);

    yield put(createProductDiscountSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createProductDiscountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateProductDiscount({ payload }) {
  try {
    const { discount_id, newDiscount } = payload;

    const response = yield call(
      api.put,
      `products-discounts/${discount_id}`,
      newDiscount
    );

    yield put(updateProductDiscountSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateProductDiscountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteProductDiscount({ payload }) {
  try {
    const { discount_id } = payload;

    yield call(api.delete, `products-discounts/${discount_id}`);

    yield put(deleteProductDiscountSuccess(discount_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteProductDiscountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.PRODUCT_DISCOUNTS_CREATE_REQUEST, createProductDiscount),
  takeLatest(Types.PRODUCT_DISCOUNTS_UPDATE_REQUEST, updateProductDiscount),
  takeLatest(Types.PRODUCT_DISCOUNTS_DELETE_REQUEST, deleteProductDiscount),
]);
