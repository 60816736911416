const Types = {
  EMPLOYEE_ADDRESSES_GET: '@employees/GET_EMPLOYEE_ADDRESSES',

  EMPLOYEE_ADDRESSES_CREATE_REQUEST:
    '@employees/CREATE_EMPLOYEE_ADDRESS_REQUEST',
  EMPLOYEE_ADDRESSES_CREATE_SUCCESS:
    '@employees/CREATE_EMPLOYEE_ADDRESS_SUCCESS',
  EMPLOYEE_ADDRESSES_CREATE_FAILURE:
    '@employees/CREATE_EMPLOYEE_ADDRESS_FAILURE',

  EMPLOYEE_ADDRESSES_UPDATE_REQUEST:
    '@employees/UPDATE_EMPLOYEE_ADDRESS_REQUEST',
  EMPLOYEE_ADDRESSES_UPDATE_SUCCESS:
    '@employees/UPDATE_EMPLOYEE_ADDRESS_SUCCESS',
  EMPLOYEE_ADDRESSES_UPDATE_FAILURE:
    '@employees/UPDATE_EMPLOYEE_ADDRESS_FAILURE',

  EMPLOYEE_ADDRESSES_DELETE_REQUEST:
    '@employees/DELETE_EMPLOYEE_ADDRESS_REQUEST',
  EMPLOYEE_ADDRESSES_DELETE_SUCCESS:
    '@employees/DELETE_EMPLOYEE_ADDRESS_SUCCESS',
  EMPLOYEE_ADDRESSES_DELETE_FAILURE:
    '@employees/DELETE_EMPLOYEE_ADDRESS_FAILURE',

  EMPLOYEE_ADDRESSES_RESET_LIST: '@employees/RESET_LIST_EMPLOYEE_ADDRESS',

  EMPLOYEE_ADDRESSES_OPEN_MODAL: '@employees/OPEN_MODAL_EMPLOYEE_ADDRESS',
  EMPLOYEE_ADDRESSES_CLOSE_MODAL: '@employees/CLOSE_MODAL_EMPLOYEE_ADDRESS',
};

export default Types;
