import Types from './types';

export function getCulturesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.CULTURES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getCulturesSuccess({ data, filterColumnsOrder, filterSearch }) {
  return {
    type: Types.CULTURES_GET_SUCCESS,
    payload: { data, filterColumnsOrder, filterSearch },
  };
}

export function getCulturesFailure() {
  return {
    type: Types.CULTURES_GET_FAILURE,
  };
}

export function createCultureRequest(culture, source) {
  return {
    type: Types.CULTURES_CREATE_REQUEST,
    payload: { culture, source },
  };
}

export function createCultureSuccess(culture, source) {
  return {
    type: Types.CULTURES_CREATE_SUCCESS,
    payload: { culture, source },
  };
}

export function createCultureFailure() {
  return {
    type: Types.CULTURES_CREATE_FAILURE,
  };
}

export function updateCultureRequest(id, newCulture) {
  return {
    type: Types.CULTURES_UPDATE_REQUEST,
    payload: { id, newCulture },
  };
}

export function updateCultureSuccess(newCulture) {
  return {
    type: Types.CULTURES_UPDATE_SUCCESS,
    payload: { newCulture },
  };
}

export function updateCultureFailure() {
  return {
    type: Types.CULTURES_UPDATE_FAILURE,
  };
}

export function deleteCultureRequest(id) {
  return {
    type: Types.CULTURES_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteCultureSuccess(id) {
  return {
    type: Types.CULTURES_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteCultureFailure() {
  return {
    type: Types.CULTURES_DELETE_FAILURE,
  };
}

export function resetListCultures() {
  return {
    type: Types.CULTURES_RESET_LIST,
  };
}

export function openModalCulture(id) {
  return {
    type: Types.CULTURES_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalCulture() {
  return {
    type: Types.CULTURES_CLOSE_MODAL,
  };
}
