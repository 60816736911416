import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getPaymentMethodInstallments,
  resetListPaymentMethodInstallment,
  openModalPaymentMethodInstallment,
} from '../paymentMethodInstallments/actions';
import {
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  createPaymentMethodSuccess,
  createPaymentMethodFailure,
  updatePaymentMethodSuccess,
  updatePaymentMethodFailure,
  deletePaymentMethodSuccess,
  deletePaymentMethodFailure,
  getDetailsPaymentMethodSuccess,
  getDetailsPaymentMethodFailure,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getPaymentMethods({ payload }) {
  try {
    const {
      billType,
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    } = payload;

    const { data } = yield call(
      api.get,
      `payment-methods?bill_type=${billType}${queryParams}`
    );

    yield put(
      getPaymentMethodsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getPaymentMethodsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createPaymentMethod({ payload }) {
  try {
    const { paymentMethod, subResourceType } = payload;

    const response = yield call(api.post, 'payment-methods', paymentMethod);

    const billType = response.data.bill_type;

    yield put(createPaymentMethodSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(
      `/payment-method/${billType.toLowerCase()}/${response.data.id}`,
      {
        creatingResource: true,
      }
    );
  } catch (err) {
    yield put(createPaymentMethodFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePaymentMethod({ payload }) {
  try {
    const { id, newPaymentMethod, subResourceType } = payload;

    const response = yield call(
      api.put,
      `payment-methods/${id}`,
      newPaymentMethod
    );

    yield put(updatePaymentMethodSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);

    if (subResourceType === 'INSTALLMENT') {
      yield put(
        openModalPaymentMethodInstallment({
          newRegister: true,
          editInstallment: null,
        })
      );
    }
  } catch (err) {
    yield put(updatePaymentMethodFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePaymentMethod({ payload }) {
  try {
    const { id, billType } = payload;

    yield call(api.delete, `payment-methods/${id}`);

    yield put(deletePaymentMethodSuccess(id));

    history.push(`/payment-methods/${billType}`);

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePaymentMethodFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsPaymentMethod({ payload }) {
  try {
    const { id, billType } = payload;

    const response = yield call(
      api.get,
      `payment-methods/${id}?bill_type=${billType}`
    );

    const { payment_methods_installments, ...rest } = response.data;

    yield put(getPaymentMethodInstallments(payment_methods_installments));

    yield put(getDetailsPaymentMethodSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.paymentMethods
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'INSTALLMENT') {
        yield put(
          openModalPaymentMethodInstallment({
            newRegister: true,
            editInstallment: null,
          })
        );
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsPaymentMethodFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsPaymentMethod() {
  yield put(resetListPaymentMethodInstallment());
}

export default all([
  takeLatest(Types.PAYMENT_METHODS_GET_REQUEST, getPaymentMethods),
  takeLatest(Types.PAYMENT_METHODS_CREATE_REQUEST, createPaymentMethod),
  takeLatest(Types.PAYMENT_METHODS_UPDATE_REQUEST, updatePaymentMethod),
  takeLatest(Types.PAYMENT_METHODS_DELETE_REQUEST, deletePaymentMethod),
  takeLatest(
    Types.PAYMENT_METHODS_GET_DETAILS_REQUEST,
    getDetailsPaymentMethod
  ),
  takeLatest(Types.PAYMENT_METHODS_RESET_DETAILS, resetDetailsPaymentMethod),
]);
