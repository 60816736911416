const Types = {
  EMPLOYEE_PHONES_GET: '@employees/GET_EMPLOYEE_PHONES',

  EMPLOYEE_PHONES_CREATE_REQUEST: '@employees/CREATE_EMPLOYEE_PHONE_REQUEST',
  EMPLOYEE_PHONES_CREATE_SUCCESS: '@employees/CREATE_EMPLOYEE_PHONE_SUCCESS',
  EMPLOYEE_PHONES_CREATE_FAILURE: '@employees/CREATE_EMPLOYEE_PHONE_FAILURE',

  EMPLOYEE_PHONES_UPDATE_REQUEST: '@employees/UPDATE_EMPLOYEE_PHONE_REQUEST',
  EMPLOYEE_PHONES_UPDATE_SUCCESS: '@employees/UPDATE_EMPLOYEE_PHONE_SUCCESS',
  EMPLOYEE_PHONES_UPDATE_FAILURE: '@employees/UPDATE_EMPLOYEE_PHONE_FAILURE',

  EMPLOYEE_PHONES_DELETE_REQUEST: '@employees/DELETE_EMPLOYEE_PHONE_REQUEST',
  EMPLOYEE_PHONES_DELETE_SUCCESS: '@employees/DELETE_EMPLOYEE_PHONE_SUCCESS',
  EMPLOYEE_PHONES_DELETE_FAILURE: '@employees/DELETE_EMPLOYEE_PHONE_FAILURE',

  EMPLOYEE_PHONES_RESET_LIST: '@employees/RESET_LIST_EMPLOYEE_PHONE',

  EMPLOYEE_PHONES_OPEN_MODAL: '@employees/OPEN_MODAL_EMPLOYEE_PHONE',
  EMPLOYEE_PHONES_CLOSE_MODAL: '@employees/CLOSE_MODAL_EMPLOYEE_PHONE',
};

export default Types;
