import Types from './types';

export function getAccountsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.ACCOUNTS_GET_REQUEST,
    payload: { queryParams, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getAccountsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.ACCOUNTS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getAccountsFailure() {
  return {
    type: Types.ACCOUNTS_GET_FAILURE,
  };
}

export function createAccountRequest(account, subResourceType = null) {
  return {
    type: Types.ACCOUNTS_CREATE_REQUEST,
    payload: { account, subResourceType },
  };
}

export function createAccountSuccess(account) {
  return {
    type: Types.ACCOUNTS_CREATE_SUCCESS,
    payload: { account },
  };
}

export function createAccountFailure() {
  return {
    type: Types.ACCOUNTS_CREATE_FAILURE,
  };
}

export function updateAccountRequest(id, newAccount, subResourceType = null) {
  return {
    type: Types.ACCOUNTS_UPDATE_REQUEST,
    payload: { id, newAccount, subResourceType },
  };
}

export function updateAccountSuccess(newAccount) {
  return {
    type: Types.ACCOUNTS_UPDATE_SUCCESS,
    payload: { newAccount },
  };
}

export function updateAccountFailure() {
  return {
    type: Types.ACCOUNTS_UPDATE_FAILURE,
  };
}

export function deleteAccountRequest(id) {
  return {
    type: Types.ACCOUNTS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteAccountSuccess(id) {
  return {
    type: Types.ACCOUNTS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteAccountFailure() {
  return {
    type: Types.ACCOUNTS_DELETE_FAILURE,
  };
}

export function getDetailsAccountRequest(id) {
  return {
    type: Types.ACCOUNTS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsAccountSuccess(account) {
  return {
    type: Types.ACCOUNTS_GET_DETAILS_SUCCESS,
    payload: { account },
  };
}

export function getDetailsAccountFailure() {
  return {
    type: Types.ACCOUNTS_GET_DETAILS_FAILURE,
  };
}

export function resetListAccounts() {
  return {
    type: Types.ACCOUNTS_RESET_LIST,
  };
}

export function resetDetailsAccount() {
  return {
    type: Types.ACCOUNTS_RESET_DETAILS,
  };
}

export function openModalAccountFilter() {
  return {
    type: Types.ACCOUNTS_FILTER_OPEN_MODAL,
  };
}

export function closeModalAccountFilter() {
  return {
    type: Types.ACCOUNTS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.ACCOUNTS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.ACCOUNTS_RESET_SUB_RESOURCE_DATA,
  };
}
