import React from 'react';

import styled from 'styled-components';

import { darken } from 'polished';

import { Chip as MuiChip } from '@material-ui/core';
import { spacing } from '@material-ui/system';

import colors from '~/theme/colors';

export const Chip = styled(
  ({
    chipColor: _chipColor,
    label: _label,
    clickable: _clickable,
    ...other
  }) => <MuiChip label={_label} clickable={_clickable} {...other} />
)`
  ${spacing}
  border-radius: 4px;
  color: ${colors.white};
  background: ${({ chipColor }) =>
    colors[chipColor] ? colors[chipColor] : colors.regular};

  &:hover {
    background: ${({ chipColor, clickable }) =>
      clickable && colors[chipColor] && darken(0.1, colors[chipColor])};
  }
`;
