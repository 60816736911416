import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  unitMeasureFilterModalOpen: false,
  unitMeasureModalOpen: false,
};

export default function unitsMeasure(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.UNITS_MEASURE_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.UNITS_MEASURE_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.unitMeasureFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        break;
      }
      case Types.UNITS_MEASURE_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.UNITS_MEASURE_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.UNITS_MEASURE_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.unitMeasure);
        draft.loading = false;
        draft.unitMeasureModalOpen = false;
        break;
      }
      case Types.UNITS_MEASURE_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.UNITS_MEASURE_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.UNITS_MEASURE_UPDATE_SUCCESS: {
        const { newUnitMeasure } = action.payload;

        draft.list = state.list.map(unitMeasure => {
          return unitMeasure.id === newUnitMeasure.id
            ? newUnitMeasure
            : unitMeasure;
        });

        draft.detail = {};
        draft.loading = false;
        draft.unitMeasureModalOpen = false;
        break;
      }
      case Types.UNITS_MEASURE_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.UNITS_MEASURE_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.UNITS_MEASURE_DELETE_SUCCESS: {
        draft.list = state.list.filter(unitMeasure => {
          return unitMeasure.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.UNITS_MEASURE_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.UNITS_MEASURE_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;

        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.unitMeasureFilterModalOpen = false;
        break;
      }

      case Types.UNITS_MEASURE_FILTER_OPEN_MODAL: {
        draft.unitMeasureFilterModalOpen = true;
        break;
      }
      case Types.UNITS_MEASURE_FILTER_CLOSE_MODAL: {
        draft.unitMeasureFilterModalOpen = false;
        break;
      }

      case Types.UNITS_MEASURE_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = state.list.filter(unitMeasure => {
            return unitMeasure.id === id;
          });
        }

        draft.unitMeasureModalOpen = true;
        break;
      }
      case Types.UNITS_MEASURE_CLOSE_MODAL: {
        draft.unitMeasureModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
