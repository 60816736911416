import React from 'react';

import PropTypes from 'prop-types';

import { Plus } from './styles';

export default function PlusIcon({ type }) {
  return <Plus type={type} />;
}

PlusIcon.propTypes = {
  type: PropTypes.string,
};

PlusIcon.defaultProps = {
  type: '',
};
