import React from 'react';

import PropTypes from 'prop-types';

import { WhatsApp } from './styles';

export default function WhatsAppIcon({ type, active }) {
  return <WhatsApp type={type} active={active} />;
}

WhatsAppIcon.propTypes = {
  type: PropTypes.string,
  active: PropTypes.bool,
};

WhatsAppIcon.defaultProps = {
  type: '',
  active: false,
};
