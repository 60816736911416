const Types = {
  SUPPLIER_ADDRESSES_GET: '@suppliers/GET_SUPPLIER_ADDRESSES',

  SUPPLIER_ADDRESSES_CREATE_REQUEST:
    '@suppliers/CREATE_SUPPLIER_ADDRESS_REQUEST',
  SUPPLIER_ADDRESSES_CREATE_SUCCESS:
    '@suppliers/CREATE_SUPPLIER_ADDRESS_SUCCESS',
  SUPPLIER_ADDRESSES_CREATE_FAILURE:
    '@suppliers/CREATE_SUPPLIER_ADDRESS_FAILURE',

  SUPPLIER_ADDRESSES_UPDATE_REQUEST:
    '@suppliers/UPDATE_SUPPLIER_ADDRESS_REQUEST',
  SUPPLIER_ADDRESSES_UPDATE_SUCCESS:
    '@suppliers/UPDATE_SUPPLIER_ADDRESS_SUCCESS',
  SUPPLIER_ADDRESSES_UPDATE_FAILURE:
    '@suppliers/UPDATE_SUPPLIER_ADDRESS_FAILURE',

  SUPPLIER_ADDRESSES_DELETE_REQUEST:
    '@suppliers/DELETE_SUPPLIER_ADDRESS_REQUEST',
  SUPPLIER_ADDRESSES_DELETE_SUCCESS:
    '@suppliers/DELETE_SUPPLIER_ADDRESS_SUCCESS',
  SUPPLIER_ADDRESSES_DELETE_FAILURE:
    '@suppliers/DELETE_SUPPLIER_ADDRESS_FAILURE',

  SUPPLIER_ADDRESSES_RESET_LIST: '@suppliers/RESET_LIST_SUPPLIER_ADDRESS',

  SUPPLIER_ADDRESSES_OPEN_MODAL: '@suppliers/OPEN_MODAL_SUPPLIER_ADDRESS',
  SUPPLIER_ADDRESSES_CLOSE_MODAL: '@suppliers/CLOSE_MODAL_SUPPLIER_ADDRESS',
};

export default Types;
