import _ from 'lodash';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default reducers => {
  const formName = 'auth';

  const formTransform = createTransform(
    inboundState => {
      return {
        ...inboundState,
        loading: _.get(inboundState, `${formName}.loading`),
        signUpModalOpen: _.get(inboundState, `${formName}.signUpModalOpen`),
      };
    },
    outboundState => {
      return outboundState;
    },
    { whitelist: [`auth`] }
  );

  const persistedReducer = persistReducer(
    {
      key: 'smartbusiness',
      storage,
      whitelist: ['auth'],
      transforms: [formTransform],
    },
    reducers
  );

  return persistedReducer;
};
