import React from 'react';

import PropTypes from 'prop-types';

import { AddBox } from './styles';

export default function AddBoxIcon({ type, disabled }) {
  return <AddBox type={type} disabled={disabled} />;
}

AddBoxIcon.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

AddBoxIcon.defaultProps = {
  type: '',
  disabled: false,
};
