const Types = {
  HARVESTS_GET_REQUEST: '@harvests/GET_HARVESTS_REQUEST',
  HARVESTS_GET_SUCCESS: '@harvests/GET_HARVESTS_SUCCESS',
  HARVESTS_GET_FAILURE: '@harvests/GET_HARVESTS_FAILURE',

  HARVESTS_CREATE_REQUEST: '@harvests/CREATE_HARVEST_REQUEST',
  HARVESTS_CREATE_SUCCESS: '@harvests/CREATE_HARVEST_SUCCESS',
  HARVESTS_CREATE_FAILURE: '@harvests/CREATE_HARVEST_FAILURE',

  HARVESTS_UPDATE_REQUEST: '@harvests/UPDATE_HARVEST_REQUEST',
  HARVESTS_UPDATE_SUCCESS: '@harvests/UPDATE_HARVEST_SUCCESS',
  HARVESTS_UPDATE_FAILURE: '@harvests/UPDATE_HARVEST_FAILURE',

  HARVESTS_DELETE_REQUEST: '@harvests/DELETE_HARVEST_REQUEST',
  HARVESTS_DELETE_SUCCESS: '@harvests/DELETE_HARVEST_SUCCESS',
  HARVESTS_DELETE_FAILURE: '@harvests/DELETE_HARVEST_FAILURE',

  HARVESTS_RESET_LIST: '@harvests/RESET_LIST_HARVESTS',

  HARVESTS_OPEN_MODAL: '@harvests/OPEN_MODAL_HARVEST',
  HARVESTS_CLOSE_MODAL: '@harvests/CLOSE_MODAL_HARVEST',

  HARVESTS_CLEAR_NEW_CULTURE: '@harvests/CLEAR_NEW_HARVEST_CULTURE',
  HARVESTS_CLEAR_NEW_UNIT_MEASURE: '@harvests/CLEAR_NEW_HARVEST_UNIT_MEASURE',
};

export default Types;
