import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  openModalInventoryItemSearch,
  resetListInventoryItem,
} from '../inventoryItems/actions';
import {
  getInventoriesSuccess,
  getInventoriesFailure,
  createInventorySuccess,
  createInventoryFailure,
  updateInventorySuccess,
  updateInventoryFailure,
  deleteInventorySuccess,
  deleteInventoryFailure,
  getDetailsInventorySuccess,
  getDetailsInventoryFailure,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getInventories({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `inventories${queryParams}`);

    yield put(
      getInventoriesSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getInventoriesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createInventory({ payload }) {
  try {
    const { inventory, subResourceType } = payload;

    const response = yield call(api.post, 'inventories', inventory);

    yield put(createInventorySuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/inventory/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createInventoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateInventory({ payload }) {
  try {
    const { id, newInventory } = payload;

    const { data } = yield call(api.put, `inventories/${id}`, newInventory);

    yield put(updateInventorySuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateInventoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteInventory({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `inventories/${id}`);

    yield put(deleteInventorySuccess(id));

    history.push('/inventories');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteInventoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsInventory({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `inventories/${id}`);

    yield put(getDetailsInventorySuccess(data));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.inventories
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'ITEM') {
        yield put(openModalInventoryItemSearch());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsInventoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsInventory() {
  yield put(resetListInventoryItem());
}

export default all([
  takeLatest(Types.INVENTORIES_GET_REQUEST, getInventories),
  takeLatest(Types.INVENTORIES_CREATE_REQUEST, createInventory),
  takeLatest(Types.INVENTORIES_UPDATE_REQUEST, updateInventory),
  takeLatest(Types.INVENTORIES_DELETE_REQUEST, deleteInventory),
  takeLatest(Types.INVENTORIES_GET_DETAILS_REQUEST, getDetailsInventory),
  takeLatest(Types.INVENTORIES_RESET_DETAILS, resetDetailsInventory),
]);
