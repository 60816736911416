import Types from './types';

export function getCovenantsRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.COVENANTS_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getCovenantsSuccess({
  data,
  queryParams,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.COVENANTS_GET_SUCCESS,
    payload: { data, queryParams, filterColumnsOrder, filterSearch },
  };
}

export function getCovenantsFailure() {
  return {
    type: Types.COVENANTS_GET_FAILURE,
  };
}

export function createCovenantRequest(covenant, source) {
  return {
    type: Types.COVENANTS_CREATE_REQUEST,
    payload: { covenant, source },
  };
}

export function createCovenantSuccess(covenant, source) {
  return {
    type: Types.COVENANTS_CREATE_SUCCESS,
    payload: { covenant, source },
  };
}

export function createCovenantFailure() {
  return {
    type: Types.COVENANTS_CREATE_FAILURE,
  };
}

export function updateCovenantRequest(id, newCovenant) {
  return {
    type: Types.COVENANTS_UPDATE_REQUEST,
    payload: { id, newCovenant },
  };
}

export function updateCovenantSuccess(newCovenant) {
  return {
    type: Types.COVENANTS_UPDATE_SUCCESS,
    payload: { newCovenant },
  };
}

export function updateCovenantFailure() {
  return {
    type: Types.COVENANTS_UPDATE_FAILURE,
  };
}

export function deleteCovenantRequest(id) {
  return {
    type: Types.COVENANTS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteCovenantSuccess(id) {
  return {
    type: Types.COVENANTS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteCovenantFailure() {
  return {
    type: Types.COVENANTS_DELETE_FAILURE,
  };
}

export function resetListCovenants() {
  return {
    type: Types.COVENANTS_RESET_LIST,
  };
}

export function openModalCovenant(id) {
  return {
    type: Types.COVENANTS_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalCovenant() {
  return {
    type: Types.COVENANTS_CLOSE_MODAL,
  };
}
