import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
};

export default function regionCities(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.REGION_CITIES_GET: {
        const { cities } = action.payload;

        draft.list = cities;
        break;
      }

      case Types.REGION_CITIES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGION_CITIES_UPDATE_SUCCESS: {
        const { cities } = action.payload;

        draft.list = cities;

        draft.loading = false;

        break;
      }
      case Types.REGION_CITIES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGION_CITIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGION_CITIES_DELETE_SUCCESS: {
        const { region_city_id } = action.payload;

        draft.list = state.list.filter(item => {
          return item.id !== region_city_id;
        });

        draft.loading = false;
        break;
      }
      case Types.REGION_CITIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGION_CITIES_RESET_LIST: {
        draft.list = [];
        break;
      }

      default:
    }
  });
}
