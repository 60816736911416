import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getContactAddresses,
  resetListContactAddress,
} from '../contactAddresses/actions';
import {
  getContactEmails,
  resetListContactEmail,
} from '../contactEmails/actions';
import { getContactFiles, resetListContactFile } from '../contactFiles/actions';
import {
  getContactPhones,
  resetListContactPhone,
} from '../contactPhones/actions';
import {
  getContactsSuccess,
  getContactsFailure,
  getDetailsContactSuccess,
  getDetailsContactFailure,
  resetContactList,
} from './actions';
import Types from './types';

export function* getContacts() {
  try {
    const response = yield call(api.get, 'persons?order=name&direction=ASC');

    yield put(getContactsSuccess(response.data));
  } catch (err) {
    yield put(getContactsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsContact({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `persons/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getContactPhones(phones));

    yield put(getContactAddresses(addresses));

    yield put(getContactEmails(emails));

    yield put(getContactFiles(files));

    yield put(getDetailsContactSuccess(rest));
  } catch (err) {
    yield put(getDetailsContactFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsContact() {
  yield put(resetListContactPhone());

  yield put(resetListContactAddress());

  yield put(resetListContactEmail());

  yield put(resetListContactFile());

  yield put(resetContactList());
}

export default all([
  takeLatest(Types.CONTACTS_GET_REQUEST, getContacts),
  takeLatest(Types.CONTACTS_GET_DETAILS_REQUEST, getDetailsContact),
  takeLatest(Types.CONTACTS_RESET_DETAILS, resetDetailsContact),
]);
