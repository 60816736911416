import Types from './types';

export function getDispatchingAgenciesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.DISPATCHING_AGENCIES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getDispatchingAgenciesSuccess({
  data,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.DISPATCHING_AGENCIES_GET_SUCCESS,
    payload: { data, filterColumnsOrder, filterSearch },
  };
}

export function getDispatchingAgenciesFailure() {
  return {
    type: Types.DISPATCHING_AGENCIES_GET_FAILURE,
  };
}

export function resetListDispatchingAgencies() {
  return {
    type: Types.DISPATCHING_AGENCIES_RESET_LIST,
  };
}
