const Types = {
  SELLER_EMAILS_GET: '@sellers/GET_SELLER_EMAILS',

  SELLER_EMAILS_CREATE_REQUEST: '@sellers/CREATE_SELLER_EMAIL_REQUEST',
  SELLER_EMAILS_CREATE_SUCCESS: '@sellers/CREATE_SELLER_EMAIL_SUCCESS',
  SELLER_EMAILS_CREATE_FAILURE: '@sellers/CREATE_SELLER_EMAIL_FAILURE',

  SELLER_EMAILS_UPDATE_REQUEST: '@sellers/UPDATE_SELLER_EMAIL_REQUEST',
  SELLER_EMAILS_UPDATE_SUCCESS: '@sellers/UPDATE_SELLER_EMAIL_SUCCESS',
  SELLER_EMAILS_UPDATE_FAILURE: '@sellers/UPDATE_SELLER_EMAIL_FAILURE',

  SELLER_EMAILS_DELETE_REQUEST: '@sellers/DELETE_SELLER_EMAIL_REQUEST',
  SELLER_EMAILS_DELETE_SUCCESS: '@sellers/DELETE_SELLER_EMAIL_SUCCESS',
  SELLER_EMAILS_DELETE_FAILURE: '@sellers/DELETE_SELLER_EMAIL_FAILURE',

  SELLER_EMAILS_RESET_LIST: '@sellers/RESET_LIST_SELLER_EMAIL',

  SELLER_EMAILS_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_EMAIL',
  SELLER_EMAILS_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_EMAIL',
};

export default Types;
