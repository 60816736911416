import Types from './types';

export function getBillToPayChartAccounts(chartAccounts) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_GET,
    payload: { chartAccounts },
  };
}

export function createBillToPayChartAccountRequest(
  bill_to_pay_id,
  chartAccount
) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_CREATE_REQUEST,
    payload: { bill_to_pay_id, chartAccount },
  };
}

export function createBillToPayChartAccountSuccess(chartAccount) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_CREATE_SUCCESS,
    payload: { chartAccount },
  };
}

export function createBillToPayChartAccountFailure() {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_CREATE_FAILURE,
  };
}

export function updateBillToPayChartAccountRequest(
  bill_to_pay_id,
  chart_account_id,
  newChartAccount
) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_REQUEST,
    payload: { bill_to_pay_id, chart_account_id, newChartAccount },
  };
}

export function updateBillToPayChartAccountSuccess(newChartAccount) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_SUCCESS,
    payload: { newChartAccount },
  };
}

export function updateBillToPayChartAccountFailure() {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_FAILURE,
  };
}

export function deleteBillToPayChartAccountRequest(
  bill_to_pay_id,
  chart_account_id
) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_DELETE_REQUEST,
    payload: { bill_to_pay_id, chart_account_id },
  };
}

export function deleteBillToPayChartAccountSuccess(chart_account_id) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_DELETE_SUCCESS,
    payload: { chart_account_id },
  };
}

export function deleteBillToPayChartAccountFailure() {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_DELETE_FAILURE,
  };
}

export function resetListBillToPayChartAccount() {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_RESET_LIST,
  };
}

export function openModalBillToPayChartAccount(chart_account_id) {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_OPEN_MODAL,
    payload: { chart_account_id },
  };
}

export function closeModalBillToPayChartAccount() {
  return {
    type: Types.BILL_TO_PAY_CHART_ACCOUNTS_CLOSE_MODAL,
  };
}
