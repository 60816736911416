import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCompanyNcmsSuccess,
  getCompanyNcmsFailure,
  createCompanyNcmSuccess,
  createCompanyNcmFailure,
  deleteCompanyNcmSuccess,
  deleteCompanyNcmFailure,
} from './actions';
import Types from './types';

export function* getCompanyNcms({ payload }) {
  try {
    const {
      companyId,
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    } = payload;

    const { data } = yield call(
      api.get,
      `companies/${companyId}/ncms${queryParams}`
    );

    yield put(
      getCompanyNcmsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getCompanyNcmsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCompanyNcm({ payload }) {
  try {
    const { company_id, companyNcm } = payload;

    const response = yield call(
      api.post,
      `companies/${company_id}/ncms`,
      companyNcm
    );

    yield put(createCompanyNcmSuccess(response.data));

    toast.success(<IntlMessages id="messages.change-success" />);
  } catch (err) {
    yield put(createCompanyNcmFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.change-failure" />);
  }
}

export function* deleteCompanyNcm({ payload }) {
  try {
    const { company_id, id } = payload;

    yield call(api.delete, `companies/${company_id}/ncms/${id}`);

    yield put(deleteCompanyNcmSuccess(id));

    toast.success(<IntlMessages id="messages.change-success" />);
  } catch (err) {
    yield put(deleteCompanyNcmFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.change-failure" />);
  }
}

export default all([
  takeLatest(Types.COMPANY_NCMS_GET_REQUEST, getCompanyNcms),
  takeLatest(Types.COMPANY_NCMS_CREATE_REQUEST, createCompanyNcm),
  takeLatest(Types.COMPANY_NCMS_DELETE_REQUEST, deleteCompanyNcm),
]);
