import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  ruralPropertyAddModalOpen: false,
  ruralPropertyFilterModalOpen: false,
};

export default function ruralProperties(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.RURAL_PROPERTIES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.RURAL_PROPERTIES_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.ruralPropertyFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.RURAL_PROPERTIES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTIES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.ruralProperty);
        draft.loading = false;
        draft.ruralPropertyAddModalOpen = false;
        break;
      }
      case Types.RURAL_PROPERTIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTIES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTIES_UPDATE_SUCCESS: {
        const { newRuralProperty } = action.payload;
        draft.detail = newRuralProperty;

        draft.list = state.list.map(ruralProperty => {
          return ruralProperty.id === newRuralProperty.id
            ? newRuralProperty
            : ruralProperty;
        });

        draft.loading = false;
        break;
      }
      case Types.RURAL_PROPERTIES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTIES_DELETE_SUCCESS: {
        draft.list = state.list.filter(ruralProperty => {
          return ruralProperty.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.RURAL_PROPERTIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTIES_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.RURAL_PROPERTIES_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.ruralProperty;
        draft.requestError = false;
        break;
      }
      case Types.RURAL_PROPERTIES_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.RURAL_PROPERTIES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;

        draft.ruralPropertyFilterModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }

      case Types.RURAL_PROPERTIES_RESET_DETAILS: {
        draft.detail = {};
        break;
      }

      case Types.RURAL_PROPERTIES_ADD_OPEN_MODAL: {
        draft.ruralPropertyAddModalOpen = true;
        break;
      }
      case Types.RURAL_PROPERTIES_ADD_CLOSE_MODAL: {
        draft.ruralPropertyAddModalOpen = false;
        break;
      }

      case Types.RURAL_PROPERTIES_FILTER_OPEN_MODAL: {
        draft.ruralPropertyFilterModalOpen = true;
        break;
      }
      case Types.RURAL_PROPERTIES_FILTER_CLOSE_MODAL: {
        draft.ruralPropertyFilterModalOpen = false;
        break;
      }

      default:
    }
  });
}
