import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createEmployeeFileSuccess,
  createEmployeeFileFailure,
  updateEmployeeFileSuccess,
  updateEmployeeFileFailure,
  deleteEmployeeFileSuccess,
  deleteEmployeeFileFailure,
} from './actions';
import Types from './types';

export function* createEmployeeFile({ payload }) {
  try {
    const { employee_id, file } = payload;

    const response = yield call(
      api.post,
      `employees/${employee_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createEmployeeFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createEmployeeFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateEmployeeFile({ payload }) {
  try {
    const { employee_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `employees/${employee_id}/files/${file_id}`,
      newFile
    );

    yield put(updateEmployeeFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateEmployeeFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteEmployeeFile({ payload }) {
  try {
    const { employee_id, file_id } = payload;

    yield call(api.delete, `employees/${employee_id}/files/${file_id}`);

    yield put(deleteEmployeeFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteEmployeeFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.EMPLOYEE_FILES_CREATE_REQUEST, createEmployeeFile),
  takeLatest(Types.EMPLOYEE_FILES_UPDATE_REQUEST, updateEmployeeFile),
  takeLatest(Types.EMPLOYEE_FILES_DELETE_REQUEST, deleteEmployeeFile),
]);
