const Types = {
  ALL_BACKUP_DATABASES_GET_REQUEST:
    '@allBackupDatabases/GET_ALL_BACKUP_DATABASES_REQUEST',
  ALL_BACKUP_DATABASES_GET_SUCCESS:
    '@allBackupDatabases/GET_ALL_BACKUP_DATABASES_SUCCESS',
  ALL_BACKUP_DATABASES_GET_FAILURE:
    '@allBackupDatabases/GET_ALL_BACKUP_DATABASES_FAILURE',

  ALL_BACKUP_DATABASES_RESET_LIST:
    '@allBackupDatabases/RESET_LIST_ALL_BACKUP_DATABASES',
};

export default Types;
