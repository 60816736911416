import styled from 'styled-components';

import { AccountBox as MuiAccountBox } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'form':
      styles = `
        width: 24px;
        height: 24px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const AccountBox = styled(MuiAccountBox)`
  ${({ type }) => defineStyles(type)}
`;
