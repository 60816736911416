import Types from './types';

export function getRuralPropertiesRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.RURAL_PROPERTIES_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getRuralPropertiesSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.RURAL_PROPERTIES_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getRuralPropertiesFailure() {
  return {
    type: Types.RURAL_PROPERTIES_GET_FAILURE,
  };
}

export function createRuralPropertyRequest(ruralProperty) {
  return {
    type: Types.RURAL_PROPERTIES_CREATE_REQUEST,
    payload: { ruralProperty },
  };
}

export function createRuralPropertySuccess(ruralProperty) {
  return {
    type: Types.RURAL_PROPERTIES_CREATE_SUCCESS,
    payload: { ruralProperty },
  };
}

export function createRuralPropertyFailure() {
  return {
    type: Types.RURAL_PROPERTIES_CREATE_FAILURE,
  };
}

export function updateRuralPropertyRequest(id, newRuralProperty) {
  return {
    type: Types.RURAL_PROPERTIES_UPDATE_REQUEST,
    payload: { id, newRuralProperty },
  };
}

export function updateRuralPropertySuccess(newRuralProperty) {
  return {
    type: Types.RURAL_PROPERTIES_UPDATE_SUCCESS,
    payload: { newRuralProperty },
  };
}

export function updateRuralPropertyFailure() {
  return {
    type: Types.RURAL_PROPERTIES_UPDATE_FAILURE,
  };
}

export function deleteRuralPropertyRequest(id) {
  return {
    type: Types.RURAL_PROPERTIES_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteRuralPropertySuccess(id) {
  return {
    type: Types.RURAL_PROPERTIES_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteRuralPropertyFailure() {
  return {
    type: Types.RURAL_PROPERTIES_DELETE_FAILURE,
  };
}

export function getDetailsRuralPropertyRequest(id) {
  return {
    type: Types.RURAL_PROPERTIES_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsRuralPropertySuccess(ruralProperty) {
  return {
    type: Types.RURAL_PROPERTIES_GET_DETAILS_SUCCESS,
    payload: { ruralProperty },
  };
}

export function getDetailsRuralPropertyFailure() {
  return {
    type: Types.RURAL_PROPERTIES_GET_DETAILS_FAILURE,
  };
}

export function resetListRuralProperties() {
  return {
    type: Types.RURAL_PROPERTIES_RESET_LIST,
  };
}

export function resetDetailsRuralProperty() {
  return {
    type: Types.RURAL_PROPERTIES_RESET_DETAILS,
  };
}

export function openModalAddRuralProperty() {
  return {
    type: Types.RURAL_PROPERTIES_ADD_OPEN_MODAL,
  };
}

export function closeModalAddRuralProperty() {
  return {
    type: Types.RURAL_PROPERTIES_ADD_CLOSE_MODAL,
  };
}

export function openModalRuralPropertyFilter() {
  return {
    type: Types.RURAL_PROPERTIES_FILTER_OPEN_MODAL,
  };
}

export function closeModalRuralPropertyFilter() {
  return {
    type: Types.RURAL_PROPERTIES_FILTER_CLOSE_MODAL,
  };
}
