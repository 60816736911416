import Types from './types';

export function getCompanyUsers(users) {
  return {
    type: Types.COMPANY_USERS_GET,
    payload: { users },
  };
}

export function createCompanyUserRequest(company_id, user) {
  return {
    type: Types.COMPANY_USERS_CREATE_REQUEST,
    payload: { company_id, user },
  };
}

export function createCompanyUserSuccess(user) {
  return {
    type: Types.COMPANY_USERS_CREATE_SUCCESS,
    payload: { user },
  };
}

export function createCompanyUserFailure() {
  return {
    type: Types.COMPANY_USERS_CREATE_FAILURE,
  };
}

export function updateCompanyUserRequest(company_id, email) {
  return {
    type: Types.COMPANY_USERS_UPDATE_REQUEST,
    payload: { company_id, email },
  };
}

export function updateCompanyUserSuccess(newInvite) {
  return {
    type: Types.COMPANY_USERS_UPDATE_SUCCESS,
    payload: { newInvite },
  };
}

export function updateCompanyUserFailure() {
  return {
    type: Types.COMPANY_USERS_UPDATE_FAILURE,
  };
}

export function deleteCompanyUserRequest(company_id, user_id) {
  return {
    type: Types.COMPANY_USERS_DELETE_REQUEST,
    payload: { company_id, user_id },
  };
}

export function deleteCompanyUserSuccess(user_id) {
  return {
    type: Types.COMPANY_USERS_DELETE_SUCCESS,
    payload: { user_id },
  };
}

export function deleteCompanyUserFailure() {
  return {
    type: Types.COMPANY_USERS_DELETE_FAILURE,
  };
}

export function resetListCompanyUser() {
  return {
    type: Types.COMPANY_USERS_RESET_LIST,
  };
}

export function openModalCompanyUser(user_id) {
  return {
    type: Types.COMPANY_USERS_OPEN_MODAL,
    payload: { user_id },
  };
}

export function closeModalCompanyUser() {
  return {
    type: Types.COMPANY_USERS_CLOSE_MODAL,
  };
}

export function openModalCompanyInvite() {
  return {
    type: Types.COMPANY_USERS_INVITE_OPEN_MODAL,
  };
}

export function closeModalCompanyInvite() {
  return {
    type: Types.COMPANY_USERS_INVITE_CLOSE_MODAL,
  };
}
