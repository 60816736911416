import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Link, LinkText, LinkBadge } from './styles';

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

export default function SidebarLink({ name, to, badge, isNested }) {
  const checkIsNested = isNested ? 'true' : 'false';
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      nested={checkIsNested}
    >
      <LinkText nested={checkIsNested}>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  );
}

SidebarLink.propTypes = {
  name: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
  badge: PropTypes.string,
  isNested: PropTypes.bool,
};

SidebarLink.defaultProps = {
  badge: null,
  isNested: null,
};
