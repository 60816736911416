const Types = {
  TRANSPORTER_EMAILS_GET: '@transporters/GET_TRANSPORTER_EMAILS',

  TRANSPORTER_EMAILS_CREATE_REQUEST:
    '@transporters/CREATE_TRANSPORTER_EMAIL_REQUEST',
  TRANSPORTER_EMAILS_CREATE_SUCCESS:
    '@transporters/CREATE_TRANSPORTER_EMAIL_SUCCESS',
  TRANSPORTER_EMAILS_CREATE_FAILURE:
    '@transporters/CREATE_TRANSPORTER_EMAIL_FAILURE',

  TRANSPORTER_EMAILS_UPDATE_REQUEST:
    '@transporters/UPDATE_TRANSPORTER_EMAIL_REQUEST',
  TRANSPORTER_EMAILS_UPDATE_SUCCESS:
    '@transporters/UPDATE_TRANSPORTER_EMAIL_SUCCESS',
  TRANSPORTER_EMAILS_UPDATE_FAILURE:
    '@transporters/UPDATE_TRANSPORTER_EMAIL_FAILURE',

  TRANSPORTER_EMAILS_DELETE_REQUEST:
    '@transporters/DELETE_TRANSPORTER_EMAIL_REQUEST',
  TRANSPORTER_EMAILS_DELETE_SUCCESS:
    '@transporters/DELETE_TRANSPORTER_EMAIL_SUCCESS',
  TRANSPORTER_EMAILS_DELETE_FAILURE:
    '@transporters/DELETE_TRANSPORTER_EMAIL_FAILURE',

  TRANSPORTER_EMAILS_RESET_LIST: '@transporters/RESET_LIST_TRANSPORTER_EMAIL',

  TRANSPORTER_EMAILS_OPEN_MODAL: '@transporters/OPEN_MODAL_TRANSPORTER_EMAIL',
  TRANSPORTER_EMAILS_CLOSE_MODAL: '@transporters/CLOSE_MODAL_TRANSPORTER_EMAIL',
};

export default Types;
