import React from 'react';

import PropTypes from 'prop-types';

import { Business } from './styles';

export default function BusinessIcon({ type }) {
  return <Business type={type} />;
}

BusinessIcon.propTypes = {
  type: PropTypes.string,
};

BusinessIcon.defaultProps = {
  type: '',
};
