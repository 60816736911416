const Types = {
  EMPLOYEE_EMAILS_GET: '@employees/GET_EMPLOYEE_EMAILS',

  EMPLOYEE_EMAILS_CREATE_REQUEST: '@employees/CREATE_EMPLOYEE_EMAIL_REQUEST',
  EMPLOYEE_EMAILS_CREATE_SUCCESS: '@employees/CREATE_EMPLOYEE_EMAIL_SUCCESS',
  EMPLOYEE_EMAILS_CREATE_FAILURE: '@employees/CREATE_EMPLOYEE_EMAIL_FAILURE',

  EMPLOYEE_EMAILS_UPDATE_REQUEST: '@employees/UPDATE_EMPLOYEE_EMAIL_REQUEST',
  EMPLOYEE_EMAILS_UPDATE_SUCCESS: '@employees/UPDATE_EMPLOYEE_EMAIL_SUCCESS',
  EMPLOYEE_EMAILS_UPDATE_FAILURE: '@employees/UPDATE_EMPLOYEE_EMAIL_FAILURE',

  EMPLOYEE_EMAILS_DELETE_REQUEST: '@employees/DELETE_EMPLOYEE_EMAIL_REQUEST',
  EMPLOYEE_EMAILS_DELETE_SUCCESS: '@employees/DELETE_EMPLOYEE_EMAIL_SUCCESS',
  EMPLOYEE_EMAILS_DELETE_FAILURE: '@employees/DELETE_EMPLOYEE_EMAIL_FAILURE',

  EMPLOYEE_EMAILS_RESET_LIST: '@employees/RESET_LIST_EMPLOYEE_EMAIL',

  EMPLOYEE_EMAILS_OPEN_MODAL: '@employees/OPEN_MODAL_EMPLOYEE_EMAIL',
  EMPLOYEE_EMAILS_CLOSE_MODAL: '@employees/CLOSE_MODAL_EMPLOYEE_EMAIL',
};

export default Types;
