const Types = {
  PRODUCT_DISCOUNTS_GET: '@products/GET_PRODUCT_DISCOUNTS',

  PRODUCT_DISCOUNTS_CREATE_REQUEST: '@products/CREATE_PRODUCT_DISCOUNT_REQUEST',
  PRODUCT_DISCOUNTS_CREATE_SUCCESS: '@products/CREATE_PRODUCT_DISCOUNT_SUCCESS',
  PRODUCT_DISCOUNTS_CREATE_FAILURE: '@products/CREATE_PRODUCT_DISCOUNT_FAILURE',

  PRODUCT_DISCOUNTS_UPDATE_REQUEST: '@products/UPDATE_PRODUCT_DISCOUNT_REQUEST',
  PRODUCT_DISCOUNTS_UPDATE_SUCCESS: '@products/UPDATE_PRODUCT_DISCOUNT_SUCCESS',
  PRODUCT_DISCOUNTS_UPDATE_FAILURE: '@products/UPDATE_PRODUCT_DISCOUNT_FAILURE',

  PRODUCT_DISCOUNTS_DELETE_REQUEST: '@products/DELETE_PRODUCT_DISCOUNT_REQUEST',
  PRODUCT_DISCOUNTS_DELETE_SUCCESS: '@products/DELETE_PRODUCT_DISCOUNT_SUCCESS',
  PRODUCT_DISCOUNTS_DELETE_FAILURE: '@products/DELETE_PRODUCT_DISCOUNT_FAILURE',

  PRODUCT_DISCOUNTS_OPEN_MODAL: '@products/OPEN_MODAL_PRODUCT_DISCOUNT',
  PRODUCT_DISCOUNTS_CLOSE_MODAL: '@products/CLOSE_MODAL_PRODUCT_DISCOUNT',

  PRODUCT_DISCOUNTS_RESET_LIST: '@products/RESET_LIST_PRODUCT_DISCOUNT',
};

export default Types;
