import { Box as RFBox } from 'react-feather';

import styled from 'styled-components';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'avatar-large':
      styles = `
        width: 80px;
        height: 80px;
      `;
      break;
    case 'avatar-small':
      styles = ``;
      break;
    case 'avatar-smallest':
      styles = `padding: 3px;`;
      break;
    case 'report':
      styles = `
        width: 30px;
        height: 30px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Box = styled(RFBox)`
  ${({ type }) => defineStyles(type)}
`;
