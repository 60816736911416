import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCustomerFileSuccess,
  createCustomerFileFailure,
  updateCustomerFileSuccess,
  updateCustomerFileFailure,
  deleteCustomerFileSuccess,
  deleteCustomerFileFailure,
} from './actions';
import Types from './types';

export function* createCustomerFile({ payload }) {
  try {
    const { customer_id, file } = payload;

    const response = yield call(
      api.post,
      `customers/${customer_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createCustomerFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCustomerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCustomerFile({ payload }) {
  try {
    const { customer_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `customers/${customer_id}/files/${file_id}`,
      newFile
    );

    yield put(updateCustomerFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCustomerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCustomerFile({ payload }) {
  try {
    const { customer_id, file_id } = payload;

    yield call(api.delete, `customers/${customer_id}/files/${file_id}`);

    yield put(deleteCustomerFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCustomerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.CUSTOMER_FILES_CREATE_REQUEST, createCustomerFile),
  takeLatest(Types.CUSTOMER_FILES_UPDATE_REQUEST, updateCustomerFile),
  takeLatest(Types.CUSTOMER_FILES_DELETE_REQUEST, deleteCustomerFile),
]);
