import Types from './types';

export function getContactPhones(phones) {
  return {
    type: Types.CONTACT_PHONES_GET,
    payload: { phones },
  };
}

export function resetListContactPhone() {
  return {
    type: Types.CONTACT_PHONES_RESET_LIST,
  };
}
