import Types from './types';

export function getCostCentersRequest() {
  return {
    type: Types.COST_CENTERS_GET_REQUEST,
  };
}

export function getCostCentersSuccess(costCenters) {
  return {
    type: Types.COST_CENTERS_GET_SUCCESS,
    payload: { costCenters },
  };
}

export function getCostCentersFailure() {
  return {
    type: Types.COST_CENTERS_GET_FAILURE,
  };
}

export function createCostCenterRequest(costCenter) {
  return {
    type: Types.COST_CENTERS_CREATE_REQUEST,
    payload: { costCenter },
  };
}

export function createCostCenterSuccess(costCenter) {
  return {
    type: Types.COST_CENTERS_CREATE_SUCCESS,
    payload: { costCenter },
  };
}

export function createCostCenterFailure() {
  return {
    type: Types.COST_CENTERS_CREATE_FAILURE,
  };
}

export function updateCostCenterRequest(id, newCostCenter) {
  return {
    type: Types.COST_CENTERS_UPDATE_REQUEST,
    payload: { id, newCostCenter },
  };
}

export function updateCostCenterSuccess(newCostCenter) {
  return {
    type: Types.COST_CENTERS_UPDATE_SUCCESS,
    payload: { newCostCenter },
  };
}

export function updateCostCenterFailure() {
  return {
    type: Types.COST_CENTERS_UPDATE_FAILURE,
  };
}

export function deleteCostCenterRequest(id) {
  return {
    type: Types.COST_CENTERS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteCostCenterSuccess(id) {
  return {
    type: Types.COST_CENTERS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteCostCenterFailure() {
  return {
    type: Types.COST_CENTERS_DELETE_FAILURE,
  };
}

export function resetListCostCenters() {
  return {
    type: Types.COST_CENTERS_RESET_LIST,
  };
}

export function openModalCostCenter({ id, newRegister }) {
  return {
    type: Types.COST_CENTERS_OPEN_MODAL,
    payload: { id, newRegister },
  };
}

export function closeModalCostCenter() {
  return {
    type: Types.COST_CENTERS_CLOSE_MODAL,
  };
}
