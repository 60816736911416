const Types = {
  SUPPLIER_PHONES_GET: '@suppliers/GET_SUPPLIER_PHONES',

  SUPPLIER_PHONES_CREATE_REQUEST: '@suppliers/CREATE_SUPPLIER_PHONE_REQUEST',
  SUPPLIER_PHONES_CREATE_SUCCESS: '@suppliers/CREATE_SUPPLIER_PHONE_SUCCESS',
  SUPPLIER_PHONES_CREATE_FAILURE: '@suppliers/CREATE_SUPPLIER_PHONE_FAILURE',

  SUPPLIER_PHONES_UPDATE_REQUEST: '@suppliers/UPDATE_SUPPLIER_PHONE_REQUEST',
  SUPPLIER_PHONES_UPDATE_SUCCESS: '@suppliers/UPDATE_SUPPLIER_PHONE_SUCCESS',
  SUPPLIER_PHONES_UPDATE_FAILURE: '@suppliers/UPDATE_SUPPLIER_PHONE_FAILURE',

  SUPPLIER_PHONES_DELETE_REQUEST: '@suppliers/DELETE_SUPPLIER_PHONE_REQUEST',
  SUPPLIER_PHONES_DELETE_SUCCESS: '@suppliers/DELETE_SUPPLIER_PHONE_SUCCESS',
  SUPPLIER_PHONES_DELETE_FAILURE: '@suppliers/DELETE_SUPPLIER_PHONE_FAILURE',

  SUPPLIER_PHONES_RESET_LIST: '@suppliers/RESET_LIST_SUPPLIER_PHONE',

  SUPPLIER_PHONES_OPEN_MODAL: '@suppliers/OPEN_MODAL_SUPPLIER_PHONE',
  SUPPLIER_PHONES_CLOSE_MODAL: '@suppliers/CLOSE_MODAL_SUPPLIER_PHONE',
};

export default Types;
