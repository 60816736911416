const Types = {
  ACCOUNTS_GET_REQUEST: '@accounts/GET_ACCOUNTS_REQUEST',
  ACCOUNTS_GET_SUCCESS: '@accounts/GET_ACCOUNTS_SUCCESS',
  ACCOUNTS_GET_FAILURE: '@accounts/GET_ACCOUNTS_FAILURE',

  ACCOUNTS_CREATE_REQUEST: '@accounts/CREATE_ACCOUNT_REQUEST',
  ACCOUNTS_CREATE_SUCCESS: '@accounts/CREATE_ACCOUNT_SUCCESS',
  ACCOUNTS_CREATE_FAILURE: '@accounts/CREATE_ACCOUNT_FAILURE',

  ACCOUNTS_UPDATE_REQUEST: '@accounts/UPDATE_ACCOUNT_REQUEST',
  ACCOUNTS_UPDATE_SUCCESS: '@accounts/UPDATE_ACCOUNT_SUCCESS',
  ACCOUNTS_UPDATE_FAILURE: '@accounts/UPDATE_ACCOUNT_FAILURE',

  ACCOUNTS_DELETE_REQUEST: '@accounts/DELETE_ACCOUNT_REQUEST',
  ACCOUNTS_DELETE_SUCCESS: '@accounts/DELETE_ACCOUNT_SUCCESS',
  ACCOUNTS_DELETE_FAILURE: '@accounts/DELETE_ACCOUNT_FAILURE',

  ACCOUNTS_GET_DETAILS_REQUEST: '@accounts/GET_DETAILS_ACCOUNT_REQUEST',
  ACCOUNTS_GET_DETAILS_SUCCESS: '@accounts/GET_DETAILS_ACCOUNT_SUCCESS',
  ACCOUNTS_GET_DETAILS_FAILURE: '@accounts/GET_DETAILS_ACCOUNT_FAILURE',

  ACCOUNTS_RESET_LIST: '@accounts/RESET_LIST_ACCOUNT',
  ACCOUNTS_RESET_DETAILS: '@accounts/RESET_DETAILS_ACCOUNT',

  ACCOUNTS_FILTER_OPEN_MODAL: '@accounts/OPEN_MODAL_FILTER_ACCOUNT',
  ACCOUNTS_FILTER_CLOSE_MODAL: '@accounts/CLOSE_MODAL_FILTER_ACCOUNT',

  ACCOUNTS_SET_SAVING_ON_SUB_RESOURCE:
    '@accounts/SET_SAVING_ON_SUB_RESOURCE_ACCOUNT',
  ACCOUNTS_RESET_SUB_RESOURCE_DATA: '@accounts/RESET_SUB_RESOURCE_DATA_ACCOUNT',
};

export default Types;
