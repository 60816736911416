import Types from './types';

export function getTeamsRequest() {
  return {
    type: Types.TEAMS_GET_REQUEST,
  };
}

export function getTeamsSuccess({ data }) {
  return {
    type: Types.TEAMS_GET_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getTeamsFailure() {
  return {
    type: Types.TEAMS_GET_FAILURE,
  };
}

export function createTeamRequest(team, subResourceType = null) {
  return {
    type: Types.TEAMS_CREATE_REQUEST,
    payload: { team, subResourceType },
  };
}

export function createTeamSuccess(team) {
  return {
    type: Types.TEAMS_CREATE_SUCCESS,
    payload: { team },
  };
}

export function createTeamFailure() {
  return {
    type: Types.TEAMS_CREATE_FAILURE,
  };
}

export function updateTeamRequest(id, newTeam) {
  return {
    type: Types.TEAMS_UPDATE_REQUEST,
    payload: {
      id,
      newTeam,
    },
  };
}

export function updateTeamSuccess(newTeam) {
  return {
    type: Types.TEAMS_UPDATE_SUCCESS,
    payload: {
      newTeam,
    },
  };
}

export function updateTeamFailure() {
  return {
    type: Types.TEAMS_UPDATE_FAILURE,
  };
}

export function deleteTeamRequest(id) {
  return {
    type: Types.TEAMS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteTeamSuccess(id) {
  return {
    type: Types.TEAMS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteTeamFailure() {
  return {
    type: Types.TEAMS_DELETE_FAILURE,
  };
}

export function getDetailsTeamRequest(id) {
  return {
    type: Types.TEAMS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsTeamSuccess(team) {
  return {
    type: Types.TEAMS_GET_DETAILS_SUCCESS,
    payload: { team },
  };
}

export function getDetailsTeamFailure() {
  return {
    type: Types.TEAMS_GET_DETAILS_FAILURE,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.TEAMS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.TEAMS_RESET_SUB_RESOURCE_DATA,
  };
}

export function resetListTeams() {
  return {
    type: Types.TEAMS_RESET_LIST,
  };
}

export function resetDetailsTeam() {
  return {
    type: Types.TEAMS_RESET_DETAIL,
  };
}
