import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCustomerAddresses,
  openModalCustomerAddress,
  resetListCustomerAddress,
} from '../customerAddresses/actions';
import {
  getCustomerEmails,
  openModalCustomerEmail,
  resetListCustomerEmail,
} from '../customerEmails/actions';
import {
  getCustomerFiles,
  resetListCustomerFile,
} from '../customerFiles/actions';
import {
  getCustomerPhones,
  resetListCustomerPhone,
  openModalCustomerPhone,
} from '../customerPhones/actions';
import { resetFoundPerson } from '../persons/actions';
import {
  createCustomerSuccess,
  createCustomerFailure,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  becomeCustomerSuccess,
  becomeCustomerFailure,
  getDetailsCustomerSuccess,
  updateCustomerSuccess,
  updateCustomerFailure,
  getDetailsCustomerFailure,
  setOpenFileDialog,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* createCustomer({ payload }) {
  try {
    const { customer, subResourceType } = payload;

    const response = yield call(api.post, 'customers', customer);

    yield put(createCustomerSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/customer/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCustomer({ payload }) {
  try {
    const { id, newCustomer } = payload;

    const response = yield call(api.put, `customers/${id}`, newCustomer);

    yield put(updateCustomerSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCustomer({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `customers/${id}`);

    yield put(deleteCustomerSuccess(isFromDetail));

    history.push('/customers');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.customers);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isDeletingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(deleteCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeCustomer({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'CUSTOMER';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeCustomerSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/customer/${id}`);
  } catch (err) {
    yield put(becomeCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsCustomer({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `customers/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getCustomerPhones(phones));

    yield put(getCustomerAddresses(addresses));

    yield put(getCustomerEmails(emails));

    yield put(getCustomerFiles(files));

    yield put(getDetailsCustomerSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.customers
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalCustomerPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalCustomerEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalCustomerAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsCustomer() {
  yield put(resetListCustomerPhone());

  yield put(resetListCustomerAddress());

  yield put(resetListCustomerEmail());

  yield put(resetListCustomerFile());
}

export default all([
  takeLatest(Types.CUSTOMERS_CREATE_REQUEST, createCustomer),
  takeLatest(Types.CUSTOMERS_UPDATE_REQUEST, updateCustomer),
  takeLatest(Types.CUSTOMERS_DELETE_REQUEST, deleteCustomer),
  takeLatest(Types.CUSTOMERS_BECOME_REQUEST, becomeCustomer),
  takeLatest(Types.CUSTOMERS_GET_DETAILS_REQUEST, getDetailsCustomer),
  takeLatest(Types.CUSTOMERS_RESET_DETAILS, resetDetailsCustomer),
]);
