import Types from './types';

export function getTransportersRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.TRANSPORTERS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getTransportersSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.TRANSPORTERS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getTransportersFailure() {
  return {
    type: Types.TRANSPORTERS_GET_FAILURE,
  };
}

export function createTransporterRequest(transporter, subResourceType = null) {
  return {
    type: Types.TRANSPORTERS_CREATE_REQUEST,
    payload: { transporter, subResourceType },
  };
}

export function createTransporterSuccess(transporter) {
  return {
    type: Types.TRANSPORTERS_CREATE_SUCCESS,
    payload: { transporter },
  };
}

export function createTransporterFailure() {
  return {
    type: Types.TRANSPORTERS_CREATE_FAILURE,
  };
}

export function updateTransporterRequest(id, newTransporter) {
  return {
    type: Types.TRANSPORTERS_UPDATE_REQUEST,
    payload: { id, newTransporter },
  };
}

export function updateTransporterSuccess(newTransporter) {
  return {
    type: Types.TRANSPORTERS_UPDATE_SUCCESS,
    payload: { newTransporter },
  };
}

export function updateTransporterFailure() {
  return {
    type: Types.TRANSPORTERS_UPDATE_FAILURE,
  };
}

export function deleteTransporterRequest(id) {
  return {
    type: Types.TRANSPORTERS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteTransporterSuccess(id) {
  return {
    type: Types.TRANSPORTERS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteTransporterFailure() {
  return {
    type: Types.TRANSPORTERS_DELETE_FAILURE,
  };
}

export function becomeTransporterRequest(id) {
  return {
    type: Types.TRANSPORTERS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeTransporterSuccess(transporter) {
  return {
    type: Types.TRANSPORTERS_BECOME_SUCCESS,
    payload: { transporter },
  };
}

export function becomeTransporterFailure() {
  return {
    type: Types.TRANSPORTERS_BECOME_FAILURE,
  };
}

export function getDetailsTransporterRequest(id) {
  return {
    type: Types.TRANSPORTERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsTransporterSuccess(transporter) {
  return {
    type: Types.TRANSPORTERS_GET_DETAILS_SUCCESS,
    payload: { transporter },
  };
}

export function getDetailsTransporterFailure() {
  return {
    type: Types.TRANSPORTERS_GET_DETAILS_FAILURE,
  };
}

export function resetListTransporters() {
  return {
    type: Types.TRANSPORTERS_RESET_LIST,
  };
}

export function resetDetailsTransporter() {
  return {
    type: Types.TRANSPORTERS_RESET_DETAILS,
  };
}

export function openModalAddTransporter() {
  return {
    type: Types.TRANSPORTERS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddTransporter() {
  return {
    type: Types.TRANSPORTERS_ADD_CLOSE_MODAL,
  };
}

export function openModalTransporterObservation() {
  return {
    type: Types.TRANSPORTERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalTransporterObservation() {
  return {
    type: Types.TRANSPORTERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalTransporterFilter() {
  return {
    type: Types.TRANSPORTERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalTransporterFilter() {
  return {
    type: Types.TRANSPORTERS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.TRANSPORTERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.TRANSPORTERS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.TRANSPORTERS_RESET_SUB_RESOURCE_DATA,
  };
}
