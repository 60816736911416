import React, { useState } from 'react';

import { CsvBuilder } from 'filefy';
import JsPDF from 'jspdf';

import 'jspdf-autotable';

import {
  InputAdornment,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
} from '@material-ui/core';

import {
  TextField,
  Toolbar,
  ActionContainer,
  ColumnsButtonMenuItem,
} from './styles';

/*
  Esse componente e uma versao customizada do component Toolbar do Material Table
  A versao original deste componente utiliza de classes e metodos em sua composicao
  Para manter o padrao de desenvolvimento, foi utilizado funcoes.

  Algumas alteracoes foram feitas e estao comentadas.

  Component:
  https://github.com/mbrn/material-table/blob/v1.66.0/src/components/m-table-toolbar.js
*/

function CustomToolbar(props) {
  const {
    actions,
    columns,
    columnsButton,
    components,
    getFieldValue,
    localization,
    onColumnsChanged,
    dataManager,
    searchText,
    onSearchChanged,
    search,
    searchFieldStyle,
    searchFieldVariant,
    selectedRows,
    title,
    showTitle,
    showTextRowsSelected,
    searchFieldAlignment,
    renderData,
    data,
    exportAllData,
    exportButton,
    exportDelimiter,
    exportFileName,
    exportCsv,
    exportPdf,
    searchAutoFocus,
    icons,
  } = props;

  const [columnsButtonAnchorEl, setColumnsButtonAnchorEl] = useState(null);
  const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState(null);
  const [searchedText, setSearchedText] = useState(searchText);

  // O nome do parametro foi alterado (searchText).
  function onSearchChange(searched) {
    dataManager.changeSearchText(searched);
    setSearchedText(searched, onSearchChanged(searched));
  }

  // Os nomes das variaveis foram alteradas (columns, data).
  function getTableData() {
    const tableColumns = columns
      .filter(
        columnDef =>
          (!columnDef.hidden || columnDef.export === true) &&
          columnDef.export !== false &&
          columnDef.field
      )
      .sort((a, b) =>
        a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1
      );

    const tableData = (exportAllData ? data : renderData).map(rowData =>
      tableColumns.map(columnDef => getFieldValue(rowData, columnDef))
    );

    return [tableColumns, tableData];
  }

  // O nome das variaveis foram alteradas (columns, data).
  function defaultExportCsv() {
    const [tableColumns, tableData] = getTableData();

    let fileName = title || 'data';

    if (exportFileName) {
      fileName =
        typeof exportFileName === 'function'
          ? exportFileName()
          : exportFileName;
    }

    const builder = new CsvBuilder(`${fileName}.csv`);

    builder
      .setDelimeter(exportDelimiter)
      .setColumns(tableColumns.map(columnDef => columnDef.title))
      .addRows(tableData)
      .exportFile();
  }

  // O nome das variaveis foram alteradas (columns, data).
  // o JsPDF foi instanciado dentro da funcao, e nao no comeco do codigo.
  function defaultExportPdf() {
    const [tableColumns, tableData] = getTableData();

    const content = {
      startY: 50,
      head: [tableColumns.map(columnDef => columnDef.title)],
      body: tableData,
    };

    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';

    const doc = new JsPDF(orientation, unit, size);

    doc.setFontSize(15);
    doc.text(exportFileName || title, 40, 40);
    doc.autoTable(content);
    doc.save(`${exportFileName || title || 'data'}.pdf`);
  }

  // O nome dessa funcao foi alterada (exportCsv) devido ao nome da props.
  function exportToCsv(tableColumns, tableData) {
    if (exportCsv) {
      exportCsv(tableColumns, tableData);
    } else {
      defaultExportCsv();
    }

    setExportButtonAnchorEl(null);
  }

  // O nome dessa funcao foi alterada (exportPdf) devido ao nome da props.
  function exportToPdf(tableColumns, tableData) {
    if (exportPdf) {
      exportPdf(tableColumns, tableData);
    } else {
      defaultExportPdf();
    }

    setExportButtonAnchorEl(null);
  }

  function renderSearch() {
    if (search) {
      return (
        <TextField
          autoFocus={searchAutoFocus}
          value={searchedText}
          onKeyPress={e => {
            if (e.which === 13) {
              e.preventDefault();
            }
          }}
          onChange={event => {
            return onSearchChange(event.target.value);
          }}
          placeholder={localization.searchPlaceholder}
          variant={searchFieldVariant}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={localization.searchTooltip}>
                  <icons.Search fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!searchText}
                  onClick={() => onSearchChange('')}
                  aria-label={localization.clearSearchAriaLabel}
                >
                  <icons.ResetSearch fontSize="small" aria-label="clear" />
                </IconButton>
              </InputAdornment>
            ),
            style: searchFieldStyle,
            inputProps: {
              'aria-label': localization.searchAriaLabel,
            },
          }}
          search_field_alignment={searchFieldAlignment}
        />
      );
    }

    return null;
  }

  // Foi criado o componente ActionContainer e ColumnsButtonMenuItem.
  function renderDefaultActions() {
    return (
      <ActionContainer>
        {columnsButton && (
          <span>
            <Tooltip title={localization.showColumnsTitle}>
              <IconButton
                color="inherit"
                onClick={event => setColumnsButtonAnchorEl(event.currentTarget)}
                aria-label={localization.showColumnsAriaLabel}
              >
                <props.icons.ViewColumn />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={columnsButtonAnchorEl}
              open={Boolean(columnsButtonAnchorEl)}
              onClose={() => setColumnsButtonAnchorEl(null)}
            >
              <MenuItem
                key="text"
                disabled
                style={{ opacity: 1, fontWeight: 600, fontSize: 12 }}
              >
                {localization.addRemoveColumns}
              </MenuItem>
              {columns.map(col => {
                if (!col.hidden || col.hiddenByColumnsButton) {
                  return (
                    <li key={col.tableData.id}>
                      <ColumnsButtonMenuItem
                        component="label"
                        htmlFor={`column-toggle-${col.tableData.id}`}
                        disabled={col.removable === false}
                      >
                        <Checkbox
                          checked={!col.hidden}
                          id={`column-toggle-${col.tableData.id}`}
                          onChange={() => onColumnsChanged(col, !col.hidden)}
                        />
                        <span>{col.title}</span>
                      </ColumnsButtonMenuItem>
                    </li>
                  );
                }

                return null;
              })}
            </Menu>
          </span>
        )}

        {exportButton && (
          <span>
            <Tooltip title={localization.exportTitle}>
              <IconButton
                color="inherit"
                onClick={event => setExportButtonAnchorEl(event.currentTarget)}
                aria-label={localization.exportAriaLabel}
              >
                <props.icons.Export />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={exportButtonAnchorEl}
              open={Boolean(exportButtonAnchorEl)}
              onClose={() => setExportButtonAnchorEl(null)}
            >
              {(exportButton || exportButton.csv) && (
                <MenuItem
                  key="export-csv"
                  onClick={() => exportToCsv(columns, data)}
                >
                  {localization.exportCSVName}
                </MenuItem>
              )}
              {(exportButton || exportButton.pdf) && (
                <MenuItem
                  key="export-pdf"
                  onClick={() => exportToPdf(columns, data)}
                >
                  {localization.exportPDFName}
                </MenuItem>
              )}
            </Menu>
          </span>
        )}

        <span>
          <props.components.Actions
            actions={actions && actions.filter(a => a.position === 'toolbar')}
            components={components}
          />
        </span>
      </ActionContainer>
    );
  }

  function renderSelectedActions() {
    return (
      <>
        <props.components.Actions
          actions={actions.filter(a => a.position === 'toolbarOnSelect')}
          data={selectedRows}
          components={components}
        />
      </>
    );
  }

  function renderActions() {
    return (
      <div>
        <div>
          {selectedRows && selectedRows.length > 0
            ? renderSelectedActions()
            : renderDefaultActions()}
        </div>
      </div>
    );
  }

  function renderToolbarTitle(toolbarTitle) {
    const toolBarTitle =
      typeof toolbarTitle === 'string' ? (
        <Typography
          variant="h6"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {toolbarTitle}
        </Typography>
      ) : (
        toolbarTitle
      );

    return <div>{toolBarTitle}</div>;
  }

  // Nao foi adicionada a funcionalidade de posicionar os elementos a direita ou a esquerda.
  function render() {
    const toolbarTitle =
      // eslint-disable-next-line no-nested-ternary
      showTextRowsSelected && selectedRows && selectedRows.length > 0
        ? typeof localization.nRowsSelected === 'function'
          ? localization.nRowsSelected(selectedRows.length)
          : localization.nRowsSelected.replace('{0}', selectedRows.length)
        : showTitle
        ? title
        : null;

    return (
      <Toolbar>
        {toolbarTitle && renderToolbarTitle(toolbarTitle)}
        {renderSearch()}
        {renderActions()}
      </Toolbar>
    );
  }

  return render();
}

export default CustomToolbar;
