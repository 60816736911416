import styled from 'styled-components';

import {
  Modal as MuiModal,
  Grid,
  Typography,
  Paper,
  Typography as MuiTypography,
} from '@material-ui/core';

import colors from '~/theme/colors';

const handleSize = size => {
  switch (size) {
    case 'extra':
      return '1000px';
    case 'large':
      return '800px';
    case 'medium':
      return '650px';
    case 'small':
      return '500px';
    default:
      return '650px';
  }
};

export const Modal = styled(MuiModal).attrs({
  disableEnforceFocus: true,
})`
  z-index: 1500;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  padding-left: 10%;
  padding-right: 10%;

  display: flex;
  justify-content: center;
  align-items: center;

  body {
    padding: 0;
  }
`;

export const Container = styled(Paper)`
  outline: none;
  min-width: 450px;
  width: ${({ size }) => handleSize(size)};
  border-radius: 4px;

  &:focus {
    box-shadow: 0 0 0 2pt ${props => props.theme.palette.secondary.main};
  }

  max-height: 90vh;

  @media (max-width: 500px) {
    min-width: 90vw;
  }
`;

export const Content = styled(Grid).attrs({
  container: true,
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;

  & > * {
    max-height: 70vh;
  }
`;

export const HeaderContainer = styled(Grid).attrs({
  container: true,
  alignItems: 'center',
})`
  background: ${props => props.theme.palette.primary.main};
  border-radius: 4px 4px 0 0;
`;

export const ButtonContainer = styled(Grid).attrs({
  container: true,
  justify: 'flex-end',
})`
  padding: 5px;
`;

export const Title = styled(Typography).attrs({
  align: 'center',
  component: 'h1',
})`
  font-size: 18px;
  color: ${colors.white};

  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const ContactName = styled.div`
  display: flex;
  align-items: center;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled(MuiTypography)`
  font-weight: 300;
  font-size: 12px;
  padding: 0;
  margin: 0;
  display: block;
`;

export const Nickname = styled(MuiTypography)`
  display: block;
  padding: 0;
  margin: 0;
`;
