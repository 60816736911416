import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  shouldLoadPriceListItems: false,

  detail: {},
  priceListFilterModalOpen: false,
  requestError: false,
  loading: false,
  detailRequestError: false,
};

export default function priceLists(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PRICE_LISTS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.PRICE_LISTS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.priceListFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        break;
      }
      case Types.PRICE_LISTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRICE_LISTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRICE_LISTS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.priceList);
        draft.loading = false;
        draft.shouldLoadPriceListItems = true;
        break;
      }
      case Types.PRICE_LISTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRICE_LISTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRICE_LISTS_UPDATE_SUCCESS: {
        const { newPriceList } = action.payload;
        draft.detail = newPriceList;

        draft.list = state.list.map(priceList => {
          return priceList.id === newPriceList.id ? newPriceList : priceList;
        });

        draft.loading = false;
        break;
      }
      case Types.PRICE_LISTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRICE_LISTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRICE_LISTS_DELETE_SUCCESS: {
        draft.list = state.list.filter(priceList => {
          return priceList.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.PRICE_LISTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRICE_LISTS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        draft.loading = true;
        break;
      }
      case Types.PRICE_LISTS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.priceList;
        draft.requestError = false;
        draft.loading = false;

        if (state.shouldLoadPriceListItems) {
          draft.shouldLoadPriceListItems = false;
        }
        break;
      }
      case Types.PRICE_LISTS_GET_DETAILS_FAILURE: {
        draft.detail = {};
        draft.loading = false;
        draft.requestError = true;
        draft.detailRequestError = true;
        break;
      }

      case Types.PRICE_LISTS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.priceListFilterModalOpen = false;
        break;
      }

      case Types.PRICE_LISTS_RESET_DETAILS: {
        draft.detail = {};
        draft.detailRequestError = false;
        draft.shouldLoadPriceListItems = false;
        break;
      }

      case Types.PRICE_LISTS_FILTER_OPEN_MODAL: {
        draft.priceListFilterModalOpen = true;
        break;
      }
      case Types.PRICE_LISTS_FILTER_CLOSE_MODAL: {
        draft.priceListFilterModalOpen = false;
        break;
      }

      default:
    }
  });
}
