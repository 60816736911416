const Types = {
  PROMOTER_EMAILS_GET: '@promoters/GET_PROMOTER_EMAILS',

  PROMOTER_EMAILS_CREATE_REQUEST: '@promoters/CREATE_PROMOTER_EMAIL_REQUEST',
  PROMOTER_EMAILS_CREATE_SUCCESS: '@promoters/CREATE_PROMOTER_EMAIL_SUCCESS',
  PROMOTER_EMAILS_CREATE_FAILURE: '@promoters/CREATE_PROMOTER_EMAIL_FAILURE',

  PROMOTER_EMAILS_UPDATE_REQUEST: '@promoters/UPDATE_PROMOTER_EMAIL_REQUEST',
  PROMOTER_EMAILS_UPDATE_SUCCESS: '@promoters/UPDATE_PROMOTER_EMAIL_SUCCESS',
  PROMOTER_EMAILS_UPDATE_FAILURE: '@promoters/UPDATE_PROMOTER_EMAIL_FAILURE',

  PROMOTER_EMAILS_DELETE_REQUEST: '@promoters/DELETE_PROMOTER_EMAIL_REQUEST',
  PROMOTER_EMAILS_DELETE_SUCCESS: '@promoters/DELETE_PROMOTER_EMAIL_SUCCESS',
  PROMOTER_EMAILS_DELETE_FAILURE: '@promoters/DELETE_PROMOTER_EMAIL_FAILURE',

  PROMOTER_EMAILS_RESET_LIST: '@promoters/RESET_LIST_PROMOTER_EMAIL',

  PROMOTER_EMAILS_OPEN_MODAL: '@promoters/OPEN_MODAL_PROMOTER_EMAIL',
  PROMOTER_EMAILS_CLOSE_MODAL: '@promoters/CLOSE_MODAL_PROMOTER_EMAIL',
};

export default Types;
