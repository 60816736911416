import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  detail: {},
  inventoryFilterModalOpen: false,
  requestError: false,
  loading: false,
  detailRequestError: false,
  subResourceType: null,
  shouldOpenSubResourceModal: false,
};

export default function inventories(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.INVENTORIES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.INVENTORIES_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.inventoryFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        break;
      }
      case Types.INVENTORIES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.INVENTORIES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.inventory);
        draft.loading = false;
        break;
      }
      case Types.INVENTORIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORIES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.INVENTORIES_UPDATE_SUCCESS: {
        const { newInventory } = action.payload;
        draft.detail = newInventory;

        draft.list = state.list.map(inventory => {
          return inventory.id === newInventory.id ? newInventory : inventory;
        });

        draft.loading = false;
        break;
      }
      case Types.INVENTORIES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.INVENTORIES_DELETE_SUCCESS: {
        draft.list = state.list.filter(inventory => {
          return inventory.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.INVENTORIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORIES_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        draft.loading = true;
        break;
      }
      case Types.INVENTORIES_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.inventory;
        draft.requestError = false;
        draft.loading = false;

        break;
      }
      case Types.INVENTORIES_GET_DETAILS_FAILURE: {
        draft.detail = {};
        draft.loading = false;
        draft.requestError = true;
        draft.detailRequestError = true;
        break;
      }

      case Types.INVENTORIES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.inventoryFilterModalOpen = false;
        break;
      }

      case Types.INVENTORIES_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.INVENTORIES_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      case Types.INVENTORIES_RESET_DETAILS: {
        draft.detail = {};
        draft.detailRequestError = false;
        break;
      }

      case Types.INVENTORIES_FILTER_OPEN_MODAL: {
        draft.inventoryFilterModalOpen = true;
        break;
      }
      case Types.INVENTORIES_FILTER_CLOSE_MODAL: {
        draft.inventoryFilterModalOpen = false;
        break;
      }

      default:
    }
  });
}
