import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCustomerEmailSuccess,
  createCustomerEmailFailure,
  updateCustomerEmailSuccess,
  updateCustomerEmailFailure,
  deleteCustomerEmailSuccess,
  deleteCustomerEmailFailure,
} from './actions';
import Types from './types';

export function* createCustomerEmail({ payload }) {
  try {
    const { customer_id, email } = payload;

    const response = yield call(
      api.post,
      `customers/${customer_id}/emails`,
      email
    );

    yield put(createCustomerEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCustomerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCustomerEmail({ payload }) {
  try {
    const { customer_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `customers/${customer_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateCustomerEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCustomerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCustomerEmail({ payload }) {
  const { customer_id, email_id } = payload;

  const emails = yield select(state => state.customerEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteCustomerEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `customers/${customer_id}/emails/${email_id}`);

    yield put(deleteCustomerEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCustomerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.CUSTOMER_EMAILS_CREATE_REQUEST, createCustomerEmail),
  takeLatest(Types.CUSTOMER_EMAILS_UPDATE_REQUEST, updateCustomerEmail),
  takeLatest(Types.CUSTOMER_EMAILS_DELETE_REQUEST, deleteCustomerEmail),
]);
