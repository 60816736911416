import produce from 'immer';

import BankBillingPixsTypes from '../bankBillingPixs/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  bankBillingAddModalOpen: false,
  bankBillingFilterModalOpen: false,
};

export default function bankBillings(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BANK_BILLINGS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.BANK_BILLINGS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.bankBillingFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.BANK_BILLINGS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_BILLINGS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BANK_BILLINGS_DELETE_SUCCESS: {
        draft.list = state.list.filter(bankBilling => {
          return bankBilling.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.BANK_BILLINGS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BANK_BILLINGS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.bankBillingFilterModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }

      case Types.BANK_BILLINGS_ADD_OPEN_MODAL: {
        draft.bankBillingAddModalOpen = true;
        break;
      }
      case Types.BANK_BILLINGS_ADD_CLOSE_MODAL: {
        draft.bankBillingAddModalOpen = false;
        break;
      }

      case Types.BANK_BILLINGS_FILTER_OPEN_MODAL: {
        draft.bankBillingFilterModalOpen = true;
        break;
      }
      case Types.BANK_BILLINGS_FILTER_CLOSE_MODAL: {
        draft.bankBillingFilterModalOpen = false;
        break;
      }

      case Types.BANK_BILLINGS_SET_TABLE_FILTERS: {
        const { filterSearch } = action.payload;

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        break;
      }

      case BankBillingPixsTypes.BANK_BILLING_PIXS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.pix);
        break;
      }

      case BankBillingPixsTypes.BANK_BILLING_PIXS_UPDATE_SUCCESS: {
        const { newPix } = action.payload;

        draft.list = state.list.map(pix => {
          return pix.id === newPix.id ? newPix : pix;
        });

        break;
      }

      case BankBillingPixsTypes.BANK_BILLING_PIXS_SETTLEMENT_SUCCESS: {
        const { newPix } = action.payload;

        draft.list = state.list.map(pix => {
          return pix.id === newPix.id ? newPix : pix;
        });

        break;
      }

      default:
    }
  });
}
