import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getDevicesSuccess, getDevicesFailure } from './actions';
import Types from './types';

export function* getDevices({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    let response;

    if (process.env.REACT_APP_MODULE === 'ADMINISTRATION') {
      response = yield call(api.get, `all-devices${queryParams}`);
      yield put(
        getDevicesSuccess({
          data: response.data,
          isAllDevicesRoute: true,
          filterColumnsOrder,
          filterSearch,
        })
      );
    } else {
      response = yield call(
        api.get,
        `devices?group_companies=true${queryParams}`
      );

      yield put(
        getDevicesSuccess({
          data: response.data,
          isAllDevicesRoute: false,
          filterColumnsOrder,
          filterSearch,
        })
      );
    }
  } catch (err) {
    yield put(getDevicesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([takeLatest(Types.DEVICES_GET_REQUEST, getDevices)]);
