import React from 'react';

import PropTypes from 'prop-types';

import { CheckCircle } from './styles';

export default function CheckIcon({ type }) {
  return <CheckCircle type={type} />;
}

CheckIcon.propTypes = {
  type: PropTypes.string,
};

CheckIcon.defaultProps = {
  type: '',
};
