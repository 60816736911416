import AddBoxIcon from './AddBoxIcon';
import BoxIcon from './BoxIcon';
import BusinessIcon from './BusinessIcon';
import CalendarIcon from './CalendarIcon';
import CancelIcon from './CancelIcon';
import CatalogIcon from './CatalogIcon';
import CheckIcon from './CheckIcon';
import CloseIcon from './CloseIcon';
import ContactDetailIcon from './ContactDetailIcon';
import CopyIcon from './CopyIcon';
import DecreaseIcon from './DecreaseIcon';
import DeleteIcon from './DeleteIcon';
import DownloadIcon from './DownloadIcon';
import EditIcon from './EditIcon';
import EmailIcon from './EmailIcon';
import ExpandIcon from './ExpandIcon';
import FilterIcon from './FilterIcon';
import GroupIcon from './GroupIcon';
import IncreaseIcon from './IncreaseIcon';
import LocationOnIcon from './LocationOnIcon';
import MapIcon from './MapIcon';
import MoneyIcon from './MoneyIcon';
import MoneyOffIcon from './MoneyOffIcon';
import OkIcon from './OkIcon';
import PhoneIcon from './PhoneIcon';
import PixIcon from './PixIcon';
import PlusIcon from './PlusIcon';
import PrintIcon from './PrintIcon';
import QrCodeIcon from './QrCodeIcon';
import ShareIcon from './ShareIcon';
import StampIcon from './StampIcon';
import StarIcon from './StarIcon';
import TaskIcon from './TaskIcon';
import ViewIcon from './ViewIcon';
import WhatsAppIcon from './WhatsAppIcon';

export {
  AddBoxIcon,
  CatalogIcon,
  BoxIcon,
  BusinessIcon,
  ContactDetailIcon,
  CalendarIcon,
  CancelIcon,
  CheckIcon,
  CloseIcon,
  CopyIcon,
  DecreaseIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EmailIcon,
  ExpandIcon,
  FilterIcon,
  GroupIcon,
  IncreaseIcon,
  LocationOnIcon,
  MapIcon,
  MoneyIcon,
  MoneyOffIcon,
  OkIcon,
  PhoneIcon,
  PixIcon,
  PlusIcon,
  PrintIcon,
  QrCodeIcon,
  ShareIcon,
  StampIcon,
  StarIcon,
  ViewIcon,
  TaskIcon,
  WhatsAppIcon,
};
