import Types from './types';

export function getEmployeePhones(phones) {
  return {
    type: Types.EMPLOYEE_PHONES_GET,
    payload: { phones },
  };
}

export function createEmployeePhoneRequest(employee_id, phone) {
  return {
    type: Types.EMPLOYEE_PHONES_CREATE_REQUEST,
    payload: { employee_id, phone },
  };
}

export function createEmployeePhoneSuccess(phone) {
  return {
    type: Types.EMPLOYEE_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createEmployeePhoneFailure() {
  return {
    type: Types.EMPLOYEE_PHONES_CREATE_FAILURE,
  };
}

export function updateEmployeePhoneRequest(employee_id, phone_id, newPhone) {
  return {
    type: Types.EMPLOYEE_PHONES_UPDATE_REQUEST,
    payload: { employee_id, phone_id, newPhone },
  };
}

export function updateEmployeePhoneSuccess(newPhone) {
  return {
    type: Types.EMPLOYEE_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateEmployeePhoneFailure() {
  return {
    type: Types.EMPLOYEE_PHONES_UPDATE_FAILURE,
  };
}

export function deleteEmployeePhoneRequest(employee_id, phone_id) {
  return {
    type: Types.EMPLOYEE_PHONES_DELETE_REQUEST,
    payload: { employee_id, phone_id },
  };
}

export function deleteEmployeePhoneSuccess(phone_id) {
  return {
    type: Types.EMPLOYEE_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteEmployeePhoneFailure() {
  return {
    type: Types.EMPLOYEE_PHONES_DELETE_FAILURE,
  };
}

export function resetListEmployeePhone() {
  return {
    type: Types.EMPLOYEE_PHONES_RESET_LIST,
  };
}

export function openModalEmployeePhone(phone_id) {
  return {
    type: Types.EMPLOYEE_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalEmployeePhone() {
  return {
    type: Types.EMPLOYEE_PHONES_CLOSE_MODAL,
  };
}
