const Types = {
  TRANSPORTER_FILES_GET: '@transporters/GET_TRANSPORTER_FILES',

  TRANSPORTER_FILES_CREATE_REQUEST:
    '@transporters/CREATE_TRANSPORTER_FILE_REQUEST',
  TRANSPORTER_FILES_CREATE_SUCCESS:
    '@transporters/CREATE_TRANSPORTER_FILE_SUCCESS',
  TRANSPORTER_FILES_CREATE_FAILURE:
    '@transporters/CREATE_TRANSPORTER_FILE_FAILURE',

  TRANSPORTER_FILES_UPDATE_REQUEST:
    '@transporters/UPDATE_TRANSPORTER_FILE_REQUEST',
  TRANSPORTER_FILES_UPDATE_SUCCESS:
    '@transporters/UPDATE_TRANSPORTER_FILE_SUCCESS',
  TRANSPORTER_FILES_UPDATE_FAILURE:
    '@transporters/UPDATE_TRANSPORTER_FILE_FAILURE',

  TRANSPORTER_FILES_DELETE_REQUEST:
    '@transporters/DELETE_TRANSPORTER_FILE_REQUEST',
  TRANSPORTER_FILES_DELETE_SUCCESS:
    '@transporters/DELETE_TRANSPORTER_FILE_SUCCESS',
  TRANSPORTER_FILES_DELETE_FAILURE:
    '@transporters/DELETE_TRANSPORTER_FILE_FAILURE',

  TRANSPORTER_FILES_OPEN_MODAL: '@transporters/OPEN_MODAL_TRANSPORTER_FILE',
  TRANSPORTER_FILES_CLOSE_MODAL: '@transporters/CLOSE_MODAL_TRANSPORTER_FILE',

  TRANSPORTER_FILES_RESET_LIST: '@transporters/RESET_LIST_TRANSPORTER_FILE',
};

export default Types;
