import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createBillToReceiveChartAccountSuccess,
  createBillToReceiveChartAccountFailure,
  updateBillToReceiveChartAccountSuccess,
  updateBillToReceiveChartAccountFailure,
  deleteBillToReceiveChartAccountSuccess,
  deleteBillToReceiveChartAccountFailure,
} from './actions';
import Types from './types';

// TODO: Posteriormente, remover a condição para poder apenas adicionar um elemento
export function* createBillToReceiveChartAccount({ payload }) {
  try {
    const { bill_to_receive_id, chartAccount } = payload;

    const chartAccounts = yield select(
      state => state.billToReceiveChartAccounts.list
    );

    if (chartAccounts.length > 0) {
      yield put(createBillToReceiveChartAccountFailure());

      toast.error(
        <IntlMessages id="bills.chart-accounts.error-chart-account-create" />
      );

      return;
    }

    const response = yield call(
      api.post,
      `bills/${bill_to_receive_id}/chart-accounts`,
      chartAccount
    );

    yield put(createBillToReceiveChartAccountSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createBillToReceiveChartAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToReceiveChartAccount({ payload }) {
  try {
    const { bill_to_receive_id, chart_account_id, newChartAccount } = payload;

    const response = yield call(
      api.put,
      `bills/${bill_to_receive_id}/chart-accounts/${chart_account_id}`,
      newChartAccount
    );

    yield put(updateBillToReceiveChartAccountSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBillToReceiveChartAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBillToReceiveChartAccount({ payload }) {
  try {
    const { bill_to_receive_id, chart_account_id } = payload;

    yield call(
      api.delete,
      `bills/${bill_to_receive_id}/chart-accounts/${chart_account_id}`
    );

    yield put(deleteBillToReceiveChartAccountSuccess(chart_account_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBillToReceiveChartAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_CREATE_REQUEST,
    createBillToReceiveChartAccount
  ),
  takeLatest(
    Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_UPDATE_REQUEST,
    updateBillToReceiveChartAccount
  ),
  takeLatest(
    Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_DELETE_REQUEST,
    deleteBillToReceiveChartAccount
  ),
]);
