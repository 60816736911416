const Types = {
  RURAL_PROPERTY_PHONES_GET: '@ruralProperty/GET_RURAL_PROPERTY_PHONES',

  RURAL_PROPERTY_PHONES_CREATE_REQUEST:
    '@ruralProperty/CREATE_RURAL_PROPERTY_PHONE_REQUEST',
  RURAL_PROPERTY_PHONES_CREATE_SUCCESS:
    '@ruralProperty/CREATE_RURAL_PROPERTY_PHONE_SUCCESS',
  RURAL_PROPERTY_PHONES_CREATE_FAILURE:
    '@ruralProperty/CREATE_RURAL_PROPERTY_PHONE_FAILURE',

  RURAL_PROPERTY_PHONES_UPDATE_REQUEST:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_PHONE_REQUEST',
  RURAL_PROPERTY_PHONES_UPDATE_SUCCESS:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_PHONE_SUCCESS',
  RURAL_PROPERTY_PHONES_UPDATE_FAILURE:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_PHONE_FAILURE',

  RURAL_PROPERTY_PHONES_DELETE_REQUEST:
    '@ruralProperty/DELETE_RURAL_PROPERTY_PHONE_REQUEST',
  RURAL_PROPERTY_PHONES_DELETE_SUCCESS:
    '@ruralProperty/DELETE_RURAL_PROPERTY_PHONE_SUCCESS',
  RURAL_PROPERTY_PHONES_DELETE_FAILURE:
    '@ruralProperty/DELETE_RURAL_PROPERTY_PHONE_FAILURE',

  RURAL_PROPERTY_PHONES_RESET_LIST:
    '@ruralProperty/RESET_LIST_RURAL_PROPERTY_PHONE',

  RURAL_PROPERTY_PHONES_OPEN_MODAL:
    '@ruralProperty/OPEN_MODAL_RURAL_PROPERTY_PHONE',
  RURAL_PROPERTY_PHONES_CLOSE_MODAL:
    '@ruralProperty/CLOSE_MODAL_RURAL_PROPERTY_PHONE',
};

export default Types;
