import Types from './types';

export function getInventoriesRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.INVENTORIES_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getInventoriesSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.INVENTORIES_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getInventoriesFailure() {
  return {
    type: Types.INVENTORIES_GET_FAILURE,
  };
}

export function createInventoryRequest(inventory, subResourceType = null) {
  return {
    type: Types.INVENTORIES_CREATE_REQUEST,
    payload: { inventory, subResourceType },
  };
}

export function createInventorySuccess(inventory) {
  return {
    type: Types.INVENTORIES_CREATE_SUCCESS,
    payload: { inventory },
  };
}

export function createInventoryFailure() {
  return {
    type: Types.INVENTORIES_CREATE_FAILURE,
  };
}

export function updateInventoryRequest(id, newInventory) {
  return {
    type: Types.INVENTORIES_UPDATE_REQUEST,
    payload: { id, newInventory },
  };
}

export function updateInventorySuccess(newInventory) {
  return {
    type: Types.INVENTORIES_UPDATE_SUCCESS,
    payload: { newInventory },
  };
}

export function updateInventoryFailure() {
  return {
    type: Types.INVENTORIES_UPDATE_FAILURE,
  };
}

export function deleteInventoryRequest(id) {
  return {
    type: Types.INVENTORIES_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteInventorySuccess(id) {
  return {
    type: Types.INVENTORIES_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteInventoryFailure() {
  return {
    type: Types.INVENTORIES_DELETE_FAILURE,
  };
}

export function openModalInventoryFilter() {
  return {
    type: Types.INVENTORIES_FILTER_OPEN_MODAL,
  };
}

export function closeModalInventoryFilter() {
  return {
    type: Types.INVENTORIES_FILTER_CLOSE_MODAL,
  };
}

export function getDetailsInventoryRequest(id) {
  return {
    type: Types.INVENTORIES_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsInventorySuccess(inventory) {
  return {
    type: Types.INVENTORIES_GET_DETAILS_SUCCESS,
    payload: { inventory },
  };
}

export function getDetailsInventoryFailure() {
  return {
    type: Types.INVENTORIES_GET_DETAILS_FAILURE,
  };
}

export function resetListInventories() {
  return {
    type: Types.INVENTORIES_RESET_LIST,
  };
}

export function resetDetailsInventory() {
  return {
    type: Types.INVENTORIES_RESET_DETAILS,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.INVENTORIES_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.INVENTORIES_RESET_SUB_RESOURCE_DATA,
  };
}
