import produce from 'immer';

import CustomerTypes from '../customers/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  customerFileModalOpen: false,
};

export default function files(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CUSTOMER_FILES_GET: {
        const { files: fileData } = action.payload;

        const hasProfile = fileData.filter(
          file => file.type === 'image/profile' && file.is_main === true
        );

        if (hasProfile.length > 0) {
          const [profile] = hasProfile;

          draft.detail = profile;
        }

        draft.list = fileData;
        break;
      }

      case Types.CUSTOMER_FILES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CUSTOMER_FILES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.file);

        draft.detail = action.payload.file;
        draft.customerFileModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.CUSTOMER_FILES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CUSTOMER_FILES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CUSTOMER_FILES_UPDATE_SUCCESS: {
        const { newFile } = action.payload;

        draft.list = state.list.map(file =>
          file.id === newFile.id ? newFile : file
        );

        draft.detail = newFile;
        draft.customerFileModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.CUSTOMER_FILES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CUSTOMER_FILES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CUSTOMER_FILES_DELETE_SUCCESS: {
        const { file_id } = action.payload;

        draft.list = state.list.filter(file => file.id !== file_id);

        draft.detail = {};
        draft.loading = false;
        break;
      }
      case Types.CUSTOMER_FILES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CUSTOMER_FILES_OPEN_MODAL: {
        draft.customerFileModalOpen = true;
        break;
      }
      case Types.CUSTOMER_FILES_CLOSE_MODAL: {
        draft.customerFileModalOpen = false;
        break;
      }

      case Types.CUSTOMER_FILES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case CustomerTypes.CUSTOMERS_RESET_DETAILS: {
        draft.detail = {};
        draft.customerFileModalOpen = false;
        break;
      }

      default:
    }
  });
}
