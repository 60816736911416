import Types from './types';

export function getSupervisorPhones(phones) {
  return {
    type: Types.SUPERVISOR_PHONES_GET,
    payload: { phones },
  };
}

export function createSupervisorPhoneRequest(supervisor_id, phone) {
  return {
    type: Types.SUPERVISOR_PHONES_CREATE_REQUEST,
    payload: { supervisor_id, phone },
  };
}

export function createSupervisorPhoneSuccess(phone) {
  return {
    type: Types.SUPERVISOR_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createSupervisorPhoneFailure() {
  return {
    type: Types.SUPERVISOR_PHONES_CREATE_FAILURE,
  };
}

export function updateSupervisorPhoneRequest(
  supervisor_id,
  phone_id,
  newPhone
) {
  return {
    type: Types.SUPERVISOR_PHONES_UPDATE_REQUEST,
    payload: { supervisor_id, phone_id, newPhone },
  };
}

export function updateSupervisorPhoneSuccess(newPhone) {
  return {
    type: Types.SUPERVISOR_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateSupervisorPhoneFailure() {
  return {
    type: Types.SUPERVISOR_PHONES_UPDATE_FAILURE,
  };
}

export function deleteSupervisorPhoneRequest(supervisor_id, phone_id) {
  return {
    type: Types.SUPERVISOR_PHONES_DELETE_REQUEST,
    payload: { supervisor_id, phone_id },
  };
}

export function deleteSupervisorPhoneSuccess(phone_id) {
  return {
    type: Types.SUPERVISOR_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteSupervisorPhoneFailure() {
  return {
    type: Types.SUPERVISOR_PHONES_DELETE_FAILURE,
  };
}

export function resetListSupervisorPhone() {
  return {
    type: Types.SUPERVISOR_PHONES_RESET_LIST,
  };
}

export function openModalSupervisorPhone(phone_id) {
  return {
    type: Types.SUPERVISOR_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalSupervisorPhone() {
  return {
    type: Types.SUPERVISOR_PHONES_CLOSE_MODAL,
  };
}
