import produce from 'immer';

import InventoryTypes from '../inventories/types';
import Types from './types';

const INITIAL_STATE = {
  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,

  searchItemFilterColumnsOrder: null,
  searchItemFilterSearch: '',
  searchItemRowsPerPage: 10,
  searchItemPage: 0,
  inventoryItemSearchModalOpen: false,

  loading: false,
};

export default function inventoryItems(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.INVENTORY_ITEMS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.INVENTORY_ITEMS_CREATE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.INVENTORY_ITEMS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORY_ITEMS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.INVENTORY_ITEMS_UPDATE_SUCCESS: {
        draft.loading = false;

        break;
      }
      case Types.INVENTORY_ITEMS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORY_ITEMS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.INVENTORY_ITEMS_DELETE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.INVENTORY_ITEMS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.INVENTORY_ITEMS_SEARCH_OPEN_MODAL: {
        draft.inventoryItemSearchModalOpen = true;
        break;
      }
      case Types.INVENTORY_ITEMS_SEARCH_CLOSE_MODAL: {
        draft.inventoryItemSearchModalOpen = false;

        draft.searchItemFilterColumnsOrder = null;
        draft.searchItemFilterSearch = null;
        draft.searchItemRowsPerPage = null;
        draft.searchItemPage = null;

        break;
      }
      case Types.INVENTORY_ITEMS_SET_TABLE_FILTERS: {
        const {
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
          type,
        } = action.payload;

        if (type === 'SEARCH_ITEM') {
          if (filterColumnsOrder) {
            draft.searchItemFilterColumnsOrder = {
              field: filterColumnsOrder?.field,
              direction: filterColumnsOrder?.direction,
            };
          }

          if (typeof filterSearch !== 'undefined') {
            draft.searchItemFilterSearch = filterSearch;
          }

          if (typeof rowsPerPage !== 'undefined') {
            draft.searchItemRowsPerPage = rowsPerPage;
          }

          if (typeof currentPage !== 'undefined') {
            draft.searchItemPage = currentPage;
          }
        } else {
          if (filterColumnsOrder) {
            draft.filterColumnsOrder = {
              field: filterColumnsOrder?.field,
              direction: filterColumnsOrder?.direction,
            };
          }

          if (typeof filterSearch !== 'undefined') {
            draft.filterSearch = filterSearch;
          }

          if (typeof rowsPerPage !== 'undefined') {
            draft.rowsPerPage = rowsPerPage;
          }

          if (typeof currentPage !== 'undefined') {
            draft.page = currentPage;
          }
        }

        if (closeModal) {
          draft.inventoryItemSearchModalOpen = false;
        }

        break;
      }

      case InventoryTypes.INVENTORIES_RESET_DETAILS: {
        draft.inventoryItemSearchModalOpen = false;
        draft.filter = {};
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        draft.rowsPerPage = 10;
        draft.page = 0;

        draft.searchItemFilterColumnsOrder = null;
        draft.searchItemFilterSearch = '';
        draft.searchItemRowsPerPage = 10;
        draft.searchItemPage = 0;

        break;
      }

      default:
    }
  });
}
