import Types from './types';

export function getSellerAddresses(addresses) {
  return {
    type: Types.SELLER_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createSellerAddressRequest(seller_id, address) {
  return {
    type: Types.SELLER_ADDRESSES_CREATE_REQUEST,
    payload: { seller_id, address },
  };
}

export function createSellerAddressSuccess(address) {
  return {
    type: Types.SELLER_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createSellerAddressFailure() {
  return {
    type: Types.SELLER_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateSellerAddressRequest(seller_id, address_id, newAddress) {
  return {
    type: Types.SELLER_ADDRESSES_UPDATE_REQUEST,
    payload: { seller_id, address_id, newAddress },
  };
}

export function updateSellerAddressSuccess(newAddress) {
  return {
    type: Types.SELLER_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateSellerAddressFailure() {
  return {
    type: Types.SELLER_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteSellerAddressRequest(seller_id, address_id) {
  return {
    type: Types.SELLER_ADDRESSES_DELETE_REQUEST,
    payload: { seller_id, address_id },
  };
}

export function deleteSellerAddressSuccess(address_id) {
  return {
    type: Types.SELLER_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteSellerAddressFailure() {
  return {
    type: Types.SELLER_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListSellerAddress() {
  return {
    type: Types.SELLER_ADDRESSES_RESET_LIST,
  };
}

export function openModalSellerAddress(address_id) {
  return {
    type: Types.SELLER_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalSellerAddress() {
  return {
    type: Types.SELLER_ADDRESSES_CLOSE_MODAL,
  };
}
