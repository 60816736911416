const Types = {
  PRODUCTS_CREATE_REQUEST: '@products/CREATE_PRODUCT_REQUEST',
  PRODUCTS_CREATE_SUCCESS: '@products/CREATE_PRODUCT_SUCCESS',
  PRODUCTS_CREATE_FAILURE: '@products/CREATE_PRODUCT_FAILURE',

  PRODUCTS_UPDATE_REQUEST: '@products/UPDATE_PRODUCT_REQUEST',
  PRODUCTS_UPDATE_SUCCESS: '@products/UPDATE_PRODUCT_SUCCESS',
  PRODUCTS_UPDATE_FAILURE: '@products/UPDATE_PRODUCT_FAILURE',

  PRODUCTS_DELETE_REQUEST: '@products/DELETE_PRODUCT_REQUEST',
  PRODUCTS_DELETE_SUCCESS: '@products/DELETE_PRODUCT_SUCCESS',
  PRODUCTS_DELETE_FAILURE: '@products/DELETE_PRODUCT_FAILURE',

  PRODUCTS_GET_DETAILS_REQUEST: '@products/GET_DETAILS_PRODUCT_REQUEST',
  PRODUCTS_GET_DETAILS_SUCCESS: '@products/GET_DETAILS_PRODUCT_SUCCESS',
  PRODUCTS_GET_DETAILS_FAILURE: '@products/GET_DETAILS_PRODUCT_FAILURE',

  PRODUCTS_RESET_LIST: '@products/RESET_LIST_PRODUCTS',
  PRODUCTS_RESET_DETAILS: '@products/RESET_DETAILS_PRODUCT',

  PRODUCTS_DESCRIPTION_OPEN_MODAL: '@products/OPEN_MODAL_PRODUCT_DESCRIPTION',
  PRODUCTS_DESCRIPTION_CLOSE_MODAL: '@products/CLOSE_MODAL_PRODUCT_DESCRIPTION',

  PRODUCTS_CLEAR_NEW_BRAND: '@products/CLEAR_NEW_PRODUCT_BRAND',
  PRODUCTS_CLEAR_NEW_DEPARTMENT: '@products/CLEAR_NEW_PRODUCT_DEPARTMENT',
  PRODUCTS_CLEAR_NEW_UNIT_MEASURE: '@products/CLEAR_NEW_PRODUCT_UNIT_MEASURE',

  PRODUCTS_SET_SAVING_ON_SUB_RESOURCE:
    '@products/SET_SAVING_ON_SUB_RESOURCE_PRODUCT',
  PRODUCTS_RESET_SUB_RESOURCE_DATA: '@products/RESET_SUB_RESOURCE_DATA_PRODUCT',
  PRODUCTS_OPEN_FILE_DIALOG: '@products/OPEN_PRODUCT_FILE_DIALOG',

  PRODUCTS_SHARE_OPEN_MODAL: '@products/OPEN_MODAL_PRODUCT_SHARE',
  PRODUCTS_SHARE_CLOSE_MODAL: '@products/CLOSE_MODAL_PRODUCT_SHARE',

  PRODUCTS_FILTER_OPEN_MODAL: '@products/OPEN_MODAL_FILTER_PRODUCT',
  PRODUCTS_FILTER_CLOSE_MODAL: '@products/CLOSE_MODAL_FILTER_PRODUCT',
  PRODUCTS_SET_TABLE_FILTERS: '@products/SET_TABLE_FILTERS_PRODUCT',
  PRODUCTS_SET_REQUEST_ERROR: '@products/SET_REQUEST_ERROR_PRODUCT',
};

export default Types;
