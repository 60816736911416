import React from 'react';

import PropTypes from 'prop-types';

import { TrendingDown } from './styles';

export default function DecreaseIcon({ type }) {
  return <TrendingDown type={type} />;
}

DecreaseIcon.propTypes = {
  type: PropTypes.string,
};

DecreaseIcon.defaultProps = {
  type: '',
};
