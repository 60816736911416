import produce from 'immer';

import EmployeeTypes from '../employees/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  employeePhoneModalOpen: false,
};

export default function employeePhones(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.EMPLOYEE_PHONES_GET: {
        draft.list = action.payload.phones;
        break;
      }

      case Types.EMPLOYEE_PHONES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEE_PHONES_CREATE_SUCCESS: {
        const { is_main } = action.payload.phone;

        if (is_main) {
          draft.list = draft.list.map(phone => ({ ...phone, is_main: false }));
        }

        draft.list.unshift(action.payload.phone);
        draft.loading = false;
        draft.employeePhoneModalOpen = false;
        break;
      }
      case Types.EMPLOYEE_PHONES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEE_PHONES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEE_PHONES_UPDATE_SUCCESS: {
        const { newPhone } = action.payload;
        const { is_main } = newPhone;
        let data = [];

        if (is_main) {
          const removeMain = draft.list.map(phone => ({
            ...phone,
            is_main: false,
          }));

          data = removeMain;
        } else {
          data = state.list;
        }

        draft.list = data.map(phone => {
          return phone.id === newPhone.id ? newPhone : phone;
        });

        draft.detail = {};
        draft.loading = false;
        draft.employeePhoneModalOpen = false;
        break;
      }
      case Types.EMPLOYEE_PHONES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEE_PHONES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEE_PHONES_DELETE_SUCCESS: {
        const { phone_id } = action.payload;

        draft.list = state.list.filter(phone => {
          return phone.id !== phone_id;
        });

        if (draft.detail.id && phone_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.EMPLOYEE_PHONES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEE_PHONES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.EMPLOYEE_PHONES_OPEN_MODAL: {
        const { phone_id } = action.payload;

        draft.detail = {};

        if (phone_id) {
          [draft.detail] = draft.list.filter(phone => phone.id === phone_id);
        }

        draft.employeePhoneModalOpen = true;
        break;
      }
      case Types.EMPLOYEE_PHONES_CLOSE_MODAL: {
        draft.employeePhoneModalOpen = false;
        draft.detail = {};
        break;
      }

      case EmployeeTypes.EMPLOYEES_RESET_DETAILS: {
        draft.employeePhoneModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
