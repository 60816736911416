import React from 'react';

import PropTypes from 'prop-types';

import { Grid, IconButton, Tooltip } from '@material-ui/core';

import { CloseIcon } from '~/components/Icons';
import IntlMessages from '~/utils/IntlMessages';

import {
  Modal,
  Container,
  Content,
  ButtonContainer,
  Title,
  HeaderContainer,
} from './styles';

export default function CustomModal({
  title,
  size,
  children,
  open,
  onClose,
  responsible,
  isInsideResponsible,
}) {
  const isResponsible = responsible ? 'true' : 'false';
  const insideResponsible = isInsideResponsible ? 'true' : 'false';

  return (
    <Modal open={open} onBackdropClick={onClose}>
      <Container size={size} responsible={isResponsible}>
        <HeaderContainer>
          <Grid item xs={2} />

          <Grid item xs={8}>
            <Title>{title}</Title>
          </Grid>

          <Grid item xs={2}>
            <ButtonContainer>
              <Tooltip arrow title={<IntlMessages id="buttons.close" />}>
                <IconButton onClick={onClose}>
                  <CloseIcon type="modal" />
                </IconButton>
              </Tooltip>
            </ButtonContainer>
          </Grid>
        </HeaderContainer>

        <Content
          responsible={isResponsible}
          inside_responsible={insideResponsible}
        >
          {children}
        </Content>
      </Container>
    </Modal>
  );
}

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  size: PropTypes.string,
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  responsible: PropTypes.bool,
  isInsideResponsible: PropTypes.bool,
};

CustomModal.defaultProps = {
  size: 'medium',
  responsible: false,
  isInsideResponsible: false,
};
