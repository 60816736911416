import Types from './types';

export function updateUserRequest(newUser, resetForm) {
  return {
    type: Types.USER_UPDATE_REQUEST,
    payload: { newUser, resetForm },
  };
}

export function updateUserSuccess(newUser) {
  return {
    type: Types.USER_UPDATE_SUCCESS,
    payload: { newUser },
  };
}

export function updateUserFailure() {
  return {
    type: Types.USER_UPDATE_FAILURE,
  };
}

export function getDetailsUserRequest() {
  return {
    type: Types.USER_GET_DETAILS_REQUEST,
  };
}

export function getDetailsUserSuccess(user) {
  return {
    type: Types.USER_GET_DETAILS_SUCCESS,
    payload: { user },
  };
}

export function getDetailsUserFailure() {
  return {
    type: Types.USER_GET_DETAILS_FAILURE,
  };
}

export function resetDetailsUser() {
  return {
    type: Types.USER_RESET_DETAILS,
  };
}
