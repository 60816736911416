import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import { todayDate } from '~/utils/dateTime';
import IntlMessages from '~/utils/IntlMessages';

import {
  openModalOrderBill,
  createOrderBillRequest,
  getOrderBills,
  resetListOrderBill,
} from '../orderBills/actions';
import {
  getOrderItems,
  openModalOrderItem,
  resetListOrderItem,
} from '../orderItems/actions';
import { resetListOrderPriceListItems } from '../orderPriceListItems/actions';
import {
  createOrderSuccess,
  createOrderFailure,
  updateOrderSuccess,
  updateOrderFailure,
  deleteOrderSuccess,
  deleteOrderFailure,
  getDetailsOrderSuccess,
  getDetailsOrderFailure,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* createOrder({ payload }) {
  try {
    const { order, subResourceType } = payload;

    const response = yield call(api.post, 'orders', order);

    yield put(createOrderSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/order/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOrder({ payload }) {
  try {
    const { id, newOrder, updatingItem, updatingDetail } = payload;

    const response = yield call(api.put, `orders/${id}`, newOrder);

    yield put(updateOrderSuccess(response.data));

    const orders = yield select(state => state.orders);
    const orderBills = yield select(state => state.orderBills);

    if (!updatingItem) {
      toast.success(<IntlMessages id="messages.edit-success" />);

      if (
        orders.detail?.total > 0 &&
        orders.detail?.situation !== 'FINISHED' &&
        updatingDetail
      ) {
        yield put(openModalOrderBill());

        if (orderBills.list.length < 1) {
          const { today } = todayDate();

          yield put(createOrderBillRequest(id, { today }, false));
        }
      }
    }
  } catch (err) {
    yield put(updateOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOrder({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `orders/${id}`);

    yield put(deleteOrderSuccess(isFromDetail));

    history.push('/orders');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.orders);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isDeletingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(deleteOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsOrder({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `orders/${id}`);

    const { items, bills, ...rest } = response.data;

    yield put(getOrderItems(items));

    yield put(getOrderBills(bills));

    yield put(getDetailsOrderSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.orders
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'ITEM') {
        yield put(openModalOrderItem());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsOrder() {
  yield put(resetListOrderItem());
  yield put(resetListOrderPriceListItems());
  yield put(resetListOrderBill());
}

export default all([
  takeLatest(Types.ORDERS_CREATE_REQUEST, createOrder),
  takeLatest(Types.ORDERS_UPDATE_REQUEST, updateOrder),
  takeLatest(Types.ORDERS_DELETE_REQUEST, deleteOrder),
  takeLatest(Types.ORDERS_GET_DETAILS_REQUEST, getDetailsOrder),
  takeLatest(Types.ORDERS_RESET_DETAILS, resetDetailsOrder),
]);
