import styled from 'styled-components';

import { FilterList as MuiFilterList } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'table':
      styles = `
        color: ${colors.darkRegular};
        width: 25px;
        height: 25px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const FilterList = styled(MuiFilterList)`
  ${({ type }) => defineStyles(type)}
`;
