import Types from './types';

export function getNcmsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.NCMS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getNcmsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.NCMS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getNcmsFailure() {
  return {
    type: Types.NCMS_GET_FAILURE,
  };
}

export function resetListNcms() {
  return {
    type: Types.NCMS_RESET_LIST,
  };
}

export function openModalNcmFilter() {
  return {
    type: Types.NCMS_FILTER_OPEN_MODAL,
  };
}

export function closeModalNcmFilter() {
  return {
    type: Types.NCMS_FILTER_CLOSE_MODAL,
  };
}
