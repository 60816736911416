import React, { forwardRef } from 'react';

import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Delete,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Edit,
  ArrowUpward,
} from '@material-ui/icons';

import colors from '~/theme/colors';

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Check: forwardRef((props, ref) => (
    <Check htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Delete: forwardRef((props, ref) => (
    <Delete htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Export: forwardRef((props, ref) => (
    <SaveAlt htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  Search: forwardRef((props, ref) => (
    <Search htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowUpward htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn htmlColor={colors.darkRegular} ref={ref} {...props} />
  )),
};

export default tableIcons;
