import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
};

export default function allBackupDatabases(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ALL_BACKUP_DATABASES_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ALL_BACKUP_DATABASES_GET_SUCCESS: {
        const customers = action.payload.backupDatabases;

        const data = [];

        customers.map(customer => {
          const lastBackupDatabase = customer.databases[0];

          const parentBackup = {
            ...customer,
            customer_code: customer.types[0].person_code,
            last_database_date: lastBackupDatabase.alteration_date,
            backup_name: lastBackupDatabase.name,
          };

          customer.databases.shift();

          const childBackup = customer.databases.map(backupDatabase => {
            const childBackupItem = {
              id: null,
              name: null,
              total_databases: null,
              first_database_date: null,
              backup_name: backupDatabase.name,
              last_database_date: backupDatabase.alteration_date,
              parent_id: customer.id,
              customer_code: customer.types[0].person_code,
            };

            return childBackupItem;
          });

          data.push(parentBackup);
          data.push(...childBackup);
          return true;
        });

        draft.list = data;

        draft.loading = false;
        break;
      }
      case Types.ALL_BACKUP_DATABASES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ALL_BACKUP_DATABASES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        break;
      }

      default:
    }
  });
}
