const Types = {
  EMPLOYEES_GET_REQUEST: '@employees/GET_EMPLOYEES_REQUEST',
  EMPLOYEES_GET_SUCCESS: '@employees/GET_EMPLOYEES_SUCCESS',
  EMPLOYEES_GET_FAILURE: '@employees/GET_EMPLOYEES_FAILURE',

  EMPLOYEES_CREATE_REQUEST: '@employees/CREATE_EMPLOYEE_REQUEST',
  EMPLOYEES_CREATE_SUCCESS: '@employees/CREATE_EMPLOYEE_SUCCESS',
  EMPLOYEES_CREATE_FAILURE: '@employees/CREATE_EMPLOYEE_FAILURE',

  EMPLOYEES_UPDATE_REQUEST: '@employees/UPDATE_EMPLOYEE_REQUEST',
  EMPLOYEES_UPDATE_SUCCESS: '@employees/UPDATE_EMPLOYEE_SUCCESS',
  EMPLOYEES_UPDATE_FAILURE: '@employees/UPDATE_EMPLOYEE_FAILURE',

  EMPLOYEES_DELETE_REQUEST: '@employees/DELETE_EMPLOYEE_REQUEST',
  EMPLOYEES_DELETE_SUCCESS: '@employees/DELETE_EMPLOYEE_SUCCESS',
  EMPLOYEES_DELETE_FAILURE: '@employees/DELETE_EMPLOYEE_FAILURE',

  EMPLOYEES_BECOME_REQUEST: '@employees/BECOME_EMPLOYEE_REQUEST',
  EMPLOYEES_BECOME_SUCCESS: '@employees/BECOME_EMPLOYEE_SUCCESS',
  EMPLOYEES_BECOME_FAILURE: '@employees/BECOME_EMPLOYEE_FAILURE',

  EMPLOYEES_GET_DETAILS_REQUEST: '@employees/GET_DETAILS_EMPLOYEE_REQUEST',
  EMPLOYEES_GET_DETAILS_SUCCESS: '@employees/GET_DETAILS_EMPLOYEE_SUCCESS',
  EMPLOYEES_GET_DETAILS_FAILURE: '@employees/GET_DETAILS_EMPLOYEE_FAILURE',

  EMPLOYEES_RESET_LIST: '@employees/RESET_LIST_EMPLOYEES',
  EMPLOYEES_RESET_DETAILS: '@employees/RESET_DETAILS_EMPLOYEE',

  EMPLOYEES_SET_SAVING_ON_SUB_RESOURCE:
    '@employees/SET_SAVING_ON_SUB_RESOURCE_EMPLOYEE',
  EMPLOYEES_RESET_SUB_RESOURCE_DATA:
    '@employees/RESET_SUB_RESOURCE_DATA_EMPLOYEE',

  EMPLOYEES_ADD_OPEN_MODAL: '@employees/OPEN_MODAL_ADD_EMPLOYEE',
  EMPLOYEES_ADD_CLOSE_MODAL: '@employees/CLOSE_MODAL_ADD_EMPLOYEE',

  EMPLOYEES_FILTER_OPEN_MODAL: '@employees/OPEN_MODAL_FILTER_EMPLOYEE',
  EMPLOYEES_FILTER_CLOSE_MODAL: '@employees/CLOSE_MODAL_FILTER_EMPLOYEE',

  EMPLOYEES_OBSERVATION_OPEN_MODAL:
    '@employees/OPEN_MODAL_EMPLOYEE_OBSERVATION',
  EMPLOYEES_OBSERVATION_CLOSE_MODAL:
    '@employees/CLOSE_MODAL_EMPLOYEE_OBSERVATION',

  EMPLOYEES_CLEAR_NEW_BRANCH_ACTIVITY:
    '@employees/CLEAR_NEW_EMPLOYEE_BRANCH_ACTIVITY',
  EMPLOYEES_CLEAR_NEW_COVENANT: '@employees/CLEAR_NEW_EMPLOYEE_COVENANT',

  EMPLOYEES_OPEN_FILE_DIALOG: '@employees/OPEN_EMPLOYEE_FILE_DIALOG',
};

export default Types;
