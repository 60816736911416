import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  detail: {},
  requestError: false,
  loading: false,
  departmentFilterModalOpen: false,
  departmentModalOpen: false,
};

export default function departments(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.DEPARTMENTS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.DEPARTMENTS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.departmentFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.DEPARTMENTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.DEPARTMENTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.DEPARTMENTS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.department);
        draft.loading = false;
        draft.departmentModalOpen = false;
        break;
      }
      case Types.DEPARTMENTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.DEPARTMENTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.DEPARTMENTS_UPDATE_SUCCESS: {
        const { newDepartment } = action.payload;

        draft.list = state.list.map(department => {
          return department.id === newDepartment.id
            ? newDepartment
            : department;
        });

        draft.detail = {};
        draft.loading = false;
        draft.departmentModalOpen = false;
        break;
      }
      case Types.DEPARTMENTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.DEPARTMENTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.DEPARTMENTS_DELETE_SUCCESS: {
        draft.list = state.list.filter(department => {
          return department.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.DEPARTMENTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.DEPARTMENTS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.departmentFilterModalOpen = false;
        draft.departmentModalOpen = false;

        draft.filter = {};
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        break;
      }

      case Types.DEPARTMENTS_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = state.list.filter(department => {
            return department.id === id;
          });
        }

        draft.departmentModalOpen = true;
        break;
      }
      case Types.DEPARTMENTS_CLOSE_MODAL: {
        draft.departmentModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.DEPARTMENTS_FILTER_OPEN_MODAL: {
        draft.departmentFilterModalOpen = true;
        break;
      }
      case Types.DEPARTMENTS_FILTER_CLOSE_MODAL: {
        draft.departmentFilterModalOpen = false;
        break;
      }

      default:
    }
  });
}
