import Types from './types';

export function getSellerPhones(phones) {
  return {
    type: Types.SELLER_PHONES_GET,
    payload: { phones },
  };
}

export function createSellerPhoneRequest(seller_id, phone) {
  return {
    type: Types.SELLER_PHONES_CREATE_REQUEST,
    payload: { seller_id, phone },
  };
}

export function createSellerPhoneSuccess(phone) {
  return {
    type: Types.SELLER_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createSellerPhoneFailure() {
  return {
    type: Types.SELLER_PHONES_CREATE_FAILURE,
  };
}

export function updateSellerPhoneRequest(seller_id, phone_id, newPhone) {
  return {
    type: Types.SELLER_PHONES_UPDATE_REQUEST,
    payload: { seller_id, phone_id, newPhone },
  };
}

export function updateSellerPhoneSuccess(newPhone) {
  return {
    type: Types.SELLER_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateSellerPhoneFailure() {
  return {
    type: Types.SELLER_PHONES_UPDATE_FAILURE,
  };
}

export function deleteSellerPhoneRequest(seller_id, phone_id) {
  return {
    type: Types.SELLER_PHONES_DELETE_REQUEST,
    payload: { seller_id, phone_id },
  };
}

export function deleteSellerPhoneSuccess(phone_id) {
  return {
    type: Types.SELLER_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteSellerPhoneFailure() {
  return {
    type: Types.SELLER_PHONES_DELETE_FAILURE,
  };
}

export function resetListSellerPhone() {
  return {
    type: Types.SELLER_PHONES_RESET_LIST,
  };
}

export function openModalSellerPhone(phone_id) {
  return {
    type: Types.SELLER_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalSellerPhone() {
  return {
    type: Types.SELLER_PHONES_CLOSE_MODAL,
  };
}
