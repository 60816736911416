import React from 'react';

import PropTypes from 'prop-types';

import { Expand } from './styles';

export default function ExpandIcon({ type, open }) {
  return <Expand type={type} open={open} />;
}

ExpandIcon.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
};

ExpandIcon.defaultProps = {
  type: '',
  open: false,
};
