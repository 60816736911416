import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,
  chartAccountModalOpen: false,
  newRegister: false,
};

export default function chartAccounts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CHART_ACCOUNTS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.CHART_ACCOUNTS_GET_SUCCESS: {
        draft.list = action.payload.chartAccounts;
        draft.loading = false;
        draft.requestError = false;
        break;
      }
      case Types.CHART_ACCOUNTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CHART_ACCOUNTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CHART_ACCOUNTS_CREATE_SUCCESS: {
        const { chartAccount } = action.payload;
        const chartAccountList = draft.list;
        chartAccountList.push(chartAccount);

        const sortedChartAccountList = chartAccountList.sort((a, b) => {
          if (a.number.length === 4 && b.number.length === 4) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number.length === 8 && b.number.length === 8) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number && b.number) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          return 0;
        });

        draft.list = sortedChartAccountList;
        draft.detail = {};
        draft.loading = false;
        draft.chartAccountModalOpen = false;
        break;
      }
      case Types.CHART_ACCOUNTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CHART_ACCOUNTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CHART_ACCOUNTS_UPDATE_SUCCESS: {
        const { newChartAccount } = action.payload;

        const chartAccountList = draft.list.map(chartAccount => {
          return chartAccount.id === newChartAccount.id
            ? newChartAccount
            : chartAccount;
        });

        const sortedChartAccountList = chartAccountList.sort((a, b) => {
          if (a.number.length === 4 && b.number.length === 4) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number.length === 8 && b.number.length === 8) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number && b.number) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          return 0;
        });

        draft.list = sortedChartAccountList;
        draft.detail = {};
        draft.loading = false;
        draft.chartAccountModalOpen = false;
        break;
      }
      case Types.CHART_ACCOUNTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CHART_ACCOUNTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CHART_ACCOUNTS_DELETE_SUCCESS: {
        const chartAccountList = draft.list.filter(chartAccount => {
          return chartAccount.id !== action.payload.id;
        });

        const sortedChartAccountList = chartAccountList.sort((a, b) => {
          if (a.number.length === 4 && b.number.length === 4) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number.length === 8 && b.number.length === 8) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          if (a.number && b.number) {
            return a.number < b.number && a.number !== b.number ? -1 : 1;
          }

          return 0;
        });

        draft.list = sortedChartAccountList;

        draft.loading = false;
        break;
      }
      case Types.CHART_ACCOUNTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CHART_ACCOUNTS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        break;
      }

      case Types.CHART_ACCOUNTS_OPEN_MODAL: {
        const { id, newRegister } = action.payload;

        draft.newRegister = newRegister;
        draft.detail = {};

        if (id) {
          draft.detail = state.list.find(chartAccount => {
            return chartAccount.id === id;
          });
        }

        draft.chartAccountModalOpen = true;
        break;
      }
      case Types.CHART_ACCOUNTS_CLOSE_MODAL: {
        draft.newRegister = false;
        draft.detail = {};
        draft.chartAccountModalOpen = false;

        break;
      }

      default:
    }
  });
}
