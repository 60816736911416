import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import typography from '~/theme/typography';

const GlobalStyle = createGlobalStyle`

  div.swal2-container {
    z-index: 2000;
  }

  textarea {
    font-family: ${typography.fontFamily};
    padding: 5px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #fff inset;
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }
`;

export default GlobalStyle;
