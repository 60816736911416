import React from 'react';

import PropTypes from 'prop-types';

import { QRCode } from './styles';

export default function QrCodeIcon({ type }) {
  return <QRCode type={type} />;
}

QrCodeIcon.propTypes = {
  type: PropTypes.string,
};

QrCodeIcon.defaultProps = {
  type: '',
};
