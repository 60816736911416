const Types = {
  BILLS_TO_RECEIVE_CREATE_REQUEST:
    '@billsToReceive/CREATE_BILL_TO_RECEIVE_REQUEST',
  BILLS_TO_RECEIVE_CREATE_SUCCESS:
    '@billsToReceive/CREATE_BILL_TO_RECEIVE_SUCCESS',
  BILLS_TO_RECEIVE_CREATE_FAILURE:
    '@billsToReceive/CREATE_BILL_TO_RECEIVE_FAILURE',

  BILLS_TO_RECEIVE_UPDATE_REQUEST:
    '@billsToReceive/UPDATE_BILL_TO_RECEIVE_REQUEST',
  BILLS_TO_RECEIVE_UPDATE_SUCCESS:
    '@billsToReceive/UPDATE_BILL_TO_RECEIVE_SUCCESS',
  BILLS_TO_RECEIVE_UPDATE_FAILURE:
    '@billsToReceive/UPDATE_BILL_TO_RECEIVE_FAILURE',

  BILLS_TO_RECEIVE_SETTLEMENT_REQUEST:
    '@billsToReceive/SETTLEMENT_BILL_TO_RECEIVE_REQUEST',
  BILLS_TO_RECEIVE_SETTLEMENT_SUCCESS:
    '@billsToReceive/SETTLEMENT_BILL_TO_RECEIVE_SUCCESS',
  BILLS_TO_RECEIVE_SETTLEMENT_FAILURE:
    '@billsToReceive/SETTLEMENT_BILL_TO_RECEIVE_FAILURE',

  BILLS_TO_RECEIVE_DELETE_REQUEST:
    '@billsToReceive/DELETE_BILL_TO_RECEIVE_REQUEST',
  BILLS_TO_RECEIVE_DELETE_SUCCESS:
    '@billsToReceive/DELETE_BILL_TO_RECEIVE_SUCCESS',
  BILLS_TO_RECEIVE_DELETE_FAILURE:
    '@billsToReceive/DELETE_BILL_TO_RECEIVE_FAILURE',

  BILLS_TO_RECEIVE_GET_DETAILS_REQUEST:
    '@billsToReceive/GET_DETAILS_BILL_TO_RECEIVE_REQUEST',
  BILLS_TO_RECEIVE_GET_DETAILS_SUCCESS:
    '@billsToReceive/GET_DETAILS_BILL_TO_RECEIVE_SUCCESS',
  BILLS_TO_RECEIVE_GET_DETAILS_FAILURE:
    '@billsToReceive/GET_DETAILS_BILL_TO_RECEIVE_FAILURE',

  BILLS_TO_RECEIVE_RESET_LIST: '@billsToReceive/RESET_LIST_BILLS_TO_RECEIVE',
  BILLS_TO_RECEIVE_RESET_DETAILS:
    '@billsToReceive/RESET_DETAILS_BILL_TO_RECEIVE',

  BILLS_TO_RECEIVE_SET_SAVING_ON_SUB_RESOURCE:
    '@billsToReceive/SET_SAVING_ON_SUB_RESOURCE_BILL_TO_RECEIVE',
  BILLS_TO_RECEIVE_RESET_SUB_RESOURCE_DATA:
    '@billsToReceive/RESET_SUB_RESOURCE_DATA_BILL_TO_RECEIVE',

  BILLS_TO_RECEIVE_SETTLEMENT_OPEN_MODAL:
    '@billsToReceive/OPEN_MODAL_SETTLEMENT_BILL_TO_RECEIVE',
  BILLS_TO_RECEIVE_SETTLEMENT_CLOSE_MODAL:
    '@billsToReceive/CLOSE_MODAL_SETTLEMENT_BILL_TO_RECEIVE',

  BILLS_TO_RECEIVE_FILTER_OPEN_MODAL:
    '@billsToReceive/OPEN_MODAL_FILTER_BILL_TO_RECEIVE',
  BILLS_TO_RECEIVE_FILTER_CLOSE_MODAL:
    '@billsToReceive/CLOSE_MODAL_FILTER_BILL_TO_RECEIVE',

  BILLS_TO_RECEIVE_OBSERVATION_OPEN_MODAL:
    '@billsToReceive/OPEN_MODAL_BILL_TO_RECEIVE_OBSERVATION',
  BILLS_TO_RECEIVE_OBSERVATION_CLOSE_MODAL:
    '@billsToReceive/CLOSE_MODAL_BILL_TO_RECEIVE_OBSERVATION',

  BILLS_TO_RECEIVE_SET_TABLE_FILTERS:
    '@billsToReceive/SET_TABLE_FILTERS_BILL_TO_RECEIVE',
  BILLS_TO_RECEIVE_SET_INITIAL_TABLE_FILTERS:
    '@billsToReceive/SET_INITIAL_TABLE_FILTERS_BILL_TO_RECEIVE',
  BILLS_TO_RECEIVE_SET_REQUEST_ERROR:
    '@billsToReceive/SET_REQUEST_ERROR_BILL_TO_RECEIVE',
  BILLS_TO_RECEIVE_SET_CREATING_FROM:
    '@billsToReceive/SET_CREATING_FROM_BILL_TO_RECEIVE',
};

export default Types;
