const Types = {
  PRODUCT_PRICE_LIST_ITEMS_GET: '@product/GET_PRODUCT_PRICE_LIST_ITEMS',

  PRODUCT_PRICE_LIST_ITEMS_UPDATE_REQUEST:
    '@product/UPDATE_PRICE_LIST_ITEM_REQUEST',
  PRODUCT_PRICE_LIST_ITEMS_UPDATE_SUCCESS:
    '@product/UPDATE_PRICE_LIST_ITEM_SUCCESS',
  PRODUCT_PRICE_LIST_ITEMS_UPDATE_FAILURE:
    '@product/UPDATE_PRICE_LIST_ITEM_FAILURE',

  PRODUCT_PRICE_LIST_ITEMS_RESET_LIST:
    '@product/RESET_LIST_PRODUCT_PRICE_LIST_ITEM',

  PRODUCT_PRICE_LIST_ITEMS_OPEN_MODAL:
    '@product/OPEN_MODAL_PRODUCT_PRICE_LIST_ITEM',
  PRODUCT_PRICE_LIST_ITEMS_CLOSE_MODAL:
    '@product/CLOSE_MODAL_PRODUCT_PRICE_LIST_ITEM',
};

export default Types;
