const Types = {
  COMPANY_ADDRESSES_GET: '@company/GET_COMPANY_ADDRESSES',

  COMPANY_ADDRESSES_CREATE_REQUEST: '@company/CREATE_COMPANY_ADDRESS_REQUEST',
  COMPANY_ADDRESSES_CREATE_SUCCESS: '@company/CREATE_COMPANY_ADDRESS_SUCCESS',
  COMPANY_ADDRESSES_CREATE_FAILURE: '@company/CREATE_COMPANY_ADDRESS_FAILURE',

  COMPANY_ADDRESSES_UPDATE_REQUEST: '@company/UPDATE_COMPANY_ADDRESS_REQUEST',
  COMPANY_ADDRESSES_UPDATE_SUCCESS: '@company/UPDATE_COMPANY_ADDRESS_SUCCESS',
  COMPANY_ADDRESSES_UPDATE_FAILURE: '@company/UPDATE_COMPANY_ADDRESS_FAILURE',

  COMPANY_ADDRESSES_DELETE_REQUEST: '@company/DELETE_COMPANY_ADDRESS_REQUEST',
  COMPANY_ADDRESSES_DELETE_SUCCESS: '@company/DELETE_COMPANY_ADDRESS_SUCCESS',
  COMPANY_ADDRESSES_DELETE_FAILURE: '@company/DELETE_COMPANY_ADDRESS_FAILURE',

  COMPANY_ADDRESSES_RESET_LIST: '@company/RESET_LIST_COMPANY_ADDRESS',

  COMPANY_ADDRESSES_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_ADDRESS',
  COMPANY_ADDRESSES_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_ADDRESS',
};

export default Types;
