const Types = {
  CULTURES_GET_REQUEST: '@cultures/GET_CULTURES_REQUEST',
  CULTURES_GET_SUCCESS: '@cultures/GET_CULTURES_SUCCESS',
  CULTURES_GET_FAILURE: '@cultures/GET_CULTURES_FAILURE',

  CULTURES_CREATE_REQUEST: '@cultures/CREATE_CULTURE_REQUEST',
  CULTURES_CREATE_SUCCESS: '@cultures/CREATE_CULTURE_SUCCESS',
  CULTURES_CREATE_FAILURE: '@cultures/CREATE_CULTURE_FAILURE',

  CULTURES_UPDATE_REQUEST: '@cultures/UPDATE_CULTURE_REQUEST',
  CULTURES_UPDATE_SUCCESS: '@cultures/UPDATE_CULTURE_SUCCESS',
  CULTURES_UPDATE_FAILURE: '@cultures/UPDATE_CULTURE_FAILURE',

  CULTURES_DELETE_REQUEST: '@cultures/DELETE_CULTURE_REQUEST',
  CULTURES_DELETE_SUCCESS: '@cultures/DELETE_CULTURE_SUCCESS',
  CULTURES_DELETE_FAILURE: '@cultures/DELETE_CULTURE_FAILURE',

  CULTURES_RESET_LIST: '@cultures/RESET_LIST_CULTURES',

  CULTURES_OPEN_MODAL: '@cultures/OPEN_MODAL_CULTURE',
  CULTURES_CLOSE_MODAL: '@cultures/CLOSE_MODAL_CULTURE',
};

export default Types;
