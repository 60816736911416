import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getChartAccountsSuccess,
  getChartAccountsFailure,
  createChartAccountSuccess,
  createChartAccountFailure,
  deleteChartAccountSuccess,
  deleteChartAccountFailure,
  updateChartAccountSuccess,
  updateChartAccountFailure,
} from './actions';
import Types from './types';

export function* getChartAccounts() {
  try {
    const response = yield call(api.get, 'chart-accounts');

    yield put(getChartAccountsSuccess(response.data));
  } catch (err) {
    yield put(getChartAccountsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createChartAccount({ payload }) {
  try {
    const { chartAccount } = payload;

    const response = yield call(api.post, 'chart-accounts', chartAccount);

    yield put(createChartAccountSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createChartAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateChartAccount({ payload }) {
  try {
    const { id, newChartAccount } = payload;

    const response = yield call(
      api.put,
      `chart-accounts/${id}`,
      newChartAccount
    );

    yield put(updateChartAccountSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateChartAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteChartAccount({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `chart-accounts/${id}`);

    yield put(deleteChartAccountSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteChartAccountFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.CHART_ACCOUNTS_GET_REQUEST, getChartAccounts),
  takeLatest(Types.CHART_ACCOUNTS_CREATE_REQUEST, createChartAccount),
  takeLatest(Types.CHART_ACCOUNTS_UPDATE_REQUEST, updateChartAccount),
  takeLatest(Types.CHART_ACCOUNTS_DELETE_REQUEST, deleteChartAccount),
]);
