import Types from './types';

export function getProductsFailure() {
  return {
    type: Types.PRODUCTS_GET_FAILURE,
  };
}

export function createProductRequest(product, subResourceType = null) {
  return {
    type: Types.PRODUCTS_CREATE_REQUEST,
    payload: { product, subResourceType },
  };
}

export function createProductSuccess(product) {
  return {
    type: Types.PRODUCTS_CREATE_SUCCESS,
    payload: { product },
  };
}

export function createProductFailure() {
  return {
    type: Types.PRODUCTS_CREATE_FAILURE,
  };
}

export function updateProductRequest(id, newProduct) {
  return {
    type: Types.PRODUCTS_UPDATE_REQUEST,
    payload: { id, newProduct },
  };
}

export function updateProductSuccess(newProduct) {
  return {
    type: Types.PRODUCTS_UPDATE_SUCCESS,
    payload: { newProduct },
  };
}

export function updateProductFailure() {
  return {
    type: Types.PRODUCTS_UPDATE_FAILURE,
  };
}

export function deleteProductRequest({
  id,
  fetchList = null,
  isFromDetail = false,
}) {
  return {
    type: Types.PRODUCTS_DELETE_REQUEST,
    payload: { id, fetchList, isFromDetail },
  };
}

export function deleteProductSuccess(isFromDetail) {
  return {
    type: Types.PRODUCTS_DELETE_SUCCESS,
    payload: { isFromDetail },
  };
}

export function deleteProductFailure() {
  return {
    type: Types.PRODUCTS_DELETE_FAILURE,
  };
}

export function getDetailsProductRequest(id) {
  return {
    type: Types.PRODUCTS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsProductSuccess(product) {
  return {
    type: Types.PRODUCTS_GET_DETAILS_SUCCESS,
    payload: { product },
  };
}

export function getDetailsProductFailure() {
  return {
    type: Types.PRODUCTS_GET_DETAILS_FAILURE,
  };
}

export function resetListProducts() {
  return {
    type: Types.PRODUCTS_RESET_LIST,
  };
}

export function resetDetailsProduct() {
  return {
    type: Types.PRODUCTS_RESET_DETAILS,
  };
}

export function openModalDescriptionProduct() {
  return {
    type: Types.PRODUCTS_DESCRIPTION_OPEN_MODAL,
  };
}

export function closeModalDescriptionProduct() {
  return {
    type: Types.PRODUCTS_DESCRIPTION_CLOSE_MODAL,
  };
}

export function clearNewProductBrand() {
  return {
    type: Types.PRODUCTS_CLEAR_NEW_BRAND,
  };
}

export function clearNewProductDepartment() {
  return {
    type: Types.PRODUCTS_CLEAR_NEW_DEPARTMENT,
  };
}

export function clearNewProductUnitMeasure() {
  return {
    type: Types.PRODUCTS_CLEAR_NEW_UNIT_MEASURE,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.PRODUCTS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.PRODUCTS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.PRODUCTS_RESET_SUB_RESOURCE_DATA,
  };
}

export function openModalProductShare() {
  return {
    type: Types.PRODUCTS_SHARE_OPEN_MODAL,
  };
}

export function closeModalProductShare() {
  return {
    type: Types.PRODUCTS_SHARE_CLOSE_MODAL,
  };
}

export function setProductTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
  searchThroughTable,
  listLength,
}) {
  return {
    type: Types.PRODUCTS_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      searchThroughTable,
      listLength,
    },
  };
}

export function setProductRequestError() {
  return {
    type: Types.PRODUCTS_SET_REQUEST_ERROR,
  };
}

export function openModalProductFilter() {
  return {
    type: Types.PRODUCTS_FILTER_OPEN_MODAL,
  };
}

export function closeModalProductFilter() {
  return {
    type: Types.PRODUCTS_FILTER_CLOSE_MODAL,
  };
}
