import Types from './types';

export function getTaxSituationsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.TAX_SITUATIONS_GET_REQUEST,
    payload: { queryParams, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getTaxSituationsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.TAX_SITUATIONS_GET_SUCCESS,
    payload: {
      data,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getTaxSituationsFailure() {
  return {
    type: Types.TAX_SITUATIONS_GET_FAILURE,
  };
}

export function openModalTaxSituationFilter() {
  return {
    type: Types.TAX_SITUATIONS_FILTER_OPEN_MODAL,
  };
}

export function closeModalTaxSituationFilter() {
  return {
    type: Types.TAX_SITUATIONS_FILTER_CLOSE_MODAL,
  };
}

export function resetListTaxSituations() {
  return {
    type: Types.TAX_SITUATIONS_RESET_LIST,
  };
}
