import React from 'react';

import PropTypes from 'prop-types';

import { LocationOn } from './styles';

export default function LocationOnIcon({ type }) {
  return <LocationOn type={type} />;
}

LocationOnIcon.propTypes = {
  type: PropTypes.string,
};

LocationOnIcon.defaultProps = {
  type: '',
};
