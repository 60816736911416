import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getStatesSuccess, getStatesFailure } from './actions';
import Types from './types';

export function* getStates({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `states${queryParams}`);

    yield put(getStatesSuccess({ data, filterColumnsOrder, filterSearch }));
  } catch (err) {
    yield put(getStatesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([takeLatest(Types.STATES_GET_REQUEST, getStates)]);
