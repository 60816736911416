import { authRoutes } from './categories/auth';
import { contactsRoutes } from './categories/contacts';
import { financialRoutes } from './categories/financial';
import { fiscalRoutes } from './categories/fiscal';
import { landingRoutes } from './categories/landing';
import { managerialRoutes } from './categories/managerial';
import { movementsRoutes } from './categories/movements';
import { privateRoutes } from './categories/private';
import { productsRoutes } from './categories/products';
import { publicRoutes } from './categories/publicRoutes';
import { regionRoutes } from './categories/regions';
import { reportsRoutes } from './categories/reports';
import { roadmapRoutes } from './categories/roadmap';
import { toolsRoutes } from './categories/tools';

const others = [
  movementsRoutes,
  contactsRoutes,
  productsRoutes,
  fiscalRoutes,
  financialRoutes,
  roadmapRoutes,
  regionRoutes,
  managerialRoutes,
  reportsRoutes,
  toolsRoutes,
];

export const dashboard = [privateRoutes, ...others];

export const auth = [authRoutes];

export const landing = [landingRoutes];

export const publicRoute = [publicRoutes];

export default others;
