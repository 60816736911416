import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  cultureModalOpen: false,
};

export default function cultures(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CULTURES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.CULTURES_GET_SUCCESS: {
        const { data, filterColumnsOrder, filterSearch } = action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }
        break;
      }
      case Types.CULTURES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CULTURES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CULTURES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.culture);
        draft.loading = false;
        draft.cultureModalOpen = false;
        break;
      }
      case Types.CULTURES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CULTURES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CULTURES_UPDATE_SUCCESS: {
        const { newCulture } = action.payload;

        draft.list = state.list.map(culture => {
          return culture.id === newCulture.id ? newCulture : culture;
        });

        draft.detail = {};
        draft.loading = false;
        draft.cultureModalOpen = false;
        break;
      }
      case Types.CULTURES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CULTURES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CULTURES_DELETE_SUCCESS: {
        draft.list = state.list.filter(culture => {
          return culture.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.CULTURES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CULTURES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;

        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        break;
      }

      case Types.CULTURES_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = state.list.filter(culture => {
            return culture.id === id;
          });
        }

        draft.cultureModalOpen = true;
        break;
      }
      case Types.CULTURES_CLOSE_MODAL: {
        draft.cultureModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
