import Types from './types';

export function getSupervisorEmails(emails) {
  return {
    type: Types.SUPERVISOR_EMAILS_GET,
    payload: { emails },
  };
}

export function createSupervisorEmailRequest(supervisor_id, email) {
  return {
    type: Types.SUPERVISOR_EMAILS_CREATE_REQUEST,
    payload: { supervisor_id, email },
  };
}

export function createSupervisorEmailSuccess(email) {
  return {
    type: Types.SUPERVISOR_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createSupervisorEmailFailure() {
  return {
    type: Types.SUPERVISOR_EMAILS_CREATE_FAILURE,
  };
}

export function updateSupervisorEmailRequest(
  supervisor_id,
  email_id,
  newEmail
) {
  return {
    type: Types.SUPERVISOR_EMAILS_UPDATE_REQUEST,
    payload: { supervisor_id, email_id, newEmail },
  };
}

export function updateSupervisorEmailSuccess(newEmail) {
  return {
    type: Types.SUPERVISOR_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateSupervisorEmailFailure() {
  return {
    type: Types.SUPERVISOR_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteSupervisorEmailRequest(supervisor_id, email_id) {
  return {
    type: Types.SUPERVISOR_EMAILS_DELETE_REQUEST,
    payload: { supervisor_id, email_id },
  };
}

export function deleteSupervisorEmailSuccess(email_id) {
  return {
    type: Types.SUPERVISOR_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteSupervisorEmailFailure() {
  return {
    type: Types.SUPERVISOR_EMAILS_DELETE_FAILURE,
  };
}

export function resetListSupervisorEmail() {
  return {
    type: Types.SUPERVISOR_EMAILS_RESET_LIST,
  };
}

export function openModalSupervisorEmail(email_id) {
  return {
    type: Types.SUPERVISOR_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalSupervisorEmail() {
  return {
    type: Types.SUPERVISOR_EMAILS_CLOSE_MODAL,
  };
}
