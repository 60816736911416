import React from 'react';
import { PieChart } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const Reports = async(() => import('~/pages/Reports'));

export const reportsRoutes = {
  id: <IntlMessages id="sidebar.reports" />,
  path: '/reports',
  icon: <PieChart />,
  component: Reports,
  children: null,
};
