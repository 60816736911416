import Types from './types';

export function getEmployeesRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.EMPLOYEES_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getEmployeesSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.EMPLOYEES_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getEmployeesFailure() {
  return {
    type: Types.EMPLOYEES_GET_FAILURE,
  };
}

export function createEmployeeRequest(employee, subResourceType = null) {
  return {
    type: Types.EMPLOYEES_CREATE_REQUEST,
    payload: { employee, subResourceType },
  };
}

export function createEmployeeSuccess(employee) {
  return {
    type: Types.EMPLOYEES_CREATE_SUCCESS,
    payload: { employee },
  };
}

export function createEmployeeFailure() {
  return {
    type: Types.EMPLOYEES_CREATE_FAILURE,
  };
}

export function updateEmployeeRequest(id, newEmployee) {
  return {
    type: Types.EMPLOYEES_UPDATE_REQUEST,
    payload: { id, newEmployee },
  };
}

export function updateEmployeeSuccess(newEmployee) {
  return {
    type: Types.EMPLOYEES_UPDATE_SUCCESS,
    payload: { newEmployee },
  };
}

export function updateEmployeeFailure() {
  return {
    type: Types.EMPLOYEES_UPDATE_FAILURE,
  };
}

export function deleteEmployeeRequest(id) {
  return {
    type: Types.EMPLOYEES_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteEmployeeSuccess(id) {
  return {
    type: Types.EMPLOYEES_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteEmployeeFailure() {
  return {
    type: Types.EMPLOYEES_DELETE_FAILURE,
  };
}

export function becomeEmployeeRequest(id) {
  return {
    type: Types.EMPLOYEES_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeEmployeeSuccess(employee) {
  return {
    type: Types.EMPLOYEES_BECOME_SUCCESS,
    payload: { employee },
  };
}

export function becomeEmployeeFailure() {
  return {
    type: Types.EMPLOYEES_BECOME_FAILURE,
  };
}

export function getDetailsEmployeeRequest(id) {
  return {
    type: Types.EMPLOYEES_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsEmployeeSuccess(employee) {
  return {
    type: Types.EMPLOYEES_GET_DETAILS_SUCCESS,
    payload: { employee },
  };
}

export function getDetailsEmployeeFailure() {
  return {
    type: Types.EMPLOYEES_GET_DETAILS_FAILURE,
  };
}

export function resetListEmployees() {
  return {
    type: Types.EMPLOYEES_RESET_LIST,
  };
}
export function resetDetailsEmployee() {
  return {
    type: Types.EMPLOYEES_RESET_DETAILS,
  };
}

export function openModalAddEmployee() {
  return {
    type: Types.EMPLOYEES_ADD_OPEN_MODAL,
  };
}

export function closeModalAddEmployee() {
  return {
    type: Types.EMPLOYEES_ADD_CLOSE_MODAL,
  };
}

export function openModalEmployeeObservation() {
  return {
    type: Types.EMPLOYEES_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalEmployeeObservation() {
  return {
    type: Types.EMPLOYEES_OBSERVATION_CLOSE_MODAL,
  };
}

export function clearNewEmployeeBranchActivity() {
  return {
    type: Types.EMPLOYEES_CLEAR_NEW_BRANCH_ACTIVITY,
  };
}

export function clearNewEmployeeCovenant() {
  return {
    type: Types.EMPLOYEES_CLEAR_NEW_COVENANT,
  };
}

export function openModalEmployeeFilter() {
  return {
    type: Types.EMPLOYEES_FILTER_OPEN_MODAL,
  };
}

export function closeModalEmployeeFilter() {
  return {
    type: Types.EMPLOYEES_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.EMPLOYEES_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.EMPLOYEES_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.EMPLOYEES_RESET_SUB_RESOURCE_DATA,
  };
}
