import produce from 'immer';

import CultureTypes from '../cultures/types';
import UnitMeasureTypes from '../unitsMeasure/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  harvestModalOpen: false,
  newCulture: {},
  newUnitMeasure: {},
};

export default function harvests(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.HARVESTS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.HARVESTS_GET_SUCCESS: {
        const { data, filterColumnsOrder, filterSearch } = action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }
        break;
      }
      case Types.HARVESTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.HARVESTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.HARVESTS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.harvest);
        draft.loading = false;
        draft.harvestModalOpen = false;
        break;
      }
      case Types.HARVESTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.HARVESTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.HARVESTS_UPDATE_SUCCESS: {
        const { newHarvest } = action.payload;

        draft.list = state.list.map(harvest => {
          return harvest.id === newHarvest.id ? newHarvest : harvest;
        });

        draft.detail = {};
        draft.loading = false;
        draft.harvestModalOpen = false;
        break;
      }
      case Types.HARVESTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.HARVESTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.HARVESTS_DELETE_SUCCESS: {
        draft.list = state.list.filter(harvest => {
          return harvest.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.HARVESTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.HARVESTS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        break;
      }

      case Types.HARVESTS_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = state.list.filter(harvest => {
            return harvest.id === id;
          });
        }

        draft.harvestModalOpen = true;
        break;
      }
      case Types.HARVESTS_CLOSE_MODAL: {
        draft.harvestModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.HARVESTS_CLEAR_NEW_CULTURE: {
        draft.newCulture = {};

        break;
      }

      case Types.HARVESTS_CLEAR_NEW_UNIT_MEASURE: {
        draft.newUnitMeasure = {};

        break;
      }

      case CultureTypes.CULTURES_CREATE_SUCCESS: {
        const { source, culture } = action.payload;

        if (source === 'HARVEST') {
          draft.newCulture = culture;
        }

        break;
      }

      case UnitMeasureTypes.UNITS_MEASURE_CREATE_SUCCESS: {
        const { source, unitMeasure } = action.payload;

        if (source === 'HARVEST') {
          draft.newUnitMeasure = unitMeasure;
        }

        break;
      }

      default:
    }
  });
}
