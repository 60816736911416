import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  loading: false,
  ncmFilterModalOpen: false,
};

export default function ncms(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.NCMS_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.NCMS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.ncmFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        break;
      }
      case Types.NCMS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.NCMS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.ncmFilterModalOpen = false;
        break;
      }

      case Types.NCMS_FILTER_OPEN_MODAL: {
        draft.ncmFilterModalOpen = true;
        break;
      }
      case Types.NCMS_FILTER_CLOSE_MODAL: {
        draft.ncmFilterModalOpen = false;
        break;
      }

      default:
    }
  });
}
