import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getBranchesActivitiesSuccess,
  getBranchesActivitiesFailure,
  createBranchActivitySuccess,
  createBranchActivityFailure,
  updateBranchActivitySuccess,
  updateBranchActivityFailure,
  deleteBranchActivitySuccess,
  deleteBranchActivityFailure,
} from './actions';
import Types from './types';

export function* getBranchesActivities({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `branches-activities${queryParams}`);

    yield put(
      getBranchesActivitiesSuccess({ data, filterColumnsOrder, filterSearch })
    );
  } catch (err) {
    yield put(getBranchesActivitiesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createBranchActivity({ payload }) {
  try {
    const { branchActivity, source } = payload;

    const response = yield call(
      api.post,
      'branches-activities',
      branchActivity
    );

    yield put(createBranchActivitySuccess(response.data, source));

    if (source) {
      toast.success(
        <IntlMessages id="branches-activities.create-branch-activity-success" />
      );
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createBranchActivityFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBranchActivity({ payload }) {
  try {
    const { id, newBranchActivity } = payload;

    const response = yield call(
      api.put,
      `branches-activities/${id}`,
      newBranchActivity
    );

    yield put(updateBranchActivitySuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBranchActivityFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBranchActivity({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `branches-activities/${id}`);

    yield put(deleteBranchActivitySuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBranchActivityFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.BRANCHES_ACTIVITIES_GET_REQUEST, getBranchesActivities),
  takeLatest(Types.BRANCHES_ACTIVITIES_CREATE_REQUEST, createBranchActivity),
  takeLatest(Types.BRANCHES_ACTIVITIES_UPDATE_REQUEST, updateBranchActivity),
  takeLatest(Types.BRANCHES_ACTIVITIES_DELETE_REQUEST, deleteBranchActivity),
]);
