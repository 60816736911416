import Types from './types';

export function getOwnerAddresses(addresses) {
  return {
    type: Types.OWNER_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createOwnerAddressRequest(owner_id, address) {
  return {
    type: Types.OWNER_ADDRESSES_CREATE_REQUEST,
    payload: { owner_id, address },
  };
}

export function createOwnerAddressSuccess(address) {
  return {
    type: Types.OWNER_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createOwnerAddressFailure() {
  return {
    type: Types.OWNER_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateOwnerAddressRequest(owner_id, address_id, newAddress) {
  return {
    type: Types.OWNER_ADDRESSES_UPDATE_REQUEST,
    payload: { owner_id, address_id, newAddress },
  };
}

export function updateOwnerAddressSuccess(newAddress) {
  return {
    type: Types.OWNER_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateOwnerAddressFailure() {
  return {
    type: Types.OWNER_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteOwnerAddressRequest(owner_id, address_id) {
  return {
    type: Types.OWNER_ADDRESSES_DELETE_REQUEST,
    payload: { owner_id, address_id },
  };
}

export function deleteOwnerAddressSuccess(address_id) {
  return {
    type: Types.OWNER_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteOwnerAddressFailure() {
  return {
    type: Types.OWNER_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListOwnerAddress() {
  return {
    type: Types.OWNER_ADDRESSES_RESET_LIST,
  };
}

export function openModalOwnerAddress(address_id) {
  return {
    type: Types.OWNER_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalOwnerAddress() {
  return {
    type: Types.OWNER_ADDRESSES_CLOSE_MODAL,
  };
}
