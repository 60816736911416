const Types = {
  CITIES_GET_REQUEST: '@cities/GET_CITIES_REQUEST',
  CITIES_GET_SUCCESS: '@cities/GET_CITIES_SUCCESS',
  CITIES_GET_FAILURE: '@cities/GET_CITIES_FAILURE',

  CITIES_RESET_LIST: '@cities/RESET_LIST_CITIES',

  CITIES_FILTER_OPEN_MODAL: '@cities/OPEN_MODAL_FILTER_CITY',
  CITIES_FILTER_CLOSE_MODAL: '@cities/CLOSE_MODAL_FILTER_CITY',
};

export default Types;
