import produce from 'immer';

import TeamTypes from '../teams/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  teamMemberModalOpen: false,
};

export default function teamMembers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.TEAM_MEMBERS_GET: {
        const { members } = action.payload;

        draft.list = members;
        break;
      }

      case Types.TEAM_MEMBERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.TEAM_MEMBERS_UPDATE_SUCCESS: {
        const { members } = action.payload;

        draft.list = members;

        draft.loading = false;
        draft.teamMemberModalOpen = false;

        break;
      }
      case Types.TEAM_MEMBERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.TEAM_MEMBERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.TEAM_MEMBERS_DELETE_SUCCESS: {
        const { team_member_id } = action.payload;

        draft.list = state.list.filter(item => {
          return item.id !== team_member_id;
        });

        draft.loading = false;
        break;
      }
      case Types.TEAM_MEMBERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.TEAM_MEMBERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.TEAM_MEMBERS_OPEN_MODAL: {
        draft.teamMemberModalOpen = true;
        break;
      }
      case Types.TEAM_MEMBERS_CLOSE_MODAL: {
        draft.teamMemberModalOpen = false;
        break;
      }

      case TeamTypes.TEAMS_RESET_DETAIL: {
        draft.teamMemberModalOpen = false;
        break;
      }

      default:
    }
  });
}
