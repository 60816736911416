import Types from './types';

export function createPriceListItemRequest({
  price_list_id,
  created,
  loadAllItems = null,
}) {
  return {
    type: Types.PRICE_LIST_ITEMS_CREATE_REQUEST,
    payload: { price_list_id, created, loadAllItems },
  };
}

export function createPriceListItemSuccess() {
  return {
    type: Types.PRICE_LIST_ITEMS_CREATE_SUCCESS,
  };
}

export function createPriceListItemFailure() {
  return {
    type: Types.PRICE_LIST_ITEMS_CREATE_FAILURE,
  };
}

export function updatePriceListItemRequest({
  price_list_id,
  item_id,
  newItem,
  loadAllItems = null,
}) {
  return {
    type: Types.PRICE_LIST_ITEMS_UPDATE_REQUEST,
    payload: { price_list_id, item_id, newItem, loadAllItems },
  };
}

export function updatePriceListItemSuccess() {
  return {
    type: Types.PRICE_LIST_ITEMS_UPDATE_SUCCESS,
  };
}

export function updatePriceListItemFailure() {
  return {
    type: Types.PRICE_LIST_ITEMS_UPDATE_FAILURE,
  };
}

export function deletePriceListItemRequest({
  price_list_id,
  item_id,
  loadAllItems = null,
}) {
  return {
    type: Types.PRICE_LIST_ITEMS_DELETE_REQUEST,
    payload: { price_list_id, item_id, loadAllItems },
  };
}

export function deletePriceListItemSuccess() {
  return {
    type: Types.PRICE_LIST_ITEMS_DELETE_SUCCESS,
  };
}

export function deletePriceListItemFailure() {
  return {
    type: Types.PRICE_LIST_ITEMS_DELETE_FAILURE,
  };
}

export function resetPriceListItem() {
  return {
    type: Types.PRICE_LIST_ITEMS_RESET_LIST,
  };
}

export function openModalPriceListItem(item) {
  return {
    type: Types.PRICE_LIST_ITEMS_OPEN_MODAL,
    payload: { item },
  };
}

export function closeModalPriceListItem() {
  return {
    type: Types.PRICE_LIST_ITEMS_CLOSE_MODAL,
  };
}

export function openModalNewPriceListItem() {
  return {
    type: Types.PRICE_LIST_ITEMS_OPEN_NEW_ITEM_MODAL,
  };
}

export function closeModalNewPriceListItem() {
  return {
    type: Types.PRICE_LIST_ITEMS_CLOSE_NEW_ITEM_MODAL,
  };
}

export function openModalPriceListItemFilter() {
  return {
    type: Types.PRICE_LIST_ITEMS_FILTER_OPEN_MODAL,
  };
}

export function closeModalPriceListItemFilter() {
  return {
    type: Types.PRICE_LIST_ITEMS_FILTER_CLOSE_MODAL,
  };
}

export function setPriceListItemTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
}) {
  return {
    type: Types.PRICE_LIST_ITEMS_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
    },
  };
}
