import styled from 'styled-components';

import { darken } from 'polished';

import {
  Grid,
  Badge,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Typography,
} from '@material-ui/core';

import colors from '~/theme/colors';

export const AppBar = styled(MuiAppBar).attrs({
  position: 'sticky',
  elevation: 0,
})`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

export const GridContainer = styled(Grid).attrs({
  alignItems: 'center',
})``;

export const IconButton = styled(MuiIconButton).attrs({
  color: 'inherit',
})`
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

export const Search = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${props => darken(0.05, props.theme.header.background)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    display: block;
  }
`;

export const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

export const TitleContainer = styled(Grid)`
  display: flex;
  flex: 1;
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 430px) {
    max-width: 20ch;
  }
`;

export const Title = styled(Typography)`
  color: ${colors.regular};
  margin-left: 5px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: fit-content;
`;
