import produce from 'immer';

import ProductTypes from '../products/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  productDiscountModalOpen: false,
};

export default function discounts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PRODUCT_DISCOUNTS_GET: {
        draft.list = action.payload.discounts;
        break;
      }

      case Types.PRODUCT_DISCOUNTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.discount);
        draft.loading = false;
        draft.productDiscountModalOpen = false;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCT_DISCOUNTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_UPDATE_SUCCESS: {
        const { newDiscount } = action.payload;

        draft.list = state.list.map(discount => {
          return discount.id === newDiscount.id ? newDiscount : discount;
        });

        draft.detail = {};
        draft.loading = false;
        draft.productDiscountModalOpen = false;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCT_DISCOUNTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_DELETE_SUCCESS: {
        const { discount_id } = action.payload;

        draft.list = state.list.filter(discount => {
          return discount.id !== discount_id;
        });

        if (draft.detail.id && discount_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRODUCT_DISCOUNTS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.PRODUCT_DISCOUNTS_OPEN_MODAL: {
        const { discount_id } = action.payload;

        draft.detail = {};

        if (discount_id) {
          [draft.detail] = draft.list.filter(
            discount => discount.id === discount_id
          );
        }

        draft.productDiscountModalOpen = true;
        break;
      }
      case Types.PRODUCT_DISCOUNTS_CLOSE_MODAL: {
        draft.productDiscountModalOpen = false;
        draft.detail = {};
        break;
      }

      case ProductTypes.PRODUCTS_RESET_DETAILS: {
        draft.productDiscountModalOpen = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      default:
    }
  });
}
