import React from 'react';

import PropTypes from 'prop-types';

import { Cancel } from './styles';

export default function CancelIcon({ type }) {
  return <Cancel type={type} />;
}

CancelIcon.propTypes = {
  type: PropTypes.string,
};

CancelIcon.defaultProps = {
  type: '',
};
