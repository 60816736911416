import React from 'react';
import { Package } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const BrandsList = async(() => import('~/pages/Brands/List'));
const CategoriesList = async(() => import('~/pages/Categories/List'));
const CompanyNcmsList = async(() => import('~/pages/CompanyNcms/List'));
const InventoriesList = async(() => import('~/pages/Inventories/List'));
const NcmsList = async(() => import('~/pages/Ncms/List'));
const DepartmentsList = async(() => import('~/pages/Departments/List'));
const PriceListsList = async(() => import('~/pages/PriceLists/List'));
const ProductsList = async(() => import('~/pages/Products/List'));
const UnitsMeasureList = async(() => import('~/pages/UnitsMeasure/List'));

export const productsRoutes = {
  id: <IntlMessages id="sidebar.products" />,
  path: '/product',
  icon: <Package />,
  children: [
    {
      path: '/products',
      name: <IntlMessages id="sidebar.products.list" />,
      component: ProductsList,
    },
    {
      path: '/inventories',
      name: <IntlMessages id="sidebar.products.inventories" />,
      component: InventoriesList,
    },
    {
      path: '/price-lists',
      name: <IntlMessages id="sidebar.products.price-lists" />,
      component: PriceListsList,
    },
    {
      path: '/brands',
      name: <IntlMessages id="sidebar.products.brands" />,
      component: BrandsList,
    },
    {
      path: '/categories',
      name: <IntlMessages id="sidebar.products.categories" />,
      component: CategoriesList,
    },
    {
      path: '/departments',
      name: <IntlMessages id="sidebar.products.departments" />,
      component: DepartmentsList,
    },
    {
      path: '/ncms',
      name: <IntlMessages id="sidebar.products.ncms" />,
      component: NcmsList,
    },
    {
      path: '/units-measure',
      name: <IntlMessages id="sidebar.products.units-measure" />,
      component: UnitsMeasureList,
    },
    {
      path: '/products-others',
      id: <IntlMessages id="sidebar.others" />,
      children: [
        {
          path: '/company-ncms',
          name: <IntlMessages id="sidebar.products.company-ncms" />,
          component: CompanyNcmsList,
        },
      ],
    },
  ],
};
