import React from 'react';

import styled from 'styled-components';

import whatsapp_inactive from '~/assets/img/icons/whatsapp-inactive.png';
import whatsapp from '~/assets/img/icons/whatsapp.png';
import IntlMessages from '~/utils/IntlMessages';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'form':
      styles = `
        width: 24px;
        height: 24px;
      `;
      break;
    case 'table':
      styles = `
        width: 20px;
        height: 20px;
      `;
      break;
    case 'modal':
      styles = `
        width: 20px;
        height: 20px;
      `;
      break;
    case 'card':
      styles = `
        width: 20px;
        height: 20px;
      `;

      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const WhatsApp = styled.img.attrs(({ type, active }) => {
  let attrs = {
    alt: <IntlMessages id="images.whatsapp-logo" />,
  };

  switch (type) {
    case 'form':
      attrs = {
        ...attrs,
        src: active ? whatsapp : whatsapp_inactive,
      };
      break;
    case 'table':
      attrs = {
        ...attrs,
        src: whatsapp,
      };
      break;
    default:
      attrs = {
        ...attrs,
        src: whatsapp,
      };
      break;
  }

  return attrs;
})`
  ${({ type }) => defineStyles(type)}
`;
