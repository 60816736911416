import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthLayout from '~/layouts/Auth';
import DashboardLayout from '~/layouts/Dashboard';
import LandingLayout from '~/layouts/Landing';
import Page404 from '~/pages/Page404';

import {
  dashboard as dashboardRoutes,
  auth as authRoutes,
  landing as landingRoutes,
  publicRoute as publicRoutes,
} from './index';
import RouteWrapper from './RouteWrapper';

function mapRoutes(routes, Layout, isPrivate, isPublic) {
  return routes.map(({ children, path, component, params }) => {
    return children ? (
      // Route item with children
      children.map(
        ({
          path: childrenPath,
          component: componentChildren,
          children: nestedChildren,
          params: nestedParams,
          Layout: CustomLayout,
        }) => {
          const RouteLayout = CustomLayout || Layout;

          if (nestedChildren) {
            return mapRoutes(nestedChildren, RouteLayout, isPrivate, isPublic);
          }
          return (
            <RouteWrapper
              key={Date.now()}
              path={childrenPath}
              exact
              component={componentChildren}
              layout={RouteLayout}
              isPrivate={isPrivate}
              params={params ?? nestedParams}
              isPublic={isPublic}
            />
          );
        }
      )
    ) : (
      // Route item without children
      <RouteWrapper
        key={Date.now()}
        path={path}
        exact
        component={component}
        layout={Layout}
        isPrivate={isPrivate}
        params={params}
        isPublic={isPublic}
      />
    );
  });
}

/** TODO: Colocar proptypes, tomar cuidado pois este arquivo é um dos principais do sistema */
const childRoutes = ({ Layout, routes, isPrivate, isPublic }) => {
  return mapRoutes(routes, Layout, isPrivate, isPublic);
};

const Routes = () => (
  <Switch>
    {childRoutes({
      Layout: AuthLayout,
      routes: authRoutes,
      isPrivate: false,
      isPublic: false,
    })}
    {childRoutes({
      Layout: LandingLayout,
      routes: landingRoutes,
      isPrivate: true,
      isPublic: false,
    })}
    {childRoutes({
      Layout: DashboardLayout,
      routes: dashboardRoutes,
      isPrivate: true,
      isPublic: false,
    })}
    {childRoutes({
      routes: publicRoutes,
      isPrivate: false,
      isPublic: true,
    })}
    <Route
      render={() => (
        <AuthLayout>
          <Page404 />
        </AuthLayout>
      )}
    />
  </Switch>
);

export default Routes;
