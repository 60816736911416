import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createTransporterAddressSuccess,
  createTransporterAddressFailure,
  updateTransporterAddressSuccess,
  updateTransporterAddressFailure,
  deleteTransporterAddressSuccess,
  deleteTransporterAddressFailure,
} from './actions';
import Types from './types';

export function* createTransporterAddress({ payload }) {
  try {
    const { transporter_id, address } = payload;

    const response = yield call(
      api.post,
      `transporters/${transporter_id}/addresses`,
      address
    );

    yield put(createTransporterAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createTransporterAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateTransporterAddress({ payload }) {
  try {
    const { transporter_id, address_id, newAddress } = payload;

    const response = yield call(
      api.put,
      `transporters/${transporter_id}/addresses/${address_id}`,
      newAddress
    );

    yield put(updateTransporterAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateTransporterAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteTransporterAddress({ payload }) {
  const { transporter_id, address_id } = payload;

  const addresses = yield select(state => state.transporterAddresses.list);

  const address = addresses.find(data => data.id === address_id);

  if (address.is_main && addresses.length > 1) {
    yield put(deleteTransporterAddressFailure());

    toast.error(<IntlMessages id="addresses.error-delete-is-main" />);

    return;
  }

  try {
    yield call(
      api.delete,
      `transporters/${transporter_id}/addresses/${address_id}`
    );

    yield put(deleteTransporterAddressSuccess(address_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteTransporterAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.TRANSPORTER_ADDRESSES_CREATE_REQUEST,
    createTransporterAddress
  ),
  takeLatest(
    Types.TRANSPORTER_ADDRESSES_UPDATE_REQUEST,
    updateTransporterAddress
  ),
  takeLatest(
    Types.TRANSPORTER_ADDRESSES_DELETE_REQUEST,
    deleteTransporterAddress
  ),
]);
