module.exports = {
  // Accounts
  'accounts.title': 'Contas',
  'accounts.new-title': 'Nova conta',

  'accounts.deleted-account': 'Conta excluída',
  'accounts.disabled-account': 'Conta desativada',

  'accounts.name': 'Nome',
  'accounts.balance': 'Saldo',
  'accounts.species': 'Espécie',
  'accounts.bank': 'Banco',

  'accounts.pix-enabled': 'Pix ativado',
  'accounts.pix-enabled.enabled': 'Sim',
  'accounts.pix-enabled.disabled': 'Não',

  'accounts.is-enabled': 'Ativo',
  'accounts.is-enabled.enabled': 'Ativado',
  'accounts.is-enabled.disabled': 'Desativado',
  'accounts.is-enabled.undefined': 'Indefinido',

  'accounts.type': 'Tipo',
  'accounts.type.bank': 'Banco',
  'accounts.type.cashier': 'Caixa',
  'accounts.type.safe': 'Cofre',
  'accounts.type.credit-card': 'Cartão de crédito',
  'accounts.type.undefined': 'Indefinido',

  'accounts.form-title.informations': 'Informações',

  'accounts.get-accounts-failure': 'Falha ao carregar as contas! 😢',

  // Addresses
  'addresses.title': 'Endereços',
  'addresses.new-title': 'Novo endereço',
  'addresses.edit-title': 'Edita endereço',

  'addresses.postal-code': 'CEP',
  'addresses.address': 'Endereço',
  'addresses.number': 'Número',
  'addresses.complement': 'Complemento',
  'addresses.neighborhood': 'Bairro',
  'addresses.city': 'Cidade',
  'addresses.state': 'Estado',

  'addresses.error-postal-code':
    'Erro ao consultar o CEP, verifique os dados! 🤔',

  'addresses.type': 'Tipo',
  'addresses.types.general': 'Geral',
  'addresses.types.billing': 'Cobrança',
  'addresses.types.delivery': 'Entrega',
  'addresses.types.branch-office': 'Filial',
  'addresses.types.headquarters': 'Matriz',
  'addresses.types.residential': 'Residencial',
  'addresses.types.work': 'Trabalho',

  'addresses.is-main': 'Principal',
  'addresses.is-main-address': 'Endereço principal',
  'addresses.error-delete-is-main':
    'Endereço principal não pode ser excluído quando tem mais de um! 🤔',
  'addresses.google-maps': 'Ver no Google Maps',

  // All Backups

  // All Backups Databases
  'all-backups-databases.title': 'Todos os backups de bancos de dados',

  'all-backups-databases.customer-code': 'Código',
  'all-backups-databases.customer-name': 'Cliente',

  'all-backups-databases.messages.no-more-backups': 'Não há mais backups',

  // All Devices
  'all-devices.title': 'Todos os Dispositivos',

  'all-devices.company-code': 'Código',
  'all-devices.company-name': 'Empresa',

  // Avatar
  'avatar.crop-modal.title': 'Recortar imagem',

  // Backups

  // Backups Databases
  'backups-databases.total': 'Total',
  'backups-databases.older-backup': 'Mais Antigo',
  'backups-databases.last-backup': 'Mais Recente',
  'backups-databases.backup-name': 'Arquivo',

  'backups-databases.status': 'Sem Backup',
  'backups-databases.status.recent': 'Recente',
  'backups-databases.status.old': 'Antigo',
  'backups-databases.status.older': 'Mais Antigo',
  'backups-databases.status.undefined': 'Indefinido',

  // Bank Accounts
  'bank-accounts.bank': 'Banco',
  'bank-accounts.city': 'Cidade',
  'bank-accounts.agency': 'Agência',
  'bank-accounts.agency-ck-digit': 'Dígito',
  'bank-accounts.account': 'Conta',
  'bank-accounts.account-ck-digit': 'Dígito',
  'bank-accounts.assignor-name': 'Nome do cedente',
  'bank-accounts.credit-limit': 'Limite de crédito',

  'bank-accounts.assignor-person-kind': 'Tipo da pessoa',
  'bank-accounts.assignor-person-kind.natural': 'Física',
  'bank-accounts.assignor-person-kind.legal': 'Jurídica',

  'bank-accounts.form-title.bank-account': 'Conta bancária',

  // Bank Account Pixs
  'bank-account-pixs.title': 'Pixs',
  'bank-account-pixs.new-title': 'Nova ativação do Pix',
  'bank-account-pixs.edit-title': 'Edita ativação do Pix',

  'bank-account-pixs.pix-key': 'Chave Pix',
  'bank-account-pixs.client-id': 'Identificador do acesso do banco',
  'bank-account-pixs.client-key': 'Chave de acesso do banco',
  'bank-account-pixs.client-secret': 'Chave secreta do banco',
  'bank-account-pixs.pix-with-register': 'Pix com registro',
  'bank-account-pixs.pix-without-register': 'Pix sem registro',

  'bank-account-pixs.pix-status.label': 'Ativado',
  'bank-account-pixs.pix-status.active': 'Ativado',
  'bank-account-pixs.pix-status.inactive': 'Desativado',

  'bank-account-pixs.status': 'Status',
  'bank-account-pixs.status.undefined': 'Indefinido',
  'bank-account-pixs.status.active': 'Ativado',
  'bank-account-pixs.status.inactive': 'Desativado',

  'bank-account-pixs.type': 'Tipo',
  'bank-account-pixs.type.document': 'Documento (CPF/CNPJ)',
  'bank-account-pixs.type.email': 'Email',
  'bank-account-pixs.type.phone-number': 'Telefone',
  'bank-account-pixs.type.random-key': 'Chave aleatória',

  'bank-account-pixs.button-label.activate-pix': 'Ativar Pix',

  'bank-account-pixs.error-bank-account-pix-create':
    'Só é possível cadastrar uma chave Pix.',

  'bank-account-pixs.message.configure-pix-on-itau':
    'Para configurar o Pix na conta do banco Itaú, entrar em contato com o suporte, pois é necessário gerar certificado.',

  'bank-account-pixs.message.information-provided-by-bank':
    'Informação disponibilizada pelo banco.',
  'bank-account-pixs.message.required-region-code':
    'É obrigatório o número de telefone começar com +55',

  // Bank Billings
  'bank-billings.title': 'Cobranças Bancárias',
  'bank-billings.new-title': 'Nova cobrança',

  'bank-billings.account-name': 'Conta',
  'bank-billings.type': 'Tipo',
  'bank-billings.amount': 'Valor',
  'bank-billings.favoured-name': 'Favorecido',
  'bank-billings.status': 'Status',
  'bank-billings.due-at': 'Data de vencimento',

  // Banks
  'banks.title': 'Bancos',

  'banks.code': 'Código',
  'banks.name': 'Nome',

  'banks.get-banks-failure': 'Falha ao carregar os bancos! 😢',

  // Bills
  'bills.new-title': 'Nova conta',
  'bills.form-title.payment': 'Pagamento',
  'bills.settlement-modal-title': 'Baixar conta',

  'bills.settlement-modal-button-ok': 'Baixar',
  'bills.document-number': 'Nº do documento',
  'bills.document': 'Documento',
  'bills.payment-method-type': 'Meio de pagamento',
  'bills.competence': 'Competência',
  'bills.description': 'Descrição',
  'bills.observation': 'Observação',
  'bills.issued-at': 'Data de emissão',
  'bills.issue': 'Emissão',
  'bills.due-at': 'Data de vencimento',
  'bills.due': 'Vencimento',
  'bills.min-date-due': 'A data não deve ser anterior à data de emissão',
  'bills.settlemented-at': 'Data da baixa',
  'bills.settlement': 'Baixa',
  'bills.property': 'Propriedade',
  'bills.percentage-paid': '% paga: {percentage}%',
  'bills.order': 'Pedido',
  'bills.account': 'Conta',
  'bills.remaining-amount': 'Valor restante',
  'bills.remove-settlement': 'Remover baixa',

  'bills.amount': 'Valor',
  'bills.amount-paid': 'Valor pago',
  'bills.fine-amount': 'Multa',
  'bills.interest-amount': 'Juros',
  'bills.discount-amount': 'Valor do desconto',

  'bills.reports.total-amount': 'Valor',
  'bills.reports.total-fine-amount': 'Total de multa',
  'bills.reports.total-interest-amount': 'Total de juros',

  'bills.debit-balance': 'Saldo devedor',
  'bills.balance': 'Saldo',
  'bills.amount-balance': 'Valor / Saldo',
  'bills.favoured': 'Favorecido',
  'bills.favoured-description': 'Favorecido / Descrição',
  'bills.status': 'Status',
  'bills.status.open': 'Aberto',
  'bills.status.overdue': 'Atrasado',
  'bills.status.partially-paid': 'Parcialmente pago',
  'bills.status.partially-paid-due': 'Parc. pago vencido',
  'bills.status.paid': 'Pago',
  'bills.status.canceled': 'Cancelado',
  'bills.status.discounted': 'Descontado',
  'bills.status.undefined': 'Indefinido',

  'bills.situation': 'Situação',
  'bills.situation.normal': 'Normal',
  'bills.situation.canceled': 'Cancelado',

  'bills.statistic-bar.relation-total': 'Em relação ao total',
  'bills.statistic-bar.total-dued': 'Total vencido',
  'bills.statistic-bar.total-due': 'Total a vencer',

  'bills.message.recalculate-fine-and-interest-amount.title': 'Aviso',
  'bills.message.recalculate-fine-and-interest-amount.text':
    'Ao alterar a data de vencimento, deseja recalcular o juros e a multa?',

  'bills.message.remove-settlemented-bill.title':
    'Deseja remover a baixa dessa parcela?',
  'bills.message.remove-settlemented-bill.text':
    'Ao remover, os valores pagos serão zerados e o saldo devedor será reajustado para o valor da parcela.',

  'bills.get-favoured-failure': 'Falha ao carregar os favorecidos! 😢',
  'bills.get-totalization-failure':
    'Falha ao carregar as informações de totalização! 😢',

  'bills.cost-centers.error-cost-center-create':
    'Só é possível cadastrar um centro de custo',

  'bills.chart-accounts.error-chart-account-create':
    'Só é possível cadastrar um plano de conta',

  // Bills To Pay
  'bills-to-pay.title': 'Contas a Pagar',
  'bills-to-pay.reports.total-pay': 'Total a pagar',

  'bills-to-pay.statistic-bar.total-paid': 'Total pago',

  'bills-to-pay.get-bills-to-pay-failure':
    'Falha ao carregar as contas a pagar! 😢',

  // Bills To Receive
  'bills-to-receive.title': 'Contas a Receber',
  'bills-to-receive.reports.total-receive': 'Total a receber',

  'bills-to-receive.statistic-bar.total-received': 'Total recebido',

  'bills-to-receive.get-bills-to-receive-failure':
    'Falha ao carregar as contas a receber! 😢',

  // Branches Activities
  'branches-activities.title': 'Ramos de Atividades',
  'branches-activities.new-title': 'Novo ramo de atividade',
  'branches-activities.create-new-title': 'Criar novo ramo de atividade',
  'branches-activities.edit-title': 'Edita ramo de atividade',

  'branches-activities.name': 'Nome',

  'branches-activities.get-branches-activities-failure':
    'Falha ao carregar os ramos de atividades! 😢',
  'branches-activities.create-branch-activity-success':
    'Ramo de atividade cadastrado com sucesso! 🎉',

  // Brands
  'brands.title': 'Marcas',
  'brands.new-title': 'Nova marca',
  'brands.create-new-title': 'Criar nova marca',
  'brands.edit-title': 'Edita marca',

  'brands.name': 'Nome',

  'brands.get-brands-failure': 'Falha ao carregar as marcas! 😢',
  'brands.create-brand-success': 'Marca cadastrada com sucesso! 🎉',

  // Buttons
  'buttons.add': 'Novo',
  'buttons.save': 'Salvar',
  'buttons.cancel': 'Cancelar',
  'buttons.ok': 'OK',
  'buttons.close': 'Fechar',
  'buttons.clear': 'Limpar',
  'buttons.delete': 'Excluir',
  'buttons.settlement': 'Baixar',
  'buttons.hide': 'Esconder',
  'buttons.go-back': 'Voltar',
  'buttons.change': 'Alterar',
  'buttons.continue': 'Continuar',
  'buttons.print': 'Imprimir',
  'buttons.copy': 'Copiar',
  'buttons.update': 'Atualizar',
  'buttons.select': 'Selecionar',
  'buttons.download-app': 'Baixar App',
  'buttons.share': 'Compartilhar',
  'buttons.catalog': 'Catálogo',
  'buttons.filter': 'Filtrar',
  'buttons.recalculate': 'Recalcular',
  'buttons.send': 'Enviar',
  'buttons.reset': 'Redefinir',
  'buttons.remove': 'Remover',
  'buttons.view': 'Visualizar',
  'buttons.search': 'Pesquisar',
  'buttons.save-and-new': 'Salvar e novo',
  'buttons.configure': 'Configurar',
  'buttons.generate-report': 'Gerar relatório',

  // Buyers
  'buyers.deleted-buyer': 'Comprador excluído',
  'buyers.disabled-buyer': 'Comprador desativado',

  'buyers.get-buyer-failure': 'Falha ao carregar o comprador! 😢',

  // Categories
  'categories.title': 'Categorias',
  'categories.new-title': 'Nova categoria',
  'categories.edit-title': 'Edita categoria',

  'categories.new-subcategory-title': 'Nova subcategoria',
  'categories.edit-subcategory-title': 'Edita subcategoria',

  'categories.name': 'Nome',
  'categories.description': 'Descrição',

  'categories.get-categories-failure': 'Falha ao carregar as categorias! 😢',

  // Chart Accounts
  'chart-accounts.title': 'Plano de Contas',
  'chart-accounts.new-title': 'Novo plano de conta',
  'chart-accounts.edit-title': 'Edita plano de conta',

  'chart-accounts.number': 'Número',
  'chart-accounts.reduced-number': 'Código reduzido',
  'chart-accounts.description': 'Descrição',
  'chart-accounts.level': 'Nível',

  'chart-accounts.bill-type': 'Tipo',
  'chart-accounts.bill-type.pay': 'Débito',
  'chart-accounts.bill-type.receive': 'Crédito',

  'chart-accounts.add-level': 'Adicionar ao nível',
  'chart-accounts.add-at-level': 'No nível {value}',
  'chart-accounts.add-at-same-level': 'No mesmo nível {value}',
  'chart-accounts.add-at-lower-level': 'No nível inferior {value}',

  'chart-accounts.max-creation':
    'Não é possível cadastrar mais planos de contas nesse nível.',

  'chart-accounts.get-chart-accounts-failure':
    'Falha ao carregar os planos de contas! 😢',

  // Cities
  'cities.title': 'Cidades',

  'cities.name': 'Nome',
  'cities.ibge-code': 'Código IBGE',
  'cities.state': 'Estado',
  'cities.default-postal-code': 'CEP Padrão',

  'cities.get-cities-failure': 'Falha ao carregar as cidades! 😢',

  // Company
  'company.title': 'Empresa',
  'company.form-title.users': 'Membros',
  'company.new-title': 'Novo membro',
  'company.edit-title': 'Edita membro',

  'company.type': 'Tipo',
  'company.type.main': 'Principal',
  'company.type.parent': 'Matriz',
  'company.type.subsidiary': 'Filial',

  'company.user.name': 'Nome',
  'company.user.email': 'Email',

  'company.disabled-company': 'Empresa desativada',

  'company.situation.normal': 'Normal',
  'company.situation.canceled': 'Cancelado',

  'company.dialogs.delete-company-title': 'Deseja excluir esta empresa?',
  'company.dialogs.delete-company-text':
    'Esta ação NÃO poderá ser desfeita, caso tenha certeza, digite sua senha para confirmar.',

  'company.table-localization.body.delete-user-text':
    'Tem certeza que deseja excluir este usuário da empresa?',

  // Company NCMS
  'company-ncms.title': 'NCMs',

  'company-ncms.id': 'Código',
  'company-ncms.description': 'Descrição',
  'company-ncms.category': 'Categoria',
  'company-ncms.level': 'Nível',

  'company-ncms.is-enabled': 'Visível',
  'company-ncms.is-enabled.enabled': 'Sim',
  'company-ncms.is-enabled.disabled': 'Não',

  'company-ncms.is-single-phase': 'Fase única',
  'company-ncms.is-single-phase.enabled': 'Sim',
  'company-ncms.is-single-phase.disabled': 'Não',

  // Company Profile
  'company-profile.no-product-found': 'Nenhum produto foi encontrado.',
  'company-profile.product-found': '{value} produto encontrado.',
  'company-profile.products-found': '{value} produtos encontrados.',
  'company-profile.filtered-by': 'Filtrado por:',

  'company-profile.search-products': 'Buscar produtos...',

  'company-profile.filter-by.condition': 'Condição',
  'company-profile.filter-by.brand': 'Marca',

  'company-profile.filter-by.category': 'Categoria',
  'company-profile.filter-by.category.drawer-title': 'Selecione uma categoria',
  'company-profile.filter-by.category.return': 'Retornar',
  'company-profile.filter-by.category.return-to': 'Retornar para {value}',

  'company-profile.is-hot': 'Destaque',
  'company-profile.is-release': 'Novo',
  'company-profile.is-on-promotion': 'Promoção',

  'company-profile.navigation.previous': 'Anterior',
  'company-profile.navigation.next': 'Próxima',

  'company-profile.get-products-failure': 'Falha ao carregar os produtos! 😢',
  'company-profile.get-company-failure':
    'Falha ao carregar as informações da empresa! 😢',

  'company-profile.error.title': 'Erro ao listar produtos!',
  'company-profile.error.message':
    'Tente novamente mais tarde ou entre em contato com a empresa responsável.',

  // Company Users
  'company-users.invite-title': 'Vincular membro',

  'company-users.email': 'Email',

  'company-users.messages.invite-success': 'Vinculado com sucesso! 🎉',
  'company-users.messages.invite-failure':
    'Falha ao vincular membro, tente novamente! 😢',

  // Contacts
  'contacts.title': 'Contatos',
  'contacts.contact-detail.title': 'Contato',

  'contacts.manage-contact': 'Gerenciar contato',

  // Cost Centers
  'cost-centers.title': 'Centros de Custos',
  'cost-centers.new-title': 'Novo centro de custo',
  'cost-centers.edit-title': 'Edita centro de custo',

  'cost-centers.number': 'Número',
  'cost-centers.reduced-number': 'Código reduzido',
  'cost-centers.description': 'Descrição',

  'cost-centers.add-level': 'Adicionar ao nível',
  'cost-centers.add-at-level': 'No nível {value}',
  'cost-centers.add-at-same-level': 'No mesmo nível {value}',
  'cost-centers.add-at-lower-level': 'No nível inferior {value}',

  'cost-centers.max-creation':
    'Não é possível cadastrar mais centros de custos nesse nível.',

  'cost-centers.get-cost-centers-failure':
    'Falha ao carregar os centros de custos! 😢',

  // Countries
  'countries.title': 'Países',

  'countries.name': 'Nome',
  'countries.code': 'Código',

  // Covenants
  'covenants.title': 'Convênios',
  'covenants.new-title': 'Novo convênio',
  'covenants.create-new-title': 'Criar novo convênio',
  'covenants.edit-title': 'Edita convênio',

  'covenants.name': 'Nome',

  'covenants.get-covenants-failure': 'Falha ao carregar os convênios! 😢',
  'covenants.create-covenant-success': 'Convênio cadastrado com sucesso! 🎉',

  // Cultures
  'cultures.title': 'Culturas',
  'cultures.new-title': 'Nova cultura',
  'cultures.create-new-title': 'Criar nova cultura',
  'cultures.edit-title': 'Edita cultura',

  'cultures.name': 'Nome',

  'cultures.create-culture-success': 'Cultura cadastrada com sucesso! 🎉',

  // Customers
  'customers.title': 'Clientes',
  'customers.new-title': 'Novo cliente',

  'customers.deleted-customer': 'Cliente excluído',
  'customers.disabled-customer': 'Cliente desativado',

  'customers.check-exists.become-customer': 'Tornar cliente',
  'customers.check-exists.exists-view-title':
    'Foi encontrado um cliente com esse documento:',
  'customers.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é cliente:',
  'customers.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um cliente nesta empresa:',

  'customers.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como cliente na empresa:',

  'customers.get-customer-failure': 'Falha ao carregar o cliente! 😢',
  'customers.get-customers-failure': 'Falha ao carregar os clientes! 😢',

  // Dashboard
  'dashboard.title': 'Início',

  'dashboard.welcome': 'Seja bem-vindo, ',
  'dashboard.logged-company': 'Você está logado na empresa ',

  // Dashboard Graphs
  'dashboard-graphs.sales-evolution.title': 'Evolução em vendas',
  'dashboard-graphs.sales-evolution.graph.label': 'Total de pedidos: {value}',
  'dashboard-graphs.sales-evolution.graph.first-month-label':
    '1ª linha - {value}',
  'dashboard-graphs.sales-evolution.graph.second-month-label':
    '2ª linha - {value}',
  'dashboard-graphs.sales-evolution.message.failed-to-load-the-sales-evolution':
    'Falha ao carregar a evolução em vendas! 😢',
  'dashboard-graphs.sales-evolution.filter.month': 'Mês',
  'dashboard-graphs.sales-evolution.filter.first-title': 'Primeira linha',
  'dashboard-graphs.sales-evolution.filter.second-title': 'Segunda linha',

  'dashboard-graphs.sellers-chart.total-by-sellers.title':
    'Total por vendedores',
  'dashboard-graphs.sellers-chart.graph.first-month-label':
    '1ª barra - {value}',
  'dashboard-graphs.sellers-chart.graph.second-month-label':
    '2ª barra - {value}',
  'dashboard-graphs.sellers-chart.message.failed-to-load-the-sellers-chart':
    'Falha ao carregar o gráfico de vendedores! 😢',
  'dashboard-graphs.sellers-chart.filter.month': 'Mês',
  'dashboard-graphs.sellers-chart.filter.first-title': 'Primeira barra',
  'dashboard-graphs.sellers-chart.filter.second-title': 'Segunda barra',

  'dashboard-graphs.products-chart.others-label': 'Outros',
  'dashboard-graphs.products-chart.best-selling-products.title':
    'Produtos mais vendidos',
  'dashboard-graphs.products-chart.percentage': 'Percentual: {value}',
  'dashboard-graphs.products-chart.message.failed-to-load-the-products-chart':
    'Falha ao carregar o gráfico de produtos! 😢',
  'dashboard-graphs.products.filter.date': 'Data',
  'dashboard-graphs.message.no-data-in-period':
    'Sem dados disponíveis nesse período.',

  // Datasmart
  'datasmart.name': 'Datasmart',
  'datasmart.copyright': '© {value} - Datasmart',
  'datasmart.website': 'https://www.datasmart.com.br',
  'datasmart.smartbusiness': 'SmartBusiness',

  'datasmart.email-company.email': 'datasmart@datasmart.com.br',
  'datasmart.email-company.contact': 'Assuntos gerais',
  'datasmart.email-company.subject-general-message': 'Assuntos gerais',

  'datasmart.email-commercial.email': 'comercial@datasmart.com.br',
  'datasmart.email-commercial.contact': 'Comercial',
  'datasmart.email-commercial.subject-commercial-message':
    'Assuntos comerciais',

  'datasmart.email-support.email': 'suporte@datasmart.com.br',
  'datasmart.email-support.contact': 'Suporte',
  'datasmart.email-support.subject-support-message': 'Suporte',

  'datasmart.phone-fixed.contact': 'Assuntos gerais',
  'datasmart.phone-fixed.number.avare': '1437119090',
  'datasmart.phone-fixed.ddi': '55',
  'datasmart.phone-fixed.operator': 'Fixo',

  'datasmart.phone-mobile.contact': 'Suporte',
  'datasmart.phone-mobile.number': '14996641191',
  'datasmart.phone-mobile.number.lencois-paulista': '14997616504',
  'datasmart.phone-mobile.ddi': '55',
  'datasmart.phone-mobile.operator': 'Vivo',
  'datasmart.phone-mobile.whatsapp-message':
    'Olá, eu estou precisando da ajuda do suporte Datasmart. Você poderia me ajudar?',

  'datasmart.address.title': 'Endereço',
  'datasmart.address.state': 'SP',

  // Datasmart - Avare Address
  'datasmart.address.city.avare': 'Avaré',
  'datasmart.address.address-location.avare': 'Rua Alagoas',
  'datasmart.address.number.avare': '1633',
  'datasmart.address.neighborhood.avare': 'Centro',
  'datasmart.address.postal-code.avare': '18705-070',

  // Datasmart - Lencois Paulista Address
  'datasmart.address.city.lencois-paulista': 'Lençóis Paulista',
  'datasmart.address.address-location.lencois-paulista':
    'Avenida Geraldo Pereira de Barros',
  'datasmart.address.number.lencois-paulista': '336',
  'datasmart.address.neighborhood.lencois-paulista': 'Centro',
  'datasmart.address.postal-code.lencois-paulista': '18682-041',

  // Dates
  'dates.created-by': 'Criado por',
  'dates.updated-by': 'Última alteração por',
  'dates.on': 'em',
  'dates.at': 'às',
  'dates.date-interval': 'De {start} para {end}',

  // Departments
  'departments.title': 'Departamentos',
  'departments.new-title': 'Novo departamento',
  'departments.create-new-title': 'Criar novo departamento',
  'departments.edit-title': 'Edita departamento',

  'departments.name': 'Nome',
  'departments.printer': 'Impressora',
  'departments.number-copies': 'Nº de cópias',

  'departments.is-print-order': 'Impressão de pedidos',
  'departments.is-print-order.enabled': 'Sim',
  'departments.is-print-order.disabled': 'Não',

  'departments.get-departments-failure':
    'Falha ao carregar os departamentos! 😢',
  'departments.create-department-success':
    'Departamento cadastrado com sucesso! 🎉',

  // Devices
  'devices.title': 'Dispositivos',

  'devices.local-remote-ip': 'IP Local / Remoto',
  'devices.port': 'Porta',
  'devices.name': 'Dispositivo',
  'devices.company-name-device-name': 'Empresa / Dispositivo',
  'devices.last-sync-at': 'Última sincronização',
  'devices.created-at': 'Criado em',
  'devices.updated-at': 'Modificado em',

  'devices.type': 'Tipo',
  'devices.type.server': 'Servidor',
  'devices.type.terminal': 'Terminal',
  'devices.type.mobile': 'Celular',

  // Dialogs
  'dialogs.delete-title': 'Deseja excluir este registro?',
  'dialogs.delete-text':
    'Esta ação não poderá ser desfeita após sua confirmação!',

  'dialogs.change-person-kind-title': 'Deseja alterar o tipo de pessoa?',
  'dialogs.change-person-kind-text':
    'Esta ação limpará os documentos preenchidos.',

  'dialogs.data-loss-title': 'Deseja continuar?',
  'dialogs.data-loss-text':
    'Existem alterações que ainda não foram salvas e serão perdidas caso continue.',

  // Dispatching Agencies
  'dispatching-agencies.title': 'Órgãos Expedidores',

  'dispatching-agencies.name': 'Nome',
  'dispatching-agencies.initials': 'Sigla',
  'dispatching-agencies.state': 'Estado',

  'dispatching-agencies.get-dispatching-agencies-failure':
    'Falha ao carregar os órgãos expedidores! 😢',

  // Documents
  'documents.cpf': 'CPF',
  'documents.cpf-data': 'CPF: {value}',
  'documents.valid-cpf': 'CPF válido',
  'documents.invalid-cpf': 'CPF inválido',

  'documents.cnpj': 'CNPJ',
  'documents.cnpj-data': 'CNPJ: {value}',
  'documents.valid-cnpj': 'CNPJ válido',
  'documents.invalid-cnpj': 'CNPJ inválido',

  'documents.cpf-cnpj': 'CPF/CNPJ',

  // Emails
  'emails.title': 'Emails',
  'emails.new-title': 'Novo email',
  'emails.edit-title': 'Edita email',

  'emails.is-main': 'Principal',
  'emails.is-main-email': 'Email principal',
  'emails.error-delete-is-main':
    'Email principal não pode ser excluído quando tem mais de um! 🤔',

  'emails.email': 'Email',
  'emails.contact': 'Contato',
  'emails.send': 'Enviar email para ',

  // Employees
  'employees.title': 'Funcionários',
  'employees.new-title': 'Novo funcionário',

  'employees.check-exists.become-employee': 'Tornar funcionário',
  'employees.check-exists.exists-view-title':
    'Foi encontrado um funcionário com esse documento:',
  'employees.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é funcionário:',
  'employees.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um funcionário nesta empresa:',

  'employees.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como funcionário na empresa:',

  // Files
  'files.add-image': 'Adicionar imagem',
  'files.change-image': 'Alterar imagem',
  'files.delete-image': 'Excluir imagem',

  'files.error-file-size-too-big': 'O arquivo excedeu o limite de {value}!',
  'files.error-wrong-file-type':
    'Formato de arquivo inválido. Apenas {value} são válidos!',

  // Filters
  'filters.filter-modal-title': 'Filtrar',
  'filters.between-start': 'Início',
  'filters.between-end': 'Fim',

  // Footer
  'footer.app-version': 'Versão {value}',
  'footer.support': 'Suporte',
  'footer.help-center': 'Central de Ajuda',
  'footer.privacy': 'Privacidade',
  'footer.terms-of-service': 'Termos de Serviço',

  // Forgot Password
  'forgot-password.title': 'Esqueceu a senha',

  'forgot-password.form-title': 'Esqueceu a senha?',
  'forgot-password.form-description':
    'Enviaremos um e-mail com as instruções necessárias para você redefinir a sua senha.',

  'forgot-password.email': 'E-mail',

  'forgot-password.message.request-success':
    'Em instantes, enviaremos um e-mail com os passos necessários para você redefinir a sua senha!',
  'forgot-password.message.request-failure':
    'Falha ao tentar enviar o e-mail para redefinir a senha!',

  // Forms
  'forms.informations': 'Informações',
  'forms.select.dropdown-select': 'Selecionar',

  'forms.field-excluded': 'Excluído',
  'forms.field-disabled': 'Desativado',

  'forms.autocomplete.close': 'Fechar',
  'forms.autocomplete.open': 'Abrir',
  'forms.autocomplete.clear': 'Apagar',
  'forms.autocomplete.no-options': 'Nenhuma opção',
  'forms.autocomplete.loading': 'Carregando...',

  'forms.date-picker.invalid-date': 'Data inválida',
  'forms.date-picker.min-date': 'A data não deve ser anterior à data mínima',
  'forms.date-picker.max-date': 'A data não deve ser posterior à data máxima',
  'forms.date-picker.clear': 'Apagar',

  'forms.date-filter.today': 'Hoje',
  'forms.date-filter.last-7-days': 'Últimos 7 dias',
  'forms.date-filter.last-week': 'Semana anterior',
  'forms.date-filter.last-30-days': 'Últimos 30 dias',
  'forms.date-filter.last-60-days': 'Últimos 60 dias',
  'forms.date-filter.last-month': 'Mês anterior',
  'forms.date-filter.this-month': 'Mês atual',
  'forms.date-filter.next-month': 'Próximo mês',
  'forms.date-filter.this-year': 'Ano atual',
  'forms.date-filter.last-year': 'Ano anterior',
  'forms.date-filter.customized': 'Customizado',

  'forms.label.from': 'De',
  'forms.label.to': 'Para',
  'forms.label.creation': 'Criação: ',
  'forms.label.last-update': 'Última alteração: ',

  'forms.input.search': 'Pesquisar',
  'forms.input.invalid-email': 'Email inválido',
  'forms.input.invalid-document': 'Documento inválido',
  'forms.input.required-field': 'Campo obrigatório',
  'forms.input.field-greater-than': 'Campo deve ser maior que {value}',
  'forms.input.field-greater-than-or-equal-to':
    'Campo deve ser maior ou igual a {value}',
  'forms.input.field-less-than': 'Campo deve ser menor que {value}',
  'forms.input.field-less-than-or-equal-to':
    'Campo deve ser menor ou igual a {value}',
  'forms.input.field-greater-than-zero': 'Campo deve ser maior que zero',
  'forms.input.field-exactly-characters':
    'Campo deve ter exatamente {value} caracteres',
  'forms.input.total-is-below-to-cost-price':
    'O total está abaixo do valor de custo',

  'forms.input.custom-validation.is-correct-minimum-value':
    'O valor mínimo deve ser menor que o valor máximo',
  'forms.input.custom-validation.is-correct-maximum-value':
    'O valor máximo deve ser maior que o valor mínimo',

  // Harvests
  'harvests.title': 'Safras',
  'harvests.new-title': 'Nova safra',
  'harvests.edit-title': 'Edita safra',

  'harvests.name': 'Nome',
  'harvests.culture': 'Cultura',
  'harvests.started-at': 'Início da safra',
  'harvests.ended-at': 'Fim da safra',
  'harvests.standard-unit-measure': 'Unidade de medida padrão',

  'harvests.get-culture-failure': 'Falha ao carregar as culturas! 😢',
  'harvests.get-standard-unit-measure-failure':
    'Falha ao carregar as unidades de medidas! 😢',

  'harvests.min-date': 'A data não pode ser anterior à data de início',

  // Header
  'header.search': 'Pesquisar',

  'header.language': 'Idioma',
  'header.language.en-us': 'English',
  'header.language.es-es': 'Español',
  'header.language.pt-br': 'Português',

  'header.profile': 'Meu perfil',
  'header.company': 'Minha empresa',
  'header.switch-company': 'Trocar de empresa',
  'header.company-public-profile': 'Perfil público da empresa',
  'header.settings': 'Configurações',
  'header.help': 'Ajuda',
  'header.sign-out': 'Sair',
  'header.user-avatar': 'Avatar do usuário',
  'header.open-drawer': 'Abrir o menu',

  // Help
  'help.title': 'Ajuda',

  'help.contact-title': 'Fale conosco',
  'help.contact-description':
    'Caso precisar de ajuda, entre em contato conosco através de um de nossos canais.',

  // Images
  'images.whatsapp-logo': 'WhatsApp logo',

  // Installments
  'installments.title': 'Parcelas',
  'installments.new-title': 'Nova parcela',
  'installments.edit-title': 'Edita parcela',

  'installments.days': 'Dias',
  'installments.installment': 'Parcela',

  'installments.installment-limit': 'Limite de parcelas atingido!',

  // Inventories
  'inventories.title': 'Inventários',
  'inventories.new-title': 'Novo inventário',

  'inventories.effective-at': 'Data de efetivação',
  'inventories.description': 'Descrição',
  'inventories.registered-at': 'Data de registro',

  'inventories.situation': 'Situação',
  'inventories.situation.undefined': 'Indefinido',
  'inventories.situation.opened': 'Aberto',
  'inventories.situation.finished': 'Finalizado',
  'inventories.situation.canceled': 'Cancelado',

  // Inventory Items
  'inventory-items.title': 'Itens',
  'inventory-items.edit-title': 'Edita item',
  'inventory-items.search-title': 'Pesquisar',

  'inventory-items.product-title': 'Produto',
  'inventory-items.stock': 'Estoque',
  'inventory-items.stock-amount': 'Estoque: {value}',
  'inventory-items.quantity': 'Quantidade',
  'inventory-items.quantity-amount': 'Quantidade: {value}',
  'inventory-items.brand': 'Marca',
  'inventory-items.category': 'Categoria',
  'inventory-items.prediction': 'Previsão',
  'inventory-items.prediction-amount': 'Previsão: {value}',

  'inventory-items.type': 'Tipo',
  'inventory-items.type.undefined': 'Indefinido',
  'inventory-items.type.entry': 'Entrada',
  'inventory-items.type.exit': 'Saída',

  'inventory-items.cannot-update-with-deleted-product':
    'Não é possível atualizar com o produto excluído!',

  'inventory-items.get-inventory-items-failure':
    'Falha ao carregar os itens do inventário! 😢',

  // Inventory Items Report
  'inventory-items.report.title': 'Inventário',

  // Material Table
  'material-table.add': 'Adicionar',
  'material-table.view-and-edit': 'Ver e editar',
  'material-table.print': 'Imprimir',
  'material-table.refresh': 'Atualizar',
  'material-table.edit': 'Editar',
  'material-table.download': 'Download',
  'material-table.settlement': 'Baixar',
  'material-table.delete': 'Deletar',
  'material-table.share': 'Compartilhar',
  'material-table.view': 'Ver',
  'material-table.filter': 'Filtrar',
  'material-table.map': 'Mostrar mapa',

  // Messages
  'messages.get-failure':
    'Falha ao carregar os registros, atualize a página! 😢',

  'messages.save-success': 'Cadastrado com sucesso! 🎉',
  'messages.save-failure': 'Falha ao salvar o registro, tente novamente! 😢',

  'messages.edit-success': 'Editado com sucesso! 🎉',
  'messages.edit-failure': 'Falha ao editar o registro, tente novamente! 😢',

  'messages.change-success': 'Alterado com sucesso! 🎉',
  'messages.change-failure': 'Falha ao alterar o registro, tente novamente! 😢',

  'messages.update-success': 'Atualizado com sucesso! 🎉',
  'messages.update-failure':
    'Falha ao atualizar o registro, tente novamente! 😢',

  'messages.delete-success': 'Excluído com sucesso! 🎉',
  'messages.delete-failure': 'Falha ao excluir o registro, tente novamente! 😢',

  'messages.settlement-success': 'Baixado com sucesso! 🎉',
  'messages.settlement-failure':
    'Falha ao baixar o registro, tente novamente! 😢',

  'messages.get-details-failure':
    'Falha ao carregar os detalhes do registro, atualize a página! 😢',

  'messages.error-500.without-connection-server':
    'Sem conexão com o servidor! 🤔',
  'messages.error-500.internal-server-error': 'Erro interno no servidor! 🤔',
  'messages.error-500.database-connection-error':
    'Erro interno no servidor! Código: 001 🤔',

  'messages.error-504.gateway-timeout':
    'Erro interno no servidor! Código: 002 🤔',

  'messages.resource-available-consultation':
    'Recurso disponível somente para consulta',
  'messages.need-changes-contact-support-1':
    'Caso necessite de alguma alteração, clique ',
  'messages.need-changes-contact-support-2': 'aqui',
  'messages.need-changes-contact-support-3':
    ' para entrar em contato com o suporte.',

  'messages.notice-title': 'Aviso',
  'messages.order-items-generation':
    'Os valores de porcentagem aplicada, porcentagem de comissão e se será negado desconto são meramente para base da geração dos itens pois eles podem ser posteriormente alterados individualmente.',

  'messages.preposition.female': 'da',
  'messages.preposition.male': 'do',
  'messages.conjuction.and': 'e',

  'messages.copied': 'Copiado!',

  // Modals - Share
  'modals.share': 'Compartilhar',
  'modals.share-by': 'Compartilhe via',
  'modals.share-link': 'Ou pelo link',

  // NCMS
  'ncms.title': 'NCMs',

  'ncms.id': 'Código',
  'ncms.description': 'Descrição',
  'ncms.category': 'Categoria',
  'ncms.level': 'Nível',

  'ncms.is-single-phase': 'Fase única',
  'ncms.is-single-phase.enabled': 'Sim',
  'ncms.is-single-phase.disabled': 'Não',

  'ncms.get-ncms-failure': 'Falha ao carregar os NCMs! 😢',

  // New Company
  'new-company.title': 'Nova empresa',

  'new-company.share-data-with': 'Compartilhar dados com',
  'new-company.share-data-with.all-group-companies':
    'Todas as empresas do grupo',
  'new-company.share-data-with.only-superior-companies':
    'Somente as empresas superiores',

  'new-company.create-button': 'Criar nova empresa',

  // Order Bills
  'order-bills.title': 'Controle de parcelas',
  'order-bills.edit-title': 'Edita parcela',
  'order-bills.installments-title': 'Parcelas',

  'order-bills.base-date': 'Data base',
  'order-bills.document-number': 'Nº do documento',
  'order-bills.document': 'Documento',
  'order-bills.payment-method-type': 'Meio de pagamento',
  'order-bills.due': 'Vencimento',
  'order-bills.due-at': 'Data de vencimento',
  'order-bills.amount': 'Valor',
  'order-bills.status': 'Status',
  'order-bills.installments-totalization': 'Total das parcelas: ',
  'order-bills.generate-installments': 'Gerar parcelas',
  'order-bills.delete-all-installments': 'Excluir todas as parcelas',

  // Order Items
  'order-items.title': 'Itens',
  'order-items.new-title': 'Novo item',
  'order-items.edit-title': 'Edita item',

  'order-items.product': 'Produto',
  'order-items.unit-measure': 'Unidade de medida',
  'order-items.type': 'Tipo',
  'order-items.description': 'Descrição',
  'order-items.quantity': 'Quantidade',
  'order-items.unit-value': 'Valor unitário',
  'order-items.cost-value': 'Valor de custo',
  'order-items.total': 'Total',
  'order-items.available': 'Disponível',
  'order-items.discount-value': 'Valor de desconto',
  'order-items.discount-percentage': '% de desconto',
  'order-items.commission-percentage': '% de comissão',
  'order-items.observation': 'Observação',
  'order-items.is-showcase': 'Mostruário',
  'order-items.not-controlled': 'Não controlado',
  'order-items.net-value': 'Valor líquido',
  'order-items.unit-value-discount-value': 'Valor unitário / Desconto',
  'order-items.price-lists': 'Tabelas de preços',
  'order-items.price-list': 'Tabela de preços',

  'order-items.value.quantity-available': '{value} disponível',
  'order-items.value.quantity-availables': '{value} disponíveis',

  'order-items.situation': 'Situação',
  'order-items.situation.normal': 'Normal',
  'order-items.situation.blocked': 'Bloqueado',
  'order-items.situation.canceled': 'Cancelado',
  'order-items.situation.undefined': 'Indefinido',

  'order-items.discount-percentage-readjusted':
    'O percentual de desconto será reajustado pois o valor do desconto ultrapassa 2 casas decimais! 🤔',

  'order-items.invalid-net-value-with-discount-check':
    'Erro ao calcular o preço líquido com desconto! 🤔',
  'order-items.invalid-net-value-check': 'Erro ao calcular o preço líquido! 🤔',

  'order-items.message.quantity-change':
    'Para alterar a quantidade, é necessário excluir este item e lançar novamente.',
  'order-items.message.product-without-stock':
    'Este produto está sem estoque! 🤔',
  'order-items.message.item-without-quantity':
    'A quantidade precisa ser maior do que 0! 🤔',
  'orders-items.message.not-allowed-to-decrease-product-price':
    'Não é permitido diminuir o valor do produto!',
  'order-items.message.cannot-apply-discount-to-product':
    'Não é permitido aplicar um desconto neste produto!',
  'order-items.message.item-total-exceeds-payment-method-maximum-amount':
    'A soma do item com o total existente do registro excede o valor máximo do método de pagamento!',

  // Orders
  'orders.title': 'Pedidos',
  'orders.new-title': 'Novo pedido',

  'orders.form-title.totalization': 'Totalização',
  'orders.form-title.discount': 'Desconto',

  'orders.code': 'Código',
  'orders.total': 'Total',
  'orders.payment-method': 'Método de pagamento',
  'orders.customer-supplier': 'Cliente / Fornecedor',
  'orders.seller-buyer': 'Vendedor / Comprador',
  'orders.customer': 'Cliente',
  'orders.supplier': 'Fornecedor',
  'orders.seller': 'Vendedor',
  'orders.buyer': 'Comprador',
  'orders.is-controls-stock': 'Controla estoque',
  'orders.customer-note': 'Nota do consumidor',
  'orders.store-note': 'Nota da loja',
  'orders.estimated-delivery-at': 'Data estimada de entrega',
  'orders.delivered-at': 'Data de entrega',
  'orders.discount-percentage': '% de desconto',
  'orders.is-delivered': 'Entregue',
  'orders.discount-coupon': 'Cupom de desconto',
  'orders.shipping-method': 'Método de envio',
  'orders.taxes': 'Taxas',
  'orders.total-products': 'Total de produtos',
  'orders.total-services': 'Total de serviços',
  'orders.shipping-fee': 'Taxa de envio',
  'orders.installments': 'Parcelas',
  'orders.tracking-code': 'Código de rastreio',
  'orders.sending-at': 'Data de envio',
  'orders.discount-value': 'Valor de desconto',
  'orders.observation': 'Observação',
  'orders.notes': 'Notas',
  'orders.shipping-delivery': 'Envio / Entrega',
  'orders.shipping-at': 'Data de despacho',
  'orders.registered-at': 'Data de registro',
  'orders.geographic-coordinate': 'Localização',
  'orders.commission-percentage': '% de comissão',
  'orders.transporter': 'Transportadora',
  'orders.map.title': 'Mapa de pedidos',
  'orders.map.customer-name': '<strong>Cliente</strong>: {value}',
  'orders.map.seller-name': '<strong>Vendedor</strong>: {value}',
  'orders.map.total': '<strong>Total</strong>: {value}',
  'orders.map.footer': 'Pedidos: {coordinatesTotal}/{total}',
  'orders.map.no-data': 'Não tem coordenadas nos pedidos atuais.',

  'orders.type': 'Tipo',
  'orders.type.budget': 'Orçamento',
  'orders.type.order': 'Pedido',
  'orders.type.sale': 'Venda',
  'orders.type.service': 'Serviço',
  'orders.type.purchase': 'Compra',
  'orders.type.undefined': 'Indefinido',

  'orders.subtype': 'Subtipo',
  'orders.subtype.budget': 'Orçamento',
  'orders.subtype.order': 'Pedido',
  'orders.subtype.pre-sale': 'Pré-venda',
  'orders.subtype.bonification': 'Bonificação',
  'orders.subtype.shipping': 'Remessa',
  'orders.subtype.exchange': 'Troca',
  'orders.subtype.purchase-order': 'Pedido de compra',
  'orders.subtype.sale': 'Venda',
  'orders.subtype.ready-delivery': 'Pronta entrega',
  'orders.subtype.service-order': 'Ordem de serviço',
  'orders.subtype.visit': 'Visita',
  'orders.subtype.purchase': 'Compra',
  'orders.subtype.undefined': 'Indefinido',

  'orders.status': 'Status',
  'orders.status.releasing': 'Esperando liberação',
  'orders.status.sorting': 'Esperando separação',
  'orders.status.checking': 'Esperando verificação',
  'orders.status.released': 'Liberado',
  'orders.status.undefined': 'Indefinido',

  'orders.situation': 'Situação',
  'orders.situation.opened': 'Aberto',
  'orders.situation.finished': 'Finalizado',
  'orders.situation.canceled': 'Cancelado',
  'orders.situation.undefined': 'Indefinido',

  'orders.operation-type': 'Tipo de operação',
  'orders.operation-type.entry': 'Entrada',
  'orders.operation-type.exit': 'Saída',
  'orders.operation-type.undefined': 'Indefinido',

  'orders.shipping-by': 'Frete por conta',
  'orders.shipping-by.sender': 'Remetente',
  'orders.shipping-by.recipient': 'Destinatário',
  'orders.shipping-by.sender-charge-shipping': 'Remetente (Cobrar frete)',
  'orders.shipping-by.third-parties': 'Terceiros',
  'orders.shipping-by.free-shipping': 'Sem frete',

  'orders.statistic-bar.relation-total': 'Em relação ao total',
  'orders.statistic-bar.total-opened': 'Total aberto',
  'orders.statistic-bar.total-finished': 'Total finalizado',

  'orders.discount-percentage-readjusted':
    'O percentual de desconto será reajustado pois o valor do desconto ultrapassa 2 casas decimais! 🤔',

  'orders.message.total-difference-one':
    'Há uma diferença de {value} do total ',
  'orders.message.total-difference-two': ' {type} com o total das parcelas!',
  'orders.message.total-difference-three':
    'Há uma diferença de {value} do total do registro com o total das parcelas!',
  'orders.message.not-allowed-to-apply-discount':
    'Não é permitido aplicar um desconto direto no pedido!',
  'orders.message.total-exceeds-payment-method-maximum-amount':
    'O total do registro excede o valor máximo do método de pagamento!',

  'orders.message.cannot-edit-values':
    'Não é possível editar valores pois existem parcelas geradas, exclua todas e tente novamente!',

  'orders.message.product-already-created':
    'Este produto já foi lançado anteriormente!',

  'orders.get-orders-failure': 'Falha ao carregar os pedidos! 😢',
  'orders.get-customer-failure': 'Falha ao carregar os clientes! 😢',
  'orders.get-supplier-failure': 'Falha ao carregar os fornecedores! 😢',
  'orders.get-seller-failure': 'Falha ao carregar os vendedores! 😢',
  'orders.get-totalization-failure':
    'Falha ao carregar as informações de totalização! 😢',

  // Orders report
  'orders.report.document.rg': 'RG: {value}',
  'orders.report.document.ie': 'IE: {value}',
  'orders.report.code': 'Código: {value}',
  'orders.report.date': 'Data: {value}',
  'orders.report.seller-man': 'Vendedor: {value}',
  'orders.report.seller-woman': 'Vendedora: {value}',
  'orders.report.buyer-man': 'Comprador: {value}',
  'orders.report.buyer-woman': 'Compradora: {value}',

  'orders.report.item.description': 'Descrição',
  'orders.report.item.quantity': 'Quantidade',
  'orders.report.item.value': 'Valor',
  'orders.report.item.total': 'Total',
  'orders.report.item.unit-measure': 'Unid.',
  'orders.report.item.total-product': 'Total dos produtos',
  'orders.report.item.total-service': 'Total dos serviços',
  'orders.report.item.discount': 'Desconto',

  'orders.report.bills.document': 'Documento',
  'orders.report.bills.payment-method-type': 'Meio de pagamento',
  'orders.report.bills.due-at': 'Data de vencimento',
  'orders.report.bills.amount': 'Valor',

  'orders.report.observation.title': 'Observação',

  'orders.report.signature.title': 'Assinatura',

  'orders.report.failure.person-disabled':
    'Falha ao gerar relatório, pessoa desativada! 😢',
  'orders.report.failure.person-canceled':
    'Falha ao gerar relatório, pessoa cancelada! 😢',

  'orders.report.failure.seller-deleted':
    'Falha ao gerar relatório, vendedor excluído! 😢',
  'orders.report.failure.buyer-deleted':
    'Falha ao gerar relatório, comprador excluído! 😢',

  'orders.report.failure.seller-disabled':
    'Falha ao gerar relatório, vendedor desativado! 😢',
  'orders.report.failure.buyer-disabled':
    'Falha ao gerar relatório, comprador desativado! 😢',

  'orders.report.failure.customer-deleted':
    'Falha ao gerar relatório, cliente excluído! 😢',
  'orders.report.failure.supplier-deleted':
    'Falha ao gerar relatório, fornecedor excluído! 😢',

  'orders.report.failure.customer-disabled':
    'Falha ao gerar relatório, cliente desativado! 😢',
  'orders.report.failure.supplier-disabled':
    'Falha ao gerar relatório, fornecedor desativado! 😢',

  'orders.report.failure.no-items-created':
    'Falha ao gerar relatório, não há itens lançados! 😢',

  'orders.report.failure.no-bills-created':
    'Falha ao gerar relatório, não há parcelas criadas! 😢',

  'orders.report.failure.payment-method-deleted':
    'Falha ao gerar relatório, método de pagamento excluído! 😢',

  // Owners
  'owners.title': 'Proprietários',
  'owners.new-title': 'Novo proprietário',

  'owners.deleted-owner': 'Proprietário excluído',
  'owners.disabled-owner': 'Proprietário desativado',

  'owners.check-exists.become-owner': 'Tornar proprietário',
  'owners.check-exists.exists-view-title':
    'Foi encontrado um proprietário com esse documento:',
  'owners.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é proprietário:',
  'owners.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um proprietário nesta empresa:',

  'owners.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como proprietário na empresa:',

  'owners.get-owners-failure': 'Falha ao carregar os proprietários! 😢',

  // Page404
  'page404.title': 'Página não encontrada',

  'page404.404': 'Erro 404',
  'page404.description':
    'A página que você está procurando não foi encontrada.',
  'page404.return-to-website': 'Voltar ao site',

  // Payment
  'payment.invalid-total-check': 'Erro ao calcular o valor total! 🤔',

  // Payment Methods
  'payment-methods.title': 'Métodos de pagamento',
  'payment-methods.title.bills-to-pay': 'Métodos de pagamento - Contas a pagar',
  'payment-methods.title.bills-to-receive':
    'Métodos de pagamento - Contas a receber',
  'payment-methods.new-title': 'Novo método de pagamento',

  'payment-methods.description': 'Descrição',
  'payment-methods.installments': 'Parcelas',
  'payment-methods.grace-days': 'Dias de carência',
  'payment-methods.discount-percentage': '% de desconto',
  'payment-methods.increase-percentage': '% de acréscimo',
  'payment-methods.payment-method-type': 'Meio de pagamento',
  'payment-methods.maximum-amount': 'Valor máximo',

  'payment-methods.frequency': 'Frequência',
  'payment-methods.frequency.daily': 'Diária',
  'payment-methods.frequency.monthly': 'Mensal',

  'payment-methods.interest-types': 'Tipo de juros',
  'payment-methods.interest-types.simple': 'Simples',
  'payment-methods.interest-types.compound': 'Composto',

  'payment-methods.is-enabled': 'Ativo',
  'payment-methods.is-enabled.enabled': 'Ativado',
  'payment-methods.is-enabled.disabled': 'Desativado',
  'payment-methods.is-enabled.undefined': 'Indefinido',

  'payment-methods.cashier': 'Caixa',
  'payment-methods.bank-deposit': 'Depósito bancário',
  'payment-methods.bank-transfer': 'Transferência bancária',
  'payment-methods.bank-slip': 'Boleto bancário',
  'payment-methods.credit-card': 'Cartão de crédito',
  'payment-methods.debit-card': 'Cartão de débito',
  'payment-methods.check': 'Cheque',
  'payment-methods.others': 'Outros',

  'payment-methods.is-default': 'Método de pagamento padrão',

  'payment-methods.get-payment-methods-failure':
    'Falha ao carregar os métodos de pagamento! 😢',

  // Payment Methods Types
  'payment-methods-types.title': 'Meios de pagamentos',

  'payment-methods-types.display-label': 'Meio de pagamento',
  'payment-methods-types.code': 'Código',
  'payment-methods-types.description': 'Descrição',

  'payment-methods-types.undefined': 'Indefinido',

  'payment-methods-types.get-payment-methods-types-failure':
    'Falha ao carregar os meios de pagamentos! 😢',

  // Persons
  'persons.title': 'Pessoas',
  'persons.form-title.personal-information': 'Informações pessoais',
  'persons.form-title.additional-information': 'Informações adicionais',
  'persons.form-title.credit': 'Crédito',
  'persons.form-title.personal-documents': 'Documentos pessoais',
  'persons.form-title.additional-documents': 'Documentos adicionais',
  'persons.form-title.occupation': 'Ocupação',
  'persons.form-title.contact': 'Contato',
  'persons.form-title.values': 'Valores',

  'persons.add-modal.title': 'Verificação de documento',

  'persons.name': 'Nome',
  'persons.nickname': 'Apelido',
  'persons.trading-name': 'Nome fantasia',
  'persons.born-at': 'Data de nascimento',
  'persons.is-rural-producer': 'Produtor rural',
  'persons.foundation-date': 'Data de fundação',
  'persons.profession': 'Profissão',
  'persons.nationality': 'Nacionalidade',
  'persons.contact': 'Contato',
  'persons.website': 'Site',
  'persons.occupation': 'Ocupação',
  'persons.workplace': 'Local de trabalho',
  'persons.credit-limit': 'Limite de crédito',
  'persons.without-credit-limit': 'Sem limite',
  'persons.price-list': 'Tabela de preço',
  'persons.payment-method': 'Método de pagamento',
  'persons.fixed-discount-percentage': '% de desconto',
  'persons.observation': 'Observação',
  'persons.registered-at': 'Data de registro',
  'persons.branch-activity': 'Ramo de atividade',
  'persons.covenant': 'Convênio',
  'persons.address': 'Endereço',
  'persons.email': 'Email',
  'persons.phone': 'Telefone',
  'persons.is-main-phone-email': 'Telefone / Email',
  'persons.maximum-discount-percentage': '% máxima de desconto',
  'persons.commission-percentage': '% de comissão',
  'persons.slug': 'Slug',
  'persons.is-blocked-installment-sale': 'Bloquear venda a prazo',

  'persons.document.valid': 'Válido',
  'persons.document.invalid': 'Inválido',
  'persons.document.undefined': 'Indefinido',
  'persons.documents.dispatching-agency': 'Órgão expedidor',
  'persons.documents.state': 'Estado',

  'persons.ie': 'IE',
  'persons.set-exempt': 'Definir isento',
  'persons.rg': 'RG',
  'persons.required-rg': 'O campo RG é obrigatório',
  'persons.im': 'IM',

  'persons.person-kind': 'Tipo de pessoa',
  'persons.natural-person': 'Física',
  'persons.legal-person': 'Jurídica',

  'persons.parents-name': 'Filiação',
  'persons.father-filiation': 'Nome do pai',
  'persons.mother-filiation': 'Nome da mãe',

  'persons.gender': 'Sexo',
  'persons.genders.female': 'Feminino',
  'persons.genders.male': 'Masculino',

  'persons.situation': 'Situação',
  'persons.situation.normal': 'Normal',
  'persons.situation.blocked': 'Bloqueado',
  'persons.situation.canceled': 'Cancelado',
  'persons.situation.debtor': 'Devedor',
  'persons.situation.undefined': 'Indefinido',

  'persons.type': 'Tipo',
  'persons.type.supplier': 'Fornecedor',
  'persons.type.customer': 'Cliente',
  'persons.type.company': 'Empresa',
  'persons.type.employee': 'Funcionário',
  'persons.type.seller': 'Vendedor',
  'persons.type.driver': 'Motorista',
  'persons.type.owner': 'Proprietário',
  'persons.type.property': 'Propriedade',
  'persons.type.technical': 'Técnico',
  'persons.type.transporter': 'Transportadora',
  'persons.type.supervisor': 'Supervisor',
  'persons.type.promoter': 'Promotor',

  'persons.is-enabled': 'Ativo',
  'persons.is-enabled.enabled': 'Ativado',
  'persons.is-enabled.disabled': 'Desativado',
  'persons.is-enabled.undefined': 'Indefinido',

  'persons.marital-status': 'Estado civil',
  'persons.marital-status.married': 'Casado(a)',
  'persons.marital-status.single': 'Solteiro(a)',
  'persons.marital-status.divorced': 'Divorciado(a)',
  'persons.marital-status.stable-union': 'União estável',
  'persons.marital-status.widowed': 'Viúvo(a)',

  'persons.without-nickname': '(Não informado)',

  'persons.deleted-person': 'Pessoa excluída',
  'persons.disabled-person': 'Pessoa desativada',

  'persons.check-exists.not-verified-title': 'Verificação por CPF/CNPJ',
  'persons.check-exists.not-verified-description':
    'Informe para verificar se há uma pessoa existente ou ignore deixando vazio.',

  'persons.check-exists.invalid-document-title': 'Erro de validação!',
  'persons.check-exists.invalid-document-description':
    'O CPF/CNPJ não passou na validação do documento.',

  'persons.check-exists.connection-error-title': 'Erro de conexão!',
  'persons.check-exists.connection-error-description':
    'Sem conexão com o servidor.',

  'persons.check-exists.not-exists-title': 'Sucesso!',
  'persons.check-exists.not-exists-description':
    'Não foi encontrado nenhuma pessoa com esse documento.',

  'persons.check-exists.complete-registration': 'Completar cadastro',
  'persons.check-exists.view-registration': 'Visualizar cadastro',

  'persons.check-exists.edit-document-title': 'Aviso!',
  'persons.check-exists.edit-document-description':
    'Não foi encontrado nenhuma pessoa com esse documento. Ao alterar o documento e trocar o tipo de pessoa, os demais documentos serão limpados.',
  'persons.check-exists.empty-edit-document-description':
    'Informe para verificar se há uma pessoa existente ou ignore deixando vazio. Ao alterar o documento e trocar o tipo de pessoa, os demais documentos serão limpados.',
  'persons.check-exists.edit-document': 'Alterar documento',

  'persons.check-exists.exists-valid-document-title': 'Documento válido!',
  'persons.check-exists.exists-valid-document-description':
    'Este documento é válido e está cadastrado para esta pessoa.',

  'persons.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada na empresa:',

  // Phones
  'phones.title': 'Telefones',
  'phones.new-title': 'Novo telefone',
  'phones.edit-title': 'Edita telefone',

  'phones.number': 'Número',
  'phones.operator': 'Operadora',
  'phones.contact': 'Contato',
  'phones.call': 'Ligar para ',
  'phones.without-ddi': 'Sem DDI',
  'phones.select-ddi': 'Selecionar',

  'phones.type': 'Tipo',
  'phones.types.general': 'Geral',
  'phones.types.cell-phone': 'Celular',
  'phones.types.contact': 'Contato',
  'phones.types.fax': 'Fax',
  'phones.types.branch-office': 'Filial',
  'phones.types.landline': 'Fixo',
  'phones.types.headquarters': 'Matriz',
  'phones.types.residential': 'Residencial',
  'phones.types.work': 'Trabalho',

  'phones.is-whatsapp': 'WhatsApp',
  'phones.open-whatsapp': 'Abrir no WhatsApp',

  'phones.is-main': 'Principal',
  'phones.is-main-phone': 'Telefone principal',
  'phones.error-delete-is-main':
    'Telefone principal não pode ser excluído quando tem mais de um! 🤔',
  'phones.error.is-without-ddi-with-is-whatsapp':
    'Não é possível salvar um telefone sem ddi com WhatsApp!',

  // Pixs
  'pixs.title': 'Pix',
  'pixs.panel-title': 'Pixs',
  'pixs.show-title': 'Visualizar Pix',
  'pixs.with-register': 'Com registro',
  'pixs.without-register': 'Sem registro',
  'pixs.with-register-title': 'Pix com registro',
  'pixs.without-register-title': 'Pix sem registro',
  'pixs.settlement-pix-title': 'Baixar Pix',
  'pixs.select-pix-account-title': 'Selecione uma conta Pix',
  'pixs.emit-pix-title': 'Emitir Pix',
  'pixs.company-pix-title':
    'Configura empresa para emissão do Pix via Datasmart',
  'pixs.sending-data-to-company-email':
    'Essas informações serão enviadas para o email principal da empresa.',

  'pixs.brand': 'Logo do Pix',

  'pixs.copy-qr-code': 'Copiar código do QR Code',
  'pixs.qr-code': 'QR Code do Pix',
  'pixs.amount': 'Valor',
  'pixs.amount-paid': 'Valor pago',
  'pixs.description': 'Descrição',
  'pixs.duration': 'Data de vencimento',
  'pixs.assignor-name': 'Nome',
  'pixs.bank-name': 'Banco',
  'pixs.payer-name': 'Pagador',
  'pixs.issued-at': 'Data de emissão',
  'pixs.due-at': 'Data de vencimento',
  'pixs.settlemented-at': 'Data da baixa',
  'pixs.errors': 'Erros',
  'pixs.is-pix-qrcode-opened': 'Pix visualizado',
  'pixs.payments': 'Pagamentos',
  'pixs.subject': 'Pagamento do Pix',
  'pixs.company-name': 'Empresa',
  'pixs.pix-key': 'Chave Pix',
  'pixs.account': 'Conta',
  'pixs.client-id': 'Identificador do acesso',
  'pixs.client-secret': 'Chave secreta do cliente',
  'pixs.dynamic-pix': 'Pix com registro',
  'pixs.static-pix': 'Pix sem registro',

  'pixs.button-label.emit-pix': 'Emitir Pix',
  'pixs.message.wait-pix-request':
    'Por favor, aguarde {value} segundos para completar a emissão do Pix.',
  'pixs.message.current-pix-status': 'O Pix está com o status final: {value}',

  'pixs.type': 'Tipo',
  'pixs.type.static': 'Sem registro',
  'pixs.type.dynamic': 'Com registro',

  'pixs.pix-status': 'Status do Pix',
  'pixs.status': 'Status',
  'pixs.status.undefined': 'Indefinido',
  'pixs.status.saved': 'Salvo',
  'pixs.status.active': 'Ativado',
  'pixs.status.liquidated': 'Pago',
  'pixs.status.psp-removed': 'Removido pelo banco',
  'pixs.status.rejected': 'Rejeitado',
  'pixs.status.failed': 'Falhou',

  'pixs.last-automatic-update': 'Última atualização automática',
  'pixs.last-automatic-update.pix-created': 'Criado',
  'pixs.last-automatic-update.pix-failed': 'Falho',
  'pixs.last-automatic-update.pix-successful': 'Emitido',
  'pixs.last-automatic-update.pix-payment-refunded': 'Pagamento reembolsado',
  'pixs.last-automatic-update.pix-refunded': 'Reembolsado',
  'pixs.last-automatic-update.pix-paid': 'Pago',
  'pixs.last-automatic-update.pix-expired': 'Expirado',
  'pixs.last-automatic-update.pix-expired-without-payment':
    'Expirado sem pagamento',
  'pixs.last-automatic-update.pix-bank-rejected': 'Rejeitado pelo banco',

  'pixs.share-pix.title': 'Compartilhar Pix',
  'pixs.share-pix.share-by': 'Compartilhe esse Pix via',
  'pixs.share-pix.share-link': 'Ou pelo link',
  'pixs.share-pix.title-message': 'Informações referente ao Pix',
  'pixs.share-pix.body-message':
    'Para efetuar o pagamento de {value} pelo Pix, entre em',

  'pixs.internal-error': 'Erro interno!',

  // Price List Items
  'price-list-items.title': 'Itens',
  'price-list-items.new-title': 'Novos itens',
  'price-list-items.edit-title': 'Edita item',

  'price-list-items.price-list-title': 'Título',
  'price-list-items.cost-value': 'Preço de custo',
  'price-list-items.percentage-applied': '% aplicada',
  'price-list-items.price': 'Preço',
  'price-list-items.commission-percentage': '% de comissão',
  'price-list-items.discount-percentage': '% de desconto',
  'price-list-items.is-denied-discount': 'Negar desconto',

  'price-list-items.get-price-list-items-failure':
    'Falha ao carregar os itens da tabela de preço! 😢',

  // Price Lists
  'price-lists.title': 'Tabelas de preços',
  'price-lists.new-title': 'Nova tabela de preços',

  'price-lists.name': 'Nome',
  'price-lists.percentage-applied': '% aplicada',
  'price-lists.commission-percentage': '% de comissão',
  'price-lists.price-list-reference': 'Tabela de preços de referência',
  'price-lists.covenant': 'Convênio',
  'price-lists.discount-percentage': '% de desconto',
  'price-lists.started-at': 'Data de início',
  'price-lists.ended-at': 'Data de término',
  'price-lists.is-default': 'Tabela padrão',
  'price-lists.is-added-manually': 'Adicionar itens manualmente',

  'price-lists.is-enabled': 'Ativo',
  'price-lists.is-enabled.enabled': 'Ativado',
  'price-lists.is-enabled.disabled': 'Desativado',
  'price-lists.is-enabled.undefined': 'Indefinido',

  'price-lists.is-promotional': 'Promocional',
  'price-lists.is-promotional.enabled': 'Sim',
  'price-lists.is-promotional.disabled': 'Não',

  'price-lists.is-enabled-to-order': 'Habilitado para pedido / venda',
  'price-lists.is-enabled-to-order.enabled': 'Sim',
  'price-lists.is-enabled-to-order.disabled': 'Não',

  'price-lists.is-denied-discount': 'Negar desconto',
  'price-lists.is-denied-discount.enabled': 'Sim',
  'price-lists.is-denied-discount.disabled': 'Não',

  'price-lists.get-price-lists-failure':
    'Falha ao carregar as tabelas de preços! 😢',

  // Products
  'products.title': 'Produtos',
  'products.new-title': 'Novo produto',
  'products.edit-price-list': 'Edita item',
  'products.search-product': 'Pesquisar produto',

  'products.discounts.new-title': 'Novo desconto',
  'products.discounts.edit-title': 'Editar desconto',

  'products.discounts.title': 'Tipo de escalonamento do produto',
  'products.discounts.types.discount': 'Desconto',
  'products.discounts.types.price-list': 'Tabela de preço',

  'products.form-title.exhibition': 'Exibição',
  'products.form-title.identification': 'Identificação',
  'products.form-title.costs-and-discounts': 'Custos e Descontos',
  'products.form-title.price-lists': 'Tabelas de preços',
  'products.form-title.discounts': 'Descontos',
  'products.form-title.promotion': 'Promoção',
  'products.form-title.classification': 'Classificação',
  'products.form-title.general': 'Geral',
  'products.form-title.stock': 'Estoque',
  'products.form-title.dimensions': 'Dimensões',
  'products.form-title.tributations': 'Tributações',

  'products.ean': 'EAN',
  'products.sku': 'SKU',
  'products.internal-code': 'Código interno',
  'products.name': 'Nome',
  'products.product-title': 'Título',
  'products.stock': 'Estoque',
  'products.minimum-stock': 'Estoque mínimo',
  'products.manufacturer-code': 'Código do fabricante',
  'products.reference': 'Referência',
  'products.registered-at': 'Data de registro',
  'products.is-hot': 'Destaque',

  'products.description': 'Descrição',
  'products.description-small': 'Descrição reduzida',

  'products.cost-value': 'Preço de custo',
  'products.dollar-cost-price': 'Preço de custo em dólar',

  'products.maximum-discount-percentage': '% máxima de desconto',
  'products.commission-percentage': '% de comissão',
  'products.percentage-applied': '% aplicada',
  'products.price': 'Preço',
  'products.commission': 'Comissão',
  'products.list-name': 'Tabela de preços',
  'products.is-denied-discount': 'Negar desconto',
  'products.is-default': 'Tabela padrão',

  'products.discount-percentage': '% de desconto',
  'products.quantity-minimum-maximum': 'Qtde. mínima / máxima',
  'products.quantity-minimum-value': 'Mínima: {value}',
  'products.quantity-maximum-value': 'Máxima: {value}',
  'products.quantity-minimum': 'Quantidade mínima',
  'products.quantity-maximum': 'Quantidade máxima',

  'products.unit-measure': 'Unidade de medida (saída)',
  'products.input-unit-measure': 'Unidade de medida (entrada)',
  'products.is-allowed-negative-stock': 'Permitir estoque negativo',
  'products.is-controlled-stock': 'Controla estoque',
  'products.brand': 'Marca',
  'products.promotional-price': 'Preço de promoção',
  'products.promotion-started-at': 'Primeiro dia de promoção',
  'products.promotion-ended-at': 'Último dia de promoção',
  'products.model': 'Modelo',
  'products.category': 'Categoria',
  'products.department': 'Departamento',
  'products.is-release': 'Lançamento',
  'products.warranty': 'Garantia',
  'products.is-free-shipping': 'Frete grátis',
  'products.http': 'HTTP',
  'products.https': 'HTTPS',
  'products.is-available-profile': 'Disponível no perfil da empresa (catálogo)',
  'products.is-available-mobile': 'Disponível no celular',
  'products.weight': 'Peso',
  'products.length': 'Comprimento',
  'products.width': 'Largura',
  'products.height': 'Altura',
  'products.cubic-weight': 'Peso cúbico',
  'products.ncm': 'NCM',
  'products.set-without-gtin': 'Definir sem gtin',
  'products.last-sale-at': 'Última venda',
  'products.last-purchase-at': 'Última compra',

  'products.situation': 'Situação',
  'products.situation.normal': 'Normal',
  'products.situation.canceled': 'Cancelado',
  'products.situation.undefined': 'Indefinido',

  'products.type': 'Tipo',
  'products.type.product': 'Produto',
  'products.type.service': 'Serviço',

  'products.is-enabled': 'Ativo',
  'products.is-enabled.enabled': 'Ativado',
  'products.is-enabled.disabled': 'Desativado',
  'products.is-enabled.undefined': 'Indefinido',

  'products.deleted-product': 'Produto excluído',
  'products.canceled-product': 'Produto cancelado',

  'products.no-products-found': 'Nenhum produto encontrado!',
  'products.discount-exceeds-maximum-discount-allowed':
    'A porcentagem de desconto excede o máximo de desconto permitido!',

  'products.min-date-promotion':
    'A data não pode ser anterior à data de início',

  'products.message.promotion-date': 'Em promoção de {start} até {end}',

  'products.get-products-failure': 'Falha ao carregar os produtos! 😢',
  'products.failed-to-search-products': 'Falha ao pesquisar produtos! 😢',

  // Products - Share
  // TODO: Reutilizar futuramente
  // 'products-share.message.greetings': 'Olá! 😀',
  // 'products-share.message.company-name': 'Aqui é a {value}.',
  // 'products-share.message.our-catalog':
  //   '🛒 Aproveite as promoções da nossa loja 🥳, acessando nosso catálogo de produtos.',
  // 'products-share.message.check-link': 'Confira! Acesse 👇',
  // 'products-share.message.support': 'Qualquer dúvida, estamos à disposição! 😊',

  'products-share.message.greetings': 'Olá!',
  'products-share.message.company-name': 'Aqui é a {value}.',
  'products-share.message.our-catalog':
    'Segue o nosso catálogo de produtos e aproveite as nossas ofertas!',

  'products-share.email-subject': 'Catálogo de produtos',

  // Product requests
  'product-requests.priority.low': 'Baixa',
  'product-requests.priority.medium': 'Média',
  'product-requests.priority.high': 'Alta',

  // Profile
  'profile.title': 'Perfil',

  'profile.change-image': 'Alterar imagem',
  'profile.full-name': 'Nome completo',
  'profile.username': 'Nome de usuário',
  'profile.email': 'Email',

  'profile.current-password': 'Senha atual',
  'profile.new-password': 'Nova senha',
  'profile.confirm-password': 'Confirme sua nova senha',
  'profile.equal-passwords': 'A nova senha e a confirmação devem ser iguais',
  'profile.diferent-passwords':
    'A nova senha e a senha atual devem ser diferentes! 🤔',

  // Promoter
  'promoters.title': 'Promotores',
  'promoters.new-title': 'Novo promotor',
  'promoters.check-exists.become-promoter': 'Tornar promotor',
  'promoters.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um promotor nesta empresa:',
  'promoters.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é promotor:',
  'promoters.check-exists.exists-view-title':
    'Foi encontrado um promoter com esse documento:',
  'promoters.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como promotor na empresa:',

  'promoters.get-promoter-failure': 'Falha ao carregar o promotor! 😢',
  'promoters.get-promoters-failure': 'Falha ao carregar os promotores! 😢',

  // Region cities
  'region-cities.cities': 'Cidades',

  'region-cities.remove': 'Remover',

  'region-cities.name': 'Nome',
  'region-cities.city': 'Cidade',
  'region-cities.ibge-code': 'Código IBGE',
  'region-cities.state': 'Estado',
  'region-cities.default-postal-code': 'CEP Padrão',

  // Region customers
  'region-customers.manage-customers': 'Gerenciar clientes',

  'region-customers.add-customer': 'Adicionar',
  'region-customers.added-customers': 'Adicionados',

  'region-customers.city-name': 'Cidade: {value}',

  'region-customers.name': 'Nome',
  'region-customers.state': 'Estado',
  'region-customers.city': 'Cidade',

  'region-customers.get-customers-failure': 'Falha ao carregar os clientes!',

  // Regions
  'regions.title': 'Regiões',
  'regions.region': 'Região',
  'regions.new-title': 'Nova região',

  'regions.name': 'Nome',
  'regions.cities': 'Cidades',
  'regions.sellers': 'Vendedores',
  'regions.customers': 'Clientes',

  'regions.is-enabled': 'Ativo',
  'regions.is-enabled.undefined': 'Indefinido',
  'regions.is-enabled.enabled': 'Ativado',
  'regions.is-enabled.disabled': 'Desativado',

  // Regions sellers
  'region-sellers.manage-sellers': 'Gerenciar vendedores',

  'region-sellers.name': 'Nome',

  // Registrations
  'registrations.title': 'Registros de matrícula',
  'registrations.new-title': 'Novo registro',
  'registrations.edit-title': 'Edita registro',

  'registrations.registration': 'Registro',

  // Reports
  'reports.title': 'Relatórios',
  'reports.filter-title': 'Filtros',

  'reports.data-uninformed': 'Não informado',

  'reports.sections.customer-title': 'Clientes',

  'reports.sections.customers-by-cities.report-title': 'Clientes por cidades',
  'reports.sections.customers-by-cities.without-city': 'Sem cidade',
  'reports.sections.customers-by-cities.name': 'Nome',
  'reports.sections.customers-by-cities.phone-email': 'Telefone / Email',
  'reports.sections.customers-by-cities.contact': 'Contato',
  'reports.sections.customers-by-cities.address': 'Endereço',

  'reports.sections.product-title': 'Produtos',

  'reports.sections.products-by-prices.report-title': 'Produtos por preços',
  'reports.sections.products-by-prices.title': 'Título',
  'reports.sections.products-by-prices.table.title': 'Nome',
  'reports.sections.products-by-prices.ean': 'EAN: {value}',
  'reports.sections.products-by-prices.table.ean': 'EAN',
  'reports.sections.products-by-prices.internal-code':
    'Código interno: {value}',
  'reports.sections.products-by-prices.table.internal-code': 'Código interno',
  'reports.sections.products-by-prices.name': 'Nome',
  'reports.sections.products-by-prices.cost-value': 'Valor de custo: {value}',
  'reports.sections.products-by-prices.table.cost-value': 'Valor de custo',
  'reports.sections.products-by-prices.percentage-applied':
    'Porcentagem aplicada',
  'reports.sections.products-by-prices.price': 'Preço',

  'reports.sections.products-by-prices.filter.minimum-cost-value':
    'Mínimo valor de custo',
  'reports.sections.products-by-prices.filter.maximum-cost-value':
    'Máximo valor de custo',
  'reports.sections.products-by-prices.filter.minimum-price': 'Preço mínimo',
  'reports.sections.products-by-prices.filter.maximum-price': 'Preço máximo',

  'reports.sections.products-top-selling.report-title':
    'Produtos mais vendidos',
  'reports.sections.products-top-selling.title': 'Título',
  'reports.sections.products-top-selling.average-unit-value':
    'Média valor unitário',
  'reports.sections.products-top-selling.total': 'Total',
  'reports.sections.products-top-selling.quantity': 'Quantidade',
  'reports.sections.products-top-selling.total-average':
    'Total média valor unitário',
  'reports.sections.products-top-selling.total-quantity': 'Total quantidade',

  'reports.sections.products-requests.report-title': 'Produtos requisitados',
  'reports.sections.products-requests.product': 'Produto',
  'reports.sections.products-requests.quantity': 'Quantidade',
  'reports.sections.products-requests.priority': 'Prioridade',
  'reports.sections.products-requests.created-at': 'Criado em',

  'reports.sections.products-requests.filter.date': 'Data',

  'reports.sections.products-top-selling.filter.type': 'Tipo',
  'reports.sections.products-top-selling.filter.date': 'Data',
  'reports.sections.products-top-selling.filter.seller': 'Vendedor',
  'reports.sections.products-top-selling.filter.customer': 'Cliente',
  'reports.sections.products-top-selling.filter.list-by': 'Listar por',
  'reports.sections.products-top-selling.filter.listed-by': 'Listado por',

  'reports.generating-report-message':
    'Relatório sendo preparado, aguarde um momento!',

  'reports.of': 'de',
  'reports.empty-data': 'Não há informações para ser exibidas.',
  'reports.unavailable-report': 'Relatório indisponível no momento.',
  'reports.customized-date': 'De {start} para {end}',
  'reports.searched': 'Pesquisado: {value}',
  'reports.ordered-by': 'Ordenado por: {value} {direction}',
  'reports.direction.ascending': 'ascendente',
  'reports.direction.descending': 'descendente',

  'reports.failed-to-load-image': 'Falha ao carregar a imagem! 😢',
  'reports.failed-to-generate-report': 'Falha ao gerar o relatório! 😢',

  // Reset Password
  'reset-password.title': 'Redefinir senha',

  'reset-password.form-title': 'Crie sua nova senha',
  'reset-password.form-description':
    'Digite sua nova senha e depois a confirme.',

  'reset-password.password': 'Senha',
  'reset-password.confirm-password': 'Confirme a senha',
  'reset-password.password-min-length':
    'A senha deve ter no mínimo 8 caracteres.',

  'reset-password.message.equal-passwords':
    'A senha e a confirmação devem ser iguais!',

  'reset-password.message.validation-failure.title': 'Erro ao alterar a senha',
  'reset-password.message.validation-failure.description':
    'Foi encontrado algum erro ao tentar alterar a senha. Por favor, tente novamente mais tarde.',

  'reset-password.message.update-success':
    'Sua senha foi alterada com sucesso!',
  'reset-password.message.update-failure': 'Falha ao tentar alterar a senha!',

  // Road Map
  'road-map.title': 'Roteiros',
  'road-map.new-road-map': 'Novo roteiro',

  'road-map.form-title.promoter': 'Promotor',

  'road-map.manage-schedule': 'Gerenciar agenda',

  //  Rural Properties
  'rural-properties.title': 'Fazendas',
  'rural-properties.new-title': 'Nova fazenda',

  'rural-properties.name': 'Nome',
  'rural-properties.website': 'Site',
  'rural-properties.size-in-bushels': 'Tamanho em alqueires',
  'rural-properties.size-in-hectares': 'Tamanho em hectares',
  'rural-properties.incra': 'INCRA',
  'rural-properties.nirf': 'NIRF',
  'rural-properties.car': 'CAR',
  'rural-properties.address': 'Endereço',
  'rural-properties.registered-at': 'Data de registro',

  'rural-properties.deleted-property': 'Propriedade excluída',
  'rural-properties.disabled-property': 'Propriedade desativada',

  'rural-properties.cadesp': 'CADESP',
  'rural-properties.cadesp-expiration-at': 'Vencimento CADESP',
  'rural-properties.cadesp.undefined': 'Indefinido',
  'rural-properties.cadesp.expire-today': 'Vence hoje',
  'rural-properties.cadesp.expire-in': 'Vence a',
  'rural-properties.cadesp.expired-in': 'Vencido há',

  'rural-properties.situation': 'Situação',
  'rural-properties.situation.canceled': 'Cancelado',
  'rural-properties.situation.normal': 'Normal',
  'rural-properties.situation.undefined': 'Indefinido',

  'rural-properties.is-enabled': 'Ativo',
  'rural-properties.is-enabled.enabled': 'Ativado',
  'rural-properties.is-enabled.disabled': 'Desativado',
  'rural-properties.is-enabled.undefined': 'Indefinido',

  'rural-properties.table-localization.delete-owner-text':
    'Tem certeza que deseja excluir esse proprietário desta fazenda?',

  'rural-properties.get-properties-failure':
    'Falha ao carregar as propriedades! 😢',

  // Seller Companies
  'seller-companies.bound-title': 'Vincular empresas',

  'seller-companies.name': 'Nome',
  'seller-companies.permission-for-sale': 'Permissão para venda',

  'seller-companies.get-seller-companies-failure':
    'Falha ao carregar as empresas! 😢',

  // Sellers
  'sellers.title': 'Vendedores',
  'sellers.new-title': 'Novo vendedor',

  'sellers.deleted-seller': 'Vendedor excluído',
  'sellers.disabled-seller': 'Vendedor desativado',

  'sellers.check-exists.become-seller': 'Tornar vendedor',
  'sellers.check-exists.exists-view-title':
    'Foi encontrado um vendedor com esse documento:',
  'sellers.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é vendedor:',
  'sellers.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um vendedor nesta empresa:',

  'sellers.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como vendedor na empresa:',

  'sellers.messages.create-bound-success': 'Vinculado com sucesso! 🎉',
  'sellers.messages.create-bound-failure':
    'Falha ao criar vínculo, tente novamente! 😢',

  'sellers.messages.delete-bound-success': 'Vínculo excluído com sucesso! 🎉',
  'sellers.messages.delete-bound-failure':
    'Falha ao excluir vínculo, tente novamente! 😢',

  'sellers.get-seller-failure': 'Falha ao carregar o vendedor! 😢',
  'sellers.get-sellers-failure': 'Falha ao carregar os vendedores! 😢',

  // Seller Users
  'seller-users.bound-title': 'Vincular usuários',

  'seller-users.name': 'Nome',
  'seller-users.email': 'Email',
  'seller-users.bound': 'Vincular usuário',

  'seller-users.button-label.bound': 'Vincular',
  'seller-users.button-label.remove-bound': 'Desvincular',

  'seller-users.messages.get-seller-users-failure':
    'Falha ao carregar os usuários! 😢',

  // Settings
  'settings.title': 'Configurações',
  'settings.edit-title': 'Edita configuração',

  'settings.name': 'Nome',
  'settings.value': 'Valor',
  'settings.set-default-value': 'Definir valor padrão',

  'settings.type': 'Tipo',
  'settings.type.all': 'Todos',
  'settings.type.web': 'Web',
  'settings.type.mobile': 'Celular',

  'settings.enabled': 'Ativado',
  'settings.disabled': 'Desativado',

  'settings.get-settings-failure': 'Falha ao carregar as configurações! 😢',

  // Sidebar
  'sidebar.customer-logo': 'Logo do cliente',
  'sidebar.menu': 'Menu',
  'sidebar.others': 'Outros',
  'sidebar.reports': 'Relatórios',

  'sidebar.contacts': 'Contatos',
  'sidebar.contacts.customers': 'Clientes',
  'sidebar.contacts.suppliers': 'Fornecedores',
  'sidebar.contacts.sellers': 'Vendedores',
  'sidebar.contacts.employees': 'Funcionários',
  'sidebar.contacts.promoters': 'Promotores',
  'sidebar.contacts.transporters': 'Transportadoras',
  'sidebar.contacts.covenants': 'Convênios',
  'sidebar.contacts.owners': 'Proprietários',
  'sidebar.contacts.supervisor': 'Supervisores',
  'sidebar.contacts.branches-activities': 'Ramos de Atividades',
  'sidebar.contacts.dispatching-agencies': 'Órgãos Expedidores',

  'sidebar.financial': 'Financeiro',
  'sidebar.financial.accounts': 'Contas',
  'sidebar.financial.bills-to-receive': 'Contas a Receber',
  'sidebar.financial.bills-to-pay': 'Contas a Pagar',
  'sidebar.financial.bank-billings': 'Cobranças Bancárias',
  'sidebar.financial.chart-accounts': 'Plano de Contas',
  'sidebar.financial.cost-centers': 'Centro de Custos',
  'sidebar.financial.payment-methods': 'Métodos de Pagamentos',
  'sidebar.financial.payment-methods.bills-to-receive': 'Contas a Receber',
  'sidebar.financial.payment-methods.bills-to-pay': 'Contas a Pagar',
  'sidebar.financial.banks': 'Bancos',
  'sidebar.financial.payment-methods-types': 'Meios de Pagamentos',

  'sidebar.fiscal': 'Fiscal',
  'sidebar.fiscal.tax-situations': 'Situações Tributárias',
  'sidebar.fiscal.tributations': 'Tributações',
  'sidebar.fiscal.fiscal-operations': 'Operações Fiscais',
  'sidebar.fiscal.csts': 'CSTs',
  'sidebar.fiscal.sats': 'SAT',

  'sidebar.managerial': 'Gerencial',
  'sidebar.managerial.rural-properties': 'Fazendas',
  'sidebar.managerial.cultures': 'Culturas',
  'sidebar.managerial.harvests': 'Safras',

  'sidebar.movements': 'Movimentos',
  'sidebar.movements.orders': 'Pedidos',
  'sidebar.movements.budget': 'Orçamentos',
  'sidebar.movements.shipping': 'Remessas',
  'sidebar.movements.bonification': 'Bonificações',
  'sidebar.movements.exchange': 'Trocas',
  'sidebar.movements.sale': 'Vendas',
  'sidebar.movements.purchase': 'Compras',
  'sidebar.movements.visit': 'Visitas',
  'sidebar.movements.notes': 'Apontamentos',

  'sidebar.products': 'Produtos',
  'sidebar.products.list': 'Listagem',
  'sidebar.products.inventories': 'Inventários',
  'sidebar.products.price-lists': 'Tabelas de Preços',
  'sidebar.products.brands': 'Marcas',
  'sidebar.products.categories': 'Categorias',
  'sidebar.products.departments': 'Departamentos',
  'sidebar.products.ncms': 'NCMs',
  'sidebar.products.units-measure': 'Unidades de Medidas',
  'sidebar.products.company-ncms': 'NCMs (Cadastro)',

  'sidebar.regions': 'Regiões',
  'sidebar.regions.list': 'Listagem',
  'sidebar.regions.cities': 'Cidades',
  'sidebar.regions.states': 'Estados',
  'sidebar.regions.countries': 'Países',

  'sidebar.roadmap': 'Roteiros',
  'sidebar.roadmap.list': 'Listagem',
  'sidebar.roadmap.teams': 'Times',

  'sidebar.tools': 'Ferramentas',
  'sidebar.tools.all-backups': 'Todos os backups',
  'sidebar.tools.all-devices': 'Todos os dispositivos',
  'sidebar.tools.devices': 'Dispositivos',
  'sidebar.tools.companies-pix': 'Empresas com Pix',
  'sidebar.tools.companies-billet': 'Empresas com Boleto',

  // Sign In
  'sign-in.title': 'Bem-vindo!',

  'sign-in.description': 'Faça o login na sua conta para continuar',
  'sign-in.login': 'Email ou nome de usuário',
  'sign-in.password': 'Senha',
  'sign-in.remind-me': 'Lembrar-me',
  'sign-in.forgot-password': 'Esqueceu a senha?',
  'sign-in.sign-in': 'Entrar',

  'sign-in.authentication-failed':
    'Falha na autenticação, verifique seus dados! 🤔',
  'sign-in.company-failed': 'Usuário não pertence a nenhuma empresa! 🤔',

  // Sign Up
  'sign-up.title': 'Cadastro',

  'sign-up.description':
    'Entre com as suas informações para realizar o cadastro no sistema',
  'sign-up.full-name': 'Nome completo',
  'sign-up.username': 'Nome de usuário',
  'sign-up.email': 'Email',

  'sign-up.password': 'Senha',
  'sign-up.confirm-password': 'Confirme sua senha',
  'sign-up.equal-passwords': 'A senha e a confirmação devem ser iguais!',

  'sign-up.sign-up': 'Cadastrar',
  'sign-up.return': 'Voltar',

  'sign-up.register-success': 'Cadastrado com sucesso! 🎉',
  'sign-up.register-failed': 'Falha no cadastro, verifique seus dados! 🤔',

  // States
  'states.title': 'Estados',

  'states.name': 'Nome',
  'states.initials': 'Sigla',
  'states.ibge-code': 'Código IBGE',
  'states.default-aliquot': 'Alíquota Padrão',

  // Statistic Bar
  'statistic-bar.title-chip-label.from-start': 'Desde o início',

  // Subsidiary Companies
  'subsidiary-companies.title': 'Filiais',
  'subsidiary-companies.new-title': 'Nova filial',

  'subsidiary-companies.document': 'Documento',

  // Supervisor
  'supervisors.title': 'Supervisores',
  'supervisors.new-title': 'Novo supervisor',
  'supervisors.check-exists.become-supervisor': 'Tornar supervisor',
  'supervisors.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um supervisor nesta empresa:',
  'supervisors.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é supervisor:',
  'supervisors.check-exists.exists-view-title':
    'Foi encontrado um supervisor com esse documento:',
  'supervisors.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como supervisor na empresa:',
  'supervisors.get-supervisor-failure': 'Falha ao carregar o supervisor! 😢',
  'supervisors.get-supervisors-failure':
    'Falha ao carregar os supervisores! 😢',

  // Suppliers
  'suppliers.title': 'Fornecedores',
  'suppliers.new-title': 'Novo fornecedor',

  'suppliers.deleted-supplier': 'Fornecedor excluído',
  'suppliers.disabled-supplier': 'Fornecedor desativado',

  'suppliers.check-exists.become-supplier': 'Tornar fornecedor',
  'suppliers.check-exists.exists-view-title':
    'Foi encontrado um fornecedor com esse documento:',
  'suppliers.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é fornecedor:',
  'suppliers.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um fornecedor nesta empresa:',

  'suppliers.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como fornecedor na empresa:',

  'suppliers.get-supplier-failure': 'Falha ao carregar o fornecedor! 😢',

  // Switch company
  'switch-company.title': 'Troca de empresas',

  'switch-company.title-member':
    'Você é membro em {value, plural, =0 {0 empresa!} one {# empresa!} other {# empresas!}}',

  'switch-company.description': 'Selecione a empresa que você deseja acessar.',
  'switch-company.new-company': 'Nova empresa',
  'switch-company.type': 'Tipo',
  'switch-company.sign-out': 'Sair',

  'switch-company.list.not-found-company': 'Empresa não encontrada.',

  // System Categories
  'system-categories.get-system-categories-failure':
    'Falha ao carregar as categorias de sistema! 😢',

  // Table Localization
  'table-localization.body.add-tooltip': 'Adicionar',
  'table-localization.body.edit-tooltip': 'Editar',
  'table-localization.body.delete-tooltip': 'Excluir',
  'table-localization.body.empty-data-source-message':
    'Nenhum registro encontrado',
  'table-localization.body.delete-text':
    'Tem certeza que deseja excluir este registro?',
  'table-localization.body.save-tooltip': 'Salvar',
  'table-localization.body.cancel-tooltip': 'Cancelar',
  'table-localization.body.filter-tooltip': 'Filtrar',

  'table-localization.grouping.grouped-by': 'Agrupado por',
  'table-localization.grouping.placeholder':
    'Arraste os cabeçalhos para agrupar',

  'table-localization.header.actions': 'Ações',

  'table-localization.pagination.first-aria-label': 'Primeira página',
  'table-localization.pagination.first-tooltip': 'Primeira página',
  'table-localization.pagination.label-displayed-rows': 'de',
  'table-localization.pagination.label-rows-per-page': 'Registros por página: ',
  'table-localization.pagination.label-rows-select': 'Registros',
  'table-localization.pagination.last-aria-label': 'Última página',
  'table-localization.pagination.last-tooltip': 'Última página',
  'table-localization.pagination.next-aria-label': 'Próxima página',
  'table-localization.pagination.next-tooltip': 'Próxima página',
  'table-localization.pagination.previous-tooltip': 'Página anterior',
  'table-localization.pagination.previous-aria-label': 'Página anterior',

  'table-localization.toolbar.add-remove-columns':
    'Adicionar ou remover colunas',
  'table-localization.toolbar.clear-search-aria-label': 'Limpar pesquisa',
  'table-localization.toolbar.export-aria-label': 'Exportar',
  'table-localization.toolbar.export-title': 'Exportar',
  'table-localization.toolbar.export-csv-name': 'Exportar como CSV',
  'table-localization.toolbar.export-pdf-name': 'Exportar como PDF',
  'table-localization.toolbar.n-rows-selected': 'linhas(s) selecionadas',
  'table-localization.toolbar.search-tooltip': 'Pesquisar',
  'table-localization.toolbar.search-placeholder': 'Pesquisar',
  'table-localization.toolbar.show-columns-title': 'Mostrar colunas',
  'table-localization.toolbar.show-columns-aria-label': 'Mostrar colunas',

  // Tables
  'tables.yes': 'Sim',
  'tables.no': 'Não',

  'tables.field-excluded': 'Excluído',
  'tables.field-disabled': 'Desativado',

  'tables.not-registered': '(Não informado)',

  // Tax Situations
  'tax-situations.title': 'Situações Tributárias',

  'tax-situations.code': 'Código',
  'tax-situations.description': 'Descrição',

  'tax-situations.type': 'Tipo',
  'tax-situations.type.ipi': 'IPI',
  'tax-situations.type.cst': 'CST',
  'tax-situations.type.csosn': 'CSOSN',
  'tax-situations.type.pis': 'PIS',
  'tax-situations.type.cofins': 'COFINS',

  'tax-situations.get-tax-situations-failure':
    'Falha ao carregar as situações tributárias! 😢',

  // Teams
  'teams.title': 'Times',
  'teams.team': 'Time',
  'teams.new-title': 'Novo time',
  'teams.manage-members': 'Gerenciar integrantes',
  'teams.name': 'Nome',
  'teams.members': 'Integrantes',
  'teams.supervisor': 'Supervisor',
  'teams.new-team': 'Novo time',
  'teams.is-enabled': 'Ativo',
  'teams.is-enabled.enabled': 'Ativo',
  'teams.is-enabled.disabled': 'Desativado',

  // Teams Members

  'team-members.name': 'Nome',
  'team-members.manage-members': 'Gerenciar membros',

  // Transporters
  'transporters.title': 'Transportadoras',
  'transporters.new-title': 'Nova transportadora',

  'transporters.deleted-transporter': 'Transportadora excluída',
  'transporters.disabled-transporter': 'Transportadora desativada',

  'transporters.check-exists.become-transporter': 'Tornar transportadora',
  'transporters.check-exists.exists-view-title':
    'Foi encontrado uma transportadora com esse documento:',
  'transporters.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é transportadora:',
  'transporters.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é uma transportadora nesta empresa:',

  'transporters.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como transportadora na empresa:',

  'transporters.get-transporter-failure':
    'Falha ao carregar a transportadora! 😢',

  // Units Measure
  'units-measure.title': 'Unidades de Medidas',
  'units-measure.new-title': 'Nova unidade de medida',
  'units-measure.create-new-title': 'Criar nova unidade de medida',
  'units-measure.edit-title': 'Edita unidade de medida',

  'units-measure.name': 'Nome',
  'units-measure.abbreviation': 'Sigla',
  'units-measure.description': 'Descrição',
  'units-measure.quantity-unit': 'Qtde. unitária',

  'units-measure.service-unit': 'Unidade de serviço',
  'units-measure.service-unit.daily': 'Diária',
  'units-measure.service-unit.hour': 'Hora',
  'units-measure.service-unit.unique': 'Única',

  'units-measure.get-units-measure-failure':
    'Falha ao carregar as unidades de medidas! 😢',
  'units-measure.create-unit-measure-success':
    'Unidade de medida cadastrada com sucesso! 🎉',

  // Users
  'users.new-title': 'Novo usuário',

  'users.new-user': 'Novo usuário',
};
