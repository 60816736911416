import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  billType: null,
  subResourceType: null,
  shouldOpenSubResourceModal: false,
  paymentMethodFilterModalOpen: false,
};

export default function paymentMethods(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PAYMENT_METHODS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.PAYMENT_METHODS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.paymentMethodFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.PAYMENT_METHODS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHODS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PAYMENT_METHODS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.paymentMethod);
        draft.loading = false;
        break;
      }
      case Types.PAYMENT_METHODS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHODS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PAYMENT_METHODS_UPDATE_SUCCESS: {
        const { newPaymentMethod } = action.payload;
        draft.detail = newPaymentMethod;

        draft.list = state.list.map(paymentMethod => {
          return paymentMethod.id === newPaymentMethod.id
            ? newPaymentMethod
            : paymentMethod;
        });

        draft.loading = false;
        break;
      }
      case Types.PAYMENT_METHODS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHODS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PAYMENT_METHODS_DELETE_SUCCESS: {
        draft.list = state.list.filter(paymentMethod => {
          return paymentMethod.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.PAYMENT_METHODS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHODS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.PAYMENT_METHODS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.paymentMethod;
        draft.requestError = false;
        break;
      }
      case Types.PAYMENT_METHODS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.PAYMENT_METHODS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.paymentMethodFilterModalOpen = false;

        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }

      case Types.PAYMENT_METHODS_RESET_DETAILS: {
        draft.detail = {};
        draft.billType = null;
        draft.list = [];
        break;
      }

      case Types.PAYMENT_METHODS_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.PAYMENT_METHODS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      case Types.PAYMENT_METHODS_FILTER_OPEN_MODAL: {
        draft.paymentMethodFilterModalOpen = true;
        break;
      }
      case Types.PAYMENT_METHODS_FILTER_CLOSE_MODAL: {
        draft.paymentMethodFilterModalOpen = false;
        break;
      }

      case Types.PAYMENT_METHODS_SET_BILL_TYPE: {
        draft.billType = action.payload.billType;
        break;
      }

      default:
    }
  });
}
