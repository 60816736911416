const Types = {
  RURAL_PROPERTY_EMAILS_GET: '@ruralProperty/GET_RURAL_PROPERTY_EMAILS',

  RURAL_PROPERTY_EMAILS_CREATE_REQUEST:
    '@ruralProperty/CREATE_RURAL_PROPERTY_EMAIL_REQUEST',
  RURAL_PROPERTY_EMAILS_CREATE_SUCCESS:
    '@ruralProperty/CREATE_RURAL_PROPERTY_EMAIL_SUCCESS',
  RURAL_PROPERTY_EMAILS_CREATE_FAILURE:
    '@ruralProperty/CREATE_RURAL_PROPERTY_EMAIL_FAILURE',

  RURAL_PROPERTY_EMAILS_UPDATE_REQUEST:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_EMAIL_REQUEST',
  RURAL_PROPERTY_EMAILS_UPDATE_SUCCESS:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_EMAIL_SUCCESS',
  RURAL_PROPERTY_EMAILS_UPDATE_FAILURE:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_EMAIL_FAILURE',

  RURAL_PROPERTY_EMAILS_DELETE_REQUEST:
    '@ruralProperty/DELETE_RURAL_PROPERTY_EMAIL_REQUEST',
  RURAL_PROPERTY_EMAILS_DELETE_SUCCESS:
    '@ruralProperty/DELETE_RURAL_PROPERTY_EMAIL_SUCCESS',
  RURAL_PROPERTY_EMAILS_DELETE_FAILURE:
    '@ruralProperty/DELETE_RURAL_PROPERTY_EMAIL_FAILURE',

  RURAL_PROPERTY_EMAILS_RESET_LIST:
    '@ruralProperty/RESET_LIST_RURAL_PROPERTY_EMAIL',

  RURAL_PROPERTY_EMAILS_OPEN_MODAL:
    '@ruralProperty/OPEN_MODAL_RURAL_PROPERTY_EMAIL',
  RURAL_PROPERTY_EMAILS_CLOSE_MODAL:
    '@ruralProperty/CLOSE_MODAL_RURAL_PROPERTY_EMAIL',
};

export default Types;
