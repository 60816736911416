import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getReportSuccess, getReportFailure } from './actions';
import Types from './types';

export function* getReports({ payload }) {
  try {
    const { route, filters } = payload;

    const response = yield call(api.get, route);

    const { data, ...rest } = response.data;

    yield put(getReportSuccess({ data, additionalData: rest, filters }));
  } catch (err) {
    yield put(getReportFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="reports.failed-to-generate-report" />);
  }
}

export default all([takeLatest(Types.REPORTS_GET_REQUEST, getReports)]);
