/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import accounts from './accounts/sagas';
import allBackupDatabases from './allBackupDatabases/sagas';
import auth from './auth/sagas';
import bankAccountPixFiles from './bankAccountPixFiles/sagas';
import bankAccountPixs from './bankAccountPixs/sagas';
import bankBillingPixs from './bankBillingPixs/sagas';
import bankBillings from './bankBillings/sagas';
import banks from './banks/sagas';
import billsToPay from './billsToPay/sagas';
import billsToReceive from './billsToReceive/sagas';
import billToPayChartAccounts from './billToPayChartAccounts/sagas';
import billToPayCostCenters from './billToPayCostCenters/sagas';
import billToReceiveChartAccounts from './billToReceiveChartAccounts/sagas';
import billToReceivePixs from './billToReceivePixs/sagas';
import branchesActivities from './branchesActivities/sagas';
import brands from './brands/sagas';
import categories from './categories/sagas';
import chartAccounts from './chartAccounts/sagas';
import cities from './cities/sagas';
import companies from './companies/sagas';
import companyAddresses from './companyAddresses/sagas';
import companyEmails from './companyEmails/sagas';
import companyFiles from './companyFiles/sagas';
import companyNcms from './companyNcms/sagas';
import companyPhones from './companyPhones/sagas';
import companySubsidiaries from './companySubsidiaries/sagas';
import companyUsers from './companyUsers/sagas';
import contacts from './contacts/sagas';
import costCenters from './costCenters/sagas';
import countries from './countries/sagas';
import covenants from './covenants/sagas';
import cultures from './cultures/sagas';
import customerAddresses from './customerAddresses/sagas';
import customerEmails from './customerEmails/sagas';
import customerFiles from './customerFiles/sagas';
import customerPhones from './customerPhones/sagas';
import customers from './customers/sagas';
import dashboard from './dashboard/sagas';
import departments from './departments/sagas';
import devices from './devices/sagas';
import dispatchingAgencies from './dispatchingAgencies/sagas';
import employeeAddresses from './employeeAddresses/sagas';
import employeeEmails from './employeeEmails/sagas';
import employeeFiles from './employeeFiles/sagas';
import employeePhones from './employeePhones/sagas';
import employees from './employees/sagas';
import harvests from './harvests/sagas';
import inventories from './inventories/sagas';
import inventoryItems from './inventoryItems/sagas';
import ncms from './ncms/sagas';
import orderBillPixs from './orderBillPixs/sagas';
import orderBills from './orderBills/sagas';
import orderItems from './orderItems/sagas';
import orders from './orders/sagas';
import ownerAddresses from './ownerAddresses/sagas';
import ownerEmails from './ownerEmails/sagas';
import ownerFiles from './ownerFiles/sagas';
import ownerPhones from './ownerPhones/sagas';
import owners from './owners/sagas';
import paymentMethodInstallments from './paymentMethodInstallments/sagas';
import paymentMethods from './paymentMethods/sagas';
import paymentMethodsTypes from './paymentMethodsTypes/sagas';
import persons from './persons/sagas';
import priceListItems from './priceListItems/sagas';
import priceLists from './priceLists/sagas';
import productDiscounts from './productDiscounts/sagas';
import productFiles from './productFiles/sagas';
import productPriceListItems from './productPriceListItems/sagas';
import products from './products/sagas';
import promoterAddresses from './promoterAddresses/sagas';
import promoterEmails from './promoterEmails/sagas';
import promoterFiles from './promoterFiles/sagas';
import promoterPhones from './promoterPhones/sagas';
import promoters from './promoters/sagas';
import regionCities from './regionCities/sagas';
import regionCustomers from './regionCustomers/sagas';
import regions from './regions/sagas';
import regionSellers from './regionSellers/sagas';
import reports from './reports/sagas';
import ruralProperties from './ruralProperties/sagas';
import ruralPropertyAddresses from './ruralPropertyAddresses/sagas';
import ruralPropertyEmails from './ruralPropertyEmails/sagas';
import ruralPropertyFiles from './ruralPropertyFiles/sagas';
import ruralPropertyOwners from './ruralPropertyOwners/sagas';
import ruralPropertyPhones from './ruralPropertyPhones/sagas';
import ruralPropertyRegistrations from './ruralPropertyRegistrations/sagas';
import sellerAddresses from './sellerAddresses/sagas';
import sellerCompanies from './sellerCompanies/sagas';
import sellerEmails from './sellerEmails/sagas';
import sellerFiles from './sellerFiles/sagas';
import sellerPhones from './sellerPhones/sagas';
import sellers from './sellers/sagas';
import sellerUsers from './sellerUsers/sagas';
import settings from './settings/sagas';
import states from './states/sagas';
import supervisorAddresses from './supervisorAddresses/sagas';
import supervisorEmails from './supervisorEmails/sagas';
import supervisorFiles from './supervisorFiles/sagas';
import supervisorPhones from './supervisorPhones/sagas';
import supervisors from './supervisors/sagas';

import supplierAddresses from './supplierAddresses/sagas';
import supplierEmails from './supplierEmails/sagas';
import supplierFiles from './supplierFiles/sagas';
import supplierPhones from './supplierPhones/sagas';
import suppliers from './suppliers/sagas';
import taxSituations from './taxSituations/sagas';
import teamMembers from './teamMembers/sagas';
import teams from './teams/sagas';
import transporterAddresses from './transporterAddresses/sagas';
import transporterEmails from './transporterEmails/sagas';
import transporterFiles from './transporterFiles/sagas';
import transporterPhones from './transporterPhones/sagas';
import transporters from './transporters/sagas';
import unitsMeasure from './unitsMeasure/sagas';
import user from './user/sagas';
import userFiles from './userFiles/sagas';

export default function* rootSaga() {
  return yield all([
    accounts,
    allBackupDatabases,
    auth,
    bankAccountPixFiles,
    bankAccountPixs,
    bankBillingPixs,
    bankBillings,
    banks,
    billsToPay,
    billsToReceive,
    billToPayChartAccounts,
    billToPayCostCenters,
    billToReceiveChartAccounts,
    billToReceivePixs,
    branchesActivities,
    brands,
    categories,
    chartAccounts,
    cities,
    companies,
    companyAddresses,
    companyEmails,
    companyFiles,
    companyNcms,
    companyPhones,
    companySubsidiaries,
    companyUsers,
    contacts,
    costCenters,
    countries,
    covenants,
    cultures,
    customerAddresses,
    customerEmails,
    customerFiles,
    customerPhones,
    customers,
    dashboard,
    departments,
    devices,
    dispatchingAgencies,
    employeeAddresses,
    employeeEmails,
    employeeFiles,
    employeePhones,
    employees,
    harvests,
    inventories,
    inventoryItems,
    ncms,
    orderBillPixs,
    orderBills,
    orderItems,
    orders,
    ownerAddresses,
    ownerEmails,
    ownerFiles,
    ownerPhones,
    owners,
    paymentMethodInstallments,
    paymentMethods,
    paymentMethodsTypes,
    persons,
    priceListItems,
    priceLists,
    productDiscounts,
    productFiles,
    productPriceListItems,
    products,
    promoterAddresses,
    promoterEmails,
    promoterFiles,
    promoterPhones,
    promoters,
    regionCities,
    regionCustomers,
    regions,
    regionSellers,
    reports,
    ruralProperties,
    ruralPropertyAddresses,
    ruralPropertyEmails,
    ruralPropertyFiles,
    ruralPropertyOwners,
    ruralPropertyPhones,
    ruralPropertyRegistrations,
    sellerAddresses,
    sellerCompanies,
    sellerEmails,
    sellerFiles,
    sellerPhones,
    sellers,
    sellerUsers,
    settings,
    states,
    supervisorAddresses,
    supervisorEmails,
    supervisorFiles,
    supervisorPhones,
    supervisors,
    supplierAddresses,
    supplierEmails,
    supplierFiles,
    supplierPhones,
    suppliers,
    taxSituations,
    teamMembers,
    teams,
    transporterAddresses,
    transporterEmails,
    transporterFiles,
    transporterPhones,
    transporters,
    unitsMeasure,
    user,
    userFiles,
  ]);
}
