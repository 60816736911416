import Types from './types';

export function getContactFiles(files) {
  return {
    type: Types.CONTACT_FILES_GET,
    payload: { files },
  };
}

export function resetListContactFile() {
  return {
    type: Types.CONTACT_FILES_RESET_LIST,
  };
}
