import styled from 'styled-components';

import colors from '~/theme/colors';

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    color: ${({ color, theme }) =>
      colors[color] ? colors[color] : theme.palette.secondary.main};
    margin-right: 8px;
  }
`;
