import produce from 'immer';

import Types from './types';
import RoadmapTypes from '../roadmaps/types'

const INITIAL_STATE = {
  scheduleModalOpen: false,
  dateType: 'terça-feira',
  list: [],
};

export default function schedules(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch(action.type) {
      case Types.SCHEDULES_OPEN_MODAL: {
        const { dateType } = action.payload;

        draft.scheduleModalOpen = true;

        if (state.dateType && dateType) {
          draft.dateType = dateType;
        }

        break;
      }

      case Types.SCHEDULES_CLOSE_MODAL: {
        draft.scheduleModalOpen = false;

        break;
      }

      case Types.SCHEDULES_SET_LIST: {
        const { list } = action.payload;

        draft.list = list;
        draft.scheduleModalOpen = false;
        break;
      }

      case RoadmapTypes.ROADMAPS_RESET_DETAIL: {
        draft.scheduleModalOpen = false;
        draft.list = [];
        break;
      }

      default:
        break;
    }
  })
}
