import Types from './types';

export function getContactEmails(emails) {
  return {
    type: Types.CONTACT_EMAILS_GET,
    payload: { emails },
  };
}

export function resetListContactEmail() {
  return {
    type: Types.CONTACT_EMAILS_RESET_LIST,
  };
}
