import produce from 'immer';

import SellerCompanyTypes from '../sellerCompanies/types';
import SellerTypes from '../sellers/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  sellerUserModalOpen: false,
};

export default function sellerUsers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SELLER_USERS_GET: {
        draft.detail = action.payload.user ?? {};
        break;
      }

      case Types.SELLER_USERS_GET_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.SELLER_USERS_GET_SUCCESS: {
        draft.list = action.payload.users;
        draft.loading = false;
        break;
      }

      case Types.SELLER_USERS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SELLER_USERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SELLER_USERS_CREATE_SUCCESS: {
        draft.detail = action.payload.user;

        draft.loading = false;
        break;
      }
      case Types.SELLER_USERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SELLER_USERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SELLER_USERS_DELETE_SUCCESS: {
        draft.detail = {};
        draft.loading = false;
        break;
      }
      case Types.SELLER_USERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SELLER_USERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.SELLER_USERS_OPEN_MODAL: {
        draft.sellerUserModalOpen = true;
        break;
      }
      case Types.SELLER_USERS_CLOSE_MODAL: {
        draft.sellerUserModalOpen = false;
        break;
      }

      case SellerTypes.SELLERS_RESET_DETAILS: {
        draft.sellerUserModalOpen = false;
        draft.detail = {};
        break;
      }

      case SellerCompanyTypes.SELLER_COMPANIES_DELETE_SUCCESS: {
        draft.detail =
          action.payload.company_id === state.detail.company_id
            ? {}
            : state.detail;
        break;
      }

      default:
    }
  });
}
