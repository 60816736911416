const Types = {
  USER_FILES_GET: '@user/GET_USER_FILES',

  USER_FILES_CREATE_REQUEST: '@user/CREATE_USER_FILE_REQUEST',
  USER_FILES_CREATE_SUCCESS: '@user/CREATE_USER_FILE_SUCCESS',
  USER_FILES_CREATE_FAILURE: '@user/CREATE_USER_FILE_FAILURE',

  USER_FILES_UPDATE_REQUEST: '@user/UPDATE_USER_FILE_REQUEST',
  USER_FILES_UPDATE_SUCCESS: '@user/UPDATE_USER_FILE_SUCCESS',
  USER_FILES_UPDATE_FAILURE: '@user/UPDATE_USER_FILE_FAILURE',

  USER_FILES_DELETE_REQUEST: '@user/DELETE_USER_FILE_REQUEST',
  USER_FILES_DELETE_SUCCESS: '@user/DELETE_USER_FILE_SUCCESS',
  USER_FILES_DELETE_FAILURE: '@user/DELETE_USER_FILE_FAILURE',

  USER_FILES_OPEN_MODAL: '@user/OPEN_MODAL_USER_FILE',
  USER_FILES_CLOSE_MODAL: '@user/CLOSE_MODAL_USER_FILE',

  USER_FILES_RESET_LIST: '@user/RESET_LIST_USER_FILE',
};

export default Types;
