import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getTaxSituationsSuccess, getTaxSituationsFailure } from './actions';
import Types from './types';

export function* getTaxSituations({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `tax-situations${queryParams}`);

    yield put(
      getTaxSituationsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getTaxSituationsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([
  takeLatest(Types.TAX_SITUATIONS_GET_REQUEST, getTaxSituations),
]);
