import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createEmployeePhoneSuccess,
  createEmployeePhoneFailure,
  updateEmployeePhoneSuccess,
  updateEmployeePhoneFailure,
  deleteEmployeePhoneSuccess,
  deleteEmployeePhoneFailure,
} from './actions';
import Types from './types';

export function* createEmployeePhone({ payload }) {
  try {
    const { employee_id, phone } = payload;

    const response = yield call(
      api.post,
      `employees/${employee_id}/phones`,
      phone
    );

    yield put(createEmployeePhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createEmployeePhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateEmployeePhone({ payload }) {
  try {
    const { employee_id, phone_id, newPhone } = payload;

    const response = yield call(
      api.put,
      `employees/${employee_id}/phones/${phone_id}`,
      newPhone
    );

    yield put(updateEmployeePhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateEmployeePhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteEmployeePhone({ payload }) {
  const { employee_id, phone_id } = payload;

  const phones = yield select(state => state.employeePhones.list);

  const phone = phones.find(data => data.id === phone_id);

  if (phone.is_main && phones.length > 1) {
    yield put(deleteEmployeePhoneFailure());

    toast.error(<IntlMessages id="phones.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `employees/${employee_id}/phones/${phone_id}`);

    yield put(deleteEmployeePhoneSuccess(phone_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteEmployeePhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.EMPLOYEE_PHONES_CREATE_REQUEST, createEmployeePhone),
  takeLatest(Types.EMPLOYEE_PHONES_UPDATE_REQUEST, updateEmployeePhone),
  takeLatest(Types.EMPLOYEE_PHONES_DELETE_REQUEST, deleteEmployeePhone),
]);
