import Types from './types';

export function getCompanyPhones(phones) {
  return {
    type: Types.COMPANY_PHONES_GET,
    payload: { phones },
  };
}

export function createCompanyPhoneRequest(company_id, phone) {
  return {
    type: Types.COMPANY_PHONES_CREATE_REQUEST,
    payload: { company_id, phone },
  };
}

export function createCompanyPhoneSuccess(phone) {
  return {
    type: Types.COMPANY_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createCompanyPhoneFailure() {
  return {
    type: Types.COMPANY_PHONES_CREATE_FAILURE,
  };
}

export function updateCompanyPhoneRequest(company_id, phone_id, newPhone) {
  return {
    type: Types.COMPANY_PHONES_UPDATE_REQUEST,
    payload: { company_id, phone_id, newPhone },
  };
}

export function updateCompanyPhoneSuccess(newPhone) {
  return {
    type: Types.COMPANY_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateCompanyPhoneFailure() {
  return {
    type: Types.COMPANY_PHONES_UPDATE_FAILURE,
  };
}

export function deleteCompanyPhoneRequest(company_id, phone_id) {
  return {
    type: Types.COMPANY_PHONES_DELETE_REQUEST,
    payload: { company_id, phone_id },
  };
}

export function deleteCompanyPhoneSuccess(phone_id) {
  return {
    type: Types.COMPANY_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteCompanyPhoneFailure() {
  return {
    type: Types.COMPANY_PHONES_DELETE_FAILURE,
  };
}

export function resetListCompanyPhone() {
  return {
    type: Types.COMPANY_PHONES_RESET_LIST,
  };
}

export function openModalCompanyPhone(phone_id) {
  return {
    type: Types.COMPANY_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalCompanyPhone() {
  return {
    type: Types.COMPANY_PHONES_CLOSE_MODAL,
  };
}
