import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  additionalData: null,
  filters: {},
  loading: false,
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.REPORTS_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REPORTS_GET_SUCCESS: {
        const { data, additionalData, filters } = action.payload;

        draft.list = data;
        draft.loading = false;

        if (typeof additionalData !== 'undefined') {
          draft.additionalData = additionalData;
        }

        if (typeof filters !== 'undefined') {
          draft.filters = filters;
        }

        break;
      }
      case Types.REPORTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REPORTS_SET_LOADING: {
        const { loading } = action.payload;

        draft.loading = loading;
        break;
      }

      case Types.REPORTS_SET_DATA: {
        const { data, additionalData } = action.payload;

        draft.list = data;

        if (additionalData !== 'undefined') {
          draft.additionalData = additionalData;
        }
        break;
      }

      case Types.REPORTS_RESET_DATA: {
        draft.list = [];
        draft.additionalData = null;
        draft.loading = false;
        draft.filters = {};
        break;
      }

      default:
        break;
    }
  });
}
