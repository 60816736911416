import Types from './types';

export function getCompanyAddresses(addresses) {
  return {
    type: Types.COMPANY_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createCompanyAddressRequest(company_id, address) {
  return {
    type: Types.COMPANY_ADDRESSES_CREATE_REQUEST,
    payload: { company_id, address },
  };
}

export function createCompanyAddressSuccess(address) {
  return {
    type: Types.COMPANY_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createCompanyAddressFailure() {
  return {
    type: Types.COMPANY_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateCompanyAddressRequest(
  company_id,
  address_id,
  newAddress
) {
  return {
    type: Types.COMPANY_ADDRESSES_UPDATE_REQUEST,
    payload: { company_id, address_id, newAddress },
  };
}

export function updateCompanyAddressSuccess(newAddress) {
  return {
    type: Types.COMPANY_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateCompanyAddressFailure() {
  return {
    type: Types.COMPANY_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteCompanyAddressRequest(company_id, address_id) {
  return {
    type: Types.COMPANY_ADDRESSES_DELETE_REQUEST,
    payload: { company_id, address_id },
  };
}

export function deleteCompanyAddressSuccess(address_id) {
  return {
    type: Types.COMPANY_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteCompanyAddressFailure() {
  return {
    type: Types.COMPANY_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListCompanyAddress() {
  return {
    type: Types.COMPANY_ADDRESSES_RESET_LIST,
  };
}

export function openModalCompanyAddress(address_id) {
  return {
    type: Types.COMPANY_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalCompanyAddress() {
  return {
    type: Types.COMPANY_ADDRESSES_CLOSE_MODAL,
  };
}
