import Types from './types';

export function getCompanyFiles(files) {
  return {
    type: Types.COMPANY_FILES_GET,
    payload: { files },
  };
}

export function createCompanyFileRequest(company_id, file) {
  return {
    type: Types.COMPANY_FILES_CREATE_REQUEST,
    payload: { company_id, file },
  };
}

export function createCompanyFileSuccess(file) {
  return {
    type: Types.COMPANY_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createCompanyFileFailure() {
  return {
    type: Types.COMPANY_FILES_CREATE_FAILURE,
  };
}

export function updateCompanyFileRequest(company_id, file_id, newFile) {
  return {
    type: Types.COMPANY_FILES_UPDATE_REQUEST,
    payload: { company_id, file_id, newFile },
  };
}

export function updateCompanyFileSuccess(newFile) {
  return {
    type: Types.COMPANY_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateCompanyFileFailure() {
  return {
    type: Types.COMPANY_FILES_UPDATE_FAILURE,
  };
}

export function deleteCompanyFileRequest(company_id, file_id) {
  return {
    type: Types.COMPANY_FILES_DELETE_REQUEST,
    payload: { company_id, file_id },
  };
}

export function deleteCompanyFileSuccess(file_id) {
  return {
    type: Types.COMPANY_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteCompanyFileFailure() {
  return {
    type: Types.COMPANY_FILES_DELETE_FAILURE,
  };
}

export function resetListCompanyFile() {
  return {
    type: Types.COMPANY_FILES_RESET_LIST,
  };
}

export function openModalCompanyFile() {
  return {
    type: Types.COMPANY_FILES_OPEN_MODAL,
  };
}

export function closeModalCompanyFile() {
  return {
    type: Types.COMPANY_FILES_CLOSE_MODAL,
  };
}
