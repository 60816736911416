import React from 'react';

import PropTypes from 'prop-types';

import { Copy } from './styles';

export default function CopyIcon({ type }) {
  return <Copy type={type} />;
}

CopyIcon.propTypes = {
  type: PropTypes.string,
};

CopyIcon.defaultProps = {
  type: '',
};
