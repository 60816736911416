import Types from './types';

export function getRegionCities(cities) {
  return {
    type: Types.REGION_CITIES_GET,
    payload: { cities },
  };
}

export function updateRegionCityRequest(region_id, cities) {
  return {
    type: Types.REGION_CITIES_UPDATE_REQUEST,
    payload: { region_id, cities },
  };
}

export function updateRegionCitySuccess(cities) {
  return {
    type: Types.REGION_CITIES_UPDATE_SUCCESS,
    payload: { cities },
  };
}

export function updateRegionCityFailure() {
  return {
    type: Types.REGION_CITIES_UPDATE_FAILURE,
  };
}

export function deleteRegionCityRequest(region_id, region_city_id) {
  return {
    type: Types.REGION_CITIES_DELETE_REQUEST,
    payload: { region_id, region_city_id },
  };
}

export function deleteRegionCitySuccess(region_city_id) {
  return {
    type: Types.REGION_CITIES_DELETE_SUCCESS,
    payload: { region_city_id },
  };
}

export function deleteRegionCityFailure() {
  return {
    type: Types.REGION_CITIES_DELETE_FAILURE,
  };
}

export function resetListRegionCity() {
  return {
    type: Types.REGION_CITIES_RESET_LIST,
  };
}
