export const personTypes = [
  {
    label: 'persons.type.supplier',
    value: 'SUPPLIER',
    url: '/supplier',
  },
  {
    label: 'persons.type.customer',
    value: 'CUSTOMER',
    url: '/customer',
  },
  {
    label: 'persons.type.company',
    value: 'COMPANY',
  },
  {
    label: 'persons.type.employee',
    value: 'EMPLOYEE',
    url: '/employee',
  },
  {
    label: 'persons.type.seller',
    value: 'SELLER',
    url: '/seller',
  },
  {
    label: 'persons.type.driver',
    value: 'DRIVER',
  },
  {
    label: 'persons.type.owner',
    value: 'OWNER',
    url: '/owner',
  },
  {
    label: 'persons.type.property',
    value: 'PROPERTY',
    url: '/rural-property',
  },
  {
    label: 'persons.type.technical',
    value: 'TECHNICAL',
  },
  {
    label: 'persons.type.transporter',
    value: 'TRANSPORTER',
    url: '/transporter',
  },
  {
    label: 'persons.type.supervisor',
    value: 'SUPERVISOR',
    url: '/supervisor',
  },
  {
    label: 'persons.type.promoter',
    value: 'PROMOTER',
    url: '/promoter',
  },
];
