import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSupplierFileSuccess,
  createSupplierFileFailure,
  updateSupplierFileSuccess,
  updateSupplierFileFailure,
  deleteSupplierFileSuccess,
  deleteSupplierFileFailure,
} from './actions';
import Types from './types';

export function* createSupplierFile({ payload }) {
  try {
    const { supplier_id, file } = payload;

    const response = yield call(
      api.post,
      `suppliers/${supplier_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createSupplierFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSupplierFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupplierFile({ payload }) {
  try {
    const { supplier_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `suppliers/${supplier_id}/files/${file_id}`,
      newFile
    );

    yield put(updateSupplierFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupplierFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupplierFile({ payload }) {
  try {
    const { supplier_id, file_id } = payload;

    yield call(api.delete, `suppliers/${supplier_id}/files/${file_id}`);

    yield put(deleteSupplierFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupplierFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.SUPPLIER_FILES_CREATE_REQUEST, createSupplierFile),
  takeLatest(Types.SUPPLIER_FILES_UPDATE_REQUEST, updateSupplierFile),
  takeLatest(Types.SUPPLIER_FILES_DELETE_REQUEST, deleteSupplierFile),
]);
