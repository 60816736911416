import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,
  categoryModalOpen: false,
  newRegister: false,
};

export default function categories(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CATEGORIES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.CATEGORIES_GET_SUCCESS: {
        draft.list = action.payload.categories;
        draft.loading = false;
        draft.requestError = false;
        break;
      }
      case Types.CATEGORIES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CATEGORIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CATEGORIES_CREATE_SUCCESS: {
        draft.list.push(action.payload.category);

        draft.loading = false;
        draft.categoryModalOpen = false;
        break;
      }
      case Types.CATEGORIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CATEGORIES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CATEGORIES_UPDATE_SUCCESS: {
        const { newCategory } = action.payload;

        draft.list = state.list.map(category => {
          return category.id === newCategory.id ? newCategory : category;
        });

        draft.detail = {};
        draft.loading = false;
        draft.categoryModalOpen = false;

        break;
      }
      case Types.CATEGORIES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CATEGORIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CATEGORIES_DELETE_SUCCESS: {
        draft.list = state.list.filter(category => {
          return category.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.CATEGORIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CATEGORIES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        break;
      }

      case Types.CATEGORIES_OPEN_MODAL: {
        const { id, newRegister } = action.payload;

        draft.newRegister = newRegister;
        draft.detail = {};

        if (id) {
          draft.detail = state.list.find(category => {
            return category.id === id;
          });
        }

        draft.categoryModalOpen = true;
        break;
      }
      case Types.CATEGORIES_CLOSE_MODAL: {
        draft.newRegister = false;
        draft.detail = {};
        draft.categoryModalOpen = false;

        break;
      }

      default:
    }
  });
}
