import React from 'react';
import { Settings } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

/** TODO: Mudar depois para a tela de backups e não banco de dados */
// const AllBackups = async(() => import('~/pages/AllBackups'));
const AllBackupDatabasesList = async(() =>
  import('~/pages/AllBackups/Databases/List')
);
const CompaniesBillet = async(() => import('~/pages/CompaniesBillet'));
const CompaniesPix = async(() => import('~/pages/CompaniesPix'));
const DevicesList = async(() => import('~/pages/Devices/List'));

export const toolsRoutes = {
  id: <IntlMessages id="sidebar.tools" />,
  path: '/tools',
  icon: <Settings />,
  children: [],
  /** TODO: Futuramente tendo mais opcoes voltar o array [] e fazer
   * o push no modulo ADMINISTRATION
   */
};

if (process.env.REACT_APP_MODULE === 'ADMINISTRATION') {
  const routes = [
    {
      path: '/all-devices',
      name: <IntlMessages id="sidebar.tools.all-devices" />,
      component: DevicesList,
    },
    {
      path: '/all-backups/databases',
      name: <IntlMessages id="sidebar.tools.all-backups" />,
      component: AllBackupDatabasesList,
    },
    {
      path: '/companies-pix',
      name: <IntlMessages id="sidebar.tools.companies-pix" />,
      component: CompaniesPix,
    },
    {
      path: '/companies-billet',
      name: <IntlMessages id="sidebar.tools.companies-billet" />,
      component: CompaniesBillet,
    },
  ];

  toolsRoutes.children.push(...routes);
} else {
  const devices = {
    path: '/devices',
    name: <IntlMessages id="sidebar.tools.devices" />,
    component: DevicesList,
  };

  toolsRoutes.children.push(devices);
}
