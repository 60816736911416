import produce from 'immer';

import ContactTypes from '../contacts/types';
import Types from './types';

const INITIAL_STATE = {
  detail: {},
  found: {},
  statusCheck: false,
  loading: false,
  requestError: false,
};

export default function persons(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PERSONS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PERSONS_UPDATE_SUCCESS: {
        const { newPerson } = action.payload;
        draft.detail = newPerson;

        draft.loading = false;
        break;
      }
      case Types.PERSONS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PERSONS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PERSONS_DELETE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.PERSONS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PERSONS_CHECK_EXISTS_REQUEST: {
        draft.statusCheck = false;
        draft.loading = true;
        break;
      }
      case Types.PERSONS_CHECK_EXISTS_SUCCESS: {
        draft.statusCheck = true;
        draft.found = action.payload.person;
        draft.loading = false;
        break;
      }
      case Types.PERSONS_CHECK_EXISTS_FAILURE: {
        draft.statusCheck = false;
        draft.loading = false;
        break;
      }

      case Types.PERSONS_RESET_FOUND: {
        draft.found = {};
        break;
      }

      case Types.PERSONS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.PERSONS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.person;
        draft.requestError = false;
        break;
      }
      case Types.PERSONS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.PERSONS_RESET_DETAILS: {
        draft.found = {};
        draft.statusCheck = false;
        draft.loading = false;
        draft.detail = {};
        break;
      }

      case ContactTypes.CONTACTS_RESET_DETAILS: {
        draft.found = {};
        break;
      }

      default:
    }
  });
}
