const Types = {
  ORDER_ITEMS_GET: '@order/GET_ORDER_ITEMS',

  ORDER_ITEMS_CREATE_REQUEST: '@order/CREATE_ORDER_ITEM_REQUEST',
  ORDER_ITEMS_CREATE_SUCCESS: '@order/CREATE_ORDER_ITEM_SUCCESS',
  ORDER_ITEMS_CREATE_FAILURE: '@order/CREATE_ORDER_ITEM_FAILURE',

  ORDER_ITEMS_UPDATE_REQUEST: '@order/UPDATE_ORDER_ITEM_REQUEST',
  ORDER_ITEMS_UPDATE_SUCCESS: '@order/UPDATE_ORDER_ITEM_SUCCESS',
  ORDER_ITEMS_UPDATE_FAILURE: '@order/UPDATE_ORDER_ITEM_FAILURE',

  ORDER_ITEMS_DELETE_REQUEST: '@order/DELETE_ORDER_ITEM_REQUEST',
  ORDER_ITEMS_DELETE_SUCCESS: '@order/DELETE_ORDER_ITEM_SUCCESS',
  ORDER_ITEMS_DELETE_FAILURE: '@order/DELETE_ORDER_ITEM_FAILURE',

  ORDER_ITEMS_RESET_LIST: '@order/RESET_LIST_ORDER_ITEM',

  ORDER_ITEMS_OPEN_MODAL: '@order/OPEN_MODAL_ORDER_ITEM',
  ORDER_ITEMS_CLOSE_MODAL: '@order/CLOSE_MODAL_ORDER_ITEM',

  ORDER_ITEMS_PRICE_LIST_OPEN_MODAL: '@order/OPEN_MODAL_PRICE_LIST',
  ORDER_ITEMS_PRICE_LIST_CLOSE_MODAL: '@order/CLOSE_MODAL_PRICE_LIST',
};

export default Types;
