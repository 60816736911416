import Types from './types';

export function getSupervisorAddresses(addresses) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createSupervisorAddressRequest(supervisor_id, address) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_CREATE_REQUEST,
    payload: { supervisor_id, address },
  };
}

export function createSupervisorAddressSuccess(address) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createSupervisorAddressFailure() {
  return {
    type: Types.SUPERVISOR_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateSupervisorAddressRequest(
  supervisor_id,
  address_id,
  newAddress
) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_UPDATE_REQUEST,
    payload: { supervisor_id, address_id, newAddress },
  };
}

export function updateSupervisorAddressSuccess(newAddress) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateSupervisorAddressFailure() {
  return {
    type: Types.SUPERVISOR_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteSupervisorAddressRequest(supervisor_id, address_id) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_DELETE_REQUEST,
    payload: { supervisor_id, address_id },
  };
}

export function deleteSupervisorAddressSuccess(address_id) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteSupervisorAddressFailure() {
  return {
    type: Types.SUPERVISOR_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListSupervisorAddress() {
  return {
    type: Types.SUPERVISOR_ADDRESSES_RESET_LIST,
  };
}

export function openModalSupervisorAddress(address_id) {
  return {
    type: Types.SUPERVISOR_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalSupervisorAddress() {
  return {
    type: Types.SUPERVISOR_ADDRESSES_CLOSE_MODAL,
  };
}
