const Types = {
  SELLER_USERS_GET: '@sellers/GET_SELLER_USERS',

  SELLER_USERS_GET_REQUEST: '@sellers/GET_SELLER_USERS_REQUEST',
  SELLER_USERS_GET_SUCCESS: '@sellers/GET_SELLER_USERS_SUCCESS',
  SELLER_USERS_GET_FAILURE: '@sellers/GET_SELLER_USERS_FAILURE',

  SELLER_USERS_CREATE_REQUEST: '@sellers/CREATE_SELLER_USER_REQUEST',
  SELLER_USERS_CREATE_SUCCESS: '@sellers/CREATE_SELLER_USER_SUCCESS',
  SELLER_USERS_CREATE_FAILURE: '@sellers/CREATE_SELLER_USER_FAILURE',

  SELLER_USERS_DELETE_REQUEST: '@sellers/DELETE_SELLER_USER_REQUEST',
  SELLER_USERS_DELETE_SUCCESS: '@sellers/DELETE_SELLER_USER_SUCCESS',
  SELLER_USERS_DELETE_FAILURE: '@sellers/DELETE_SELLER_USER_FAILURE',

  SELLER_USERS_RESET_LIST: '@sellers/RESET_LIST_SELLER_USER',

  SELLER_USERS_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_USER',
  SELLER_USERS_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_USER',
};

export default Types;
