import Types from './types';

export function getReportRequest({ route, filters = {} }) {
  return {
    type: Types.REPORTS_GET_REQUEST,
    payload: {
      route,
      filters,
    },
  };
}

export function getReportSuccess({
  data = [],
  additionalData = null,
  filters = {},
}) {
  return {
    type: Types.REPORTS_GET_SUCCESS,
    payload: {
      data,
      additionalData,
      filters,
    },
  };
}

export function getReportFailure() {
  return {
    type: Types.REPORTS_GET_FAILURE,
  };
}

export function setReportData({ data = [], additionalData = null }) {
  return {
    type: Types.REPORTS_SET_DATA,
    payload: { data, additionalData },
  };
}

export function setReportLoading(loading = false) {
  return {
    type: Types.REPORTS_SET_LOADING,
    payload: { loading },
  };
}

export function resetReportData() {
  return {
    type: Types.REPORTS_RESET_DATA,
  };
}
