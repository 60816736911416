import produce from 'immer';

import RuralPropertyTypes from '../ruralProperties/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  ruralPropertyOwnerModalOpen: false,
};

export default function ruralPropertyOwners(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.RURAL_PROPERTY_OWNERS_GET: {
        draft.list = action.payload.owners;
        break;
      }

      case Types.RURAL_PROPERTY_OWNERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTY_OWNERS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.owner);
        draft.loading = false;
        draft.ruralPropertyOwnerModalOpen = false;
        break;
      }
      case Types.RURAL_PROPERTY_OWNERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTY_OWNERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RURAL_PROPERTY_OWNERS_DELETE_SUCCESS: {
        const { property_owner_id } = action.payload;

        draft.list = state.list.filter(owner => {
          return owner.property_owner_id !== property_owner_id;
        });

        draft.loading = false;
        break;
      }
      case Types.RURAL_PROPERTY_OWNERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.RURAL_PROPERTY_OWNERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.RURAL_PROPERTY_OWNERS_OPEN_MODAL: {
        draft.ruralPropertyOwnerModalOpen = true;
        break;
      }
      case Types.RURAL_PROPERTY_OWNERS_CLOSE_MODAL: {
        draft.ruralPropertyOwnerModalOpen = false;
        break;
      }

      case RuralPropertyTypes.RURAL_PROPERTIES_RESET_DETAILS: {
        draft.ruralPropertyOwnerModalOpen = false;
        draft.list = [];
        break;
      }

      default:
    }
  });
}
