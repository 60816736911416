import styled from 'styled-components';

import { Typography } from '@material-ui/core';
import { AddBox, RemoveRedEye, Print, Edit, Refresh } from '@material-ui/icons';

import colors from '~/theme/colors';

export const Title = styled(Typography).attrs({
  variant: 'h5',
  component: 'h1',
})``;

export const AddIcon = styled(AddBox)`
  color: ${colors.darkRegular};
`;

export const ViewIcon = styled(RemoveRedEye)`
  color: ${colors.darkRegular};
`;

export const EditIcon = styled(Edit)`
  color: ${colors.darkRegular};
`;

export const PrintIcon = styled(Print)`
  color: ${colors.darkRegular};
`;

export const RefreshIcon = styled(Refresh)`
  color: ${colors.darkRegular};
`;
