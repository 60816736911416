import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getDepartmentsSuccess,
  getDepartmentsFailure,
  createDepartmentSuccess,
  createDepartmentFailure,
  updateDepartmentSuccess,
  updateDepartmentFailure,
  deleteDepartmentSuccess,
  deleteDepartmentFailure,
} from './actions';
import Types from './types';

export function* getDepartments({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `departments${queryParams}`);

    yield put(
      getDepartmentsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getDepartmentsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createDepartment({ payload }) {
  try {
    const { department, source } = payload;

    const response = yield call(api.post, 'departments', department);

    yield put(createDepartmentSuccess(response.data, source));

    if (source) {
      toast.success(
        <IntlMessages id="departments.create-department-success" />
      );
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createDepartmentFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateDepartment({ payload }) {
  try {
    const { id, newDepartment } = payload;

    const response = yield call(api.put, `departments/${id}`, newDepartment);

    yield put(updateDepartmentSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateDepartmentFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteDepartment({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `departments/${id}`);

    yield put(deleteDepartmentSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteDepartmentFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.DEPARTMENTS_GET_REQUEST, getDepartments),
  takeLatest(Types.DEPARTMENTS_CREATE_REQUEST, createDepartment),
  takeLatest(Types.DEPARTMENTS_UPDATE_REQUEST, updateDepartment),
  takeLatest(Types.DEPARTMENTS_DELETE_REQUEST, deleteDepartment),
]);
