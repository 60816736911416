import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import PageTitle from '~/components/PageTitle';
import IntlMessages from '~/utils/IntlMessages';

import { Error, Title, Description, Button, Wrapper } from './styles';

export default function Page404() {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <PageTitle title={formatMessage({ id: 'page404.title' })} />

      <Error>
        <IntlMessages id="page404.404" />
      </Error>
      <Title>
        <IntlMessages id="page404.title" />
      </Title>
      <Description>
        <IntlMessages id="page404.description" />
      </Description>

      <Button component={Link} to="/">
        <IntlMessages id="page404.return-to-website" />
      </Button>
    </Wrapper>
  );
}
