import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getOwnerAddresses,
  resetListOwnerAddress,
  openModalOwnerAddress,
} from '../ownerAddresses/actions';
import {
  getOwnerEmails,
  openModalOwnerEmail,
  resetListOwnerEmail,
} from '../ownerEmails/actions';
import { getOwnerFiles, resetListOwnerFile } from '../ownerFiles/actions';
import {
  getOwnerPhones,
  openModalOwnerPhone,
  resetListOwnerPhone,
} from '../ownerPhones/actions';
import { resetFoundPerson } from '../persons/actions';
import {
  getOwnersSuccess,
  getOwnersFailure,
  createOwnerSuccess,
  createOwnerFailure,
  deleteOwnerSuccess,
  deleteOwnerFailure,
  becomeOwnerSuccess,
  becomeOwnerFailure,
  getDetailsOwnerSuccess,
  updateOwnerSuccess,
  updateOwnerFailure,
  getDetailsOwnerFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getOwners({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `owners${queryParams}`);

    yield put(
      getOwnersSuccess({ data, modalFilters, filterColumnsOrder, filterSearch })
    );
  } catch (err) {
    yield put(getOwnersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createOwner({ payload }) {
  try {
    const { owner, subResourceType } = payload;

    const response = yield call(api.post, 'owners', owner);

    yield put(createOwnerSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/owner/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOwner({ payload }) {
  try {
    const { id, newOwner } = payload;

    const response = yield call(api.put, `owners/${id}`, newOwner);

    yield put(updateOwnerSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOwner({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `owners/${id}`);

    yield put(deleteOwnerSuccess(id));

    history.push('/owners');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeOwner({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'OWNER';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeOwnerSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/owner/${id}`);
  } catch (err) {
    yield put(becomeOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsOwner({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `owners/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getOwnerPhones(phones));

    yield put(getOwnerAddresses(addresses));

    yield put(getOwnerEmails(emails));

    yield put(getOwnerFiles(files));

    yield put(getDetailsOwnerSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.owners
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalOwnerPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalOwnerEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalOwnerAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsOwner() {
  yield put(resetListOwnerPhone());

  yield put(resetListOwnerAddress());

  yield put(resetListOwnerEmail());

  yield put(resetListOwnerFile());
}
export default all([
  takeLatest(Types.OWNERS_GET_REQUEST, getOwners),
  takeLatest(Types.OWNERS_CREATE_REQUEST, createOwner),
  takeLatest(Types.OWNERS_UPDATE_REQUEST, updateOwner),
  takeLatest(Types.OWNERS_DELETE_REQUEST, deleteOwner),
  takeLatest(Types.OWNERS_BECOME_REQUEST, becomeOwner),
  takeLatest(Types.OWNERS_GET_DETAILS_REQUEST, getDetailsOwner),
  takeLatest(Types.OWNERS_RESET_DETAILS, resetDetailsOwner),
]);
