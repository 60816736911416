import Types from './types';

export function getSettingsRequest(system_category_id) {
  return {
    type: Types.SETTINGS_GET_REQUEST,
    payload: { system_category_id },
  };
}

export function getSettingsSuccess(settings) {
  return {
    type: Types.SETTINGS_GET_SUCCESS,
    payload: { settings },
  };
}

export function getSettingsFailure() {
  return {
    type: Types.SETTINGS_GET_FAILURE,
  };
}

export function updateSettingRequest(
  setting_id,
  setting_item_id,
  newSettingItem
) {
  return {
    type: Types.SETTINGS_UPDATE_REQUEST,
    payload: { setting_id, setting_item_id, newSettingItem },
  };
}

export function updateSettingSuccess(newSettingItem) {
  return {
    type: Types.SETTINGS_UPDATE_SUCCESS,
    payload: { newSettingItem },
  };
}

export function updateSettingFailure() {
  return {
    type: Types.SETTINGS_UPDATE_FAILURE,
  };
}

export function resetListSettings() {
  return {
    type: Types.SETTINGS_RESET_LIST,
  };
}

export function openModalSetting(id) {
  return {
    type: Types.SETTINGS_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalSetting() {
  return {
    type: Types.SETTINGS_CLOSE_MODAL,
  };
}

export function setGlobalListSettingSuccess(settings) {
  return {
    type: Types.SETTINGS_SET_GLOBAL_LIST_SUCCESS,
    payload: { settings },
  };
}

export function setGlobalListSettingFailure() {
  return {
    type: Types.SETTINGS_SET_GLOBAL_LIST_FAILURE,
  };
}

export function resetGlobalList() {
  return {
    type: Types.SETTINGS_RESET_GLOBAL_LIST,
  };
}

export function updateGlobalSetting(setting) {
  return {
    type: Types.SETTINGS_UPDATE_GLOBAL_SETTING,
    payload: {
      setting,
    },
  };
}

export function openModalConfigureDefaultPriceList(id) {
  return {
    type: Types.SETTINGS_CONFIGURE_DEFAULT_PRICE_LIST_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalConfigureDefaultPriceList() {
  return {
    type: Types.SETTINGS_CONFIGURE_DEFAULT_PRICE_LIST_CLOSE_MODAL,
  };
}

export function openModalConfigureDefaultPaymentMethod(id) {
  return {
    type: Types.SETTINGS_CONFIGURE_DEFAULT_PAYMENT_METHOD_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalConfigureDefaultPaymentMethod() {
  return {
    type: Types.SETTINGS_CONFIGURE_DEFAULT_PAYMENT_METHOD_CLOSE_MODAL,
  };
}

export function openModalConfigureDefaultProductDiscountType(id) {
  return {
    type: Types.SETTINGS_CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalConfigureDefaultProductDiscountType() {
  return {
    type: Types.SETTINGS_CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_CLOSE_MODAL,
  };
}
