import Types from './types';

export function getCompanyEmails(emails) {
  return {
    type: Types.COMPANY_EMAILS_GET,
    payload: { emails },
  };
}

export function createCompanyEmailRequest(company_id, email) {
  return {
    type: Types.COMPANY_EMAILS_CREATE_REQUEST,
    payload: { company_id, email },
  };
}

export function createCompanyEmailSuccess(email) {
  return {
    type: Types.COMPANY_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createCompanyEmailFailure() {
  return {
    type: Types.COMPANY_EMAILS_CREATE_FAILURE,
  };
}

export function updateCompanyEmailRequest(company_id, email_id, newEmail) {
  return {
    type: Types.COMPANY_EMAILS_UPDATE_REQUEST,
    payload: { company_id, email_id, newEmail },
  };
}

export function updateCompanyEmailSuccess(newEmail) {
  return {
    type: Types.COMPANY_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateCompanyEmailFailure() {
  return {
    type: Types.COMPANY_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteCompanyEmailRequest(company_id, email_id) {
  return {
    type: Types.COMPANY_EMAILS_DELETE_REQUEST,
    payload: { company_id, email_id },
  };
}

export function deleteCompanyEmailSuccess(email_id) {
  return {
    type: Types.COMPANY_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteCompanyEmailFailure() {
  return {
    type: Types.COMPANY_EMAILS_DELETE_FAILURE,
  };
}

export function resetListCompanyEmail() {
  return {
    type: Types.COMPANY_EMAILS_RESET_LIST,
  };
}

export function openModalCompanyEmail(email_id) {
  return {
    type: Types.COMPANY_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalCompanyEmail() {
  return {
    type: Types.COMPANY_EMAILS_CLOSE_MODAL,
  };
}
