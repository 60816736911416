const Types = {
  COST_CENTERS_GET_REQUEST: '@costCenters/GET_COST_CENTERS_REQUEST',
  COST_CENTERS_GET_SUCCESS: '@costCenters/GET_COST_CENTERS_SUCCESS',
  COST_CENTERS_GET_FAILURE: '@costCenters/GET_COST_CENTERS_FAILURE',

  COST_CENTERS_CREATE_REQUEST: '@costCenters/CREATE_COST_CENTER_REQUEST',
  COST_CENTERS_CREATE_SUCCESS: '@costCenters/CREATE_COST_CENTER_SUCCESS',
  COST_CENTERS_CREATE_FAILURE: '@costCenters/CREATE_COST_CENTER_FAILURE',

  COST_CENTERS_UPDATE_REQUEST: '@costCenters/UPDATE_COST_CENTER_REQUEST',
  COST_CENTERS_UPDATE_SUCCESS: '@costCenters/UPDATE_COST_CENTER_SUCCESS',
  COST_CENTERS_UPDATE_FAILURE: '@costCenters/UPDATE_COST_CENTER_FAILURE',

  COST_CENTERS_DELETE_REQUEST: '@costCenters/DELETE_COST_CENTER_REQUEST',
  COST_CENTERS_DELETE_SUCCESS: '@costCenters/DELETE_COST_CENTER_SUCCESS',
  COST_CENTERS_DELETE_FAILURE: '@costCenters/DELETE_COST_CENTER_FAILURE',

  COST_CENTERS_RESET_LIST: '@costCenters/RESET_LIST_COST_CENTERS',

  COST_CENTERS_OPEN_MODAL: '@costCenters/OPEN_MODAL_COST_CENTER',
  COST_CENTERS_CLOSE_MODAL: '@costCenters/CLOSE_MODAL_COST_CENTER',
};

export default Types;
