import styled from 'styled-components';

import { MoneyOff as MuiMoneyOff } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'info':
      styles = `
        height: 24px;
        width: 24px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const MoneyOff = styled(MuiMoneyOff)`
  ${({ type }) => defineStyles(type)}
`;
