import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getUnitsMeasureSuccess,
  getUnitsMeasureFailure,
  createUnitMeasureSuccess,
  createUnitMeasureFailure,
  updateUnitMeasureSuccess,
  updateUnitMeasureFailure,
  deleteUnitMeasureSuccess,
  deleteUnitMeasureFailure,
} from './actions';
import Types from './types';

export function* getUnitsMeasure({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `units-measure${queryParams}`);

    yield put(
      getUnitsMeasureSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getUnitsMeasureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createUnitMeasure({ payload }) {
  try {
    const { unitMeasure, source } = payload;

    const response = yield call(api.post, 'units-measure', unitMeasure);

    yield put(createUnitMeasureSuccess(response.data, source));

    if (source) {
      toast.success(
        <IntlMessages id="units-measure.create-unit-measure-success" />
      );
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createUnitMeasureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateUnitMeasure({ payload }) {
  try {
    const { id, newUnitMeasure } = payload;

    const response = yield call(api.put, `units-measure/${id}`, newUnitMeasure);

    yield put(updateUnitMeasureSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateUnitMeasureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteUnitMeasure({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `units-measure/${id}`);

    yield put(deleteUnitMeasureSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteUnitMeasureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.UNITS_MEASURE_GET_REQUEST, getUnitsMeasure),
  takeLatest(Types.UNITS_MEASURE_CREATE_REQUEST, createUnitMeasure),
  takeLatest(Types.UNITS_MEASURE_UPDATE_REQUEST, updateUnitMeasure),
  takeLatest(Types.UNITS_MEASURE_DELETE_REQUEST, deleteUnitMeasure),
]);
