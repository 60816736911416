import Types from './types';

export function getRegionSellers(sellers) {
  return {
    type: Types.REGION_SELLERS_GET,
    payload: { sellers },
  };
}

export function updateRegionSellerRequest(region_id, sellers) {
  return {
    type: Types.REGION_SELLERS_UPDATE_REQUEST,
    payload: { region_id, sellers },
  };
}

export function updateRegionSellerSuccess(sellers) {
  return {
    type: Types.REGION_SELLERS_UPDATE_SUCCESS,
    payload: { sellers },
  };
}

export function updateRegionSellerFailure() {
  return {
    type: Types.REGION_SELLERS_UPDATE_FAILURE,
  };
}

export function deleteRegionSellerRequest(region_id, region_seller_id) {
  return {
    type: Types.REGION_SELLERS_DELETE_REQUEST,
    payload: { region_id, region_seller_id },
  };
}

export function deleteRegionSellerSuccess(region_seller_id) {
  return {
    type: Types.REGION_SELLERS_DELETE_SUCCESS,
    payload: { region_seller_id },
  };
}

export function deleteRegionSellerFailure() {
  return {
    type: Types.REGION_SELLERS_DELETE_FAILURE,
  };
}

export function resetListRegionSeller() {
  return {
    type: Types.REGION_SELLERS_RESET_LIST,
  };
}

export function openModalRegionSeller() {
  return {
    type: Types.REGION_SELLERS_OPEN_MODAL,
  };
}

export function closeModalRegionSeller() {
  return {
    type: Types.REGION_SELLERS_CLOSE_MODAL,
  };
}
