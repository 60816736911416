import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';
import { round10 } from '~/utils/match';

import { updateOrderRequest } from '../orders/actions';
import {
  createOrderItemSuccess,
  createOrderItemFailure,
  updateOrderItemSuccess,
  updateOrderItemFailure,
  deleteOrderItemSuccess,
  deleteOrderItemFailure,
} from './actions';
import Types from './types';

function* updateOrderValues(recalculateValues) {
  if (!recalculateValues) {
    return;
  }

  const orderItemsList = yield select(state => state.orderItems.list);
  const orderDetail = yield select(state => state.orders.detail);

  let totalProductRounded = 0;
  let totalServiceRounded = 0;

  const filteredProductItems = orderItemsList.filter(
    productItem =>
      productItem.type === 'PRODUCT' && productItem.situation !== 'C'
  );

  if (filteredProductItems.length > 0) {
    const productItems = filteredProductItems.map(
      filteredProduct => filteredProduct.total
    );

    const totalProductValue = productItems.reduce(
      (total, value) => total + value,
      0
    );

    totalProductRounded = round10(totalProductValue, -2);
  }

  const filteredServiceItems = orderItemsList.filter(
    serviceItem =>
      serviceItem.type === 'SERVICE' && serviceItem.situation !== 'C'
  );

  if (filteredServiceItems.length > 0) {
    const serviceItems = filteredServiceItems.map(
      filteredService => filteredService.total
    );

    const totalServiceValue = serviceItems.reduce(
      (total, value) => total + value,
      0
    );

    totalServiceRounded = round10(totalServiceValue, -2);
  }

  const { taxes, shipping_fee, shipping_by } = orderDetail;

  const taxesRounded = round10(taxes, -2);

  const shippingFeeRounded =
    shipping_by === 2 || shipping_by === 3 ? round10(shipping_fee, -2) : 0;

  const total =
    totalProductRounded +
    totalServiceRounded +
    taxesRounded +
    shippingFeeRounded;

  const totalRounded = round10(total, -2);

  const data = {
    ...orderDetail,
    total_services: totalServiceRounded,
    total_products: totalProductRounded,
    total: totalRounded,
    discount_value: 0,
    discount_percentage: 0,
  };

  yield put(
    updateOrderRequest({
      id: orderDetail.id,
      newOrder: data,
      updatingItem: true,
    })
  );
}

export function* createOrderItem({ payload }) {
  try {
    const {
      order_id,
      item,
      saveAndNew,
      resetForm,
      handleFocusProductAfterSaveAndNew,
    } = payload;

    const response = yield call(api.post, `orders/${order_id}/items`, item);

    yield put(createOrderItemSuccess(response.data, saveAndNew));

    if (saveAndNew && resetForm && handleFocusProductAfterSaveAndNew) {
      resetForm();
      handleFocusProductAfterSaveAndNew();
    }

    yield updateOrderValues(true);

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createOrderItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOrderItem({ payload }) {
  try {
    const { order_id, item_id, newItem, recalculateValues } = payload;

    const response = yield call(
      api.put,
      `orders/${order_id}/items/${item_id}`,
      newItem
    );

    yield put(updateOrderItemSuccess(response.data));

    yield updateOrderValues(recalculateValues);

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateOrderItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOrderItem({ payload }) {
  try {
    const { order_id, item_id } = payload;
    yield call(api.delete, `orders/${order_id}/items/${item_id}`);

    yield put(deleteOrderItemSuccess(item_id));

    yield updateOrderValues(true);

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteOrderItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.ORDER_ITEMS_CREATE_REQUEST, createOrderItem),
  takeLatest(Types.ORDER_ITEMS_UPDATE_REQUEST, updateOrderItem),
  takeLatest(Types.ORDER_ITEMS_DELETE_REQUEST, deleteOrderItem),
]);
