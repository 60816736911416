const Types = {
  PRICE_LIST_ITEMS_CREATE_REQUEST: '@priceList/CREATE_PRICE_LIST_ITEM_REQUEST',
  PRICE_LIST_ITEMS_CREATE_SUCCESS: '@priceList/CREATE_PRICE_LIST_ITEM_SUCCESS',
  PRICE_LIST_ITEMS_CREATE_FAILURE: '@priceList/CREATE_PRICE_LIST_ITEM_FAILURE',

  PRICE_LIST_ITEMS_UPDATE_REQUEST: '@priceList/UPDATE_PRICE_LIST_ITEM_REQUEST',
  PRICE_LIST_ITEMS_UPDATE_SUCCESS: '@priceList/UPDATE_PRICE_LIST_ITEM_SUCCESS',
  PRICE_LIST_ITEMS_UPDATE_FAILURE: '@priceList/UPDATE_PRICE_LIST_ITEM_FAILURE',

  PRICE_LIST_ITEMS_DELETE_REQUEST: '@priceList/DELETE_PRICE_LIST_ITEM_REQUEST',
  PRICE_LIST_ITEMS_DELETE_SUCCESS: '@priceList/DELETE_PRICE_LIST_ITEM_SUCCESS',
  PRICE_LIST_ITEMS_DELETE_FAILURE: '@priceList/DELETE_PRICE_LIST_ITEM_FAILURE',

  PRICE_LIST_ITEMS_RESET_LIST: '@priceList/RESET_LIST_PRICE_LIST_ITEM',

  PRICE_LIST_ITEMS_OPEN_MODAL: '@priceList/OPEN_MODAL_PRICE_LIST_ITEM',
  PRICE_LIST_ITEMS_CLOSE_MODAL: '@priceList/CLOSE_MODAL_PRICE_LIST_ITEM',

  PRICE_LIST_ITEMS_OPEN_NEW_ITEM_MODAL:
    '@priceList/OPEN_NEW_ITEM_MODAL_PRICE_LIST_ITEM',
  PRICE_LIST_ITEMS_CLOSE_NEW_ITEM_MODAL:
    '@priceList/CLOSE_NEW_ITEM_MODAL_PRICE_LIST_ITEM',

  PRICE_LIST_ITEMS_FILTER_OPEN_MODAL:
    '@priceList/OPEN_MODAL_FILTER_PRICE_LIST_ITEM',
  PRICE_LIST_ITEMS_FILTER_CLOSE_MODAL:
    '@priceList/CLOSE_MODAL_FILTER_PRICE_LIST_ITEM',
  PRICE_LIST_ITEMS_SET_TABLE_FILTERS:
    '@priceList/SET_TABLE_FILTERS_PRICE_LIST_ITEM',
};

export default Types;
