const Types = {
  SUPERVISOR_FILES_GET: '@supervisors/GET_SUPERVISOR_FILES',

  SUPERVISOR_FILES_CREATE_REQUEST:
    '@supervisors/CREATE_SUPERVISOR_FILE_REQUEST',
  SUPERVISOR_FILES_CREATE_SUCCESS:
    '@supervisors/CREATE_SUPERVISOR_FILE_SUCCESS',
  SUPERVISOR_FILES_CREATE_FAILURE:
    '@supervisors/CREATE_SUPERVISOR_FILE_FAILURE',

  SUPERVISOR_FILES_UPDATE_REQUEST:
    '@supervisors/UPDATE_SUPERVISOR_FILE_REQUEST',
  SUPERVISOR_FILES_UPDATE_SUCCESS:
    '@supervisors/UPDATE_SUPERVISOR_FILE_SUCCESS',
  SUPERVISOR_FILES_UPDATE_FAILURE:
    '@supervisors/UPDATE_SUPERVISOR_FILE_FAILURE',

  SUPERVISOR_FILES_DELETE_REQUEST:
    '@supervisors/DELETE_SUPERVISOR_FILE_REQUEST',
  SUPERVISOR_FILES_DELETE_SUCCESS:
    '@supervisors/DELETE_SUPERVISOR_FILE_SUCCESS',
  SUPERVISOR_FILES_DELETE_FAILURE:
    '@supervisors/DELETE_SUPERVISOR_FILE_FAILURE',

  SUPERVISOR_FILES_OPEN_MODAL: '@supervisors/OPEN_MODAL_SUPERVISOR_FILE',
  SUPERVISOR_FILES_CLOSE_MODAL: '@supervisors/CLOSE_MODAL_SUPERVISOR_FILE',

  SUPERVISOR_FILES_RESET_LIST: '@supervisors/RESET_LIST_SUPERVISOR_FILE',
};

export default Types;
