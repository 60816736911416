const Types = {
  COMPANY_FILES_GET: '@company/GET_COMPANY_FILES',

  COMPANY_FILES_CREATE_REQUEST: '@company/CREATE_COMPANY_FILE_REQUEST',
  COMPANY_FILES_CREATE_SUCCESS: '@company/CREATE_COMPANY_FILE_SUCCESS',
  COMPANY_FILES_CREATE_FAILURE: '@company/CREATE_COMPANY_FILE_FAILURE',

  COMPANY_FILES_UPDATE_REQUEST: '@company/UPDATE_COMPANY_FILE_REQUEST',
  COMPANY_FILES_UPDATE_SUCCESS: '@company/UPDATE_COMPANY_FILE_SUCCESS',
  COMPANY_FILES_UPDATE_FAILURE: '@company/UPDATE_COMPANY_FILE_FAILURE',

  COMPANY_FILES_DELETE_REQUEST: '@company/DELETE_COMPANY_FILE_REQUEST',
  COMPANY_FILES_DELETE_SUCCESS: '@company/DELETE_COMPANY_FILE_SUCCESS',
  COMPANY_FILES_DELETE_FAILURE: '@company/DELETE_COMPANY_FILE_FAILURE',

  COMPANY_FILES_RESET_LIST: '@company/RESET_LIST_COMPANY_FILE',

  COMPANY_FILES_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_FILE',
  COMPANY_FILES_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_FILE',
};

export default Types;
