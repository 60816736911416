import produce from 'immer';

import OrderBillPixTypes from '../orderBillPixs/types';
import OrderTypes from '../orders/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  orderBillModalOpen: false,
  orderBillEditModalOpen: false,
  isCreatingFromList: false,
};

export default function orderBills(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ORDER_BILLS_GET: {
        draft.list = action.payload.bills;
        break;
      }

      case Types.ORDER_BILLS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_BILLS_CREATE_SUCCESS: {
        draft.list = action.payload.bills;
        draft.loading = false;
        break;
      }
      case Types.ORDER_BILLS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_BILLS_UPDATE_BILL_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_BILLS_UPDATE_BILL_SUCCESS: {
        const { newBill } = action.payload;

        draft.detail = {};
        draft.orderBillEditModalOpen = false;
        draft.loading = false;

        draft.list = state.list.map(bill => {
          return bill.id === newBill.id
            ? { ...newBill, pixs: bill.pixs }
            : bill;
        });
        break;
      }
      case Types.ORDER_BILLS_UPDATE_BILL_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_BILLS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_BILLS_DELETE_SUCCESS: {
        const { bill_id } = action.payload;

        draft.list = state.list.filter(item => {
          return item.id !== bill_id;
        });

        draft.loading = false;
        break;
      }
      case Types.ORDER_BILLS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_BILLS_DELETE_ALL_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_BILLS_DELETE_ALL_SUCCESS: {
        draft.list = [];

        draft.loading = false;
        break;
      }
      case Types.ORDER_BILLS_DELETE_ALL_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_BILLS_SET_SELECTED_BILL: {
        const { bill_id } = action.payload;

        [draft.detail] = draft.list.filter(bill => bill.id === bill_id);

        break;
      }

      case Types.ORDER_BILLS_RESET_LIST: {
        draft.list = [];
        draft.isCreatingFromList = false;
        break;
      }

      case Types.ORDER_BILLS_OPEN_MODAL: {
        draft.orderBillModalOpen = true;
        break;
      }
      case Types.ORDER_BILLS_CLOSE_MODAL: {
        draft.orderBillModalOpen = false;
        break;
      }

      case Types.ORDER_BILLS_EDIT_OPEN_MODAL: {
        const { bill_id } = action.payload;

        draft.detail = {};

        if (bill_id) {
          [draft.detail] = draft.list.filter(bill => bill.id === bill_id);
        }

        draft.orderBillEditModalOpen = true;
        break;
      }
      case Types.ORDER_BILLS_EDIT_CLOSE_MODAL: {
        draft.orderBillEditModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.ORDER_BILLS_SET_LIST: {
        draft.list = action.payload.bills;
        draft.isCreatingFromList = true;
        break;
      }

      case OrderTypes.ORDERS_RESET_DETAILS: {
        draft.list = [];
        draft.detail = {};
        draft.orderBillModalOpen = false;
        draft.orderBillEditModalOpen = false;
        draft.isCreatingFromList = false;
        break;
      }

      case OrderTypes.ORDERS_RESET_LIST: {
        draft.list = [];
        draft.detail = {};
        draft.orderBillModalOpen = false;
        draft.orderBillEditModalOpen = false;
        draft.isCreatingFromList = false;
        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_SHARE_CLOSE_MODAL: {
        if (draft.isCreatingFromList) {
          draft.list = [];
          draft.isCreatingFromList = false;
        }

        draft.detail = {};

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_DETAIL_CLOSE_MODAL: {
        if (draft.isCreatingFromList) {
          draft.list = [];
          draft.isCreatingFromList = false;
        }

        draft.detail = {};
        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_ADD_ACCOUNT_CLOSE_MODAL: {
        if (draft.isCreatingFromList) {
          draft.list = [];
          draft.isCreatingFromList = false;
        }

        draft.detail = {};
        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_EMIT_CLOSE_MODAL: {
        if (draft.isCreatingFromList) {
          draft.list = [];
          draft.isCreatingFromList = false;
        }

        draft.detail = {};
        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_CLOSE_MODAL: {
        if (draft.isCreatingFromList) {
          draft.list = [];
          draft.isCreatingFromList = false;
        }

        draft.detail = {};
        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_CREATE_SUCCESS: {
        const findBill = state.list.find(item => item.id === state.detail.id);

        const newData = {
          ...findBill,
          pixs: [...findBill.pixs, action.payload.pix],
        };

        draft.list = state.list.map(bill => {
          return bill.id === newData.id ? newData : bill;
        });

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_SHARE_OPEN_MODAL: {
        if (action.payload.pix) {
          const findBill = state.list.find(item => item.id === state.detail.id);

          const billPixs = findBill.pixs.map(pix =>
            pix.id === action.payload.pix.id ? action.payload.pix : pix
          );

          const newData = {
            ...findBill,
            pixs: billPixs,
          };

          draft.list = state.list.map(bill => {
            return bill.id === newData.id ? newData : bill;
          });
        }

        break;
      }

      case OrderBillPixTypes.ORDER_BILL_PIXS_DETAIL_OPEN_MODAL: {
        if (action.payload.pix) {
          const findBill = state.list.find(item => item.id === state.detail.id);

          const billPixs = findBill.pixs.map(pix =>
            pix.id === action.payload.pix.id ? action.payload.pix : pix
          );

          const newData = {
            ...findBill,
            pixs: billPixs,
          };

          draft.list = state.list.map(bill => {
            return bill.id === newData.id ? newData : bill;
          });
        }

        break;
      }

      default:
    }
  });
}
