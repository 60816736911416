const Types = {
  SELLER_ADDRESSES_GET: '@sellers/GET_SELLER_ADDRESSES',

  SELLER_ADDRESSES_CREATE_REQUEST: '@sellers/CREATE_SELLER_ADDRESS_REQUEST',
  SELLER_ADDRESSES_CREATE_SUCCESS: '@sellers/CREATE_SELLER_ADDRESS_SUCCESS',
  SELLER_ADDRESSES_CREATE_FAILURE: '@sellers/CREATE_SELLER_ADDRESS_FAILURE',

  SELLER_ADDRESSES_UPDATE_REQUEST: '@sellers/UPDATE_SELLER_ADDRESS_REQUEST',
  SELLER_ADDRESSES_UPDATE_SUCCESS: '@sellers/UPDATE_SELLER_ADDRESS_SUCCESS',
  SELLER_ADDRESSES_UPDATE_FAILURE: '@sellers/UPDATE_SELLER_ADDRESS_FAILURE',

  SELLER_ADDRESSES_DELETE_REQUEST: '@sellers/DELETE_SELLER_ADDRESS_REQUEST',
  SELLER_ADDRESSES_DELETE_SUCCESS: '@sellers/DELETE_SELLER_ADDRESS_SUCCESS',
  SELLER_ADDRESSES_DELETE_FAILURE: '@sellers/DELETE_SELLER_ADDRESS_FAILURE',

  SELLER_ADDRESSES_RESET_LIST: '@sellers/RESET_LIST_SELLER_ADDRESS',

  SELLER_ADDRESSES_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_ADDRESS',
  SELLER_ADDRESSES_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_ADDRESS',
};

export default Types;
