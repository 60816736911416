import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getRuralPropertyAddresses,
  resetListRuralPropertyAddress,
  openModalRuralPropertyAddress,
} from '../ruralPropertyAddresses/actions';
import {
  getRuralPropertyEmails,
  resetListRuralPropertyEmail,
} from '../ruralPropertyEmails/actions';
import {
  getRuralPropertyFiles,
  resetListRuralPropertyFile,
} from '../ruralPropertyFiles/actions';
import {
  getRuralPropertyOwners,
  resetListRuralPropertyOwner,
} from '../ruralPropertyOwners/actions';
import {
  getRuralPropertyPhones,
  resetListRuralPropertyPhone,
} from '../ruralPropertyPhones/actions';
import {
  getRuralPropertyRegistrations,
  resetListRuralPropertyRegistration,
} from '../ruralPropertyRegistrations/actions';
import {
  getRuralPropertiesSuccess,
  getRuralPropertiesFailure,
  createRuralPropertySuccess,
  createRuralPropertyFailure,
  deleteRuralPropertySuccess,
  deleteRuralPropertyFailure,
  getDetailsRuralPropertySuccess,
  updateRuralPropertySuccess,
  updateRuralPropertyFailure,
  getDetailsRuralPropertyFailure,
} from './actions';
import Types from './types';

export function* getRuralProperties({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `rural-properties${queryParams}`);

    yield put(
      getRuralPropertiesSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getRuralPropertiesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createRuralProperty({ payload }) {
  try {
    const { name } = payload.ruralProperty;

    const response = yield call(api.post, 'rural-properties', {
      name,
    });

    yield put(createRuralPropertySuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRuralProperty({ payload }) {
  try {
    const { id, newRuralProperty } = payload;

    const response = yield call(
      api.put,
      `rural-properties/${id}`,
      newRuralProperty
    );

    yield put(updateRuralPropertySuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRuralPropertyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteRuralProperty({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `rural-properties/${id}`);

    yield put(deleteRuralPropertySuccess(id));

    history.push('/rural-properties');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRuralPropertyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsRuralProperty({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `rural-properties/${id}`);

    const { phones, addresses, emails, registrations, owners, files, ...rest } =
      response.data;

    yield put(getRuralPropertyPhones(phones));

    yield put(getRuralPropertyAddresses(addresses));

    yield put(getRuralPropertyEmails(emails));

    yield put(getRuralPropertyRegistrations(registrations));

    yield put(getRuralPropertyOwners(owners));

    yield put(getRuralPropertyFiles(files));

    yield put(getDetailsRuralPropertySuccess(rest));

    if (addresses.length === 0) {
      yield put(openModalRuralPropertyAddress());
    }
  } catch (err) {
    yield put(getDetailsRuralPropertyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsRuralProperty() {
  yield put(resetListRuralPropertyPhone());

  yield put(resetListRuralPropertyAddress());

  yield put(resetListRuralPropertyEmail());

  yield put(resetListRuralPropertyRegistration());

  yield put(resetListRuralPropertyOwner());

  yield put(resetListRuralPropertyFile());
}

export default all([
  takeLatest(Types.RURAL_PROPERTIES_GET_REQUEST, getRuralProperties),
  takeLatest(Types.RURAL_PROPERTIES_CREATE_REQUEST, createRuralProperty),
  takeLatest(Types.RURAL_PROPERTIES_UPDATE_REQUEST, updateRuralProperty),
  takeLatest(Types.RURAL_PROPERTIES_DELETE_REQUEST, deleteRuralProperty),
  takeLatest(
    Types.RURAL_PROPERTIES_GET_DETAILS_REQUEST,
    getDetailsRuralProperty
  ),
  takeLatest(Types.RURAL_PROPERTIES_RESET_DETAILS, resetDetailsRuralProperty),
]);
