import Types from './types';

export function getTransporterAddresses(addresses) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createTransporterAddressRequest(transporter_id, address) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_CREATE_REQUEST,
    payload: { transporter_id, address },
  };
}

export function createTransporterAddressSuccess(address) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createTransporterAddressFailure() {
  return {
    type: Types.TRANSPORTER_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateTransporterAddressRequest(
  transporter_id,
  address_id,
  newAddress
) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_UPDATE_REQUEST,
    payload: { transporter_id, address_id, newAddress },
  };
}

export function updateTransporterAddressSuccess(newAddress) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateTransporterAddressFailure() {
  return {
    type: Types.TRANSPORTER_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteTransporterAddressRequest(transporter_id, address_id) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_DELETE_REQUEST,
    payload: { transporter_id, address_id },
  };
}

export function deleteTransporterAddressSuccess(address_id) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteTransporterAddressFailure() {
  return {
    type: Types.TRANSPORTER_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListTransporterAddress() {
  return {
    type: Types.TRANSPORTER_ADDRESSES_RESET_LIST,
  };
}

export function openModalTransporterAddress(address_id) {
  return {
    type: Types.TRANSPORTER_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalTransporterAddress() {
  return {
    type: Types.TRANSPORTER_ADDRESSES_CLOSE_MODAL,
  };
}
