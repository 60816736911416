import Types from './types';

export function getCountriesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.COUNTRIES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getCountriesSuccess({
  data,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.COUNTRIES_GET_SUCCESS,
    payload: {
      data,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getCountriesFailure() {
  return {
    type: Types.COUNTRIES_GET_FAILURE,
  };
}

export function resetListCountries() {
  return {
    type: Types.COUNTRIES_RESET_LIST,
  };
}
