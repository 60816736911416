import Types from './types';

export function getPaymentMethodsTypesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.PAYMENT_METHODS_TYPES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getPaymentMethodsTypesSuccess({
  data,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.PAYMENT_METHODS_TYPES_GET_SUCCESS,
    payload: {
      data,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getPaymentMethodsTypesFailure() {
  return {
    type: Types.PAYMENT_METHODS_TYPES_GET_FAILURE,
  };
}

export function resetListPaymentMethodsTypes() {
  return {
    type: Types.PAYMENT_METHODS_TYPES_RESET_LIST,
  };
}
