import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { resetFoundPerson } from '../persons/actions';
import {
  getPromoterAddresses,
  openModalPromoterAddress,
  resetListPromoterAddress,
} from '../promoterAddresses/actions';
import {
  getPromoterEmails,
  openModalPromoterEmail,
  resetListPromoterEmail,
} from '../promoterEmails/actions';
import {
  getPromoterFiles,
  resetListPromoterFile,
} from '../promoterFiles/actions';
import {
  getPromoterPhones,
  openModalPromoterPhone,
  resetListPromoterPhone,
} from '../promoterPhones/actions';
import {
  getPromotersSuccess,
  getPromotersFailure,
  createPromoterSuccess,
  createPromoterFailure,
  deletePromoterSuccess,
  deletePromoterFailure,
  becomePromoterSuccess,
  becomePromoterFailure,
  getDetailsPromoterSuccess,
  updatePromoterSuccess,
  updatePromoterFailure,
  getDetailsPromoterFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getPromoters({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `promoters${queryParams}`);

    yield put(
      getPromotersSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getPromotersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createPromoter({ payload }) {
  try {
    const { promoter, subResourceType } = payload;

    const response = yield call(api.post, 'promoters', promoter);

    yield put(createPromoterSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/promoter/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createPromoterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePromoter({ payload }) {
  try {
    const { id, newPromoter } = payload;

    const response = yield call(api.put, `promoters/${id}`, newPromoter);

    yield put(updatePromoterSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updatePromoterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePromoter({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `promoters/${id}`);

    yield put(deletePromoterSuccess(id));

    history.push('/promoters');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePromoterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomePromoter({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'PROMOTER';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomePromoterSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/promoter/${id}`);
  } catch (err) {
    yield put(becomePromoterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsPromoter({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `promoters/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getPromoterPhones(phones));

    yield put(getPromoterAddresses(addresses));

    yield put(getPromoterEmails(emails));

    yield put(getPromoterFiles(files));

    yield put(getDetailsPromoterSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.promoters
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalPromoterPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalPromoterEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalPromoterAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsPromoterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsPromoter() {
  yield put(resetListPromoterPhone());

  yield put(resetListPromoterAddress());

  yield put(resetListPromoterEmail());

  yield put(resetListPromoterFile());
}

export default all([
  takeLatest(Types.PROMOTERS_GET_REQUEST, getPromoters),
  takeLatest(Types.PROMOTERS_CREATE_REQUEST, createPromoter),
  takeLatest(Types.PROMOTERS_UPDATE_REQUEST, updatePromoter),
  takeLatest(Types.PROMOTERS_DELETE_REQUEST, deletePromoter),
  takeLatest(Types.PROMOTERS_BECOME_REQUEST, becomePromoter),
  takeLatest(Types.PROMOTERS_GET_DETAILS_REQUEST, getDetailsPromoter),
  takeLatest(Types.PROMOTERS_RESET_DETAILS, resetDetailsPromoter),
]);
