const Types = {
  SUPPLIERS_GET_REQUEST: '@suppliers/GET_SUPPLIERS_REQUEST',
  SUPPLIERS_GET_SUCCESS: '@suppliers/GET_SUPPLIERS_SUCCESS',
  SUPPLIERS_GET_FAILURE: '@suppliers/GET_SUPPLIERS_FAILURE',

  SUPPLIERS_CREATE_REQUEST: '@suppliers/CREATE_SUPPLIER_REQUEST',
  SUPPLIERS_CREATE_SUCCESS: '@suppliers/CREATE_SUPPLIER_SUCCESS',
  SUPPLIERS_CREATE_FAILURE: '@suppliers/CREATE_SUPPLIER_FAILURE',

  SUPPLIERS_UPDATE_REQUEST: '@suppliers/UPDATE_SUPPLIER_REQUEST',
  SUPPLIERS_UPDATE_SUCCESS: '@suppliers/UPDATE_SUPPLIER_SUCCESS',
  SUPPLIERS_UPDATE_FAILURE: '@suppliers/UPDATE_SUPPLIER_FAILURE',

  SUPPLIERS_DELETE_REQUEST: '@suppliers/DELETE_SUPPLIER_REQUEST',
  SUPPLIERS_DELETE_SUCCESS: '@suppliers/DELETE_SUPPLIER_SUCCESS',
  SUPPLIERS_DELETE_FAILURE: '@suppliers/DELETE_SUPPLIER_FAILURE',

  SUPPLIERS_BECOME_REQUEST: '@suppliers/BECOME_SUPPLIER_REQUEST',
  SUPPLIERS_BECOME_SUCCESS: '@suppliers/BECOME_SUPPLIER_SUCCESS',
  SUPPLIERS_BECOME_FAILURE: '@suppliers/BECOME_SUPPLIER_FAILURE',

  SUPPLIERS_GET_DETAILS_REQUEST: '@suppliers/GET_DETAILS_SUPPLIER_REQUEST',
  SUPPLIERS_GET_DETAILS_SUCCESS: '@suppliers/GET_DETAILS_SUPPLIER_SUCCESS',
  SUPPLIERS_GET_DETAILS_FAILURE: '@suppliers/GET_DETAILS_SUPPLIER_FAILURE',

  SUPPLIERS_RESET_LIST: '@suppliers/RESET_LIST_SUPPLIERS',
  SUPPLIERS_RESET_DETAILS: '@suppliers/RESET_DETAILS_SUPPLIER',

  SUPPLIERS_SET_SAVING_ON_SUB_RESOURCE:
    '@suppliers/SET_SAVING_ON_SUB_RESOURCE_SUPPLIER',
  SUPPLIERS_RESET_SUB_RESOURCE_DATA:
    '@suppliers/RESET_SUB_RESOURCE_DATA_SUPPLIER',

  SUPPLIERS_OPEN_FILE_DIALOG: '@suppliers/OPEN_SUPPLIER_FILE_DIALOG',

  SUPPLIERS_ADD_OPEN_MODAL: '@suppliers/OPEN_MODAL_ADD_SUPPLIER',
  SUPPLIERS_ADD_CLOSE_MODAL: '@suppliers/CLOSE_MODAL_ADD_SUPPLIER',

  SUPPLIERS_FILTER_OPEN_MODAL: '@suppliers/OPEN_MODAL_FILTER_SUPPLIER',
  SUPPLIERS_FILTER_CLOSE_MODAL: '@suppliers/CLOSE_MODAL_FILTER_SUPPLIER',

  SUPPLIERS_OBSERVATION_OPEN_MODAL:
    '@suppliers/OPEN_MODAL_SUPPLIER_OBSERVATION',
  SUPPLIERS_OBSERVATION_CLOSE_MODAL:
    '@suppliers/CLOSE_MODAL_SUPPLIER_OBSERVATION',
};

export default Types;
