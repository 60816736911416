import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createOwnerAddressSuccess,
  createOwnerAddressFailure,
  updateOwnerAddressSuccess,
  updateOwnerAddressFailure,
  deleteOwnerAddressSuccess,
  deleteOwnerAddressFailure,
} from './actions';
import Types from './types';

export function* createOwnerAddress({ payload }) {
  try {
    const { owner_id, address } = payload;

    const response = yield call(
      api.post,
      `owners/${owner_id}/addresses`,
      address
    );

    yield put(createOwnerAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createOwnerAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOwnerAddress({ payload }) {
  try {
    const { owner_id, address_id, newAddress } = payload;

    const response = yield call(
      api.put,
      `owners/${owner_id}/addresses/${address_id}`,
      newAddress
    );

    yield put(updateOwnerAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateOwnerAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOwnerAddress({ payload }) {
  const { owner_id, address_id } = payload;

  const addresses = yield select(state => state.ownerAddresses.list);

  const address = addresses.find(data => data.id === address_id);

  if (address.is_main && addresses.length > 1) {
    yield put(deleteOwnerAddressFailure());

    toast.error(<IntlMessages id="addresses.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `owners/${owner_id}/addresses/${address_id}`);

    yield put(deleteOwnerAddressSuccess(address_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteOwnerAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.OWNER_ADDRESSES_CREATE_REQUEST, createOwnerAddress),
  takeLatest(Types.OWNER_ADDRESSES_UPDATE_REQUEST, updateOwnerAddress),
  takeLatest(Types.OWNER_ADDRESSES_DELETE_REQUEST, deleteOwnerAddress),
]);
