import produce from 'immer';

import CustomerTypes from '../customers/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  customerAddressModalOpen: false,
};

export default function customerAddresses(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CUSTOMER_ADDRESSES_GET: {
        draft.list = action.payload.addresses;
        break;
      }

      case Types.CUSTOMER_ADDRESSES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_CREATE_SUCCESS: {
        const { is_main } = action.payload.address;

        if (is_main) {
          draft.list = draft.list.map(address => ({
            ...address,
            is_main: false,
          }));
        }

        draft.list.unshift(action.payload.address);
        draft.loading = false;
        draft.customerAddressModalOpen = false;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CUSTOMER_ADDRESSES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_UPDATE_SUCCESS: {
        const { newAddress } = action.payload;
        const { is_main } = newAddress;
        let data = [];

        if (is_main) {
          const removeMain = draft.list.map(address => ({
            ...address,
            is_main: false,
          }));

          data = removeMain;
        } else {
          data = state.list;
        }

        draft.list = data.map(address => {
          return address.id === newAddress.id ? newAddress : address;
        });

        draft.detail = {};
        draft.loading = false;
        draft.customerAddressModalOpen = false;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CUSTOMER_ADDRESSES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_DELETE_SUCCESS: {
        const { address_id } = action.payload;

        draft.list = state.list.filter(address => {
          return address.id !== address_id;
        });

        if (draft.detail.id && address_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CUSTOMER_ADDRESSES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.CUSTOMER_ADDRESSES_OPEN_MODAL: {
        const { address_id } = action.payload;

        draft.detail = {};

        if (address_id) {
          [draft.detail] = draft.list.filter(
            address => address.id === address_id
          );
        }

        draft.customerAddressModalOpen = true;
        break;
      }
      case Types.CUSTOMER_ADDRESSES_CLOSE_MODAL: {
        draft.customerAddressModalOpen = false;
        draft.detail = {};
        break;
      }

      case CustomerTypes.CUSTOMERS_RESET_DETAILS: {
        draft.customerAddressModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
