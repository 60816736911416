const Types = {
  OWNER_EMAILS_GET: '@owners/GET_OWNER_EMAILS',

  OWNER_EMAILS_CREATE_REQUEST: '@owners/CREATE_OWNER_EMAIL_REQUEST',
  OWNER_EMAILS_CREATE_SUCCESS: '@owners/CREATE_OWNER_EMAIL_SUCCESS',
  OWNER_EMAILS_CREATE_FAILURE: '@owners/CREATE_OWNER_EMAIL_FAILURE',

  OWNER_EMAILS_UPDATE_REQUEST: '@owners/UPDATE_OWNER_EMAIL_REQUEST',
  OWNER_EMAILS_UPDATE_SUCCESS: '@owners/UPDATE_OWNER_EMAIL_SUCCESS',
  OWNER_EMAILS_UPDATE_FAILURE: '@owners/UPDATE_OWNER_EMAIL_FAILURE',

  OWNER_EMAILS_DELETE_REQUEST: '@owners/DELETE_OWNER_EMAIL_REQUEST',
  OWNER_EMAILS_DELETE_SUCCESS: '@owners/DELETE_OWNER_EMAIL_SUCCESS',
  OWNER_EMAILS_DELETE_FAILURE: '@owners/DELETE_OWNER_EMAIL_FAILURE',

  OWNER_EMAILS_RESET_LIST: '@owners/RESET_LIST_OWNER_EMAIL',

  OWNER_EMAILS_OPEN_MODAL: '@owners/OPEN_MODAL_OWNER_EMAIL',
  OWNER_EMAILS_CLOSE_MODAL: '@owners/CLOSE_MODAL_OWNER_EMAIL',
};

export default Types;
