import React from 'react';
import { Globe } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const CitiesList = async(() => import('~/pages/Cities/List'));
const CountriesList = async(() => import('~/pages/Countries/List'));
const RegionsList = async(() => import('~/pages/Regions/List'));
const StatesList = async(() => import('~/pages/States/List'));

export const regionRoutes = {
  id: <IntlMessages id="sidebar.regions" />,
  path: '/regions',
  icon: <Globe />,
  children: [
    {
      path: '/regions',
      name: <IntlMessages id="sidebar.regions.list" />,
      component: RegionsList,
    },
    {
      path: '/cities',
      name: <IntlMessages id="sidebar.regions.cities" />,
      component: CitiesList,
    },
    {
      path: '/states',
      name: <IntlMessages id="sidebar.regions.states" />,
      component: StatesList,
    },
    {
      path: '/countries',
      name: <IntlMessages id="sidebar.regions.countries" />,
      component: CountriesList,
    },
  ],
};
