const Types = {
  CUSTOMERS_CREATE_REQUEST: '@customers/CREATE_CUSTOMER_REQUEST',
  CUSTOMERS_CREATE_SUCCESS: '@customers/CREATE_CUSTOMER_SUCCESS',
  CUSTOMERS_CREATE_FAILURE: '@customers/CREATE_CUSTOMER_FAILURE',

  CUSTOMERS_UPDATE_REQUEST: '@customers/UPDATE_CUSTOMER_REQUEST',
  CUSTOMERS_UPDATE_SUCCESS: '@customers/UPDATE_CUSTOMER_SUCCESS',
  CUSTOMERS_UPDATE_FAILURE: '@customers/UPDATE_CUSTOMER_FAILURE',

  CUSTOMERS_DELETE_REQUEST: '@customers/DELETE_CUSTOMER_REQUEST',
  CUSTOMERS_DELETE_SUCCESS: '@customers/DELETE_CUSTOMER_SUCCESS',
  CUSTOMERS_DELETE_FAILURE: '@customers/DELETE_CUSTOMER_FAILURE',

  CUSTOMERS_BECOME_REQUEST: '@customers/BECOME_CUSTOMER_REQUEST',
  CUSTOMERS_BECOME_SUCCESS: '@customers/BECOME_CUSTOMER_SUCCESS',
  CUSTOMERS_BECOME_FAILURE: '@customers/BECOME_CUSTOMER_FAILURE',

  CUSTOMERS_GET_DETAILS_REQUEST: '@customers/GET_DETAILS_CUSTOMER_REQUEST',
  CUSTOMERS_GET_DETAILS_SUCCESS: '@customers/GET_DETAILS_CUSTOMER_SUCCESS',
  CUSTOMERS_GET_DETAILS_FAILURE: '@customers/GET_DETAILS_CUSTOMER_FAILURE',

  CUSTOMERS_RESET_LIST: '@customers/RESET_LIST_CUSTOMERS',
  CUSTOMERS_RESET_DETAILS: '@customers/RESET_DETAILS_CUSTOMER',

  CUSTOMERS_SET_SAVING_ON_SUB_RESOURCE:
    '@customers/SET_SAVING_ON_SUB_RESOURCE_CUSTOMER',
  CUSTOMERS_RESET_SUB_RESOURCE_DATA:
    '@customers/RESET_SUB_RESOURCE_DATA_CUSTOMER',

  CUSTOMERS_ADD_OPEN_MODAL: '@customers/OPEN_MODAL_ADD_CUSTOMER',
  CUSTOMERS_ADD_CLOSE_MODAL: '@customers/CLOSE_MODAL_ADD_CUSTOMER',

  CUSTOMERS_FILTER_OPEN_MODAL: '@customers/OPEN_MODAL_FILTER_CUSTOMER',
  CUSTOMERS_FILTER_CLOSE_MODAL: '@customers/CLOSE_MODAL_FILTER_CUSTOMER',

  CUSTOMERS_OBSERVATION_OPEN_MODAL:
    '@customers/OPEN_MODAL_CUSTOMER_OBSERVATION',
  CUSTOMERS_OBSERVATION_CLOSE_MODAL:
    '@customers/CLOSE_MODAL_CUSTOMER_OBSERVATION',

  CUSTOMERS_SET_TABLE_FILTERS: '@customers/SET_TABLE_FILTERS_CUSTOMER',
  CUSTOMERS_SET_REQUEST_ERROR: '@customers/SET_REQUEST_ERROR_CUSTOMER',

  CUSTOMERS_CLEAR_NEW_BRANCH_ACTIVITY:
    '@customers/CLEAR_NEW_CUSTOMER_BRANCH_ACTIVITY',
  CUSTOMERS_CLEAR_NEW_COVENANT: '@customers/CLEAR_NEW_CUSTOMER_COVENANT',

  CUSTOMERS_OPEN_FILE_DIALOG: '@customers/OPEN_CUSTOMER_FILE_DIALOG',
};

export default Types;
