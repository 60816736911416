const Types = {
  REGION_CUSTOMERS_GET: '@region/GET_REGION_CUSTOMERS',

  REGION_CUSTOMERS_CREATE_REQUEST: '@region/CREATE_REGION_CUSTOMER_REQUEST',
  REGION_CUSTOMERS_CREATE_SUCCESS: '@region/CREATE_REGION_CUSTOMER_SUCCESS',
  REGION_CUSTOMERS_CREATE_FAILURE: '@region/CREATE_REGION_CUSTOMER_FAILURE',

  REGION_CUSTOMERS_UPDATE_REQUEST: '@region/UPDATE_REGION_CUSTOMER_REQUEST',
  REGION_CUSTOMERS_UPDATE_SUCCESS: '@region/UPDATE_REGION_CUSTOMER_SUCCESS',
  REGION_CUSTOMERS_UPDATE_FAILURE: '@region/UPDATE_REGION_CUSTOMER_FAILURE',

  REGION_CUSTOMERS_DELETE_REQUEST: '@region/DELETE_REGION_CUSTOMER_REQUEST',
  REGION_CUSTOMERS_DELETE_SUCCESS: '@region/DELETE_REGION_CUSTOMER_SUCCESS',
  REGION_CUSTOMERS_DELETE_FAILURE: '@region/DELETE_REGION_CUSTOMER_FAILURE',

  REGION_CUSTOMERS_RESET_LIST: '@region/RESET_LIST_REGION_CUSTOMER',

  REGION_CUSTOMERS_OPEN_MODAL: '@region/OPEN_MODAL_REGION_CUSTOMER',
  REGION_CUSTOMERS_CLOSE_MODAL: '@region/CLOSE_MODAL_REGION_CUSTOMER',
};

export default Types;
