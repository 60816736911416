const Types = {
  RURAL_PROPERTY_ADDRESSES_GET: '@ruralProperty/GET_RURAL_PROPERTY_ADDRESSES',

  RURAL_PROPERTY_ADDRESSES_CREATE_REQUEST:
    '@ruralProperty/CREATE_RURAL_PROPERTY_ADDRESS_REQUEST',
  RURAL_PROPERTY_ADDRESSES_CREATE_SUCCESS:
    '@ruralProperty/CREATE_RURAL_PROPERTY_ADDRESS_SUCCESS',
  RURAL_PROPERTY_ADDRESSES_CREATE_FAILURE:
    '@ruralProperty/CREATE_RURAL_PROPERTY_ADDRESS_FAILURE',

  RURAL_PROPERTY_ADDRESSES_UPDATE_REQUEST:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_ADDRESS_REQUEST',
  RURAL_PROPERTY_ADDRESSES_UPDATE_SUCCESS:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_ADDRESS_SUCCESS',
  RURAL_PROPERTY_ADDRESSES_UPDATE_FAILURE:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_ADDRESS_FAILURE',

  RURAL_PROPERTY_ADDRESSES_DELETE_REQUEST:
    '@ruralProperty/DELETE_RURAL_PROPERTY_ADDRESS_REQUEST',
  RURAL_PROPERTY_ADDRESSES_DELETE_SUCCESS:
    '@ruralProperty/DELETE_RURAL_PROPERTY_ADDRESS_SUCCESS',
  RURAL_PROPERTY_ADDRESSES_DELETE_FAILURE:
    '@ruralProperty/DELETE_RURAL_PROPERTY_ADDRESS_FAILURE',

  RURAL_PROPERTY_ADDRESSES_RESET_LIST:
    '@ruralProperty/RESET_LIST_RURAL_PROPERTY_ADDRESS',

  RURAL_PROPERTY_ADDRESSES_OPEN_MODAL:
    '@ruralProperty/OPEN_MODAL_RURAL_PROPERTY_ADDRESS',
  RURAL_PROPERTY_ADDRESSES_CLOSE_MODAL:
    '@ruralProperty/CLOSE_MODAL_RURAL_PROPERTY_ADDRESS',
};

export default Types;
