import Types from './types';

export function getBranchesActivitiesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.BRANCHES_ACTIVITIES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getBranchesActivitiesSuccess({
  data,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.BRANCHES_ACTIVITIES_GET_SUCCESS,
    payload: { data, filterColumnsOrder, filterSearch },
  };
}

export function getBranchesActivitiesFailure() {
  return {
    type: Types.BRANCHES_ACTIVITIES_GET_FAILURE,
  };
}

export function createBranchActivityRequest(branchActivity, source) {
  return {
    type: Types.BRANCHES_ACTIVITIES_CREATE_REQUEST,
    payload: { branchActivity, source },
  };
}

export function createBranchActivitySuccess(branchActivity, source) {
  return {
    type: Types.BRANCHES_ACTIVITIES_CREATE_SUCCESS,
    payload: { branchActivity, source },
  };
}

export function createBranchActivityFailure() {
  return {
    type: Types.BRANCHES_ACTIVITIES_CREATE_FAILURE,
  };
}

export function updateBranchActivityRequest(id, newBranchActivity) {
  return {
    type: Types.BRANCHES_ACTIVITIES_UPDATE_REQUEST,
    payload: { id, newBranchActivity },
  };
}

export function updateBranchActivitySuccess(newBranchActivity) {
  return {
    type: Types.BRANCHES_ACTIVITIES_UPDATE_SUCCESS,
    payload: { newBranchActivity },
  };
}

export function updateBranchActivityFailure() {
  return {
    type: Types.BRANCHES_ACTIVITIES_UPDATE_FAILURE,
  };
}

export function deleteBranchActivityRequest(id) {
  return {
    type: Types.BRANCHES_ACTIVITIES_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteBranchActivitySuccess(id) {
  return {
    type: Types.BRANCHES_ACTIVITIES_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteBranchActivityFailure() {
  return {
    type: Types.BRANCHES_ACTIVITIES_DELETE_FAILURE,
  };
}

export function resetListBranchesActivities() {
  return {
    type: Types.BRANCHES_ACTIVITIES_RESET_LIST,
  };
}

export function openModalBranchActivity(id) {
  return {
    type: Types.BRANCHES_ACTIVITIES_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalBranchActivity() {
  return {
    type: Types.BRANCHES_ACTIVITIES_CLOSE_MODAL,
  };
}
