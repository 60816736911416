import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getBillToPayChartAccounts,
  openModalBillToPayChartAccount,
} from '../billToPayChartAccounts/actions';
import {
  getBillToPayCostCenters,
  openModalBillToPayCostCenter,
} from '../billToPayCostCenters/actions';
import {
  createBillToPaySuccess,
  createBillToPayFailure,
  updateBillToPaySuccess,
  updateBillToPayFailure,
  settlementBillToPaySuccess,
  settlementBillToPayFailure,
  deleteBillToPaySuccess,
  deleteBillToPayFailure,
  getDetailsBillToPaySuccess,
  getDetailsBillToPayFailure,
  setSavingOnSubResource,
  resetSubResourceData,
  setBillToPayTableFilters,
} from './actions';
import Types from './types';

export function* createBillToPay({ payload }) {
  try {
    const { billToPay, subResourceType } = payload;

    const response = yield call(api.post, 'bills', billToPay);

    yield put(createBillToPaySuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/bill-to-pay/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createBillToPayFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToPay({ payload }) {
  try {
    const { id, newBillToPay } = payload;

    const response = yield call(api.put, `bills/${id}`, newBillToPay);

    yield put(updateBillToPaySuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBillToPayFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* settlementBillToPay({ payload }) {
  try {
    const { id, newBillToPay, fetchList } = payload;

    const response = yield call(api.put, `bills/${id}`, newBillToPay);

    yield put(settlementBillToPaySuccess(response.data));

    const { listLength } = yield select(state => state.billsToPay);

    if (fetchList) {
      yield put(
        setBillToPayTableFilters({
          shouldFetchStatisticData: true,
        })
      );

      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(settlementBillToPayFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export function* deleteBillToPay({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `bills/${id}`);

    yield put(deleteBillToPaySuccess(isFromDetail));

    history.push('/bills-to-pay');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.billsToPay);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(deleteBillToPayFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsBillToPay({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `bills/${id}`);

    const { chart_accounts, cost_centers, ...rest } = response.data;

    const {
      billToPaySettlementModalOpen,
      subResourceType,
      shouldOpenSubResourceModal,
    } = yield select(state => state.billsToPay);

    if (!billToPaySettlementModalOpen) {
      yield put(getBillToPayChartAccounts(chart_accounts));

      yield put(getBillToPayCostCenters(cost_centers));
    }

    yield put(getDetailsBillToPaySuccess(rest));

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'CHART_ACCOUNT') {
        yield put(openModalBillToPayChartAccount());
      } else if (subResourceType === 'COST_CENTER') {
        yield put(openModalBillToPayCostCenter());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsBillToPayFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(Types.BILLS_TO_PAY_CREATE_REQUEST, createBillToPay),
  takeLatest(Types.BILLS_TO_PAY_UPDATE_REQUEST, updateBillToPay),
  takeLatest(Types.BILLS_TO_PAY_SETTLEMENT_REQUEST, settlementBillToPay),
  takeLatest(Types.BILLS_TO_PAY_DELETE_REQUEST, deleteBillToPay),
  takeLatest(Types.BILLS_TO_PAY_GET_DETAILS_REQUEST, getDetailsBillToPay),
]);
