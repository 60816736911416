import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  updateTeamMemberSuccess,
  updateTeamMemberFailure,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
} from './actions';
import Types from './types';

export function* updateTeamMember({ payload }) {
  try {
    const { team_id, members } = payload;

    const response = yield call(
      api.put,
      `groups-persons/${team_id}/person-groups-persons`,
      members
    );

    yield put(updateTeamMemberSuccess(response.data));

    toast.success(<IntlMessages id="messages.change-success" />);
  } catch (err) {
    yield put(updateTeamMemberFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.change-failure" />);
  }
}

export function* deleteTeamMember({ payload }) {
  try {
    const { team_id, team_member_id } = payload;
    yield call(
      api.delete,
      `groups-persons/${team_id}/person-groups-persons/${team_member_id}`
    );

    yield put(deleteTeamMemberSuccess(team_member_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteTeamMemberFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.TEAM_MEMBERS_UPDATE_REQUEST, updateTeamMember),
  takeLatest(Types.TEAM_MEMBERS_DELETE_REQUEST, deleteTeamMember),
]);
