import Types from './types';

export function getUnitsMeasureRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.UNITS_MEASURE_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getUnitsMeasureSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.UNITS_MEASURE_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getUnitsMeasureFailure() {
  return {
    type: Types.UNITS_MEASURE_GET_FAILURE,
  };
}

export function createUnitMeasureRequest(unitMeasure, source) {
  return {
    type: Types.UNITS_MEASURE_CREATE_REQUEST,
    payload: { unitMeasure, source },
  };
}

export function createUnitMeasureSuccess(unitMeasure, source) {
  return {
    type: Types.UNITS_MEASURE_CREATE_SUCCESS,
    payload: { unitMeasure, source },
  };
}

export function createUnitMeasureFailure() {
  return {
    type: Types.UNITS_MEASURE_CREATE_FAILURE,
  };
}

export function updateUnitMeasureRequest(id, newUnitMeasure) {
  return {
    type: Types.UNITS_MEASURE_UPDATE_REQUEST,
    payload: { id, newUnitMeasure },
  };
}

export function updateUnitMeasureSuccess(newUnitMeasure) {
  return {
    type: Types.UNITS_MEASURE_UPDATE_SUCCESS,
    payload: { newUnitMeasure },
  };
}

export function updateUnitMeasureFailure() {
  return {
    type: Types.UNITS_MEASURE_UPDATE_FAILURE,
  };
}

export function deleteUnitMeasureRequest(id) {
  return {
    type: Types.UNITS_MEASURE_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteUnitMeasureSuccess(id) {
  return {
    type: Types.UNITS_MEASURE_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteUnitMeasureFailure() {
  return {
    type: Types.UNITS_MEASURE_DELETE_FAILURE,
  };
}

export function resetListUnitsMeasure() {
  return {
    type: Types.UNITS_MEASURE_RESET_LIST,
  };
}

export function openModalUnitMeasureFilter() {
  return {
    type: Types.UNITS_MEASURE_FILTER_OPEN_MODAL,
  };
}

export function closeModalUnitMeasureFilter() {
  return {
    type: Types.UNITS_MEASURE_FILTER_CLOSE_MODAL,
  };
}

export function openModalUnitMeasure(id) {
  return {
    type: Types.UNITS_MEASURE_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalUnitMeasure() {
  return {
    type: Types.UNITS_MEASURE_CLOSE_MODAL,
  };
}
