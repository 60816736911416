import Types from './types';

export function getProductPriceListItems(items) {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_GET,
    payload: { items },
  };
}

export function updateProductPriceListItemRequest(
  price_list_id,
  item_id,
  newItem
) {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_REQUEST,
    payload: { price_list_id, item_id, newItem },
  };
}

export function updateProductPriceListItemSuccess(newItem) {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_SUCCESS,
    payload: { newItem },
  };
}

export function updateProductPriceListItemFailure() {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_UPDATE_FAILURE,
  };
}

export function resetProductPriceListItem() {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_RESET_LIST,
  };
}

export function openModalProductPriceListItem(item_id) {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_OPEN_MODAL,
    payload: { item_id },
  };
}

export function closeModalProductPriceListItem() {
  return {
    type: Types.PRODUCT_PRICE_LIST_ITEMS_CLOSE_MODAL,
  };
}
