import Types from './types';

export function getOrderBills(bills) {
  return {
    type: Types.ORDER_BILLS_GET,
    payload: { bills },
  };
}

export function createOrderBillRequest(order_id, bill, showMessage = true) {
  return {
    type: Types.ORDER_BILLS_CREATE_REQUEST,
    payload: { order_id, bill, showMessage },
  };
}

export function createOrderBillSuccess(bills) {
  return {
    type: Types.ORDER_BILLS_CREATE_SUCCESS,
    payload: { bills },
  };
}

export function createOrderBillFailure() {
  return {
    type: Types.ORDER_BILLS_CREATE_FAILURE,
  };
}

export function updateBillOrderBillRequest(bill_id, newBill) {
  return {
    type: Types.ORDER_BILLS_UPDATE_BILL_REQUEST,
    payload: { bill_id, newBill },
  };
}

export function updateBillOrderBillSuccess(newBill) {
  return {
    type: Types.ORDER_BILLS_UPDATE_BILL_SUCCESS,
    payload: { newBill },
  };
}

export function updateBillOrderBillFailure() {
  return {
    type: Types.ORDER_BILLS_UPDATE_BILL_FAILURE,
  };
}

export function deleteOrderBillRequest(bill_id) {
  return {
    type: Types.ORDER_BILLS_DELETE_REQUEST,
    payload: { bill_id },
  };
}

export function deleteOrderBillSuccess(bill_id) {
  return {
    type: Types.ORDER_BILLS_DELETE_SUCCESS,
    payload: { bill_id },
  };
}

export function deleteOrderBillFailure() {
  return {
    type: Types.ORDER_BILLS_DELETE_FAILURE,
  };
}

export function deleteAllOrderBillRequest(order_id) {
  return {
    type: Types.ORDER_BILLS_DELETE_ALL_REQUEST,
    payload: { order_id },
  };
}

export function deleteAllOrderBillSuccess() {
  return {
    type: Types.ORDER_BILLS_DELETE_ALL_SUCCESS,
  };
}

export function deleteAllOrderBillFailure() {
  return {
    type: Types.ORDER_BILLS_DELETE_ALL_FAILURE,
  };
}

export function setSelectedBillOrderBill(bill_id) {
  return {
    type: Types.ORDER_BILLS_SET_SELECTED_BILL,
    payload: { bill_id },
  };
}

export function resetListOrderBill() {
  return {
    type: Types.ORDER_BILLS_RESET_LIST,
  };
}

export function openModalOrderBill() {
  return {
    type: Types.ORDER_BILLS_OPEN_MODAL,
  };
}

export function closeModalOrderBill() {
  return {
    type: Types.ORDER_BILLS_CLOSE_MODAL,
  };
}

export function openModalEditOrderBill(bill_id) {
  return {
    type: Types.ORDER_BILLS_EDIT_OPEN_MODAL,
    payload: { bill_id },
  };
}

export function closeModalEditOrderBill() {
  return {
    type: Types.ORDER_BILLS_EDIT_CLOSE_MODAL,
  };
}

export function setListOrderBill(bills) {
  return {
    type: Types.ORDER_BILLS_SET_LIST,
    payload: { bills },
  };
}
