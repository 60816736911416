import { combineReducers } from 'redux';

import accounts from './accounts/reducer';
import allBackupDatabases from './allBackupDatabases/reducer';
import auth from './auth/reducer';
import bankAccountPixFiles from './bankAccountPixFiles/reducer';
import bankAccountPixs from './bankAccountPixs/reducer';
import bankBillingPixs from './bankBillingPixs/reducer';
import bankBillings from './bankBillings/reducer';
import banks from './banks/reducer';
import billsToPay from './billsToPay/reducer';
import billsToReceive from './billsToReceive/reducer';
import billToPayChartAccounts from './billToPayChartAccounts/reducer';
import billToPayCostCenters from './billToPayCostCenters/reducer';
import billToReceiveChartAccounts from './billToReceiveChartAccounts/reducer';
import billToReceivePixs from './billToReceivePixs/reducer';
import branchesActivities from './branchesActivities/reducer';
import brands from './brands/reducer';
import categories from './categories/reducer';
import chartAccounts from './chartAccounts/reducer';
import cities from './cities/reducer';
import companies from './companies/reducer';
import companyAddresses from './companyAddresses/reducer';
import companyEmails from './companyEmails/reducer';
import companyFiles from './companyFiles/reducer';
import companyNcms from './companyNcms/reducer';
import companyPhones from './companyPhones/reducer';
import companyProfile from './companyProfile/reducer';
import companySubsidiaries from './companySubsidiaries/reducer';
import companyUsers from './companyUsers/reducer';
import contactAddresses from './contactAddresses/reducer';
import contactEmails from './contactEmails/reducer';
import contactFiles from './contactFiles/reducer';
import contactPhones from './contactPhones/reducer';
import contacts from './contacts/reducer';
import costCenters from './costCenters/reducer';
import countries from './countries/reducer';
import covenants from './covenants/reducer';
import cultures from './cultures/reducer';
import customerAddresses from './customerAddresses/reducer';
import customerEmails from './customerEmails/reducer';
import customerFiles from './customerFiles/reducer';
import customerPhones from './customerPhones/reducer';
import customers from './customers/reducer';
import dashboard from './dashboard/reducer';
import departments from './departments/reducer';
import devices from './devices/reducer';
import dispatchingAgencies from './dispatchingAgencies/reducer';
import employeeAddresses from './employeeAddresses/reducer';
import employeeEmails from './employeeEmails/reducer';
import employeeFiles from './employeeFiles/reducer';
import employeePhones from './employeePhones/reducer';
import employees from './employees/reducer';
import harvests from './harvests/reducer';
import inventories from './inventories/reducer';
import inventoryItems from './inventoryItems/reducer';
import ncms from './ncms/reducer';
import orderBillPixs from './orderBillPixs/reducer';
import orderBills from './orderBills/reducer';
import orderItems from './orderItems/reducer';
import orderPriceListItems from './orderPriceListItems/reducer';
import orders from './orders/reducer';
import ownerAddresses from './ownerAddresses/reducer';
import ownerEmails from './ownerEmails/reducer';
import ownerFiles from './ownerFiles/reducer';
import ownerPhones from './ownerPhones/reducer';
import owners from './owners/reducer';
import paymentMethodInstallments from './paymentMethodInstallments/reducer';
import paymentMethods from './paymentMethods/reducer';
import paymentMethodsTypes from './paymentMethodsTypes/reducer';
import persons from './persons/reducer';
import priceListItems from './priceListItems/reducer';
import priceLists from './priceLists/reducer';
import productDiscounts from './productDiscounts/reducer';
import productFiles from './productFiles/reducer';
import productPriceListItems from './productPriceListItems/reducer';
import products from './products/reducer';
import promoterAddresses from './promoterAddresses/reducer';
import promoterEmails from './promoterEmails/reducer';
import promoterFiles from './promoterFiles/reducer';
import promoterPhones from './promoterPhones/reducer';
import promoters from './promoters/reducer';
import regionCities from './regionCities/reducer';
import regionCustomers from './regionCustomers/reducer';
import regions from './regions/reducer';
import regionSellers from './regionSellers/reducer';
import reports from './reports/reducer';
import roadmaps from './roadmaps/reducer';
import ruralProperties from './ruralProperties/reducer';
import ruralPropertyAddresses from './ruralPropertyAddresses/reducer';
import ruralPropertyEmails from './ruralPropertyEmails/reducer';
import ruralPropertyFiles from './ruralPropertyFiles/reducer';
import ruralPropertyOwners from './ruralPropertyOwners/reducer';
import ruralPropertyPhones from './ruralPropertyPhones/reducer';
import ruralPropertyRegistrations from './ruralPropertyRegistrations/reducer';
import schedules from './schedules/reducer';
import sellerAddresses from './sellerAddresses/reducer';
import sellerCompanies from './sellerCompanies/reducer';
import sellerEmails from './sellerEmails/reducer';
import sellerFiles from './sellerFiles/reducer';
import sellerPhones from './sellerPhones/reducer';
import sellers from './sellers/reducer';

import sellerUsers from './sellerUsers/reducer';
import settings from './settings/reducer';
import states from './states/reducer';
import supervisorAddresses from './supervisorAddresses/reducer';
import supervisorEmails from './supervisorEmails/reducer';
import supervisorFiles from './supervisorFiles/reducer';
import supervisorPhones from './supervisorPhones/reducer';
import supervisors from './supervisors/reducer';
import supplierAddresses from './supplierAddresses/reducer';
import supplierEmails from './supplierEmails/reducer';
import supplierFiles from './supplierFiles/reducer';
import supplierPhones from './supplierPhones/reducer';
import suppliers from './suppliers/reducer';
import taxSituations from './taxSituations/reducer';
import teamMembers from './teamMembers/reducer';
import teams from './teams/reducer';
import theme from './theme/reducer';
import transporterAddresses from './transporterAddresses/reducer';
import transporterEmails from './transporterEmails/reducer';
import transporterFiles from './transporterFiles/reducer';
import transporterPhones from './transporterPhones/reducer';
import transporters from './transporters/reducer';
import unitsMeasure from './unitsMeasure/reducer';
import user from './user/reducer';
import userFiles from './userFiles/reducer';

/* TODO: Excluir o theme e colocar junto com as informacoes do usuario ou grupo de usuario */
export default combineReducers({
  accounts,
  allBackupDatabases,
  auth,
  bankAccountPixFiles,
  bankAccountPixs,
  bankBillingPixs,
  bankBillings,
  banks,
  billsToPay,
  billsToReceive,
  billToPayChartAccounts,
  billToPayCostCenters,
  billToReceiveChartAccounts,
  billToReceivePixs,
  branchesActivities,
  brands,
  categories,
  chartAccounts,
  cities,
  companies,
  companyAddresses,
  companyEmails,
  companyFiles,
  companyNcms,
  companySubsidiaries,
  companyUsers,
  companyPhones,
  companyProfile,
  contactAddresses,
  contactEmails,
  contactFiles,
  contactPhones,
  contacts,
  costCenters,
  countries,
  covenants,
  cultures,
  customerAddresses,
  customerEmails,
  customerFiles,
  customerPhones,
  customers,
  dashboard,
  departments,
  devices,
  dispatchingAgencies,
  employeeAddresses,
  employeeEmails,
  employeeFiles,
  employeePhones,
  employees,
  harvests,
  inventories,
  inventoryItems,
  ncms,
  orderBillPixs,
  orderBills,
  orderItems,
  orderPriceListItems,
  orders,
  ownerAddresses,
  ownerEmails,
  ownerFiles,
  ownerPhones,
  owners,
  paymentMethodInstallments,
  paymentMethods,
  paymentMethodsTypes,
  persons,
  priceListItems,
  priceLists,
  productDiscounts,
  productFiles,
  productPriceListItems,
  products,
  promoterAddresses,
  promoterEmails,
  promoterFiles,
  promoterPhones,
  promoters,
  regionCities,
  regionCustomers,
  regions,
  regionSellers,
  reports,
  roadmaps,
  ruralProperties,
  ruralPropertyAddresses,
  ruralPropertyEmails,
  ruralPropertyFiles,
  ruralPropertyOwners,
  ruralPropertyPhones,
  ruralPropertyRegistrations,
  sellerAddresses,
  sellerCompanies,
  sellerEmails,
  sellerFiles,
  sellerPhones,
  sellers,
  sellerUsers,
  settings,
  schedules,
  states,
  supervisorAddresses,
  supervisorEmails,
  supervisorFiles,
  supervisorPhones,
  supervisors,
  supplierAddresses,
  supplierEmails,
  supplierFiles,
  supplierPhones,
  suppliers,
  taxSituations,
  teamMembers,
  teams,
  theme,
  transporterAddresses,
  transporterEmails,
  transporterFiles,
  transporterPhones,
  transporters,
  unitsMeasure,
  user,
  userFiles,
});
