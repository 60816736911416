import styled from 'styled-components';

import { Share as MuiShare } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'table':
      styles = `
        color: ${colors.darkRegular};
        width: 25px;
        height: 25px;
        padding: 3px;
      `;
      break;

    case 'button':
      styles = `
        width: 18px;
        height: 18px;
        margin-right: 2px;
        margin-bottom: 2px;
      `;
      break;

    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Share = styled(MuiShare)`
  ${({ type }) => defineStyles(type)}
`;
