import async from '~/components/Async';

const NewCompany = async(() => import('~/pages/NewCompany'));
const SwitchCompany = async(() => import('~/pages/SwitchCompany'));

export const landingRoutes = {
  children: [
    {
      path: '/switch-company',
      component: SwitchCompany,
    },
    {
      path: '/new-company',
      component: NewCompany,
    },
  ],
};
