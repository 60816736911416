import React from 'react';

import PropTypes from 'prop-types';

import { Map } from './styles';

export default function MapIcon({ type }) {
  return <Map type={type} />;
}

MapIcon.propTypes = {
  type: PropTypes.string,
};

MapIcon.defaultProps = {
  type: '',
};
