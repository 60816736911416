import Types from './types';

export function getBankBillingsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.BANK_BILLINGS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getBankBillingsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.BANK_BILLINGS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getBankBillingsFailure() {
  return {
    type: Types.BANK_BILLINGS_GET_FAILURE,
  };
}

export function deleteBankBillingRequest(id) {
  return {
    type: Types.BANK_BILLINGS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteBankBillingSuccess(id) {
  return {
    type: Types.BANK_BILLINGS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteBankBillingFailure() {
  return {
    type: Types.BANK_BILLINGS_DELETE_FAILURE,
  };
}

export function resetListBankBillings() {
  return {
    type: Types.BANK_BILLINGS_RESET_LIST,
  };
}

export function openModalAddBankBilling() {
  return {
    type: Types.BANK_BILLINGS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddBankBilling() {
  return {
    type: Types.BANK_BILLINGS_ADD_CLOSE_MODAL,
  };
}

export function openModalBankBillingFilter() {
  return {
    type: Types.BANK_BILLINGS_FILTER_OPEN_MODAL,
  };
}

export function closeModalBankBillingFilter() {
  return {
    type: Types.BANK_BILLINGS_FILTER_CLOSE_MODAL,
  };
}

export function setBankBillingTableFilters({ filterSearch }) {
  return {
    type: Types.BANK_BILLINGS_SET_TABLE_FILTERS,
    payload: { filterSearch },
  };
}
