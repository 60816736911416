import Types from './types';

export function getSellerCompanies(companies) {
  return {
    type: Types.SELLER_COMPANIES_GET,
    payload: { companies },
  };
}

export function getSellerCompaniesCompaniesNotBoundRequest(seller_id) {
  return {
    type: Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_REQUEST,
    payload: { seller_id },
  };
}

export function getSellerCompaniesCompaniesNotBoundSuccess(companies) {
  return {
    type: Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_SUCCESS,
    payload: { companies },
  };
}

export function getSellerCompaniesCompaniesNotBoundFailure() {
  return {
    type: Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_FAILURE,
  };
}

export function createSellerCompanyRequest(seller_id, company) {
  return {
    type: Types.SELLER_COMPANIES_CREATE_REQUEST,
    payload: { seller_id, company },
  };
}

export function createSellerCompanySuccess(company) {
  return {
    type: Types.SELLER_COMPANIES_CREATE_SUCCESS,
    payload: { company },
  };
}

export function createSellerCompanyFailure() {
  return {
    type: Types.SELLER_COMPANIES_CREATE_FAILURE,
  };
}

export function deleteSellerCompanyRequest(seller_id, company_id) {
  return {
    type: Types.SELLER_COMPANIES_DELETE_REQUEST,
    payload: { seller_id, company_id },
  };
}

export function deleteSellerCompanySuccess(company_id, current_company_id) {
  return {
    type: Types.SELLER_COMPANIES_DELETE_SUCCESS,
    payload: { company_id, current_company_id },
  };
}

export function deleteSellerCompanyFailure() {
  return {
    type: Types.SELLER_COMPANIES_DELETE_FAILURE,
  };
}

export function resetListSellerCompany() {
  return {
    type: Types.SELLER_COMPANIES_RESET_LIST,
  };
}

export function openModalSellerCompany() {
  return {
    type: Types.SELLER_COMPANIES_OPEN_MODAL,
  };
}

export function closeModalSellerCompany() {
  return {
    type: Types.SELLER_COMPANIES_CLOSE_MODAL,
  };
}
