import produce from 'immer';

import SellerTypes from '../sellers/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  sellerCompanyModalOpen: false,
};

function changeSellerBound(isBound, id, companies) {
  return companies.map(company =>
    id === company.id ? { ...company, isBound } : company
  );
}

function setIsBoundProperty(isBound, companies) {
  return companies.map(company => ({
    ...company,
    isBound,
  }));
}

export default function sellerCompanies(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SELLER_COMPANIES_GET: {
        const isBound = setIsBoundProperty(
          true,
          action.payload.companies ?? []
        );

        draft.list = isBound;
        break;
      }

      case Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_SUCCESS: {
        const isBound = setIsBoundProperty(false, action.payload.companies);

        const filteredNotBound = isBound.filter(company => {
          const checkExistedCompany = state.list.every(
            boundedCompany => company.id !== boundedCompany.id
          );

          return checkExistedCompany;
        });

        draft.list.push(...filteredNotBound);
        draft.loading = false;

        break;
      }

      case Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SELLER_COMPANIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SELLER_COMPANIES_CREATE_SUCCESS: {
        const { id } = action.payload.company;

        draft.list = changeSellerBound(true, id, state.list);

        draft.loading = false;
        break;
      }
      case Types.SELLER_COMPANIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SELLER_COMPANIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SELLER_COMPANIES_DELETE_SUCCESS: {
        const { company_id, current_company_id } = action.payload;

        const boundedCompanies = [];

        state.list.map(company => {
          if (company.id === company_id) {
            if (company_id === current_company_id) {
              return boundedCompanies.push({ ...company, isBound: false });
            }

            return false;
          }

          return boundedCompanies.push(company);
        });

        draft.list = boundedCompanies;

        draft.loading = false;
        break;
      }
      case Types.SELLER_COMPANIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SELLER_COMPANIES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.SELLER_COMPANIES_OPEN_MODAL: {
        draft.sellerCompanyModalOpen = true;
        break;
      }
      case Types.SELLER_COMPANIES_CLOSE_MODAL: {
        draft.sellerCompanyModalOpen = false;
        break;
      }
      case SellerTypes.SELLERS_RESET_DETAILS: {
        draft.sellerCompanyModalOpen = false;
        break;
      }

      default:
    }
  });
}
