const Types = {
  ORDERS_CREATE_REQUEST: '@orders/CREATE_ORDER_REQUEST',
  ORDERS_CREATE_SUCCESS: '@orders/CREATE_ORDER_SUCCESS',
  ORDERS_CREATE_FAILURE: '@orders/CREATE_ORDER_FAILURE',

  ORDERS_UPDATE_REQUEST: '@orders/UPDATE_ORDER_REQUEST',
  ORDERS_UPDATE_SUCCESS: '@orders/UPDATE_ORDER_SUCCESS',
  ORDERS_UPDATE_FAILURE: '@orders/UPDATE_ORDER_FAILURE',

  ORDERS_DELETE_REQUEST: '@orders/DELETE_ORDER_REQUEST',
  ORDERS_DELETE_SUCCESS: '@orders/DELETE_ORDER_SUCCESS',
  ORDERS_DELETE_FAILURE: '@orders/DELETE_ORDER_FAILURE',

  ORDERS_GET_DETAILS_REQUEST: '@orders/GET_DETAILS_ORDER_REQUEST',
  ORDERS_GET_DETAILS_SUCCESS: '@orders/GET_DETAILS_ORDER_SUCCESS',
  ORDERS_GET_DETAILS_FAILURE: '@orders/GET_DETAILS_ORDER_FAILURE',

  ORDERS_RESET_LIST: '@orders/RESET_LIST_ORDERS',
  ORDERS_RESET_DETAILS: '@orders/RESET_DETAILS_ORDER',

  ORDERS_OBSERVATION_OPEN_MODAL: '@orders/OPEN_MODAL_ORDER_OBSERVATION',
  ORDERS_OBSERVATION_CLOSE_MODAL: '@orders/CLOSE_MODAL_ORDER_OBSERVATION',

  ORDERS_NOTE_OPEN_MODAL: '@orders/OPEN_MODAL_ORDER_NOTE',
  ORDERS_NOTE_CLOSE_MODAL: '@orders/CLOSE_MODAL_ORDER_NOTE',

  ORDERS_SHIPPING_OPEN_MODAL: '@orders/OPEN_MODAL_ORDER_SHIPPING',
  ORDERS_SHIPPING_CLOSE_MODAL: '@orders/CLOSE_MODAL_ORDER_SHIPPING',

  ORDERS_MAP_OPEN_MODAL: '@orders/OPEN_MODAL_ORDER_MAP',
  ORDERS_MAP_CLOSE_MODAL: '@orders/CLOSE_MODAL_ORDER_MAP',

  ORDERS_FILTER_OPEN_MODAL: '@orders/OPEN_MODAL_FILTER_ORDER',
  ORDERS_FILTER_CLOSE_MODAL: '@orders/CLOSE_MODAL_FILTER_ORDER',
  ORDERS_SET_TABLE_FILTERS: '@orders/SET_TABLE_FILTERS_ORDER',
  ORDERS_SET_REQUEST_ERROR: '@orders/SET_REQUEST_ERROR_ORDER',

  ORDERS_SET_SAVING_ON_SUB_RESOURCE: '@orders/SET_SAVING_ON_SUB_RESOURCE_ORDER',
  ORDERS_RESET_SUB_RESOURCE_DATA: '@orders/RESET_SUB_RESOURCE_DATA_ORDER',
};

export default Types;
