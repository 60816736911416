import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  filters: {
    productChart: null,
    ordersSalesChart: null,
  },
  dashboardFilterModalOpen: false,
  dashboardFilterModalType: '',
  dataProductChart: null,
  dataOrdersSalesChart: null,
  loadingProductChart: false,
  loadingOrdersSalesChart: false,
};

export default function dashboard(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.DASHBOARD_FILTER_OPEN_MODAL: {
        const { modalType } = action.payload;
        draft.dashboardFilterModalType = modalType;
        draft.dashboardFilterModalOpen = true;
        break;
      }
      case Types.DASHBOARD_FILTER_CLOSE_MODAL: {
        draft.dashboardFilterModalType = '';
        draft.dashboardFilterModalOpen = false;
        break;
      }
      case Types.DASHBOARD_GET_PRODUCTS_CHART_REQUEST: {
        draft.loadingProductChart = true;
        break;
      }
      case Types.DASHBOARD_GET_PRODUCTS_CHART_SUCCESS: {
        const { data, filters } = action.payload;
        draft.dataProductChart = data;
        draft.loadingProductChart = false;
        draft.dashboardFilterModalType = '';
        draft.dashboardFilterModalOpen = false;
        draft.filters.productChart = filters;

        break;
      }
      case Types.DASHBOARD_GET_PRODUCTS_CHART_FAILURE: {
        draft.loadingProductChart = false;
        break;
      }
      case Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_REQUEST: {
        draft.loadingOrdersSalesChart = true;
        break;
      }
      case Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_SUCCESS: {
        const { data, filters } = action.payload;
        draft.dataOrdersSalesChart = data;
        draft.loadingOrdersSalesChart = false;
        draft.dashboardFilterModalType = '';
        draft.dashboardFilterModalOpen = false;
        draft.filters.ordersSalesChart = filters;
        break;
      }
      case Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_FAILURE: {
        draft.loadingOrdersSalesChart = false;
        break;
      }
      case Types.DASHBOARD_RESET_LIST: {
        draft.filters = {
          productChart: null,
          ordersSalesChart: null,
        };

        draft.dashboardFilterModalOpen = false;
        draft.dashboardFilterModalType = '';

        draft.dataOrdersSalesChart = null;
        draft.dataProductChart = null;

        draft.loadingOrdersSalesChart = false;
        draft.loadingProductChart = false;
        break;
      }
      default:
    }
  });
}
