import Types from './types';

export function getCitiesRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.CITIES_GET_REQUEST,
    payload: { queryParams, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getCitiesSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.CITIES_GET_SUCCESS,
    payload: {
      data,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getCitiesFailure() {
  return {
    type: Types.CITIES_GET_FAILURE,
  };
}

export function resetListCities() {
  return {
    type: Types.CITIES_RESET_LIST,
  };
}

export function openModalCityFilter() {
  return {
    type: Types.CITIES_FILTER_OPEN_MODAL,
  };
}

export function closeModalCityFilter() {
  return {
    type: Types.CITIES_FILTER_CLOSE_MODAL,
  };
}
