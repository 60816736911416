import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getBrandsSuccess,
  getBrandsFailure,
  createBrandSuccess,
  createBrandFailure,
  updateBrandSuccess,
  updateBrandFailure,
  deleteBrandSuccess,
  deleteBrandFailure,
} from './actions';
import Types from './types';

export function* getBrands({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `brands${queryParams}`);

    yield put(getBrandsSuccess({ data, filterColumnsOrder, filterSearch }));
  } catch (err) {
    yield put(getBrandsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createBrand({ payload }) {
  try {
    const { brand, source } = payload;

    const response = yield call(api.post, 'brands', brand);

    yield put(createBrandSuccess(response.data, source));

    if (source) {
      toast.success(<IntlMessages id="brands.create-brand-success" />);
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createBrandFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBrand({ payload }) {
  try {
    const { id, newBrand } = payload;

    const response = yield call(api.put, `brands/${id}`, newBrand);

    yield put(updateBrandSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBrandFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBrand({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `brands/${id}`);

    yield put(deleteBrandSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBrandFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.BRANDS_GET_REQUEST, getBrands),
  takeLatest(Types.BRANDS_CREATE_REQUEST, createBrand),
  takeLatest(Types.BRANDS_UPDATE_REQUEST, updateBrand),
  takeLatest(Types.BRANDS_DELETE_REQUEST, deleteBrand),
]);
