const Types = {
  SUPERVISOR_PHONES_GET: '@supervisors/GET_SUPERVISOR_PHONES',

  SUPERVISOR_PHONES_CREATE_REQUEST:
    '@supervisors/CREATE_SUPERVISOR_PHONE_REQUEST',
  SUPERVISOR_PHONES_CREATE_SUCCESS:
    '@supervisors/CREATE_SUPERVISOR_PHONE_SUCCESS',
  SUPERVISOR_PHONES_CREATE_FAILURE:
    '@supervisors/CREATE_SUPERVISOR_PHONE_FAILURE',

  SUPERVISOR_PHONES_UPDATE_REQUEST:
    '@supervisors/UPDATE_SUPERVISOR_PHONE_REQUEST',
  SUPERVISOR_PHONES_UPDATE_SUCCESS:
    '@supervisors/UPDATE_SUPERVISOR_PHONE_SUCCESS',
  SUPERVISOR_PHONES_UPDATE_FAILURE:
    '@supervisors/UPDATE_SUPERVISOR_PHONE_FAILURE',

  SUPERVISOR_PHONES_DELETE_REQUEST:
    '@supervisors/DELETE_SUPERVISOR_PHONE_REQUEST',
  SUPERVISOR_PHONES_DELETE_SUCCESS:
    '@supervisors/DELETE_SUPERVISOR_PHONE_SUCCESS',
  SUPERVISOR_PHONES_DELETE_FAILURE:
    '@supervisors/DELETE_SUPERVISOR_PHONE_FAILURE',

  SUPERVISOR_PHONES_RESET_LIST: '@supervisors/RESET_LIST_SUPERVISOR_PHONE',

  SUPERVISOR_PHONES_OPEN_MODAL: '@supervisors/OPEN_MODAL_SUPERVISOR_PHONE',
  SUPERVISOR_PHONES_CLOSE_MODAL: '@supervisors/CLOSE_MODAL_SUPERVISOR_PHONE',
};

export default Types;
