import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createEmployeeEmailSuccess,
  createEmployeeEmailFailure,
  updateEmployeeEmailSuccess,
  updateEmployeeEmailFailure,
  deleteEmployeeEmailSuccess,
  deleteEmployeeEmailFailure,
} from './actions';
import Types from './types';

export function* createEmployeeEmail({ payload }) {
  try {
    const { employee_id, email } = payload;

    const response = yield call(
      api.post,
      `employees/${employee_id}/emails`,
      email
    );

    yield put(createEmployeeEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createEmployeeEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateEmployeeEmail({ payload }) {
  try {
    const { employee_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `employees/${employee_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateEmployeeEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateEmployeeEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteEmployeeEmail({ payload }) {
  const { employee_id, email_id } = payload;

  const emails = yield select(state => state.employeeEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteEmployeeEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `employees/${employee_id}/emails/${email_id}`);

    yield put(deleteEmployeeEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteEmployeeEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.EMPLOYEE_EMAILS_CREATE_REQUEST, createEmployeeEmail),
  takeLatest(Types.EMPLOYEE_EMAILS_UPDATE_REQUEST, updateEmployeeEmail),
  takeLatest(Types.EMPLOYEE_EMAILS_DELETE_REQUEST, deleteEmployeeEmail),
]);
