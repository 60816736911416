import Types from './types';

export function getRuralPropertyRegistrations(registrations) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_GET,
    payload: { registrations },
  };
}

export function createRuralPropertyRegistrationRequest(
  property_id,
  registration
) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_REQUEST,
    payload: { property_id, registration },
  };
}

export function createRuralPropertyRegistrationSuccess(registration) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_SUCCESS,
    payload: { registration },
  };
}

export function createRuralPropertyRegistrationFailure() {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_CREATE_FAILURE,
  };
}

export function updateRuralPropertyRegistrationRequest(
  property_id,
  registration_id,
  newRegistration
) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_REQUEST,
    payload: { property_id, registration_id, newRegistration },
  };
}

export function updateRuralPropertyRegistrationSuccess(newRegistration) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_SUCCESS,
    payload: { newRegistration },
  };
}

export function updateRuralPropertyRegistrationFailure() {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_UPDATE_FAILURE,
  };
}

export function deleteRuralPropertyRegistrationRequest(
  property_id,
  registration_id
) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_REQUEST,
    payload: { property_id, registration_id },
  };
}

export function deleteRuralPropertyRegistrationSuccess(registration_id) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_SUCCESS,
    payload: { registration_id },
  };
}

export function deleteRuralPropertyRegistrationFailure() {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_DELETE_FAILURE,
  };
}

export function resetListRuralPropertyRegistration() {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_RESET_LIST,
  };
}

export function openModalRuralPropertyRegistration(registration_id) {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_OPEN_MODAL,
    payload: { registration_id },
  };
}

export function closeModalRuralPropertyRegistration() {
  return {
    type: Types.RURAL_PROPERTY_REGISTRATIONS_CLOSE_MODAL,
  };
}
