import Types from './types';

export function openModalDashboardFilter({ modalType }) {
  return {
    type: Types.DASHBOARD_FILTER_OPEN_MODAL,
    payload: { modalType },
  };
}

export function closeModalDashboardFilter() {
  return {
    type: Types.DASHBOARD_FILTER_CLOSE_MODAL,
  };
}

export function getProductsChartRequest({ filters }) {
  return {
    type: Types.DASHBOARD_GET_PRODUCTS_CHART_REQUEST,
    payload: { filters },
  };
}

export function getProductsChartSuccess({ data, filters }) {
  return {
    type: Types.DASHBOARD_GET_PRODUCTS_CHART_SUCCESS,
    payload: { data, filters },
  };
}

export function getProductsChartFailure() {
  return {
    type: Types.DASHBOARD_GET_PRODUCTS_CHART_FAILURE,
  };
}

export function getOrdersSalesChartRequest({ filters }) {
  return {
    type: Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_REQUEST,
    payload: { filters },
  };
}

export function getOrdersSalesChartSuccess({ data, filters }) {
  return {
    type: Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_SUCCESS,
    payload: { data, filters },
  };
}

export function getOrdersSalesChartFailure() {
  return {
    type: Types.DASHBOARD_GET_ORDERS_SALES_CHARTS_FAILURE,
  };
}

export function resetListDashboard() {
  return {
    type: Types.DASHBOARD_RESET_LIST,
  };
}
