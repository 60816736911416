import produce from 'immer';

import OrderTypes from '../orders/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  orderItemModalOpen: false,
  orderItemPriceListModalOpen: false,
};

export default function orderItems(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ORDER_ITEMS_GET: {
        draft.list = action.payload.items;
        break;
      }

      case Types.ORDER_ITEMS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_ITEMS_CREATE_SUCCESS: {
        draft.list.push(action.payload.item);
        draft.loading = false;

        if (!action.payload.saveAndNew) {
          draft.orderItemModalOpen = false;
        }
        break;
      }
      case Types.ORDER_ITEMS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_ITEMS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_ITEMS_UPDATE_SUCCESS: {
        const { newItem } = action.payload;

        draft.list = state.list.map(item => {
          return item.id === newItem.id ? newItem : item;
        });

        draft.detail = {};
        draft.loading = false;
        draft.orderItemModalOpen = false;

        break;
      }
      case Types.ORDER_ITEMS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_ITEMS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_ITEMS_DELETE_SUCCESS: {
        const { item_id } = action.payload;

        draft.list = state.list.filter(item => {
          return item.id !== item_id;
        });

        if (draft.detail.id && item_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.ORDER_ITEMS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_ITEMS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.ORDER_ITEMS_OPEN_MODAL: {
        const { item_id } = action.payload;

        draft.detail = {};

        if (item_id) {
          [draft.detail] = draft.list.filter(element => element.id === item_id);
        }

        draft.orderItemModalOpen = true;
        break;
      }
      case Types.ORDER_ITEMS_CLOSE_MODAL: {
        draft.orderItemModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.ORDER_ITEMS_PRICE_LIST_OPEN_MODAL: {
        draft.orderItemPriceListModalOpen = true;
        break;
      }
      case Types.ORDER_ITEMS_PRICE_LIST_CLOSE_MODAL: {
        draft.orderItemPriceListModalOpen = false;
        break;
      }

      case OrderTypes.ORDERS_RESET_DETAILS: {
        draft.detail = {};
        draft.orderItemModalOpen = false;
        draft.orderItemPriceListModalOpen = false;
        break;
      }

      default:
    }
  });
}
