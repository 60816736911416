import React from 'react';

import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import { Root } from './styles';

export default function Loader({ size, color }) {
  return (
    <Root>
      <CircularProgress size={size} color={color} />
    </Root>
  );
}

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: 20,
  color: 'secondary',
};
