import produce from 'immer';

import PersonTypes from '../persons/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  supplierAddModalOpen: false,
  supplierObservationModalOpen: false,
  supplierFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  openFileDialog: false,
};

export default function suppliers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SUPPLIERS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.SUPPLIERS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.supplierFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.SUPPLIERS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPPLIERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPPLIERS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.supplier);
        draft.loading = false;
        break;
      }
      case Types.SUPPLIERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPPLIERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPPLIERS_UPDATE_SUCCESS: {
        const { newSupplier } = action.payload;
        draft.detail = newSupplier;

        draft.list = state.list.map(supplier => {
          return supplier.id === newSupplier.id ? newSupplier : supplier;
        });

        draft.supplierObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.SUPPLIERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPPLIERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPPLIERS_DELETE_SUCCESS: {
        draft.list = state.list.filter(supplier => {
          return supplier.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.SUPPLIERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPPLIERS_BECOME_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUPPLIERS_BECOME_SUCCESS: {
        draft.loading = false;
        draft.supplierAddModalOpen = false;
        break;
      }
      case Types.SUPPLIERS_BECOME_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SUPPLIERS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.SUPPLIERS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.supplier;
        draft.requestError = false;
        break;
      }
      case Types.SUPPLIERS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.SUPPLIERS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.supplierFilterModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }

      case Types.SUPPLIERS_RESET_DETAILS: {
        draft.detail = {};
        draft.supplierAddModalOpen = false;
        draft.supplierObservationModalOpen = false;
        break;
      }

      case Types.SUPPLIERS_ADD_OPEN_MODAL: {
        draft.supplierAddModalOpen = true;
        break;
      }
      case Types.SUPPLIERS_ADD_CLOSE_MODAL: {
        draft.supplierAddModalOpen = false;
        break;
      }

      case Types.SUPPLIERS_OBSERVATION_OPEN_MODAL: {
        draft.supplierObservationModalOpen = true;
        break;
      }
      case Types.SUPPLIERS_OBSERVATION_CLOSE_MODAL: {
        draft.supplierObservationModalOpen = false;
        break;
      }

      case Types.SUPPLIERS_FILTER_OPEN_MODAL: {
        draft.supplierFilterModalOpen = true;
        break;
      }
      case Types.SUPPLIERS_FILTER_CLOSE_MODAL: {
        draft.supplierFilterModalOpen = false;
        break;
      }

      case Types.SUPPLIERS_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.SUPPLIERS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        draft.openFileDialog = false;
        break;
      }

      case Types.SUPPLIERS_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case PersonTypes.PERSONS_RESET_DETAILS: {
        draft.supplierAddModalOpen = false;
        break;
      }

      default:
    }
  });
}
