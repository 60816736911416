const Types = {
  BANK_ACCOUNT_PIXS_GET: '@bankAccounts/GET_BANK_ACCOUNT_PIXS',

  BANK_ACCOUNT_PIXS_CREATE_REQUEST:
    '@bankAccounts/CREATE_BANK_ACCOUNT_PIX_REQUEST',
  BANK_ACCOUNT_PIXS_CREATE_SUCCESS:
    '@bankAccounts/CREATE_BANK_ACCOUNT_PIX_SUCCESS',
  BANK_ACCOUNT_PIXS_CREATE_FAILURE:
    '@bankAccounts/CREATE_BANK_ACCOUNT_PIX_FAILURE',

  BANK_ACCOUNT_PIXS_UPDATE_REQUEST:
    '@bankAccounts/UPDATE_BANK_ACCOUNT_PIX_REQUEST',
  BANK_ACCOUNT_PIXS_UPDATE_SUCCESS:
    '@bankAccounts/UPDATE_BANK_ACCOUNT_PIX_SUCCESS',
  BANK_ACCOUNT_PIXS_UPDATE_FAILURE:
    '@bankAccounts/UPDATE_BANK_ACCOUNT_PIX_FAILURE',

  BANK_ACCOUNT_PIXS_DELETE_REQUEST:
    '@bankAccounts/DELETE_BANK_ACCOUNT_PIX_REQUEST',
  BANK_ACCOUNT_PIXS_DELETE_SUCCESS:
    '@bankAccounts/DELETE_BANK_ACCOUNT_PIX_SUCCESS',
  BANK_ACCOUNT_PIXS_DELETE_FAILURE:
    '@bankAccounts/DELETE_BANK_ACCOUNT_PIX_FAILURE',

  BANK_ACCOUNT_PIXS_GET_DETAILS_REQUEST:
    '@bankAccounts/GET_DETAILS_BANK_ACCOUNT_PIX_REQUEST',
  BANK_ACCOUNT_PIXS_GET_DETAILS_SUCCESS:
    '@bankAccounts/GET_DETAILS_BANK_ACCOUNT_PIX_SUCCESS',
  BANK_ACCOUNT_PIXS_GET_DETAILS_FAILURE:
    '@bankAccounts/GET_DETAILS_BANK_ACCOUNT_PIX_FAILURE',

  BANK_ACCOUNT_PIXS_RESET_LIST: '@bankAccounts/RESET_LIST_BANK_ACCOUNT_PIX',
  BANK_ACCOUNT_PIXS_RESET_DETAILS:
    '@bankAccounts/RESET_DETAILS_BANK_ACCOUNT_PIX',

  BANK_ACCOUNT_PIXS_OPEN_MODAL: '@bankAccounts/OPEN_MODAL_BANK_ACCOUNT_PIX',
  BANK_ACCOUNT_PIXS_CLOSE_MODAL: '@bankAccounts/CLOSE_MODAL_BANK_ACCOUNT_PIX',

  BANK_ACCOUNT_PIXS_COMPANY_PIX_OPEN_MODAL:
    '@bankAccounts/OPEN_MODAL_COMPANY_PIX_BANK_ACCOUNT_PIXS',
  BANK_ACCOUNT_PIXS_COMPANY_PIX_CLOSE_MODAL:
    '@bankAccounts/CLOSE_MODAL_COMPANY_PIX_BANK_ACCOUNT_PIXS',

  BANK_ACCOUNT_PIXS_OPEN_FILE_DIALOG:
    '@bankAccounts/OPEN_BANK_ACCOUNT_PIX_FILE_DIALOG',
  BANK_ACCOUNT_PIXS_RESET_SUB_RESOURCE_DATA:
    '@bankAccounts/RESET_SUB_RESOURCE_DATA_BANK_ACCOUNT_PIX',
};

export default Types;
