import React from 'react';
import { UserCheck, UserX, Circle } from 'react-feather';

const statusUndefined = {
  label: 'persons.is-enabled.undefined',
  color: 'regular',
  icon: <Circle />,
};

const statusEnabled = {
  label: 'persons.is-enabled.enabled',
  color: 'success',
  icon: <UserCheck />,
};

const statusNotEnabled = {
  label: 'persons.is-enabled.disabled',
  color: 'error',
  icon: <UserX />,
};

function getIsEnabledStatus(is_enabled) {
  let status;

  switch (is_enabled) {
    case true:
      status = statusEnabled;
      break;
    case false:
      status = statusNotEnabled;
      break;
    default:
      status = statusUndefined;
  }

  return status;
}

export { statusUndefined, getIsEnabledStatus };
