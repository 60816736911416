import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  detail: {},
  requestError: false,
  loading: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.USER_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.USER_UPDATE_SUCCESS: {
        const { newUser } = action.payload;

        draft.detail = newUser;
        draft.loading = false;
        break;
      }
      case Types.USER_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.USER_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.USER_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.user;
        draft.requestError = false;
        break;
      }
      case Types.USER_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.USER_RESET_DETAILS: {
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
