import Types from './types';

export function getChartAccountsRequest() {
  return {
    type: Types.CHART_ACCOUNTS_GET_REQUEST,
  };
}

export function getChartAccountsSuccess(chartAccounts) {
  return {
    type: Types.CHART_ACCOUNTS_GET_SUCCESS,
    payload: { chartAccounts },
  };
}

export function getChartAccountsFailure() {
  return {
    type: Types.CHART_ACCOUNTS_GET_FAILURE,
  };
}

export function createChartAccountRequest(chartAccount) {
  return {
    type: Types.CHART_ACCOUNTS_CREATE_REQUEST,
    payload: { chartAccount },
  };
}

export function createChartAccountSuccess(chartAccount) {
  return {
    type: Types.CHART_ACCOUNTS_CREATE_SUCCESS,
    payload: { chartAccount },
  };
}

export function createChartAccountFailure() {
  return {
    type: Types.CHART_ACCOUNTS_CREATE_FAILURE,
  };
}

export function updateChartAccountRequest(id, newChartAccount) {
  return {
    type: Types.CHART_ACCOUNTS_UPDATE_REQUEST,
    payload: { id, newChartAccount },
  };
}

export function updateChartAccountSuccess(newChartAccount) {
  return {
    type: Types.CHART_ACCOUNTS_UPDATE_SUCCESS,
    payload: { newChartAccount },
  };
}

export function updateChartAccountFailure() {
  return {
    type: Types.CHART_ACCOUNTS_UPDATE_FAILURE,
  };
}

export function deleteChartAccountRequest(id) {
  return {
    type: Types.CHART_ACCOUNTS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteChartAccountSuccess(id) {
  return {
    type: Types.CHART_ACCOUNTS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteChartAccountFailure() {
  return {
    type: Types.CHART_ACCOUNTS_DELETE_FAILURE,
  };
}

export function resetListChartAccounts() {
  return {
    type: Types.CHART_ACCOUNTS_RESET_LIST,
  };
}

export function openModalChartAccount({ id, newRegister }) {
  return {
    type: Types.CHART_ACCOUNTS_OPEN_MODAL,
    payload: { id, newRegister },
  };
}

export function closeModalChartAccount() {
  return {
    type: Types.CHART_ACCOUNTS_CLOSE_MODAL,
  };
}
