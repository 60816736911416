const Types = {
  CUSTOMER_EMAILS_GET: '@customers/GET_CUSTOMER_EMAILS',

  CUSTOMER_EMAILS_CREATE_REQUEST: '@customers/CREATE_CUSTOMER_EMAIL_REQUEST',
  CUSTOMER_EMAILS_CREATE_SUCCESS: '@customers/CREATE_CUSTOMER_EMAIL_SUCCESS',
  CUSTOMER_EMAILS_CREATE_FAILURE: '@customers/CREATE_CUSTOMER_EMAIL_FAILURE',

  CUSTOMER_EMAILS_UPDATE_REQUEST: '@customers/UPDATE_CUSTOMER_EMAIL_REQUEST',
  CUSTOMER_EMAILS_UPDATE_SUCCESS: '@customers/UPDATE_CUSTOMER_EMAIL_SUCCESS',
  CUSTOMER_EMAILS_UPDATE_FAILURE: '@customers/UPDATE_CUSTOMER_EMAIL_FAILURE',

  CUSTOMER_EMAILS_DELETE_REQUEST: '@customers/DELETE_CUSTOMER_EMAIL_REQUEST',
  CUSTOMER_EMAILS_DELETE_SUCCESS: '@customers/DELETE_CUSTOMER_EMAIL_SUCCESS',
  CUSTOMER_EMAILS_DELETE_FAILURE: '@customers/DELETE_CUSTOMER_EMAIL_FAILURE',

  CUSTOMER_EMAILS_RESET_LIST: '@customers/RESET_LIST_CUSTOMER_EMAIL',

  CUSTOMER_EMAILS_OPEN_MODAL: '@customers/OPEN_MODAL_CUSTOMER_EMAIL',
  CUSTOMER_EMAILS_CLOSE_MODAL: '@customers/CLOSE_MODAL_CUSTOMER_EMAIL',
};

export default Types;
