import React from 'react';

import PropTypes from 'prop-types';

import { Pix } from './styles';

export default function PixIcon({ type }) {
  return <Pix type={type} />;
}

PixIcon.propTypes = {
  type: PropTypes.string,
};

PixIcon.defaultProps = {
  type: '',
};
