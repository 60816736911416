import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getTeamMembers,
  openModalTeamMember,
  resetListTeamMember,
} from '../teamMembers/actions';
import {
  getTeamsSuccess,
  getTeamsFailure,
  createTeamSuccess,
  createTeamFailure,
  updateTeamSuccess,
  updateTeamFailure,
  deleteTeamSuccess,
  deleteTeamFailure,
  getDetailsTeamSuccess,
  getDetailsTeamFailure,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getTeams() {
  try {
    const { data } = yield call(api.get, `groups-persons`);

    yield put(getTeamsSuccess({ data }));
  } catch (err) {
    yield put(getTeamsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createTeam({ payload }) {
  try {
    const { team, subResourceType } = payload;

    const { data } = yield call(api.post, 'groups-persons', team);

    yield put(createTeamSuccess(data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/team/${data.id}`, { creatingResource: true });
  } catch (err) {
    yield put(createTeamFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateTeam({ payload }) {
  try {
    const { id, newTeam } = payload;

    const { data } = yield call(api.put, `groups-persons/${id}`, newTeam);

    yield put(updateTeamSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateTeamFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteTeam({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `groups-persons/${id}`);

    yield put(deleteTeamSuccess(id));

    history.push('/teams');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteTeamFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsTeam({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `groups-persons/${id}`);

    const { membersData, ...rest } = data;

    yield put(getTeamMembers(membersData));

    yield put(getDetailsTeamSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.teams
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'MEMBER') {
        yield put(openModalTeamMember());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsTeamFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsTeam() {
  yield put(resetListTeamMember());
}

export default all([
  takeLatest(Types.TEAMS_GET_REQUEST, getTeams),
  takeLatest(Types.TEAMS_CREATE_REQUEST, createTeam),
  takeLatest(Types.TEAMS_UPDATE_REQUEST, updateTeam),
  takeLatest(Types.TEAMS_DELETE_REQUEST, deleteTeam),
  takeLatest(Types.TEAMS_GET_DETAILS_REQUEST, getDetailsTeam),
  takeLatest(Types.TEAMS_RESET_DETAIL, resetDetailsTeam),
]);
