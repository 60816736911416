import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filterColumnsOrder: null,
  filterSearch: '',

  detail: {},
  requestError: false,
  loading: false,
  covenantModalOpen: false,
};

export default function covenants(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COVENANTS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.COVENANTS_GET_SUCCESS: {
        const { data, filterColumnsOrder, filterSearch } = action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }
        break;
      }
      case Types.COVENANTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COVENANTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COVENANTS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.covenant);
        draft.loading = false;
        draft.covenantModalOpen = false;
        break;
      }
      case Types.COVENANTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COVENANTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COVENANTS_UPDATE_SUCCESS: {
        const { newCovenant } = action.payload;

        draft.list = state.list.map(covenant => {
          return covenant.id === newCovenant.id ? newCovenant : covenant;
        });

        draft.detail = {};
        draft.loading = false;
        draft.covenantModalOpen = false;
        break;
      }
      case Types.COVENANTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COVENANTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COVENANTS_DELETE_SUCCESS: {
        draft.list = state.list.filter(covenant => {
          return covenant.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.COVENANTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COVENANTS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        break;
      }

      case Types.COVENANTS_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = state.list.filter(covenant => {
            return covenant.id === id;
          });
        }

        draft.covenantModalOpen = true;
        break;
      }
      case Types.COVENANTS_CLOSE_MODAL: {
        draft.covenantModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
