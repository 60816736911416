import React from 'react';
import { File } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const TaxSituations = async(() => import('~/pages/TaxSituations/List'));

export const fiscalRoutes = {
  id: <IntlMessages id="sidebar.fiscal" />,
  path: '/fiscal',
  icon: <File />,
  children: [
    {
      path: '/tax-situations',
      name: <IntlMessages id="sidebar.fiscal.tax-situations" />,
      component: TaxSituations,
    },
  ],
  // children: [
  //   {
  //     path: '/tributations',
  //     name: <IntlMessages id="sidebar.fiscal.tributations" />,
  //     component: TributationsList,
  //   },
  //   {
  //     path: '/fiscal-operations',
  //     name: <IntlMessages id="sidebar.fiscal.fiscal-operations" />,
  //     component: TributationsList,
  //   },
  //   {
  //     path: '/csts',
  //     name: <IntlMessages id="sidebar.fiscal.csts" />,
  //     component: TributationsList,
  //   },
  //   {
  //     path: '/sats',
  //     name: <IntlMessages id="sidebar.fiscal.sats" />,
  //     component: TributationsList,
  //   },
  // ],
};
