import styled from 'styled-components';

import { AttachMoney as MuiAttachMoney } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'table':
      styles = ``;
      break;
    case 'form':
      styles = '';
      break;
    case 'report':
      styles = `
        width: 30px;
        height: 30px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const AttachMoney = styled(MuiAttachMoney)`
  ${({ type }) => defineStyles(type)}
`;
