const Types = {
  CONTACTS_GET_REQUEST: '@contacts/GET_CONTACTS_REQUEST',
  CONTACTS_GET_SUCCESS: '@contacts/GET_CONTACTS_SUCCESS',
  CONTACTS_GET_FAILURE: '@contacts/GET_CONTACTS_FAILURE',

  CONTACTS_GET_DETAILS_REQUEST: '@contacts/GET_DETAILS_CONTACT_REQUEST',
  CONTACTS_GET_DETAILS_SUCCESS: '@contacts/GET_DETAILS_CONTACT_SUCCESS',
  CONTACTS_GET_DETAILS_FAILURE: '@contacts/GET_DETAILS_CONTACT_FAILURE',

  CONTACTS_OPEN_MODAL: '@contacts/OPEN_MODAL_CONTACT',
  CONTACTS_CLOSE_MODAL: '@contacts/CLOSE_MODAL_CONTACT',

  CONTACTS_DETAIL_OPEN_MODAL: '@contacts/OPEN_MODAL_CONTACT_DETAIL',
  CONTACTS_DETAIL_CLOSE_MODAL: '@contacts/CLOSE_MODAL_CONTACT_DETAIL',

  CONTACTS_RESET_DETAILS: '@contacts/RESET_DETAILS_CONTACT',
  CONTACTS_RESET_LIST: '@contacts/RESET_LIST_CONTACT',
};

export default Types;
