import Types from './types';

export function createOrderRequest(order, subResourceType = null) {
  return {
    type: Types.ORDERS_CREATE_REQUEST,
    payload: { order, subResourceType },
  };
}

export function createOrderSuccess(order) {
  return {
    type: Types.ORDERS_CREATE_SUCCESS,
    payload: { order },
  };
}

export function createOrderFailure() {
  return {
    type: Types.ORDERS_CREATE_FAILURE,
  };
}

export function updateOrderRequest({
  id,
  newOrder,
  updatingItem = false,
  updatingDetail = false,
}) {
  return {
    type: Types.ORDERS_UPDATE_REQUEST,
    payload: { id, newOrder, updatingItem, updatingDetail },
  };
}

export function updateOrderSuccess(newOrder) {
  return {
    type: Types.ORDERS_UPDATE_SUCCESS,
    payload: { newOrder },
  };
}

export function updateOrderFailure() {
  return {
    type: Types.ORDERS_UPDATE_FAILURE,
  };
}

export function deleteOrderRequest({
  id,
  fetchList = null,
  isFromDetail = false,
}) {
  return {
    type: Types.ORDERS_DELETE_REQUEST,
    payload: { id, fetchList, isFromDetail },
  };
}

export function deleteOrderSuccess(isFromDetail = false) {
  return {
    type: Types.ORDERS_DELETE_SUCCESS,
    payload: { isFromDetail },
  };
}

export function deleteOrderFailure() {
  return {
    type: Types.ORDERS_DELETE_FAILURE,
  };
}

export function getDetailsOrderRequest(id) {
  return {
    type: Types.ORDERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsOrderSuccess(order) {
  return {
    type: Types.ORDERS_GET_DETAILS_SUCCESS,
    payload: { order },
  };
}

export function getDetailsOrderFailure() {
  return {
    type: Types.ORDERS_GET_DETAILS_FAILURE,
  };
}

export function resetListOrders() {
  return {
    type: Types.ORDERS_RESET_LIST,
  };
}

export function resetDetailsOrder() {
  return {
    type: Types.ORDERS_RESET_DETAILS,
  };
}

export function openModalOrderObservation() {
  return {
    type: Types.ORDERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalOrderObservation() {
  return {
    type: Types.ORDERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalOrderNote() {
  return {
    type: Types.ORDERS_NOTE_OPEN_MODAL,
  };
}

export function closeModalOrderNote() {
  return {
    type: Types.ORDERS_NOTE_CLOSE_MODAL,
  };
}

export function openModalOrderShipping() {
  return {
    type: Types.ORDERS_SHIPPING_OPEN_MODAL,
  };
}

export function closeModalOrderShipping() {
  return {
    type: Types.ORDERS_SHIPPING_CLOSE_MODAL,
  };
}

export function openModalOrderMap() {
  return {
    type: Types.ORDERS_MAP_OPEN_MODAL,
  };
}

export function closeModalOrderMap() {
  return {
    type: Types.ORDERS_MAP_CLOSE_MODAL,
  };
}

export function openModalOrderFilter() {
  return {
    type: Types.ORDERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalOrderFilter() {
  return {
    type: Types.ORDERS_FILTER_CLOSE_MODAL,
  };
}

export function setOrderTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
  searchThroughTable,
  shouldFetchStatisticData = false,
  listLength,
}) {
  return {
    type: Types.ORDERS_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      searchThroughTable,
      shouldFetchStatisticData,
      listLength,
    },
  };
}

export function setOrderRequestError({ requestError = false }) {
  return {
    type: Types.ORDERS_SET_REQUEST_ERROR,
    payload: { requestError },
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.ORDERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.ORDERS_RESET_SUB_RESOURCE_DATA,
  };
}
