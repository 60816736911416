import async from '~/components/Async';
import CompanyProfileLayout from '~/layouts/CompanyProfileLayout';
import LandingLayout from '~/layouts/Landing';
import MapLayout from '~/layouts/Map';

const PixPayment = async(() => import('~/pages/PixPayment'));
const CompanyProfile = async(() => import('~/pages/CompanyProfile'));
const Map = async(() => import('~/pages/Maps/Map'));

export const publicRoutes = {
  children: [
    {
      path: '/pixs/:id',
      component: PixPayment,
      isPublic: true,
      Layout: LandingLayout,
    },
    {
      path: '/profile/:slug',
      component: CompanyProfile,
      isPublic: true,
      Layout: CompanyProfileLayout,
    },
    {
      path: '/map/',
      component: Map,
      isPublic: true,
      Layout: MapLayout,
    },
  ],
};
