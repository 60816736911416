import produce from 'immer';

import OrderTypes from '../orders/types';
import Types from './types';

const INITIAL_STATE = {
  detail: {},
  loading: false,
  orderBillPixModalOpen: false,
  orderBillPixEmitModalOpen: false,
  orderBillPixAddAccountModalOpen: false,
  orderBillPixDetailModalOpen: false,
  orderBillPixShareModalOpen: false,
  showNextPixRequestMessage: null,
  selectedAccount: null,
};

export default function orderBillPixs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ORDER_BILL_PIXS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_BILL_PIXS_CREATE_SUCCESS: {
        const { pix, pixType } = action.payload;

        draft.detail = pix;

        if (pixType === 'DYNAMIC') {
          draft.showNextPixRequestMessage = true;
          draft.orderBillPixEmitModalOpen = true;
        } else if (pixType === 'STATIC') {
          draft.selectedAccount = null;

          if (pix.status === 'ACTIVE') {
            draft.orderBillPixShareModalOpen = true;
            draft.orderBillPixEmitModalOpen = false;
          } else {
            draft.orderBillPixDetailModalOpen = true;
            draft.orderBillPixEmitModalOpen = false;
          }
        }

        draft.loading = false;
        break;
      }
      case Types.ORDER_BILL_PIXS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_BILL_PIXS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ORDER_BILL_PIXS_UPDATE_SUCCESS: {
        const { newPix } = action.payload;

        draft.list = state.list.map(pix => {
          return pix.id === newPix.id ? newPix : pix;
        });

        draft.detail = newPix;
        draft.loading = false;

        break;
      }
      case Types.ORDER_BILL_PIXS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.ORDER_BILL_PIXS_OPEN_MODAL: {
        draft.orderBillPixModalOpen = true;
        break;
      }
      case Types.ORDER_BILL_PIXS_CLOSE_MODAL: {
        draft.orderBillPixModalOpen = false;
        draft.showNextPixRequestMessage = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.ORDER_BILL_PIXS_EMIT_OPEN_MODAL: {
        draft.orderBillPixEmitModalOpen = true;
        break;
      }
      case Types.ORDER_BILL_PIXS_EMIT_CLOSE_MODAL: {
        draft.orderBillPixEmitModalOpen = false;
        draft.showNextPixRequestMessage = false;
        draft.selectedAccount = null;
        draft.detail = {};
        break;
      }

      case Types.ORDER_BILL_PIXS_ADD_ACCOUNT_OPEN_MODAL: {
        draft.orderBillPixAddAccountModalOpen = true;
        draft.orderBillPixModalOpen = false;
        break;
      }
      case Types.ORDER_BILL_PIXS_ADD_ACCOUNT_CLOSE_MODAL: {
        draft.orderBillPixAddAccountModalOpen = false;
        break;
      }
      case Types.ORDER_BILL_PIXS_SET_SELECTED_ACCOUNT: {
        draft.selectedAccount = action.payload.account;
        draft.orderBillPixAddAccountModalOpen = false;
        draft.orderBillPixEmitModalOpen = true;
        break;
      }

      case Types.ORDER_BILL_PIXS_DETAIL_OPEN_MODAL: {
        const { pix: pixData } = action.payload;

        draft.detail = {};

        if (pixData) {
          draft.detail = pixData;
        }

        draft.showNextPixRequestMessage = false;
        draft.orderBillPixEmitModalOpen = false;
        draft.orderBillPixDetailModalOpen = true;
        break;
      }
      case Types.ORDER_BILL_PIXS_DETAIL_CLOSE_MODAL: {
        draft.orderBillPixDetailModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.ORDER_BILL_PIXS_SHARE_OPEN_MODAL: {
        const { pix: pixData } = action.payload;

        draft.detail = {};

        if (pixData) {
          draft.detail = pixData;
        }

        draft.showNextPixRequestMessage = false;
        draft.orderBillPixEmitModalOpen = false;
        draft.orderBillPixShareModalOpen = true;
        draft.selectedAccount = null;
        break;
      }
      case Types.ORDER_BILL_PIXS_SHARE_CLOSE_MODAL: {
        draft.orderBillPixShareModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case OrderTypes.ORDERS_RESET_DETAILS: {
        draft.detail = {};
        draft.showNextPixRequestMessage = null;
        draft.orderBillPixEmitModalOpen = false;
        draft.orderBillPixShareModalOpen = false;
        draft.orderBillPixDetailModalOpen = false;
        draft.orderBillPixAddAccountModalOpen = false;
        draft.orderBillPixModalOpen = false;
        draft.selectedAccount = null;

        break;
      }

      case OrderTypes.ORDERS_RESET_LIST: {
        draft.detail = {};
        draft.showNextPixRequestMessage = null;
        draft.orderBillPixEmitModalOpen = false;
        draft.orderBillPixShareModalOpen = false;
        draft.orderBillPixDetailModalOpen = false;
        draft.orderBillPixAddAccountModalOpen = false;
        draft.orderBillPixModalOpen = false;
        draft.selectedAccount = null;
        break;
      }

      default:
    }
  });
}
