import Types from './types';

export function getRuralPropertyAddresses(addresses) {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createRuralPropertyAddressRequest(property_id, address) {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_CREATE_REQUEST,
    payload: { property_id, address },
  };
}

export function createRuralPropertyAddressSuccess(address) {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createRuralPropertyAddressFailure() {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateRuralPropertyAddressRequest(
  property_id,
  address_id,
  newAddress
) {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_UPDATE_REQUEST,
    payload: { property_id, address_id, newAddress },
  };
}

export function updateRuralPropertyAddressSuccess(newAddress) {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateRuralPropertyAddressFailure() {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_UPDATE_FAILURE,
  };
}

export function resetListRuralPropertyAddress() {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_RESET_LIST,
  };
}

export function openModalRuralPropertyAddress(address_id) {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalRuralPropertyAddress() {
  return {
    type: Types.RURAL_PROPERTY_ADDRESSES_CLOSE_MODAL,
  };
}
