import async from '~/components/Async';

const AccountDetail = async(() => import('~/pages/Accounts/Detail'));
const BillToPayDetail = async(() => import('~/pages/BillsToPay/Detail'));
const BillToReceiveDetail = async(() =>
  import('~/pages/BillsToReceive/Detail')
);
const CompanyDetail = async(() => import('~/pages/Company/Detail'));
const CustomerDetail = async(() => import('~/pages/Customers/Detail'));
const Dashboard = async(() => import('~/pages/Dashboard'));
const EmployeeDetail = async(() => import('~/pages/Employees/Detail'));
const Help = async(() => import('~/pages/Help'));
const InventoryDetail = async(() => import('~/pages/Inventories/Detail'));
const OrderDetail = async(() => import('~/pages/Orders/Detail'));
const OwnerDetail = async(() => import('~/pages/Owners/Detail'));
const PaymentMethodDetail = async(() =>
  import('~/pages/PaymentMethods/Detail')
);
const PromoterDetail = async(() => import('~/pages/Promoter/Detail'));
const PersonDetail = async(() => import('~/pages/Persons/Detail'));
const PriceListDetail = async(() => import('~/pages/PriceLists/Detail'));
const ProductDetail = async(() => import('~/pages/Products/Detail'));
const ProfileDetail = async(() => import('~/pages/Profile/Detail'));
const RegionDetail = async(() => import('~/pages/Regions/Detail'));
const RoadMap = async(() => import('~/pages/Promoter/RoadMap'));
const RuralPropertyDetail = async(() =>
  import('~/pages/RuralProperties/Detail')
);
const SellerDetail = async(() => import('~/pages/Sellers/Detail'));
const Settings = async(() => import('~/pages/Settings'));
const SupplierDetail = async(() => import('~/pages/Suppliers/Detail'));
const SupervisorDetail = async(() => import('~/pages/Supervisor/Detail'));
const TeamDetail = async(() => import('~/pages/Teams/Detail'));
const TransporterDetail = async(() => import('~/pages/Transporters/Detail'));

// Reports
const ProductsTopSellingReportPage = async(() =>
  import('~/pages/Reports/pages/Products/ProductsTopSelling')
);
const ProductsRequestsReportPage = async(() =>
  import('~/pages/Reports/pages/Products/ProductsRequests')
);

const ProductsByPricesReportPage = async(() =>
  import('~/pages/Reports/pages/Products/ProductsByPrices')
);

export const privateRoutes = {
  id: 'Private',
  path: '/private',
  children: [
    {
      path: '/account/:id',
      component: AccountDetail,
    },
    {
      path: '/dashboard',
      component: Dashboard,
    },
    {
      path: '/payment-method/pay/:id',
      component: PaymentMethodDetail,
      params: 'PAY',
    },
    {
      path: '/payment-method/receive/:id',
      component: PaymentMethodDetail,
      params: 'RECEIVE',
    },
    {
      path: '/bill-to-pay/:id',
      component: BillToPayDetail,
    },
    {
      path: '/bill-to-receive/:id',
      component: BillToReceiveDetail,
    },
    {
      path: '/customer/:id',
      component: CustomerDetail,
    },
    {
      path: '/price-list/:id',
      component: PriceListDetail,
    },
    {
      path: '/product/:id',
      component: ProductDetail,
    },
    {
      path: '/inventory/:id',
      component: InventoryDetail,
    },
    {
      path: '/profile',
      component: ProfileDetail,
    },
    {
      path: '/person/:id',
      component: PersonDetail,
    },
    {
      path: '/company',
      component: CompanyDetail,
    },
    {
      path: '/supplier/:id',
      component: SupplierDetail,
    },
    {
      path: '/seller/:id',
      component: SellerDetail,
    },
    {
      path: '/employee/:id',
      component: EmployeeDetail,
    },
    {
      path: '/promoter/:id',
      component: PromoterDetail,
    },
    {
      path: '/roadmap/:id',
      component: RoadMap,
    },
    {
      path: '/team/:id',
      component: TeamDetail,
    },
    {
      path: '/transporter/:id',
      component: TransporterDetail,
    },
    {
      path: '/supervisor/:id',
      component: SupervisorDetail,
    },
    {
      path: '/order/:id',
      component: OrderDetail,
    },
    {
      path: '/settings',
      component: Settings,
    },
    {
      path: '/help',
      component: Help,
    },
    {
      path: '/region/:id',
      component: RegionDetail,
    },
  ],
};

// Reports
privateRoutes.children.push(
  {
    path: '/report/products-top-selling',
    component: ProductsTopSellingReportPage,
  },
  {
    path: '/report/products-by-prices',
    component: ProductsByPricesReportPage,
  },
  { path: '/report/products-requests', component: ProductsRequestsReportPage }
);

if (process.env.REACT_APP_MODULE === 'AGRIBUSINESS') {
  privateRoutes.children.push(
    {
      path: '/owner/:id',
      component: OwnerDetail,
    },
    {
      path: '/rural-property/:id',
      component: RuralPropertyDetail,
    }
  );
}
