import React from 'react';

import PropTypes from 'prop-types';

import { Box } from './styles';

export default function BoxIcon({ type }) {
  return <Box type={type} />;
}

BoxIcon.propTypes = {
  type: PropTypes.string,
};

BoxIcon.defaultProps = {
  type: '',
};
