const Types = {
  BRANDS_GET_REQUEST: '@brands/GET_BRANDS_REQUEST',
  BRANDS_GET_SUCCESS: '@brands/GET_BRANDS_SUCCESS',
  BRANDS_GET_FAILURE: '@brands/GET_BRANDS_FAILURE',

  BRANDS_CREATE_REQUEST: '@brands/CREATE_BRAND_REQUEST',
  BRANDS_CREATE_SUCCESS: '@brands/CREATE_BRAND_SUCCESS',
  BRANDS_CREATE_FAILURE: '@brands/CREATE_BRAND_FAILURE',

  BRANDS_UPDATE_REQUEST: '@brands/UPDATE_BRAND_REQUEST',
  BRANDS_UPDATE_SUCCESS: '@brands/UPDATE_BRAND_SUCCESS',
  BRANDS_UPDATE_FAILURE: '@brands/UPDATE_BRAND_FAILURE',

  BRANDS_DELETE_REQUEST: '@brands/DELETE_BRAND_REQUEST',
  BRANDS_DELETE_SUCCESS: '@brands/DELETE_BRAND_SUCCESS',
  BRANDS_DELETE_FAILURE: '@brands/DELETE_BRAND_FAILURE',

  BRANDS_RESET_LIST: '@brands/RESET_LIST_BRANDS',

  BRANDS_OPEN_MODAL: '@brands/OPEN_MODAL_BRAND',
  BRANDS_CLOSE_MODAL: '@brands/CLOSE_MODAL_BRAND',
};

export default Types;
