import Types from './types';

export function getPriceListsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.PRICE_LISTS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getPriceListsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.PRICE_LISTS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getPriceListsFailure() {
  return {
    type: Types.PRICE_LISTS_GET_FAILURE,
  };
}

export function createPriceListRequest(priceList) {
  return {
    type: Types.PRICE_LISTS_CREATE_REQUEST,
    payload: { priceList },
  };
}

export function createPriceListSuccess(priceList) {
  return {
    type: Types.PRICE_LISTS_CREATE_SUCCESS,
    payload: { priceList },
  };
}

export function createPriceListFailure() {
  return {
    type: Types.PRICE_LISTS_CREATE_FAILURE,
  };
}

export function updatePriceListRequest(
  id,
  newPriceList,
  loadPriceListItems = null
) {
  return {
    type: Types.PRICE_LISTS_UPDATE_REQUEST,
    payload: { id, newPriceList, loadPriceListItems },
  };
}

export function updatePriceListSuccess(newPriceList) {
  return {
    type: Types.PRICE_LISTS_UPDATE_SUCCESS,
    payload: { newPriceList },
  };
}

export function updatePriceListFailure() {
  return {
    type: Types.PRICE_LISTS_UPDATE_FAILURE,
  };
}

export function deletePriceListRequest(id) {
  return {
    type: Types.PRICE_LISTS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deletePriceListSuccess(id) {
  return {
    type: Types.PRICE_LISTS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deletePriceListFailure() {
  return {
    type: Types.PRICE_LISTS_DELETE_FAILURE,
  };
}

export function openModalPriceListFilter() {
  return {
    type: Types.PRICE_LISTS_FILTER_OPEN_MODAL,
  };
}

export function closeModalPriceListFilter() {
  return {
    type: Types.PRICE_LISTS_FILTER_CLOSE_MODAL,
  };
}

export function getDetailsPriceListRequest(id, loadAllItems = null) {
  return {
    type: Types.PRICE_LISTS_GET_DETAILS_REQUEST,
    payload: { id, loadAllItems },
  };
}

export function getDetailsPriceListSuccess(priceList) {
  return {
    type: Types.PRICE_LISTS_GET_DETAILS_SUCCESS,
    payload: { priceList },
  };
}

export function getDetailsPriceListFailure() {
  return {
    type: Types.PRICE_LISTS_GET_DETAILS_FAILURE,
  };
}

export function resetListPriceLists() {
  return {
    type: Types.PRICE_LISTS_RESET_LIST,
  };
}

export function resetDetailsPriceList() {
  return {
    type: Types.PRICE_LISTS_RESET_DETAILS,
  };
}
