const Types = {
  BANK_ACCOUNT_PIX_FILES_GET: '@bankAccount/GET_BANK_ACCOUNT_PIX_FILES',

  BANK_ACCOUNT_PIX_FILES_CREATE_REQUEST:
    '@bankAccount/CREATE_BANK_ACCOUNT_PIX_FILE_REQUEST',
  BANK_ACCOUNT_PIX_FILES_CREATE_SUCCESS:
    '@bankAccount/CREATE_BANK_ACCOUNT_PIX_FILE_SUCCESS',
  BANK_ACCOUNT_PIX_FILES_CREATE_FAILURE:
    '@bankAccount/CREATE_BANK_ACCOUNT_PIX_FILE_FAILURE',

  BANK_ACCOUNT_PIX_FILES_UPDATE_REQUEST:
    '@bankAccount/UPDATE_BANK_ACCOUNT_PIX_FILE_REQUEST',
  BANK_ACCOUNT_PIX_FILES_UPDATE_SUCCESS:
    '@bankAccount/UPDATE_BANK_ACCOUNT_PIX_FILE_SUCCESS',
  BANK_ACCOUNT_PIX_FILES_UPDATE_FAILURE:
    '@bankAccount/UPDATE_BANK_ACCOUNT_PIX_FILE_FAILURE',

  BANK_ACCOUNT_PIX_FILES_DELETE_REQUEST:
    '@bankAccount/DELETE_BANK_ACCOUNT_PIX_FILE_REQUEST',
  BANK_ACCOUNT_PIX_FILES_DELETE_SUCCESS:
    '@bankAccount/DELETE_BANK_ACCOUNT_PIX_FILE_SUCCESS',
  BANK_ACCOUNT_PIX_FILES_DELETE_FAILURE:
    '@bankAccount/DELETE_BANK_ACCOUNT_PIX_FILE_FAILURE',

  BANK_ACCOUNT_PIX_FILES_RESET_LIST:
    '@bankAccount/RESET_LIST_BANK_ACCOUNT_PIX_FILE',

  BANK_ACCOUNT_PIX_FILES_OPEN_MODAL:
    '@bankAccount/OPEN_MODAL_BANK_ACCOUNT_PIX_FILE',
  BANK_ACCOUNT_PIX_FILES_CLOSE_MODAL:
    '@bankAccount/CLOSE_MODAL_BANK_ACCOUNT_PIX_FILE',
};

export default Types;
