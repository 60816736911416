import styled from 'styled-components';

import {
  Grid,
  IconButton as MuiIconButton,
  Avatar as MuiAvatar,
} from '@material-ui/core';

import colors from '~/theme/colors';

export const CardContainer = styled(Grid)`
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
    overflow-y: auto;
    max-width: 90vw;
  }
`;

export const AvatarWrapper = styled(Grid)`
  display: flex;
  padding: 30px;
  background-color: ${colors.light};
  max-width: 200px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-bottom-left-radius: 4px;

  @media (max-width: 960px) {
    padding: 15px;
    flex-direction: row;
    max-width: 100%;
    justify-content: center;
    border-bottom-left-radius: 0px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ImgContainer = styled(Grid).attrs({
  item: true,
})`
  margin-bottom: 5px;
`;

export const Avatar = styled(MuiAvatar).attrs({
  variant: 'circle',
})`
  width: 150px;
  height: 150px;

  @media (max-width: 960px) {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

export const ChipContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  @media (max-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    & > * {
      margin: 8px 6px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const InfoWrapper = styled(Grid)`
  @media (max-width: 960px) {
    max-height: calc(50vh - 50px);
  }
`;

export const InfoContainer = styled(Grid).attrs({
  container: true,
})`
  padding: 30px;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: 960px) {
    padding: 15px 20px;
  }
`;

export const InfoTitle = styled.p`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 2px;
  margin-top: 0px;
`;

export const InfoText = styled.span`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  word-break: ${props => (props.break ? 'break-all' : 'normal')};
  word-wrap: ${props => (props.break ? 'break-word' : 'normal')};
`;

export const IconButton = styled(MuiIconButton)`
  padding: 3px;
  margin-bottom: 3px;
`;

export const ContactGroup = styled(Grid)`
  display: flex;
  flex-direction: column;

  margin-bottom: 3px;
`;

export const ContactName = styled.span`
  font-size: 12px;
`;

export const ContactAction = styled.span`
  display: flex;
  align-items: center;
  margin-top: -4px;
  font-size: 13px;
  font-weight: bold;
  word-break: ${props => (props.break ? 'break-all' : 'normal')};
  word-wrap: ${props => (props.break ? 'break-word' : 'normal')};
`;

export const LoadingContainer = styled(Grid)`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactWebsite = styled.a`
  color: inherit;
`;
