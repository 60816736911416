import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { resetFoundPerson } from '../persons/actions';
import {
  getSupplierAddresses,
  openModalSupplierAddress,
  resetListSupplierAddress,
} from '../supplierAddresses/actions';
import {
  getSupplierEmails,
  resetListSupplierEmail,
  openModalSupplierEmail,
} from '../supplierEmails/actions';
import {
  getSupplierFiles,
  resetListSupplierFile,
} from '../supplierFiles/actions';
import {
  getSupplierPhones,
  openModalSupplierPhone,
  resetListSupplierPhone,
} from '../supplierPhones/actions';
import {
  getSuppliersSuccess,
  getSuppliersFailure,
  createSupplierSuccess,
  createSupplierFailure,
  deleteSupplierSuccess,
  deleteSupplierFailure,
  becomeSupplierSuccess,
  becomeSupplierFailure,
  getDetailsSupplierSuccess,
  updateSupplierSuccess,
  updateSupplierFailure,
  getDetailsSupplierFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getSuppliers({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `suppliers${queryParams}`);

    yield put(
      getSuppliersSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getSuppliersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createSupplier({ payload }) {
  try {
    const { supplier, subResourceType } = payload;

    const response = yield call(api.post, 'suppliers', supplier);

    yield put(createSupplierSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/supplier/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createSupplierFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupplier({ payload }) {
  try {
    const { id, newSupplier } = payload;

    const response = yield call(api.put, `suppliers/${id}`, newSupplier);

    yield put(updateSupplierSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupplierFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupplier({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `suppliers/${id}`);

    yield put(deleteSupplierSuccess(id));

    history.push('/suppliers');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupplierFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeSupplier({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'SUPPLIER';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeSupplierSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/supplier/${id}`);
  } catch (err) {
    yield put(becomeSupplierFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsSupplier({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `suppliers/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getSupplierPhones(phones));

    yield put(getSupplierAddresses(addresses));

    yield put(getSupplierEmails(emails));

    yield put(getSupplierFiles(files));

    yield put(getDetailsSupplierSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.suppliers
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalSupplierPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalSupplierEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalSupplierAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsSupplierFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsSupplier() {
  yield put(resetListSupplierPhone());

  yield put(resetListSupplierAddress());

  yield put(resetListSupplierEmail());

  yield put(resetListSupplierFile());
}

export default all([
  takeLatest(Types.SUPPLIERS_GET_REQUEST, getSuppliers),
  takeLatest(Types.SUPPLIERS_CREATE_REQUEST, createSupplier),
  takeLatest(Types.SUPPLIERS_UPDATE_REQUEST, updateSupplier),
  takeLatest(Types.SUPPLIERS_DELETE_REQUEST, deleteSupplier),
  takeLatest(Types.SUPPLIERS_BECOME_REQUEST, becomeSupplier),
  takeLatest(Types.SUPPLIERS_GET_DETAILS_REQUEST, getDetailsSupplier),
  takeLatest(Types.SUPPLIERS_RESET_DETAILS, resetDetailsSupplier),
]);
