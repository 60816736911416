import React from 'react';

import PropTypes from 'prop-types';

import { Edit } from './styles';

export default function EditIcon({ type }) {
  return <Edit type={type} />;
}

EditIcon.propTypes = {
  type: PropTypes.string,
};

EditIcon.defaultProps = {
  type: '',
};
