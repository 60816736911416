const Types = {
  BANK_BILLINGS_GET_REQUEST: '@bankBillings/GET_BANK_BILLINGS_REQUEST',
  BANK_BILLINGS_GET_SUCCESS: '@bankBillings/GET_BANK_BILLINGS_SUCCESS',
  BANK_BILLINGS_GET_FAILURE: '@bankBillings/GET_BANK_BILLINGS_FAILURE',

  BANK_BILLINGS_DELETE_REQUEST: '@bankBillings/DELETE_BANK_BILLING_REQUEST',
  BANK_BILLINGS_DELETE_SUCCESS: '@bankBillings/DELETE_BANK_BILLING_SUCCESS',
  BANK_BILLINGS_DELETE_FAILURE: '@bankBillings/DELETE_BANK_BILLING_FAILURE',

  BANK_BILLINGS_RESET_LIST: '@bankBillings/RESET_LIST_BANK_BILLINGS',

  BANK_BILLINGS_ADD_OPEN_MODAL: '@bankBillings/OPEN_MODAL_ADD_BANK_BILLING',
  BANK_BILLINGS_ADD_CLOSE_MODAL: '@bankBillings/CLOSE_MODAL_ADD_BANK_BILLING',

  BANK_BILLINGS_FILTER_OPEN_MODAL:
    '@bankBillings/OPEN_MODAL_FILTER_BANK_BILLING',
  BANK_BILLINGS_FILTER_CLOSE_MODAL:
    '@bankBillings/CLOSE_MODAL_FILTER_BANK_BILLING',

  BANK_BILLINGS_SET_TABLE_FILTERS:
    '@bankBillings/SET_TABLE_FILTERS_BANK_BILLING',
};

export default Types;
