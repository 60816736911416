import React from 'react';
import { Map } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const Roadmap = async(() => import('~/pages/Promoter/RoadMap/List'));

const Teams = async(() => import('~/pages/Teams/List'));

export const roadmapRoutes = {
  id: <IntlMessages id="sidebar.roadmap" />,
  path: '/roadmap',
  icon: <Map />,
  children: [
    {
      path: '/roadmap',
      name: <IntlMessages id="sidebar.roadmap.list" />,
      component: Roadmap,
    },
    {
      path: '/teams',
      name: <IntlMessages id="sidebar.roadmap.teams" />,
      component: Teams,
    },
  ],
};
