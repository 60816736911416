import produce from 'immer';

import EmployeeTypes from '../employees/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  employeeEmailModalOpen: false,
};

export default function employeeEmails(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.EMPLOYEE_EMAILS_GET: {
        draft.list = action.payload.emails;
        break;
      }

      case Types.EMPLOYEE_EMAILS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEE_EMAILS_CREATE_SUCCESS: {
        const { is_main } = action.payload.email;

        if (is_main) {
          draft.list = draft.list.map(email => ({ ...email, is_main: false }));
        }

        draft.list.unshift(action.payload.email);
        draft.loading = false;
        draft.employeeEmailModalOpen = false;
        break;
      }
      case Types.EMPLOYEE_EMAILS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEE_EMAILS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEE_EMAILS_UPDATE_SUCCESS: {
        const { newEmail } = action.payload;
        const { is_main } = newEmail;
        let data = [];

        if (is_main) {
          const removeMain = draft.list.map(email => ({
            ...email,
            is_main: false,
          }));

          data = removeMain;
        } else {
          data = state.list;
        }

        draft.list = data.map(email => {
          return email.id === newEmail.id ? newEmail : email;
        });

        draft.detail = {};
        draft.loading = false;
        draft.employeeEmailModalOpen = false;
        break;
      }
      case Types.EMPLOYEE_EMAILS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEE_EMAILS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEE_EMAILS_DELETE_SUCCESS: {
        const { email_id } = action.payload;

        draft.list = state.list.filter(email => {
          return email.id !== email_id;
        });

        if (draft.detail.id && email_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.EMPLOYEE_EMAILS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEE_EMAILS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.EMPLOYEE_EMAILS_OPEN_MODAL: {
        const { email_id } = action.payload;

        draft.detail = {};

        if (email_id) {
          [draft.detail] = draft.list.filter(email => email.id === email_id);
        }
        draft.employeeEmailModalOpen = true;
        break;
      }
      case Types.EMPLOYEE_EMAILS_CLOSE_MODAL: {
        draft.employeeEmailModalOpen = false;
        draft.detail = {};
        break;
      }

      case EmployeeTypes.EMPLOYEES_RESET_DETAILS: {
        draft.employeeEmailModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
