import Types from './types';

export function getCompaniesRequest(types = null) {
  return {
    type: Types.COMPANIES_GET_REQUEST,
    payload: { types },
  };
}

export function getCompaniesSuccess(companies) {
  return {
    type: Types.COMPANIES_GET_SUCCESS,
    payload: { companies },
  };
}

export function getCompaniesFailure() {
  return {
    type: Types.COMPANIES_GET_FAILURE,
  };
}

export function createCompanyRequest(company) {
  return {
    type: Types.COMPANIES_CREATE_REQUEST,
    payload: { company },
  };
}

export function createCompanySuccess(company) {
  return {
    type: Types.COMPANIES_CREATE_SUCCESS,
    payload: { company },
  };
}

export function createCompanyFailure() {
  return {
    type: Types.COMPANIES_CREATE_FAILURE,
  };
}

export function updateCompanyRequest(newCompany, subResourceType = null) {
  return {
    type: Types.COMPANIES_UPDATE_REQUEST,
    payload: { newCompany, subResourceType },
  };
}

export function updateCompanySuccess(newCompany) {
  return {
    type: Types.COMPANIES_UPDATE_SUCCESS,
    payload: { newCompany },
  };
}

export function updateCompanyFailure() {
  return {
    type: Types.COMPANIES_UPDATE_FAILURE,
  };
}

export function deleteCompanyRequest() {
  return {
    type: Types.COMPANIES_DELETE_REQUEST,
  };
}

export function deleteCompanySuccess() {
  return {
    type: Types.COMPANIES_DELETE_SUCCESS,
  };
}

export function deleteCompanyFailure() {
  return {
    type: Types.COMPANIES_DELETE_FAILURE,
  };
}

// export function becomeCompanyRequest(id) {
//   return {
//     type: Types.COMPANIES_BECOME_REQUEST,
//     payload: { id },
//   };
// }

// export function becomeCompanySuccess(company) {
//   return {
//     type: Types.COMPANIES_BECOME_SUCCESS,
//     payload: { company },
//   };
// }

// export function becomeCompanyFailure() {
//   return {
//     type: Types.COMPANIES_BECOME_FAILURE,
//   };
// }

export function getDetailsCompanyRequest(id) {
  return {
    type: Types.COMPANIES_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsCompanySuccess(company) {
  return {
    type: Types.COMPANIES_GET_DETAILS_SUCCESS,
    payload: { company },
  };
}

export function getDetailsCompanyFailure() {
  return {
    type: Types.COMPANIES_GET_DETAILS_FAILURE,
  };
}

export function resetDetailsCompany() {
  return {
    type: Types.COMPANIES_RESET_DETAILS,
  };
}

export function openModalCompanyObservation() {
  return {
    type: Types.COMPANIES_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalCompanyObservation() {
  return {
    type: Types.COMPANIES_OBSERVATION_CLOSE_MODAL,
  };
}

export function clearNewCompanyBranchActivity() {
  return {
    type: Types.COMPANIES_CLEAR_NEW_BRANCH_ACTIVITY,
  };
}

export function clearNewCompanyCovenant() {
  return {
    type: Types.COMPANIES_CLEAR_NEW_COVENANT,
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.COMPANIES_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.COMPANIES_RESET_SUB_RESOURCE_DATA,
  };
}
