import React from 'react';

import PropTypes from 'prop-types';

import { Email } from './styles';

export default function EmailIcon({ type }) {
  return <Email type={type} />;
}

EmailIcon.propTypes = {
  type: PropTypes.string,
};

EmailIcon.defaultProps = {
  type: '',
};
