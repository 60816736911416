const Types = {
  COMPANY_SUBSIDIARIES_GET: '@company/GET_COMPANY_SUBSIDIARIES',

  COMPANY_SUBSIDIARIES_CREATE_REQUEST:
    '@company/CREATE_COMPANY_SUBSIDIARY_REQUEST',
  COMPANY_SUBSIDIARIES_CREATE_SUCCESS:
    '@company/CREATE_COMPANY_SUBSIDIARY_SUCCESS',
  COMPANY_SUBSIDIARIES_CREATE_FAILURE:
    '@company/CREATE_COMPANY_SUBSIDIARY_FAILURE',

  COMPANY_SUBSIDIARIES_RESET_LIST: '@company/RESET_LIST_COMPANY_SUBSIDIARY',

  COMPANY_SUBSIDIARIES_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_SUBSIDIARY',
  COMPANY_SUBSIDIARIES_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_SUBSIDIARY',
};

export default Types;
