import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import MaterialTable, { MTableBodyRow, MTableFilterRow } from 'material-table';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import colors from '~/theme/colors';
import tableIcons from '~/utils/tableIcons';
import tableLocalization from '~/utils/tableLocalization';

import CustomToolbar from '../CustomToolbar';
import {
  Title,
  AddIcon,
  ViewIcon,
  EditIcon,
  PrintIcon,
  RefreshIcon,
} from './styles';

/* TODO: Futuramente fazer a paginacao, colocar proptypes no 'options' e handlePrint de todas as tabelas */
export default function MuiMaterialTable({
  main,
  stripedRows,
  data,
  customActions,
  titleIntl,
  exportFileName,
  handleAdd,
  handleView,
  handleEdit,
  handlePrint,
  handleRefresh,
  options,
  components,
  ...other
}) {
  const { formatMessage } = useIntl();

  const { globalList } = useSelector(state => state.settings);

  const searchTimeWhenTypingInTextEntries = globalList.find(
    item => item.setting_name === 'SEARCH_TIME_WHEN_TYPING_IN_TEXT_ENTRIES'
  );

  const tableRow = {
    '&:hover': { backgroundColor: `${colors.lighterRegular}!important` },
  };

  if (stripedRows) {
    tableRow['&:nth-child(odd)'] = { backgroundColor: `${colors.light}` };
  }

  let filterRowBackground = null;

  if (options.filtering && options.changeFilterRowBackground) {
    filterRowBackground = {
      '@global tbody tr:nth-child(1)': {
        backgroundColor: `${colors.light}`,
      },
    };
  }

  const useStyles = makeStyles({
    tableRow,
    ...filterRowBackground,
  });

  const classes = useStyles();

  const Add = handleAdd
    ? {
        icon: () => <AddIcon />,
        tooltip: formatMessage({ id: 'material-table.add' }),
        isFreeAction: true,
        onClick: (event, rowData) => handleAdd(event, rowData),
      }
    : null;

  const View = handleView
    ? {
        icon: () => <ViewIcon />,
        tooltip: formatMessage({ id: 'material-table.view-and-edit' }),
        onClick: (event, rowData) => handleView(event, rowData),
      }
    : null;

  const Edit = handleEdit
    ? {
        icon: () => <EditIcon />,
        tooltip: formatMessage({ id: 'material-table.edit' }),
        onClick: (event, rowData) => handleEdit(event, rowData),
      }
    : null;

  const Print = handlePrint
    ? {
        icon: () => <PrintIcon />,
        tooltip: formatMessage({ id: 'material-table.print' }),
        isFreeAction: true,
        onClick: (event, rowData) => handlePrint(event, rowData),
      }
    : null;

  const Refresh = handleRefresh
    ? {
        icon: () => <RefreshIcon />,
        tooltip: formatMessage({ id: 'material-table.refresh' }),
        isFreeAction: true,
        onClick: (event, rowData) => handleRefresh(event, rowData),
      }
    : null;

  return (
    <MaterialTable
      components={{
        FilterRow: props => (
          <MTableFilterRow className={classes.tableRow} {...props} />
        ),
        Row: props => <MTableBodyRow className={classes.tableRow} {...props} />,
        Toolbar: props => <CustomToolbar {...props} />,
        ...components,
      }}
      options={{
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        draggable: true,
        emptyRowsWhenPaging: true,
        exportAllData: true,
        exportButton: main,
        exportDelimiter: ';',
        exportFileName: exportFileName && formatMessage({ id: exportFileName }),
        initialPage: 0,
        loadingType: 'overlay',
        padding: 'default',
        pageSize: main ? 10 : data.length,
        pageSizeOptions: [5, 10, 20, 30, 40, 50],
        paginationType: 'stepped',
        paging: main,
        search: main,
        showFirstLastPageButtons: true,
        sorting: true,
        thirdSortClick: false,
        searchFieldAlignment: 'left',
        debounceInterval: searchTimeWhenTypingInTextEntries?.value
          ? searchTimeWhenTypingInTextEntries.value * 1000
          : 1500,
        // headerStyle: {
        //   backgroundColor: main ? colors.dark : colors.white,
        //   color: main ? colors.white : colors.dark,
        // },
        ...options,
      }}
      icons={tableIcons}
      title={<Title>{titleIntl}</Title>}
      localization={tableLocalization()}
      actions={[View, Edit, Print, Refresh, Add, ...customActions]}
      data={data}
      {...other}
    />
  );
}

MuiMaterialTable.propTypes = {
  main: PropTypes.bool,
  stripedRows: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.func,
  ]).isRequired,
  customActions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func])
  ),
  titleIntl: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  exportFileName: PropTypes.string,
  handleAdd: PropTypes.func,
  handleView: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePrint: PropTypes.func,
  handleRefresh: PropTypes.func,
  options: PropTypes.shape({
    filtering: PropTypes.bool,
    changeFilterRowBackground: PropTypes.bool,
  }),
  components: PropTypes.shape(),
};

MuiMaterialTable.defaultProps = {
  main: false,
  stripedRows: true,
  customActions: [],
  titleIntl: null,
  exportFileName: null,
  handleAdd: null,
  handleView: null,
  handleEdit: null,
  handlePrint: null,
  handleRefresh: null,
  options: {},
  components: null,
};
