import React from 'react';

import PropTypes from 'prop-types';

import { Phone } from './styles';

export default function PhoneIcon({ type }) {
  return <Phone type={type} />;
}

PhoneIcon.propTypes = {
  type: PropTypes.string,
};

PhoneIcon.defaultProps = {
  type: '',
};
