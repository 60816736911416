import React from 'react';

import PropTypes from 'prop-types';

import { Archive } from './styles';

export default function DownloadIcon({ type }) {
  return <Archive type={type} />;
}

DownloadIcon.propTypes = {
  type: PropTypes.string,
};

DownloadIcon.defaultProps = {
  type: '',
};
