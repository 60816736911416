import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

// TODO: Ver futuramente sobre o caso do params que eh passado na rota
// TODO: Em alguns casos pode ocorrer bugs
export default function RouteWrapper({
  component: Component,
  layout: Layout,
  isPrivate,
  isPublic,
  params,
  ...rest
}) {
  const { path } = rest;
  const { signed, token, selectedCompany } = useSelector(state => state.auth);

  if (!isPublic) {
    if ((!signed || !token) && isPrivate) {
      return <Redirect to="/" />;
    }

    if (
      signed &&
      token &&
      !selectedCompany &&
      path !== '/switch-company' &&
      path !== '/new-company'
    ) {
      return <Redirect to="/switch-company" />;
    }

    if (
      signed &&
      token &&
      selectedCompany &&
      (!isPrivate || path === '/switch-company' || path === '/new-company')
    ) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (params) {
          const { location } = routeProps;
          location.state = params;
        }

        return (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        );
      }}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  layout: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  params: PropTypes.string,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isPublic: false,
  component: null,
  params: null,
};
