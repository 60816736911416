import produce from 'immer';

import ContactTypes from '../contacts/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
};

export default function contactAddresses(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CONTACT_ADDRESSES_GET: {
        draft.list = action.payload.addresses;
        break;
      }

      case Types.CONTACT_ADDRESSES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case ContactTypes.CONTACTS_DETAIL_CLOSE_MODAL: {
        draft.list = [];
        break;
      }

      default:
    }
  });
}
