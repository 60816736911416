const Types = {
  INVENTORIES_GET_REQUEST: '@inventories/GET_INVENTORIES_REQUEST',
  INVENTORIES_GET_SUCCESS: '@inventories/GET_INVENTORIES_SUCCESS',
  INVENTORIES_GET_FAILURE: '@inventories/GET_INVENTORIES_FAILURE',

  INVENTORIES_CREATE_REQUEST: '@inventories/CREATE_INVENTORY_REQUEST',
  INVENTORIES_CREATE_SUCCESS: '@inventories/CREATE_INVENTORY_SUCCESS',
  INVENTORIES_CREATE_FAILURE: '@inventories/CREATE_INVENTORY_FAILURE',

  INVENTORIES_UPDATE_REQUEST: '@inventories/UPDATE_INVENTORY_REQUEST',
  INVENTORIES_UPDATE_SUCCESS: '@inventories/UPDATE_INVENTORY_SUCCESS',
  INVENTORIES_UPDATE_FAILURE: '@inventories/UPDATE_INVENTORY_FAILURE',

  INVENTORIES_DELETE_REQUEST: '@inventories/DELETE_INVENTORY_REQUEST',
  INVENTORIES_DELETE_SUCCESS: '@inventories/DELETE_INVENTORY_SUCCESS',
  INVENTORIES_DELETE_FAILURE: '@inventories/DELETE_INVENTORY_FAILURE',

  INVENTORIES_FILTER_OPEN_MODAL: '@inventories/OPEN_MODAL_FILTER_INVENTORY',
  INVENTORIES_FILTER_CLOSE_MODAL: '@inventories/CLOSE_MODAL_FILTER_INVENTORY',

  INVENTORIES_GET_DETAILS_REQUEST:
    '@inventories/GET_DETAILS_INVENTORIES_REQUEST',
  INVENTORIES_GET_DETAILS_SUCCESS:
    '@inventories/GET_DETAILS_INVENTORIES_SUCCESS',
  INVENTORIES_GET_DETAILS_FAILURE:
    '@inventories/GET_DETAILS_INVENTORIES_FAILURE',

  INVENTORIES_SET_SAVING_ON_SUB_RESOURCE:
    '@inventories/SET_SAVING_ON_SUB_RESOURCE_INVENTORY',
  INVENTORIES_RESET_SUB_RESOURCE_DATA:
    '@inventories/RESET_SUB_RESOURCE_DATA_INVENTORY',

  INVENTORIES_RESET_LIST: '@inventories/RESET_LIST_INVENTORIES',
  INVENTORIES_RESET_DETAILS: '@inventories/RESET_DETAILS_INVENTORY',
};

export default Types;
