import React from 'react';

import PropTypes from 'prop-types';

import { Star } from './styles';

export default function StarIcon({ type, active }) {
  return <Star type={type} active={active} />;
}

StarIcon.propTypes = {
  type: PropTypes.string,
  active: PropTypes.bool,
};

StarIcon.defaultProps = {
  type: '',
  active: false,
};
