import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { resetPriceListItem } from '../priceListItems/actions';
import {
  getPriceListsSuccess,
  getPriceListsFailure,
  createPriceListSuccess,
  createPriceListFailure,
  updatePriceListSuccess,
  updatePriceListFailure,
  deletePriceListSuccess,
  deletePriceListFailure,
  getDetailsPriceListSuccess,
  getDetailsPriceListFailure,
} from './actions';
import Types from './types';

export function* getPriceLists({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `price-lists${queryParams}`);

    yield put(
      getPriceListsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getPriceListsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createPriceList({ payload }) {
  try {
    const { priceList } = payload;

    const response = yield call(api.post, 'price-lists', priceList);

    yield put(createPriceListSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/price-list/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createPriceListFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePriceList({ payload }) {
  try {
    const { id, newPriceList, loadPriceListItems } = payload;

    const { data } = yield call(api.put, `price-lists/${id}`, newPriceList);

    yield put(updatePriceListSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);

    if (loadPriceListItems) {
      loadPriceListItems();
    }
  } catch (err) {
    yield put(updatePriceListFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePriceList({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `price-lists/${id}`);

    yield put(deletePriceListSuccess(id));

    history.push('/price-lists');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePriceListFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsPriceList({ payload }) {
  try {
    const { id, loadAllItems } = payload;

    const { data } = yield call(api.get, `price-lists/${id}`);

    const { shouldLoadPriceListItems } = yield select(
      state => state.priceLists
    );

    yield put(getDetailsPriceListSuccess(data));

    if (shouldLoadPriceListItems && loadAllItems) {
      loadAllItems();
    }
  } catch (err) {
    yield put(getDetailsPriceListFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsPriceList() {
  yield put(resetPriceListItem());
}

export default all([
  takeLatest(Types.PRICE_LISTS_GET_REQUEST, getPriceLists),
  takeLatest(Types.PRICE_LISTS_CREATE_REQUEST, createPriceList),
  takeLatest(Types.PRICE_LISTS_UPDATE_REQUEST, updatePriceList),
  takeLatest(Types.PRICE_LISTS_DELETE_REQUEST, deletePriceList),
  takeLatest(Types.PRICE_LISTS_GET_DETAILS_REQUEST, getDetailsPriceList),
  takeLatest(Types.PRICE_LISTS_RESET_DETAILS, resetDetailsPriceList),
]);
