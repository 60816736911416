import produce from 'immer';

import BillToPayTypes from '../billsToPay/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  billToPayCostCenterModalOpen: false,
};

export default function billToPayCostCenters(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BILL_TO_PAY_COST_CENTERS_GET: {
        draft.list = action.payload.costCenters;
        break;
      }

      case Types.BILL_TO_PAY_COST_CENTERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.costCenter);
        draft.loading = false;
        draft.billToPayCostCenterModalOpen = false;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_PAY_COST_CENTERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_UPDATE_SUCCESS: {
        const { newCostCenter } = action.payload;

        draft.list = state.list.map(costCenter => {
          return costCenter.id === newCostCenter.id
            ? newCostCenter
            : costCenter;
        });

        draft.detail = {};
        draft.loading = false;
        draft.billToPayCostCenterModalOpen = false;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_PAY_COST_CENTERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_DELETE_SUCCESS: {
        const { cost_center_id } = action.payload;

        draft.list = state.list.filter(costCenter => {
          return costCenter.id !== cost_center_id;
        });

        if (draft.detail.id && cost_center_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_PAY_COST_CENTERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.BILL_TO_PAY_COST_CENTERS_OPEN_MODAL: {
        const { cost_center_id } = action.payload;

        draft.detail = {};

        if (cost_center_id) {
          [draft.detail] = draft.list.filter(
            costCenter => costCenter.id === cost_center_id
          );
        }

        draft.billToPayCostCenterModalOpen = true;
        break;
      }
      case Types.BILL_TO_PAY_COST_CENTERS_CLOSE_MODAL: {
        draft.billToPayCostCenterModalOpen = false;
        draft.detail = {};
        break;
      }

      case BillToPayTypes.BILLS_TO_PAY_RESET_DETAILS: {
        draft.billToPayCostCenterModalOpen = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      default:
    }
  });
}
