import Types from './types';

export function createInventoryItemRequest(
  inventory_id,
  item,
  updateItemList = null
) {
  return {
    type: Types.INVENTORY_ITEMS_CREATE_REQUEST,
    payload: { inventory_id, item, updateItemList },
  };
}

export function createInventoryItemSuccess() {
  return {
    type: Types.INVENTORY_ITEMS_CREATE_SUCCESS,
  };
}

export function createInventoryItemFailure() {
  return {
    type: Types.INVENTORY_ITEMS_CREATE_FAILURE,
  };
}

export function updateInventoryItemRequest({
  inventory_id,
  item_id,
  newItem,
  updateItemList = null,
}) {
  return {
    type: Types.INVENTORY_ITEMS_UPDATE_REQUEST,
    payload: { inventory_id, item_id, newItem, updateItemList },
  };
}

export function updateInventoryItemSuccess() {
  return {
    type: Types.INVENTORY_ITEMS_UPDATE_SUCCESS,
  };
}

export function updateInventoryItemFailure() {
  return {
    type: Types.INVENTORY_ITEMS_UPDATE_FAILURE,
  };
}

export function deleteInventoryItemRequest(
  inventory_id,
  item_id,
  updateItemList = null
) {
  return {
    type: Types.INVENTORY_ITEMS_DELETE_REQUEST,
    payload: { inventory_id, item_id, updateItemList },
  };
}

export function deleteInventoryItemSuccess() {
  return {
    type: Types.INVENTORY_ITEMS_DELETE_SUCCESS,
  };
}

export function deleteInventoryItemFailure() {
  return {
    type: Types.INVENTORY_ITEMS_DELETE_FAILURE,
  };
}

export function resetListInventoryItem() {
  return {
    type: Types.INVENTORY_ITEMS_RESET_LIST,
  };
}

export function openModalInventoryItemSearch() {
  return {
    type: Types.INVENTORY_ITEMS_SEARCH_OPEN_MODAL,
  };
}

export function closeModalInventoryItemSearch() {
  return {
    type: Types.INVENTORY_ITEMS_SEARCH_CLOSE_MODAL,
  };
}

export function setInventoryItemTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
  type = null,
}) {
  return {
    type: Types.INVENTORY_ITEMS_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      type,
    },
  };
}
