import Types from './types';

export function getRuralPropertyFiles(files) {
  return {
    type: Types.RURAL_PROPERTY_FILES_GET,
    payload: { files },
  };
}

export function createRuralPropertyFileRequest(property_id, file) {
  return {
    type: Types.RURAL_PROPERTY_FILES_CREATE_REQUEST,
    payload: { property_id, file },
  };
}

export function createRuralPropertyFileSuccess(file) {
  return {
    type: Types.RURAL_PROPERTY_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createRuralPropertyFileFailure() {
  return {
    type: Types.RURAL_PROPERTY_FILES_CREATE_FAILURE,
  };
}

export function updateRuralPropertyFileRequest(property_id, file_id, newFile) {
  return {
    type: Types.RURAL_PROPERTY_FILES_UPDATE_REQUEST,
    payload: { property_id, file_id, newFile },
  };
}

export function updateRuralPropertyFileSuccess(newFile) {
  return {
    type: Types.RURAL_PROPERTY_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateRuralPropertyFileFailure() {
  return {
    type: Types.RURAL_PROPERTY_FILES_UPDATE_FAILURE,
  };
}

export function deleteRuralPropertyFileRequest(property_id, file_id) {
  return {
    type: Types.RURAL_PROPERTY_FILES_DELETE_REQUEST,
    payload: { property_id, file_id },
  };
}

export function deleteRuralPropertyFileSuccess(file_id) {
  return {
    type: Types.RURAL_PROPERTY_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteRuralPropertyFileFailure() {
  return {
    type: Types.RURAL_PROPERTY_FILES_DELETE_FAILURE,
  };
}

export function openModalRuralPropertyFile() {
  return {
    type: Types.RURAL_PROPERTY_FILES_OPEN_MODAL,
  };
}

export function closeModalRuralPropertyFile() {
  return {
    type: Types.RURAL_PROPERTY_FILES_CLOSE_MODAL,
  };
}

export function resetListRuralPropertyFile() {
  return {
    type: Types.RURAL_PROPERTY_FILES_RESET_LIST,
  };
}
