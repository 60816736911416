const Types = {
  SETTINGS_GET_REQUEST: '@settings/GET_SETTINGS_REQUEST',
  SETTINGS_GET_SUCCESS: '@settings/GET_SETTINGS_SUCCESS',
  SETTINGS_GET_FAILURE: '@settings/GET_SETTINGS_FAILURE',

  SETTINGS_UPDATE_REQUEST: '@settings/UPDATE_SETTINGS_REQUEST',
  SETTINGS_UPDATE_SUCCESS: '@settings/UPDATE_SETTINGS_SUCCESS',
  SETTINGS_UPDATE_FAILURE: '@settings/UPDATE_SETTINGS_FAILURE',

  SETTINGS_RESET_LIST: '@settings/RESET_LIST_SETTINGS',

  SETTINGS_OPEN_MODAL: '@settings/OPEN_MODAL_SETTINGS',
  SETTINGS_CLOSE_MODAL: '@settings/CLOSE_MODAL_SETTINGS',

  SETTINGS_CONFIGURE_DEFAULT_PRICE_LIST_OPEN_MODAL:
    '@settings/CONFIGURE_DEFAULT_PRICE_LIST_OPEN_MODAL',
  SETTINGS_CONFIGURE_DEFAULT_PRICE_LIST_CLOSE_MODAL:
    '@settings/CONFIGURE_DEFAULT_PRICE_LIST_CLOSE_MODAL',

  SETTINGS_SET_GLOBAL_LIST_SUCCESS:
    '@settings/SET_GLOBAL_LIST_SETTINGS_SUCCESS',
  SETTINGS_SET_GLOBAL_LIST_FAILURE:
    '@settings/SET_GLOBAL_LIST_SETTINGS_FAILURE',
  SETTINGS_RESET_GLOBAL_LIST: '@settings/RESET_GLOBAL_LIST_SETTINGS',
  SETTINGS_UPDATE_GLOBAL_SETTING: '@settings/UPDATE_GLOBAL_SETTING_SETTINGS',

  SETTINGS_CONFIGURE_DEFAULT_PAYMENT_METHOD_OPEN_MODAL:
    '@settings/CONFIGURE_DEFAULT_PAYMENT_METHOD_OPEN_MODAL',
  SETTINGS_CONFIGURE_DEFAULT_PAYMENT_METHOD_CLOSE_MODAL:
    '@settings/CONFIGURE_DEFAULT_PAYMENT_METHOD_CLOSE_MODAL',

  SETTINGS_CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_OPEN_MODAL:
    '@settings/CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_OPEN_MODAL',
  SETTINGS_CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_CLOSE_MODAL:
    '@settings/CONFIGURE_DEFAULT_PRODUCT_DISCOUNT_TYPE_CLOSE_MODAL',
};

export default Types;
