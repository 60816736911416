import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getDispatchingAgenciesSuccess,
  getDispatchingAgenciesFailure,
} from './actions';
import Types from './types';

export function* getDispatchingAgencies({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `dispatching-agencies${queryParams}`);

    yield put(
      getDispatchingAgenciesSuccess({ data, filterColumnsOrder, filterSearch })
    );
  } catch (err) {
    yield put(getDispatchingAgenciesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([
  takeLatest(Types.DISPATCHING_AGENCIES_GET_REQUEST, getDispatchingAgencies),
]);
