import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { resetFoundPerson } from '../persons/actions';
import {
  getSupervisorAddresses,
  openModalSupervisorAddress,
  resetListSupervisorAddress,
} from '../supervisorAddresses/actions';
import {
  getSupervisorEmails,
  openModalSupervisorEmail,
  resetListSupervisorEmail,
} from '../supervisorEmails/actions';
import {
  getSupervisorFiles,
  resetListSupervisorFile,
} from '../supervisorFiles/actions';
import {
  getSupervisorPhones,
  openModalSupervisorPhone,
  resetListSupervisorPhone,
} from '../supervisorPhones/actions';
import {
  getSupervisorsSuccess,
  getSupervisorsFailure,
  createSupervisorSuccess,
  createSupervisorFailure,
  deleteSupervisorSuccess,
  deleteSupervisorFailure,
  becomeSupervisorSuccess,
  becomeSupervisorFailure,
  getDetailsSupervisorSuccess,
  updateSupervisorSuccess,
  updateSupervisorFailure,
  getDetailsSupervisorFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getSupervisors({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `supervisors${queryParams}`);

    yield put(
      getSupervisorsSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getSupervisorsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createSupervisor({ payload }) {
  try {
    const { supervisor, subResourceType } = payload;

    const response = yield call(api.post, 'supervisors', supervisor);

    yield put(createSupervisorSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/supervisor/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createSupervisorFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupervisor({ payload }) {
  try {
    const { id, newSupervisor } = payload;

    const response = yield call(api.put, `supervisors/${id}`, newSupervisor);

    yield put(updateSupervisorSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupervisorFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupervisor({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `supervisors/${id}`);

    yield put(deleteSupervisorSuccess(id));

    history.push('/supervisors');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupervisorFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeSupervisor({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'SUPERVISOR';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeSupervisorSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/supervisor/${id}`);
  } catch (err) {
    yield put(becomeSupervisorFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsSupervisor({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `supervisors/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getSupervisorPhones(phones));

    yield put(getSupervisorAddresses(addresses));

    yield put(getSupervisorEmails(emails));

    yield put(getSupervisorFiles(files));

    yield put(getDetailsSupervisorSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.supervisors
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalSupervisorPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalSupervisorEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalSupervisorAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsSupervisorFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsSupervisor() {
  yield put(resetListSupervisorPhone());

  yield put(resetListSupervisorAddress());

  yield put(resetListSupervisorEmail());

  yield put(resetListSupervisorFile());
}

export default all([
  takeLatest(Types.SUPERVISORS_GET_REQUEST, getSupervisors),
  takeLatest(Types.SUPERVISORS_CREATE_REQUEST, createSupervisor),
  takeLatest(Types.SUPERVISORS_UPDATE_REQUEST, updateSupervisor),
  takeLatest(Types.SUPERVISORS_DELETE_REQUEST, deleteSupervisor),
  takeLatest(Types.SUPERVISORS_BECOME_REQUEST, becomeSupervisor),
  takeLatest(Types.SUPERVISORS_GET_DETAILS_REQUEST, getDetailsSupervisor),
  takeLatest(Types.SUPERVISORS_RESET_DETAILS, resetDetailsSupervisor),
]);
