const Types = {
  REGION_CITIES_GET: '@region/GET_REGION_CITIES',

  REGION_CITIES_CREATE_REQUEST: '@region/CREATE_REGION_CITY_REQUEST',
  REGION_CITIES_CREATE_SUCCESS: '@region/CREATE_REGION_CITY_SUCCESS',
  REGION_CITIES_CREATE_FAILURE: '@region/CREATE_REGION_CITY_FAILURE',

  REGION_CITIES_UPDATE_REQUEST: '@region/UPDATE_REGION_CITY_REQUEST',
  REGION_CITIES_UPDATE_SUCCESS: '@region/UPDATE_REGION_CITY_SUCCESS',
  REGION_CITIES_UPDATE_FAILURE: '@region/UPDATE_REGION_CITY_FAILURE',

  REGION_CITIES_DELETE_REQUEST: '@region/DELETE_REGION_CITY_REQUEST',
  REGION_CITIES_DELETE_SUCCESS: '@region/DELETE_REGION_CITY_SUCCESS',
  REGION_CITIES_DELETE_FAILURE: '@region/DELETE_REGION_CITY_FAILURE',

  REGION_CITIES_RESET_LIST: '@region/RESET_LIST_REGION_CITY',
};

export default Types;
