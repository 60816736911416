import Types from './types';

export function getEmployeeAddresses(addresses) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createEmployeeAddressRequest(employee_id, address) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_CREATE_REQUEST,
    payload: { employee_id, address },
  };
}

export function createEmployeeAddressSuccess(address) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createEmployeeAddressFailure() {
  return {
    type: Types.EMPLOYEE_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateEmployeeAddressRequest(
  employee_id,
  address_id,
  newAddress
) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_UPDATE_REQUEST,
    payload: { employee_id, address_id, newAddress },
  };
}

export function updateEmployeeAddressSuccess(newAddress) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateEmployeeAddressFailure() {
  return {
    type: Types.EMPLOYEE_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteEmployeeAddressRequest(employee_id, address_id) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_DELETE_REQUEST,
    payload: { employee_id, address_id },
  };
}

export function deleteEmployeeAddressSuccess(address_id) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteEmployeeAddressFailure() {
  return {
    type: Types.EMPLOYEE_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListEmployeeAddress() {
  return {
    type: Types.EMPLOYEE_ADDRESSES_RESET_LIST,
  };
}

export function openModalEmployeeAddress(address_id) {
  return {
    type: Types.EMPLOYEE_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalEmployeeAddress() {
  return {
    type: Types.EMPLOYEE_ADDRESSES_CLOSE_MODAL,
  };
}
