import Types from './types';

export function getOwnerEmails(emails) {
  return {
    type: Types.OWNER_EMAILS_GET,
    payload: { emails },
  };
}

export function createOwnerEmailRequest(owner_id, email) {
  return {
    type: Types.OWNER_EMAILS_CREATE_REQUEST,
    payload: { owner_id, email },
  };
}

export function createOwnerEmailSuccess(email) {
  return {
    type: Types.OWNER_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createOwnerEmailFailure() {
  return {
    type: Types.OWNER_EMAILS_CREATE_FAILURE,
  };
}

export function updateOwnerEmailRequest(owner_id, email_id, newEmail) {
  return {
    type: Types.OWNER_EMAILS_UPDATE_REQUEST,
    payload: { owner_id, email_id, newEmail },
  };
}

export function updateOwnerEmailSuccess(newEmail) {
  return {
    type: Types.OWNER_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateOwnerEmailFailure() {
  return {
    type: Types.OWNER_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteOwnerEmailRequest(owner_id, email_id) {
  return {
    type: Types.OWNER_EMAILS_DELETE_REQUEST,
    payload: { owner_id, email_id },
  };
}

export function deleteOwnerEmailSuccess(email_id) {
  return {
    type: Types.OWNER_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteOwnerEmailFailure() {
  return {
    type: Types.OWNER_EMAILS_DELETE_FAILURE,
  };
}

export function resetListOwnerEmail() {
  return {
    type: Types.OWNER_EMAILS_RESET_LIST,
  };
}

export function openModalOwnerEmail(email_id) {
  return {
    type: Types.OWNER_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalOwnerEmail() {
  return {
    type: Types.OWNER_EMAILS_CLOSE_MODAL,
  };
}
