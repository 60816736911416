import styled from 'styled-components';

import { Edit as MuiEdit } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'form':
      styles = `padding: 3px;`;
      break;
    case 'info':
      styles = ``;
      break;
    case 'avatar-button':
      styles = `padding: 2px`;
      break;
    case 'table':
      styles = `color: ${colors.darkRegular}`;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Edit = styled(MuiEdit)`
  ${({ type }) => defineStyles(type)}
`;
