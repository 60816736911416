import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCompanySubsidiarySuccess,
  createCompanySubsidiaryFailure,
} from './actions';
import Types from './types';

export function* createCompanySubsidiary({ payload }) {
  try {
    const { subsidiaryCompany } = payload;

    const response = yield call(api.post, 'companies', subsidiaryCompany);

    yield put(createCompanySubsidiarySuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCompanySubsidiaryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export default all([
  takeLatest(
    Types.COMPANY_SUBSIDIARIES_CREATE_REQUEST,
    createCompanySubsidiary
  ),
]);
