import { Plus as RFPlusIcon } from 'react-feather';

import styled from 'styled-components';

function defineStyles(type) {
  let styles;

  switch (type) {
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Plus = styled(RFPlusIcon)`
  ${({ type }) => defineStyles(type)}
`;
