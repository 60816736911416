import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSupervisorPhoneSuccess,
  createSupervisorPhoneFailure,
  updateSupervisorPhoneSuccess,
  updateSupervisorPhoneFailure,
  deleteSupervisorPhoneSuccess,
  deleteSupervisorPhoneFailure,
} from './actions';
import Types from './types';

export function* createSupervisorPhone({ payload }) {
  try {
    const { supervisor_id, phone } = payload;

    const response = yield call(
      api.post,
      `supervisors/${supervisor_id}/phones`,
      phone
    );

    yield put(createSupervisorPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSupervisorPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupervisorPhone({ payload }) {
  try {
    const { supervisor_id, phone_id, newPhone } = payload;

    const response = yield call(
      api.put,
      `supervisors/${supervisor_id}/phones/${phone_id}`,
      newPhone
    );

    yield put(updateSupervisorPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupervisorPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupervisorPhone({ payload }) {
  const { supervisor_id, phone_id } = payload;

  const phones = yield select(state => state.supervisorPhones.list);

  const phone = phones.find(data => data.id === phone_id);

  if (phone.is_main && phones.length > 1) {
    yield put(deleteSupervisorPhoneFailure());

    toast.error(<IntlMessages id="phones.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `supervisors/${supervisor_id}/phones/${phone_id}`);

    yield put(deleteSupervisorPhoneSuccess(phone_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupervisorPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.SUPERVISOR_PHONES_CREATE_REQUEST, createSupervisorPhone),
  takeLatest(Types.SUPERVISOR_PHONES_UPDATE_REQUEST, updateSupervisorPhone),
  takeLatest(Types.SUPERVISOR_PHONES_DELETE_REQUEST, deleteSupervisorPhone),
]);
