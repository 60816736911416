import styled from 'styled-components';

import { Cancel as MuiCancel } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'form':
      styles = `
        color: ${colors.error};
        padding: 3px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Cancel = styled(MuiCancel)`
  ${({ type }) => defineStyles(type)}
`;
