import React from 'react';

import companyLogo from '~/assets/img/logo/logo-company.png';
import IntlMessages from '~/utils/IntlMessages';

import { Container, BrandCompany } from './styles';

export function BrandFooter() {
  return (
    <Container>
      <BrandCompany
        src={companyLogo}
        alt={<IntlMessages id="datasmart.name" />}
        onClick={() => {
          window.open('https://www.datasmart.com.br', '_blank');
        }}
      />
    </Container>
  );
}
