const Types = {
  CUSTOMER_FILES_GET: '@customers/GET_CUSTOMER_FILES',

  CUSTOMER_FILES_CREATE_REQUEST: '@customers/CREATE_CUSTOMER_FILE_REQUEST',
  CUSTOMER_FILES_CREATE_SUCCESS: '@customers/CREATE_CUSTOMER_FILE_SUCCESS',
  CUSTOMER_FILES_CREATE_FAILURE: '@customers/CREATE_CUSTOMER_FILE_FAILURE',

  CUSTOMER_FILES_UPDATE_REQUEST: '@customers/UPDATE_CUSTOMER_FILE_REQUEST',
  CUSTOMER_FILES_UPDATE_SUCCESS: '@customers/UPDATE_CUSTOMER_FILE_SUCCESS',
  CUSTOMER_FILES_UPDATE_FAILURE: '@customers/UPDATE_CUSTOMER_FILE_FAILURE',

  CUSTOMER_FILES_DELETE_REQUEST: '@customers/DELETE_CUSTOMER_FILE_REQUEST',
  CUSTOMER_FILES_DELETE_SUCCESS: '@customers/DELETE_CUSTOMER_FILE_SUCCESS',
  CUSTOMER_FILES_DELETE_FAILURE: '@customers/DELETE_CUSTOMER_FILE_FAILURE',

  CUSTOMER_FILES_OPEN_MODAL: '@customers/OPEN_MODAL_CUSTOMER_FILE',
  CUSTOMER_FILES_CLOSE_MODAL: '@customers/CLOSE_MODAL_CUSTOMER_FILE',

  CUSTOMER_FILES_RESET_LIST: '@customers/RESET_LIST_CUSTOMER_FILE',
};

export default Types;
