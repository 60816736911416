import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCostCentersSuccess,
  getCostCentersFailure,
  createCostCenterSuccess,
  createCostCenterFailure,
  deleteCostCenterSuccess,
  deleteCostCenterFailure,
  updateCostCenterSuccess,
  updateCostCenterFailure,
} from './actions';
import Types from './types';

export function* getCostCenters() {
  try {
    const response = yield call(api.get, 'cost-centers');

    yield put(getCostCentersSuccess(response.data));
  } catch (err) {
    yield put(getCostCentersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCostCenter({ payload }) {
  try {
    const { costCenter } = payload;

    const response = yield call(api.post, 'cost-centers', costCenter);

    yield put(createCostCenterSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCostCenterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCostCenter({ payload }) {
  try {
    const { id, newCostCenter } = payload;

    const response = yield call(api.put, `cost-centers/${id}`, newCostCenter);

    yield put(updateCostCenterSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCostCenterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCostCenter({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `cost-centers/${id}`);

    yield put(deleteCostCenterSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCostCenterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.COST_CENTERS_GET_REQUEST, getCostCenters),
  takeLatest(Types.COST_CENTERS_CREATE_REQUEST, createCostCenter),
  takeLatest(Types.COST_CENTERS_UPDATE_REQUEST, updateCostCenter),
  takeLatest(Types.COST_CENTERS_DELETE_REQUEST, deleteCostCenter),
]);
