import React from 'react';

import styled from 'styled-components';

import logoPix from '~/assets/img/logo/logo-pix-icone-1024.png';
import IntlMessages from '~/utils/IntlMessages';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'button':
      styles = `
        width: 24px;
        height: 24px;
        filter: brightness(0) invert(1);
      `;
      break;

    case 'table':
      styles = `
        width: 24px;
        height: 24px;
        filter: invert(46%) sepia(0%) saturate(0%) hue-rotate(164deg) brightness(98%) contrast(93%);
      `;
      break;

    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Pix = styled.img.attrs(({ type }) => {
  let attrs = {
    alt: <IntlMessages id="pixs.brand" />,
  };

  switch (type) {
    case 'button':
      attrs = {
        ...attrs,
        src: logoPix,
      };
      break;

    default:
      attrs = {
        ...attrs,
        src: logoPix,
      };
      break;
  }

  return attrs;
})`
  ${({ type }) => defineStyles(type)}
`;
