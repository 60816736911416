const Types = {
  PROMOTER_PHONES_GET: '@promoters/GET_PROMOTER_PHONES',

  PROMOTER_PHONES_CREATE_REQUEST: '@promoters/CREATE_PROMOTER_PHONE_REQUEST',
  PROMOTER_PHONES_CREATE_SUCCESS: '@promoters/CREATE_PROMOTER_PHONE_SUCCESS',
  PROMOTER_PHONES_CREATE_FAILURE: '@promoters/CREATE_PROMOTER_PHONE_FAILURE',

  PROMOTER_PHONES_UPDATE_REQUEST: '@promoters/UPDATE_PROMOTER_PHONE_REQUEST',
  PROMOTER_PHONES_UPDATE_SUCCESS: '@promoters/UPDATE_PROMOTER_PHONE_SUCCESS',
  PROMOTER_PHONES_UPDATE_FAILURE: '@promoters/UPDATE_PROMOTER_PHONE_FAILURE',

  PROMOTER_PHONES_DELETE_REQUEST: '@promoters/DELETE_PROMOTER_PHONE_REQUEST',
  PROMOTER_PHONES_DELETE_SUCCESS: '@promoters/DELETE_PROMOTER_PHONE_SUCCESS',
  PROMOTER_PHONES_DELETE_FAILURE: '@promoters/DELETE_PROMOTER_PHONE_FAILURE',

  PROMOTER_PHONES_RESET_LIST: '@promoters/RESET_LIST_PROMOTER_PHONE',

  PROMOTER_PHONES_OPEN_MODAL: '@promoters/OPEN_MODAL_PROMOTER_PHONE',
  PROMOTER_PHONES_CLOSE_MODAL: '@promoters/CLOSE_MODAL_PROMOTER_PHONE',
};

export default Types;
