import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createOrderBillPixSuccess,
  createOrderBillPixFailure,
  updateOrderBillPixSuccess,
  updateOrderBillPixFailure,
} from './actions';
import Types from './types';

export function* createOrderBillPix({ payload }) {
  try {
    const { pix, pixType } = payload;

    const response = yield call(api.post, `/pixs`, pix);

    yield put(createOrderBillPixSuccess(response.data, pixType));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createOrderBillPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOrderBillPix({ payload }) {
  try {
    const { pix_id } = payload;

    const response = yield call(api.put, `pixs/${pix_id}`);

    yield put(updateOrderBillPixSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateOrderBillPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export default all([
  takeLatest(Types.ORDER_BILL_PIXS_CREATE_REQUEST, createOrderBillPix),
  takeLatest(Types.ORDER_BILL_PIXS_UPDATE_REQUEST, updateOrderBillPix),
]);
