import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCustomerAddressSuccess,
  createCustomerAddressFailure,
  updateCustomerAddressSuccess,
  updateCustomerAddressFailure,
  deleteCustomerAddressSuccess,
  deleteCustomerAddressFailure,
} from './actions';
import Types from './types';

export function* createCustomerAddress({ payload }) {
  try {
    const { customer_id, address } = payload;

    const response = yield call(
      api.post,
      `customers/${customer_id}/addresses`,
      address
    );

    yield put(createCustomerAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCustomerAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCustomerAddress({ payload }) {
  try {
    const { customer_id, address_id, newAddress } = payload;

    const response = yield call(
      api.put,
      `customers/${customer_id}/addresses/${address_id}`,
      newAddress
    );

    yield put(updateCustomerAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCustomerAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCustomerAddress({ payload }) {
  const { customer_id, address_id } = payload;

  const addresses = yield select(state => state.customerAddresses.list);

  const address = addresses.find(data => data.id === address_id);

  if (address.is_main && addresses.length > 1) {
    yield put(deleteCustomerAddressFailure());

    toast.error(<IntlMessages id="addresses.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `customers/${customer_id}/addresses/${address_id}`);

    yield put(deleteCustomerAddressSuccess(address_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCustomerAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.CUSTOMER_ADDRESSES_CREATE_REQUEST, createCustomerAddress),
  takeLatest(Types.CUSTOMER_ADDRESSES_UPDATE_REQUEST, updateCustomerAddress),
  takeLatest(Types.CUSTOMER_ADDRESSES_DELETE_REQUEST, deleteCustomerAddress),
]);
