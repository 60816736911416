import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Grid, IconButton, Tooltip } from '@material-ui/core';

import { ImageAvatar } from '~/components/Avatars';
import { CloseIcon } from '~/components/Icons';
import ContactDetailModal from '~/components/Modals/ContactDetailModal';
import MaterialTable from '~/components/Tables/MaterialTable';
import {
  getContactsRequest,
  openModalDetailContact,
} from '~/store/modules/contacts/actions';
import IntlMessages from '~/utils/IntlMessages';
import { sortByDistinctTwoStringValues } from '~/utils/tableSorts';

import {
  Modal,
  Container,
  Content,
  HeaderContainer,
  ButtonContainer,
  Title,
  ContactName,
  NameContainer,
  Name,
  Nickname,
} from './styles';

export default function ContactModal({ title, size, open, onClose }) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [selectedContactId, setSelectedContactId] = useState('');

  const contacts = useSelector(state => state.contacts);

  const loadContacts = useCallback(() => {
    dispatch(getContactsRequest());
  }, [dispatch]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts, dispatch]);

  const memoizedTableData = useMemo(() => {
    return contacts.list.map(contact => {
      const formattedNickname =
        contact.nickname || formatMessage({ id: 'persons.without-nickname' });

      const newData = {
        ...contact,
        formattedNickname,
      };

      return newData;
    });
  }, [contacts.list, formatMessage]);

  function renderName(rowData) {
    if (!rowData.name) {
      return <div />;
    }

    const { name, formattedNickname, nickname, files } = rowData;

    const avatar = files && files[0]?.url;

    let firstLetter;

    if (nickname) {
      firstLetter = nickname.charAt(0);
    } else {
      firstLetter = name.charAt(0);
    }

    return (
      <ContactName>
        <ImageAvatar src={avatar} alt={firstLetter} />
        <NameContainer>
          <Nickname>{formattedNickname}</Nickname>
          <Name>{name}</Name>
        </NameContainer>
      </ContactName>
    );
  }

  function customNameSearch(terms, data) {
    const { nickname, name } = data;

    const nicknameUppercase = nickname?.toUpperCase().trim();
    const nameUppercase = name?.toUpperCase().trim();

    const searchedTermsUppercase = terms.toUpperCase().trim();

    if (
      !nicknameUppercase?.includes(searchedTermsUppercase) &&
      !nameUppercase.includes(searchedTermsUppercase)
    ) {
      return false;
    }

    return data;
  }

  const columns = [
    {
      title: formatMessage({ id: 'persons.name' }),
      field: 'name',
      render: renderName,
      customFilterAndSearch: customNameSearch,
      customSort: (rowA, rowB) =>
        sortByDistinctTwoStringValues(
          rowA.nickname,
          rowB.nickname,
          rowA.name,
          rowB.name
        ),
      defaultSort: 'asc',
    },
  ];

  return (
    <>
      <Modal open={open} onBackdropClick={onClose}>
        <Container size={size}>
          <HeaderContainer>
            <Grid item xs={2} />

            <Grid item xs={8}>
              <Title>{title}</Title>
            </Grid>

            <Grid item xs={2}>
              <ButtonContainer>
                <Tooltip arrow title={<IntlMessages id="buttons.close" />}>
                  <IconButton onClick={onClose}>
                    <CloseIcon type="modal" />
                  </IconButton>
                </Tooltip>
              </ButtonContainer>
            </Grid>
          </HeaderContainer>

          <Content>
            <MaterialTable
              main
              isLoading={contacts.loading}
              columns={columns}
              data={memoizedTableData}
              handleRefresh={loadContacts}
              options={{
                exportButton: false,
              }}
              onRowClick={(event, rowData) => {
                setSelectedContactId(rowData.id);
                dispatch(openModalDetailContact('CONTACT'));
              }}
              // handlePrint={handlePrint}
            />
          </Content>
        </Container>
      </Modal>

      {contacts.contactDetailModalOpen &&
        contacts.detailModalSource === 'CONTACT' && (
          <ContactDetailModal
            open={contacts.contactDetailModalOpen}
            contactId={selectedContactId}
            title={formatMessage({ id: 'contacts.contact-detail.title' })}
          />
        )}
    </>
  );
}

ContactModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  size: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ContactModal.defaultProps = {
  size: 'medium',
};
