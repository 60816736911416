import Types from './types';

export function getSupervisorFiles(files) {
  return {
    type: Types.SUPERVISOR_FILES_GET,
    payload: { files },
  };
}

export function createSupervisorFileRequest(supervisor_id, file) {
  return {
    type: Types.SUPERVISOR_FILES_CREATE_REQUEST,
    payload: { supervisor_id, file },
  };
}

export function createSupervisorFileSuccess(file) {
  return {
    type: Types.SUPERVISOR_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createSupervisorFileFailure() {
  return {
    type: Types.SUPERVISOR_FILES_CREATE_FAILURE,
  };
}

export function updateSupervisorFileRequest(supervisor_id, file_id, newFile) {
  return {
    type: Types.SUPERVISOR_FILES_UPDATE_REQUEST,
    payload: { supervisor_id, file_id, newFile },
  };
}

export function updateSupervisorFileSuccess(newFile) {
  return {
    type: Types.SUPERVISOR_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateSupervisorFileFailure() {
  return {
    type: Types.SUPERVISOR_FILES_UPDATE_FAILURE,
  };
}

export function deleteSupervisorFileRequest(supervisor_id, file_id) {
  return {
    type: Types.SUPERVISOR_FILES_DELETE_REQUEST,
    payload: { supervisor_id, file_id },
  };
}

export function deleteSupervisorFileSuccess(file_id) {
  return {
    type: Types.SUPERVISOR_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteSupervisorFileFailure() {
  return {
    type: Types.SUPERVISOR_FILES_DELETE_FAILURE,
  };
}

export function openModalSupervisorFile() {
  return {
    type: Types.SUPERVISOR_FILES_OPEN_MODAL,
  };
}

export function closeModalSupervisorFile() {
  return {
    type: Types.SUPERVISOR_FILES_CLOSE_MODAL,
  };
}

export function resetListSupervisorFile() {
  return {
    type: Types.SUPERVISOR_FILES_RESET_LIST,
  };
}
