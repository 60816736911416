import React from 'react';

import PropTypes from 'prop-types';

import { Chip } from './styles';

export default function CustomChip({ label, chipColor, clickable, ...rest }) {
  const chipStyle = {
    mb: 1,
    mr: 1,
    size: 'small',
    ...rest,
  };

  return (
    <Chip
      label={label}
      chipColor={chipColor}
      clickable={clickable}
      {...chipStyle}
    />
  );
}

CustomChip.propTypes = {
  clickable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  chipColor: PropTypes.string,
};

CustomChip.defaultProps = {
  clickable: false,
  label: '',
  chipColor: null,
};
