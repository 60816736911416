import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  filter: [],
};

export default function profile(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COMPANY_PROFILE_ADD_FILTER: {
        const { type } = action.payload;

        const findEqualType = state.filter.find(
          item => item.value === type.value
        );

        if (!findEqualType) {
          draft.filter.push(type);
        }

        if (type.replaceValues) {
          const filteredByType = state.filter.filter(
            item => item.fromGroup !== type.fromGroup
          );

          draft.filter = [...filteredByType, type];
        }

        break;
      }

      case Types.COMPANY_PROFILE_REMOVE_FILTER: {
        const { type } = action.payload;

        draft.filter = state.filter.filter(item => item.value !== type);

        break;
      }
      default:
    }
  });
}
