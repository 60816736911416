import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  updateRegionSellerSuccess,
  updateRegionSellerFailure,
  deleteRegionSellerSuccess,
  deleteRegionSellerFailure,
} from './actions';
import Types from './types';

export function* updateRegionSeller({ payload }) {
  try {
    const { region_id, sellers } = payload;

    const response = yield call(
      api.put,
      `regions/${region_id}/sellers`,
      sellers
    );

    yield put(updateRegionSellerSuccess(response.data));

    toast.success(<IntlMessages id="messages.change-success" />);
  } catch (err) {
    yield put(updateRegionSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.change-failure" />);
  }
}

export function* deleteRegionSeller({ payload }) {
  try {
    const { region_id, region_seller_id } = payload;
    yield call(api.delete, `regions/${region_id}/sellers/${region_seller_id}`);

    yield put(deleteRegionSellerSuccess(region_seller_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRegionSellerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.REGION_SELLERS_UPDATE_REQUEST, updateRegionSeller),
  takeLatest(Types.REGION_SELLERS_DELETE_REQUEST, deleteRegionSeller),
]);
