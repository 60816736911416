const Types = {
  NCMS_GET_REQUEST: '@ncms/GET_NCMS_REQUEST',
  NCMS_GET_SUCCESS: '@ncms/GET_NCMS_SUCCESS',
  NCMS_GET_FAILURE: '@ncms/GET_NCMS_FAILURE',

  NCMS_RESET_LIST: '@ncms/RESET_LIST_NCMS',

  NCMS_FILTER_OPEN_MODAL: '@ncms/OPEN_MODAL_FILTER_NCM',
  NCMS_FILTER_CLOSE_MODAL: '@ncms/CLOSE_MODAL_FILTER_NCM',
};

export default Types;
