import React from 'react';
import { toast } from 'react-toastify';

import { isNetworkError } from '~/utils/checkNetwork';
import IntlMessages from '~/utils/IntlMessages';

export function isShownAPIErrorMessages(error) {
  if (isNetworkError(error)) {
    toast.error(
      <IntlMessages id="messages.error-500.without-connection-server" />
    );
    return true;
  }

  if (error.response.status === 500) {
    const { message } = error.response.data.error;

    if (message.startsWith('connect ECONNREFUSED')) {
      toast.error(
        <IntlMessages id="messages.error-500.database-connection-error" />
      );
    } else
      toast.error(
        <IntlMessages id="messages.error-500.internal-server-error" />
      );

    return true;
  }

  if (error.response.status === 504) {
    toast.error(<IntlMessages id="messages.error-504.gateway-timeout" />);

    return true;
  }

  if (error.response.status === 400 || error.response.status === 401) {
    const { error: errorMessage } = error.response.data;

    toast.error(`${errorMessage} 🤔`);
    return true;
  }

  return false;
}
