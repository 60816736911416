import Types from './types';

export function getCustomerAddresses(addresses) {
  return {
    type: Types.CUSTOMER_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createCustomerAddressRequest(customer_id, address) {
  return {
    type: Types.CUSTOMER_ADDRESSES_CREATE_REQUEST,
    payload: { customer_id, address },
  };
}

export function createCustomerAddressSuccess(address) {
  return {
    type: Types.CUSTOMER_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createCustomerAddressFailure() {
  return {
    type: Types.CUSTOMER_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateCustomerAddressRequest(
  customer_id,
  address_id,
  newAddress
) {
  return {
    type: Types.CUSTOMER_ADDRESSES_UPDATE_REQUEST,
    payload: { customer_id, address_id, newAddress },
  };
}

export function updateCustomerAddressSuccess(newAddress) {
  return {
    type: Types.CUSTOMER_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateCustomerAddressFailure() {
  return {
    type: Types.CUSTOMER_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteCustomerAddressRequest(customer_id, address_id) {
  return {
    type: Types.CUSTOMER_ADDRESSES_DELETE_REQUEST,
    payload: { customer_id, address_id },
  };
}

export function deleteCustomerAddressSuccess(address_id) {
  return {
    type: Types.CUSTOMER_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteCustomerAddressFailure() {
  return {
    type: Types.CUSTOMER_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListCustomerAddress() {
  return {
    type: Types.CUSTOMER_ADDRESSES_RESET_LIST,
  };
}

export function openModalCustomerAddress(address_id) {
  return {
    type: Types.CUSTOMER_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalCustomerAddress() {
  return {
    type: Types.CUSTOMER_ADDRESSES_CLOSE_MODAL,
  };
}
