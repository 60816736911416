import Types from './types';

export function getSupervisorsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.SUPERVISORS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getSupervisorsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.SUPERVISORS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getSupervisorsFailure() {
  return {
    type: Types.SUPERVISORS_GET_FAILURE,
  };
}

export function createSupervisorRequest(supervisor, subResourceType = null) {
  return {
    type: Types.SUPERVISORS_CREATE_REQUEST,
    payload: { supervisor, subResourceType },
  };
}

export function createSupervisorSuccess(supervisor) {
  return {
    type: Types.SUPERVISORS_CREATE_SUCCESS,
    payload: { supervisor },
  };
}

export function createSupervisorFailure() {
  return {
    type: Types.SUPERVISORS_CREATE_FAILURE,
  };
}

export function updateSupervisorRequest(id, newSupervisor) {
  return {
    type: Types.SUPERVISORS_UPDATE_REQUEST,
    payload: { id, newSupervisor },
  };
}

export function updateSupervisorSuccess(newSupervisor) {
  return {
    type: Types.SUPERVISORS_UPDATE_SUCCESS,
    payload: { newSupervisor },
  };
}

export function updateSupervisorFailure() {
  return {
    type: Types.SUPERVISORS_UPDATE_FAILURE,
  };
}

export function deleteSupervisorRequest(id) {
  return {
    type: Types.SUPERVISORS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteSupervisorSuccess(id) {
  return {
    type: Types.SUPERVISORS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteSupervisorFailure() {
  return {
    type: Types.SUPERVISORS_DELETE_FAILURE,
  };
}

export function becomeSupervisorRequest(id) {
  return {
    type: Types.SUPERVISORS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeSupervisorSuccess(supervisor) {
  return {
    type: Types.SUPERVISORS_BECOME_SUCCESS,
    payload: { supervisor },
  };
}

export function becomeSupervisorFailure() {
  return {
    type: Types.SUPERVISORS_BECOME_FAILURE,
  };
}

export function getDetailsSupervisorRequest(id) {
  return {
    type: Types.SUPERVISORS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsSupervisorSuccess(supervisor) {
  return {
    type: Types.SUPERVISORS_GET_DETAILS_SUCCESS,
    payload: { supervisor },
  };
}

export function getDetailsSupervisorFailure() {
  return {
    type: Types.SUPERVISORS_GET_DETAILS_FAILURE,
  };
}

export function resetListSupervisors() {
  return {
    type: Types.SUPERVISORS_RESET_LIST,
  };
}

export function resetDetailsSupervisor() {
  return {
    type: Types.SUPERVISORS_RESET_DETAILS,
  };
}

export function openModalAddSupervisor() {
  return {
    type: Types.SUPERVISORS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddSupervisor() {
  return {
    type: Types.SUPERVISORS_ADD_CLOSE_MODAL,
  };
}

export function openModalSupervisorObservation() {
  return {
    type: Types.SUPERVISORS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalSupervisorObservation() {
  return {
    type: Types.SUPERVISORS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalSupervisorFilter() {
  return {
    type: Types.SUPERVISORS_FILTER_OPEN_MODAL,
  };
}

export function closeModalSupervisorFilter() {
  return {
    type: Types.SUPERVISORS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.SUPERVISORS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.SUPERVISORS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.SUPERVISORS_RESET_SUB_RESOURCE_DATA,
  };
}
