import styled from 'styled-components';

import { Avatar as MuiAvatar } from '@material-ui/core';

import colors from '~/theme/colors';

const Avatar = styled(MuiAvatar)`
  background: ${colors.darkRegular};
`;

export default Avatar;
