import Types from './types';

export function getEmployeeFiles(files) {
  return {
    type: Types.EMPLOYEE_FILES_GET,
    payload: { files },
  };
}

export function createEmployeeFileRequest(employee_id, file) {
  return {
    type: Types.EMPLOYEE_FILES_CREATE_REQUEST,
    payload: { employee_id, file },
  };
}

export function createEmployeeFileSuccess(file) {
  return {
    type: Types.EMPLOYEE_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createEmployeeFileFailure() {
  return {
    type: Types.EMPLOYEE_FILES_CREATE_FAILURE,
  };
}

export function updateEmployeeFileRequest(employee_id, file_id, newFile) {
  return {
    type: Types.EMPLOYEE_FILES_UPDATE_REQUEST,
    payload: { employee_id, file_id, newFile },
  };
}

export function updateEmployeeFileSuccess(newFile) {
  return {
    type: Types.EMPLOYEE_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateEmployeeFileFailure() {
  return {
    type: Types.EMPLOYEE_FILES_UPDATE_FAILURE,
  };
}

export function deleteEmployeeFileRequest(employee_id, file_id) {
  return {
    type: Types.EMPLOYEE_FILES_DELETE_REQUEST,
    payload: { employee_id, file_id },
  };
}

export function deleteEmployeeFileSuccess(file_id) {
  return {
    type: Types.EMPLOYEE_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteEmployeeFileFailure() {
  return {
    type: Types.EMPLOYEE_FILES_DELETE_FAILURE,
  };
}

export function openModalEmployeeFile() {
  return {
    type: Types.EMPLOYEE_FILES_OPEN_MODAL,
  };
}

export function closeModalEmployeeFile() {
  return {
    type: Types.EMPLOYEE_FILES_CLOSE_MODAL,
  };
}

export function resetListEmployeeFile() {
  return {
    type: Types.EMPLOYEE_FILES_RESET_LIST,
  };
}
