const Types = {
  BILL_TO_RECEIVE_PIXS_GET: '@billToReceive/GET_BILL_TO_RECEIVE_PIXS',

  BILL_TO_RECEIVE_PIXS_CREATE_REQUEST:
    '@billToReceive/CREATE_BILL_TO_RECEIVE_PIX_REQUEST',
  BILL_TO_RECEIVE_PIXS_CREATE_SUCCESS:
    '@billToReceive/CREATE_BILL_TO_RECEIVE_PIX_SUCCESS',
  BILL_TO_RECEIVE_PIXS_CREATE_FAILURE:
    '@billToReceive/CREATE_BILL_TO_RECEIVE_PIX_FAILURE',

  BILL_TO_RECEIVE_PIXS_UPDATE_REQUEST:
    '@billToReceive/UPDATE_BILL_TO_RECEIVE_PIX_REQUEST',
  BILL_TO_RECEIVE_PIXS_UPDATE_SUCCESS:
    '@billToReceive/UPDATE_BILL_TO_RECEIVE_PIX_SUCCESS',
  BILL_TO_RECEIVE_PIXS_UPDATE_FAILURE:
    '@billToReceive/UPDATE_BILL_TO_RECEIVE_PIX_FAILURE',

  BILL_TO_RECEIVE_PIXS_SETTLEMENT_REQUEST:
    '@billToReceive/SETTLEMENT_BILL_TO_RECEIVE_PIX_REQUEST',
  BILL_TO_RECEIVE_PIXS_SETTLEMENT_SUCCESS:
    '@billToReceive/SETTLEMENT_BILL_TO_RECEIVE_PIX_SUCCESS',
  BILL_TO_RECEIVE_PIXS_SETTLEMENT_FAILURE:
    '@billToReceive/SETTLEMENT_BILL_TO_RECEIVE_PIX_FAILURE',

  BILL_TO_RECEIVE_PIXS_DELETE_REQUEST:
    '@billToReceive/DELETE_BILL_TO_RECEIVE_PIX_REQUEST',
  BILL_TO_RECEIVE_PIXS_DELETE_SUCCESS:
    '@billToReceive/DELETE_BILL_TO_RECEIVE_PIX_SUCCESS',
  BILL_TO_RECEIVE_PIXS_DELETE_FAILURE:
    '@billToReceive/DELETE_BILL_TO_RECEIVE_PIX_FAILURE',

  BILL_TO_RECEIVE_PIXS_RESET_LIST:
    '@billToReceive/RESET_LIST_BILL_TO_RECEIVE_PIX',

  BILL_TO_RECEIVE_PIXS_OPEN_MODAL:
    '@billToReceive/OPEN_MODAL_BILL_TO_RECEIVE_PIX',
  BILL_TO_RECEIVE_PIXS_CLOSE_MODAL:
    '@billToReceive/CLOSE_MODAL_BILL_TO_RECEIVE_PIX',

  BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_OPEN_MODAL:
    '@billToReceive/OPEN_MODAL_BILL_TO_RECEIVE_PIX_ADD_ACCOUNT',
  BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_CLOSE_MODAL:
    '@billToReceive/CLOSE_MODAL_BILL_TO_RECEIVE_PIX_ADD_ACCOUNT',
  BILL_TO_RECEIVE_PIXS_SET_SELECTED_ACCOUNT:
    '@billToReceive/SET_SELECTED_ACCOUNT_BILL_TO_RECEIVE_PIX',

  BILL_TO_RECEIVE_PIXS_DETAIL_OPEN_MODAL:
    '@billToReceive/OPEN_MODAL_BILL_TO_RECEIVE_PIX_DETAIL',
  BILL_TO_RECEIVE_PIXS_DETAIL_CLOSE_MODAL:
    '@billToReceive/CLOSE_MODAL_BILL_TO_RECEIVE_PIX_DETAIL',

  BILL_TO_RECEIVE_PIXS_SHARE_OPEN_MODAL:
    '@billToReceive/OPEN_MODAL_BILL_TO_RECEIVE_PIX_SHARE',
  BILL_TO_RECEIVE_PIXS_SHARE_CLOSE_MODAL:
    '@billToReceive/CLOSE_MODAL_BILL_TO_RECEIVE_PIX_SHARE',

  BILL_TO_RECEIVE_PIXS_SETTLEMENT_OPEN_MODAL:
    '@billToReceive/OPEN_MODAL_BILL_TO_RECEIVE_PIX_SETTLEMENT',
  BILL_TO_RECEIVE_PIXS_SETTLEMENT_CLOSE_MODAL:
    '@billToReceive/CLOSE_MODAL_BILL_TO_RECEIVE_PIX_SETTLEMENT',
};

export default Types;
