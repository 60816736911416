const Types = {
  RURAL_PROPERTY_FILES_GET: '@ruralProperty/GET_RURAL_PROPERTY_FILES',

  RURAL_PROPERTY_FILES_CREATE_REQUEST:
    '@ruralProperty/CREATE_RURAL_PROPERTY_FILE_REQUEST',
  RURAL_PROPERTY_FILES_CREATE_SUCCESS:
    '@ruralProperty/CREATE_RURAL_PROPERTY_FILE_SUCCESS',
  RURAL_PROPERTY_FILES_CREATE_FAILURE:
    '@ruralProperty/CREATE_RURAL_PROPERTY_FILE_FAILURE',

  RURAL_PROPERTY_FILES_UPDATE_REQUEST:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_FILE_REQUEST',
  RURAL_PROPERTY_FILES_UPDATE_SUCCESS:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_FILE_SUCCESS',
  RURAL_PROPERTY_FILES_UPDATE_FAILURE:
    '@ruralProperty/UPDATE_RURAL_PROPERTY_FILE_FAILURE',

  RURAL_PROPERTY_FILES_DELETE_REQUEST:
    '@ruralProperty/DELETE_RURAL_PROPERTY_FILE_REQUEST',
  RURAL_PROPERTY_FILES_DELETE_SUCCESS:
    '@ruralProperty/DELETE_RURAL_PROPERTY_FILE_SUCCESS',
  RURAL_PROPERTY_FILES_DELETE_FAILURE:
    '@ruralProperty/DELETE_RURAL_PROPERTY_FILE_FAILURE',

  RURAL_PROPERTY_FILES_OPEN_MODAL:
    '@ruralProperty/OPEN_MODAL_RURAL_PROPERTY_FILE',
  RURAL_PROPERTY_FILES_CLOSE_MODAL:
    '@ruralProperty/CLOSE_MODAL_RURAL_PROPERTY_FILE',

  RURAL_PROPERTY_FILES_RESET_LIST:
    '@ruralProperty/RESET_LIST_RURAL_PROPERTY_FILE',
};

export default Types;
