import Types from './types';

export function getCompanySubsidiaries(subsdiaries) {
  return {
    type: Types.COMPANY_SUBSIDIARIES_GET,
    payload: { subsdiaries },
  };
}

export function createCompanySubsidiaryRequest(company_id, subsidiaryCompany) {
  return {
    type: Types.COMPANY_SUBSIDIARIES_CREATE_REQUEST,
    payload: { company_id, subsidiaryCompany },
  };
}

export function createCompanySubsidiarySuccess(subsidiaryCompany) {
  return {
    type: Types.COMPANY_SUBSIDIARIES_CREATE_SUCCESS,
    payload: { subsidiaryCompany },
  };
}

export function createCompanySubsidiaryFailure() {
  return {
    type: Types.COMPANY_SUBSIDIARIES_CREATE_FAILURE,
  };
}

export function resetListCompanySubsidiary() {
  return {
    type: Types.COMPANY_SUBSIDIARIES_RESET_LIST,
  };
}

export function openModalCompanySubsidiary(superiorCompanyId) {
  return {
    type: Types.COMPANY_SUBSIDIARIES_OPEN_MODAL,
    payload: { superiorCompanyId },
  };
}

export function closeModalCompanySubsidiary() {
  return {
    type: Types.COMPANY_SUBSIDIARIES_CLOSE_MODAL,
  };
}
