import Types from './types';

export function createOrderBillPixRequest(pix, pixType) {
  return {
    type: Types.ORDER_BILL_PIXS_CREATE_REQUEST,
    payload: { pix, pixType },
  };
}

export function createOrderBillPixSuccess(pix, pixType) {
  return {
    type: Types.ORDER_BILL_PIXS_CREATE_SUCCESS,
    payload: { pix, pixType },
  };
}

export function createOrderBillPixFailure() {
  return {
    type: Types.ORDER_BILL_PIXS_CREATE_FAILURE,
  };
}

export function updateOrderBillPixRequest(pix_id) {
  return {
    type: Types.ORDER_BILL_PIXS_UPDATE_REQUEST,
    payload: { pix_id },
  };
}

export function updateOrderBillPixSuccess(newPix) {
  return {
    type: Types.ORDER_BILL_PIXS_UPDATE_SUCCESS,
    payload: { newPix },
  };
}

export function updateOrderBillPixFailure() {
  return {
    type: Types.ORDER_BILL_PIXS_UPDATE_FAILURE,
  };
}

export function resetListOrderBillPix() {
  return {
    type: Types.ORDER_BILL_PIXS_RESET_LIST,
  };
}

export function openModalOrderBillPix(order = null) {
  return {
    type: Types.ORDER_BILL_PIXS_OPEN_MODAL,
    payload: { order },
  };
}

export function closeModalOrderBillPix() {
  return {
    type: Types.ORDER_BILL_PIXS_CLOSE_MODAL,
  };
}

export function openModalOrderBillEmitPix({ id, type }) {
  return {
    type: Types.ORDER_BILL_PIXS_EMIT_OPEN_MODAL,
    payload: { id, type },
  };
}

export function closeModalOrderBillEmitPix() {
  return {
    type: Types.ORDER_BILL_PIXS_EMIT_CLOSE_MODAL,
  };
}

export function openModalOrderBillAddAccountPix() {
  return {
    type: Types.ORDER_BILL_PIXS_ADD_ACCOUNT_OPEN_MODAL,
  };
}

export function closeModalOrderBillAddAccountPix() {
  return {
    type: Types.ORDER_BILL_PIXS_ADD_ACCOUNT_CLOSE_MODAL,
  };
}

export function setSelectedAccountOrderBillPix(account) {
  return {
    type: Types.ORDER_BILL_PIXS_SET_SELECTED_ACCOUNT,
    payload: { account },
  };
}

export function openModalOrderBillPixDetail(id, pix = null) {
  return {
    type: Types.ORDER_BILL_PIXS_DETAIL_OPEN_MODAL,
    payload: { id, pix },
  };
}

export function closeModalOrderBillToPixDetail() {
  return {
    type: Types.ORDER_BILL_PIXS_DETAIL_CLOSE_MODAL,
  };
}

export function openModalOrderBillPixShare(id, pix = null) {
  return {
    type: Types.ORDER_BILL_PIXS_SHARE_OPEN_MODAL,
    payload: { id, pix },
  };
}

export function closeModalOrderBillPixShare() {
  return {
    type: Types.ORDER_BILL_PIXS_SHARE_CLOSE_MODAL,
  };
}
