import React, { useState } from 'react';

import { Menu, MenuItem } from '@material-ui/core';

import brFlag from '~/assets/img/flags/br.png';
import esFlag from '~/assets/img/flags/es.png';
import usFlag from '~/assets/img/flags/us.png';
import IntlMessages from '~/utils/IntlMessages';

import { IconButton } from '../styles';
import { Flag } from './styles';

/* TODO: Fazer as mudancas de idiomas ao clicar nos itens */
export default function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = Boolean(anchorMenu);

  return (
    <>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={event => setAnchorMenu(event.currentTarget)}
      >
        <Flag src={brFlag} alt={<IntlMessages id="header.language" />} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={open}
        onClose={() => setAnchorMenu(null)}
      >
        <MenuItem onClick={() => setAnchorMenu(null)}>
          <Flag
            margin
            src={brFlag}
            alt={<IntlMessages id="header.language.pt-br" />}
          />
          <IntlMessages id="header.language.pt-br" />
        </MenuItem>
        <MenuItem onClick={() => setAnchorMenu(null)}>
          <Flag
            margin
            src={usFlag}
            alt={<IntlMessages id="header.language.en-us" />}
          />
          <IntlMessages id="header.language.en-us" />
        </MenuItem>
        <MenuItem onClick={() => setAnchorMenu(null)}>
          <Flag
            margin
            src={esFlag}
            alt={<IntlMessages id="header.language.es-es" />}
          />
          <IntlMessages id="header.language.es-es" />
        </MenuItem>
      </Menu>
    </>
  );
}
