const Types = {
  CATEGORIES_GET_REQUEST: '@categories/GET_CATEGORIES_REQUEST',
  CATEGORIES_GET_SUCCESS: '@categories/GET_CATEGORIES_SUCCESS',
  CATEGORIES_GET_FAILURE: '@categories/GET_CATEGORIES_FAILURE',

  CATEGORIES_CREATE_REQUEST: '@categories/CREATE_CATEGORY_REQUEST',
  CATEGORIES_CREATE_SUCCESS: '@categories/CREATE_CATEGORY_SUCCESS',
  CATEGORIES_CREATE_FAILURE: '@categories/CREATE_CATEGORY_FAILURE',

  CATEGORIES_UPDATE_REQUEST: '@categories/UPDATE_CATEGORY_REQUEST',
  CATEGORIES_UPDATE_SUCCESS: '@categories/UPDATE_CATEGORY_SUCCESS',
  CATEGORIES_UPDATE_FAILURE: '@categories/UPDATE_CATEGORY_FAILURE',

  CATEGORIES_DELETE_REQUEST: '@categories/DELETE_CATEGORY_REQUEST',
  CATEGORIES_DELETE_SUCCESS: '@categories/DELETE_CATEGORY_SUCCESS',
  CATEGORIES_DELETE_FAILURE: '@categories/DELETE_CATEGORY_FAILURE',

  CATEGORIES_RESET_LIST: '@categories/RESET_LIST_CATEGORIES',

  CATEGORIES_OPEN_MODAL: '@categories/OPEN_MODAL_CATEGORY',
  CATEGORIES_CLOSE_MODAL: '@categories/CLOSE_MODAL_CATEGORY',
};

export default Types;
