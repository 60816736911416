const Types = {
  EMPLOYEE_FILES_GET: '@employees/GET_EMPLOYEE_FILES',

  EMPLOYEE_FILES_CREATE_REQUEST: '@employees/CREATE_EMPLOYEE_FILE_REQUEST',
  EMPLOYEE_FILES_CREATE_SUCCESS: '@employees/CREATE_EMPLOYEE_FILE_SUCCESS',
  EMPLOYEE_FILES_CREATE_FAILURE: '@employees/CREATE_EMPLOYEE_FILE_FAILURE',

  EMPLOYEE_FILES_UPDATE_REQUEST: '@employees/UPDATE_EMPLOYEE_FILE_REQUEST',
  EMPLOYEE_FILES_UPDATE_SUCCESS: '@employees/UPDATE_EMPLOYEE_FILE_SUCCESS',
  EMPLOYEE_FILES_UPDATE_FAILURE: '@employees/UPDATE_EMPLOYEE_FILE_FAILURE',

  EMPLOYEE_FILES_DELETE_REQUEST: '@employees/DELETE_EMPLOYEE_FILE_REQUEST',
  EMPLOYEE_FILES_DELETE_SUCCESS: '@employees/DELETE_EMPLOYEE_FILE_SUCCESS',
  EMPLOYEE_FILES_DELETE_FAILURE: '@employees/DELETE_EMPLOYEE_FILE_FAILURE',

  EMPLOYEE_FILES_OPEN_MODAL: '@employees/OPEN_MODAL_EMPLOYEE_FILE',
  EMPLOYEE_FILES_CLOSE_MODAL: '@employees/CLOSE_MODAL_EMPLOYEE_FILE',

  EMPLOYEE_FILES_RESET_LIST: '@employees/RESET_LIST_EMPLOYEE_FILE',
};

export default Types;
