import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,

  subResourceType: null,
  shouldOpenSubResourceModal: false,

  regionRowDataModalOpen: false,
  rowDataDetail: {},
};

export default function regions(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.REGIONS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.REGIONS_GET_SUCCESS: {
        const { data } = action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        break;
      }
      case Types.REGIONS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGIONS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGIONS_CREATE_SUCCESS: {
        const { region } = action.payload;

        draft.list.unshift(region);
        draft.loading = false;
        break;
      }
      case Types.REGIONS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGIONS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGIONS_UPDATE_SUCCESS: {
        const { newRegion } = action.payload;
        draft.detail = newRegion;

        draft.list = state.list.map(region =>
          region.id === newRegion.id ? newRegion : region
        );
        draft.loading = false;
        break;
      }
      case Types.REGIONS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGIONS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGIONS_DELETE_SUCCESS: {
        const { id } = action.payload;

        draft.list = state.list.filter(region => region.id !== id);
        draft.loading = false;
        break;
      }
      case Types.REGIONS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGIONS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.REGIONS_GET_DETAILS_SUCCESS: {
        const { region } = action.payload;

        draft.detail = region;
        draft.requestError = false;
        break;
      }
      case Types.REGIONS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.REGIONS_ROW_DATA_OPEN_MODAL: {
        const { rowData } = action.payload;

        draft.regionRowDataModalOpen = true;
        draft.rowDataDetail = rowData;
        break;
      }
      case Types.REGIONS_ROW_DATA_CLOSE_MODAL: {
        draft.regionRowDataModalOpen = false;
        draft.rowDataDetail = {};
        break;
      }

      case Types.REGIONS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        break;
      }
      case Types.REGIONS_RESET_DETAIL: {
        draft.detail = {};
        draft.requestError = false;
        draft.loading = false;
        break;
      }

      case Types.REGIONS_SET_SAVING_ON_SUB_RESOURCE: {
        const { subResourceType } = action.payload;

        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = subResourceType;
        break;
      }
      case Types.REGIONS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      default:
    }
  });
}
