import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSellerFileSuccess,
  createSellerFileFailure,
  updateSellerFileSuccess,
  updateSellerFileFailure,
  deleteSellerFileSuccess,
  deleteSellerFileFailure,
} from './actions';
import Types from './types';

export function* createSellerFile({ payload }) {
  try {
    const { seller_id, file } = payload;

    const response = yield call(api.post, `sellers/${seller_id}/files`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(createSellerFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSellerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSellerFile({ payload }) {
  try {
    const { seller_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `sellers/${seller_id}/files/${file_id}`,
      newFile
    );

    yield put(updateSellerFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSellerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSellerFile({ payload }) {
  try {
    const { seller_id, file_id } = payload;

    yield call(api.delete, `sellers/${seller_id}/files/${file_id}`);

    yield put(deleteSellerFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSellerFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.SELLER_FILES_CREATE_REQUEST, createSellerFile),
  takeLatest(Types.SELLER_FILES_UPDATE_REQUEST, updateSellerFile),
  takeLatest(Types.SELLER_FILES_DELETE_REQUEST, deleteSellerFile),
]);
