import styled from 'styled-components';

import { Email as MuiEmail } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'table':
      styles = `
        color: ${colors.darkerInfo};
        height: 20px;
        width: 20px;
      `;
      break;
    case 'modal':
      styles = `
        color: ${colors.darkerInfo};
        height: 20px;
        width: 20px;
      `;
      break;
    case 'card':
      styles = `
        color: ${colors.darkerInfo};
        width: 20px;
        height: 20px;
      `;
      break;
    case 'help':
      styles = `
        color: ${colors.darkestRegular};
        width: 26px;
        height: 26px;
        margin-top: 2px;
        transition: all 0.5s;

        &:hover {
          color: ${colors.darkerInfo};
        }
      `;
      break;

    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Email = styled(MuiEmail)`
  ${({ type }) => defineStyles(type)}
`;
