const Types = {
  PRODUCT_FILES_GET: '@products/GET_PRODUCT_FILES',

  PRODUCT_FILES_CREATE_REQUEST: '@products/CREATE_PRODUCT_FILE_REQUEST',
  PRODUCT_FILES_CREATE_SUCCESS: '@products/CREATE_PRODUCT_FILE_SUCCESS',
  PRODUCT_FILES_CREATE_FAILURE: '@products/CREATE_PRODUCT_FILE_FAILURE',

  PRODUCT_FILES_UPDATE_REQUEST: '@products/UPDATE_PRODUCT_FILE_REQUEST',
  PRODUCT_FILES_UPDATE_SUCCESS: '@products/UPDATE_PRODUCT_FILE_SUCCESS',
  PRODUCT_FILES_UPDATE_FAILURE: '@products/UPDATE_PRODUCT_FILE_FAILURE',

  PRODUCT_FILES_DELETE_REQUEST: '@products/DELETE_PRODUCT_FILE_REQUEST',
  PRODUCT_FILES_DELETE_SUCCESS: '@products/DELETE_PRODUCT_FILE_SUCCESS',
  PRODUCT_FILES_DELETE_FAILURE: '@products/DELETE_PRODUCT_FILE_FAILURE',

  PRODUCT_FILES_OPEN_MODAL: '@products/OPEN_MODAL_PRODUCT_FILE',
  PRODUCT_FILES_CLOSE_MODAL: '@products/CLOSE_MODAL_PRODUCT_FILE',

  PRODUCT_FILES_RESET_LIST: '@products/RESET_LIST_PRODUCT_FILE',
};

export default Types;
