import Types from './types';

export function getBrandsRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.BRANDS_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getBrandsSuccess({
  data,
  queryParams,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.BRANDS_GET_SUCCESS,
    payload: { data, queryParams, filterColumnsOrder, filterSearch },
  };
}

export function getBrandsFailure() {
  return {
    type: Types.BRANDS_GET_FAILURE,
  };
}

export function createBrandRequest(brand, source) {
  return {
    type: Types.BRANDS_CREATE_REQUEST,
    payload: { brand, source },
  };
}

export function createBrandSuccess(brand, source) {
  return {
    type: Types.BRANDS_CREATE_SUCCESS,
    payload: { brand, source },
  };
}

export function createBrandFailure() {
  return {
    type: Types.BRANDS_CREATE_FAILURE,
  };
}

export function updateBrandRequest(id, newBrand) {
  return {
    type: Types.BRANDS_UPDATE_REQUEST,
    payload: { id, newBrand },
  };
}

export function updateBrandSuccess(newBrand) {
  return {
    type: Types.BRANDS_UPDATE_SUCCESS,
    payload: { newBrand },
  };
}

export function updateBrandFailure() {
  return {
    type: Types.BRANDS_UPDATE_FAILURE,
  };
}

export function deleteBrandRequest(id) {
  return {
    type: Types.BRANDS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteBrandSuccess(id) {
  return {
    type: Types.BRANDS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteBrandFailure() {
  return {
    type: Types.BRANDS_DELETE_FAILURE,
  };
}

export function resetListBrands() {
  return {
    type: Types.BRANDS_RESET_LIST,
  };
}

export function openModalBrand(id) {
  return {
    type: Types.BRANDS_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalBrand() {
  return {
    type: Types.BRANDS_CLOSE_MODAL,
  };
}
