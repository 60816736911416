import Types from './types';

export function getBankAccountPixFiles(files) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_GET,
    payload: { files },
  };
}

export function createBankAccountPixFileRequest(
  bank_account_id,
  bank_account_pix_id,
  file,
  setFieldValue
) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_CREATE_REQUEST,
    payload: { bank_account_id, bank_account_pix_id, file, setFieldValue },
  };
}

export function createBankAccountPixFileSuccess(file, bank_account_pix_id) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_CREATE_SUCCESS,
    payload: { file, bank_account_pix_id },
  };
}

export function createBankAccountPixFileFailure() {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_CREATE_FAILURE,
  };
}

export function updateBankAccountPixFileRequest(
  bank_account_id,
  bank_account_pix_id,
  file_id,
  newFile
) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_UPDATE_REQUEST,
    payload: {
      bank_account_id,
      bank_account_pix_id,
      file_id,
      newFile,
    },
  };
}

export function updateBankAccountPixFileSuccess(newFile) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateBankAccountPixFileFailure() {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_UPDATE_FAILURE,
  };
}

export function deleteBankAccountPixFileRequest(
  bank_account_id,
  bank_account_pix_id,
  file_id,
  setFieldValue
) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_DELETE_REQUEST,
    payload: { bank_account_id, bank_account_pix_id, file_id, setFieldValue },
  };
}

export function deleteBankAccountPixFileSuccess(file_id, bank_account_pix_id) {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_DELETE_SUCCESS,
    payload: { file_id, bank_account_pix_id },
  };
}

export function deleteBankAccountPixFileFailure() {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_DELETE_FAILURE,
  };
}

export function resetListBankAccountPixFile() {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_RESET_LIST,
  };
}

export function openModalBankAccountPixFile() {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_OPEN_MODAL,
  };
}

export function closeModalBankAccountPixFile() {
  return {
    type: Types.BANK_ACCOUNT_PIX_FILES_CLOSE_MODAL,
  };
}
