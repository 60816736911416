import Types from './types';

export function getBankAccountPixs(bankAccountPixs) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_GET,
    payload: { bankAccountPixs },
  };
}

export function createBankAccountPixRequest(
  bank_account_id,
  bankAccountPix,
  subResourceType = null,
  resetForm = () => {}
) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_CREATE_REQUEST,
    payload: { bank_account_id, bankAccountPix, subResourceType, resetForm },
  };
}

export function createBankAccountPixSuccess(bankAccountPix) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_CREATE_SUCCESS,
    payload: { bankAccountPix },
  };
}

export function createBankAccountPixFailure() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_CREATE_FAILURE,
  };
}

export function updateBankAccountPixRequest(
  bank_account_id,
  bank_account_pix_id,
  newBankAccountPix,
  subResourceType = null
) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_UPDATE_REQUEST,
    payload: {
      bank_account_id,
      bank_account_pix_id,
      newBankAccountPix,
      subResourceType,
    },
  };
}

export function updateBankAccountPixSuccess(newBankAccountPix) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_UPDATE_SUCCESS,
    payload: { newBankAccountPix },
  };
}

export function updateBankAccountPixFailure() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_UPDATE_FAILURE,
  };
}

export function deleteBankAccountPixRequest(
  bank_account_id,
  bank_account_pix_id
) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_DELETE_REQUEST,
    payload: { bank_account_id, bank_account_pix_id },
  };
}

export function deleteBankAccountPixSuccess(bank_account_pix_id) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_DELETE_SUCCESS,
    payload: { bank_account_pix_id },
  };
}

export function deleteBankAccountPixFailure() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_DELETE_FAILURE,
  };
}

export function resetListBankAccountPixs() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_RESET_LIST,
  };
}

export function resetDetailsBankAccountPix() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_RESET_DETAILS,
  };
}

export function openModalBankAccountPix(bank_account_pix_id) {
  return {
    type: Types.BANK_ACCOUNT_PIXS_OPEN_MODAL,
    payload: { bank_account_pix_id },
  };
}

export function closeModalBankAccountPix() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_CLOSE_MODAL,
  };
}

export function openModalBankAccountPixCompanyPix() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_COMPANY_PIX_OPEN_MODAL,
  };
}

export function closeModalBankAccountPixCompanyPix() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_COMPANY_PIX_CLOSE_MODAL,
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.BANK_ACCOUNT_PIXS_RESET_SUB_RESOURCE_DATA,
  };
}
