import { useIntl } from 'react-intl';

export default function TableLocalization() {
  const { formatMessage } = useIntl();

  const tableLocalization = {
    body: {
      addTooltip: formatMessage({ id: 'table-localization.body.add-tooltip' }),
      deleteTooltip: formatMessage({
        id: 'table-localization.body.delete-tooltip',
      }),
      editTooltip: formatMessage({
        id: 'table-localization.body.edit-tooltip',
      }),
      emptyDataSourceMessage: formatMessage({
        id: 'table-localization.body.empty-data-source-message',
      }),
      editRow: {
        deleteText: formatMessage({
          id: 'table-localization.body.delete-text',
        }),
        saveTooltip: formatMessage({
          id: 'table-localization.body.save-tooltip',
        }),
        cancelTooltip: formatMessage({
          id: 'table-localization.body.cancel-tooltip',
        }),
      },
      filterRow: {
        filterTooltip: formatMessage({
          id: 'table-localization.body.filter-tooltip',
        }),
      },
    },
    grouping: {
      groupedBy: formatMessage({
        id: 'table-localization.grouping.grouped-by',
      }),
      placeholder: formatMessage({
        id: 'table-localization.grouping.placeholder',
      }),
    },
    header: {
      actions: formatMessage({ id: 'table-localization.header.actions' }),
    },
    pagination: {
      firstAriaLabel: formatMessage({
        id: 'table-localization.pagination.first-aria-label',
      }),
      firstTooltip: formatMessage({
        id: 'table-localization.pagination.first-tooltip',
      }),
      labelDisplayedRows: `{from}-{to} ${formatMessage({
        id: 'table-localization.pagination.label-displayed-rows',
      })} {count}`,
      labelRowsPerPage: formatMessage({
        id: 'table-localization.pagination.label-rows-per-page',
      }),
      labelRowsSelect: formatMessage({
        id: 'table-localization.pagination.label-rows-select',
      }),
      lastAriaLabel: formatMessage({
        id: 'table-localization.pagination.last-aria-label',
      }),
      lastTooltip: formatMessage({
        id: 'table-localization.pagination.last-tooltip',
      }),
      nextTooltip: formatMessage({
        id: 'table-localization.pagination.next-aria-label',
      }),
      nextAriaLabel: formatMessage({
        id: 'table-localization.pagination.next-tooltip',
      }),
      previousTooltip: formatMessage({
        id: 'table-localization.pagination.previous-tooltip',
      }),
      previousAriaLabel: formatMessage({
        id: 'table-localization.pagination.previous-aria-label',
      }),
    },
    toolbar: {
      addRemoveColumns: formatMessage({
        id: 'table-localization.toolbar.add-remove-columns',
      }),
      clearSearchAriaLabel: formatMessage({
        id: 'table-localization.toolbar.clear-search-aria-label',
      }),
      exportAriaLabel: formatMessage({
        id: 'table-localization.toolbar.export-aria-label',
      }),
      exportTitle: formatMessage({
        id: 'table-localization.toolbar.export-title',
      }),
      exportCSVName: formatMessage({
        id: 'table-localization.toolbar.export-csv-name',
      }),
      exportPDFName: formatMessage({
        id: 'table-localization.toolbar.export-pdf-name',
      }),
      nRowsSelected: `{0} ${formatMessage({
        id: 'table-localization.toolbar.n-rows-selected',
      })}`,
      searchTooltip: formatMessage({
        id: 'table-localization.toolbar.search-tooltip',
      }),
      searchPlaceholder: formatMessage({
        id: 'table-localization.toolbar.search-placeholder',
      }),
      showColumnsTitle: formatMessage({
        id: 'table-localization.toolbar.show-columns-title',
      }),
      showColumnsAriaLabel: formatMessage({
        id: 'table-localization.toolbar.show-columns-aria-label',
      }),
    },
  };

  return tableLocalization;
}
