import Types from './types';

export function getTransporterPhones(phones) {
  return {
    type: Types.TRANSPORTER_PHONES_GET,
    payload: { phones },
  };
}

export function createTransporterPhoneRequest(transporter_id, phone) {
  return {
    type: Types.TRANSPORTER_PHONES_CREATE_REQUEST,
    payload: { transporter_id, phone },
  };
}

export function createTransporterPhoneSuccess(phone) {
  return {
    type: Types.TRANSPORTER_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createTransporterPhoneFailure() {
  return {
    type: Types.TRANSPORTER_PHONES_CREATE_FAILURE,
  };
}

export function updateTransporterPhoneRequest(
  transporter_id,
  phone_id,
  newPhone
) {
  return {
    type: Types.TRANSPORTER_PHONES_UPDATE_REQUEST,
    payload: { transporter_id, phone_id, newPhone },
  };
}

export function updateTransporterPhoneSuccess(newPhone) {
  return {
    type: Types.TRANSPORTER_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateTransporterPhoneFailure() {
  return {
    type: Types.TRANSPORTER_PHONES_UPDATE_FAILURE,
  };
}

export function deleteTransporterPhoneRequest(transporter_id, phone_id) {
  return {
    type: Types.TRANSPORTER_PHONES_DELETE_REQUEST,
    payload: { transporter_id, phone_id },
  };
}

export function deleteTransporterPhoneSuccess(phone_id) {
  return {
    type: Types.TRANSPORTER_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteTransporterPhoneFailure() {
  return {
    type: Types.TRANSPORTER_PHONES_DELETE_FAILURE,
  };
}

export function resetListTransporterPhone() {
  return {
    type: Types.TRANSPORTER_PHONES_RESET_LIST,
  };
}

export function openModalTransporterPhone(phone_id) {
  return {
    type: Types.TRANSPORTER_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalTransporterPhone() {
  return {
    type: Types.TRANSPORTER_PHONES_CLOSE_MODAL,
  };
}
