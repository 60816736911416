import Types from './types';

export function getSellerFiles(files) {
  return {
    type: Types.SELLER_FILES_GET,
    payload: { files },
  };
}

export function createSellerFileRequest(seller_id, file) {
  return {
    type: Types.SELLER_FILES_CREATE_REQUEST,
    payload: { seller_id, file },
  };
}

export function createSellerFileSuccess(file) {
  return {
    type: Types.SELLER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createSellerFileFailure() {
  return {
    type: Types.SELLER_FILES_CREATE_FAILURE,
  };
}

export function updateSellerFileRequest(seller_id, file_id, newFile) {
  return {
    type: Types.SELLER_FILES_UPDATE_REQUEST,
    payload: { seller_id, file_id, newFile },
  };
}

export function updateSellerFileSuccess(newFile) {
  return {
    type: Types.SELLER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateSellerFileFailure() {
  return {
    type: Types.SELLER_FILES_UPDATE_FAILURE,
  };
}

export function deleteSellerFileRequest(seller_id, file_id) {
  return {
    type: Types.SELLER_FILES_DELETE_REQUEST,
    payload: { seller_id, file_id },
  };
}

export function deleteSellerFileSuccess(file_id) {
  return {
    type: Types.SELLER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteSellerFileFailure() {
  return {
    type: Types.SELLER_FILES_DELETE_FAILURE,
  };
}

export function openModalSellerFile() {
  return {
    type: Types.SELLER_FILES_OPEN_MODAL,
  };
}

export function closeModalSellerFile() {
  return {
    type: Types.SELLER_FILES_CLOSE_MODAL,
  };
}

export function resetListSellerFile() {
  return {
    type: Types.SELLER_FILES_RESET_LIST,
  };
}
