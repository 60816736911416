import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCompanyEmailSuccess,
  createCompanyEmailFailure,
  updateCompanyEmailSuccess,
  updateCompanyEmailFailure,
  deleteCompanyEmailSuccess,
  deleteCompanyEmailFailure,
} from './actions';
import Types from './types';

export function* createCompanyEmail({ payload }) {
  try {
    const { company_id, email } = payload;

    const response = yield call(
      api.post,
      `companies/${company_id}/emails`,
      email
    );

    yield put(createCompanyEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCompanyEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCompanyEmail({ payload }) {
  try {
    const { company_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `companies/${company_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateCompanyEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCompanyEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCompanyEmail({ payload }) {
  const { company_id, email_id } = payload;

  const emails = yield select(state => state.companyEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteCompanyEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `companies/${company_id}/emails/${email_id}`);

    yield put(deleteCompanyEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCompanyEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.COMPANY_EMAILS_CREATE_REQUEST, createCompanyEmail),
  takeLatest(Types.COMPANY_EMAILS_UPDATE_REQUEST, updateCompanyEmail),
  takeLatest(Types.COMPANY_EMAILS_DELETE_REQUEST, deleteCompanyEmail),
]);
