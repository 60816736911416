const Types = {
  TRANSPORTER_ADDRESSES_GET: '@transporters/GET_TRANSPORTER_ADDRESSES',

  TRANSPORTER_ADDRESSES_CREATE_REQUEST:
    '@transporters/CREATE_TRANSPORTER_ADDRESS_REQUEST',
  TRANSPORTER_ADDRESSES_CREATE_SUCCESS:
    '@transporters/CREATE_TRANSPORTER_ADDRESS_SUCCESS',
  TRANSPORTER_ADDRESSES_CREATE_FAILURE:
    '@transporters/CREATE_TRANSPORTER_ADDRESS_FAILURE',

  TRANSPORTER_ADDRESSES_UPDATE_REQUEST:
    '@transporters/UPDATE_TRANSPORTER_ADDRESS_REQUEST',
  TRANSPORTER_ADDRESSES_UPDATE_SUCCESS:
    '@transporters/UPDATE_TRANSPORTER_ADDRESS_SUCCESS',
  TRANSPORTER_ADDRESSES_UPDATE_FAILURE:
    '@transporters/UPDATE_TRANSPORTER_ADDRESS_FAILURE',

  TRANSPORTER_ADDRESSES_DELETE_REQUEST:
    '@transporters/DELETE_TRANSPORTER_ADDRESS_REQUEST',
  TRANSPORTER_ADDRESSES_DELETE_SUCCESS:
    '@transporters/DELETE_TRANSPORTER_ADDRESS_SUCCESS',
  TRANSPORTER_ADDRESSES_DELETE_FAILURE:
    '@transporters/DELETE_TRANSPORTER_ADDRESS_FAILURE',

  TRANSPORTER_ADDRESSES_RESET_LIST:
    '@transporters/RESET_LIST_TRANSPORTER_ADDRESS',

  TRANSPORTER_ADDRESSES_OPEN_MODAL:
    '@transporters/OPEN_MODAL_TRANSPORTER_ADDRESS',
  TRANSPORTER_ADDRESSES_CLOSE_MODAL:
    '@transporters/CLOSE_MODAL_TRANSPORTER_ADDRESS',
};

export default Types;
