import produce from 'immer';

import CompanyTypes from '../companies/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  companySubsidiaryModalOpen: false,
  superiorCompanyId: null,
};

export default function companySubsidiaries(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COMPANY_SUBSIDIARIES_GET: {
        draft.list = action.payload.subsdiaries;
        break;
      }

      case Types.COMPANY_SUBSIDIARIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_SUBSIDIARIES_CREATE_SUCCESS: {
        const companiesList = [...state.list, action.payload.subsidiaryCompany];

        const sortedCompaniesList = companiesList.sort((a, b) => {
          if (a.level > b.level) {
            return 1;
          }

          if (a.level < b.level) {
            return -1;
          }

          return a.name.localeCompare(b.name);
        });

        draft.list = sortedCompaniesList;
        draft.companySubsidiaryModalOpen = false;
        draft.loading = false;
        draft.superiorCompanyId = null;
        break;
      }
      case Types.COMPANY_SUBSIDIARIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_SUBSIDIARIES_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.COMPANY_SUBSIDIARIES_OPEN_MODAL: {
        const { superiorCompanyId } = action.payload;

        draft.companySubsidiaryModalOpen = true;
        draft.superiorCompanyId = superiorCompanyId;
        break;
      }
      case Types.COMPANY_SUBSIDIARIES_CLOSE_MODAL: {
        draft.companySubsidiaryModalOpen = false;
        draft.superiorCompanyId = null;
        break;
      }

      case CompanyTypes.COMPANIES_RESET_DETAILS: {
        draft.companySubsidiaryModalOpen = false;
        draft.list = [];
        draft.superiorCompanyId = null;
        break;
      }

      default:
    }
  });
}
