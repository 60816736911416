import Types from './types';

export function getOrderPriceListItems(items, productId) {
  return {
    type: Types.ORDER_PRICE_LIST_ITEMS_GET,
    payload: { items, productId },
  };
}

export function getOrderPriceListItemsFailure() {
  return {
    type: Types.ORDER_PRICE_LIST_ITEMS_GET_FAILURE,
  };
}

export function resetListOrderPriceListItems() {
  return {
    type: Types.ORDER_PRICE_LIST_ITEMS_RESET_LIST,
  };
}
