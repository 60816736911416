import Types from './types';

export function getPaymentMethodsRequest({
  billType,
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.PAYMENT_METHODS_GET_REQUEST,
    payload: {
      billType,
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getPaymentMethodsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.PAYMENT_METHODS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getPaymentMethodsFailure() {
  return {
    type: Types.PAYMENT_METHODS_GET_FAILURE,
  };
}

export function createPaymentMethodRequest(
  paymentMethod,
  subResourceType = null
) {
  return {
    type: Types.PAYMENT_METHODS_CREATE_REQUEST,
    payload: { paymentMethod, subResourceType },
  };
}

export function createPaymentMethodSuccess(paymentMethod) {
  return {
    type: Types.PAYMENT_METHODS_CREATE_SUCCESS,
    payload: { paymentMethod },
  };
}

export function createPaymentMethodFailure() {
  return {
    type: Types.PAYMENT_METHODS_CREATE_FAILURE,
  };
}

export function updatePaymentMethodRequest(
  id,
  newPaymentMethod,
  subResourceType = null
) {
  return {
    type: Types.PAYMENT_METHODS_UPDATE_REQUEST,
    payload: { id, newPaymentMethod, subResourceType },
  };
}

export function updatePaymentMethodSuccess(newPaymentMethod) {
  return {
    type: Types.PAYMENT_METHODS_UPDATE_SUCCESS,
    payload: { newPaymentMethod },
  };
}

export function updatePaymentMethodFailure() {
  return {
    type: Types.PAYMENT_METHODS_UPDATE_FAILURE,
  };
}

export function deletePaymentMethodRequest(id, billType) {
  return {
    type: Types.PAYMENT_METHODS_DELETE_REQUEST,
    payload: { id, billType },
  };
}

export function deletePaymentMethodSuccess(id) {
  return {
    type: Types.PAYMENT_METHODS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deletePaymentMethodFailure() {
  return {
    type: Types.PAYMENT_METHODS_DELETE_FAILURE,
  };
}

export function getDetailsPaymentMethodRequest(id, billType) {
  return {
    type: Types.PAYMENT_METHODS_GET_DETAILS_REQUEST,
    payload: { id, billType },
  };
}

export function getDetailsPaymentMethodSuccess(paymentMethod) {
  return {
    type: Types.PAYMENT_METHODS_GET_DETAILS_SUCCESS,
    payload: { paymentMethod },
  };
}

export function getDetailsPaymentMethodFailure() {
  return {
    type: Types.PAYMENT_METHODS_GET_DETAILS_FAILURE,
  };
}

export function resetListPaymentMethods() {
  return {
    type: Types.PAYMENT_METHODS_RESET_LIST,
  };
}

export function resetDetailsPaymentMethod() {
  return {
    type: Types.PAYMENT_METHODS_RESET_DETAILS,
  };
}

export function setBillTypePaymentMethod(billType) {
  return {
    type: Types.PAYMENT_METHODS_SET_BILL_TYPE,
    payload: { billType },
  };
}

export function openModalPaymentMethodFilter() {
  return {
    type: Types.PAYMENT_METHODS_FILTER_OPEN_MODAL,
  };
}

export function closeModalPaymentMethodFilter() {
  return {
    type: Types.PAYMENT_METHODS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.PAYMENT_METHODS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.PAYMENT_METHODS_RESET_SUB_RESOURCE_DATA,
  };
}
