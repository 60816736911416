import React from 'react';

import PropTypes from 'prop-types';

import { Assignment } from './styles';

export default function TaskIcon({ type }) {
  return <Assignment type={type} />;
}

TaskIcon.propTypes = {
  type: PropTypes.string,
};

TaskIcon.defaultProps = {
  type: '',
};
