import React from 'react';
import { Clipboard } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const CulturesList = async(() => import('~/pages/Cultures/List'));
const HarvestsList = async(() => import('~/pages/Harvests/List'));
const RuralPropertiesList = async(() => import('~/pages/RuralProperties/List'));

export const managerialRoutes = {
  id: <IntlMessages id="sidebar.managerial" />,
  path: '/managerial',
  icon: <Clipboard />,
  children: [],
};

if (process.env.REACT_APP_MODULE === 'AGRIBUSINESS') {
  managerialRoutes.children.push(
    {
      path: '/cultures',
      name: <IntlMessages id="sidebar.managerial.cultures" />,
      component: CulturesList,
    },
    {
      path: '/rural-properties',
      name: <IntlMessages id="sidebar.managerial.rural-properties" />,
      component: RuralPropertiesList,
    },
    {
      path: '/harvests',
      name: <IntlMessages id="sidebar.managerial.harvests" />,
      component: HarvestsList,
    }
  );
}
