import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],

  filterColumnsOrder: null,
  filterSearch: '',

  loading: false,
};

export default function devices(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.DEVICES_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.DEVICES_GET_SUCCESS: {
        const {
          data: devicesList,
          isAllDevicesRoute,
          filterColumnsOrder,
          filterSearch,
        } = action.payload;

        const data = [];

        if (isAllDevicesRoute) {
          devicesList.map(deviceList => {
            const device = deviceList.devices.map(item => {
              const deviceInfo = {
                company_id: deviceList.id,
                company_code: deviceList.person_code,
                company_name: deviceList.nickname || deviceList.name,
                is_enabled: deviceList.is_enabled,
                situation: deviceList.situation,
                ...item,
              };

              return deviceInfo;
            });

            return data.push(...device);
          });

          draft.list = data;
        } else {
          const deviceData = devicesList.map(device => {
            return {
              ...device,
              company_name: device.company.nickname || device.company.name,
              situation: device.company.situation,
              is_enabled: device.company.is_enabled,
            };
          });

          draft.list = deviceData;
        }

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        draft.loading = false;
        break;
      }
      case Types.DEVICES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.DEVICES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        break;
      }

      default:
    }
  });
}
