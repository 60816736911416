import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createBillToPayCostCenterSuccess,
  createBillToPayCostCenterFailure,
  updateBillToPayCostCenterSuccess,
  updateBillToPayCostCenterFailure,
  deleteBillToPayCostCenterSuccess,
  deleteBillToPayCostCenterFailure,
} from './actions';
import Types from './types';

// TODO: Posteriormente, remover a condição para poder apenas adicionar um elemento
export function* createBillToPayCostCenter({ payload }) {
  try {
    const { bill_to_pay_id, costCenter } = payload;

    const costCenters = yield select(state => state.billToPayCostCenters.list);

    if (costCenters.length > 0) {
      yield put(createBillToPayCostCenterFailure());

      toast.error(
        <IntlMessages id="bills.cost-centers.error-cost-center-create" />
      );

      return;
    }

    const response = yield call(
      api.post,
      `bills/${bill_to_pay_id}/cost-centers`,
      costCenter
    );

    yield put(createBillToPayCostCenterSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createBillToPayCostCenterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToPayCostCenter({ payload }) {
  try {
    const { bill_to_pay_id, cost_center_id, newCostCenter } = payload;

    const response = yield call(
      api.put,
      `bills/${bill_to_pay_id}/cost-centers/${cost_center_id}`,
      newCostCenter
    );

    yield put(updateBillToPayCostCenterSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBillToPayCostCenterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBillToPayCostCenter({ payload }) {
  try {
    const { bill_to_pay_id, cost_center_id } = payload;
    yield call(
      api.delete,
      `bills/${bill_to_pay_id}/cost-centers/${cost_center_id}`
    );

    yield put(deleteBillToPayCostCenterSuccess(cost_center_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBillToPayCostCenterFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.BILL_TO_PAY_COST_CENTERS_CREATE_REQUEST,
    createBillToPayCostCenter
  ),
  takeLatest(
    Types.BILL_TO_PAY_COST_CENTERS_UPDATE_REQUEST,
    updateBillToPayCostCenter
  ),
  takeLatest(
    Types.BILL_TO_PAY_COST_CENTERS_DELETE_REQUEST,
    deleteBillToPayCostCenter
  ),
]);
