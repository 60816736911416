const Types = {
  SELLER_COMPANIES_GET: '@sellers/GET_SELLER_COMPANIES',

  SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_REQUEST:
    '@sellers/GET_SELLER_COMPANIES_COMPANIES_NOT_BOUND_REQUEST',
  SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_SUCCESS:
    '@sellers/GET_SELLER_COMPANIES_COMPANIES_NOT_BOUND_SUCCESS',
  SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_FAILURE:
    '@sellers/GET_SELLER_COMPANIES_COMPANIES_NOT_BOUND_FAILURE',

  SELLER_COMPANIES_CREATE_REQUEST: '@sellers/CREATE_SELLER_COMPANY_REQUEST',
  SELLER_COMPANIES_CREATE_SUCCESS: '@sellers/CREATE_SELLER_COMPANY_SUCCESS',
  SELLER_COMPANIES_CREATE_FAILURE: '@sellers/CREATE_SELLER_COMPANY_FAILURE',

  SELLER_COMPANIES_DELETE_REQUEST: '@sellers/DELETE_SELLER_COMPANY_REQUEST',
  SELLER_COMPANIES_DELETE_SUCCESS: '@sellers/DELETE_SELLER_COMPANY_SUCCESS',
  SELLER_COMPANIES_DELETE_FAILURE: '@sellers/DELETE_SELLER_COMPANY_FAILURE',

  SELLER_COMPANIES_RESET_LIST: '@sellers/RESET_LIST_SELLER_COMPANY',

  SELLER_COMPANIES_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_COMPANY',
  SELLER_COMPANIES_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_COMPANY',
};

export default Types;
