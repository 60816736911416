import Types from './types';

export function getRuralPropertyPhones(phones) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_GET,
    payload: { phones },
  };
}

export function createRuralPropertyPhoneRequest(property_id, phone) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_CREATE_REQUEST,
    payload: { property_id, phone },
  };
}

export function createRuralPropertyPhoneSuccess(phone) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createRuralPropertyPhoneFailure() {
  return {
    type: Types.RURAL_PROPERTY_PHONES_CREATE_FAILURE,
  };
}

export function updateRuralPropertyPhoneRequest(
  property_id,
  phone_id,
  newPhone
) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_UPDATE_REQUEST,
    payload: { property_id, phone_id, newPhone },
  };
}

export function updateRuralPropertyPhoneSuccess(newPhone) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateRuralPropertyPhoneFailure() {
  return {
    type: Types.RURAL_PROPERTY_PHONES_UPDATE_FAILURE,
  };
}

export function deleteRuralPropertyPhoneRequest(property_id, phone_id) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_DELETE_REQUEST,
    payload: { property_id, phone_id },
  };
}

export function deleteRuralPropertyPhoneSuccess(phone_id) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteRuralPropertyPhoneFailure() {
  return {
    type: Types.RURAL_PROPERTY_PHONES_DELETE_FAILURE,
  };
}

export function resetListRuralPropertyPhone() {
  return {
    type: Types.RURAL_PROPERTY_PHONES_RESET_LIST,
  };
}

export function openModalRuralPropertyPhone(phone_id) {
  return {
    type: Types.RURAL_PROPERTY_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalRuralPropertyPhone() {
  return {
    type: Types.RURAL_PROPERTY_PHONES_CLOSE_MODAL,
  };
}
