import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createProductFileSuccess,
  createProductFileFailure,
  updateProductFileSuccess,
  updateProductFileFailure,
  deleteProductFileSuccess,
  deleteProductFileFailure,
} from './actions';
import Types from './types';

export function* createProductFile({ payload }) {
  try {
    const { product_id, file } = payload;

    const response = yield call(
      api.post,
      `products/${product_id}/files`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(createProductFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createProductFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateProductFile({ payload }) {
  try {
    const { product_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `products/${product_id}/files/${file_id}`,
      newFile
    );

    yield put(updateProductFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateProductFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteProductFile({ payload }) {
  try {
    const { product_id, file_id } = payload;

    yield call(api.delete, `products/${product_id}/files/${file_id}`);

    yield put(deleteProductFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteProductFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.PRODUCT_FILES_CREATE_REQUEST, createProductFile),
  takeLatest(Types.PRODUCT_FILES_UPDATE_REQUEST, updateProductFile),
  takeLatest(Types.PRODUCT_FILES_DELETE_REQUEST, deleteProductFile),
]);
