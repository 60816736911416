const Types = {
  TEAMS_GET_REQUEST: '@teams/GET_TEAMS_REQUEST',
  TEAMS_GET_SUCCESS: '@teams/GET_TEAMS_SUCCESS',
  TEAMS_GET_FAILURE: '@teams/GET_TEAMS_FAILURE',

  TEAMS_CREATE_REQUEST: '@teams/CREATE_TEAM_REQUEST',
  TEAMS_CREATE_SUCCESS: '@teams/CREATE_TEAM_SUCCESS',
  TEAMS_CREATE_FAILURE: '@teams/CREATE_TEAM_FAILURE',

  TEAMS_UPDATE_REQUEST: '@teams/UPDATE_TEAM_REQUEST',
  TEAMS_UPDATE_SUCCESS: '@teams/UPDATE_TEAM_SUCCESS',
  TEAMS_UPDATE_FAILURE: '@teams/UPDATE_TEAM_FAILURE',

  TEAMS_DELETE_REQUEST: '@teams/DELETE_TEAM_REQUEST',
  TEAMS_DELETE_SUCCESS: '@teams/DELETE_TEAM_SUCCESS',
  TEAMS_DELETE_FAILURE: '@teams/DELETE_TEAM_FAILURE',

  TEAMS_GET_DETAILS_REQUEST: '@teams/GET_DETAILS_TEAM_REQUEST',
  TEAMS_GET_DETAILS_SUCCESS: '@teams/GET_DETAILS_TEAM_SUCCESS',
  TEAMS_GET_DETAILS_FAILURE: '@teams/GET_DETAILS_TEAM_FAILURE',

  TEAMS_SET_SAVING_ON_SUB_RESOURCE: '@teams/SET_SAVING_ON_SUB_RESOURCE_TEAM',
  TEAMS_RESET_SUB_RESOURCE_DATA: '@teams/RESET_SUB_RESOURCE_DATA_TEAM',

  TEAMS_RESET_LIST: '@teams/RESET_LIST_TEAMS',
  TEAMS_RESET_DETAIL: '@teams/RESET_DETAILS_TEAM',
};

export default Types;
