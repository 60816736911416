import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createUserFileSuccess,
  createUserFileFailure,
  updateUserFileSuccess,
  updateUserFileFailure,
  deleteUserFileSuccess,
  deleteUserFileFailure,
} from './actions';
import Types from './types';

export function* createUserFile({ payload }) {
  try {
    const { user_id, file } = payload;

    const response = yield call(api.post, `users/${user_id}/files`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(createUserFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createUserFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateUserFile({ payload }) {
  try {
    const { user_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `users/${user_id}/files/${file_id}`,
      newFile
    );

    yield put(updateUserFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateUserFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteUserFile({ payload }) {
  try {
    const { user_id, file_id } = payload;

    yield call(api.delete, `users/${user_id}/files/${file_id}`);

    yield put(deleteUserFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteUserFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.USER_FILES_CREATE_REQUEST, createUserFile),
  takeLatest(Types.USER_FILES_UPDATE_REQUEST, updateUserFile),
  takeLatest(Types.USER_FILES_DELETE_REQUEST, deleteUserFile),
]);
