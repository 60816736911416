import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { Grid, Tooltip, CircularProgress, Button } from '@material-ui/core';

import avatarMan from '~/assets/img/avatar/avatar-man.png';
import avatarWoman from '~/assets/img/avatar/avatar-woman.png';
import { CustomChip, IconChip } from '~/components/Chips';
import {
  EmailIcon,
  LocationOnIcon,
  PhoneIcon,
  WhatsAppIcon,
  StarIcon,
} from '~/components/Icons';
import CustomModal from '~/components/Modals/CustomModal';
import { getIsEnabledStatus } from '~/data/personIsEnabledStatus';
import { getSituationStatus } from '~/data/personSituationStatus';
import { personTypes } from '~/data/personTypes';
import history from '~/services/history';
import {
  closeModalDetailContact,
  getDetailsContactRequest,
  resetDetailsContact,
} from '~/store/modules/contacts/actions';
import { formatFullAddress } from '~/utils/formats';
import IntlMessages from '~/utils/IntlMessages';

import {
  CardContainer,
  AvatarWrapper,
  ImgContainer,
  Avatar,
  ChipContainer,
  InfoContainer,
  InfoTitle,
  InfoText,
  InfoWrapper,
  IconButton,
  ContactGroup,
  ContactName,
  ContactAction,
  LoadingContainer,
  ContactWebsite,
} from './styles';

export default function ContactDetailModal({ contactId, open, title, size }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const contacts = useSelector(state => state.contacts);
  const contactAddresses = useSelector(state => state.contactAddresses);
  const contactEmails = useSelector(state => state.contactEmails);
  const contactPhones = useSelector(state => state.contactPhones);
  const contactFiles = useSelector(state => state.contactFiles);
  const { contactDetailModalLoading, detail: contactDetail } = contacts;

  const contactDocument =
    contactDetail.documents &&
    contactDetail.documents.find(
      doc => doc.type === 'CPF' || doc.type === 'CNPJ'
    );

  const formattedBornAt =
    contactDetail.born_at &&
    format(parseISO(contactDetail.born_at), 'dd/MM/yyyy');

  const situationStatus = getSituationStatus(contactDetail.situation);
  const isEnabledStatus = getIsEnabledStatus(contactDetail.is_enabled);

  let avatarSrc = contactDetail.gender === 'M' ? avatarMan : avatarWoman;

  if (contactFiles.detail?.url) {
    avatarSrc = contactFiles.detail.url;
  }

  useEffect(() => {
    dispatch(getDetailsContactRequest(contactId));
  }, [dispatch, contactId]);

  return (
    <CustomModal
      title={title}
      open={open}
      onClose={() => dispatch(closeModalDetailContact())}
      size={size}
    >
      <CardContainer>
        {contactDetailModalLoading ? (
          <LoadingContainer>
            <CircularProgress color="inherit" size={20} />
          </LoadingContainer>
        ) : (
          <>
            <AvatarWrapper>
              <ImgContainer>
                {contactDetail.gender && (
                  <Avatar src={avatarSrc} alt={contactDetail.name} />
                )}
              </ImgContainer>
              <ChipContainer>
                {contactDetail.types?.map(contactType => {
                  if (contactType.deleted_at) {
                    return false;
                  }

                  const typeValue = personTypes.find(
                    type => contactType.name === type.value
                  );

                  const formattedType = formatMessage({ id: typeValue.label });

                  const chipColor = contactType.is_enabled
                    ? 'success'
                    : 'error';

                  return (
                    <CustomChip
                      clickable={contactDetail.is_enabled}
                      label={formattedType}
                      chipColor={chipColor}
                      key={contactType.id}
                      onClick={() => {
                        if (!contactDetail.is_enabled) {
                          return false;
                        }

                        history.replace(`${typeValue.url}/${contactDetail.id}`);
                        return dispatch(resetDetailsContact());
                      }}
                    />
                  );
                })}
              </ChipContainer>
            </AvatarWrapper>
            <InfoWrapper>
              <InfoContainer>
                {contacts.detailModalSource === 'CONTACT' && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        history.push(`/person/${contactDetail.id}`);
                        dispatch(resetDetailsContact());
                      }}
                    >
                      {formatMessage({ id: 'contacts.manage-contact' })}
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.is-enabled" />
                  </InfoTitle>

                  <CustomChip
                    label={formatMessage({ id: isEnabledStatus.label })}
                    chipColor={isEnabledStatus.color}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.situation" />
                  </InfoTitle>

                  <IconChip properties={situationStatus} />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.name" />
                  </InfoTitle>

                  <InfoText>{contactDetail.name}</InfoText>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    {contactDocument?.type === 'CPF' ? (
                      <IntlMessages id="persons.nickname" />
                    ) : (
                      <IntlMessages id="persons.trading-name" />
                    )}
                  </InfoTitle>

                  <InfoText>{contactDetail.nickname}</InfoText>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    {contactDocument?.type === 'CPF' ? (
                      <IntlMessages id="documents.cpf" />
                    ) : (
                      <IntlMessages id="documents.cnpj" />
                    )}
                  </InfoTitle>

                  <InfoText>
                    <NumberFormat
                      value={contactDocument?.document}
                      displayType="text"
                      format={
                        contactDocument?.type === 'CPF'
                          ? '###.###.###-##'
                          : '##.###.###/####-##'
                      }
                    />
                  </InfoText>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.born-at" />
                  </InfoTitle>

                  <InfoText>{formattedBornAt}</InfoText>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.contact" />
                  </InfoTitle>

                  <InfoText>{contactDetail.contact}</InfoText>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.website" />
                  </InfoTitle>

                  <InfoText break>
                    <ContactWebsite
                      rel="noopener noreferrer"
                      target="_blank"
                      href={contactDetail.website}
                    >
                      {contactDetail.website}
                    </ContactWebsite>
                  </InfoText>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.phone" />
                  </InfoTitle>

                  {contactPhones.list.map(contactPhone => {
                    const { number, contact, is_whatsapp, is_main, id, ddi } =
                      contactPhone;

                    const hasDdi = ddi ? `+${ddi}` : '';

                    const formattedDdi = hasDdi ? ddi.replace(/\s/g, '') : '';

                    const formattedDdiNumber = `${
                      formattedDdi && `+${formattedDdi}`
                    }${number}`;

                    return (
                      <ContactGroup key={id}>
                        <ContactName>
                          {contact && (
                            <>
                              <IntlMessages id="persons.contact" /> {contact}
                            </>
                          )}
                        </ContactName>

                        <ContactAction>
                          {ddi === '55' ? (
                            <NumberFormat
                              value={number}
                              displayType="text"
                              format={
                                number.length <= 10
                                  ? `${hasDdi} (##) ####-####`
                                  : `${hasDdi} (##) #####-####`
                              }
                            />
                          ) : (
                            <>{`${hasDdi} ${number}`}</>
                          )}
                          <Tooltip
                            arrow
                            title={
                              <>
                                <IntlMessages id="phones.call" />
                                {ddi === '55' ? (
                                  <NumberFormat
                                    value={number}
                                    displayType="text"
                                    format={
                                      number.length <= 10
                                        ? `${hasDdi} (##) ####-####`
                                        : `${hasDdi} (##) #####-####`
                                    }
                                  />
                                ) : (
                                  <>{`${hasDdi} ${number}`}</>
                                )}
                              </>
                            }
                          >
                            <IconButton href={`tel:${formattedDdiNumber}`}>
                              <PhoneIcon type="modal" />
                            </IconButton>
                          </Tooltip>

                          {is_whatsapp && (
                            <Tooltip
                              arrow
                              title={<IntlMessages id="phones.open-whatsapp" />}
                            >
                              <IconButton
                                href={`https://web.whatsapp.com/send?phone=${formattedDdiNumber}`}
                                target="blank"
                              >
                                <WhatsAppIcon type="modal" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {is_main && (
                            <Tooltip
                              arrow
                              title={<IntlMessages id="phones.is-main-phone" />}
                            >
                              <IconButton>
                                <StarIcon type="table" active />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ContactAction>
                      </ContactGroup>
                    );
                  })}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoTitle>
                    <IntlMessages id="persons.email" />
                  </InfoTitle>

                  {contactEmails.list.map(contactEmail => {
                    const { is_main, email, contact, id } = contactEmail;

                    return (
                      <ContactGroup key={id}>
                        <ContactName>
                          {contact && (
                            <>
                              <IntlMessages id="persons.contact" /> {contact}
                            </>
                          )}
                        </ContactName>

                        <ContactAction break>
                          {email}{' '}
                          <Tooltip
                            arrow
                            title={
                              <>
                                <IntlMessages id="emails.send" /> {email}
                              </>
                            }
                          >
                            <IconButton
                              href={`mailto://${email}`}
                              target="_blank"
                            >
                              <EmailIcon type="modal" />
                            </IconButton>
                          </Tooltip>
                          {is_main && (
                            <Tooltip
                              arrow
                              title={<IntlMessages id="emails.is-main-email" />}
                            >
                              <IconButton>
                                <StarIcon type="table" active />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ContactAction>
                      </ContactGroup>
                    );
                  })}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InfoTitle>
                    <IntlMessages id="persons.address" />
                  </InfoTitle>

                  {contactAddresses.list.map(contactAddress => {
                    const {
                      address,
                      city,
                      neighborhood,
                      number,
                      is_main,
                      postal_code,
                      id,
                      complement,
                    } = contactAddress;

                    const formattedFullAddress = formatFullAddress({
                      address,
                      number,
                      neighborhood,
                      city: city.name,
                      state: city.state_id,
                      postal_code,
                    });

                    let formattedAddress = address || '';

                    if (number) {
                      formattedAddress += formattedAddress
                        ? `, ${number}`
                        : number;
                    }

                    if (complement) {
                      formattedAddress += formattedAddress
                        ? `, ${complement}`
                        : complement;
                    }

                    if (neighborhood) {
                      formattedAddress += formattedAddress
                        ? ` - ${neighborhood}`
                        : neighborhood;
                    }

                    formattedAddress += formattedAddress
                      ? `, ${city.name}`
                      : city.name;

                    formattedAddress += formattedAddress
                      ? ` - ${city.state_id}`
                      : city.state_id;

                    return (
                      <ContactGroup key={id}>
                        <ContactAction break>
                          {formattedAddress}
                          <Tooltip
                            arrow
                            title={<IntlMessages id="addresses.google-maps" />}
                          >
                            <IconButton
                              href={`https://www.google.com/maps/search/${formattedFullAddress}`}
                              target="_blank"
                            >
                              <LocationOnIcon type="modal" />
                            </IconButton>
                          </Tooltip>

                          {is_main && (
                            <Tooltip
                              arrow
                              title={
                                <IntlMessages id="addresses.is-main-address" />
                              }
                            >
                              <IconButton>
                                <StarIcon type="table" active />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ContactAction>
                      </ContactGroup>
                    );
                  })}
                </Grid>
              </InfoContainer>
            </InfoWrapper>
          </>
        )}
      </CardContainer>
    </CustomModal>
  );
}

ContactDetailModal.propTypes = {
  contactId: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  size: PropTypes.string,
};

ContactDetailModal.defaultProps = {
  contactId: '',
  open: false,
  size: 'large',
};
