const Types = {
  REGION_SELLERS_GET: '@region/GET_REGION_SELLERS',

  REGION_SELLERS_CREATE_REQUEST: '@region/CREATE_REGION_SELLER_REQUEST',
  REGION_SELLERS_CREATE_SUCCESS: '@region/CREATE_REGION_SELLER_SUCCESS',
  REGION_SELLERS_CREATE_FAILURE: '@region/CREATE_REGION_SELLER_FAILURE',

  REGION_SELLERS_UPDATE_REQUEST: '@region/UPDATE_REGION_SELLER_REQUEST',
  REGION_SELLERS_UPDATE_SUCCESS: '@region/UPDATE_REGION_SELLER_SUCCESS',
  REGION_SELLERS_UPDATE_FAILURE: '@region/UPDATE_REGION_SELLER_FAILURE',

  REGION_SELLERS_DELETE_REQUEST: '@region/DELETE_REGION_SELLER_REQUEST',
  REGION_SELLERS_DELETE_SUCCESS: '@region/DELETE_REGION_SELLER_SUCCESS',
  REGION_SELLERS_DELETE_FAILURE: '@region/DELETE_REGION_SELLER_FAILURE',

  REGION_SELLERS_RESET_LIST: '@region/RESET_LIST_REGION_SELLER',

  REGION_SELLERS_OPEN_MODAL: '@region/OPEN_MODAL_REGION_SELLER',
  REGION_SELLERS_CLOSE_MODAL: '@region/CLOSE_MODAL_REGION_SELLER',
};

export default Types;
