import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getEmployeeAddresses,
  openModalEmployeeAddress,
  resetListEmployeeAddress,
} from '../employeeAddresses/actions';
import {
  getEmployeeEmails,
  openModalEmployeeEmail,
  resetListEmployeeEmail,
} from '../employeeEmails/actions';
import {
  getEmployeeFiles,
  resetListEmployeeFile,
} from '../employeeFiles/actions';
import {
  getEmployeePhones,
  openModalEmployeePhone,
  resetListEmployeePhone,
} from '../employeePhones/actions';
import { resetFoundPerson } from '../persons/actions';
import {
  getEmployeesSuccess,
  getEmployeesFailure,
  createEmployeeSuccess,
  createEmployeeFailure,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
  becomeEmployeeSuccess,
  becomeEmployeeFailure,
  getDetailsEmployeeSuccess,
  updateEmployeeSuccess,
  updateEmployeeFailure,
  getDetailsEmployeeFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getEmployees({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `employees${queryParams}`);

    yield put(
      getEmployeesSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(getEmployeesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createEmployee({ payload }) {
  try {
    const { employee, subResourceType } = payload;

    const response = yield call(api.post, 'employees', employee);

    yield put(createEmployeeSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/employee/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createEmployeeFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateEmployee({ payload }) {
  try {
    const { id, newEmployee } = payload;

    const response = yield call(api.put, `employees/${id}`, newEmployee);

    yield put(updateEmployeeSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateEmployeeFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteEmployee({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `employees/${id}`);

    yield put(deleteEmployeeSuccess(id));

    history.push('/employees');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteEmployeeFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeEmployee({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'EMPLOYEE';

    const response = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(becomeEmployeeSuccess(response.data));

    yield put(resetFoundPerson());

    history.push(`/employee/${id}`);
  } catch (err) {
    yield put(becomeEmployeeFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsEmployee({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `employees/${id}`);

    const { phones, addresses, emails, files, ...rest } = response.data;

    yield put(getEmployeePhones(phones));

    yield put(getEmployeeAddresses(addresses));

    yield put(getEmployeeEmails(emails));

    yield put(getEmployeeFiles(files));

    yield put(getDetailsEmployeeSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.employees
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(openModalEmployeePhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(openModalEmployeeEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(openModalEmployeeAddress());
      } else if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsEmployeeFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsEmployee() {
  yield put(resetListEmployeePhone());

  yield put(resetListEmployeeAddress());

  yield put(resetListEmployeeEmail());

  yield put(resetListEmployeeFile());
}

export default all([
  takeLatest(Types.EMPLOYEES_GET_REQUEST, getEmployees),
  takeLatest(Types.EMPLOYEES_CREATE_REQUEST, createEmployee),
  takeLatest(Types.EMPLOYEES_UPDATE_REQUEST, updateEmployee),
  takeLatest(Types.EMPLOYEES_DELETE_REQUEST, deleteEmployee),
  takeLatest(Types.EMPLOYEES_BECOME_REQUEST, becomeEmployee),
  takeLatest(Types.EMPLOYEES_GET_DETAILS_REQUEST, getDetailsEmployee),
  takeLatest(Types.EMPLOYEES_RESET_DETAILS, resetDetailsEmployee),
]);
