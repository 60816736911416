import { endOfMonth, parse, startOfMonth } from 'date-fns';

export function getDatesBetweenMonth(date) {
  const parseDate = parse(date, 'yyyy-MM', new Date());

  const betweenDateStart = startOfMonth(parseDate);
  const betweenDateEnd = endOfMonth(parseDate);

  return {
    startDate: betweenDateStart,
    endDate: betweenDateEnd,
  };
}
