import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getDetailsBillToReceiveRequest } from '../billsToReceive/actions';
import {
  createBillToReceivePixSuccess,
  createBillToReceivePixFailure,
  updateBillToReceivePixSuccess,
  updateBillToReceivePixFailure,
  settlementBillToReceivePixSuccess,
  settlementBillToReceivePixFailure,
  deleteBillToReceivePixSuccess,
  deleteBillToReceivePixFailure,
} from './actions';
import Types from './types';

export function* createBillToReceivePix({ payload }) {
  try {
    const { pix, pixType } = payload;

    const response = yield call(api.post, `/pixs`, pix);

    yield put(createBillToReceivePixSuccess(response.data, pixType));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createBillToReceivePixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToReceivePix({ payload }) {
  try {
    const { pix_id } = payload;

    const billToReceivePixDetail = yield select(
      state => state.billToReceivePixs.detail
    );

    const response = yield call(api.put, `pixs/${pix_id}`);

    if (billToReceivePixDetail.status !== response.data.status) {
      const billsToReceiveId = yield select(
        state => state.billsToReceive.detail.id
      );

      yield put(getDetailsBillToReceiveRequest(billsToReceiveId));
    }

    yield put(updateBillToReceivePixSuccess(response.data));

    toast.success(<IntlMessages id="messages.update-success" />);
  } catch (err) {
    yield put(updateBillToReceivePixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.update-failure" />);
  }
}

export function* settlementBillToReceivePix({ payload }) {
  try {
    const { pix_id, newPix } = payload;

    const response = yield call(api.put, `pixs/${pix_id}`, newPix);

    yield put(settlementBillToReceivePixSuccess(response.data));

    const billsToReceiveId = yield select(
      state => state.billsToReceive.detail.id
    );

    yield put(getDetailsBillToReceiveRequest(billsToReceiveId));

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(settlementBillToReceivePixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export function* deleteBillToReceivePix({ payload }) {
  try {
    const { pix_id } = payload;

    yield call(api.delete, `pixs/${pix_id}`);

    yield put(deleteBillToReceivePixSuccess(pix_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBillToReceivePixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.BILL_TO_RECEIVE_PIXS_CREATE_REQUEST, createBillToReceivePix),
  takeLatest(Types.BILL_TO_RECEIVE_PIXS_UPDATE_REQUEST, updateBillToReceivePix),
  takeLatest(
    Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_REQUEST,
    settlementBillToReceivePix
  ),
  takeLatest(Types.BILL_TO_RECEIVE_PIXS_DELETE_REQUEST, deleteBillToReceivePix),
]);
