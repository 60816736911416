import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Hidden, List, ListItemText } from '@material-ui/core';

import IntlMessages from '~/utils/IntlMessages';

import {
  GridContainer,
  GridCompany,
  Wrapper,
  ListItem,
  CustomNavigationLink,
} from './styles';

/* TODO: Adicionar os links dos itens */
export function Footer() {
  return (
    <Wrapper>
      <GridContainer container>
        <Hidden smDown>
          <Grid container item xs={12} sm={12} md={9}>
            <List>
              {process.env.REACT_APP_VERSION && (
                <ListItem>
                  <IntlMessages
                    id="footer.app-version"
                    values={{ value: process.env.REACT_APP_VERSION }}
                  />
                </ListItem>
              )}

              <ListItem>
                <CustomNavigationLink component={RouterLink} to="/help">
                  <ListItemText
                    primary={<IntlMessages id="footer.support" />}
                  />
                </CustomNavigationLink>
              </ListItem>

              <ListItem>
                <CustomNavigationLink component={RouterLink} to="/help">
                  <ListItemText
                    primary={<IntlMessages id="footer.help-center" />}
                  />
                </CustomNavigationLink>
              </ListItem>

              <ListItem>
                <CustomNavigationLink component="span" href="#" disabled>
                  <ListItemText
                    primary={<IntlMessages id="footer.privacy" />}
                  />
                </CustomNavigationLink>
              </ListItem>

              <ListItem>
                <CustomNavigationLink component="span" href="#" disabled>
                  <ListItemText
                    primary={<IntlMessages id="footer.terms-of-service" />}
                  />
                </CustomNavigationLink>
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <GridCompany container item xs={12} sm={12} md={3}>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <IntlMessages
                    id="datasmart.copyright"
                    values={{ value: new Date().getFullYear() }}
                  />
                }
              />
            </ListItem>
          </List>
        </GridCompany>
      </GridContainer>
    </Wrapper>
  );
}
