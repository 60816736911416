const Types = {
  PRICE_LISTS_GET_REQUEST: '@priceLists/GET_PRICE_LISTS_REQUEST',
  PRICE_LISTS_GET_SUCCESS: '@priceLists/GET_PRICE_LISTS_SUCCESS',
  PRICE_LISTS_GET_FAILURE: '@priceLists/GET_PRICE_LISTS_FAILURE',

  PRICE_LISTS_CREATE_REQUEST: '@priceLists/CREATE_PRICE_LIST_REQUEST',
  PRICE_LISTS_CREATE_SUCCESS: '@priceLists/CREATE_PRICE_LIST_SUCCESS',
  PRICE_LISTS_CREATE_FAILURE: '@priceLists/CREATE_PRICE_LIST_FAILURE',

  PRICE_LISTS_UPDATE_REQUEST: '@priceLists/UPDATE_PRICE_LIST_REQUEST',
  PRICE_LISTS_UPDATE_SUCCESS: '@priceLists/UPDATE_PRICE_LIST_SUCCESS',
  PRICE_LISTS_UPDATE_FAILURE: '@priceLists/UPDATE_PRICE_LIST_FAILURE',

  PRICE_LISTS_DELETE_REQUEST: '@priceLists/DELETE_PRICE_LIST_REQUEST',
  PRICE_LISTS_DELETE_SUCCESS: '@priceLists/DELETE_PRICE_LIST_SUCCESS',
  PRICE_LISTS_DELETE_FAILURE: '@priceLists/DELETE_PRICE_LIST_FAILURE',

  PRICE_LISTS_FILTER_OPEN_MODAL: '@priceLists/OPEN_MODAL_FILTER_PRICE_LIST',
  PRICE_LISTS_FILTER_CLOSE_MODAL: '@priceLists/CLOSE_MODAL_FILTER_PRICE_LIST',

  PRICE_LISTS_GET_DETAILS_REQUEST:
    '@priceLists/GET_DETAILS_PRICE_LISTS_REQUEST',
  PRICE_LISTS_GET_DETAILS_SUCCESS:
    '@priceLists/GET_DETAILS_PRICE_LISTS_SUCCESS',
  PRICE_LISTS_GET_DETAILS_FAILURE:
    '@priceLists/GET_DETAILS_PRICE_LISTS_FAILURE',

  PRICE_LISTS_RESET_LIST: '@priceLists/RESET_LIST_PRICE_LISTS',
  PRICE_LISTS_RESET_DETAILS: '@priceLists/RESET_DETAILS_PRICE_LIST',
};

export default Types;
