import Types from './types';

export function getDepartmentsRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.DEPARTMENTS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getDepartmentsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.DEPARTMENTS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getDepartmentsFailure() {
  return {
    type: Types.DEPARTMENTS_GET_FAILURE,
  };
}

export function createDepartmentRequest(department, source) {
  return {
    type: Types.DEPARTMENTS_CREATE_REQUEST,
    payload: { department, source },
  };
}

export function createDepartmentSuccess(department, source) {
  return {
    type: Types.DEPARTMENTS_CREATE_SUCCESS,
    payload: { department, source },
  };
}

export function createDepartmentFailure() {
  return {
    type: Types.DEPARTMENTS_CREATE_FAILURE,
  };
}

export function updateDepartmentRequest(id, newDepartment) {
  return {
    type: Types.DEPARTMENTS_UPDATE_REQUEST,
    payload: { id, newDepartment },
  };
}

export function updateDepartmentSuccess(newDepartment) {
  return {
    type: Types.DEPARTMENTS_UPDATE_SUCCESS,
    payload: { newDepartment },
  };
}

export function updateDepartmentFailure() {
  return {
    type: Types.DEPARTMENTS_UPDATE_FAILURE,
  };
}

export function deleteDepartmentRequest(id) {
  return {
    type: Types.DEPARTMENTS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteDepartmentSuccess(id) {
  return {
    type: Types.DEPARTMENTS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteDepartmentFailure() {
  return {
    type: Types.DEPARTMENTS_DELETE_FAILURE,
  };
}

export function resetListDepartments() {
  return {
    type: Types.DEPARTMENTS_RESET_LIST,
  };
}

export function openModalDepartmentFilter() {
  return {
    type: Types.DEPARTMENTS_FILTER_OPEN_MODAL,
  };
}

export function closeModalDepartmentFilter() {
  return {
    type: Types.DEPARTMENTS_FILTER_CLOSE_MODAL,
  };
}

export function openModalDepartment(id) {
  return {
    type: Types.DEPARTMENTS_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalDepartment() {
  return {
    type: Types.DEPARTMENTS_CLOSE_MODAL,
  };
}
