import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { getRegionCities, resetListRegionCity } from '../regionCities/actions';
import {
  getRegionCustomers,
  openModalRegionCustomer,
  resetListRegionCustomer,
} from '../regionCustomers/actions';
import {
  getRegionSellers,
  openModalRegionSeller,
  resetListRegionSeller,
} from '../regionSellers/actions';
import {
  getRegionsSuccess,
  getRegionsFailure,
  createRegionSuccess,
  createRegionFailure,
  updateRegionSuccess,
  updateRegionFailure,
  deleteRegionSuccess,
  deleteRegionFailure,
  getDetailsRegionSuccess,
  getDetailsRegionFailure,
  setSavingOnSubResource,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* getRegions() {
  try {
    const { data } = yield call(api.get, `regions`);

    yield put(getRegionsSuccess({ data }));
  } catch (err) {
    yield put(getRegionsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createRegion({ payload }) {
  try {
    const { region, subResourceType } = payload;

    const { data } = yield call(api.post, 'regions', region);

    yield put(createRegionSuccess(data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/region/${data.id}`, { creatingResource: true });
  } catch (err) {
    yield put(createRegionFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRegion({ payload }) {
  try {
    const { id, newRegion } = payload;

    const { data } = yield call(api.put, `regions/${id}`, newRegion);

    yield put(updateRegionSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRegionFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteRegion({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `regions/${id}`);

    yield put(deleteRegionSuccess(id));

    history.push('/regions');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRegionFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsRegion({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `regions/${id}`);

    const { cities, sellers, customers, ...rest } = data;

    yield put(getRegionCities(cities));

    yield put(getRegionSellers(sellers));

    yield put(getRegionCustomers(customers));

    yield put(getDetailsRegionSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.regions
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'SELLER') {
        yield put(openModalRegionSeller());
      } else if (subResourceType === 'CUSTOMER') {
        yield put(openModalRegionCustomer());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsRegionFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsRegion() {
  yield put(resetListRegionCity());
  yield put(resetListRegionSeller());
  yield put(resetListRegionCustomer());
}

export default all([
  takeLatest(Types.REGIONS_GET_REQUEST, getRegions),
  takeLatest(Types.REGIONS_CREATE_REQUEST, createRegion),
  takeLatest(Types.REGIONS_UPDATE_REQUEST, updateRegion),
  takeLatest(Types.REGIONS_DELETE_REQUEST, deleteRegion),
  takeLatest(Types.REGIONS_GET_DETAILS_REQUEST, getDetailsRegion),
  takeLatest(Types.REGIONS_RESET_DETAIL, resetDetailsRegion),
]);
