const Types = {
  DEPARTMENTS_GET_REQUEST: '@departments/GET_DEPARTMENTS_REQUEST',
  DEPARTMENTS_GET_SUCCESS: '@departments/GET_DEPARTMENTS_SUCCESS',
  DEPARTMENTS_GET_FAILURE: '@departments/GET_DEPARTMENTS_FAILURE',

  DEPARTMENTS_CREATE_REQUEST: '@departments/CREATE_DEPARTMENT_REQUEST',
  DEPARTMENTS_CREATE_SUCCESS: '@departments/CREATE_DEPARTMENT_SUCCESS',
  DEPARTMENTS_CREATE_FAILURE: '@departments/CREATE_DEPARTMENT_FAILURE',

  DEPARTMENTS_UPDATE_REQUEST: '@departments/UPDATE_DEPARTMENT_REQUEST',
  DEPARTMENTS_UPDATE_SUCCESS: '@departments/UPDATE_DEPARTMENT_SUCCESS',
  DEPARTMENTS_UPDATE_FAILURE: '@departments/UPDATE_DEPARTMENT_FAILURE',

  DEPARTMENTS_DELETE_REQUEST: '@departments/DELETE_DEPARTMENT_REQUEST',
  DEPARTMENTS_DELETE_SUCCESS: '@departments/DELETE_DEPARTMENT_SUCCESS',
  DEPARTMENTS_DELETE_FAILURE: '@departments/DELETE_DEPARTMENT_FAILURE',

  DEPARTMENTS_RESET_LIST: '@departments/RESET_LIST_DEPARTMENTS',

  DEPARTMENTS_FILTER_OPEN_MODAL: '@departments/OPEN_MODAL_FILTER_ORDER',
  DEPARTMENTS_FILTER_CLOSE_MODAL: '@departments/CLOSE_MODAL_FILTER_ORDER',

  DEPARTMENTS_OPEN_MODAL: '@departments/OPEN_MODAL_DEPARTMENT',
  DEPARTMENTS_CLOSE_MODAL: '@departments/CLOSE_MODAL_DEPARTMENT',
};

export default Types;
