import { TrendingUp as RFTrendingUp } from 'react-feather';

import styled from 'styled-components';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'tooltip':
      styles = `
        width: 13px;
        height: 13px;
      `;
      break;
    case 'form':
      styles = `
        padding: 2px;
        color: ${colors.darkRegular};
        margin-top: 6px;
        margin-right: 3px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const TrendingUp = styled(RFTrendingUp)`
  ${({ type }) => defineStyles(type)}
`;
