import { blue, green, grey, indigo, red, teal } from '@material-ui/core/colors';

const blueVariant = {
  name: 'Blue',
  palette: {
    primary: {
      main: blue[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: blue[700],
    header: {
      color: '#FFF',
      background: blue[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: blue[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    auth: {
      background: blue[800],
    },
    dashboard: {
      background: '#F7F9FC',
    },
    landing: {
      background: blue[800],
    },
  },
};

const greenVariant = {
  name: 'Green',
  palette: {
    primary: {
      main: green[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: green[500],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[500],
    },
  },
  sidebar: {
    color: '#FFF',
    background: green[700],
    header: {
      color: '#FFF',
      background: green[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: green[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    auth: {
      background: green[800],
    },
    dashboard: {
      background: '#F7F9FC',
    },
    landing: {
      background: green[800],
    },
  },
};

const indigoVariant = {
  name: 'Indigo',
  palette: {
    primary: {
      main: indigo[600],
      contrastText: '#FFF',
    },
    secondary: {
      main: indigo[400],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: indigo[500],
    },
  },
  sidebar: {
    color: '#FFF',
    background: indigo[600],
    header: {
      color: '#FFF',
      background: indigo[700],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: indigo[700],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    auth: {
      background: indigo[800],
    },
    dashboard: {
      background: '#F7F9FC',
    },
    landing: {
      background: indigo[800],
    },
  },
};

const tealVariant = {
  name: 'Teal',
  palette: {
    primary: {
      main: teal[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: teal[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: teal[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: teal[700],
    header: {
      color: '#FFF',
      background: teal[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: teal[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    auth: {
      background: teal[800],
    },
    dashboard: {
      background: '#F7F9FC',
    },
    landing: {
      background: teal[800],
    },
  },
};

const lightVariant = {
  name: 'Light',
  palette: {
    primary: {
      main: blue[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[200],
    background: blue[700],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: '#FFF',
    header: {
      color: '#FFF',
      background: blue[700],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: green[600],
    },
  },
  body: {
    auth: {
      background: blue[800],
    },
    dashboard: {
      background: '#F7F9FC',
    },
    landing: {
      background: blue[800],
    },
  },
};

const darkVariant = {
  name: 'Dark',
  palette: {
    primary: {
      main: blue[700],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[500],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFFFFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: grey[200],
    background: '#1B2430',
    header: {
      color: grey[200],
      background: '#232f3e',
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#232f3e',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: blue[500],
    },
  },
  body: {
    auth: {
      background: blue[700],
    },
    dashboard: {
      background: '#F7F9FC',
    },
    landing: {
      background: blue[700],
    },
  },
};

const variants = [
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
  tealVariant,
];

export default variants;
