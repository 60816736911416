import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createBankAccountPixSuccess,
  createBankAccountPixFailure,
  updateBankAccountPixSuccess,
  updateBankAccountPixFailure,
  deleteBankAccountPixSuccess,
  deleteBankAccountPixFailure,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

// TODO: Posteriormente, remover a condição para poder apenas adicionar um elemento
export function* createBankAccountPix({ payload }) {
  try {
    const { bank_account_id, bankAccountPix, subResourceType, resetForm } =
      payload;

    const bankAccountPixs = yield select(state => state.bankAccountPixs.list);

    if (bankAccountPixs.length > 0) {
      yield put(createBankAccountPixFailure());

      toast.error(
        <IntlMessages id="bank-account-pixs.error-bank-account-pix-create" />
      );
      return;
    }

    const response = yield call(
      api.post,
      `/bank-accounts/${bank_account_id}/pixs`,
      bankAccountPix
    );

    yield put(createBankAccountPixSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);

    resetForm();

    if (subResourceType === 'FILE') {
      yield put(setOpenFileDialog());
    }

    yield put(resetSubResourceData());
  } catch (err) {
    yield put(createBankAccountPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBankAccountPix({ payload }) {
  try {
    const {
      bank_account_id,
      bank_account_pix_id,
      newBankAccountPix,
      subResourceType,
    } = payload;

    const response = yield call(
      api.put,
      `bank-accounts/${bank_account_id}/pixs/${bank_account_pix_id}`,
      newBankAccountPix
    );

    yield put(updateBankAccountPixSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);

    if (subResourceType === 'FILE') {
      yield put(setOpenFileDialog());
    }

    yield put(resetSubResourceData());
  } catch (err) {
    yield put(updateBankAccountPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBankAccountPix({ payload }) {
  try {
    const { bank_account_id, bank_account_pix_id } = payload;

    yield call(
      api.delete,
      `bank-accounts/${bank_account_id}/pixs/${bank_account_pix_id}`
    );

    yield put(deleteBankAccountPixSuccess(bank_account_pix_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteBankAccountPixFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.BANK_ACCOUNT_PIXS_CREATE_REQUEST, createBankAccountPix),
  takeLatest(Types.BANK_ACCOUNT_PIXS_UPDATE_REQUEST, updateBankAccountPix),
  takeLatest(Types.BANK_ACCOUNT_PIXS_DELETE_REQUEST, deleteBankAccountPix),
]);
