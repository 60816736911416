import produce from 'immer';

import PriceListTypes from '../priceLists/types';
import Types from './types';

const INITIAL_STATE = {
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,
  priceListItemFilterModalOpen: false,
  priceListItemNewModalOpen: false,

  loading: false,
  priceListItemModalOpen: false,
};

export default function priceListItems(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PRICE_LIST_ITEMS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRICE_LIST_ITEMS_CREATE_SUCCESS: {
        draft.loading = false;
        draft.priceListItemNewModalOpen = false;

        break;
      }
      case Types.PRICE_LIST_ITEMS_CREATE_FAILURE: {
        draft.loading = false;

        break;
      }

      case Types.PRICE_LIST_ITEMS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRICE_LIST_ITEMS_UPDATE_SUCCESS: {
        draft.detail = {};
        draft.loading = false;
        draft.priceListItemModalOpen = false;

        break;
      }
      case Types.PRICE_LIST_ITEMS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRICE_LIST_ITEMS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PRICE_LIST_ITEMS_DELETE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.PRICE_LIST_ITEMS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PRICE_LIST_ITEMS_RESET_LIST: {
        break;
      }

      case Types.PRICE_LIST_ITEMS_OPEN_MODAL: {
        const { item } = action.payload;

        draft.detail = {};

        if (item) {
          draft.detail = item;
        }

        draft.priceListItemModalOpen = true;
        break;
      }
      case Types.PRICE_LIST_ITEMS_CLOSE_MODAL: {
        draft.priceListItemModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.PRICE_LIST_ITEMS_FILTER_OPEN_MODAL: {
        draft.priceListItemFilterModalOpen = true;
        break;
      }

      case Types.PRICE_LIST_ITEMS_FILTER_CLOSE_MODAL: {
        draft.priceListItemFilterModalOpen = false;
        break;
      }

      case Types.PRICE_LIST_ITEMS_OPEN_NEW_ITEM_MODAL: {
        draft.priceListItemNewModalOpen = true;
        break;
      }

      case Types.PRICE_LIST_ITEMS_CLOSE_NEW_ITEM_MODAL: {
        draft.priceListItemNewModalOpen = false;
        break;
      }

      case Types.PRICE_LIST_ITEMS_SET_TABLE_FILTERS: {
        const {
          modalFilters,
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
        } = action.payload;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        if (typeof rowsPerPage !== 'undefined') {
          draft.rowsPerPage = rowsPerPage;
        }

        if (typeof currentPage !== 'undefined') {
          draft.page = currentPage;
        }

        if (closeModal) {
          draft.priceListItemFilterModalOpen = false;
        }

        break;
      }

      case PriceListTypes.PRICE_LISTS_RESET_DETAILS: {
        draft.detail = {};
        draft.priceListItemModalOpen = false;

        draft.filter = {};
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.priceListItemFilterModalOpen = false;
        draft.priceListItemNewModalOpen = false;
        break;
      }

      default:
    }
  });
}
