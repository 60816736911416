import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCompanyFileSuccess,
  createCompanyFileFailure,
  updateCompanyFileSuccess,
  updateCompanyFileFailure,
  deleteCompanyFileSuccess,
  deleteCompanyFileFailure,
} from './actions';
import Types from './types';

export function* createCompanyFile({ payload }) {
  try {
    const { company_id, file } = payload;

    const response = yield call(
      api.post,
      `companies/${company_id}/files`,
      file
    );

    yield put(createCompanyFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCompanyFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCompanyFile({ payload }) {
  try {
    const { company_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `companies/${company_id}/files/${file_id}`,
      newFile
    );

    yield put(updateCompanyFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCompanyFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCompanyFile({ payload }) {
  try {
    const { company_id, file_id } = payload;

    yield call(api.delete, `companies/${company_id}/files/${file_id}`);

    yield put(deleteCompanyFileSuccess(file_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCompanyFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.COMPANY_FILES_CREATE_REQUEST, createCompanyFile),
  takeLatest(Types.COMPANY_FILES_UPDATE_REQUEST, updateCompanyFile),
  takeLatest(Types.COMPANY_FILES_DELETE_REQUEST, deleteCompanyFile),
]);
