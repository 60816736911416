import Types from './types';

export function getTransporterFiles(files) {
  return {
    type: Types.TRANSPORTER_FILES_GET,
    payload: { files },
  };
}

export function createTransporterFileRequest(transporter_id, file) {
  return {
    type: Types.TRANSPORTER_FILES_CREATE_REQUEST,
    payload: { transporter_id, file },
  };
}

export function createTransporterFileSuccess(file) {
  return {
    type: Types.TRANSPORTER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createTransporterFileFailure() {
  return {
    type: Types.TRANSPORTER_FILES_CREATE_FAILURE,
  };
}

export function updateTransporterFileRequest(transporter_id, file_id, newFile) {
  return {
    type: Types.TRANSPORTER_FILES_UPDATE_REQUEST,
    payload: { transporter_id, file_id, newFile },
  };
}

export function updateTransporterFileSuccess(newFile) {
  return {
    type: Types.TRANSPORTER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateTransporterFileFailure() {
  return {
    type: Types.TRANSPORTER_FILES_UPDATE_FAILURE,
  };
}

export function deleteTransporterFileRequest(transporter_id, file_id) {
  return {
    type: Types.TRANSPORTER_FILES_DELETE_REQUEST,
    payload: { transporter_id, file_id },
  };
}

export function deleteTransporterFileSuccess(file_id) {
  return {
    type: Types.TRANSPORTER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteTransporterFileFailure() {
  return {
    type: Types.TRANSPORTER_FILES_DELETE_FAILURE,
  };
}

export function openModalTransporterFile() {
  return {
    type: Types.TRANSPORTER_FILES_OPEN_MODAL,
  };
}

export function closeModalTransporterFile() {
  return {
    type: Types.TRANSPORTER_FILES_CLOSE_MODAL,
  };
}

export function resetListTransporterFile() {
  return {
    type: Types.TRANSPORTER_FILES_RESET_LIST,
  };
}
