const Types = {
  COMPANIES_GET_REQUEST: '@companies/GET_COMPANIES_REQUEST',
  COMPANIES_GET_SUCCESS: '@companies/GET_COMPANIES_SUCCESS',
  COMPANIES_GET_FAILURE: '@companies/GET_COMPANIES_FAILURE',

  COMPANIES_CREATE_REQUEST: '@companies/CREATE_COMPANY_REQUEST',
  COMPANIES_CREATE_SUCCESS: '@companies/CREATE_COMPANY_SUCCESS',
  COMPANIES_CREATE_FAILURE: '@companies/CREATE_COMPANY_FAILURE',

  COMPANIES_UPDATE_REQUEST: '@companies/UPDATE_COMPANY_REQUEST',
  COMPANIES_UPDATE_SUCCESS: '@companies/UPDATE_COMPANY_SUCCESS',
  COMPANIES_UPDATE_FAILURE: '@companies/UPDATE_COMPANY_FAILURE',

  COMPANIES_DELETE_REQUEST: '@companies/DELETE_COMPANY_REQUEST',
  COMPANIES_DELETE_SUCCESS: '@companies/DELETE_COMPANY_SUCCESS',
  COMPANIES_DELETE_FAILURE: '@companies/DELETE_COMPANY_FAILURE',

  // COMPANIES_BECOME_REQUEST: '@companies/BECOME_COMPANY_REQUEST',
  // COMPANIES_BECOME_SUCCESS: '@companies/BECOME_COMPANY_SUCCESS',
  // COMPANIES_BECOME_FAILURE: '@companies/BECOME_COMPANY_FAILURE',

  COMPANIES_GET_DETAILS_REQUEST: '@companies/GET_DETAILS_COMPANY_REQUEST',
  COMPANIES_GET_DETAILS_SUCCESS: '@companies/GET_DETAILS_COMPANY_SUCCESS',
  COMPANIES_GET_DETAILS_FAILURE: '@companies/GET_DETAILS_COMPANY_FAILURE',

  COMPANIES_RESET_DETAILS: '@companies/RESET_DETAILS_COMPANY',

  COMPANIES_OBSERVATION_OPEN_MODAL: '@companies/OPEN_MODAL_COMPANY_OBSERVATION',
  COMPANIES_OBSERVATION_CLOSE_MODAL:
    '@companies/CLOSE_MODAL_COMPANY_OBSERVATION',

  COMPANIES_CLEAR_NEW_BRANCH_ACTIVITY:
    '@companies/CLEAR_NEW_COMPANY_BRANCH_ACTIVITY',
  COMPANIES_CLEAR_NEW_COVENANT: '@companies/CLEAR_NEW_COMPANY_COVENANT',

  COMPANIES_OPEN_FILE_DIALOG: '@companies/OPEN_COMPANY_FILE_DIALOG',
  COMPANIES_RESET_SUB_RESOURCE_DATA:
    '@companies/RESET_SUB_RESOURCE_DATA_COMPANY',
};

export default Types;
