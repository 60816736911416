import React from 'react';
import { FaStar as Icon } from 'react-icons/fa';

import styled from 'styled-components';

import colors from '../../../theme/colors';

function defineStyles(type, active) {
  let styles;

  const color = active ? colors.warning : colors.lighterRegular;

  switch (type) {
    case 'table':
      styles = `
        color: ${color};
        width: 20px;
        height: 20px;
      `;
      break;
    case 'form':
      styles = `
        color: ${color};
        width: 20px;
        height: 20px;
      `;
      break;
    case 'modal':
      styles = `
        color: ${color};
        width: 100%;
        height: 100%;
        max-width: 20px;
        max-height: 20px;
        margin-bottom: 4px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Star = styled(({ active: _active, type: _type, ...other }) => (
  <Icon {...other} />
))`
  ${({ type, active }) => {
    return defineStyles(type, active);
  }}
`;
