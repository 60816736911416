import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getNcmsSuccess, getNcmsFailure } from './actions';
import Types from './types';

export function* getNcms({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `ncms${queryParams}`);

    yield put(
      getNcmsSuccess({ data, modalFilters, filterColumnsOrder, filterSearch })
    );
  } catch (err) {
    yield put(getNcmsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export default all([takeLatest(Types.NCMS_GET_REQUEST, getNcms)]);
