import Types from './types';

export function signInRequest(login, password) {
  return {
    type: Types.SIGN_IN_REQUEST,
    payload: { login, password },
  };
}

export function signInSuccess(token, user, companies, refreshToken) {
  return {
    type: Types.SIGN_IN_SUCCESS,
    payload: { token, user, companies, refreshToken },
  };
}

export function signInFailure() {
  return {
    type: Types.SIGN_IN_FAILURE,
  };
}

export function signUpRequest(name, username, email, password) {
  return {
    type: Types.SIGN_UP_REQUEST,
    payload: { name, username, email, password },
  };
}

export function signUpSuccess() {
  return {
    type: Types.SIGN_UP_SUCCESS,
  };
}

export function signUpFailure() {
  return {
    type: Types.SIGN_UP_FAILURE,
  };
}

export function openModalSignUp() {
  return {
    type: Types.SIGN_UP_OPEN_MODAL,
  };
}

export function closeModalSignUp() {
  return {
    type: Types.SIGN_UP_CLOSE_MODAL,
  };
}

export function signOut() {
  return {
    type: Types.SIGN_OUT,
  };
}

export function signOutTokenInvalid() {
  return {
    type: Types.SIGN_OUT_TOKEN_INVALID,
  };
}

export function setSelectedCompany(company) {
  return {
    type: Types.COMPANY_SET_SELECTED,
    payload: { company },
  };
}

export function clearSelectedCompany() {
  return {
    type: Types.COMPANY_CLEAR_SELECTED,
  };
}

export function refreshAuthToken(token) {
  return {
    type: Types.AUTH_REFRESH_TOKEN,
    payload: { token },
  };
}
