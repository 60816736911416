import Types from './types';

export function getSellerEmails(emails) {
  return {
    type: Types.SELLER_EMAILS_GET,
    payload: { emails },
  };
}

export function createSellerEmailRequest(seller_id, email) {
  return {
    type: Types.SELLER_EMAILS_CREATE_REQUEST,
    payload: { seller_id, email },
  };
}

export function createSellerEmailSuccess(email) {
  return {
    type: Types.SELLER_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createSellerEmailFailure() {
  return {
    type: Types.SELLER_EMAILS_CREATE_FAILURE,
  };
}

export function updateSellerEmailRequest(seller_id, email_id, newEmail) {
  return {
    type: Types.SELLER_EMAILS_UPDATE_REQUEST,
    payload: { seller_id, email_id, newEmail },
  };
}

export function updateSellerEmailSuccess(newEmail) {
  return {
    type: Types.SELLER_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateSellerEmailFailure() {
  return {
    type: Types.SELLER_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteSellerEmailRequest(seller_id, email_id) {
  return {
    type: Types.SELLER_EMAILS_DELETE_REQUEST,
    payload: { seller_id, email_id },
  };
}

export function deleteSellerEmailSuccess(email_id) {
  return {
    type: Types.SELLER_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteSellerEmailFailure() {
  return {
    type: Types.SELLER_EMAILS_DELETE_FAILURE,
  };
}

export function resetListSellerEmail() {
  return {
    type: Types.SELLER_EMAILS_RESET_LIST,
  };
}

export function openModalSellerEmail(email_id) {
  return {
    type: Types.SELLER_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalSellerEmail() {
  return {
    type: Types.SELLER_EMAILS_CLOSE_MODAL,
  };
}
