import styled from 'styled-components';

import { Archive as MuiArchive } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'table':
      styles = `color: ${colors.darkRegular};`;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Archive = styled(MuiArchive)`
  ${({ type }) => defineStyles(type)}
`;
