import Types from './types';

export function getCustomerFiles(files) {
  return {
    type: Types.CUSTOMER_FILES_GET,
    payload: { files },
  };
}

export function createCustomerFileRequest(customer_id, file) {
  return {
    type: Types.CUSTOMER_FILES_CREATE_REQUEST,
    payload: { customer_id, file },
  };
}

export function createCustomerFileSuccess(file) {
  return {
    type: Types.CUSTOMER_FILES_CREATE_SUCCESS,
    payload: { file },
  };
}

export function createCustomerFileFailure() {
  return {
    type: Types.CUSTOMER_FILES_CREATE_FAILURE,
  };
}

export function updateCustomerFileRequest(customer_id, file_id, newFile) {
  return {
    type: Types.CUSTOMER_FILES_UPDATE_REQUEST,
    payload: { customer_id, file_id, newFile },
  };
}

export function updateCustomerFileSuccess(newFile) {
  return {
    type: Types.CUSTOMER_FILES_UPDATE_SUCCESS,
    payload: { newFile },
  };
}

export function updateCustomerFileFailure() {
  return {
    type: Types.CUSTOMER_FILES_UPDATE_FAILURE,
  };
}

export function deleteCustomerFileRequest(customer_id, file_id) {
  return {
    type: Types.CUSTOMER_FILES_DELETE_REQUEST,
    payload: { customer_id, file_id },
  };
}

export function deleteCustomerFileSuccess(file_id) {
  return {
    type: Types.CUSTOMER_FILES_DELETE_SUCCESS,
    payload: { file_id },
  };
}

export function deleteCustomerFileFailure() {
  return {
    type: Types.CUSTOMER_FILES_DELETE_FAILURE,
  };
}

export function openModalCustomerFile() {
  return {
    type: Types.CUSTOMER_FILES_OPEN_MODAL,
  };
}

export function closeModalCustomerFile() {
  return {
    type: Types.CUSTOMER_FILES_CLOSE_MODAL,
  };
}

export function resetListCustomerFile() {
  return {
    type: Types.CUSTOMER_FILES_RESET_LIST,
  };
}
