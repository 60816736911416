import { MdContentCopy } from 'react-icons/md';

import styled from 'styled-components';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'button':
      styles = `
        width: 20px;
        height: 20px;
        margin-left: 5px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Copy = styled(MdContentCopy)`
  ${({ type }) => defineStyles(type)}
`;
