import React from 'react';

import PropTypes from 'prop-types';

import { Avatar } from './styles';

export default function ImageAvatar({ src, alt, variant, icon }) {
  const firstLetter = alt ? alt.charAt(0) : '';

  return (
    <Avatar src={src} variant={variant}>
      {icon || firstLetter}
    </Avatar>
  );
}

ImageAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  variant: PropTypes.oneOf(['circle', 'rounded']),
  icon: PropTypes.element,
};

ImageAvatar.defaultProps = {
  src: '',
  alt: '',
  variant: 'circle',
  icon: null,
};
