import Types from './types';

export function getBillToReceiveChartAccounts(chartAccounts) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_GET,
    payload: { chartAccounts },
  };
}

export function createBillToReceiveChartAccountRequest(
  bill_to_receive_id,
  chartAccount
) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_CREATE_REQUEST,
    payload: { bill_to_receive_id, chartAccount },
  };
}

export function createBillToReceiveChartAccountSuccess(chartAccount) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_CREATE_SUCCESS,
    payload: { chartAccount },
  };
}

export function createBillToReceiveChartAccountFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_CREATE_FAILURE,
  };
}

export function updateBillToReceiveChartAccountRequest(
  bill_to_receive_id,
  chart_account_id,
  newChartAccount
) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_UPDATE_REQUEST,
    payload: { bill_to_receive_id, chart_account_id, newChartAccount },
  };
}

export function updateBillToReceiveChartAccountSuccess(newChartAccount) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_UPDATE_SUCCESS,
    payload: { newChartAccount },
  };
}

export function updateBillToReceiveChartAccountFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_UPDATE_FAILURE,
  };
}

export function deleteBillToReceiveChartAccountRequest(
  bill_to_receive_id,
  chart_account_id
) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_DELETE_REQUEST,
    payload: { bill_to_receive_id, chart_account_id },
  };
}

export function deleteBillToReceiveChartAccountSuccess(chart_account_id) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_DELETE_SUCCESS,
    payload: { chart_account_id },
  };
}

export function deleteBillToReceiveChartAccountFailure() {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_DELETE_FAILURE,
  };
}

export function resetListBillToReceiveChartAccount() {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_RESET_LIST,
  };
}

export function openModalBillToReceiveChartAccount(chart_account_id) {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_OPEN_MODAL,
    payload: { chart_account_id },
  };
}

export function closeModalBillToReceiveChartAccount() {
  return {
    type: Types.BILL_TO_RECEIVE_CHART_ACCOUNTS_CLOSE_MODAL,
  };
}
