import styled from 'styled-components';

import {
  Toolbar as MuiToolbar,
  TextField as MuiTextField,
  MenuItem,
} from '@material-ui/core';

import breakpoints from '~/theme/breakpoints';

const smBreakpoint = `${breakpoints.values.sm}px`;

export const Toolbar = styled(MuiToolbar)`
  padding: 0 4px 0 12px;
  @media (max-width: ${smBreakpoint}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const TextField = styled(MuiTextField).attrs({
  fullWidth: true,
})`
  padding-left: 8px;
  max-width: 230px;
  transition: max-width 0.4s ease;

  ${({ search_field_alignment }) => {
    return search_field_alignment === 'right'
      ? `margin-left: auto`
      : `margin-right: auto`;
  }}

  &:focus-within {
    max-width: 260px;
  }

  @media (max-width: ${smBreakpoint}) {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;

    &:focus-within {
      max-width: 90%;
    }
  }

  @media (max-width: 300px) {
    &:focus-within {
      max-width: 100%;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColumnsButtonMenuItem = styled(MenuItem)`
  padding: 0 4px;
`;
