import Types from './types';

export function getContactsRequest() {
  return {
    type: Types.CONTACTS_GET_REQUEST,
  };
}

export function getContactsSuccess(contacts) {
  return {
    type: Types.CONTACTS_GET_SUCCESS,
    payload: { contacts },
  };
}

export function getContactsFailure() {
  return {
    type: Types.CONTACTS_GET_FAILURE,
  };
}

export function getDetailsContactRequest(id) {
  return {
    type: Types.CONTACTS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsContactSuccess(contact) {
  return {
    type: Types.CONTACTS_GET_DETAILS_SUCCESS,
    payload: { contact },
  };
}

export function getDetailsContactFailure() {
  return {
    type: Types.CONTACTS_GET_DETAILS_FAILURE,
  };
}

export function resetDetailsContact() {
  return {
    type: Types.CONTACTS_RESET_DETAILS,
  };
}

export function openModalContact() {
  return {
    type: Types.CONTACTS_OPEN_MODAL,
  };
}

export function closeModalContact() {
  return {
    type: Types.CONTACTS_CLOSE_MODAL,
  };
}

export function openModalDetailContact(source) {
  return {
    type: Types.CONTACTS_DETAIL_OPEN_MODAL,
    payload: { source },
  };
}

export function closeModalDetailContact() {
  return {
    type: Types.CONTACTS_DETAIL_CLOSE_MODAL,
  };
}
export function resetContactList() {
  return {
    type: Types.CONTACTS_RESET_LIST,
  };
}
