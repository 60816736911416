import { FaStamp } from 'react-icons/fa';

import styled from 'styled-components';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'table':
      styles = `
        color: ${colors.darkRegular};
        width: 25px;
        height: 25px;
        padding: 3px;
      `;
      break;
    case 'info':
      styles = ``;
      break;
    case 'okButton':
      styles = `
        width: 19px;
        height: 19px;
        padding: 2px;
        margin-bottom: 3px;
        margin-right: 3px;
      `;

      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Stamp = styled(FaStamp)`
  ${({ type }) => defineStyles(type)}
`;
