import Types from './types';

export function getStatesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.STATES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getStatesSuccess({
  data,
  queryParams,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.STATES_GET_SUCCESS,
    payload: { data, queryParams, filterColumnsOrder, filterSearch },
  };
}

export function getStatesFailure() {
  return {
    type: Types.STATES_GET_FAILURE,
  };
}

export function resetListStates() {
  return {
    type: Types.STATES_RESET_LIST,
  };
}
