import React from 'react';

import PropTypes from 'prop-types';

import { Delete } from './styles';

export default function DeleteIcon({ type }) {
  return <Delete type={type} />;
}

DeleteIcon.propTypes = {
  type: PropTypes.string,
};

DeleteIcon.defaultProps = {
  type: '',
};
