import Types from './types';

export function getEmployeeEmails(emails) {
  return {
    type: Types.EMPLOYEE_EMAILS_GET,
    payload: { emails },
  };
}

export function createEmployeeEmailRequest(employee_id, email) {
  return {
    type: Types.EMPLOYEE_EMAILS_CREATE_REQUEST,
    payload: { employee_id, email },
  };
}

export function createEmployeeEmailSuccess(email) {
  return {
    type: Types.EMPLOYEE_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createEmployeeEmailFailure() {
  return {
    type: Types.EMPLOYEE_EMAILS_CREATE_FAILURE,
  };
}

export function updateEmployeeEmailRequest(employee_id, email_id, newEmail) {
  return {
    type: Types.EMPLOYEE_EMAILS_UPDATE_REQUEST,
    payload: { employee_id, email_id, newEmail },
  };
}

export function updateEmployeeEmailSuccess(newEmail) {
  return {
    type: Types.EMPLOYEE_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateEmployeeEmailFailure() {
  return {
    type: Types.EMPLOYEE_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteEmployeeEmailRequest(employee_id, email_id) {
  return {
    type: Types.EMPLOYEE_EMAILS_DELETE_REQUEST,
    payload: { employee_id, email_id },
  };
}

export function deleteEmployeeEmailSuccess(email_id) {
  return {
    type: Types.EMPLOYEE_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteEmployeeEmailFailure() {
  return {
    type: Types.EMPLOYEE_EMAILS_DELETE_FAILURE,
  };
}

export function resetListEmployeeEmail() {
  return {
    type: Types.EMPLOYEE_EMAILS_RESET_LIST,
  };
}

export function openModalEmployeeEmail(email_id) {
  return {
    type: Types.EMPLOYEE_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalEmployeeEmail() {
  return {
    type: Types.EMPLOYEE_EMAILS_CLOSE_MODAL,
  };
}
