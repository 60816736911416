import Types from './types';

export function getBillToPayCostCenters(costCenters) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_GET,
    payload: { costCenters },
  };
}

export function createBillToPayCostCenterRequest(bill_to_pay_id, costCenter) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_CREATE_REQUEST,
    payload: { bill_to_pay_id, costCenter },
  };
}

export function createBillToPayCostCenterSuccess(costCenter) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_CREATE_SUCCESS,
    payload: { costCenter },
  };
}

export function createBillToPayCostCenterFailure() {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_CREATE_FAILURE,
  };
}

export function updateBillToPayCostCenterRequest(
  bill_to_pay_id,
  cost_center_id,
  newCostCenter
) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_UPDATE_REQUEST,
    payload: { bill_to_pay_id, cost_center_id, newCostCenter },
  };
}

export function updateBillToPayCostCenterSuccess(newCostCenter) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_UPDATE_SUCCESS,
    payload: { newCostCenter },
  };
}

export function updateBillToPayCostCenterFailure() {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_UPDATE_FAILURE,
  };
}

export function deleteBillToPayCostCenterRequest(
  bill_to_pay_id,
  cost_center_id
) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_DELETE_REQUEST,
    payload: { bill_to_pay_id, cost_center_id },
  };
}

export function deleteBillToPayCostCenterSuccess(cost_center_id) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_DELETE_SUCCESS,
    payload: { cost_center_id },
  };
}

export function deleteBillToPayCostCenterFailure() {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_DELETE_FAILURE,
  };
}

export function resetListBillToPayCostCenter() {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_RESET_LIST,
  };
}

export function openModalBillToPayCostCenter(cost_center_id) {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_OPEN_MODAL,
    payload: { cost_center_id },
  };
}

export function closeModalBillToPayCostCenter() {
  return {
    type: Types.BILL_TO_PAY_COST_CENTERS_CLOSE_MODAL,
  };
}
