import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCovenantsSuccess,
  getCovenantsFailure,
  createCovenantSuccess,
  createCovenantFailure,
  updateCovenantSuccess,
  updateCovenantFailure,
  deleteCovenantSuccess,
  deleteCovenantFailure,
} from './actions';
import Types from './types';

export function* getCovenants({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `covenants${queryParams}`);

    yield put(getCovenantsSuccess({ data, filterColumnsOrder, filterSearch }));
  } catch (err) {
    yield put(getCovenantsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCovenant({ payload }) {
  try {
    const { covenant, source } = payload;

    const response = yield call(api.post, 'covenants', covenant);

    yield put(createCovenantSuccess(response.data, source));

    if (source) {
      toast.success(<IntlMessages id="covenants.create-covenant-success" />);
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createCovenantFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCovenant({ payload }) {
  try {
    const { id, newCovenant } = payload;

    const response = yield call(api.put, `covenants/${id}`, newCovenant);

    yield put(updateCovenantSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCovenantFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCovenant({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `covenants/${id}`);

    yield put(deleteCovenantSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCovenantFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.COVENANTS_GET_REQUEST, getCovenants),
  takeLatest(Types.COVENANTS_CREATE_REQUEST, createCovenant),
  takeLatest(Types.COVENANTS_UPDATE_REQUEST, updateCovenant),
  takeLatest(Types.COVENANTS_DELETE_REQUEST, deleteCovenant),
]);
