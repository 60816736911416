import PerfectScrollbar from 'react-perfect-scrollbar';

import styled from 'styled-components';

import { darken } from 'polished';

import '~/vendor/perfect-scrollbar.css';

import {
  Grid as MuiGrid,
  ListItem,
  Drawer as MuiDrawer,
  List as MuiList,
  Typography,
  Avatar,
} from '@material-ui/core';

export const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

export const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

export const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

export const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

export const Brand = styled(ListItem)`
  background-color: ${props => props.theme.sidebar.header.background};
  min-height: 56px;
  padding-right: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  cursor: pointer;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`;

export const BrandCompany = styled.img`
  color: ${props => props.theme.sidebar.header.brand.color};
  /* TODO: se for o logotipo original so substituir
           os estilos debaixo por esta linha:
  width: 120px; */

  width: 140px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const BrandAvatar = styled(Avatar).attrs({ variant: 'rounded' })`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

export const Title = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.sidebar.color};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const Subtitle = styled(Typography)`
  font-size: 12px;
  color: ${props => darken(0.2, props.theme.sidebar.color)};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const Grid = styled(MuiGrid).attrs({
  spacing: 2,
})``;

export const GridItemCustom = styled(MuiGrid)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

export const SidebarFooter = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;
