import Types from './types';

export function getDevicesRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.DEVICES_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getDevicesSuccess({
  data,
  isAllDevicesRoute = false,
  queryParams,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.DEVICES_GET_SUCCESS,
    payload: {
      data,
      isAllDevicesRoute,
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getDevicesFailure() {
  return {
    type: Types.DEVICES_GET_FAILURE,
  };
}

export function resetListDevices() {
  return {
    type: Types.DEVICES_RESET_LIST,
  };
}
