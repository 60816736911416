import Types from './types';

export function getPromoterAddresses(addresses) {
  return {
    type: Types.PROMOTER_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createPromoterAddressRequest(promoter_id, address) {
  return {
    type: Types.PROMOTER_ADDRESSES_CREATE_REQUEST,
    payload: { promoter_id, address },
  };
}

export function createPromoterAddressSuccess(address) {
  return {
    type: Types.PROMOTER_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createPromoterAddressFailure() {
  return {
    type: Types.PROMOTER_ADDRESSES_CREATE_FAILURE,
  };
}

export function updatePromoterAddressRequest(
  promoter_id,
  address_id,
  newAddress
) {
  return {
    type: Types.PROMOTER_ADDRESSES_UPDATE_REQUEST,
    payload: { promoter_id, address_id, newAddress },
  };
}

export function updatePromoterAddressSuccess(newAddress) {
  return {
    type: Types.PROMOTER_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updatePromoterAddressFailure() {
  return {
    type: Types.PROMOTER_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deletePromoterAddressRequest(promoter_id, address_id) {
  return {
    type: Types.PROMOTER_ADDRESSES_DELETE_REQUEST,
    payload: { promoter_id, address_id },
  };
}

export function deletePromoterAddressSuccess(address_id) {
  return {
    type: Types.PROMOTER_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deletePromoterAddressFailure() {
  return {
    type: Types.PROMOTER_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListPromoterAddress() {
  return {
    type: Types.PROMOTER_ADDRESSES_RESET_LIST,
  };
}

export function openModalPromoterAddress(address_id) {
  return {
    type: Types.PROMOTER_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalPromoterAddress() {
  return {
    type: Types.PROMOTER_ADDRESSES_CLOSE_MODAL,
  };
}
