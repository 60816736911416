import Types from './types';

export function getTeamMembers(members) {
  return {
    type: Types.TEAM_MEMBERS_GET,
    payload: { members },
  };
}

export function updateTeamMemberRequest(team_id, members) {
  return {
    type: Types.TEAM_MEMBERS_UPDATE_REQUEST,
    payload: { team_id, members },
  };
}

export function updateTeamMemberSuccess(members) {
  return {
    type: Types.TEAM_MEMBERS_UPDATE_SUCCESS,
    payload: { members },
  };
}

export function updateTeamMemberFailure() {
  return {
    type: Types.TEAM_MEMBERS_UPDATE_FAILURE,
  };
}

export function deleteTeamMemberRequest(team_id, team_member_id) {
  return {
    type: Types.TEAM_MEMBERS_DELETE_REQUEST,
    payload: { team_id, team_member_id },
  };
}

export function deleteTeamMemberSuccess(team_member_id) {
  return {
    type: Types.TEAM_MEMBERS_DELETE_SUCCESS,
    payload: { team_member_id },
  };
}

export function deleteTeamMemberFailure() {
  return {
    type: Types.TEAM_MEMBERS_DELETE_FAILURE,
  };
}

export function resetListTeamMember() {
  return {
    type: Types.TEAM_MEMBERS_RESET_LIST,
  };
}

export function openModalTeamMember() {
  return {
    type: Types.TEAM_MEMBERS_OPEN_MODAL,
  };
}

export function closeModalTeamMember() {
  return {
    type: Types.TEAM_MEMBERS_CLOSE_MODAL,
  };
}
