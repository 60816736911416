const Types = {
  PROMOTERS_GET_REQUEST: '@promoters/GET_PROMOTERS_REQUEST',
  PROMOTERS_GET_SUCCESS: '@promoters/GET_PROMOTERS_SUCCESS',
  PROMOTERS_GET_FAILURE: '@promoters/GET_PROMOTERS_FAILURE',

  PROMOTERS_CREATE_REQUEST: '@promoters/CREATE_PROMOTER_REQUEST',
  PROMOTERS_CREATE_SUCCESS: '@promoters/CREATE_PROMOTER_SUCCESS',
  PROMOTERS_CREATE_FAILURE: '@promoters/CREATE_PROMOTER_FAILURE',

  PROMOTERS_UPDATE_REQUEST: '@promoters/UPDATE_PROMOTER_REQUEST',
  PROMOTERS_UPDATE_SUCCESS: '@promoters/UPDATE_PROMOTER_SUCCESS',
  PROMOTERS_UPDATE_FAILURE: '@promoters/UPDATE_PROMOTER_FAILURE',

  PROMOTERS_DELETE_REQUEST: '@promoters/DELETE_PROMOTER_REQUEST',
  PROMOTERS_DELETE_SUCCESS: '@promoters/DELETE_PROMOTER_SUCCESS',
  PROMOTERS_DELETE_FAILURE: '@promoters/DELETE_PROMOTER_FAILURE',

  PROMOTERS_BECOME_REQUEST: '@promoters/BECOME_PROMOTER_REQUEST',
  PROMOTERS_BECOME_SUCCESS: '@promoters/BECOME_PROMOTER_SUCCESS',
  PROMOTERS_BECOME_FAILURE: '@promoters/BECOME_PROMOTER_FAILURE',

  PROMOTERS_GET_DETAILS_REQUEST: '@promoters/GET_DETAILS_PROMOTER_REQUEST',
  PROMOTERS_GET_DETAILS_SUCCESS: '@promoters/GET_DETAILS_PROMOTER_SUCCESS',
  PROMOTERS_GET_DETAILS_FAILURE: '@promoters/GET_DETAILS_PROMOTER_FAILURE',

  PROMOTERS_RESET_LIST: '@promoters/RESET_LIST_PROMOTERS',
  PROMOTERS_RESET_DETAILS: '@promoters/RESET_DETAILS_PROMOTER',

  PROMOTERS_SET_SAVING_ON_SUB_RESOURCE:
    '@promoters/SET_SAVING_ON_SUB_RESOURCE_PROMOTER',
  PROMOTERS_RESET_SUB_RESOURCE_DATA:
    '@promoters/RESET_SUB_RESOURCE_DATA_PROMOTER',

  PROMOTERS_ADD_OPEN_MODAL: '@promoters/OPEN_MODAL_ADD_PROMOTER',
  PROMOTERS_ADD_CLOSE_MODAL: '@promoters/CLOSE_MODAL_ADD_PROMOTER',

  PROMOTERS_FILTER_OPEN_MODAL: '@promoters/OPEN_MODAL_FILTER_PROMOTER',
  PROMOTERS_FILTER_CLOSE_MODAL: '@promoters/CLOSE_MODAL_FILTER_PROMOTER',

  PROMOTERS_OBSERVATION_OPEN_MODAL:
    '@promoters/OPEN_MODAL_PROMOTER_OBSERVATION',
  PROMOTERS_OBSERVATION_CLOSE_MODAL:
    '@promoters/CLOSE_MODAL_PROMOTER_OBSERVATION',

  PROMOTERS_OPEN_FILE_DIALOG: '@promoters/OPEN_PROMOTER_FILE_DIALOG',
};

export default Types;
