import styled from 'styled-components';

import { Group as MuiGroup } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'report':
      styles = ``;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Group = styled(MuiGroup)`
  ${({ type }) => defineStyles(type)}
`;
