import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createPromoterEmailSuccess,
  createPromoterEmailFailure,
  updatePromoterEmailSuccess,
  updatePromoterEmailFailure,
  deletePromoterEmailSuccess,
  deletePromoterEmailFailure,
} from './actions';
import Types from './types';

export function* createPromoterEmail({ payload }) {
  try {
    const { promoter_id, email } = payload;

    const response = yield call(
      api.post,
      `promoters/${promoter_id}/emails`,
      email
    );

    yield put(createPromoterEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createPromoterEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePromoterEmail({ payload }) {
  try {
    const { promoter_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `promoters/${promoter_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updatePromoterEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updatePromoterEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePromoterEmail({ payload }) {
  const { promoter_id, email_id } = payload;

  const emails = yield select(state => state.promoterEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deletePromoterEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `promoters/${promoter_id}/emails/${email_id}`);

    yield put(deletePromoterEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePromoterEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.PROMOTER_EMAILS_CREATE_REQUEST, createPromoterEmail),
  takeLatest(Types.PROMOTER_EMAILS_UPDATE_REQUEST, updatePromoterEmail),
  takeLatest(Types.PROMOTER_EMAILS_DELETE_REQUEST, deletePromoterEmail),
]);
