const Types = {
  OWNER_PHONES_GET: '@owners/GET_OWNER_PHONES',

  OWNER_PHONES_CREATE_REQUEST: '@owners/CREATE_OWNER_PHONE_REQUEST',
  OWNER_PHONES_CREATE_SUCCESS: '@owners/CREATE_OWNER_PHONE_SUCCESS',
  OWNER_PHONES_CREATE_FAILURE: '@owners/CREATE_OWNER_PHONE_FAILURE',

  OWNER_PHONES_UPDATE_REQUEST: '@owners/UPDATE_OWNER_PHONE_REQUEST',
  OWNER_PHONES_UPDATE_SUCCESS: '@owners/UPDATE_OWNER_PHONE_SUCCESS',
  OWNER_PHONES_UPDATE_FAILURE: '@owners/UPDATE_OWNER_PHONE_FAILURE',

  OWNER_PHONES_DELETE_REQUEST: '@owners/DELETE_OWNER_PHONE_REQUEST',
  OWNER_PHONES_DELETE_SUCCESS: '@owners/DELETE_OWNER_PHONE_SUCCESS',
  OWNER_PHONES_DELETE_FAILURE: '@owners/DELETE_OWNER_PHONE_FAILURE',

  OWNER_PHONES_RESET_LIST: '@owners/RESET_LIST_OWNER_PHONE',

  OWNER_PHONES_OPEN_MODAL: '@owners/OPEN_MODAL_OWNER_PHONE',
  OWNER_PHONES_CLOSE_MODAL: '@owners/CLOSE_MODAL_OWNER_PHONE',
};

export default Types;
