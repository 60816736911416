import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink as RouterNavLink, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Collapse } from '@material-ui/core';

import companyLogo from '~/assets/img/logo/logo-company.png';
import { BusinessIcon } from '~/components/Icons';
import routes from '~/routes';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';
import {
  Drawer,
  Scrollbar,
  List,
  Items,
  Brand,
  BrandAvatar,
  BrandCompany,
  Title,
  Subtitle,
  SidebarSection,
  SidebarFooter,
  Grid,
  GridItemCustom,
} from './styles';

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

/* TODO: Fazer as proptypes das propriedades e trocar as keys para algo que não seja o index  */
function Sidebar({ location, ...rest }) {
  // This prop is not used anywhere and if its not deleted, an error appears on console
  delete rest.staticContext;

  const company = useSelector(state => state.auth.company);

  const companyAvatar = company.files?.length > 0 ? company.files[0].url : '';

  const initOpenRoutes = () => {
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach(route => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = !!(route.containsHome && pathName === '/');

      _routes = { ..._routes, [route.path]: isActive || isOpen || isHome };

      if (route.children?.length > 0) {
        route.children.forEach(childrenRoute => {
          if (childrenRoute.children?.length > 0) {
            const isChildrenActive = pathName.indexOf(childrenRoute.path) === 0;
            const isChildrenOpen = childrenRoute.open;
            const isChildrenHome = !!(
              childrenRoute.containsHome && pathName === '/'
            );

            _routes = {
              ..._routes,
              [childrenRoute.path]:
                isChildrenActive || isChildrenOpen || isChildrenHome,
            };
          }
        });
      }
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (route, isNested) => {
    // Collapse all elements
    setOpenRoutes(routesOpened => {
      if (isNested) {
        Object.keys(routesOpened).forEach(item => {
          return {
            ...item,
            [route]: true,
          };
        });
      } else {
        Object.keys(openRoutes).forEach(
          item =>
            openRoutes[route] ||
            setOpenRoutes(openRoutesData => ({
              ...openRoutesData,
              [item]: false,
            }))
        );
      }

      return routesOpened;
    });

    // Toggle selected element
    setOpenRoutes(openRoutesData => ({
      ...openRoutesData,
      [route]: !openRoutes[route],
    }));
  };

  function renderSidebarCategoriesLinks(routesChildren, isNested = false) {
    if (routesChildren.children) {
      return (
        <React.Fragment key={routesChildren.path}>
          <SidebarCategory
            isOpen={!openRoutes[routesChildren.path]}
            isCollapsable
            name={routesChildren.id}
            icon={routesChildren.icon}
            button
            onClick={() => toggle(routesChildren.path, isNested)}
            isNested={isNested}
          />

          <Collapse in={openRoutes[routesChildren.path]} timeout="auto">
            {routesChildren.children.map(nestedRoute => {
              if (nestedRoute.children) {
                return renderSidebarCategoriesLinks(nestedRoute, true);
              }
              return (
                <SidebarLink
                  key={nestedRoute.path}
                  name={nestedRoute.name}
                  to={nestedRoute.path}
                  icon={nestedRoute.icon}
                  badge={nestedRoute.badge}
                  isNested={isNested}
                />
              );
            })}
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <SidebarCategory
        isCollapsable={false}
        name={routesChildren.id}
        to={routesChildren.path}
        activeClassName="active"
        component={NavLink}
        icon={routesChildren.icon}
        exact
        badge={routesChildren.badge}
      />
    );
  }

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand onClick={() => history.push('/dashboard')}>
        <BrandAvatar src={companyAvatar}>
          <BusinessIcon type="avatar-small" />
        </BrandAvatar>
        <div>
          <Title>{company.nickname ? company.nickname : company.name}</Title>
          {company.nickname && <Subtitle>{company.name}</Subtitle>}
        </div>
      </Brand>

      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map(category => {
              return (
                <React.Fragment key={category.path}>
                  {category.header ? (
                    <SidebarSection>{category.header}</SidebarSection>
                  ) : null}

                  {renderSidebarCategoriesLinks(category)}
                </React.Fragment>
              );
            })}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container>
          <GridItemCustom item>
            <BrandCompany
              src={companyLogo}
              alt={<IntlMessages id="datasmart.name" />}
            />
          </GridItemCustom>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
}

Sidebar.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default withRouter(Sidebar);
