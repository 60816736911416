import produce from 'immer';

import BillToReceiveTypes from '../billsToReceive/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  billToReceiveAddAccountModalOpen: false,
  billToReceivePixModalOpen: false,
  billToReceivePixDetailModalOpen: false,
  billToReceivePixShareModalOpen: false,
  billToReceivePixSettlementModalOpen: false,
  showNextPixRequestMessage: false,
  selectedAccount: null,
  isCreatingPixFromList: false,
};

export default function billToReceivePixs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BILL_TO_RECEIVE_PIXS_GET: {
        draft.list = action.payload.pixs;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_CREATE_SUCCESS: {
        const { pix, pixType } = action.payload;

        draft.list.unshift(pix);

        draft.detail = pix;

        if (pixType === 'DYNAMIC') {
          draft.showNextPixRequestMessage = true;
          draft.billToReceivePixModalOpen = true;
        } else if (pixType === 'STATIC') {
          draft.selectedAccount = null;

          if (pix.status === 'ACTIVE') {
            draft.billToReceivePixShareModalOpen = true;
            draft.billToReceivePixModalOpen = false;
          } else {
            draft.billToReceivePixDetailModalOpen = true;
            draft.billToReceivePixModalOpen = false;
          }
        }

        draft.loading = false;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_UPDATE_SUCCESS: {
        const { newPix } = action.payload;

        draft.list = state.list.map(pix => {
          return pix.id === newPix.id ? newPix : pix;
        });

        draft.detail = newPix;
        draft.loading = false;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_SUCCESS: {
        const { newPix } = action.payload;

        draft.detail = {};
        draft.list = state.list.map(pix => {
          return pix.id === newPix.id ? newPix : pix;
        });

        draft.loading = false;

        draft.billToReceivePixSettlementModalOpen = false;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_DELETE_SUCCESS: {
        const { pix_id } = action.payload;

        draft.list = state.list.filter(pix => {
          return pix.id !== pix_id;
        });

        if (draft.detail.id && pix_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = draft.list.filter(pix => pix.id === id);
        }

        draft.billToReceivePixModalOpen = true;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.list = [];
          draft.isCreatingPixFromList = false;
        }

        draft.billToReceivePixModalOpen = false;
        draft.showNextPixRequestMessage = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_OPEN_MODAL: {
        draft.billToReceiveAddAccountModalOpen = true;

        if (action.payload.isCreatingPixFromList) {
          draft.isCreatingPixFromList = true;
        }
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_CLOSE_MODAL: {
        draft.billToReceiveAddAccountModalOpen = false;
        draft.isCreatingPixFromList = false;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_SET_SELECTED_ACCOUNT: {
        draft.selectedAccount = action.payload.account;
        draft.billToReceiveAddAccountModalOpen = false;
        draft.billToReceivePixModalOpen = true;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_DETAIL_OPEN_MODAL: {
        const { id, pix: pixData } = action.payload;

        draft.detail = {};

        if (pixData) {
          draft.detail = pixData;

          draft.list = state.list.map(pix =>
            pix.id === pixData.id ? pixData : pix
          );
        } else if (id) {
          [draft.detail] = draft.list.filter(pix => pix.id === id);
        }

        draft.showNextPixRequestMessage = false;
        draft.billToReceivePixModalOpen = false;
        draft.billToReceivePixDetailModalOpen = true;
        draft.selectedAccount = null;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_DETAIL_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.list = [];
          draft.isCreatingPixFromList = false;
        }

        draft.billToReceivePixDetailModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_SHARE_OPEN_MODAL: {
        const { id, pix: pixData } = action.payload;

        draft.detail = {};

        if (pixData) {
          draft.detail = pixData;

          draft.list = state.list.map(pix =>
            pix.id === pixData.id ? pixData : pix
          );
        } else if (id) {
          [draft.detail] = draft.list.filter(pix => pix.id === id);
        }

        draft.showNextPixRequestMessage = false;
        draft.billToReceivePixModalOpen = false;
        draft.billToReceivePixShareModalOpen = true;
        draft.selectedAccount = null;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_SHARE_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.list = [];
          draft.isCreatingPixFromList = false;
        }

        draft.billToReceivePixShareModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = draft.list.filter(pix => pix.id === id);
        }

        draft.billToReceivePixSettlementModalOpen = true;
        break;
      }
      case Types.BILL_TO_RECEIVE_PIXS_SETTLEMENT_CLOSE_MODAL: {
        draft.billToReceivePixSettlementModalOpen = false;
        draft.detail = {};
        draft.selectedAccount = null;
        break;
      }

      case BillToReceiveTypes.BILLS_TO_RECEIVE_RESET_DETAILS: {
        draft.billToReceivePixModalOpen = false;
        draft.billToReceivePixShareModalOpen = false;
        draft.billToReceivePixSettlementModalOpen = false;
        draft.billToReceivePixDetailModalOpen = false;
        draft.billToReceiveAddAccountModalOpen = false;
        draft.selectedAccount = null;
        draft.showNextPixRequestMessage = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      case BillToReceiveTypes.BILLS_TO_RECEIVE_RESET_LIST: {
        draft.billToReceivePixModalOpen = false;
        draft.billToReceivePixShareModalOpen = false;
        draft.billToReceivePixSettlementModalOpen = false;
        draft.billToReceivePixDetailModalOpen = false;
        draft.billToReceiveAddAccountModalOpen = false;
        draft.selectedAccount = null;
        draft.showNextPixRequestMessage = false;
        draft.detail = {};
        draft.list = [];
        draft.isCreatingPixFromList = false;
        break;
      }

      default:
    }
  });
}
