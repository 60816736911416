import produce from 'immer';

import CompanyTypes from '../companies/types';
import CompanyFilesTypes from '../companyFiles/types';
import UserTypes from '../user/types';
import UserFilesTypes from '../userFiles/types';
import Types from './types';

const INITIAL_STATE = {
  token: null,
  refreshToken: null,
  company: {},
  user: {},
  signed: false,
  selectedCompany: false,
  loading: false,
  signUpModalOpen: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SIGN_IN_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SIGN_IN_SUCCESS: {
        const { token, user, companies, refreshToken } = action.payload;

        draft.user = user;
        draft.token = token;
        draft.signed = true;
        draft.loading = false;
        draft.refreshToken = refreshToken;

        if (companies.length === 1) {
          if (
            user.username === 'admin' ||
            user.username === 'suporte' ||
            user.username === 'replicador'
          ) {
            draft.selectedCompany = false;
          } else {
            const [companyData] = companies;

            draft.company = companyData;

            draft.selectedCompany = true;
          }
        }

        break;
      }
      case Types.SIGN_IN_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SIGN_UP_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SIGN_UP_SUCCESS: {
        draft.loading = false;
        draft.signUpModalOpen = false;
        break;
      }
      case Types.SIGN_UP_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.SIGN_UP_OPEN_MODAL: {
        draft.signUpModalOpen = true;
        break;
      }
      case Types.SIGN_UP_CLOSE_MODAL: {
        draft.signUpModalOpen = false;
        break;
      }

      case Types.SIGN_OUT: {
        draft.token = null;
        draft.refreshToken = null;
        draft.signed = false;
        draft.user = {};
        draft.selectedCompany = false;
        draft.company = {};
        break;
      }

      case Types.SIGN_OUT_TOKEN_INVALID: {
        draft.token = null;
        draft.refreshToken = null;
        draft.signed = false;
        draft.user = {};
        draft.selectedCompany = false;
        draft.company = {};
        break;
      }

      case Types.COMPANY_SET_SELECTED: {
        const { company } = action.payload;

        draft.selectedCompany = true;
        draft.company = company;
        break;
      }
      case Types.COMPANY_CLEAR_SELECTED: {
        draft.selectedCompany = false;
        draft.company = {};
        break;
      }

      case Types.AUTH_REFRESH_TOKEN: {
        const { token } = action.payload;

        draft.token = token;
        break;
      }

      case UserTypes.USER_UPDATE_SUCCESS: {
        const { newUser } = action.payload;

        const { name, email, username } = newUser;

        const newData = {
          id: state.user.id,
          name,
          email,
          username,
        };

        const data = {
          ...state.user,
          ...newData,
        };

        draft.user = data;
        break;
      }

      case UserFilesTypes.USER_FILES_CREATE_SUCCESS: {
        const { file } = action.payload;

        const changesFile = {
          ...state.user,
          files: [file],
        };

        draft.user = changesFile;

        break;
      }

      case UserFilesTypes.USER_FILES_UPDATE_SUCCESS: {
        const { newFile } = action.payload;

        const changesFile = {
          ...state.user,
          files: [newFile],
        };

        draft.user = changesFile;

        break;
      }

      case UserFilesTypes.USER_FILES_DELETE_SUCCESS: {
        const changesFile = {
          ...state.user,
          files: [],
        };

        draft.user = changesFile;
        break;
      }

      case CompanyTypes.COMPANIES_CREATE_SUCCESS: {
        const { company } = action.payload;

        draft.selectedCompany = true;
        draft.company = company;
        break;
      }

      case CompanyTypes.COMPANIES_UPDATE_SUCCESS: {
        const { newCompany } = action.payload;

        const { name, nickname, documents, slug } = newCompany;

        const newData = {
          id: state.company.id,
          type: state.company.type,
          name,
          nickname,
          documents,
          slug,
        };

        const data = {
          ...state.company,
          ...newData,
        };

        draft.company = data;
        break;
      }

      case CompanyTypes.COMPANIES_DELETE_SUCCESS: {
        draft.selectedCompany = false;
        draft.company = {};
        break;
      }

      case CompanyFilesTypes.COMPANY_FILES_CREATE_SUCCESS: {
        const { file } = action.payload;

        const changesFile = {
          ...state.company,
          files: [file],
        };

        draft.company = changesFile;

        break;
      }

      case CompanyFilesTypes.COMPANY_FILES_UPDATE_SUCCESS: {
        const { newFile } = action.payload;

        const changesFile = {
          ...state.company,
          files: [newFile],
        };

        draft.company = changesFile;

        break;
      }

      case CompanyFilesTypes.COMPANY_FILES_DELETE_SUCCESS: {
        const changesFile = {
          ...state.company,
          files: [],
        };

        draft.company = changesFile;
        break;
      }
      default:
    }
  });
}
