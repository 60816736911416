import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  deleteRegionCityRequest,
  updateRegionCityRequest,
} from '../regionCities/actions';
import {
  updateRegionCustomerSuccess,
  updateRegionCustomerFailure,
  deleteRegionCustomerSuccess,
  deleteRegionCustomerFailure,
} from './actions';
import Types from './types';

function* removeRegionCityAfterDeletingCustomer(city, region_id) {
  const regionCustomers = yield select(state => state.regionCustomers.list);

  const findExistingCity = regionCustomers.find(
    regionCustomer => regionCustomer.addresses[0].city.id === city.id
  );

  if (!findExistingCity) {
    const regionCities = yield select(state => state.regionCities.list);

    const findDeletingCity = regionCities.find(
      regionCity => regionCity.city_id === city.id
    );

    yield put(deleteRegionCityRequest(region_id, findDeletingCity.id));
  }
}

export function* updateRegionCustomer({ payload }) {
  try {
    const { region_id, customers, cities } = payload;

    const response = yield call(
      api.put,
      `regions/${region_id}/customers`,
      customers
    );

    yield put(updateRegionCustomerSuccess(response.data, customers));

    if (cities.created.length || cities.deleted.length) {
      yield put(updateRegionCityRequest(region_id, cities));
    }

    toast.success(<IntlMessages id="messages.change-success" />);
  } catch (err) {
    yield put(updateRegionCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.change-failure" />);
  }
}

export function* deleteRegionCustomer({ payload }) {
  try {
    const { region_id, person } = payload;

    const { addresses } = person;

    const city = addresses && addresses[0].city;

    yield call(api.delete, `regions/${region_id}/customers/${person.id}`);

    yield put(deleteRegionCustomerSuccess(person.id));

    yield removeRegionCityAfterDeletingCustomer(city, region_id);

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRegionCustomerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.REGION_CUSTOMERS_UPDATE_REQUEST, updateRegionCustomer),
  takeLatest(Types.REGION_CUSTOMERS_DELETE_REQUEST, deleteRegionCustomer),
]);
