const Types = {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: '@auth/SIGN_IN_FAILURE',

  SIGN_UP_REQUEST: '@auth/SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: '@auth/SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: '@auth/SIGN_UP_FAILURE',

  SIGN_UP_OPEN_MODAL: '@auth/OPEN_MODAL_SIGN_UP',
  SIGN_UP_CLOSE_MODAL: '@auth/CLOSE_MODAL_SIGN_UP',

  SIGN_OUT: '@auth/SIGN_OUT',
  SIGN_OUT_TOKEN_INVALID: '@auth/SIGN_OUT_TOKEN_INVALID',

  COMPANY_SET_SELECTED: '@auth/SET_SELECTED_COMPANY',
  COMPANY_CLEAR_SELECTED: '@auth/CLEAR_SELECTED_COMPANY',

  AUTH_REFRESH_TOKEN: '@auth/REFRESH_TOKEN_AUTH',
};

export default Types;
