import Types from './types';

export function getCompanyNcmsRequest({
  companyId,
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.COMPANY_NCMS_GET_REQUEST,
    payload: {
      companyId,
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getCompanyNcmsSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.COMPANY_NCMS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getCompanyNcmsFailure() {
  return {
    type: Types.COMPANY_NCMS_GET_FAILURE,
  };
}

export function createCompanyNcmRequest(company_id, companyNcm) {
  return {
    type: Types.COMPANY_NCMS_CREATE_REQUEST,
    payload: { company_id, companyNcm },
  };
}

export function createCompanyNcmSuccess(companyNcm) {
  return {
    type: Types.COMPANY_NCMS_CREATE_SUCCESS,
    payload: { companyNcm },
  };
}

export function createCompanyNcmFailure() {
  return {
    type: Types.COMPANY_NCMS_CREATE_FAILURE,
  };
}

export function deleteCompanyNcmRequest(company_id, id) {
  return {
    type: Types.COMPANY_NCMS_DELETE_REQUEST,
    payload: { company_id, id },
  };
}

export function deleteCompanyNcmSuccess(id) {
  return {
    type: Types.COMPANY_NCMS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteCompanyNcmFailure() {
  return {
    type: Types.COMPANY_NCMS_DELETE_FAILURE,
  };
}

export function resetListCompanyNcms() {
  return {
    type: Types.COMPANY_NCMS_RESET_LIST,
  };
}

export function openModalCompanyNcmFilter() {
  return {
    type: Types.COMPANY_NCMS_FILTER_OPEN_MODAL,
  };
}

export function closeModalCompanyNcmFilter() {
  return {
    type: Types.COMPANY_NCMS_FILTER_CLOSE_MODAL,
  };
}
