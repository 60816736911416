import Types from './types';

export function getBanksRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.BANKS_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getBanksSuccess({
  data,
  queryParams,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.BANKS_GET_SUCCESS,
    payload: { data, queryParams, filterColumnsOrder, filterSearch },
  };
}

export function getBanksFailure() {
  return {
    type: Types.BANKS_GET_FAILURE,
  };
}

export function resetListBanks() {
  return {
    type: Types.BANKS_RESET_LIST,
  };
}
