const Types = {
  COMPANY_PHONES_GET: '@company/GET_COMPANY_PHONES',

  COMPANY_PHONES_CREATE_REQUEST: '@company/CREATE_COMPANY_PHONE_REQUEST',
  COMPANY_PHONES_CREATE_SUCCESS: '@company/CREATE_COMPANY_PHONE_SUCCESS',
  COMPANY_PHONES_CREATE_FAILURE: '@company/CREATE_COMPANY_PHONE_FAILURE',

  COMPANY_PHONES_UPDATE_REQUEST: '@company/UPDATE_COMPANY_PHONE_REQUEST',
  COMPANY_PHONES_UPDATE_SUCCESS: '@company/UPDATE_COMPANY_PHONE_SUCCESS',
  COMPANY_PHONES_UPDATE_FAILURE: '@company/UPDATE_COMPANY_PHONE_FAILURE',

  COMPANY_PHONES_DELETE_REQUEST: '@company/DELETE_COMPANY_PHONE_REQUEST',
  COMPANY_PHONES_DELETE_SUCCESS: '@company/DELETE_COMPANY_PHONE_SUCCESS',
  COMPANY_PHONES_DELETE_FAILURE: '@company/DELETE_COMPANY_PHONE_FAILURE',

  COMPANY_PHONES_RESET_LIST: '@company/RESET_LIST_COMPANY_PHONE',

  COMPANY_PHONES_OPEN_MODAL: '@company/OPEN_MODAL_COMPANY_PHONE',
  COMPANY_PHONES_CLOSE_MODAL: '@company/CLOSE_MODAL_COMPANY_PHONE',
};

export default Types;
