import styled from 'styled-components';

import { Close as MuiClose } from '@material-ui/icons';

import colors from '~/theme/colors';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'modal':
      styles = `color: ${colors.white};`;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Close = styled(MuiClose)`
  ${({ type }) => defineStyles(type)}
`;
