import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  listLength: 0,
  detail: {},

  searchThroughTable: true,
  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,

  detailUpdatedOnce: false,
  requestError: false,
  loading: false,
  sharedDetail: false,
  settlementId: null,
  billToPaySettlementModalOpen: false,
  billToPayObservationModalOpen: false,
  billToPayFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
};

export default function billsToPay(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BILLS_TO_PAY_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_PAY_CREATE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.BILLS_TO_PAY_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_PAY_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_PAY_UPDATE_SUCCESS: {
        const { newBillToPay } = action.payload;
        draft.detail = newBillToPay;

        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        draft.billToPayObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.BILLS_TO_PAY_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_PAY_SETTLEMENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_PAY_SETTLEMENT_SUCCESS: {
        const { newBillToPay } = action.payload;
        draft.detail = newBillToPay;

        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        draft.loading = false;

        draft.settlementId = null;
        draft.billToPaySettlementModalOpen = false;
        break;
      }
      case Types.BILLS_TO_PAY_SETTLEMENT_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_PAY_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_PAY_DELETE_SUCCESS: {
        const { isFromDetail } = action.payload;

        if (isFromDetail) {
          if (state.page > 0 && state.listLength === 1) {
            draft.page = state.page - 1;
          }
        }

        draft.loading = false;
        draft.shouldFetchStatisticData = true;
        break;
      }
      case Types.BILLS_TO_PAY_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_PAY_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.BILLS_TO_PAY_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.billToPay;
        draft.requestError = false;
        break;
      }
      case Types.BILLS_TO_PAY_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.BILLS_TO_PAY_RESET_DETAILS: {
        draft.detail = {};
        draft.billToPaySettlementModalOpen = false;
        draft.billToPayObservationModalOpen = false;
        draft.settlementId = null;
        draft.sharedDetail = false;
        draft.detailUpdatedOnce = false;
        break;
      }

      case Types.BILLS_TO_PAY_RESET_LIST: {
        draft.loading = false;
        draft.requestError = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.billToPayFilterModalOpen = false;

        draft.shouldFetchStatisticData = false;
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.searchThroughTable = true;
        draft.listLength = 0;
        break;
      }

      case Types.BILLS_TO_PAY_SETTLEMENT_OPEN_MODAL: {
        draft.settlementId = action.payload.id;
        draft.sharedDetail = action.payload.sharedDetail;
        draft.billToPaySettlementModalOpen = true;
        break;
      }
      case Types.BILLS_TO_PAY_SETTLEMENT_CLOSE_MODAL: {
        draft.settlementId = null;
        draft.sharedDetail = false;
        draft.billToPaySettlementModalOpen = false;
        break;
      }

      case Types.BILLS_TO_PAY_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.BILLS_TO_PAY_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      case Types.BILLS_TO_PAY_OBSERVATION_OPEN_MODAL: {
        draft.billToPayObservationModalOpen = true;
        break;
      }
      case Types.BILLS_TO_PAY_OBSERVATION_CLOSE_MODAL: {
        draft.billToPayObservationModalOpen = false;
        break;
      }

      case Types.BILLS_TO_PAY_FILTER_OPEN_MODAL: {
        draft.billToPayFilterModalOpen = true;
        break;
      }
      case Types.BILLS_TO_PAY_FILTER_CLOSE_MODAL: {
        draft.billToPayFilterModalOpen = false;
        break;
      }

      case Types.BILLS_TO_PAY_SET_TABLE_FILTERS: {
        const {
          modalFilters,
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
          searchThroughTable,
          shouldFetchStatisticData,
          listLength,
        } = action.payload;

        if (typeof listLength !== 'undefined') {
          draft.listLength = listLength;
        }

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        if (typeof rowsPerPage !== 'undefined') {
          draft.rowsPerPage = rowsPerPage;
        }

        if (typeof currentPage !== 'undefined') {
          draft.page = currentPage;
        }

        if (typeof searchThroughTable !== 'undefined') {
          draft.searchThroughTable = searchThroughTable;
        }

        if (typeof shouldFetchStatisticData !== 'undefined') {
          draft.shouldFetchStatisticData = shouldFetchStatisticData;
        }

        if (closeModal) {
          draft.billToPayFilterModalOpen = false;
        }

        break;
      }
      case Types.BILLS_TO_PAY_SET_INITIAL_TABLE_FILTERS: {
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.shouldFetchStatisticData = false;
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.searchThroughTable = true;
        break;
      }

      case Types.BILLS_TO_PAY_SET_REQUEST_ERROR: {
        draft.requestError = true;
        break;
      }

      default:
    }
  });
}
