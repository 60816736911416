import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createPriceListItemSuccess,
  createPriceListItemFailure,
  updatePriceListItemSuccess,
  updatePriceListItemFailure,
  deletePriceListItemSuccess,
  deletePriceListItemFailure,
} from './actions';
import Types from './types';

export function* createPriceListItem({ payload }) {
  try {
    const { price_list_id, created, loadAllItems } = payload;

    yield call(api.post, `price-lists/${price_list_id}/items`, { created });

    yield put(createPriceListItemSuccess());

    toast.success(<IntlMessages id="messages.save-success" />);

    if (loadAllItems) {
      loadAllItems();
    }
  } catch (err) {
    yield put(createPriceListItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePriceListItem({ payload }) {
  try {
    const { price_list_id, item_id, newItem, loadAllItems } = payload;

    yield call(
      api.put,
      `price-lists/${price_list_id}/items/${item_id}`,
      newItem
    );

    yield put(updatePriceListItemSuccess());

    toast.success(<IntlMessages id="messages.edit-success" />);

    if (loadAllItems) {
      loadAllItems();
    }
  } catch (err) {
    yield put(updatePriceListItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePriceListItem({ payload }) {
  try {
    const { price_list_id, item_id, loadAllItems } = payload;

    yield call(api.delete, `price-lists/${price_list_id}/items/${item_id}`);

    yield put(deletePriceListItemSuccess());

    toast.success(<IntlMessages id="messages.delete-success" />);

    if (loadAllItems) {
      loadAllItems();
    }
  } catch (err) {
    yield put(deletePriceListItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.PRICE_LIST_ITEMS_CREATE_REQUEST, createPriceListItem),
  takeLatest(Types.PRICE_LIST_ITEMS_UPDATE_REQUEST, updatePriceListItem),
  takeLatest(Types.PRICE_LIST_ITEMS_DELETE_REQUEST, deletePriceListItem),
]);
