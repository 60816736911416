import React from 'react';

import PropTypes from 'prop-types';

import { Close } from './styles';

export default function CloseIcon({ type }) {
  return <Close type={type} />;
}

CloseIcon.propTypes = {
  type: PropTypes.string,
};

CloseIcon.defaultProps = {
  type: '',
};
