const Types = {
  TRANSPORTERS_GET_REQUEST: '@transporters/GET_TRANSPORTERS_REQUEST',
  TRANSPORTERS_GET_SUCCESS: '@transporters/GET_TRANSPORTERS_SUCCESS',
  TRANSPORTERS_GET_FAILURE: '@transporters/GET_TRANSPORTERS_FAILURE',

  TRANSPORTERS_CREATE_REQUEST: '@transporters/CREATE_TRANSPORTER_REQUEST',
  TRANSPORTERS_CREATE_SUCCESS: '@transporters/CREATE_TRANSPORTER_SUCCESS',
  TRANSPORTERS_CREATE_FAILURE: '@transporters/CREATE_TRANSPORTER_FAILURE',

  TRANSPORTERS_UPDATE_REQUEST: '@transporters/UPDATE_TRANSPORTER_REQUEST',
  TRANSPORTERS_UPDATE_SUCCESS: '@transporters/UPDATE_TRANSPORTER_SUCCESS',
  TRANSPORTERS_UPDATE_FAILURE: '@transporters/UPDATE_TRANSPORTER_FAILURE',

  TRANSPORTERS_DELETE_REQUEST: '@transporters/DELETE_TRANSPORTER_REQUEST',
  TRANSPORTERS_DELETE_SUCCESS: '@transporters/DELETE_TRANSPORTER_SUCCESS',
  TRANSPORTERS_DELETE_FAILURE: '@transporters/DELETE_TRANSPORTER_FAILURE',

  TRANSPORTERS_BECOME_REQUEST: '@transporters/BECOME_TRANSPORTER_REQUEST',
  TRANSPORTERS_BECOME_SUCCESS: '@transporters/BECOME_TRANSPORTER_SUCCESS',
  TRANSPORTERS_BECOME_FAILURE: '@transporters/BECOME_TRANSPORTER_FAILURE',

  TRANSPORTERS_GET_DETAILS_REQUEST:
    '@transporters/GET_DETAILS_TRANSPORTER_REQUEST',
  TRANSPORTERS_GET_DETAILS_SUCCESS:
    '@transporters/GET_DETAILS_TRANSPORTER_SUCCESS',
  TRANSPORTERS_GET_DETAILS_FAILURE:
    '@transporters/GET_DETAILS_TRANSPORTER_FAILURE',

  TRANSPORTERS_RESET_LIST: '@transporters/RESET_LIST_TRANSPORTERS',
  TRANSPORTERS_RESET_DETAILS: '@transporters/RESET_DETAILS_TRANSPORTER',

  TRANSPORTERS_SET_SAVING_ON_SUB_RESOURCE:
    '@transporters/SET_SAVING_ON_SUB_RESOURCE_TRANSPORTER',
  TRANSPORTERS_RESET_SUB_RESOURCE_DATA:
    '@transporters/RESET_SUB_RESOURCE_DATA_TRANSPORTER',

  TRANSPORTERS_OPEN_FILE_DIALOG: '@transporters/OPEN_TRANSPORTER_FILE_DIALOG',

  TRANSPORTERS_ADD_OPEN_MODAL: '@transporters/OPEN_MODAL_ADD_TRANSPORTER',
  TRANSPORTERS_ADD_CLOSE_MODAL: '@transporters/CLOSE_MODAL_ADD_TRANSPORTER',

  TRANSPORTERS_FILTER_OPEN_MODAL: '@transporters/OPEN_MODAL_FILTER_TRANSPORTER',
  TRANSPORTERS_FILTER_CLOSE_MODAL:
    '@transporters/CLOSE_MODAL_FILTER_TRANSPORTER',

  TRANSPORTERS_OBSERVATION_OPEN_MODAL:
    '@transporters/OPEN_MODAL_TRANSPORTER_OBSERVATION',
  TRANSPORTERS_OBSERVATION_CLOSE_MODAL:
    '@transporters/CLOSE_MODAL_TRANSPORTER_OBSERVATION',
};

export default Types;
