import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  branchActivityModalOpen: false,
};

export default function branchesActivities(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BRANCHES_ACTIVITIES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_GET_SUCCESS: {
        const { data, filterColumnsOrder, filterSearch } = action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }
        break;
      }
      case Types.BRANCHES_ACTIVITIES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BRANCHES_ACTIVITIES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.branchActivity);
        draft.loading = false;
        draft.branchActivityModalOpen = false;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BRANCHES_ACTIVITIES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_UPDATE_SUCCESS: {
        const { newBranchActivity } = action.payload;

        draft.list = state.list.map(branchActivity => {
          return branchActivity.id === newBranchActivity.id
            ? newBranchActivity
            : branchActivity;
        });

        draft.detail = {};
        draft.loading = false;
        draft.branchActivityModalOpen = false;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BRANCHES_ACTIVITIES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_DELETE_SUCCESS: {
        draft.list = state.list.filter(branchActivity => {
          return branchActivity.id !== action.payload.id;
        });
        draft.loading = false;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BRANCHES_ACTIVITIES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.filterColumnsOrder = null;
        draft.filterSearch = '';
        break;
      }

      case Types.BRANCHES_ACTIVITIES_OPEN_MODAL: {
        const { id } = action.payload;

        draft.detail = {};

        if (id) {
          [draft.detail] = state.list.filter(branchActivity => {
            return branchActivity.id === id;
          });
        }

        draft.branchActivityModalOpen = true;
        break;
      }
      case Types.BRANCHES_ACTIVITIES_CLOSE_MODAL: {
        draft.branchActivityModalOpen = false;
        draft.detail = {};
        break;
      }

      default:
    }
  });
}
