import React from 'react';
import { Book } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const CustomersList = async(() => import('~/pages/Customers/List'));
const EmployeesList = async(() => import('~/pages/Employees/List'));
const SellersList = async(() => import('~/pages/Sellers/List'));
const SuppliersList = async(() => import('~/pages/Suppliers/List'));
const BranchesActivitiesList = async(() =>
  import('~/pages/BranchesActivities/List')
);
const PromotersList = async(() => import('~/pages/Promoter/List'));
const CovenantsList = async(() => import('~/pages/Covenants/List'));
const DispatchingAgenciesList = async(() =>
  import('~/pages/DispatchingAgencies/List')
);
const OwnersList = async(() => import('~/pages/Owners/List'));
const TransportersList = async(() => import('~/pages/Transporters/List'));
const SupervisorList = async(() => import('~/pages/Supervisor/List'));

export const contactsRoutes = {
  id: <IntlMessages id="sidebar.contacts" />,
  path: '/contacts',
  icon: <Book />,
  children: [
    {
      path: '/customers',
      name: <IntlMessages id="sidebar.contacts.customers" />,
      component: CustomersList,
    },
    {
      path: '/suppliers',
      name: <IntlMessages id="sidebar.contacts.suppliers" />,
      component: SuppliersList,
    },
    {
      path: '/sellers',
      name: <IntlMessages id="sidebar.contacts.sellers" />,
      component: SellersList,
    },
    {
      path: '/employees',
      name: <IntlMessages id="sidebar.contacts.employees" />,
      component: EmployeesList,
    },
    {
      path: '/promoters',
      name: <IntlMessages id="sidebar.contacts.promoters" />,
      component: PromotersList,
    },
    {
      path: '/transporters',
      name: <IntlMessages id="sidebar.contacts.transporters" />,
      component: TransportersList,
    },
    {
      path: '/supervisors',
      name: <IntlMessages id="sidebar.contacts.supervisor" />,
      component: SupervisorList,
    },
    {
      path: '/contacts-others',
      id: <IntlMessages id="sidebar.others" />,
      children: [
        {
          path: '/covenants',
          name: <IntlMessages id="sidebar.contacts.covenants" />,
          component: CovenantsList,
        },
        {
          path: '/dispatching-agencies',
          name: <IntlMessages id="sidebar.contacts.dispatching-agencies" />,
          component: DispatchingAgenciesList,
        },
        {
          path: '/branches-activities',
          name: <IntlMessages id="sidebar.contacts.branches-activities" />,
          component: BranchesActivitiesList,
        },
      ],
    },
  ],
};

if (process.env.REACT_APP_MODULE === 'AGRIBUSINESS') {
  const ownerRoute = {
    path: '/owners',
    name: <IntlMessages id="sidebar.contacts.owners" />,
    component: OwnersList,
  };

  contactsRoutes.children.splice(5, 0, ownerRoute);
}
