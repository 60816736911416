import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createRuralPropertyEmailSuccess,
  createRuralPropertyEmailFailure,
  updateRuralPropertyEmailSuccess,
  updateRuralPropertyEmailFailure,
  deleteRuralPropertyEmailSuccess,
  deleteRuralPropertyEmailFailure,
} from './actions';
import Types from './types';

export function* createRuralPropertyEmail({ payload }) {
  try {
    const { property_id, email } = payload;

    const response = yield call(
      api.post,
      `rural-properties/${property_id}/emails`,
      email
    );

    yield put(createRuralPropertyEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRuralPropertyEmail({ payload }) {
  try {
    const { property_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `rural-properties/${property_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateRuralPropertyEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRuralPropertyEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteRuralPropertyEmail({ payload }) {
  const { property_id, email_id } = payload;

  const emails = yield select(state => state.ruralPropertyEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteRuralPropertyEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(
      api.delete,
      `rural-properties/${property_id}/emails/${email_id}`
    );

    yield put(deleteRuralPropertyEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRuralPropertyEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.RURAL_PROPERTY_EMAILS_CREATE_REQUEST,
    createRuralPropertyEmail
  ),
  takeLatest(
    Types.RURAL_PROPERTY_EMAILS_UPDATE_REQUEST,
    updateRuralPropertyEmail
  ),
  takeLatest(
    Types.RURAL_PROPERTY_EMAILS_DELETE_REQUEST,
    deleteRuralPropertyEmail
  ),
]);
