import React from 'react';

import PropTypes from 'prop-types';

import { ChipContainer } from './styles';

export default function TableFilterChip({ title, label }) {
  return (
    <ChipContainer>
      <strong>{title}</strong>: {label}
    </ChipContainer>
  );
}

TableFilterChip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
