import produce from 'immer';

import CompanyTypes from '../companies/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  companyUserModalOpen: false,
  companyUserInviteModalOpen: false,
};

export default function companyUsers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.COMPANY_USERS_GET: {
        draft.list = action.payload.users;
        break;
      }

      case Types.COMPANY_USERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_USERS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.user);
        draft.loading = false;
        draft.companyUserModalOpen = false;
        break;
      }
      case Types.COMPANY_USERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_USERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_USERS_UPDATE_SUCCESS: {
        draft.list.unshift(action.payload.newInvite);
        draft.loading = false;
        draft.companyUserInviteModalOpen = false;
        break;
      }
      case Types.COMPANY_USERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_USERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.COMPANY_USERS_DELETE_SUCCESS: {
        const { user_id } = action.payload;

        draft.list = state.list.filter(user => {
          return user.id !== user_id;
        });

        if (draft.detail.id && user_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.COMPANY_USERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.COMPANY_USERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.COMPANY_USERS_OPEN_MODAL: {
        const { user_id } = action.payload;

        draft.detail = {};

        if (user_id) {
          [draft.detail] = draft.list.filter(user => user.id === user_id);
        }
        draft.companyUserModalOpen = true;
        break;
      }
      case Types.COMPANY_USERS_CLOSE_MODAL: {
        draft.companyUserModalOpen = false;
        draft.detail = {};
        break;
      }

      case Types.COMPANY_USERS_INVITE_OPEN_MODAL: {
        draft.companyUserInviteModalOpen = true;
        break;
      }
      case Types.COMPANY_USERS_INVITE_CLOSE_MODAL: {
        draft.companyUserInviteModalOpen = false;
        break;
      }

      case CompanyTypes.COMPANIES_RESET_DETAILS: {
        draft.companyUserModalOpen = false;
        draft.companyUserInviteModalOpen = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      default:
    }
  });
}
