const Types = {
  SUPPLIER_FILES_GET: '@suppliers/GET_SUPPLIER_FILES',

  SUPPLIER_FILES_CREATE_REQUEST: '@suppliers/CREATE_SUPPLIER_FILE_REQUEST',
  SUPPLIER_FILES_CREATE_SUCCESS: '@suppliers/CREATE_SUPPLIER_FILE_SUCCESS',
  SUPPLIER_FILES_CREATE_FAILURE: '@suppliers/CREATE_SUPPLIER_FILE_FAILURE',

  SUPPLIER_FILES_UPDATE_REQUEST: '@suppliers/UPDATE_SUPPLIER_FILE_REQUEST',
  SUPPLIER_FILES_UPDATE_SUCCESS: '@suppliers/UPDATE_SUPPLIER_FILE_SUCCESS',
  SUPPLIER_FILES_UPDATE_FAILURE: '@suppliers/UPDATE_SUPPLIER_FILE_FAILURE',

  SUPPLIER_FILES_DELETE_REQUEST: '@suppliers/DELETE_SUPPLIER_FILE_REQUEST',
  SUPPLIER_FILES_DELETE_SUCCESS: '@suppliers/DELETE_SUPPLIER_FILE_SUCCESS',
  SUPPLIER_FILES_DELETE_FAILURE: '@suppliers/DELETE_SUPPLIER_FILE_FAILURE',

  SUPPLIER_FILES_OPEN_MODAL: '@suppliers/OPEN_MODAL_SUPPLIER_FILE',
  SUPPLIER_FILES_CLOSE_MODAL: '@suppliers/CLOSE_MODAL_SUPPLIER_FILE',

  SUPPLIER_FILES_RESET_LIST: '@suppliers/RESET_LIST_SUPPLIER_FILE',
};

export default Types;
