import styled from 'styled-components';

import { Done as MuiDone } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'okButton':
      styles = `
        width: 22px;
        height: 22px;
        margin-right: 2px;
        margin-bottom: 2px;
      `;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Done = styled(MuiDone)`
  ${({ type }) => defineStyles(type)}
`;
