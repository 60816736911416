import Types from './types';

export function getSupplierPhones(phones) {
  return {
    type: Types.SUPPLIER_PHONES_GET,
    payload: { phones },
  };
}

export function createSupplierPhoneRequest(supplier_id, phone) {
  return {
    type: Types.SUPPLIER_PHONES_CREATE_REQUEST,
    payload: { supplier_id, phone },
  };
}

export function createSupplierPhoneSuccess(phone) {
  return {
    type: Types.SUPPLIER_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createSupplierPhoneFailure() {
  return {
    type: Types.SUPPLIER_PHONES_CREATE_FAILURE,
  };
}

export function updateSupplierPhoneRequest(supplier_id, phone_id, newPhone) {
  return {
    type: Types.SUPPLIER_PHONES_UPDATE_REQUEST,
    payload: { supplier_id, phone_id, newPhone },
  };
}

export function updateSupplierPhoneSuccess(newPhone) {
  return {
    type: Types.SUPPLIER_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateSupplierPhoneFailure() {
  return {
    type: Types.SUPPLIER_PHONES_UPDATE_FAILURE,
  };
}

export function deleteSupplierPhoneRequest(supplier_id, phone_id) {
  return {
    type: Types.SUPPLIER_PHONES_DELETE_REQUEST,
    payload: { supplier_id, phone_id },
  };
}

export function deleteSupplierPhoneSuccess(phone_id) {
  return {
    type: Types.SUPPLIER_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteSupplierPhoneFailure() {
  return {
    type: Types.SUPPLIER_PHONES_DELETE_FAILURE,
  };
}

export function resetListSupplierPhone() {
  return {
    type: Types.SUPPLIER_PHONES_RESET_LIST,
  };
}

export function openModalSupplierPhone(phone_id) {
  return {
    type: Types.SUPPLIER_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalSupplierPhone() {
  return {
    type: Types.SUPPLIER_PHONES_CLOSE_MODAL,
  };
}
