const Types = {
  COVENANTS_GET_REQUEST: '@covenants/GET_COVENANTS_REQUEST',
  COVENANTS_GET_SUCCESS: '@covenants/GET_COVENANTS_SUCCESS',
  COVENANTS_GET_FAILURE: '@covenants/GET_COVENANTS_FAILURE',

  COVENANTS_CREATE_REQUEST: '@covenants/CREATE_COVENANT_REQUEST',
  COVENANTS_CREATE_SUCCESS: '@covenants/CREATE_COVENANT_SUCCESS',
  COVENANTS_CREATE_FAILURE: '@covenants/CREATE_COVENANT_FAILURE',

  COVENANTS_UPDATE_REQUEST: '@covenants/UPDATE_COVENANT_REQUEST',
  COVENANTS_UPDATE_SUCCESS: '@covenants/UPDATE_COVENANT_SUCCESS',
  COVENANTS_UPDATE_FAILURE: '@covenants/UPDATE_COVENANT_FAILURE',

  COVENANTS_DELETE_REQUEST: '@covenants/DELETE_COVENANT_REQUEST',
  COVENANTS_DELETE_SUCCESS: '@covenants/DELETE_COVENANT_SUCCESS',
  COVENANTS_DELETE_FAILURE: '@covenants/DELETE_COVENANT_FAILURE',

  COVENANTS_RESET_LIST: '@covenants/RESET_LIST_COVENANTS',

  COVENANTS_OPEN_MODAL: '@covenants/OPEN_MODAL_COVENANT',
  COVENANTS_CLOSE_MODAL: '@covenants/CLOSE_MODAL_COVENANT',
};

export default Types;
