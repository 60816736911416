export function sortStrings(rowA, rowB) {
  const rowAValue = rowA || '';

  const rowBValue = rowB || '';

  return rowAValue.localeCompare(rowBValue);
}

export function sortNumbers(rowA, rowB) {
  const rowAValue = rowA || 0;

  const rowBValue = rowB || 0;

  return rowAValue - rowBValue;
}

export function sortDates(rowA, rowB) {
  const rowAValue = new Date(rowA) || '';

  const rowBValue = new Date(rowB) || '';

  return rowAValue - rowBValue;
}

export function sortStringNullAsLast(rowA, rowB, direction) {
  if (!rowA) {
    if (direction === 'desc') {
      return 1;
    }
    return -1;
  }

  if (!rowB) {
    if (direction === 'desc') {
      return 1;
    }
    return -1;
  }

  return rowA.localeCompare(rowB);
}

export function sortByDistinctTwoStringValues(
  aPrimaryValue,
  bPrimaryValue,
  aComplementaryValue,
  bComplementaryValue
) {
  if (!aPrimaryValue) {
    if (!bPrimaryValue) {
      return aComplementaryValue.localeCompare(bComplementaryValue);
    }

    return aComplementaryValue.localeCompare(bPrimaryValue);
  }

  if (bPrimaryValue) {
    return aPrimaryValue.localeCompare(bPrimaryValue);
  }

  if (!bPrimaryValue) {
    return aPrimaryValue.localeCompare(bComplementaryValue);
  }

  return aComplementaryValue.localeCompare(bComplementaryValue);
}
