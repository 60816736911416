import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createPaymentMethodInstallmentSuccess,
  createPaymentMethodInstallmentFailure,
  updatePaymentMethodInstallmentSuccess,
  updatePaymentMethodInstallmentFailure,
  deletePaymentMethodInstallmentSuccess,
  deletePaymentMethodInstallmentFailure,
} from './actions';
import Types from './types';

export function* createPaymentMethodInstallment({ payload }) {
  try {
    const { payment_method_id, installment } = payload;

    const response = yield call(
      api.post,
      `payment-methods/${payment_method_id}/installments`,
      installment
    );

    yield put(createPaymentMethodInstallmentSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createPaymentMethodInstallmentFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePaymentMethodInstallment({ payload }) {
  try {
    const { payment_method_id, installment_id, newInstallment } = payload;

    const response = yield call(
      api.put,
      `payment-methods/${payment_method_id}/installments/${installment_id}`,
      newInstallment
    );

    yield put(updatePaymentMethodInstallmentSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updatePaymentMethodInstallmentFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePaymentMethodInstallment({ payload }) {
  try {
    const { payment_method_id, installment_id } = payload;

    yield call(
      api.delete,
      `payment-methods/${payment_method_id}/installments/${installment_id}`
    );

    yield put(deletePaymentMethodInstallmentSuccess(installment_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePaymentMethodInstallmentFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_REQUEST,
    createPaymentMethodInstallment
  ),
  takeLatest(
    Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_REQUEST,
    updatePaymentMethodInstallment
  ),
  takeLatest(
    Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_REQUEST,
    deletePaymentMethodInstallment
  ),
]);
