const Types = {
  REGIONS_GET_REQUEST: '@regions/GET_REGIONS_REQUEST',
  REGIONS_GET_SUCCESS: '@regions/GET_REGIONS_SUCCESS',
  REGIONS_GET_FAILURE: '@regions/GET_REGIONS_FAILURE',

  REGIONS_CREATE_REQUEST: '@regions/CREATE_REGION_REQUEST',
  REGIONS_CREATE_SUCCESS: '@regions/CREATE_REGION_SUCCESS',
  REGIONS_CREATE_FAILURE: '@regions/CREATE_REGION_FAILURE',

  REGIONS_UPDATE_REQUEST: '@regions/UPDATE_REGION_REQUEST',
  REGIONS_UPDATE_SUCCESS: '@regions/UPDATE_REGION_SUCCESS',
  REGIONS_UPDATE_FAILURE: '@regions/UPDATE_REGION_FAILURE',

  REGIONS_DELETE_REQUEST: '@regions/DELETE_REGION_REQUEST',
  REGIONS_DELETE_SUCCESS: '@regions/DELETE_REGION_SUCCESS',
  REGIONS_DELETE_FAILURE: '@regions/DELETE_REGION_FAILURE',

  REGIONS_GET_DETAILS_REQUEST: '@regions/GET_DETAILS_REGION_REQUEST',
  REGIONS_GET_DETAILS_SUCCESS: '@regions/GET_DETAILS_REGION_SUCCESS',
  REGIONS_GET_DETAILS_FAILURE: '@regions/GET_DETAILS_REGION_FAILURE',

  REGIONS_SET_SAVING_ON_SUB_RESOURCE:
    '@regions/SET_SAVING_ON_SUB_RESOURCE_REGION',
  REGIONS_RESET_SUB_RESOURCE_DATA: '@regions/RESET_SUB_RESOURCE_DATA_REGION',

  REGIONS_ROW_DATA_OPEN_MODAL: '@regions/OPEN_MODAL_REGION_ROW_DATA',
  REGIONS_ROW_DATA_CLOSE_MODAL: '@regions/CLOSE_MODAL_REGION_ROW_DATA',

  REGIONS_RESET_LIST: '@regions/RESET_LIST_REGIONS',
  REGIONS_RESET_DETAIL: '@regions/RESET_DETAILS_REGION',
};

export default Types;
