import Types from './types';

export function getOwnersRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.OWNERS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getOwnersSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.OWNERS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getOwnersFailure() {
  return {
    type: Types.OWNERS_GET_FAILURE,
  };
}

export function createOwnerRequest(owner, subResourceType = null) {
  return {
    type: Types.OWNERS_CREATE_REQUEST,
    payload: { owner, subResourceType },
  };
}

export function createOwnerSuccess(owner) {
  return {
    type: Types.OWNERS_CREATE_SUCCESS,
    payload: { owner },
  };
}

export function createOwnerFailure() {
  return {
    type: Types.OWNERS_CREATE_FAILURE,
  };
}

export function updateOwnerRequest(id, newOwner) {
  return {
    type: Types.OWNERS_UPDATE_REQUEST,
    payload: { id, newOwner },
  };
}

export function updateOwnerSuccess(newOwner) {
  return {
    type: Types.OWNERS_UPDATE_SUCCESS,
    payload: { newOwner },
  };
}

export function updateOwnerFailure() {
  return {
    type: Types.OWNERS_UPDATE_FAILURE,
  };
}

export function deleteOwnerRequest(id) {
  return {
    type: Types.OWNERS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteOwnerSuccess(id) {
  return {
    type: Types.OWNERS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteOwnerFailure() {
  return {
    type: Types.OWNERS_DELETE_FAILURE,
  };
}

export function becomeOwnerRequest(id) {
  return {
    type: Types.OWNERS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeOwnerSuccess(owner) {
  return {
    type: Types.OWNERS_BECOME_SUCCESS,
    payload: { owner },
  };
}

export function becomeOwnerFailure() {
  return {
    type: Types.OWNERS_BECOME_FAILURE,
  };
}

export function getDetailsOwnerRequest(id) {
  return {
    type: Types.OWNERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsOwnerSuccess(owner) {
  return {
    type: Types.OWNERS_GET_DETAILS_SUCCESS,
    payload: { owner },
  };
}

export function getDetailsOwnerFailure() {
  return {
    type: Types.OWNERS_GET_DETAILS_FAILURE,
  };
}

export function resetListOwners() {
  return {
    type: Types.OWNERS_RESET_LIST,
  };
}

export function resetDetailsOwner() {
  return {
    type: Types.OWNERS_RESET_DETAILS,
  };
}

export function openModalAddOwner() {
  return {
    type: Types.OWNERS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddOwner() {
  return {
    type: Types.OWNERS_ADD_CLOSE_MODAL,
  };
}

export function openModalOwnerObservation() {
  return {
    type: Types.OWNERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalOwnerObservation() {
  return {
    type: Types.OWNERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalOwnerFilter() {
  return {
    type: Types.OWNERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalOwnerFilter() {
  return {
    type: Types.OWNERS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.OWNERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.OWNERS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.OWNERS_RESET_SUB_RESOURCE_DATA,
  };
}
