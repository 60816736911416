import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCulturesSuccess,
  getCulturesFailure,
  createCultureSuccess,
  createCultureFailure,
  updateCultureSuccess,
  updateCultureFailure,
  deleteCultureSuccess,
  deleteCultureFailure,
} from './actions';
import Types from './types';

export function* getCultures({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `cultures${queryParams}`);

    yield put(getCulturesSuccess({ data, filterColumnsOrder, filterSearch }));
  } catch (err) {
    yield put(getCulturesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCulture({ payload }) {
  try {
    const { culture, source } = payload;

    const response = yield call(api.post, 'cultures', culture);

    yield put(createCultureSuccess(response.data, source));

    if (source) {
      toast.success(<IntlMessages id="cultures.create-culture-success" />);
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(createCultureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCulture({ payload }) {
  try {
    const { id, newCulture } = payload;

    const response = yield call(api.put, `cultures/${id}`, newCulture);

    yield put(updateCultureSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCultureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCulture({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `cultures/${id}`);

    yield put(deleteCultureSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCultureFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.CULTURES_GET_REQUEST, getCultures),
  takeLatest(Types.CULTURES_CREATE_REQUEST, createCulture),
  takeLatest(Types.CULTURES_UPDATE_REQUEST, updateCulture),
  takeLatest(Types.CULTURES_DELETE_REQUEST, deleteCulture),
]);
