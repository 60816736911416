const Types = {
  PROMOTER_FILES_GET: '@promoters/GET_PROMOTER_FILES',

  PROMOTER_FILES_CREATE_REQUEST: '@promoters/CREATE_PROMOTER_FILE_REQUEST',
  PROMOTER_FILES_CREATE_SUCCESS: '@promoters/CREATE_PROMOTER_FILE_SUCCESS',
  PROMOTER_FILES_CREATE_FAILURE: '@promoters/CREATE_PROMOTER_FILE_FAILURE',

  PROMOTER_FILES_UPDATE_REQUEST: '@promoters/UPDATE_PROMOTER_FILE_REQUEST',
  PROMOTER_FILES_UPDATE_SUCCESS: '@promoters/UPDATE_PROMOTER_FILE_SUCCESS',
  PROMOTER_FILES_UPDATE_FAILURE: '@promoters/UPDATE_PROMOTER_FILE_FAILURE',

  PROMOTER_FILES_DELETE_REQUEST: '@promoters/DELETE_PROMOTER_FILE_REQUEST',
  PROMOTER_FILES_DELETE_SUCCESS: '@promoters/DELETE_PROMOTER_FILE_SUCCESS',
  PROMOTER_FILES_DELETE_FAILURE: '@promoters/DELETE_PROMOTER_FILE_FAILURE',

  PROMOTER_FILES_OPEN_MODAL: '@promoters/OPEN_MODAL_PROMOTER_FILE',
  PROMOTER_FILES_CLOSE_MODAL: '@promoters/CLOSE_MODAL_PROMOTER_FILE',

  PROMOTER_FILES_RESET_LIST: '@promoters/RESET_LIST_PROMOTER_FILE',
};

export default Types;
