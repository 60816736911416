import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getBillToReceiveChartAccounts,
  openModalBillToReceiveChartAccount,
} from '../billToReceiveChartAccounts/actions';
import { getBillToReceivePixs } from '../billToReceivePixs/actions';
import {
  createBillToReceiveSuccess,
  createBillToReceiveFailure,
  updateBillToReceiveSuccess,
  updateBillToReceiveFailure,
  settlementBillToReceiveSuccess,
  settlementBillToReceiveFailure,
  deleteBillToReceiveSuccess,
  deleteBillToReceiveFailure,
  getDetailsBillToReceiveSuccess,
  getDetailsBillToReceiveFailure,
  setSavingOnSubResource,
  setBillToReceiveTableFilters,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* createBillToReceive({ payload }) {
  try {
    const { billToReceive, subResourceType } = payload;

    const response = yield call(api.post, 'bills', billToReceive);

    yield put(createBillToReceiveSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/bill-to-receive/${response.data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(createBillToReceiveFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToReceive({ payload }) {
  try {
    const { id, newBillToReceive } = payload;

    const response = yield call(api.put, `bills/${id}`, newBillToReceive);

    yield put(updateBillToReceiveSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBillToReceiveFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* settlementBillToReceive({ payload }) {
  try {
    const { id, newBillToReceive, fetchList } = payload;

    const response = yield call(api.put, `bills/${id}`, newBillToReceive);

    yield put(settlementBillToReceiveSuccess(response.data));

    const { listLength } = yield select(state => state.billsToReceive);

    if (fetchList) {
      yield put(
        setBillToReceiveTableFilters({ shouldFetchStatisticData: true })
      );

      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(settlementBillToReceiveFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export function* deleteBillToReceive({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `bills/${id}`);

    yield put(deleteBillToReceiveSuccess(isFromDetail));

    history.push('/bills-to-receive');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.billsToReceive);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(deleteBillToReceiveFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsBillToReceive({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `bills/${id}`);

    const { chart_accounts, pixs, ...rest } = response.data;

    const {
      billToReceiveSettlementModalOpen,
      subResourceType,
      shouldOpenSubResourceModal,
    } = yield select(state => state.billsToReceive);

    if (!billToReceiveSettlementModalOpen) {
      yield put(getBillToReceiveChartAccounts(chart_accounts));
    }

    yield put(getBillToReceivePixs(pixs));

    yield put(getDetailsBillToReceiveSuccess(rest));

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'CHART_ACCOUNT') {
        yield put(openModalBillToReceiveChartAccount());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsBillToReceiveFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(Types.BILLS_TO_RECEIVE_CREATE_REQUEST, createBillToReceive),
  takeLatest(Types.BILLS_TO_RECEIVE_UPDATE_REQUEST, updateBillToReceive),
  takeLatest(
    Types.BILLS_TO_RECEIVE_SETTLEMENT_REQUEST,
    settlementBillToReceive
  ),
  takeLatest(Types.BILLS_TO_RECEIVE_DELETE_REQUEST, deleteBillToReceive),
  takeLatest(
    Types.BILLS_TO_RECEIVE_GET_DETAILS_REQUEST,
    getDetailsBillToReceive
  ),
]);
