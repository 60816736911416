import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getHarvestsSuccess,
  getHarvestsFailure,
  createHarvestSuccess,
  createHarvestFailure,
  updateHarvestSuccess,
  updateHarvestFailure,
  deleteHarvestSuccess,
  deleteHarvestFailure,
} from './actions';
import Types from './types';

export function* getHarvests({ payload }) {
  try {
    const { queryParams, filterColumnsOrder, filterSearch } = payload;

    const { data } = yield call(api.get, `harvests${queryParams}`);

    yield put(getHarvestsSuccess({ data, filterColumnsOrder, filterSearch }));
  } catch (err) {
    yield put(getHarvestsFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createHarvest({ payload }) {
  try {
    const { harvest } = payload;

    const response = yield call(api.post, 'harvests', harvest);

    yield put(createHarvestSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createHarvestFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateHarvest({ payload }) {
  try {
    const { id, newHarvest } = payload;

    const response = yield call(api.put, `harvests/${id}`, newHarvest);

    yield put(updateHarvestSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateHarvestFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteHarvest({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `harvests/${id}`);

    yield put(deleteHarvestSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteHarvestFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.HARVESTS_GET_REQUEST, getHarvests),
  takeLatest(Types.HARVESTS_CREATE_REQUEST, createHarvest),
  takeLatest(Types.HARVESTS_UPDATE_REQUEST, updateHarvest),
  takeLatest(Types.HARVESTS_DELETE_REQUEST, deleteHarvest),
]);
