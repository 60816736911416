import { BookOpen as RFBookOpen } from 'react-feather';

import styled from 'styled-components';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'button':
      styles = `
        width: 18px;
        height: 18px;
        margin-right: 3px;
      `;
      break;

    default:
      styles = ``;
      break;
  }

  return styles;
}

export const BookOpen = styled(RFBookOpen)`
  ${({ type }) => defineStyles(type)}
`;
