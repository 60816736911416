import Types from './types';

export function getHarvestsRequest({
  queryParams,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.HARVESTS_GET_REQUEST,
    payload: {
      queryParams,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getHarvestsSuccess({ data, filterColumnsOrder, filterSearch }) {
  return {
    type: Types.HARVESTS_GET_SUCCESS,
    payload: { data, filterColumnsOrder, filterSearch },
  };
}

export function getHarvestsFailure() {
  return {
    type: Types.HARVESTS_GET_FAILURE,
  };
}

export function createHarvestRequest(harvest) {
  return {
    type: Types.HARVESTS_CREATE_REQUEST,
    payload: { harvest },
  };
}

export function createHarvestSuccess(harvest) {
  return {
    type: Types.HARVESTS_CREATE_SUCCESS,
    payload: { harvest },
  };
}

export function createHarvestFailure() {
  return {
    type: Types.HARVESTS_CREATE_FAILURE,
  };
}

export function updateHarvestRequest(id, newHarvest) {
  return {
    type: Types.HARVESTS_UPDATE_REQUEST,
    payload: { id, newHarvest },
  };
}

export function updateHarvestSuccess(newHarvest) {
  return {
    type: Types.HARVESTS_UPDATE_SUCCESS,
    payload: { newHarvest },
  };
}

export function updateHarvestFailure() {
  return {
    type: Types.HARVESTS_UPDATE_FAILURE,
  };
}

export function deleteHarvestRequest(id) {
  return {
    type: Types.HARVESTS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteHarvestSuccess(id) {
  return {
    type: Types.HARVESTS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteHarvestFailure() {
  return {
    type: Types.HARVESTS_DELETE_FAILURE,
  };
}

export function resetListHarvests() {
  return {
    type: Types.HARVESTS_RESET_LIST,
  };
}

export function openModalHarvest(id) {
  return {
    type: Types.HARVESTS_OPEN_MODAL,
    payload: { id },
  };
}

export function closeModalHarvest() {
  return {
    type: Types.HARVESTS_CLOSE_MODAL,
  };
}

export function clearNewHarvestCulture() {
  return {
    type: Types.HARVESTS_CLEAR_NEW_CULTURE,
  };
}

export function clearNewHarvestUnitMeasure() {
  return {
    type: Types.HARVESTS_CLEAR_NEW_UNIT_MEASURE,
  };
}
