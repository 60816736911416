import Types from './types';

export function getAllBackupDatabasesRequest() {
  return {
    type: Types.ALL_BACKUP_DATABASES_GET_REQUEST,
  };
}

export function getAllBackupDatabasesSuccess(backupDatabases) {
  return {
    type: Types.ALL_BACKUP_DATABASES_GET_SUCCESS,
    payload: { backupDatabases },
  };
}

export function getAllBackupDatabasesFailure() {
  return {
    type: Types.ALL_BACKUP_DATABASES_GET_FAILURE,
  };
}

export function resetListAllBackupDatabases() {
  return {
    type: Types.ALL_BACKUP_DATABASES_RESET_LIST,
  };
}
