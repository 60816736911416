import produce from 'immer';

import BillToReceivePixTypes from '../billToReceivePixs/types';
import Types from './types';

const INITIAL_STATE = {
  listLength: 0,
  detail: {},

  searchThroughTable: true,
  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,
  shouldFetchStatisticData: false,

  creatingFrom: '',

  detailUpdatedOnce: false,
  requestError: false,
  loading: false,
  sharedDetail: false,
  settlementId: null,
  billToReceiveSettlementModalOpen: false,
  billToReceiveObservationModalOpen: false,
  billToReceiveFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  isCreatingPixFromList: false,
};

export default function billsToReceive(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BILLS_TO_RECEIVE_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_CREATE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.BILLS_TO_RECEIVE_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_UPDATE_SUCCESS: {
        const { newBillToReceive } = action.payload;
        draft.detail = newBillToReceive;

        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        draft.billToReceiveObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.BILLS_TO_RECEIVE_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_SETTLEMENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_SETTLEMENT_SUCCESS: {
        const { newBillToReceive } = action.payload;
        draft.detail = newBillToReceive;

        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        draft.loading = false;

        draft.settlementId = null;
        draft.billToReceiveSettlementModalOpen = false;
        break;
      }
      case Types.BILLS_TO_RECEIVE_SETTLEMENT_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_DELETE_SUCCESS: {
        const { isFromDetail } = action.payload;

        if (isFromDetail) {
          if (state.page > 0 && state.listLength === 1) {
            draft.page = state.page - 1;
          }
        }

        draft.loading = false;
        draft.shouldFetchStatisticData = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.billToReceive;
        draft.requestError = false;
        break;
      }
      case Types.BILLS_TO_RECEIVE_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.BILLS_TO_RECEIVE_RESET_LIST: {
        draft.loading = false;
        draft.requestError = false;
        draft.isCreatingPixFromList = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.billToReceiveFilterModalOpen = false;
        draft.creatingFrom = '';

        draft.shouldFetchStatisticData = false;
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.searchThroughTable = true;
        draft.listLength = 0;

        break;
      }

      case Types.BILLS_TO_RECEIVE_RESET_DETAILS: {
        draft.detail = {};
        draft.settlementId = null;
        draft.sharedDetail = false;
        draft.billToReceiveSettlementModalOpen = false;
        draft.billToReceiveObservationModalOpen = false;
        draft.detailUpdatedOnce = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_SETTLEMENT_OPEN_MODAL: {
        draft.settlementId = action.payload.id;
        draft.sharedDetail = action.payload.sharedDetail;
        draft.billToReceiveSettlementModalOpen = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_SETTLEMENT_CLOSE_MODAL: {
        draft.settlementId = null;
        draft.sharedDetail = false;

        draft.billToReceiveSettlementModalOpen = false;

        break;
      }

      case Types.BILLS_TO_RECEIVE_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.BILLS_TO_RECEIVE_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        break;
      }

      case Types.BILLS_TO_RECEIVE_OBSERVATION_OPEN_MODAL: {
        draft.billToReceiveObservationModalOpen = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_OBSERVATION_CLOSE_MODAL: {
        draft.billToReceiveObservationModalOpen = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_FILTER_OPEN_MODAL: {
        draft.billToReceiveFilterModalOpen = true;
        break;
      }
      case Types.BILLS_TO_RECEIVE_FILTER_CLOSE_MODAL: {
        draft.billToReceiveFilterModalOpen = false;
        break;
      }

      case Types.BILLS_TO_RECEIVE_SET_TABLE_FILTERS: {
        const {
          modalFilters,
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
          searchThroughTable,
          shouldFetchStatisticData,
          listLength,
        } = action.payload;

        if (draft.creatingFrom) {
          draft.creatingFrom = '';
        }

        if (typeof listLength !== 'undefined') {
          draft.listLength = listLength;
        }

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }

        if (typeof rowsPerPage !== 'undefined') {
          draft.rowsPerPage = rowsPerPage;
        }

        if (typeof currentPage !== 'undefined') {
          draft.page = currentPage;
        }

        if (typeof searchThroughTable !== 'undefined') {
          draft.searchThroughTable = searchThroughTable;
        }

        if (typeof shouldFetchStatisticData !== 'undefined') {
          draft.shouldFetchStatisticData = shouldFetchStatisticData;
        }

        if (closeModal) {
          draft.billToReceiveFilterModalOpen = false;
        }

        break;
      }
      case Types.BILLS_TO_RECEIVE_SET_INITIAL_TABLE_FILTERS: {
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        draft.shouldFetchStatisticData = false;
        draft.rowsPerPage = 10;
        draft.page = 0;
        draft.searchThroughTable = true;
        break;
      }

      case Types.BILLS_TO_RECEIVE_SET_REQUEST_ERROR: {
        draft.requestError = true;
        break;
      }

      case Types.BILLS_TO_RECEIVE_SET_CREATING_FROM: {
        const { creatingFrom } = action.payload;
        draft.creatingFrom = creatingFrom;
        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_UPDATE_SUCCESS: {
        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_SETTLEMENT_SUCCESS: {
        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          draft.page = state.page - 1;
          draft.detailUpdatedOnce = true;
        }

        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_OPEN_MODAL: {
        if (action.payload.billData) {
          draft.detail = action.payload.billData;

          draft.isCreatingPixFromList = true;
        }

        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_ADD_ACCOUNT_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_DETAIL_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_SHARE_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      case BillToReceivePixTypes.BILL_TO_RECEIVE_PIXS_CLOSE_MODAL: {
        if (draft.isCreatingPixFromList) {
          draft.detail = {};
          draft.isCreatingPixFromList = false;
        }

        break;
      }

      default:
    }
  });
}
