const Types = {
  PAYMENT_METHOD_INSTALLMENTS_GET:
    '@paymentMethod/GET_PAYMENT_METHOD_INSTALLMENTS',

  PAYMENT_METHOD_INSTALLMENTS_CREATE_REQUEST:
    '@paymentMethod/CREATE_PAYMENT_METHOD_INSTALLMENT_REQUEST',
  PAYMENT_METHOD_INSTALLMENTS_CREATE_SUCCESS:
    '@paymentMethod/CREATE_PAYMENT_METHOD_INSTALLMENT_SUCCESS',
  PAYMENT_METHOD_INSTALLMENTS_CREATE_FAILURE:
    '@paymentMethod/CREATE_PAYMENT_METHOD_INSTALLMENT_FAILURE',

  PAYMENT_METHOD_INSTALLMENTS_UPDATE_REQUEST:
    '@paymentMethod/UPDATE_PAYMENT_METHOD_INSTALLMENT_REQUEST',
  PAYMENT_METHOD_INSTALLMENTS_UPDATE_SUCCESS:
    '@paymentMethod/UPDATE_PAYMENT_METHOD_INSTALLMENT_SUCCESS',
  PAYMENT_METHOD_INSTALLMENTS_UPDATE_FAILURE:
    '@paymentMethod/UPDATE_PAYMENT_METHOD_INSTALLMENT_FAILURE',

  PAYMENT_METHOD_INSTALLMENTS_DELETE_REQUEST:
    '@paymentMethod/DELETE_PAYMENT_METHOD_INSTALLMENT_REQUEST',
  PAYMENT_METHOD_INSTALLMENTS_DELETE_SUCCESS:
    '@paymentMethod/DELETE_PAYMENT_METHOD_INSTALLMENT_SUCCESS',
  PAYMENT_METHOD_INSTALLMENTS_DELETE_FAILURE:
    '@paymentMethod/DELETE_PAYMENT_METHOD_INSTALLMENT_FAILURE',

  PAYMENT_METHOD_INSTALLMENTS_RESET_LIST:
    '@paymentMethod/RESET_LIST_PAYMENT_METHOD_INSTALLMENTS',

  PAYMENT_METHOD_INSTALLMENTS_OPEN_MODAL:
    '@paymentMethod/OPEN_MODAL_PAYMENT_METHOD_INSTALLMENTS',
  PAYMENT_METHOD_INSTALLMENTS_CLOSE_MODAL:
    '@paymentMethod/CLOSE_MODAL_PAYMENT_METHOD_INSTALLMENTS',
};

export default Types;
