import styled, { createGlobalStyle } from 'styled-components';

import { Paper as MuiPaper } from '@material-ui/core';
import { spacing } from '@material-ui/system';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.dashboard.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.dashboard.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
