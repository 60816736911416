import React from 'react';
import { toast } from 'react-toastify';

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { getUserFiles, resetListUserFile } from '../userFiles/actions';
import {
  updateUserSuccess,
  updateUserFailure,
  getDetailsUserSuccess,
  getDetailsUserFailure,
} from './actions';
import Types from './types';

export function* updateUser({ payload }) {
  try {
    const { newUser, resetForm } = payload;

    const response = yield call(api.put, 'users', newUser);

    yield put(updateUserSuccess(response.data));

    resetForm();

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* getDetailsUser() {
  try {
    const response = yield call(api.get, `users`);

    const { files, ...rest } = response.data;

    yield put(getUserFiles(files));

    yield put(getDetailsUserSuccess(rest));
  } catch (err) {
    yield put(getDetailsUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsUser() {
  yield put(resetListUserFile());
}

export default all([
  takeLatest(Types.USER_UPDATE_REQUEST, updateUser),
  takeLatest(Types.USER_GET_DETAILS_REQUEST, getDetailsUser),
  takeLatest(Types.USER_RESET_DETAILS, resetDetailsUser),
]);
