import React from 'react';

import PropTypes from 'prop-types';

import { Print } from './styles';

export default function PrintIcon({ type }) {
  return <Print type={type} />;
}

PrintIcon.propTypes = {
  type: PropTypes.string,
};

PrintIcon.defaultProps = {
  type: '',
};
