import produce from 'immer';

import BillToPayTypes from '../billsToPay/types';
import BillToReceiveTypes from '../billsToReceive/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  requestError: false,
  loading: false,
  detailModalSource: '',
  contactDetailModalOpen: false,
  contactDetailModalLoading: false,
  contactModalOpen: false,
};

export default function contacts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CONTACTS_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTACTS_GET_SUCCESS: {
        draft.list = action.payload.contacts;
        draft.loading = false;
        break;
      }
      case Types.CONTACTS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CONTACTS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.contactDetailModalLoading = true;
        break;
      }
      case Types.CONTACTS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.contact;
        draft.contactDetailModalLoading = false;
        break;
      }
      case Types.CONTACTS_GET_DETAILS_FAILURE: {
        draft.contactDetailModalLoading = false;
        break;
      }

      case Types.CONTACTS_OPEN_MODAL: {
        draft.contactModalOpen = true;
        break;
      }
      case Types.CONTACTS_CLOSE_MODAL: {
        draft.contactModalOpen = false;
        draft.list = [];
        break;
      }

      case Types.CONTACTS_RESET_LIST: {
        draft.list = [];
        draft.contactModalOpen = false;
        break;
      }

      case Types.CONTACTS_DETAIL_OPEN_MODAL: {
        draft.contactDetailModalOpen = true;
        draft.detailModalSource = action.payload.source;
        break;
      }
      case Types.CONTACTS_DETAIL_CLOSE_MODAL: {
        draft.contactDetailModalOpen = false;
        draft.detail = {};
        draft.detailModalSource = '';
        break;
      }

      case Types.CONTACTS_RESET_DETAILS: {
        draft.loading = false;
        draft.detail = {};
        draft.detailModalSource = '';
        draft.contactDetailModalOpen = false;
        draft.contactDetailModalLoading = false;
        break;
      }

      case BillToPayTypes.BILLS_TO_PAY_RESET_DETAILS: {
        draft.detail = {};
        draft.contactDetailModalOpen = false;
        draft.contactDetailModalLoading = false;
        draft.detailModalSource = '';
        break;
      }

      case BillToReceiveTypes.BILLS_TO_RECEIVE_RESET_DETAILS: {
        draft.detail = {};
        draft.contactDetailModalOpen = false;
        break;
      }

      default:
    }
  });
}
