import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createRuralPropertyOwnerSuccess,
  createRuralPropertyOwnerFailure,
  deleteRuralPropertyOwnerSuccess,
  deleteRuralPropertyOwnerFailure,
} from './actions';
import Types from './types';

export function* createRuralPropertyOwner({ payload }) {
  try {
    const { property_id, owner } = payload;

    const response = yield call(
      api.post,
      `rural-properties/${property_id}/owners`,
      owner
    );

    yield put(createRuralPropertyOwnerSuccess(response.data.owner));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* deleteRuralPropertyOwner({ payload }) {
  try {
    const { property_id, property_owner_id } = payload;

    yield call(
      api.delete,
      `rural-properties/${property_id}/owners/${property_owner_id}`
    );

    yield put(deleteRuralPropertyOwnerSuccess(property_owner_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteRuralPropertyOwnerFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.RURAL_PROPERTY_OWNERS_CREATE_REQUEST,
    createRuralPropertyOwner
  ),
  takeLatest(
    Types.RURAL_PROPERTY_OWNERS_DELETE_REQUEST,
    deleteRuralPropertyOwner
  ),
]);
