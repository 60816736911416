import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getSellerCompaniesCompaniesNotBoundSuccess,
  getSellerCompaniesCompaniesNotBoundFailure,
  createSellerCompanySuccess,
  createSellerCompanyFailure,
  deleteSellerCompanySuccess,
  deleteSellerCompanyFailure,
} from './actions';
import Types from './types';

export function* getSellerCompaniesCompaniesNotBound({ payload }) {
  try {
    const { seller_id } = payload;

    const response = yield call(api.get, `sellers/${seller_id}/companies`);

    yield put(getSellerCompaniesCompaniesNotBoundSuccess(response.data));
  } catch (err) {
    yield put(getSellerCompaniesCompaniesNotBoundFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(
      <IntlMessages id="seller-companies.get-seller-companies-failure" />
    );
  }
}

export function* createSellerCompany({ payload }) {
  try {
    const { seller_id, company } = payload;

    const response = yield call(
      api.post,
      `sellers/${seller_id}/companies`,
      company
    );

    yield put(createSellerCompanySuccess(response.data));

    toast.success(<IntlMessages id="sellers.messages.create-bound-success" />);
  } catch (err) {
    yield put(createSellerCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sellers.messages.create-bound-failure" />);
  }
}

export function* deleteSellerCompany({ payload }) {
  try {
    const { seller_id, company_id } = payload;

    yield call(api.delete, `sellers/${seller_id}/companies/${company_id}`);

    const currentCompany = yield select(state => state.auth.company);

    yield put(deleteSellerCompanySuccess(company_id, currentCompany.id));

    toast.success(<IntlMessages id="sellers.messages.delete-bound-success" />);
  } catch (err) {
    yield put(deleteSellerCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sellers.messages.delete-bound-failure" />);
  }
}

export default all([
  takeLatest(
    Types.SELLER_COMPANIES_GET_COMPANIES_NOT_BOUND_REQUEST,
    getSellerCompaniesCompaniesNotBound
  ),
  takeLatest(Types.SELLER_COMPANIES_CREATE_REQUEST, createSellerCompany),
  takeLatest(Types.SELLER_COMPANIES_DELETE_REQUEST, deleteSellerCompany),
]);
