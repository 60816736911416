import produce from 'immer';

import PaymentMethodTypes from '../paymentMethods/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  paymentMethodInstallmentModalOpen: false,
  newRegister: false,
  editInstallment: null,
};

export default function paymentMethodInstallments(
  state = INITIAL_STATE,
  action
) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PAYMENT_METHOD_INSTALLMENTS_GET: {
        draft.list = action.payload.installments;
        break;
      }

      case Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_SUCCESS: {
        draft.list.push(action.payload.installment);
        draft.loading = false;
        draft.paymentMethodInstallmentModalOpen = false;
        draft.newRegister = false;
        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_SUCCESS: {
        const { newInstallment } = action.payload;

        draft.list = state.list.map(installment => {
          return installment.id === newInstallment.id
            ? newInstallment
            : installment;
        });

        draft.detail = {};
        draft.loading = false;
        draft.editInstallment = null;
        draft.paymentMethodInstallmentModalOpen = false;

        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_SUCCESS: {
        const { installment_id } = action.payload;

        draft.list = state.list.filter(installment => {
          return installment.id !== installment_id;
        });

        if (draft.detail.id && installment_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PAYMENT_METHOD_INSTALLMENTS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.PAYMENT_METHOD_INSTALLMENTS_OPEN_MODAL: {
        const { installment_id, newRegister, editInstallment } = action.payload;

        draft.detail = {};
        draft.newRegister = newRegister;
        draft.editInstallment = editInstallment;

        if (installment_id) {
          [draft.detail] = draft.list.filter(
            element => element.id === installment_id
          );
        }

        draft.paymentMethodInstallmentModalOpen = true;
        break;
      }
      case Types.PAYMENT_METHOD_INSTALLMENTS_CLOSE_MODAL: {
        draft.paymentMethodInstallmentModalOpen = false;
        draft.newRegister = false;
        draft.editInstallment = null;
        draft.detail = {};
        break;
      }

      case PaymentMethodTypes.PAYMENT_METHODS_RESET_DETAILS: {
        draft.detail = {};
        draft.newRegister = false;
        draft.editInstallment = null;
        draft.paymentMethodInstallmentModalOpen = false;
        break;
      }

      default:
    }
  });
}
