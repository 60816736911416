import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createPromoterPhoneSuccess,
  createPromoterPhoneFailure,
  updatePromoterPhoneSuccess,
  updatePromoterPhoneFailure,
  deletePromoterPhoneSuccess,
  deletePromoterPhoneFailure,
} from './actions';
import Types from './types';

export function* createPromoterPhone({ payload }) {
  try {
    const { promoter_id, phone } = payload;

    const response = yield call(
      api.post,
      `promoters/${promoter_id}/phones`,
      phone
    );

    yield put(createPromoterPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createPromoterPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePromoterPhone({ payload }) {
  try {
    const { promoter_id, phone_id, newPhone } = payload;

    const response = yield call(
      api.put,
      `promoters/${promoter_id}/phones/${phone_id}`,
      newPhone
    );

    yield put(updatePromoterPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updatePromoterPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePromoterPhone({ payload }) {
  const { promoter_id, phone_id } = payload;

  const phones = yield select(state => state.promoterPhones.list);

  const phone = phones.find(data => data.id === phone_id);

  if (phone.is_main && phones.length > 1) {
    yield put(deletePromoterPhoneFailure());

    toast.error(<IntlMessages id="phones.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `promoters/${promoter_id}/phones/${phone_id}`);

    yield put(deletePromoterPhoneSuccess(phone_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deletePromoterPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.PROMOTER_PHONES_CREATE_REQUEST, createPromoterPhone),
  takeLatest(Types.PROMOTER_PHONES_UPDATE_REQUEST, updatePromoterPhone),
  takeLatest(Types.PROMOTER_PHONES_DELETE_REQUEST, deletePromoterPhone),
]);
