import {
  LogOut as MuiLogOut,
  User as MuiUser,
  ToggleRight,
  HelpCircle,
  Globe as RFGlobe,
} from 'react-feather';

import styled from 'styled-components';

import { SwapHoriz } from '@material-ui/icons';

import colors from '~/theme/colors';

export const LogOut = styled(MuiLogOut)`
  color: ${colors.darkRegular};
  margin-right: 10px;
`;

export const User = styled(MuiUser)`
  color: ${colors.darkRegular};
  margin-right: 10px;
`;

export const Switch = styled(SwapHoriz)`
  color: ${colors.darkRegular};
  margin-right: 10px;
`;

export const Setting = styled(ToggleRight)`
  color: ${colors.darkRegular};
  margin-right: 10px;
`;

export const Help = styled(HelpCircle)`
  color: ${colors.darkRegular};
  margin-right: 10px;
`;

export const Globe = styled(RFGlobe)`
  color: ${colors.darkRegular};
  margin-right: 10px;
`;
