import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createOwnerEmailSuccess,
  createOwnerEmailFailure,
  updateOwnerEmailSuccess,
  updateOwnerEmailFailure,
  deleteOwnerEmailSuccess,
  deleteOwnerEmailFailure,
} from './actions';
import Types from './types';

export function* createOwnerEmail({ payload }) {
  try {
    const { owner_id, email } = payload;

    const response = yield call(api.post, `owners/${owner_id}/emails`, email);

    yield put(createOwnerEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createOwnerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOwnerEmail({ payload }) {
  try {
    const { owner_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `owners/${owner_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateOwnerEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateOwnerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOwnerEmail({ payload }) {
  const { owner_id, email_id } = payload;

  const emails = yield select(state => state.ownerEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteOwnerEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `owners/${owner_id}/emails/${email_id}`);

    yield put(deleteOwnerEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteOwnerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.OWNER_EMAILS_CREATE_REQUEST, createOwnerEmail),
  takeLatest(Types.OWNER_EMAILS_UPDATE_REQUEST, updateOwnerEmail),
  takeLatest(Types.OWNER_EMAILS_DELETE_REQUEST, deleteOwnerEmail),
]);
