const Types = {
  TAX_SITUATIONS_GET_REQUEST: '@taxSituations/GET_TAX_SITUATIONS_REQUEST',
  TAX_SITUATIONS_GET_SUCCESS: '@taxSituations/GET_TAX_SITUATIONS_SUCCESS',
  TAX_SITUATIONS_GET_FAILURE: '@taxSituations/GET_TAX_SITUATIONS_FAILURE',

  TAX_SITUATIONS_FILTER_OPEN_MODAL:
    '@taxSituations/OPEN_MODAL_FILTER_TAX_SITUATION',
  TAX_SITUATIONS_FILTER_CLOSE_MODAL:
    '@taxSituations/CLOSE_MODAL_FILTER_TAX_SITUATION',

  TAX_SITUATIONS_RESET_LIST: '@taxSituations/RESET_LIST_TAX_SITUATIONS',
};

export default Types;
