import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createBankAccountPixFileSuccess,
  createBankAccountPixFileFailure,
  updateBankAccountPixFileSuccess,
  updateBankAccountPixFileFailure,
  deleteBankAccountPixFileSuccess,
  deleteBankAccountPixFileFailure,
} from './actions';
import Types from './types';

export function* createBankAccountPixFile({ payload }) {
  try {
    const { bank_account_id, bank_account_pix_id, file, setFieldValue } =
      payload;

    const response = yield call(
      api.post,
      `bank-accounts/${bank_account_id}/pixs/${bank_account_pix_id}/files`,
      file
    );

    yield put(
      createBankAccountPixFileSuccess(response.data, bank_account_pix_id)
    );

    toast.success(<IntlMessages id="messages.save-success" />);

    setFieldValue('static_pix_status', true);
  } catch (err) {
    yield put(createBankAccountPixFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBankAccountPixFile({ payload }) {
  try {
    const { bank_account_id, bank_account_pix_id, file_id, newFile } = payload;

    const response = yield call(
      api.put,
      `bank-accounts/${bank_account_id}/pixs/${bank_account_pix_id}/files/${file_id}`,
      newFile
    );

    yield put(updateBankAccountPixFileSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateBankAccountPixFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBankAccountPixFile({ payload }) {
  try {
    const { bank_account_id, bank_account_pix_id, file_id, setFieldValue } =
      payload;

    yield call(
      api.delete,
      `bank-accounts/${bank_account_id}/pixs/${bank_account_pix_id}/files/${file_id}`
    );

    yield put(deleteBankAccountPixFileSuccess(file_id, bank_account_pix_id));

    toast.success(<IntlMessages id="messages.delete-success" />);

    setFieldValue('static_pix_status', false);
  } catch (err) {
    yield put(deleteBankAccountPixFileFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.BANK_ACCOUNT_PIX_FILES_CREATE_REQUEST,
    createBankAccountPixFile
  ),
  takeLatest(
    Types.BANK_ACCOUNT_PIX_FILES_UPDATE_REQUEST,
    updateBankAccountPixFile
  ),
  takeLatest(
    Types.BANK_ACCOUNT_PIX_FILES_DELETE_REQUEST,
    deleteBankAccountPixFile
  ),
]);
