const Types = {
  SELLERS_GET_REQUEST: '@sellers/GET_SELLERS_REQUEST',
  SELLERS_GET_SUCCESS: '@sellers/GET_SELLERS_SUCCESS',
  SELLERS_GET_FAILURE: '@sellers/GET_SELLERS_FAILURE',

  SELLERS_CREATE_REQUEST: '@sellers/CREATE_SELLER_REQUEST',
  SELLERS_CREATE_SUCCESS: '@sellers/CREATE_SELLER_SUCCESS',
  SELLERS_CREATE_FAILURE: '@sellers/CREATE_SELLER_FAILURE',

  SELLERS_UPDATE_REQUEST: '@sellers/UPDATE_SELLER_REQUEST',
  SELLERS_UPDATE_SUCCESS: '@sellers/UPDATE_SELLER_SUCCESS',
  SELLERS_UPDATE_FAILURE: '@sellers/UPDATE_SELLER_FAILURE',

  SELLERS_DELETE_REQUEST: '@sellers/DELETE_SELLER_REQUEST',
  SELLERS_DELETE_SUCCESS: '@sellers/DELETE_SELLER_SUCCESS',
  SELLERS_DELETE_FAILURE: '@sellers/DELETE_SELLER_FAILURE',

  SELLERS_BECOME_REQUEST: '@sellers/BECOME_SELLER_REQUEST',
  SELLERS_BECOME_SUCCESS: '@sellers/BECOME_SELLER_SUCCESS',
  SELLERS_BECOME_FAILURE: '@sellers/BECOME_SELLER_FAILURE',

  SELLERS_GET_DETAILS_REQUEST: '@sellers/GET_DETAILS_SELLER_REQUEST',
  SELLERS_GET_DETAILS_SUCCESS: '@sellers/GET_DETAILS_SELLER_SUCCESS',
  SELLERS_GET_DETAILS_FAILURE: '@sellers/GET_DETAILS_SELLER_FAILURE',

  SELLERS_RESET_LIST: '@sellers/RESET_LIST_SELLERS',
  SELLERS_RESET_DETAILS: '@sellers/RESET_DETAILS_SELLER',

  SELLERS_SET_SAVING_ON_SUB_RESOURCE:
    '@sellers/SET_SAVING_ON_SUB_RESOURCE_SELLER',
  SELLERS_RESET_SUB_RESOURCE_DATA: '@sellers/RESET_SUB_RESOURCE_DATA_SELLER',

  SELLERS_ADD_OPEN_MODAL: '@sellers/OPEN_MODAL_ADD_SELLER',
  SELLERS_ADD_CLOSE_MODAL: '@sellers/CLOSE_MODAL_ADD_SELLER',

  SELLERS_FILTER_OPEN_MODAL: '@sellers/OPEN_MODAL_FILTER_SELLER',
  SELLERS_FILTER_CLOSE_MODAL: '@sellers/CLOSE_MODAL_FILTER_SELLER',

  SELLERS_OBSERVATION_OPEN_MODAL: '@sellers/OPEN_MODAL_SELLER_OBSERVATION',
  SELLERS_OBSERVATION_CLOSE_MODAL: '@sellers/CLOSE_MODAL_SELLER_OBSERVATION',

  SELLERS_OPEN_FILE_DIALOG: '@sellers/OPEN_SELLER_FILE_DIALOG',
};

export default Types;
