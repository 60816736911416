import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSupervisorAddressSuccess,
  createSupervisorAddressFailure,
  updateSupervisorAddressSuccess,
  updateSupervisorAddressFailure,
  deleteSupervisorAddressSuccess,
  deleteSupervisorAddressFailure,
} from './actions';
import Types from './types';

export function* createSupervisorAddress({ payload }) {
  try {
    const { supervisor_id, address } = payload;

    const response = yield call(
      api.post,
      `supervisors/${supervisor_id}/addresses`,
      address
    );

    yield put(createSupervisorAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSupervisorAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupervisorAddress({ payload }) {
  try {
    const { supervisor_id, address_id, newAddress } = payload;

    const response = yield call(
      api.put,
      `supervisors/${supervisor_id}/addresses/${address_id}`,
      newAddress
    );

    yield put(updateSupervisorAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupervisorAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupervisorAddress({ payload }) {
  const { supervisor_id, address_id } = payload;

  const addresses = yield select(state => state.supervisorAddresses.list);

  const address = addresses.find(data => data.id === address_id);

  if (address.is_main && addresses.length > 1) {
    yield put(deleteSupervisorAddressFailure());

    toast.error(<IntlMessages id="addresses.error-delete-is-main" />);

    return;
  }

  try {
    yield call(
      api.delete,
      `supervisors/${supervisor_id}/addresses/${address_id}`
    );

    yield put(deleteSupervisorAddressSuccess(address_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupervisorAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    Types.SUPERVISOR_ADDRESSES_CREATE_REQUEST,
    createSupervisorAddress
  ),
  takeLatest(
    Types.SUPERVISOR_ADDRESSES_UPDATE_REQUEST,
    updateSupervisorAddress
  ),
  takeLatest(
    Types.SUPERVISOR_ADDRESSES_DELETE_REQUEST,
    deleteSupervisorAddress
  ),
]);
