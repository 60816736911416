import React from 'react';
import { toast } from 'react-toastify';

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';
import { formatUrl } from '~/utils/formats';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCompanyAddresses,
  openModalCompanyAddress,
  resetListCompanyAddress,
} from '../companyAddresses/actions';
import {
  getCompanyEmails,
  openModalCompanyEmail,
  resetListCompanyEmail,
} from '../companyEmails/actions';
import { getCompanyFiles, resetListCompanyFile } from '../companyFiles/actions';
import {
  getCompanyPhones,
  openModalCompanyPhone,
  resetListCompanyPhone,
} from '../companyPhones/actions';
import {
  getCompanySubsidiaries,
  openModalCompanySubsidiary,
  resetListCompanySubsidiary,
} from '../companySubsidiaries/actions';
import {
  getCompanyUsers,
  openModalCompanyUser,
  resetListCompanyUser,
} from '../companyUsers/actions';
import {
  getCompaniesSuccess,
  getCompaniesFailure,
  createCompanySuccess,
  createCompanyFailure,
  deleteCompanySuccess,
  deleteCompanyFailure,
  // becomeCompanySuccess,
  // becomeCompanyFailure,
  updateCompanySuccess,
  updateCompanyFailure,
  getDetailsCompanySuccess,
  getDetailsCompanyFailure,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

function getRelatedSubsidiaryCompanies(
  subsidiaryCompanies,
  id,
  companies = []
) {
  const filteredRelatedCompanies = subsidiaryCompanies.filter(
    company => company.company_id === id
  );

  companies.push(...filteredRelatedCompanies);

  if (filteredRelatedCompanies.length === 0) {
    return companies;
  }

  filteredRelatedCompanies.forEach(item => {
    return getRelatedSubsidiaryCompanies(
      subsidiaryCompanies,
      item.id,
      companies
    );
  });

  return companies;
}

export function* getCompanies({ payload }) {
  try {
    const { types } = payload;

    let url = 'companies';

    if (types) {
      url = formatUrl(`companies?type=[${types}]`);
    }

    const response = yield call(api.get, url);

    yield put(getCompaniesSuccess(response.data));
  } catch (err) {
    yield put(getCompaniesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCompany({ payload }) {
  try {
    const { company } = payload;

    const response = yield call(api.post, 'companies', company);

    yield put(createCompanySuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCompany({ payload }) {
  try {
    const { newCompany, subResourceType } = payload;

    const response = yield call(api.put, 'companies', newCompany);

    yield put(updateCompanySuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);

    if (subResourceType === 'PHONE') {
      yield put(openModalCompanyPhone());
    } else if (subResourceType === 'EMAIL') {
      yield put(openModalCompanyEmail());
    } else if (subResourceType === 'ADDRESS') {
      yield put(openModalCompanyAddress());
    } else if (subResourceType === 'USER') {
      yield put(openModalCompanyUser());
    } else if (subResourceType === 'SUBSIDIARY') {
      yield put(openModalCompanySubsidiary(response.data.id));
    } else if (subResourceType === 'FILE') {
      yield put(setOpenFileDialog());
    }

    yield put(resetSubResourceData());
  } catch (err) {
    yield put(updateCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCompany() {
  try {
    yield call(api.delete, 'companies');

    yield put(deleteCompanySuccess());

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

// export function* becomeCompany({ payload }) {
//   try {
//     const { id } = payload;

//     const response = yield call(api.put, `persons/${id}/become-company`);

//     yield put(becomeCompanySuccess(response.data));
//   } catch (err) {
//     yield put(becomeCompanyFailure());

//     if (err.isShownAPIErrorMessages) return;

//     toast.error(<IntlMessages id="messages.get-failure" />);
//   }
// }

export function* getDetailsCompany({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `companies/${id}`);

    const {
      phones,
      addresses,
      emails,
      users,
      group_companies,
      files,
      ...rest
    } = response.data;

    yield put(getCompanyPhones(phones));

    yield put(getCompanyAddresses(addresses));

    yield put(getCompanyEmails(emails));

    yield put(getCompanyUsers(users));

    yield put(getCompanyFiles(files));

    const filteredSubsidiariesCompanies = group_companies.filter(
      groupCompany => groupCompany.level > rest.level
    );

    const filteredRelatedCompanies =
      rest.type !== 'PARENT'
        ? getRelatedSubsidiaryCompanies(filteredSubsidiariesCompanies, id)
        : filteredSubsidiariesCompanies;

    yield put(getCompanySubsidiaries(filteredRelatedCompanies));

    yield put(getDetailsCompanySuccess(rest));
  } catch (err) {
    yield put(getDetailsCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsCompany() {
  yield put(resetListCompanyPhone());

  yield put(resetListCompanyAddress());

  yield put(resetListCompanyEmail());

  yield put(resetListCompanyUser());

  yield put(resetListCompanySubsidiary());

  yield put(resetListCompanyFile());
}

export default all([
  takeLatest(Types.COMPANIES_GET_REQUEST, getCompanies),
  takeLatest(Types.COMPANIES_CREATE_REQUEST, createCompany),
  takeLatest(Types.COMPANIES_UPDATE_REQUEST, updateCompany),
  takeLatest(Types.COMPANIES_DELETE_REQUEST, deleteCompany),
  // takeLatest(Types.COMPANIES_BECOME_REQUEST, becomeCompany),
  takeLatest(Types.COMPANIES_GET_DETAILS_REQUEST, getDetailsCompany),
  takeLatest(Types.COMPANIES_RESET_DETAILS, resetDetailsCompany),
]);
