import Types from './types';

export function getSupplierAddresses(addresses) {
  return {
    type: Types.SUPPLIER_ADDRESSES_GET,
    payload: { addresses },
  };
}

export function createSupplierAddressRequest(supplier_id, address) {
  return {
    type: Types.SUPPLIER_ADDRESSES_CREATE_REQUEST,
    payload: { supplier_id, address },
  };
}

export function createSupplierAddressSuccess(address) {
  return {
    type: Types.SUPPLIER_ADDRESSES_CREATE_SUCCESS,
    payload: { address },
  };
}

export function createSupplierAddressFailure() {
  return {
    type: Types.SUPPLIER_ADDRESSES_CREATE_FAILURE,
  };
}

export function updateSupplierAddressRequest(
  supplier_id,
  address_id,
  newAddress
) {
  return {
    type: Types.SUPPLIER_ADDRESSES_UPDATE_REQUEST,
    payload: { supplier_id, address_id, newAddress },
  };
}

export function updateSupplierAddressSuccess(newAddress) {
  return {
    type: Types.SUPPLIER_ADDRESSES_UPDATE_SUCCESS,
    payload: { newAddress },
  };
}

export function updateSupplierAddressFailure() {
  return {
    type: Types.SUPPLIER_ADDRESSES_UPDATE_FAILURE,
  };
}

export function deleteSupplierAddressRequest(supplier_id, address_id) {
  return {
    type: Types.SUPPLIER_ADDRESSES_DELETE_REQUEST,
    payload: { supplier_id, address_id },
  };
}

export function deleteSupplierAddressSuccess(address_id) {
  return {
    type: Types.SUPPLIER_ADDRESSES_DELETE_SUCCESS,
    payload: { address_id },
  };
}

export function deleteSupplierAddressFailure() {
  return {
    type: Types.SUPPLIER_ADDRESSES_DELETE_FAILURE,
  };
}

export function resetListSupplierAddress() {
  return {
    type: Types.SUPPLIER_ADDRESSES_RESET_LIST,
  };
}

export function openModalSupplierAddress(address_id) {
  return {
    type: Types.SUPPLIER_ADDRESSES_OPEN_MODAL,
    payload: { address_id },
  };
}

export function closeModalSupplierAddress() {
  return {
    type: Types.SUPPLIER_ADDRESSES_CLOSE_MODAL,
  };
}
