import Types from './types';

export function getSellerUsers(user) {
  return {
    type: Types.SELLER_USERS_GET,
    payload: { user },
  };
}

export function getSellerUsersRequest(seller_id) {
  return {
    type: Types.SELLER_USERS_GET_REQUEST,
    payload: { seller_id },
  };
}

export function getSellerUsersSuccess(users) {
  return {
    type: Types.SELLER_USERS_GET_SUCCESS,
    payload: { users },
  };
}

export function getSellerUsersFailure() {
  return {
    type: Types.SELLER_USERS_GET_FAILURE,
  };
}

export function createSellerUserRequest(seller_id, user) {
  return {
    type: Types.SELLER_USERS_CREATE_REQUEST,
    payload: { seller_id, user },
  };
}

export function createSellerUserSuccess(user) {
  return {
    type: Types.SELLER_USERS_CREATE_SUCCESS,
    payload: { user },
  };
}

export function createSellerUserFailure() {
  return {
    type: Types.SELLER_USERS_CREATE_FAILURE,
  };
}

export function deleteSellerUserRequest(seller_id) {
  return {
    type: Types.SELLER_USERS_DELETE_REQUEST,
    payload: { seller_id },
  };
}

export function deleteSellerUserSuccess() {
  return {
    type: Types.SELLER_USERS_DELETE_SUCCESS,
  };
}

export function deleteSellerUserFailure() {
  return {
    type: Types.SELLER_USERS_DELETE_FAILURE,
  };
}

export function resetListSellerUser() {
  return {
    type: Types.SELLER_USERS_RESET_LIST,
  };
}

export function openModalSellerUser() {
  return {
    type: Types.SELLER_USERS_OPEN_MODAL,
  };
}

export function closeModalSellerUser() {
  return {
    type: Types.SELLER_USERS_CLOSE_MODAL,
  };
}
