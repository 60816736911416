import Types from './types';

export function getSellersRequest({
  queryParams,
  modalFilters,
  filterColumnsOrder,
  filterSearch = '',
}) {
  return {
    type: Types.SELLERS_GET_REQUEST,
    payload: {
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch,
    },
  };
}

export function getSellersSuccess({
  data,
  modalFilters,
  filterColumnsOrder,
  filterSearch,
}) {
  return {
    type: Types.SELLERS_GET_SUCCESS,
    payload: { data, modalFilters, filterColumnsOrder, filterSearch },
  };
}

export function getSellersFailure() {
  return {
    type: Types.SELLERS_GET_FAILURE,
  };
}

export function createSellerRequest(seller, subResourceType = null) {
  return {
    type: Types.SELLERS_CREATE_REQUEST,
    payload: { seller, subResourceType },
  };
}

export function createSellerSuccess(seller) {
  return {
    type: Types.SELLERS_CREATE_SUCCESS,
    payload: { seller },
  };
}

export function createSellerFailure() {
  return {
    type: Types.SELLERS_CREATE_FAILURE,
  };
}

export function updateSellerRequest(id, newSeller) {
  return {
    type: Types.SELLERS_UPDATE_REQUEST,
    payload: { id, newSeller },
  };
}

export function updateSellerSuccess(newSeller) {
  return {
    type: Types.SELLERS_UPDATE_SUCCESS,
    payload: { newSeller },
  };
}

export function updateSellerFailure() {
  return {
    type: Types.SELLERS_UPDATE_FAILURE,
  };
}

export function deleteSellerRequest(id) {
  return {
    type: Types.SELLERS_DELETE_REQUEST,
    payload: { id },
  };
}

export function deleteSellerSuccess(id) {
  return {
    type: Types.SELLERS_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteSellerFailure() {
  return {
    type: Types.SELLERS_DELETE_FAILURE,
  };
}

export function becomeSellerRequest(id) {
  return {
    type: Types.SELLERS_BECOME_REQUEST,
    payload: { id },
  };
}

export function becomeSellerSuccess(seller) {
  return {
    type: Types.SELLERS_BECOME_SUCCESS,
    payload: { seller },
  };
}

export function becomeSellerFailure() {
  return {
    type: Types.SELLERS_BECOME_FAILURE,
  };
}

export function getDetailsSellerRequest(id) {
  return {
    type: Types.SELLERS_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsSellerSuccess(seller) {
  return {
    type: Types.SELLERS_GET_DETAILS_SUCCESS,
    payload: { seller },
  };
}

export function getDetailsSellerFailure() {
  return {
    type: Types.SELLERS_GET_DETAILS_FAILURE,
  };
}

export function resetListSellers() {
  return {
    type: Types.SELLERS_RESET_LIST,
  };
}

export function resetDetailsSeller() {
  return {
    type: Types.SELLERS_RESET_DETAILS,
  };
}

export function openModalAddSeller() {
  return {
    type: Types.SELLERS_ADD_OPEN_MODAL,
  };
}

export function closeModalAddSeller() {
  return {
    type: Types.SELLERS_ADD_CLOSE_MODAL,
  };
}

export function openModalSellerObservation() {
  return {
    type: Types.SELLERS_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalSellerObservation() {
  return {
    type: Types.SELLERS_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalSellerFilter() {
  return {
    type: Types.SELLERS_FILTER_OPEN_MODAL,
  };
}

export function closeModalSellerFilter() {
  return {
    type: Types.SELLERS_FILTER_CLOSE_MODAL,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.SELLERS_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function setOpenFileDialog() {
  return {
    type: Types.SELLERS_OPEN_FILE_DIALOG,
  };
}

export function resetSubResourceData() {
  return {
    type: Types.SELLERS_RESET_SUB_RESOURCE_DATA,
  };
}
