export function formatField(value, type) {
  if (!value || !type) return '';

  let formattedValue = '';

  switch (type) {
    case 'CADESP':
      formattedValue = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );

      break;

    case 'CNPJ':
      formattedValue = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );

      break;

    case 'CPF':
      formattedValue = value.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{1,2}).*/,
        '$1.$2.$3-$4'
      );

      break;

    case 'IE':
      formattedValue = value.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{3}).*/,
        '$1.$2.$3.$4'
      );

      break;

    case 'NCM':
      formattedValue = value.replace(/^(\d{4})(\d{2})(\d{2}).*/, '$1.$2.$3');

      break;

    case 'PHONE':
      formattedValue =
        value.length > 0 && value.length <= 10
          ? value.replace(/^(\d{0,2})(\d{0,4})(\d{0,4}).*/, '($1) $2-$3')
          : value.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');

      break;

    case 'POSTAL_CODE':
      formattedValue = value.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');

      break;

    case 'RG':
      {
        const formatDocument = value.toUpperCase().replace(/[^\dX]/g, '');
        formattedValue = formatDocument.replace(
          /^(\d{1,2})(\d{3})(\d{3})([\dX])$/,
          '$1.$2.$3-$4'
        );
      }

      break;

    default:
      break;
  }

  return formattedValue;
}

export function formatFullAddress({
  address,
  number,
  neighborhood,
  complement,
  city,
  state,
  postal_code,
}) {
  let fullAddress = address || '';

  if (number) {
    fullAddress += fullAddress ? `, ${number}` : number;
  }

  if (neighborhood) {
    fullAddress += fullAddress ? ` - ${neighborhood}` : neighborhood;
  }

  if (complement) {
    fullAddress += fullAddress ? ` - ${complement}` : complement;
  }

  if (city) {
    fullAddress += fullAddress ? ` - ${city}` : city;
  }

  if (state) {
    fullAddress += fullAddress ? ` - ${state}` : state;
  }

  if (postal_code) {
    const postalCode = formatField(postal_code, 'POSTAL_CODE').replace('.', '');

    fullAddress += fullAddress ? ` - ${postalCode}` : postalCode;
  }

  return fullAddress;
}

export function formatUrl(url) {
  const encodedUrl = encodeURI(url);

  return encodedUrl;
}

export function formatValue(value, config) {
  const configData = {
    style: 'decimal',
    digits: 2,
    withSpace: false,
    withPercentage: false,
    minDigits: null,
    ...config,
  };

  const { style, digits, withSpace, withPercentage, minDigits } = configData;

  const data = {
    style,
    currency: 'BRL',
    minimumFractionDigits: minDigits ?? digits,
    maximumFractionDigits: digits,
    useGrouping: true,
  };

  const formatter = new Intl.NumberFormat('pt-br', data);

  const newValue = formatter.format(value);

  if (style === 'currency' && withSpace) {
    const formattedValue = newValue.replace(/^(\D+)/, '$1 ');

    return formattedValue;
  }

  if (style === 'decimal' && withPercentage) {
    const formattedValue = `${newValue} %`;

    return formattedValue;
  }

  return newValue;
}

export function nameCase(value) {
  if (!value) return '';

  return value.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function slugify(value) {
  return value
    .toString()
    .toLowerCase()
    .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a') // Special Characters #1
    .replace(/[èÈéÉêÊëË]+/g, 'e') // Special Characters #2
    .replace(/[ìÌíÍîÎïÏ]+/g, 'i') // Special Characters #3
    .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o') // Special Characters #4
    .replace(/[ùÙúÚûÛüÜ]+/g, 'u') // Special Characters #5
    .replace(/[ýÝÿŸ]+/g, 'y') // Special Characters #6
    .replace(/[ñÑ]+/g, 'n') // Special Characters #7
    .replace(/[çÇ]+/g, 'c') // Special Characters #8
    .replace(/[ß]+/g, 'ss') // Special Characters #9
    .replace(/[Ææ]+/g, 'ae') // Special Characters #10
    .replace(/[Øøœ]+/g, 'oe') // Special Characters #11
    .replace(/[%]+/g, 'pct') // Special Characters #12
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
