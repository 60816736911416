import produce from 'immer';

import RegionTypes from '../regions/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  regionCustomerModalOpen: false,
};

export default function regionCustomers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.REGION_CUSTOMERS_GET: {
        const { customers } = action.payload;

        draft.list = customers;
        break;
      }

      case Types.REGION_CUSTOMERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGION_CUSTOMERS_UPDATE_SUCCESS: {
        const { customers } = action.payload;

        draft.list = customers;

        draft.loading = false;
        draft.regionCustomerModalOpen = false;

        break;
      }
      case Types.REGION_CUSTOMERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGION_CUSTOMERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGION_CUSTOMERS_DELETE_SUCCESS: {
        const { person_id } = action.payload;

        draft.list = state.list.filter(item => {
          return item.id !== person_id;
        });

        draft.loading = false;
        break;
      }
      case Types.REGION_CUSTOMERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.REGION_CUSTOMERS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.REGION_CUSTOMERS_OPEN_MODAL: {
        draft.regionCustomerModalOpen = true;
        break;
      }
      case Types.REGION_CUSTOMERS_CLOSE_MODAL: {
        draft.regionCustomerModalOpen = false;
        break;
      }

      case RegionTypes.REGIONS_RESET_DETAIL: {
        draft.regionCustomerModalOpen = false;
        break;
      }

      default:
    }
  });
}
