import Types from './types';

export function getOwnerPhones(phones) {
  return {
    type: Types.OWNER_PHONES_GET,
    payload: { phones },
  };
}

export function createOwnerPhoneRequest(owner_id, phone) {
  return {
    type: Types.OWNER_PHONES_CREATE_REQUEST,
    payload: { owner_id, phone },
  };
}

export function createOwnerPhoneSuccess(phone) {
  return {
    type: Types.OWNER_PHONES_CREATE_SUCCESS,
    payload: { phone },
  };
}

export function createOwnerPhoneFailure() {
  return {
    type: Types.OWNER_PHONES_CREATE_FAILURE,
  };
}

export function updateOwnerPhoneRequest(owner_id, phone_id, newPhone) {
  return {
    type: Types.OWNER_PHONES_UPDATE_REQUEST,
    payload: { owner_id, phone_id, newPhone },
  };
}

export function updateOwnerPhoneSuccess(newPhone) {
  return {
    type: Types.OWNER_PHONES_UPDATE_SUCCESS,
    payload: { newPhone },
  };
}

export function updateOwnerPhoneFailure() {
  return {
    type: Types.OWNER_PHONES_UPDATE_FAILURE,
  };
}

export function deleteOwnerPhoneRequest(owner_id, phone_id) {
  return {
    type: Types.OWNER_PHONES_DELETE_REQUEST,
    payload: { owner_id, phone_id },
  };
}

export function deleteOwnerPhoneSuccess(phone_id) {
  return {
    type: Types.OWNER_PHONES_DELETE_SUCCESS,
    payload: { phone_id },
  };
}

export function deleteOwnerPhoneFailure() {
  return {
    type: Types.OWNER_PHONES_DELETE_FAILURE,
  };
}

export function resetListOwnerPhone() {
  return {
    type: Types.OWNER_PHONES_RESET_LIST,
  };
}

export function openModalOwnerPhone(phone_id) {
  return {
    type: Types.OWNER_PHONES_OPEN_MODAL,
    payload: { phone_id },
  };
}

export function closeModalOwnerPhone() {
  return {
    type: Types.OWNER_PHONES_CLOSE_MODAL,
  };
}
