import styled from 'styled-components';

import { Assignment as MuiAssignment } from '@material-ui/icons';

function defineStyles(type) {
  let styles;

  switch (type) {
    case 'info':
      styles = ``;
      break;
    default:
      styles = ``;
      break;
  }

  return styles;
}

export const Assignment = styled(MuiAssignment)`
  ${({ type }) => defineStyles(type)}
`;
