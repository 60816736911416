import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createRuralPropertyAddressSuccess,
  createRuralPropertyAddressFailure,
  updateRuralPropertyAddressSuccess,
  updateRuralPropertyAddressFailure,
} from './actions';
import Types from './types';

export function* createRuralPropertyAddress({ payload }) {
  try {
    const { property_id, address } = payload;

    const response = yield call(
      api.post,
      `rural-properties/${property_id}/addresses`,
      address
    );

    yield put(createRuralPropertyAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createRuralPropertyAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateRuralPropertyAddress({ payload }) {
  try {
    const { property_id, address_id, newAddress } = payload;

    const response = yield call(
      api.put,
      `rural-properties/${property_id}/addresses/${address_id}`,
      newAddress
    );

    yield put(updateRuralPropertyAddressSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateRuralPropertyAddressFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export default all([
  takeLatest(
    Types.RURAL_PROPERTY_ADDRESSES_CREATE_REQUEST,
    createRuralPropertyAddress
  ),
  takeLatest(
    Types.RURAL_PROPERTY_ADDRESSES_UPDATE_REQUEST,
    updateRuralPropertyAddress
  ),
]);
