import produce from 'immer';

import PersonTypes from '../persons/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  promoterAddModalOpen: false,
  promoterObservationModalOpen: false,
  promoterFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  openFileDialog: false,
};

export default function promoters(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.PROMOTERS_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.PROMOTERS_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.promoterFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.PROMOTERS_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PROMOTERS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PROMOTERS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.promoter);
        draft.loading = false;
        break;
      }
      case Types.PROMOTERS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PROMOTERS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PROMOTERS_UPDATE_SUCCESS: {
        const { newPromoter } = action.payload;
        draft.detail = newPromoter;

        draft.list = state.list.map(promoter => {
          return promoter.id === newPromoter.id ? newPromoter : promoter;
        });

        draft.promoterObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.PROMOTERS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PROMOTERS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PROMOTERS_DELETE_SUCCESS: {
        draft.list = state.list.filter(promoter => {
          return promoter.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.PROMOTERS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PROMOTERS_BECOME_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PROMOTERS_BECOME_SUCCESS: {
        draft.loading = false;
        draft.promoterAddModalOpen = false;
        break;
      }
      case Types.PROMOTERS_BECOME_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.PROMOTERS_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.PROMOTERS_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.promoter;
        draft.requestError = false;
        break;
      }
      case Types.PROMOTERS_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.PROMOTERS_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;
        draft.promoterFilterModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }

      case Types.PROMOTERS_RESET_DETAILS: {
        draft.detail = {};
        draft.promoterAddModalOpen = false;
        draft.promoterObservationModalOpen = false;
        break;
      }

      case Types.PROMOTERS_ADD_OPEN_MODAL: {
        draft.promoterAddModalOpen = true;
        break;
      }
      case Types.PROMOTERS_ADD_CLOSE_MODAL: {
        draft.promoterAddModalOpen = false;
        break;
      }

      case Types.PROMOTERS_OBSERVATION_OPEN_MODAL: {
        draft.promoterObservationModalOpen = true;
        break;
      }
      case Types.PROMOTERS_OBSERVATION_CLOSE_MODAL: {
        draft.promoterObservationModalOpen = false;
        break;
      }

      case Types.PROMOTERS_FILTER_OPEN_MODAL: {
        draft.promoterFilterModalOpen = true;
        break;
      }
      case Types.PROMOTERS_FILTER_CLOSE_MODAL: {
        draft.promoterFilterModalOpen = false;
        break;
      }

      case Types.PROMOTERS_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.PROMOTERS_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        draft.openFileDialog = false;
        break;
      }

      case Types.PROMOTERS_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case PersonTypes.PERSONS_RESET_DETAILS: {
        draft.promoterAddModalOpen = false;
        break;
      }

      default:
    }
  });
}
