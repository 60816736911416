import produce from 'immer';

import BillToPayTypes from '../billsToPay/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  loading: false,
  billToPayChartAccountModalOpen: false,
};

export default function billToPayChartAccounts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_GET: {
        draft.list = action.payload.chartAccounts;
        break;
      }

      case Types.BILL_TO_PAY_CHART_ACCOUNTS_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.chartAccount);
        draft.loading = false;
        draft.billToPayChartAccountModalOpen = false;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_SUCCESS: {
        const { newChartAccount } = action.payload;

        draft.list = state.list.map(chartAccount => {
          return chartAccount.id === newChartAccount.id
            ? newChartAccount
            : chartAccount;
        });

        draft.detail = {};
        draft.loading = false;
        draft.billToPayChartAccountModalOpen = false;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_PAY_CHART_ACCOUNTS_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_DELETE_SUCCESS: {
        const { chart_account_id } = action.payload;

        draft.list = state.list.filter(chartAccount => {
          return chartAccount.id !== chart_account_id;
        });

        if (draft.detail.id && chart_account_id === draft.detail.id) {
          draft.detail = {};
        }

        draft.loading = false;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.BILL_TO_PAY_CHART_ACCOUNTS_RESET_LIST: {
        draft.list = [];
        break;
      }

      case Types.BILL_TO_PAY_CHART_ACCOUNTS_OPEN_MODAL: {
        const { chart_account_id } = action.payload;

        draft.detail = {};

        if (chart_account_id) {
          [draft.detail] = draft.list.filter(
            chartAccount => chartAccount.id === chart_account_id
          );
        }

        draft.billToPayChartAccountModalOpen = true;
        break;
      }
      case Types.BILL_TO_PAY_CHART_ACCOUNTS_CLOSE_MODAL: {
        draft.billToPayChartAccountModalOpen = false;
        draft.detail = {};
        break;
      }

      case BillToPayTypes.BILLS_TO_PAY_RESET_DETAILS: {
        draft.billToPayChartAccountModalOpen = false;
        draft.detail = {};
        draft.list = [];
        break;
      }

      default:
    }
  });
}
