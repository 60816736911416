const Types = {
  USER_UPDATE_REQUEST: '@user/UPDATE_USER_REQUEST',
  USER_UPDATE_SUCCESS: '@user/UPDATE_USER_SUCCESS',
  USER_UPDATE_FAILURE: '@user/UPDATE_USER_FAILURE',

  USER_GET_DETAILS_REQUEST: '@user/GET_DETAILS_USER_REQUEST',
  USER_GET_DETAILS_SUCCESS: '@user/GET_DETAILS_USER_SUCCESS',
  USER_GET_DETAILS_FAILURE: '@user/GET_DETAILS_USER_FAILURE',

  USER_RESET_DETAILS: '@user/RESET_DETAILS_USER',
};

export default Types;
