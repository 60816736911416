const Types = {
  REPORTS_GET_REQUEST: '@reports/GET_REPORTS_REQUEST',
  REPORTS_GET_SUCCESS: '@reports/GET_REPORTS_SUCCESS',
  REPORTS_GET_FAILURE: '@reports/GET_REPORTS_FAILURE',

  REPORTS_SET_DATA: '@reports/SET_DATA_REPORT',
  REPORTS_SET_LOADING: '@reports/SET_LOADING_REPORT',

  REPORTS_RESET_DATA: '@reports/RESET_DATA_REPORT',
};

export default Types;
