import React from 'react';
import { DollarSign } from 'react-feather';

import async from '~/components/Async';
import IntlMessages from '~/utils/IntlMessages';

const AccountsList = async(() => import('~/pages/Accounts/List'));
const BanksList = async(() => import('~/pages/Banks/List'));
const BankBillings = async(() => import('~/pages/BankBillings/List'));
const BillsToPayList = async(() => import('~/pages/BillsToPay/List'));
const BillsToReceiveList = async(() => import('~/pages/BillsToReceive/List'));
const ChartAccountsList = async(() => import('~/pages/ChartAccounts/List'));
const CostCentersList = async(() => import('~/pages/CostCenters/List'));
const PaymentMethodsList = async(() => import('~/pages/PaymentMethods/List'));
const PaymentMethodsTypesList = async(() =>
  import('~/pages/PaymentMethodsTypes/List')
);

export const financialRoutes = {
  id: <IntlMessages id="sidebar.financial" />,
  path: '/financial',
  icon: <DollarSign />,
  children: [
    {
      path: '/accounts',
      name: <IntlMessages id="sidebar.financial.accounts" />,
      component: AccountsList,
    },
    {
      path: '/bills-to-receive',
      name: <IntlMessages id="sidebar.financial.bills-to-receive" />,
      component: BillsToReceiveList,
    },
    {
      path: '/bills-to-pay',
      name: <IntlMessages id="sidebar.financial.bills-to-pay" />,
      component: BillsToPayList,
    },
    {
      path: '/bank-billings',
      name: <IntlMessages id="sidebar.financial.bank-billings" />,
      component: BankBillings,
    },
    {
      path: '/chart-accounts',
      name: <IntlMessages id="sidebar.financial.chart-accounts" />,
      component: ChartAccountsList,
    },
    {
      path: '/cost-centers',
      name: <IntlMessages id="sidebar.financial.cost-centers" />,
      component: CostCentersList,
    },
    {
      path: '/payment-methods',
      id: <IntlMessages id="sidebar.financial.payment-methods" />,
      children: [
        {
          path: '/payment-methods/receive',
          params: 'RECEIVE',
          component: PaymentMethodsList,
          name: (
            <IntlMessages id="sidebar.financial.payment-methods.bills-to-receive" />
          ),
        },
        {
          path: '/payment-methods/pay',
          params: 'PAY',
          component: PaymentMethodsList,
          name: (
            <IntlMessages id="sidebar.financial.payment-methods.bills-to-pay" />
          ),
        },
      ],
    },
    {
      path: '/financial-others',
      id: <IntlMessages id="sidebar.others" />,
      children: [
        {
          path: '/banks',
          name: <IntlMessages id="sidebar.financial.banks" />,
          component: BanksList,
        },
        {
          path: '/payment-methods-types',
          name: <IntlMessages id="sidebar.financial.payment-methods-types" />,
          component: PaymentMethodsTypesList,
        },
      ],
    },
  ],
};
