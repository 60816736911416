import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createCompanyUserSuccess,
  createCompanyUserFailure,
  updateCompanyUserSuccess,
  updateCompanyUserFailure,
  deleteCompanyUserSuccess,
  deleteCompanyUserFailure,
} from './actions';
import Types from './types';

export function* createCompanyUser({ payload }) {
  try {
    const { company_id, user } = payload;

    const { password: unencrypted_password, name, username, email } = user;

    const response = yield call(api.post, `companies/${company_id}/users`, {
      name,
      username,
      email,
      unencrypted_password,
    });

    yield put(createCompanyUserSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCompanyUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCompanyUser({ payload }) {
  try {
    const { company_id, email } = payload;

    const response = yield call(
      api.put,
      `companies/${company_id}/users/${email}`
    );

    yield put(updateCompanyUserSuccess(response.data));

    toast.success(<IntlMessages id="company-users.messages.invite-success" />);
  } catch (err) {
    yield put(updateCompanyUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="company-users.messages.invite-failure" />);
  }
}

export function* deleteCompanyUser({ payload }) {
  const { company_id, user_id } = payload;

  try {
    yield call(api.delete, `companies/${company_id}/users/${user_id}`);

    yield put(deleteCompanyUserSuccess(user_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCompanyUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.COMPANY_USERS_CREATE_REQUEST, createCompanyUser),
  takeLatest(Types.COMPANY_USERS_UPDATE_REQUEST, updateCompanyUser),
  takeLatest(Types.COMPANY_USERS_DELETE_REQUEST, deleteCompanyUser),
]);
