import Types from './types';

export function getRuralPropertyEmails(emails) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_GET,
    payload: { emails },
  };
}

export function createRuralPropertyEmailRequest(property_id, email) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_CREATE_REQUEST,
    payload: { property_id, email },
  };
}

export function createRuralPropertyEmailSuccess(email) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createRuralPropertyEmailFailure() {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_CREATE_FAILURE,
  };
}

export function updateRuralPropertyEmailRequest(
  property_id,
  email_id,
  newEmail
) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_UPDATE_REQUEST,
    payload: { property_id, email_id, newEmail },
  };
}

export function updateRuralPropertyEmailSuccess(newEmail) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updateRuralPropertyEmailFailure() {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_UPDATE_FAILURE,
  };
}

export function deleteRuralPropertyEmailRequest(property_id, email_id) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_DELETE_REQUEST,
    payload: { property_id, email_id },
  };
}

export function deleteRuralPropertyEmailSuccess(email_id) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deleteRuralPropertyEmailFailure() {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_DELETE_FAILURE,
  };
}

export function resetListRuralPropertyEmail() {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_RESET_LIST,
  };
}

export function openModalRuralPropertyEmail(email_id) {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalRuralPropertyEmail() {
  return {
    type: Types.RURAL_PROPERTY_EMAILS_CLOSE_MODAL,
  };
}
