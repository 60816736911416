import styled from 'styled-components';

import breakpoints from '~/theme/breakpoints';
import colors from '~/theme/colors';

const smBreakpoint = `${breakpoints.values.sm}px`;

export const NotAvailableChip = styled.div`
  border: 1px solid ${colors.lightError};
  border-radius: 4px;
  margin-bottom: 2px;
  margin-left: ${({ margin_left }) => (margin_left ? '10px' : 0)};
  padding: 0 4px;
  font-size: 12px;
  color: ${colors.darkError};
  max-width: max-content;
  text-align: center;
  font-weight: bold;
  display: ${({ display }) => display || 'block'};

  @media (max-width: ${smBreakpoint}) {
    display: block;
    margin-left: 0;
  }
`;
