import React from 'react';

import PropTypes from 'prop-types';

import { AttachMoney } from './styles';

export default function MoneyIcon({ type }) {
  return <AttachMoney type={type} />;
}

MoneyIcon.propTypes = {
  type: PropTypes.string,
};

MoneyIcon.defaultProps = {
  type: '',
};
