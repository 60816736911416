import produce from 'immer';

import BranchActivityTypes from '../branchesActivities/types';
import CovenantTypes from '../covenants/types';
import PersonTypes from '../persons/types';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: '',

  requestError: false,
  loading: false,
  employeeAddModalOpen: false,
  employeeObservationModalOpen: false,
  employeeFilterModalOpen: false,
  newBranchActivity: {},
  newCovenant: {},
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  openFileDialog: false,
};

export default function employees(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.EMPLOYEES_GET_REQUEST: {
        draft.loading = true;
        draft.requestError = true;
        break;
      }
      case Types.EMPLOYEES_GET_SUCCESS: {
        const { data, modalFilters, filterColumnsOrder, filterSearch } =
          action.payload;

        draft.list = data;
        draft.loading = false;
        draft.requestError = false;
        draft.employeeFilterModalOpen = false;

        if (filterColumnsOrder) {
          draft.filterColumnsOrder = {
            field: filterColumnsOrder?.field,
            direction: filterColumnsOrder?.direction,
          };
        }

        if (typeof filterSearch !== 'undefined') {
          draft.filterSearch = filterSearch;
        }

        if (typeof modalFilters !== 'undefined') {
          draft.filter = modalFilters;
        }
        break;
      }
      case Types.EMPLOYEES_GET_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEES_CREATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEES_CREATE_SUCCESS: {
        draft.list.unshift(action.payload.employee);
        draft.loading = false;
        break;
      }
      case Types.EMPLOYEES_CREATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEES_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEES_UPDATE_SUCCESS: {
        const { newEmployee } = action.payload;
        draft.detail = newEmployee;

        draft.list = state.list.map(employee => {
          return employee.id === newEmployee.id ? newEmployee : employee;
        });

        draft.employeeObservationModalOpen = false;
        draft.loading = false;
        break;
      }
      case Types.EMPLOYEES_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEES_DELETE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEES_DELETE_SUCCESS: {
        draft.list = state.list.filter(employee => {
          return employee.id !== action.payload.id;
        });

        draft.loading = false;
        break;
      }
      case Types.EMPLOYEES_DELETE_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEES_BECOME_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.EMPLOYEES_BECOME_SUCCESS: {
        draft.loading = false;
        draft.employeeAddModalOpen = false;
        break;
      }
      case Types.EMPLOYEES_BECOME_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.EMPLOYEES_GET_DETAILS_REQUEST: {
        draft.detail = {};
        draft.requestError = true;
        break;
      }
      case Types.EMPLOYEES_GET_DETAILS_SUCCESS: {
        draft.detail = action.payload.employee;
        draft.requestError = false;
        break;
      }
      case Types.EMPLOYEES_GET_DETAILS_FAILURE: {
        break;
      }

      case Types.EMPLOYEES_RESET_LIST: {
        draft.loading = false;
        draft.list = [];
        draft.requestError = false;

        draft.employeeFilterModalOpen = false;
        draft.filterSearch = '';
        draft.filterColumnsOrder = null;
        draft.filter = {};
        break;
      }
      case Types.EMPLOYEES_RESET_DETAILS: {
        draft.detail = {};
        draft.employeeAddModalOpen = false;
        draft.employeeObservationModalOpen = false;
        break;
      }

      case Types.EMPLOYEES_ADD_OPEN_MODAL: {
        draft.employeeAddModalOpen = true;
        break;
      }
      case Types.EMPLOYEES_ADD_CLOSE_MODAL: {
        draft.employeeAddModalOpen = false;
        break;
      }

      case Types.EMPLOYEES_OBSERVATION_OPEN_MODAL: {
        draft.employeeObservationModalOpen = true;
        break;
      }
      case Types.EMPLOYEES_OBSERVATION_CLOSE_MODAL: {
        draft.employeeObservationModalOpen = false;
        break;
      }

      case Types.EMPLOYEES_CLEAR_NEW_BRANCH_ACTIVITY: {
        draft.newBranchActivity = {};
        break;
      }

      case Types.EMPLOYEES_CLEAR_NEW_COVENANT: {
        draft.newCovenant = {};
        break;
      }

      case Types.EMPLOYEES_SET_SAVING_ON_SUB_RESOURCE: {
        draft.shouldOpenSubResourceModal = true;
        draft.subResourceType = action.payload.subResourceType;
        break;
      }

      case Types.EMPLOYEES_RESET_SUB_RESOURCE_DATA: {
        draft.shouldOpenSubResourceModal = false;
        draft.subResourceType = null;
        draft.openFileDialog = false;
        break;
      }

      case Types.EMPLOYEES_OPEN_FILE_DIALOG: {
        draft.openFileDialog = true;
        break;
      }

      case Types.EMPLOYEES_FILTER_OPEN_MODAL: {
        draft.employeeFilterModalOpen = true;
        break;
      }
      case Types.EMPLOYEES_FILTER_CLOSE_MODAL: {
        draft.employeeFilterModalOpen = false;
        break;
      }

      case BranchActivityTypes.BRANCHES_ACTIVITIES_CREATE_SUCCESS: {
        const { source, branchActivity } = action.payload;

        if (source === 'EMPLOYEE') {
          draft.newBranchActivity = branchActivity;
        }

        break;
      }

      case CovenantTypes.COVENANTS_CREATE_SUCCESS: {
        const { source, covenant } = action.payload;

        if (source === 'EMPLOYEE') {
          draft.newCovenant = covenant;
        }

        break;
      }

      case PersonTypes.PERSONS_RESET_DETAILS: {
        draft.employeeObservationModalOpen = false;
        break;
      }

      default:
    }
  });
}
