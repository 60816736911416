import Types from './types';

export function getPromoterEmails(emails) {
  return {
    type: Types.PROMOTER_EMAILS_GET,
    payload: { emails },
  };
}

export function createPromoterEmailRequest(promoter_id, email) {
  return {
    type: Types.PROMOTER_EMAILS_CREATE_REQUEST,
    payload: { promoter_id, email },
  };
}

export function createPromoterEmailSuccess(email) {
  return {
    type: Types.PROMOTER_EMAILS_CREATE_SUCCESS,
    payload: { email },
  };
}

export function createPromoterEmailFailure() {
  return {
    type: Types.PROMOTER_EMAILS_CREATE_FAILURE,
  };
}

export function updatePromoterEmailRequest(promoter_id, email_id, newEmail) {
  return {
    type: Types.PROMOTER_EMAILS_UPDATE_REQUEST,
    payload: { promoter_id, email_id, newEmail },
  };
}

export function updatePromoterEmailSuccess(newEmail) {
  return {
    type: Types.PROMOTER_EMAILS_UPDATE_SUCCESS,
    payload: { newEmail },
  };
}

export function updatePromoterEmailFailure() {
  return {
    type: Types.PROMOTER_EMAILS_UPDATE_FAILURE,
  };
}

export function deletePromoterEmailRequest(promoter_id, email_id) {
  return {
    type: Types.PROMOTER_EMAILS_DELETE_REQUEST,
    payload: { promoter_id, email_id },
  };
}

export function deletePromoterEmailSuccess(email_id) {
  return {
    type: Types.PROMOTER_EMAILS_DELETE_SUCCESS,
    payload: { email_id },
  };
}

export function deletePromoterEmailFailure() {
  return {
    type: Types.PROMOTER_EMAILS_DELETE_FAILURE,
  };
}

export function resetListPromoterEmail() {
  return {
    type: Types.PROMOTER_EMAILS_RESET_LIST,
  };
}

export function openModalPromoterEmail(email_id) {
  return {
    type: Types.PROMOTER_EMAILS_OPEN_MODAL,
    payload: { email_id },
  };
}

export function closeModalPromoterEmail() {
  return {
    type: Types.PROMOTER_EMAILS_CLOSE_MODAL,
  };
}
