const Types = {
  PERSONS_UPDATE_REQUEST: '@persons/UPDATE_PERSON_REQUEST',
  PERSONS_UPDATE_SUCCESS: '@persons/UPDATE_PERSON_SUCCESS',
  PERSONS_UPDATE_FAILURE: '@persons/UPDATE_PERSON_FAILURE',

  PERSONS_DELETE_REQUEST: '@persons/DELETE_PERSON_REQUEST',
  PERSONS_DELETE_SUCCESS: '@persons/DELETE_PERSON_SUCCESS',
  PERSONS_DELETE_FAILURE: '@persons/DELETE_PERSON_FAILURE',

  PERSONS_CHECK_EXISTS_REQUEST: '@persons/CHECK_EXISTS_PERSON_REQUEST',
  PERSONS_CHECK_EXISTS_SUCCESS: '@persons/CHECK_EXISTS_PERSON_SUCCESS',
  PERSONS_CHECK_EXISTS_FAILURE: '@persons/CHECK_EXISTS_PERSON_FAILURE',

  PERSONS_GET_DETAILS_REQUEST: '@persons/GET_DETAILS_PERSON_REQUEST',
  PERSONS_GET_DETAILS_SUCCESS: '@persons/GET_DETAILS_PERSON_SUCCESS',
  PERSONS_GET_DETAILS_FAILURE: '@persons/GET_DETAILS_PERSON_FAILURE',

  PERSONS_RESET_DETAILS: '@persons/RESET_DETAILS_PERSON',
  PERSONS_RESET_FOUND: '@persons/RESET_FOUND_PERSON',
};

export default Types;
