import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSellerEmailSuccess,
  createSellerEmailFailure,
  updateSellerEmailSuccess,
  updateSellerEmailFailure,
  deleteSellerEmailSuccess,
  deleteSellerEmailFailure,
} from './actions';
import Types from './types';

export function* createSellerEmail({ payload }) {
  try {
    const { seller_id, email } = payload;

    const response = yield call(api.post, `sellers/${seller_id}/emails`, email);

    yield put(createSellerEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSellerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSellerEmail({ payload }) {
  try {
    const { seller_id, email_id, newEmail } = payload;

    const response = yield call(
      api.put,
      `sellers/${seller_id}/emails/${email_id}`,
      newEmail
    );

    yield put(updateSellerEmailSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSellerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSellerEmail({ payload }) {
  const { seller_id, email_id } = payload;

  const emails = yield select(state => state.sellerEmails.list);

  const email = emails.find(data => data.id === email_id);

  if (email.is_main && emails.length > 1) {
    yield put(deleteSellerEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `sellers/${seller_id}/emails/${email_id}`);

    yield put(deleteSellerEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSellerEmailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.SELLER_EMAILS_CREATE_REQUEST, createSellerEmail),
  takeLatest(Types.SELLER_EMAILS_UPDATE_REQUEST, updateSellerEmail),
  takeLatest(Types.SELLER_EMAILS_DELETE_REQUEST, deleteSellerEmail),
]);
