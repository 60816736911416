import React from 'react';

import PropTypes from 'prop-types';

import { CssBaseline } from '@material-ui/core';

import { GlobalStyle, Root } from './styles';

export default function Auth({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

Auth.propTypes = {
  children: PropTypes.element.isRequired,
};
