const Types = {
  SUPERVISOR_ADDRESSES_GET: '@supervisors/GET_SUPERVISOR_ADDRESSES',

  SUPERVISOR_ADDRESSES_CREATE_REQUEST:
    '@supervisors/CREATE_SUPERVISOR_ADDRESS_REQUEST',
  SUPERVISOR_ADDRESSES_CREATE_SUCCESS:
    '@supervisors/CREATE_SUPERVISOR_ADDRESS_SUCCESS',
  SUPERVISOR_ADDRESSES_CREATE_FAILURE:
    '@supervisors/CREATE_SUPERVISOR_ADDRESS_FAILURE',

  SUPERVISOR_ADDRESSES_UPDATE_REQUEST:
    '@supervisors/UPDATE_SUPERVISOR_ADDRESS_REQUEST',
  SUPERVISOR_ADDRESSES_UPDATE_SUCCESS:
    '@supervisors/UPDATE_SUPERVISOR_ADDRESS_SUCCESS',
  SUPERVISOR_ADDRESSES_UPDATE_FAILURE:
    '@supervisors/UPDATE_SUPERVISOR_ADDRESS_FAILURE',

  SUPERVISOR_ADDRESSES_DELETE_REQUEST:
    '@supervisors/DELETE_SUPERVISOR_ADDRESS_REQUEST',
  SUPERVISOR_ADDRESSES_DELETE_SUCCESS:
    '@supervisors/DELETE_SUPERVISOR_ADDRESS_SUCCESS',
  SUPERVISOR_ADDRESSES_DELETE_FAILURE:
    '@supervisors/DELETE_SUPERVISOR_ADDRESS_FAILURE',

  SUPERVISOR_ADDRESSES_RESET_LIST: '@supervisors/RESET_LIST_SUPERVISOR_ADDRESS',

  SUPERVISOR_ADDRESSES_OPEN_MODAL: '@supervisors/OPEN_MODAL_SUPERVISOR_ADDRESS',
  SUPERVISOR_ADDRESSES_CLOSE_MODAL:
    '@supervisors/CLOSE_MODAL_SUPERVISOR_ADDRESS',
};

export default Types;
