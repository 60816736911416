import Types from './types';

export function createBillToPayRequest(billToPay, subResourceType = null) {
  return {
    type: Types.BILLS_TO_PAY_CREATE_REQUEST,
    payload: { billToPay, subResourceType },
  };
}

export function createBillToPaySuccess(billToPay) {
  return {
    type: Types.BILLS_TO_PAY_CREATE_SUCCESS,
    payload: { billToPay },
  };
}

export function createBillToPayFailure() {
  return {
    type: Types.BILLS_TO_PAY_CREATE_FAILURE,
  };
}

export function updateBillToPayRequest(id, newBillToPay) {
  return {
    type: Types.BILLS_TO_PAY_UPDATE_REQUEST,
    payload: { id, newBillToPay },
  };
}

export function updateBillToPaySuccess(newBillToPay) {
  return {
    type: Types.BILLS_TO_PAY_UPDATE_SUCCESS,
    payload: { newBillToPay },
  };
}

export function updateBillToPayFailure() {
  return {
    type: Types.BILLS_TO_PAY_UPDATE_FAILURE,
  };
}

export function settlementBillToPayRequest(id, newBillToPay, fetchList = null) {
  return {
    type: Types.BILLS_TO_PAY_SETTLEMENT_REQUEST,
    payload: { id, newBillToPay, fetchList },
  };
}

export function settlementBillToPaySuccess(newBillToPay) {
  return {
    type: Types.BILLS_TO_PAY_SETTLEMENT_SUCCESS,
    payload: { newBillToPay },
  };
}

export function settlementBillToPayFailure() {
  return {
    type: Types.BILLS_TO_PAY_SETTLEMENT_FAILURE,
  };
}

export function deleteBillToPayRequest({
  id,
  fetchList = null,
  isFromDetail = false,
}) {
  return {
    type: Types.BILLS_TO_PAY_DELETE_REQUEST,
    payload: { id, fetchList, isFromDetail },
  };
}

export function deleteBillToPaySuccess(isFromDetail = false) {
  return {
    type: Types.BILLS_TO_PAY_DELETE_SUCCESS,
    payload: { isFromDetail },
  };
}

export function deleteBillToPayFailure() {
  return {
    type: Types.BILLS_TO_PAY_DELETE_FAILURE,
  };
}

export function getDetailsBillToPayRequest(id) {
  return {
    type: Types.BILLS_TO_PAY_GET_DETAILS_REQUEST,
    payload: { id },
  };
}

export function getDetailsBillToPaySuccess(billToPay) {
  return {
    type: Types.BILLS_TO_PAY_GET_DETAILS_SUCCESS,
    payload: { billToPay },
  };
}

export function getDetailsBillToPayFailure() {
  return {
    type: Types.BILLS_TO_PAY_GET_DETAILS_FAILURE,
  };
}

export function resetListBillsToPay() {
  return {
    type: Types.BILLS_TO_PAY_RESET_LIST,
  };
}

export function resetDetailsBillToPay() {
  return {
    type: Types.BILLS_TO_PAY_RESET_DETAILS,
  };
}

export function openModalSettlementBillToPay(id, sharedDetail) {
  return {
    type: Types.BILLS_TO_PAY_SETTLEMENT_OPEN_MODAL,
    payload: { id, sharedDetail },
  };
}

export function closeModalSettlementBillToPay() {
  return {
    type: Types.BILLS_TO_PAY_SETTLEMENT_CLOSE_MODAL,
  };
}

export function openModalBillToPayObservation() {
  return {
    type: Types.BILLS_TO_PAY_OBSERVATION_OPEN_MODAL,
  };
}

export function closeModalBillToPayObservation() {
  return {
    type: Types.BILLS_TO_PAY_OBSERVATION_CLOSE_MODAL,
  };
}

export function openModalBillToPayFilter() {
  return {
    type: Types.BILLS_TO_PAY_FILTER_OPEN_MODAL,
  };
}

export function closeModalBillToPayFilter() {
  return {
    type: Types.BILLS_TO_PAY_FILTER_CLOSE_MODAL,
  };
}

export function setBillToPayTableFilters({
  modalFilters,
  filterColumnsOrder,
  filterSearch,
  closeModal = false,
  rowsPerPage,
  currentPage,
  searchThroughTable,
  shouldFetchStatisticData = false,
  listLength,
}) {
  return {
    type: Types.BILLS_TO_PAY_SET_TABLE_FILTERS,
    payload: {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      searchThroughTable,
      shouldFetchStatisticData,
      listLength,
    },
  };
}

export function setBillToPayInitialTableFilters() {
  return {
    type: Types.BILLS_TO_PAY_SET_INITIAL_TABLE_FILTERS,
  };
}

export function setBillToPayRequestError() {
  return {
    type: Types.BILLS_TO_PAY_SET_REQUEST_ERROR,
  };
}

export function setSavingOnSubResource(subResourceType) {
  return {
    type: Types.BILLS_TO_PAY_SET_SAVING_ON_SUB_RESOURCE,
    payload: { subResourceType },
  };
}

export function resetSubResourceData() {
  return {
    type: Types.BILLS_TO_PAY_RESET_SUB_RESOURCE_DATA,
  };
}
