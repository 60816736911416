import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import {
  getProductDiscounts,
  openModalProductDiscount,
  resetListProductDiscount,
} from '../productDiscounts/actions';
import { getProductFiles, resetListProductFile } from '../productFiles/actions';
import {
  getProductPriceListItems,
  resetProductPriceListItem,
} from '../productPriceListItems/actions';
import {
  createProductSuccess,
  createProductFailure,
  updateProductSuccess,
  updateProductFailure,
  deleteProductSuccess,
  deleteProductFailure,
  getDetailsProductSuccess,
  getDetailsProductFailure,
  setSavingOnSubResource,
  setOpenFileDialog,
  resetSubResourceData,
} from './actions';
import Types from './types';

export function* createProduct({ payload }) {
  try {
    const { product, subResourceType } = payload;

    const response = yield call(api.post, 'products', product);

    yield put(createProductSuccess(response.data));

    yield put(setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    history.replace(`/product/${response.data.id}`, { creatingResource: true });
  } catch (err) {
    yield put(createProductFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateProduct({ payload }) {
  try {
    const { id, newProduct } = payload;

    const response = yield call(api.put, `products/${id}`, newProduct);

    const { price_list_items: items, ...rest } = response.data;

    yield put(getProductPriceListItems(items));

    yield put(updateProductSuccess(rest));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateProductFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteProduct({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `products/${id}`);

    yield put(deleteProductSuccess(id, isFromDetail));

    history.push('/products');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.products);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isDeletingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(deleteProductFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsProduct({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `products/${id}`);

    const {
      price_list_items: items,
      files,
      products_discounts,
      ...rest
    } = response.data;

    yield put(getProductPriceListItems(items));

    yield put(getProductFiles(files));

    yield put(getProductDiscounts(products_discounts));

    yield put(getDetailsProductSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.products
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'FILE') {
        yield put(setOpenFileDialog());
      } else if (subResourceType === 'DISCOUNT') {
        yield put(openModalProductDiscount());
      }

      yield put(resetSubResourceData());
    }
  } catch (err) {
    yield put(getDetailsProductFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsProduct() {
  yield put(resetProductPriceListItem());

  yield put(resetListProductFile());

  yield put(resetListProductDiscount());
}

export default all([
  takeLatest(Types.PRODUCTS_CREATE_REQUEST, createProduct),
  takeLatest(Types.PRODUCTS_UPDATE_REQUEST, updateProduct),
  takeLatest(Types.PRODUCTS_DELETE_REQUEST, deleteProduct),
  takeLatest(Types.PRODUCTS_GET_DETAILS_REQUEST, getDetailsProduct),
  takeLatest(Types.PRODUCTS_RESET_DETAILS, resetDetailsProduct),
]);
