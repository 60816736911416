import React from 'react';
import { Circle, CheckCircle, XCircle, MinusCircle, Lock } from 'react-feather';

const situationUndefined = {
  label: 'persons.situation.undefined',
  color: 'regular',
  icon: <Circle />,
};

const situationNormal = {
  label: 'persons.situation.normal',
  color: 'success',
  icon: <CheckCircle />,
};

const situationBlocked = {
  label: 'persons.situation.blocked',
  color: 'error',
  icon: <Lock />,
};

const situationDebtor = {
  label: 'persons.situation.debtor',
  color: 'error',
  icon: <MinusCircle />,
};

const situationCanceled = {
  label: 'persons.situation.canceled',
  color: 'error',
  icon: <XCircle />,
};

function getSituationStatus(situation) {
  let status;

  switch (situation) {
    case 'N':
      status = situationNormal;
      break;
    case 'B':
      status = situationBlocked;
      break;
    case 'D':
      status = situationDebtor;
      break;
    case 'C':
      status = situationCanceled;
      break;
    default:
      status = situationUndefined;
  }

  return status;
}

export { situationUndefined, getSituationStatus };
