import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  getCategoriesSuccess,
  getCategoriesFailure,
  createCategorySuccess,
  createCategoryFailure,
  updateCategorySuccess,
  updateCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
} from './actions';
import Types from './types';

export function* getCategories() {
  try {
    const response = yield call(api.get, 'categories');

    yield put(getCategoriesSuccess(response.data));
  } catch (err) {
    yield put(getCategoriesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCategory({ payload }) {
  try {
    const { category } = payload;

    const response = yield call(api.post, 'categories', category);

    yield put(createCategorySuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createCategoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCategory({ payload }) {
  try {
    const { id, newCategory } = payload;

    const response = yield call(api.put, `categories/${id}`, newCategory);

    yield put(updateCategorySuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateCategoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCategory({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `categories/${id}`);

    yield put(deleteCategorySuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteCategoryFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.CATEGORIES_GET_REQUEST, getCategories),
  takeLatest(Types.CATEGORIES_CREATE_REQUEST, createCategory),
  takeLatest(Types.CATEGORIES_UPDATE_REQUEST, updateCategory),
  takeLatest(Types.CATEGORIES_DELETE_REQUEST, deleteCategory),
]);
