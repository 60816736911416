import React from 'react';

import PropTypes from 'prop-types';

import { Group } from './styles';

export default function GroupIcon({ type }) {
  return <Group type={type} />;
}

GroupIcon.propTypes = {
  type: PropTypes.string,
};

GroupIcon.defaultProps = {
  type: '',
};
