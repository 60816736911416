import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import {
  createSupplierPhoneSuccess,
  createSupplierPhoneFailure,
  updateSupplierPhoneSuccess,
  updateSupplierPhoneFailure,
  deleteSupplierPhoneSuccess,
  deleteSupplierPhoneFailure,
} from './actions';
import Types from './types';

export function* createSupplierPhone({ payload }) {
  try {
    const { supplier_id, phone } = payload;

    const response = yield call(
      api.post,
      `suppliers/${supplier_id}/phones`,
      phone
    );

    yield put(createSupplierPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(createSupplierPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateSupplierPhone({ payload }) {
  try {
    const { supplier_id, phone_id, newPhone } = payload;

    const response = yield call(
      api.put,
      `suppliers/${supplier_id}/phones/${phone_id}`,
      newPhone
    );

    yield put(updateSupplierPhoneSuccess(response.data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(updateSupplierPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteSupplierPhone({ payload }) {
  const { supplier_id, phone_id } = payload;

  const phones = yield select(state => state.supplierPhones.list);

  const phone = phones.find(data => data.id === phone_id);

  if (phone.is_main && phones.length > 1) {
    yield put(deleteSupplierPhoneFailure());

    toast.error(<IntlMessages id="phones.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `suppliers/${supplier_id}/phones/${phone_id}`);

    yield put(deleteSupplierPhoneSuccess(phone_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(deleteSupplierPhoneFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(Types.SUPPLIER_PHONES_CREATE_REQUEST, createSupplierPhone),
  takeLatest(Types.SUPPLIER_PHONES_UPDATE_REQUEST, updateSupplierPhone),
  takeLatest(Types.SUPPLIER_PHONES_DELETE_REQUEST, deleteSupplierPhone),
]);
